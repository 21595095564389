import { useEffect, useRef } from "react";

import { IInternalStructure } from "@/Interfaces/InternalIp.interface";
import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import InternalIpAction from "@/Actions/InternalIp.action";
import { RootState, useTypedDispatch } from "@/Store";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { updateInternalIp } = InternalIpAction;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: IInternalStructure;
}

const DEFAULT_FORM_DATA = {
  ipAddress: "",
  description: "",
};

const UpdateInternalDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isUpdataSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isUpdataSuccess")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      description: yup.string(),
      ipAddress: yup
        .string()
        .required(t("message.internalIpAddressIsRequired")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    if (payload?.id) dispatch(updateInternalIp(payload?.id, data));
  };

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      setValue("ipAddress", payload?.ipAddress || "");
      setValue("description", payload?.description || "");
    }
  }, [payload]);

  useEffect(() => {
    if (!open) {
      onClose();
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (isUpdataSuccess) {
      onClose();
    }
  }, [isUpdataSuccess]);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "15px 15px 0 0",
          }}
        >
          {t("title.updateInternalIPAddresses")}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflowY: "auto",
          }}
        >
          <Box mt={2}>
            <Controller
              name="ipAddress"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  label={t("label.internalIPAddress")}
                  placeholder={t("label.internalIPAddress")}
                  size="sm"
                  isRequired
                  isError={!_.isEmpty(errors.ipAddress?.message)}
                  errorMessage={errors?.ipAddress?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextareaComponent
                  label={t("label.desciption")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={t("label.desciption")}
                />
              )}
            />
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 15px 15px",
          }}
        >
          <Button
            size={"sm"}
            onClick={() => {
              onClose();
            }}
            isLoading={isActionLoading}
            w={75}
          >
            {t("button.cancel")}
          </Button>
          <Button
            ml={3}
            colorScheme="twitter"
            onClick={handleSubmit(onSubmit)}
            size={"sm"}
            isLoading={isActionLoading}
            w={75}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.create")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateInternalDialog;
