import { useState, useEffect } from "react";
import { validate } from "uuid";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import DataTable from "@/Components/LayoutPart/DataTable";
import { TActions } from "@/Types/Common.types";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  InputGroup,
  Select,
  Stack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverFooter,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { ConfirmDialog, RequestUpdateTimeCardDialog } from "@/Components/Popup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import _ from "lodash";
import { RequestUpdateTimeCardAction } from "@/Actions";
import {
  IDataRequestUpdateTimeCard,
  IFilterRequestUpdateTimeCard,
} from "@/Interfaces/RequestUpdateTimeCard.interface";
import { ENUMS } from "@/Constants";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import Utils from "@/Utils";
import { AdminLayout } from "@/Components/DefaultLayout";
import {
  DateRangePicker,
  RoundedContainer,
  TextField,
} from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const { REQUEST_UPDATE_TIMECARD_STATUS } = ENUMS;

const DEFAULT_FILTERS: IFilterRequestUpdateTimeCard = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  startDate: "",
  endDate: "",
  type: "",
};

const type = [
  { label: "Attendance Sheets", value: "attendance history" },
  { label: "Leaves day", value: "leave day" },
  { label: "Timesheet", value: "timesheet" },
];

const {
  fetchRequestUpdateTimeCard,
  changeStatusRequestUpdateTimeCard,
  resetRequestUpdateTimeCardReducer,
  deleteUpdateTimeCard,
  getByIdRequestUpdateTimeCard,
  resetRequestUpdateTimeCardDetails,
} = RequestUpdateTimeCardAction;

const RequestUpdateTimeCard = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    edit: false,
    details: false,
    delete: false,
    filter: false,
  });
  const [confirmDialog, setConfirmDialog] = useState<any>({
    body: "",
    actionType: "",
    openApprove: false,
    openReject: false,
    openPending: false,
  });
  const [requestUpdateTimeCardDetail, setRequestUpdateTimeCardDetail] =
    useState<IDataRequestUpdateTimeCard>({
      id: "",
      note: "",
      status: "",
      content: "",
      date: "",
      type: "",
      link: "",
    });
  const [noteRejected, setNoteRejected] = useState<string>("");
  const [isMessage, setIsMessage] = useState<string>("");
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const userData = Utils.getSavedUserData();
  const [dateFilter, setDateFilter] = useState<any>();

  const meta = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "meta")
  );
  const payload: any = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "payload")
  );
  const details: any = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "details")
  );
  const pagination: IFilterRequestUpdateTimeCard = useSelector(
    (state: RootState) => _.get(state.REQUEST_UPDATE_TIME_CARD, "pagination")
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "isFetchLoading")
  );

  const isChangeStatusSuccess = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "isChangeStatusSuccess")
  );
  const isDeleteSuccess = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "isDeleteSuccess")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "isActionLoading")
  );

  const isUpdateRequestUpdateTimeCardSuccess: boolean = useSelector(
    (state: RootState) =>
      _.get(
        state.REQUEST_UPDATE_TIME_CARD,
        "isUpdateRequestUpdateTimeCardSuccess"
      )
  );

  const sendRequestUpdateTimecard = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "UpdateTimecard",
    "sendUpdateTimeCard"
  );

  const fieldGroupSize = React.useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "275px",
      xl: "275px",
      "2xl": "275px",
    };
  }, []);

  useEffect(() => {
    // Access the 'id' parameter from searchParams
    const recordId = searchParams.get("id");
    if (recordId && validate(recordId)) {
      dispatch(getByIdRequestUpdateTimeCard(recordId));
    }
  }, [searchParams]);

  useEffect(() => {
    const recordId = searchParams.get("id");
    if (!_.isEmpty(details) && recordId) {
      setRequestUpdateTimeCardDetail(details);
      handleShowPopup("details", true);
    }
  }, [details]);

  const handleShowPopup = (name: string, value: boolean) => {
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const onRowActionChange = (action: TActions, item: any) => {
    setRequestUpdateTimeCardDetail(item);
    setIsShowPopup({ ...isShowPopup, [action]: true });
  };

  const onPageChange = (page: number) => {
    const newFilter = { ...filters, page };
    setFilters(newFilter);
    dispatch(fetchRequestUpdateTimeCard(newFilter));
  };

  const onChangeLimit = (limit: number) => {
    const newFilter = { ...filters, page: 1, limit };
    setFilters(newFilter);
    dispatch(fetchRequestUpdateTimeCard(newFilter));
  };

  const onChangStatus = (
    action: "approved" | "rejected" | "delete" | "pending"
  ) => {
    if (requestUpdateTimeCardDetail?.id) {
      if (action === "approved")
        dispatch(
          changeStatusRequestUpdateTimeCard(
            requestUpdateTimeCardDetail?.id,
            { status: "approved" },
            pagination,
            t("status.approved")
          )
        );
      if (action === "pending")
        dispatch(
          changeStatusRequestUpdateTimeCard(
            requestUpdateTimeCardDetail?.id,
            { status: "pending" },
            pagination,
            t("status.pending")
          )
        );
      if (action === "rejected") {
        if (_.trim(noteRejected))
          dispatch(
            changeStatusRequestUpdateTimeCard(
              requestUpdateTimeCardDetail?.id,
              { status: "rejected", note: noteRejected },
              pagination,
              t("status.rejected")
            )
          );
        else
          setIsMessage(
            t("message.reasonForDenyingEmployeeLeaveRequestDateIsRequired")
          );
      }
      if (action === "delete")
        dispatch(
          deleteUpdateTimeCard(requestUpdateTimeCardDetail?.id, pagination)
        );
    }
  };

  const onChangeActionConfirm = (
    action: "approve" | "rejected" | "pending"
  ) => {
    if (action === "approve")
      setConfirmDialog({
        body: t("message.doYouWantToApprovalUpdateRequestTimecardOfEmployee"),
        actionType: "approve",
        openApprove: true,
      });
    if (action === "rejected")
      setConfirmDialog({
        body: t("message.reasonForDenyingEmployeeLeaveUpdateRequestTimecard"),
        actionType: "rejected",
        openReject: true,
      });
    if (action === "pending")
      setConfirmDialog({
        body: t("message.doYouWantToPendingUpdateRequestTimecardOfRmployee"),
        actionType: "pending",
        openPending: true,
      });
  };

  const handleSubmit = () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters)
      dispatch(
        fetchRequestUpdateTimeCard(
          resolvedFilters as IFilterRequestUpdateTimeCard
        )
      );
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    setDateFilter([]);
    dispatch(fetchRequestUpdateTimeCard(DEFAULT_FILTERS));
    setIsShowPopup({ ...isShowPopup, filter: false });
  };

  const handleFiltersChange = (key: string, value: string | string[]) => {
    setFilters({ ...filters, [key]: value });
  };

  React.useEffect(() => {
    dispatch(fetchRequestUpdateTimeCard({ page: 1, limit: 10 }));
    return () => {
      dispatch(resetRequestUpdateTimeCardReducer());
    };
  }, []);

  React.useEffect(() => {
    if (
      isChangeStatusSuccess ||
      isDeleteSuccess ||
      isUpdateRequestUpdateTimeCardSuccess
    )
      setIsShowPopup({
        details: false,
        create: false,
        edit: false,
        delete: false,
        filter: false,
      });
    setConfirmDialog({
      body: "",
      actionType: "",
      openApprove: false,
      openReject: false,
    });
  }, [
    isChangeStatusSuccess,
    isDeleteSuccess,
    isUpdateRequestUpdateTimeCardSuccess,
  ]);

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName: any) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);
    dispatch(fetchRequestUpdateTimeCard(updatedPagination));
    setDateFilter([]);
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        variant="solid"
        key={label}
        h="max-content"
      >
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (pagination?.keyword) {
      renderedTags.push(renderTag(pagination?.keyword, "keyword"));
    }
    if (pagination?.status) {
      renderedTags.push(renderTag(t(`status.${pagination.status}`), "status"));
    }
    if (pagination?.type) {
      renderedTags.push(renderTag(t(`label.${pagination.type}`), "type"));
    }
    if (pagination?.startDate && pagination?.endDate) {
      renderedTags.push(
        renderTag(
          `${dayjs(pagination.startDate).format("DD/MM/YYYY")} - ${dayjs(
            pagination.endDate
          ).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HStack>
          <Text fontSize="sm">
            {/* Any additional information you want to display */}
          </Text>
          <HStack spacing={4}>
            <HStack spacing={1}>
              <Text fontSize="sm" fontWeight={500} color={"#5C6e6c"}>
                {t("label.filterBy")} :
              </Text>
              <HStack spacing={1} sx={{ textTransform: "capitalize" }}>
                {renderedTags}
              </HStack>
            </HStack>
          </HStack>
        </HStack>
      </Box>
    );
  };

  const _renderFilterOptionsUser = () => {
    return (
      <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          <InputGroup
            size="sm"
            w={250}
            sx={{
              zIndex: 3,
            }}
          >
            <DateRangePicker
              onDateChange={(newDate) => {
                setFilters({
                  ...filters,
                  startDate: dayjs(newDate[0]).format("YYYY-MM-DD"),
                  endDate: dayjs(newDate[1]).format("YYYY-MM-DD"),
                });
                setDateFilter(newDate);
              }}
              value={dateFilter}
            />
          </InputGroup>
          <InputGroup size="sm" w={110}>
            <Select
              placeholder={t("label.status")}
              value={filters.status}
              sx={{ borderRadius: 10, minW: "110px", maxW: "115px" }}
              onChange={(e: any) =>
                handleFiltersChange("status", e.target.value)
              }
            >
              {_.map(
                _.values(REQUEST_UPDATE_TIMECARD_STATUS),
                (status: any, index: number) => (
                  <option key={index} value={status}>
                    {t(`status.${status}`)}
                  </option>
                )
              )}
            </Select>
          </InputGroup>
          <InputGroup size="sm" w={170}>
            <Select
              placeholder={t("label.types")}
              value={filters?.type}
              sx={{ borderRadius: 10, minW: "170px", maxW: "115px" }}
              onChange={(e: any) => handleFiltersChange("type", e.target.value)}
            >
              {_.map(_.values(type), (item: any, index: number) => (
                <option key={index} value={item.value}>
                  {t(`label.${item.value}`)}
                </option>
              ))}
            </Select>
          </InputGroup>
          <Box ml={1}>
            <InputGroup gap={3}>
              <Button
                size="sm"
                onClick={handleSubmit}
                //colorScheme="whatsapp"
                leftIcon={<Icon as={FunnelIcon} />}
                sx={{
                  color: "#fff",
                  background: "#D2A96A",
                  "&:hover": {
                    background: "#D2BD6A",
                  },
                }}
              >
                {t("button.filter")}
              </Button>
              <Button
                size="sm"
                onClick={handleReset}
                leftIcon={<Icon as={ArrowPathIcon} />}
                sx={{
                  color: "#fff",
                  background: "#bb7154",
                  "&:hover": {
                    background: "#Db9d97",
                  },
                }}
              >
                {t("button.reset")}
              </Button>
            </InputGroup>
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderFilterOptions = () => (
    <Popover isOpen={isShowPopup.filter} placement="auto-start">
      <PopoverTrigger>
        <Button
          size="sm"
          onClick={(e: any) => {
            e.stopPropagation();
            handleShowPopup("filter", true);
          }}
          //  colorScheme="whatsapp"
          leftIcon={<Icon as={FunnelIcon} />}
          sx={{
            color: "#fff",
            background: "#D2A96A",
            "&:hover": {
              background: "#D2BD6A",
            },
          }}
        >
          {t("button.filter")}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{t("label.filterOptions")}</PopoverHeader>
        <PopoverBody
          sx={{
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <InputGroup size="sm">
              <Select
                placeholder="Status"
                value={filters.status}
                onChange={(e: any) =>
                  handleFiltersChange("status", e.target.value)
                }
              >
                {_.map(
                  _.values(REQUEST_UPDATE_TIMECARD_STATUS),
                  (status: any, index: number) => (
                    <option key={index} value={status}>
                      {_.startCase(status)}
                    </option>
                  )
                )}
              </Select>
            </InputGroup>
            <InputGroup
              sx={{
                maxWidth: 350,
              }}
              size="sm"
            >
              <DateRangePicker
                onDateChange={(newDate: any) => {
                  setFilters({
                    ...filters,
                    startDate: dayjs(newDate[0]).format("YYYY-MM-DD"),
                    endDate: dayjs(newDate[1]).format("YYYY-MM-DD"),
                  });
                  setDateFilter(newDate);
                }}
                value={dateFilter}
              />
            </InputGroup>
          </Box>
        </PopoverBody>
        <PopoverFooter sx={{ zIndex: -1 }}>
          <HStack justifyContent="space-between">
            <Button
              size="sm"
              onClick={() => handleShowPopup("filter", false)}
              variant="ghost"
              colorScheme="red"
            >
              {t("button.close")}
            </Button>
            <HStack spacing={3}>
              <Button
                size="sm"
                onClick={handleReset}
                colorScheme="orange"
                leftIcon={<Icon as={ArrowPathIcon} />}
                sx={{
                  color: "#fff",
                  background: "#bb7154",
                  "&:hover": {
                    background: "#Db9d97",
                  },
                }}
              >
                {t("button.reset")}
              </Button>
              <Button
                size="sm"
                onClick={handleSubmit}
                //colorScheme="whatsapp"
                leftIcon={<Icon as={FunnelIcon} />}
                sx={{
                  color: "#fff",
                  background: "#D2A96A",
                  "&:hover": {
                    background: "#D2BD6A",
                  },
                }}
              >
                {t("button.filter")}
              </Button>
            </HStack>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.requestUpdateTimecard")}
          </Heading>
          {sendRequestUpdateTimecard && (
            <Button
              size="sm"
              //  colorScheme="whatsapp"
              leftIcon={<Icon as={AddIcon} boxSize={3} />}
              onClick={() => handleShowPopup("create", true)}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.newRequest")}
            </Button>
          )}
        </Box>
      </Stack>
    );
  };

  const _renderTable = () => {
    return (
      <RoundedContainer>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {!sendRequestUpdateTimecard && (
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexWrap: "wrap",
                flex: 1,
              }}
            >
              <Box sx={{ maxW: fieldGroupSize }}>
                <TextField
                  placeholder="Search"
                  value={filters.keyword}
                  onChange={(e: any) =>
                    handleFiltersChange("keyword", e.target.value)
                  }
                  onKeyDown={(e) => {
                    const element = e.currentTarget as HTMLInputElement;
                    const value = element.value;
                    if (e.key === "Enter")
                      dispatch(
                        fetchRequestUpdateTimeCard({
                          ...pagination,
                          keyword: _.trim(value),
                        })
                      );
                  }}
                  rightElement={
                    <Tooltip label={t("label.search")} hasArrow>
                      <Icon
                        as={SearchIcon}
                        boxSize={5}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          dispatch(
                            fetchRequestUpdateTimeCard({
                              ...pagination,
                              keyword: _.trim(filters.keyword),
                            })
                          )
                        }
                      />
                    </Tooltip>
                  }
                />
              </Box>
            </Box>
          )}
          {!sendRequestUpdateTimecard ? (
            <Box>{_renderFilterOptions()}</Box>
          ) : (
            <Box>{_renderFilterOptionsUser()}</Box>
          )}
        </Box>
        <Box mt={2}>{_renderTags()}</Box>
        <DataTable.UpdateRequestTimeCardDataTable
          payload={payload}
          paginate={meta}
          onRowAction={onRowActionChange}
          isLoading={isFetchLoading}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
        />
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Box>{_renderTopSection()}</Box>
        <Box
          sx={{
            flexWrap: "wrap",
            gap: 3,
            mx: "1rem",
          }}
        >
          {_renderTable()}
        </Box>
        <RequestUpdateTimeCardDialog.SendRequestUpdateTimeCardDialog
          open={isShowPopup.create}
          onClose={() => handleShowPopup("create", false)}
        />
        <RequestUpdateTimeCardDialog.UpdateRequestUpdateTimeCardDialog
          open={isShowPopup.edit}
          onClose={() => {
            handleShowPopup("edit", false);
            setRequestUpdateTimeCardDetail({
              content: "",
              date: "",
              type: "",
              id: "",
              link: "",
            });
          }}
          payload={requestUpdateTimeCardDetail}
        />
        <RequestUpdateTimeCardDialog.DetailsRequestUpdateTimeCardDialog
          open={isShowPopup.details}
          onClose={() => {
            handleShowPopup("details", false);
            const recordId = searchParams.get("id");
            if (recordId) {
              searchParams.delete("id");
              setSearchParams(searchParams);
            }
            if (!_.isEmpty(details))
              dispatch(resetRequestUpdateTimeCardDetails());
            //  setDayOffRequestList({});
          }}
          onRowAction={onChangeActionConfirm}
          payload={requestUpdateTimeCardDetail}
        />

        <ConfirmDialog
          isOpen={confirmDialog.openApprove}
          onClose={() =>
            setConfirmDialog({ ...ConfirmDialog, openApprove: false })
          }
          body={confirmDialog.body}
          onAction={() => onChangStatus("approved")}
          actionType={confirmDialog.actionType}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={confirmDialog.openReject}
          onClose={() =>
            setConfirmDialog({ ...ConfirmDialog, openReject: false })
          }
          body={confirmDialog.body}
          onAction={() => onChangStatus("rejected")}
          actionType={confirmDialog.actionType}
          onChange={(e) => {
            setNoteRejected(e);
            setIsMessage("");
          }}
          isError={!_.isEmpty(isMessage)}
          errorMessage={isMessage}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={confirmDialog.openPending}
          onClose={() =>
            setConfirmDialog({ ...ConfirmDialog, openPending: false })
          }
          body={confirmDialog.body}
          onAction={() => onChangStatus("pending")}
          actionType={"save"}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={isShowPopup.delete}
          onClose={() => setIsShowPopup({ ...isShowPopup, delete: false })}
          body={t("message.doYouWantToDeleteRequestUpdateTimecard")}
          onAction={() => onChangStatus("delete")}
          actionType={"delete"}
          isLoading={isActionLoading}
        />
      </Box>
    );
  };
  return <AdminLayout content={renderMain()} />;
};

export default RequestUpdateTimeCard;
