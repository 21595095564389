import React, { useRef } from "react";

import { DatePicker } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import { ENUMS } from "@/Constants";

import Utils from "@/Utils";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Icon,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CalendarDaysIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useTranslation } from "react-multi-lang";

const { TIMECARD_STATUS_COLOR } = ENUMS;

interface SectionProps {
  open: boolean;
  onClose(): void;
  onRowAction: (action: any, item: string) => void;
  payload?: any;
}

const DetaiTimeOffRequestDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  onRowAction,
  payload,
}) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const userData = Utils.getSavedUserData();

  const approveDayOffRequest = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "DayOffRequest",
    "approve"
  );

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box
          sx={{
            display: "flex",
            gap: 4,
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Heading fontSize="sm">
              <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
              {t("label.startTimeOffDate")}:
            </Heading>
            <Grid templateColumns="repeat(6, 1fr)" gap={2}>
              <GridItem colSpan={4}>
                <DatePicker
                  disabled
                  value={payload?.startDate}
                  size="sm"
                  onDateChange={() => {}}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Stack>
                  <Select
                    placeholder=""
                    size={"sm"}
                    disabled
                    value={payload?.startDateType}
                  >
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                  </Select>
                </Stack>
              </GridItem>
            </Grid>
          </Box>
          <Box>
            <Box>
              <Heading
                fontSize="sm"
                sx={{
                  alignItems: "center",
                }}
              >
                <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
                {t("label.endTimeOffDate")}:
              </Heading>
            </Box>
            <Grid templateColumns="repeat(6, 1fr)" gap={2}>
              <GridItem colSpan={4}>
                <DatePicker
                  value={payload?.endDate}
                  disabled
                  //label="Birthday"
                  size="sm"
                  onDateChange={() => {}}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Select
                  size={"sm"}
                  placeholder=""
                  disabled
                  value={payload?.endDateType}
                >
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </Select>
              </GridItem>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "baseline", mt: 2 }}>
          <Heading
            fontSize="sm"
            sx={{
              mb: 2,
              alignItems: "center",
            }}
          >
            {t("label.duration")}:
          </Heading>
          <Text sx={{ fontSize: 15, fontWeight: 700, ml: 2 }}>
            {`${payload?.duration} ${t("label.days")}`}
          </Text>
        </Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
            alignItems: "center",
          }}
        >
          <Icon as={NewspaperIcon} boxSize={3} mr={1} />
          {t("label.timeOffReason")}:
        </Heading>
        <TextareaComponent size={"sm"} disabled value={payload?.reason} />
        {payload?.note && (
          <Box>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
                alignItems: "center",
              }}
            >
              <Icon as={NewspaperIcon} boxSize={3} mr={1} />
              {t("label.reasonForRefusal")}:
            </Heading>
            <TextareaComponent size={"sm"} disabled value={payload?.note} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            paddingBottom: 1,
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("title.detailDateOffRequest")}
          </Text>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {payload?.status && (
              <Text
                color={
                  TIMECARD_STATUS_COLOR[
                    payload?.status as keyof typeof TIMECARD_STATUS_COLOR
                  ].text
                }
                bgColor={
                  TIMECARD_STATUS_COLOR[
                    payload?.status as keyof typeof TIMECARD_STATUS_COLOR
                  ].background
                }
                sx={{
                  fontSize: 16,
                  textTransform: "capitalize",
                  borderRadius: 15,
                  px: "15px",
                  textAlign: "center",
                }}
              >
                {t(`status.${payload?.status}`)}
              </Text>
            )}
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          {payload?.status === "pending" && approveDayOffRequest && (
            <Box>
              <Button
                bgColor={"#FF0000"}
                color={"#ffffff"}
                onClick={() => onRowAction("rejected", payload)}
                size={"sm"}
                w={75}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(187, 113, 84,1)",
                  "&:hover": {
                    bgColor: "rgba(187, 113, 84,0.8)",
                  },
                }}
              >
                {t("button.reject")}
              </Button>
              <Button
                onClick={() => onRowAction("approve", payload)}
                //  onClick={handleSubmit(onSubmit)}
                ml={3}
                //colorScheme="whatsapp"
                size={"sm"}
                w={75}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(92, 110, 108,1)",
                  "&:hover": {
                    bgColor: "rgba(92, 110, 108,0.8)",
                  },
                }}
              >
                {t("button.approve")}
              </Button>
            </Box>
          )}
          {payload?.status !== "pending" && approveDayOffRequest && (
            <Button
              onClick={() => onRowAction("pending", payload)}
              ml={3}
              //  colorScheme="#ffd700"
              //  sx={{ backgroundColor: "#ffd700" }}
              size={"sm"}
              sx={{
                color: "#fff",
                bgColor: "rgba( 210, 169, 106,1)",
                "&:hover": {
                  bgColor: "rgba( 210, 169, 106,0.8)",
                },
              }}
            >
              {t("button.pending")}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DetaiTimeOffRequestDialog;
