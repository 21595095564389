import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { NOTIFICATION } = APIs;

export const fetchNotifications = async (payload?: any) => {
  return request(NOTIFICATION.BASIC, "GET", payload);
};

export const fetchOutsideNotifications = async () => {
  return request(NOTIFICATION.FETCH_OUTSIDE, "GET");
};

export const getNotificationById = async (id: string) => {
  return request(`${NOTIFICATION.GET_BY_ID}/${id}`, "GET");
};

export const markAsRead = async (payload: { notificationIds: string[] }) => {
  return request(NOTIFICATION.MARK_AS_READ, "PUT", payload);
};

export const markAllAsRead = async () => {
  return request(NOTIFICATION.MARK_ALL_AS_READ, "PUT");
};

export const markAllAsReadOutside = async () => {
  return request(NOTIFICATION.MARK_AS_READ_OUTSIDE, "PUT");
};
