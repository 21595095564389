import _ from "lodash";
import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";

const setFetchLoading = () => {
  return {
    type: ActionTypes.SET_WORKING_DAY_FETCH_LOADING,
  };
};

const setGetLoading = () => {
  return {
    type: ActionTypes.SET_WORKING_DAY_GET_LOADING,
  };
};

const setActionLoading = () => {
  return {
    type: ActionTypes.SET_WORKING_DAY_ACTION_LOADING,
  };
};

const resetWorkingDayReducer = () => {
  return {
    type: ActionTypes.RESET_WORKING_DAY_REDUCER,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_WORKING_DAY_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ActionTypes.FETCH_WORKING_DAY_FAILURE,
  };
};

const fetchWorkingDay = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setFetchLoading());
    try {
      const response: any = await APIs.fetchWorkingDay(payload);
      const result: any = response?.data?.payload;
      if (!result) {
        dispatch(fetchFail());
      } else {
        dispatch(
          fetchSuccess(_.omit(result, ["id", "createdAt", "updatedAt"]))
        );
      }
    } catch (error) {
      dispatch(fetchFail());
    }
  };
};

const getByIdSuccess = () => {
  return {
    type: ActionTypes.GET_WORKING_DAY_BY_ID_SUCCESS,
  };
};

const getByIdFail = () => {
  return {
    type: ActionTypes.GET_WORKING_DAY_BY_ID_FAILURE,
  };
};

const getWorkingDayById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetLoading());
    await APIs.getWorkingDayById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(getByIdSuccess());
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(getByIdFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_WORKING_DAY_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ActionTypes.UPDATE_WORKING_DAY_FAILURE,
  };
};

const updateWorkingDay = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setActionLoading());
    await APIs.updateWorkingDay(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchWorkingDay,
  getWorkingDayById,
  updateWorkingDay,
  resetWorkingDayReducer,
};
