import Cookies from "universal-cookie";

enum COOKIE_KEYS {
  ACCESS_TOKEN = "CAV_AT",
  REFRESH_TOKEN = "CAV_RT",
  USER_DATA = "CAV_UD",
  CLIENT_KEY = "CAV_CK",
}

const cookies = new Cookies();

const setAccessToken = (token: string) => {
  cookies.set(COOKIE_KEYS.ACCESS_TOKEN, token, { path: "/" });
};

const getAccessToken = () => {
  const token = cookies.get(COOKIE_KEYS.ACCESS_TOKEN);
  return token;
};

const removeAccessToken = () => {
  cookies.remove(COOKIE_KEYS.ACCESS_TOKEN, { path: "/" });
};

const setRefreshToken = (token: string) => {
  cookies.set(COOKIE_KEYS.REFRESH_TOKEN, token, { path: "/" });
};

const getRefreshToken = () => {
  const token = cookies.get(COOKIE_KEYS.REFRESH_TOKEN);
  return token;
};

const removeRefreshToken = () => {
  cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, { path: "/" });
};

const setUserData = (userData: any) => {
  cookies.set(COOKIE_KEYS.USER_DATA, userData, { path: "/" });
};

const getUserData = () => {
  const userData = cookies.get(COOKIE_KEYS.USER_DATA);

  return userData;
};

const removeUserData = () => {
  cookies.remove(COOKIE_KEYS.USER_DATA, { path: "/" });
};

const setClientKey = (clientKey: string) => {
  cookies.set(COOKIE_KEYS.CLIENT_KEY, clientKey, { path: "/" });
};

const getClientKey = () => {
  const clientKey = cookies.get(COOKIE_KEYS.CLIENT_KEY);
  return clientKey;
};

const removeClientKey = () => {
  cookies.remove(COOKIE_KEYS.CLIENT_KEY, { path: "/" });
};

const clearAllCookies = () => {
  removeAccessToken();
  removeAccessToken();
  removeClientKey();
};

export {
  setAccessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
  setUserData,
  removeUserData,
  setClientKey,
  removeClientKey,
  clearAllCookies,
  getAccessToken,
  getClientKey,
  getUserData,
  getRefreshToken,
};
