import { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Stack,
  Icon,
  Box,
  Heading,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Avatar,
  Text,
  useMediaQuery,
  Tooltip,
  IconButton,
  HStack,
  CircularProgress,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  EyeIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { shawdowEffects } from "@/Themes/CommonStyles";
import { AdminLayout } from "@/Components/DefaultLayout";
import { Button, RoundedContainer, SelectUser } from "@/Components/Common";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { useTypedDispatch, RootState } from "@/Store";
import { ENUMS } from "@/Constants";
//import CommonColors from "@/Themes/CommonColors";
import ProjectExteriorActions from "@/Actions/ProjectExterior.actions";
import { useSearchParams } from "react-router-dom";
import { LogtimeActions, UserActions } from "@/Actions";
import { ConfirmDialog, TimeLogDialog } from "@/Components/Popup";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import CommonColors from "@/Themes/CommonColors";
import TimelineExterior from "./TimelineExterior";
import { useTranslation } from "react-multi-lang";

const { performAction, resetLogTimePerformAction } = LogtimeActions;
const { performAction: userPerformAction } = UserActions;

const {
  getProjectExteriorById,
  resetProjectExteriorReducer,
  updateProject,
  fetchUserCanAdd,
  fetchProjectsExterior,
} = ProjectExteriorActions;

const UpdateExteriorProject = () => {
  const dispatch = useTypedDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const t = useTranslation();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const details: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "details")
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const userCanAdd: any[] = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "userCanAdd")
  );

  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  const isCreateSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );

  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "isGetLoading")
  );

  const isUpdateProjectSuccess = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "isUpdateSuccess")
  );

  const userData = Utils.getSavedUserData();

  const canCreateMember = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "createMemberExterior"
  );

  const canDeleteMember = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "deleteMemberExterior"
  );

  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const projectId = searchParams.get("id");
    if (projectId) dispatch(getProjectExteriorById(projectId));
    return () => {
      dispatch(resetProjectExteriorReducer());
    };
  }, [searchParams]);

  useEffect(() => {
    if (canCreateMember) dispatch(fetchUserCanAdd({ page: 1, limit: 10 }));
  }, [canCreateMember]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      setSelectedUsers(
        _.map(details?.projectExteriorMember, (projectMember) => {
          return {
            id: projectMember?.user?.id,
            name: projectMember?.user?.userData?.fullName,
            email: projectMember?.user?.email,
            roles: projectMember?.user?.userRole,
            avatar: projectMember?.user?.userData?.avatar?.path,
            user: projectMember?.user,
          };
        })
      );
    }
  }, [details]);

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess || isUpdateProjectSuccess)
      dispatch(getProjectExteriorById(details?.id));
  }, [isUpdateSuccess, isCreateSuccess, isUpdateProjectSuccess]);

  const userOptions = useMemo(() => {
    return _.map(userCanAdd, (user) => ({
      id: user.id,
      avatar: user?.userData?.avatar?.path,
      name: user?.userData?.fullName,
      email: user?.email,
      roles: user?.userRole,
    }));
  }, [userCanAdd]);

  const canViewScreenProjectExterior = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "canViewScreenProjectExterior"
  );

  useEffect(() => {
    if (canViewScreenProjectExterior)
      dispatch(fetchProjectsExterior({ page: 1, limit: 10 }));
  }, []);

  const _renderPopup = () => (
    <>
      <TimeLogDialog.CreateTimelogDialog
        open={timeLogPerformAction?.actionType === "logExternal"}
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <ConfirmDialog
        actionType="delete"
        isOpen={!!selectedDeleteUserId}
        onClose={() => setSelectedDeleteUserId("")}
        onAction={() => {
          dispatch(
            updateProject(
              details?.id,
              {
                removeMember: [selectedDeleteUserId],
              },
              t("message.deleteMemberSuccess")
            )
          );
          setSelectedDeleteUserId("");
        }}
        body={t(
          "message.ByConfirmingThisActionYouWillPermanentlyRemoveTheSelectedUserFromTheProject"
        )}
      />
      <TimeLogDialog.UpdateTimelogDialog
        open={timeLogPerformAction?.actionType === "editLog"}
        onClose={() => {
          if (searchParams) {
            searchParams.delete("id");
            //setSearchParams(searchParams);
          }
          dispatch(resetLogTimePerformAction());
        }}
      />
      {/*<ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(deleteLogTime(timeLogPerformAction.id));
        }}
        body="Do you want remove this log"
        actionType="delete"
      />*/}
    </>
  );

  const _renderLoading = () => (
    <Box
      sx={{
        background: "#fff",
        inset: 0,
        zIndex: 99,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all .3s ease-in-out",
      }}
    >
      <HStack>
        <CircularProgress size="20px" isIndeterminate color="gray" />
        <Text>Loading...</Text>
      </HStack>
    </Box>
  );

  const _renderInformationTab = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        mt: 3,
      }}
    >
      <Box gap={3} sx={{ display: "flex" }}>
        <Heading
          sx={{
            fontSize: "sm",
          }}
        >
          Title:
        </Heading>
        <Box>{details?.title}</Box>
      </Box>
      <Box gap={3} sx={{ display: "flex" }}>
        <Heading
          sx={{
            fontSize: "sm",
          }}
        >
          Degree Of Urgency:
        </Heading>
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {details?.urgency}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Heading
          sx={{
            fontSize: "sm",
            mr: 2,
          }}
        >
          Description:
        </Heading>
        <Box
          sx={{
            fontSize: 14,
            fontWeight: 400,
            "& ul": {
              ml: 3,
            },
          }}
          dangerouslySetInnerHTML={{
            __html: details?.description ? details?.description : "--",
          }}
        />
      </Box>
    </Box>
  );
  const [selectedUserId, setSelectedUserId] = useState<string | string[]>("");
  const [isClearSelectedUser, setIsClearSelectedUser] = useState(false);
  const [selectedDeleteUserId, setSelectedDeleteUserId] = useState("");

  const handleAddNewMember = () => {
    const findUser = _.find(userOptions, (user) => user?.id === selectedUserId);
    const findUserExist = _.find(
      selectedUsers,
      (user) => user.id === selectedUserId
    );
    if (!findUserExist) {
      dispatch(
        updateProject(
          details?.id,
          {
            member: [findUser?.id],
          },
          t("message.addMemberSuccessfully")
        )
      );
    }
    setSelectedUserId("");
    setIsClearSelectedUser(true);
  };

  const selectedUserIds = useMemo(() => {
    return _.map(selectedUsers, "id");
  }, [selectedUsers]);

  const _renderMembersTab = () => (
    <Box>
      <Heading
        sx={{
          fontSize: 22,
          mb: 3,
        }}
      >
        List of members ({selectedUsers?.length})
      </Heading>
      {canCreateMember && (
        <Box
          sx={{
            display: "flex",
            gap: 3,
            alignItems: "center",
            mb: 3,
          }}
        >
          <SelectUser
            payload={userOptions}
            onChangeValue={(userId) => {
              setSelectedUserId(userId);
              setIsClearSelectedUser(false);
            }}
            isClear={isClearSelectedUser}
            disabledIds={selectedUserIds}
            sx={{
              width: isMobile ? "100%" : "50%",
            }}
          />
          <Button
            isDisabled={!selectedUserId}
            size="sm"
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            onClick={handleAddNewMember}
          >
            Add
          </Button>
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {!_.isEmpty(selectedUsers) ? (
            _.map(selectedUsers, (user, index) => {
              const roleNames = _.map(user?.roles, (userRole) =>
                userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                  ? `Staff`
                  : userRole?.role?.name
              );
              const concatenatedRoleNames = roleNames.join(", ");
              return (
                <Box
                  key={index}
                  sx={{
                    boxShadow: shawdowEffects,
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={user.avatar} />
                      <Box>
                        <Heading size="sm">{user.name}</Heading>
                        <Text fontSize="sm">{user.email}</Text>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Text fontSize="sm" textTransform="capitalize">
                        {concatenatedRoleNames}
                      </Text>
                      <Box>
                        {canViewUserProfile && (
                          <Tooltip label={t("tooltip.view")} hasArrow>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              aria-label={""}
                              size="sm"
                              sx={{
                                background: "none",
                              }}
                              onClick={() =>
                                canViewUserProfile &&
                                dispatch(
                                  userPerformAction(
                                    "viewProfile",
                                    user?.id,
                                    user?.user
                                  )
                                )
                              }
                            >
                              <Icon as={EyeIcon} boxSize={5} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {canDeleteMember && (
                          <Tooltip label={t("tooltip.remove")} hasArrow>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              aria-label={""}
                              size="sm"
                              sx={{
                                background: "none",
                              }}
                              onClick={() => setSelectedDeleteUserId(user.id)}
                              //isDisabled={
                              //  !handleCheckPermissionDeleteMember(
                              //    userRoles,
                              //    roleCodes
                              //  )
                              //}
                            >
                              <Icon as={TrashIcon} boxSize={5} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderMain = () => (
    <Stack
      sx={{
        p: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
      ref={containerRef}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="twitter"
            leftIcon={<Icon as={ChevronLeftIcon} />}
            color={"#bb7154"}
            onClick={() => Utils.redirect(Routers.PROJECT)}
          >
            Back to Project
          </Button>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "flex-end",
              }}
            >
              {details?.status === ENUMS.PROJECT_EXTERIOR_STATUS.ACTIVE && (
                <Button
                  //  colorScheme="twitter"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(performAction("logExternal", details?.id));
                  }}
                  sx={{
                    color: "#fff",
                    background: "#5c6e6c",
                    "&:hover": {
                      background: "#a6b7af",
                    },
                  }}
                >
                  Log Time
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <Tabs index={tabIndex} onChange={handleTabsChange} size="sm">
          <TabList
            sx={{
              mx: "auto",
            }}
          >
            <Tab
              sx={{
                px: 2,
                color: CommonColors.mainBackground,
              }}
              isDisabled={isGetLoading}
            >
              Information
            </Tab>
            <Tab
              sx={{
                px: 2,
                color: CommonColors.mainBackground,
              }}
              isDisabled={isGetLoading}
            >
              Members
            </Tab>
            <Tab
              sx={{
                px: 2,
                color: CommonColors.mainBackground,
              }}
              isDisabled={isGetLoading}
            >
              Timeline
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel
              sx={{
                p: 0,
              }}
            >
              <RoundedContainer mt={4}>
                {isGetLoading ? (
                  <Box>{_renderLoading()}</Box>
                ) : (
                  _renderInformationTab()
                )}
              </RoundedContainer>
            </TabPanel>
            <TabPanel
              sx={{
                p: 0,
              }}
            >
              <RoundedContainer mt={4}>
                {isGetLoading ? (
                  <Box>{_renderLoading()}</Box>
                ) : (
                  _renderMembersTab()
                )}
              </RoundedContainer>
            </TabPanel>
            <TabPanel
              sx={{
                p: 0,
              }}
            >
              <RoundedContainer mt={4}>
                <TimelineExterior />
              </RoundedContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {_renderPopup()}
    </Stack>
  );

  return <AdminLayout content={renderMain()} />;
};
export default UpdateExteriorProject;
