import { type ThemeConfig, extendTheme } from "@chakra-ui/react";
import CommonColors from "./CommonColors";

const breakpoints = {
  base: "0em", // 0px
  sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
  md: "48em", // ~768px
  lg: "62em", // ~992px
  xl: "80em", // ~1280px
  "2xl": "96em", // ~1536px
};

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const semanticTokens = {
  colors: {
    error: "red.500",
    success: "green.500",
    primary: {
      default: "red.500",
      _dark: "red.400",
    },
    secondary: {
      default: "red.800",
      _dark: "red.700",
    },
  },
};

const config: ThemeConfig = {
  // ! Only update to "true" with use system color mode if the app has light and dark mode
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  breakpoints,
  semanticTokens,
  colors,
  config,
  styles: {
    global: {
      body: {
        fontFamily: "Arial, sans-serif,'Red Hat Display'",
        background: "#F9FAFF",
        color: "#28282A !important",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar,": {
          height: "5px",
          width: "5px",
          backgroundColor: "rgba(166, 183, 170,0.2) !important",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          backgroundColor: `${CommonColors.burntSienna} !important`,
          outline: "none",
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 5px rgba(187, 113, 84, 1) !important",
        },
        "td > p": {
          fontFamily: "sans-serif !important",
        },
        hr: {
          m: "0!important",
        },
        p: {
          m: "0!important",
        },
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none",
            background: "inherit",
            color: "inherit",
          },
        },
      },
    },
  },
});

export default theme;
