import alert from "./alert.json";
import button from "./button.json";
import table from "./table.json";
import status from "./status.json";
import title from "./title.json";
import label from "./label.json";
import tooltip from "./tooltip.json";
import message from "./message.json";
import menu from "./menu.json";

export default {
  alert,
  button,
  table,
  status,
  title,
  label,
  tooltip,
  message,
  menu,
};
