import _ from "lodash";
import { ActionTypes, Routers } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import { IFilterUser, IUser } from "@/Interfaces/User.interface";
import { IPaginate } from "@/Types/Common.types";

// SINGLE ACTIONS

const setUserFetchLoading = () => {
  return {
    type: ActionTypes.SET_USER_FETCH_LOADING,
  };
};

const setUserGetLoading = () => {
  return {
    type: ActionTypes.SET_USER_GET_LOADING,
  };
};

const setUserActionLoading = () => {
  return {
    type: ActionTypes.SET_USER_ACTION_LOADING,
  };
};

const resetUserReducer = () => {
  return {
    type: ActionTypes.RESET_USER_REDUCER,
  };
};

const resetActionUserReducer = () => {
  return {
    type: ActionTypes.RESET_ACTION_USER_REDUCER,
  };
};

const setMetaUser = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_USER,
    payload,
  };
};

const performAction = (
  actionType: string,
  itemId: string,
  payload?: IUser
) => ({
  type: ActionTypes.USER_PERFORM_ACTION,
  payload: { actionType, itemId, payload },
});

const resetUserPerformAction = () => {
  return {
    type: ActionTypes.RESET_USER_PERFORM_ACTION,
  };
};

const resetUserMessage = () => {
  return {
    type: ActionTypes.RESET_USER_MESSAGE,
  };
};

const resetUserDetail = () => {
  return {
    type: ActionTypes.RESET_USER_DETAIL,
  };
};

const setPaginationUser = (payload: IFilterUser) => {
  return {
    type: ActionTypes.SET_PAGINATION_USER,
    payload,
  };
};

const createUserSuccess = () => {
  return {
    type: ActionTypes.CREATE_USER_SUCCESS,
  };
};

const createUserFail = () => {
  return {
    type: ActionTypes.CREATE_USER_FAILURE,
  };
};

const createNewUser = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.createUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createUserFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createUserSuccess());
          Utils.redirect(Routers.USER);
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createUserFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const fetchUserSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_USER_SUCCESS,
    payload,
  };
};

const fetchUSerFail = () => {
  return {
    type: ActionTypes.FETCH_USER_FAILURE,
  };
};

const fetchUser = (payload: IFilterUser) => {
  return async (dispatch: any) => {
    dispatch(setUserFetchLoading());
    await APIs.fetchUser(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchUSerFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          dispatch(fetchUserSuccess(items));
          dispatch(setMetaUser(meta));
          dispatch(setPaginationUser(payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchUSerFail());
      });
  };
};

const fetchUserForDashboard = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setUserFetchLoading());
    await APIs.fetchAllUsers(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchUSerFail());
        else {
          dispatch(fetchUserSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(fetchUSerFail());
      });
  };
};

const getByIdUserSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_USER_SUCCESS,
    payload,
  };
};

const getByIdUSerFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_USER_FAILURE,
  };
};

const getByIdUser = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setUserGetLoading());
    await APIs.getUserById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdUSerFail());
        else {
          dispatch(getByIdUserSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdUSerFail());
      });
  };
};

const getForSelfSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_FOR_SELF_SUCCESS,
    payload,
  };
};

const getForSelfFail = () => {
  return {
    type: ActionTypes.GET_FOR_SELF_FAILURE,
  };
};

const getForSelf = () => {
  return async (dispatch: any) => {
    dispatch(setUserGetLoading());
    await APIs.getForSelf()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getForSelfFail());
        else {
          dispatch(getForSelfSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getForSelfFail());
      });
  };
};

const updateUserSuccess = (payload?: any) => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
    payload,
  };
};

const updateUserFail = () => {
  return {
    type: ActionTypes.UPDATE_USER_FAILURE,
  };
};

const updateUser = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.updateUser(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateUserFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const getAccountId = Utils.getAccountId();
          const resolveResult: {
            id: string;
          } = result as {
            id: string;
          };
          const userPayload = resolveResult;
          Toast({
            title: message,
            status: "success",
          });
          if (getAccountId === userPayload?.id) {
            //dispatch(updateUserSuccess(getAccountId === userPayload?.id));
            userPayload && Utils.saveUserData(userPayload);
          }
          await dispatch(updateUserSuccess(response?.data?.payload));
        }
      })
      .catch(async (error) => {
        await dispatch(updateUserFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const updateForSelfSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_FOR_SELF_SUCCESS,
    payload,
  };
};

const updateForSelfFail = () => {
  return {
    type: ActionTypes.UPDATE_FOR_SELF_FAILURE,
  };
};

const updateForSelf = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.updateForSelf(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await Toast({
            title: message,
            status: "error",
          });
          await dispatch(updateForSelfFail());
        } else {
          dispatch(updateForSelfSuccess(result));
          const resolveResult: {
            id: string;
          } = result as {
            id: string;
          };
          const userPayload = resolveResult;
          userPayload && Utils.saveUserData(userPayload);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateForSelfFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updatePasswordSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_FOR_SELF_SUCCESS,
    payload,
  };
};

const updatePasswordfFail = (payload: string) => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_FOR_SELF_FAILURE,
    payload,
  };
};

const updatePasswordForSelf = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.updatePasswordForSelf(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updatePasswordfFail(message));
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updatePasswordSuccess(result));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updatePasswordfFail(error));
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const activateUserSuccess = () => {
  return {
    type: ActionTypes.ACTIVATE_USER_SUCCESS,
  };
};

const activateUserFail = () => {
  return {
    type: ActionTypes.ACTIVATE_USER_FAILURE,
  };
};

const activateUser = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.activateUser(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(activateUserFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(activateUserSuccess());
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(activateUserFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deactivateUserSuccess = () => {
  return {
    type: ActionTypes.DEACTIVATE_USER_SUCCESS,
  };
};

const deactivateUserFail = () => {
  return {
    type: ActionTypes.DEACTIVATE_USER_FAILURE,
  };
};

const deactivateUser = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.deactivateUser(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deactivateUserFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deactivateUserSuccess());
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deactivateUserFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteUserSuccess = () => {
  return {
    type: ActionTypes.DELETE_USER_SUCCESS,
  };
};

const deleteUserFail = () => {
  return {
    type: ActionTypes.DELETE_USER_FAILURE,
  };
};

const deleteUser = (id: string, pagination: IFilterUser) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading());
    await APIs.deleteUser(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteUserFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteUserSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchUser(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(deleteUserFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  resetUserReducer,
  createNewUser,
  fetchUser,
  getByIdUser,
  getForSelf,
  updateUser,
  updateForSelf,
  activateUser,
  deactivateUser,
  updatePasswordForSelf,
  deleteUser,
  resetActionUserReducer,
  performAction,
  resetUserPerformAction,
  resetUserMessage,
  resetUserDetail,
  fetchUserForDashboard,
};
