import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { TIMEKEEPING } = APIs;

export const fetchTimekeeping = async (payload: any) => {
  return request(TIMEKEEPING.BASIC, "GET", payload);
};

export const getTimekeepingById = async (id: string) => {
  return request(`${TIMEKEEPING.GET_BY_ID}/${id}`, "GET");
};

export const createTimekeeping = async (payload: FormData) => {
  return request(TIMEKEEPING.BASIC, "POST_FORM_DATA", payload);
};

export const updateTimekeeping = async (id: string, payload: any) => {
  return request(`${TIMEKEEPING.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const updateTimekeepingAdmin = async (payload: FormData) => {
  return request(TIMEKEEPING.UPDATE, "POST_FORM_DATA", payload);
};
