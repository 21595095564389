import CommonColors from "@/Themes/CommonColors";
import { keyframes } from "@emotion/react";
//import { keyframes, SxProps, Theme } from "@mui/system";

const desktopEffect = keyframes`
  0% {
    width: 80px;
    height: 100px;
  }
  50% {
    width: 150px;
    height: 100px;
  }
  85% {
    width: 150px;
    height: 100px;
  }
  100% {
    width: 80px;
    height: 100px;
  }
`;

const mobileEffect = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
   transform: rotate(90deg);
  }
  85% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const mobileEffectScreen = keyframes`
  0% {
    transform: rotate(55deg)
  }
  100% {
    transform: rotate(135deg);
  }
`;

// Define the animation styles using makeStyles
export const rotateIcon = {
  fontSize: "10px",
  margin: "10px",
  width: "50px",
  animation: `${mobileEffectScreen} 2s infinite`,
};

export const mainStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "black",
  zIndex: 99999,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const labelStyles = {
  color: CommonColors.mystic,
  textAlign: "center",
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

export const guideResizeStyles = {
  color: CommonColors.mystic,
  textAlign: "center",
  fontSize: "30px",
};
export const mobileStyles = {
  width: "50px",
  height: "90px",
  background: CommonColors.mystic,
  borderRadius: "3px",
  position: "relative",
  transformOrigin: "center center",
  animation: `${mobileEffect} 3000ms ease-in-out infinite`,

  "&:before": {
    content: '""',
    position: "absolute",
    background: "black",
    width: "90%",
    height: "80%",
    borderRadius: "3px",
    bottom: "10%",
    left: "5%",
  },
};

export const desktopStyles = {
  width: "80px",
  height: "100px",
  background: CommonColors.mystic,
  borderRadius: "3px",
  position: "relative",
  animation: `${desktopEffect} 3000ms ease-in-out infinite`,

  "&:before": {
    content: '""',
    position: "absolute",
    background: "black",
    width: "90%",
    height: "80%",
    borderRadius: "3px",
    bottom: "5%",
    left: "5%",
  },
};
