import {
  TextField,
  UploadAvatar,
  UploadFileInput,
  DatePicker,
  Select,
  RoundedContainer,
} from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import { AdminLayout } from "@/Components/DefaultLayout";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  useBreakpoint,
  Icon,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { RoleActions, UserActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const { createNewUser } = UserActions;
const { fetchRoles } = RoleActions;

const DEFAULT_FORM_DATA = {
  email: "",
  roleCode: "",
  phoneNumber: "",
  lastName: "",
  firstName: "",
  address: "",
  bankAccountNumber: "",
  bankName: "",
  insuranceCompany: "",
  contractTerm: "",
  officialWorkingDay: "",
  probationWorkingDay: "",
  extraData: "",
  gender: "",
  dob: "",
  probationEndDay: "",
  bankAccountName: "",
};

const CreateUser = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const [avatar, setAvatar] = React.useState<File[]>([]);
  const [files, setFiles] = React.useState<any[]>([]);

  const breakpoints = {
    base: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    "2xl": 3,
  };

  const currentBreakpoint = useBreakpoint();

  const roles: any = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );
  const isLoading: any = useSelector((state: RootState) =>
    _.get(state.USER, "isActionLoading")
  );

  const roleOptions = React.useMemo(
    () =>
      _.map(
        roles.filter((item: any) => item?.roleCode !== "admin"),
        (role: any) => ({
          label: role.name === "artist" ? "Staff" : _.startCase(role.name),
          value: role.roleCode,
        })
      ),
    [roles]
  );

  const columns =
    breakpoints[currentBreakpoint as keyof typeof breakpoints] || 1;

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .email(t("message.emailMustBeAValidEmail"))
        .required(t("message.emailIsRequired")),
      firstName: yup.string().trim().required(t("message.firtsNameIsRequired")),
      lastName: yup.string().trim().required(t("message.lastNameIsRequired")),
      roleCode: yup.string().trim().required(t("message.roleIsRequired")),
      phoneNumber: yup.string().trim(),
      gender: yup.string().trim(),
      dob: yup.string().trim(),
      insuranceCompany: yup.string().trim(),
      probationWorkingDay: yup.string().trim(),
      officialWorkingDay: yup.string().trim(),
      contractTerm: yup.string().trim(),
      bankAccountNumber: yup.string().trim(),
      bankName: yup.string().trim(),
      address: yup.string().trim(),
      probationEndDay: yup.string().trim(),
      bankAccountName: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    //setValue,
    //getValues,
    //reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();
    //if (!isAcceptEditAttachments) return;
    if (files) {
      files.forEach((file: any) => {
        if (file.valid) {
          formData.append("userData[fileAttachment]", file.file);
        }
      });
    }
    if (!_.isEmpty(data.dob))
      formData.append(
        "userData[dob]",
        dayjs(data.dob).isValid() ? dayjs(data?.dob).format("YYYY-MM-DD") : ""
      );
    if (!_.isEmpty(data.officialWorkingDay))
      formData.append(
        "userData[officialWorkingDay]",
        dayjs(data.officialWorkingDay).isValid()
          ? dayjs(data.officialWorkingDay).format("YYYY-MM-DD")
          : ""
      );
    if (!_.isEmpty(data.probationWorkingDay))
      formData.append(
        "userData[probationWorkingDay]",
        dayjs(data.probationWorkingDay).isValid()
          ? dayjs(data.probationWorkingDay).format("YYYY-MM-DD")
          : ""
      );
    if (!_.isEmpty(avatar)) formData.append("userData[avatar]", avatar[0]);
    formData.append("email", data.email);
    formData.append("userData[firstName]", data.firstName);
    formData.append("userData[lastName]", data.lastName);
    formData.append("roleCode", data.roleCode);
    formData.append(
      "userData[phoneNumber]",
      !_.isEmpty(data.phoneNumber) ? data.phoneNumber : ""
    );
    formData.append(
      "userData[bankAccountNumber]",
      !_.isEmpty(data.bankAccountNumber) ? data.bankAccountNumber : ""
    );
    formData.append(
      "userData[bankAccountName]",
      !_.isEmpty(data.bankAccountName) ? data.bankAccountName : ""
    );
    formData.append(
      "userData[bankName]",
      !_.isEmpty(data.bankName) ? data.bankName : ""
    );
    formData.append(
      "userData[insuranceCompany]",
      !_.isEmpty(data.insuranceCompany) ? data.insuranceCompany : ""
    );
    formData.append(
      "userData[contractTerm]",
      dayjs(data.contractTerm).isValid()
        ? dayjs(data.contractTerm).format("YYYY-MM-DD")
        : ""
    );

    formData.append(
      "userData[gender]",
      !_.isEmpty(data.gender) ? data.gender : ""
    );
    formData.append(
      "userData[address]",
      !_.isEmpty(data.address) ? data.address : ""
    );
    if (data.probationEndDay)
      formData.append(
        "userData[probationEndDay]",
        dayjs(data.probationEndDay).isValid()
          ? dayjs(data.probationEndDay).format("YYYY-MM-DD")
          : ""
      );

    dispatch(createNewUser(formData));
  };

  React.useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (_.isEmpty(roles) && accessRouter) dispatch(fetchRoles());
  }, []);

  const _renderBottom = () => {
    return (
      <Box>
        <Button
          size="sm"
          onClick={() => Utils.redirect(Routers.USER)}
          isLoading={isLoading}
          leftIcon={<Icon as={ArrowBackIcon} boxSize={3} />}
          // colorScheme="red"
        >
          {t("button.back")}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          ml={3}
          size="sm"
          //  colorScheme="twitter"
          isLoading={isLoading}
          rightIcon={<Icon as={AddIcon} boxSize={3} />}
          sx={{
            color: "#fff",
            background: "#5c6e6c",
            "&:hover": {
              background: "#a6b7af",
            },
          }}
        >
          {t("button.create")}
        </Button>
      </Box>
    );
  };

  const _renderForm = () => {
    return (
      <RoundedContainer>
        <Box mb={4}></Box>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3}>
          <GridItem>
            <Stack direction="column" alignItems="center" rowGap={2}>
              <UploadAvatar
                direction="row"
                labelSx={{ minWidth: "250px" }}
                containerSx={{ mb: 2 }}
                files={avatar}
                onFileChange={(newFile) => setAvatar(newFile)}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder="Email address"
                    value={field.value}
                    label="Email"
                    onChange={(e: any) => field.onChange(e)}
                    isError={!_.isEmpty(errors.email?.message)}
                    errorMessage={errors.email?.message}
                    isRequired
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.firstName")}
                    label={t("label.firstName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.firstName?.message}
                    isError={!_.isEmpty(errors.firstName?.message)}
                    isRequired
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.lastName")}
                    label={t("label.lastName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.lastName?.message}
                    isError={!_.isEmpty(errors.lastName?.message)}
                    isRequired
                  />
                )}
              />
              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    maxDate={new Date()}
                    value={field.value}
                    onDateChange={(newDate) => field.onChange(newDate)}
                    label={t("label.birthday")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    size="sm"
                    label={t("label.gender")}
                    //  placeholder="Gender"
                    options={[
                      { label: "", value: "" },
                      { label: t("label.male"), value: "male" },
                      { label: t("label.female"), value: "female" },
                      { label: t("label.other"), value: "other" },
                    ]}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.phoneNumber")}
                    label={t("label.phoneNumber")}
                    type="number"
                    phoneInputvalue={field.value}
                    onPhoneInputChange={(e: any) => field.onChange(e)}
                    autoComplete="false"
                    // onWheel={() =>
                    //   (document.activeElement as HTMLElement).blur()
                    // }
                    isPhoneInput
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextareaComponent
                    placeholder={t("label.address")}
                    label={t("label.address")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    //isError={!isEmpty(errors.fullName?.message)}
                    size="sm"
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Text fontSize="18px" fontWeight={600}>
                {t("title.positionInformations")}
              </Text>
              <Controller
                name="roleCode"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    onChange={(e: any) => field.onChange(e.target.value)}
                    isRequired
                    size="sm"
                    label={t("label.role")}
                    placeholder={t("label.role")}
                    options={roleOptions}
                    isError={!!errors?.roleCode?.message}
                    errorMessage={errors?.roleCode?.message}
                  />
                )}
              />
              <TextField
                disabled
                placeholder={t("label.staffCode")}
                label={t("label.staffCode")}
                size="sm"
              />
              <Controller
                name="probationWorkingDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate) => field.onChange(newDate)}
                    label={t("label.probationWorkingDay")}
                  />
                )}
              />
              <Controller
                name="probationEndDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate) => field.onChange(newDate)}
                    label={t("label.probationEndDay")}
                  />
                )}
              />
              <Controller
                name="officialWorkingDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate) => field.onChange(newDate)}
                    label={t("label.officialWorkingDay")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="contractTerm"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate) => field.onChange(newDate)}
                    label={t("label.contractExpirationDate")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="insuranceCompany"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.insurancePlace")}
                    label={t("label.insurancePlace")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="bankName"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.bankName")}
                    label={t("label.bankName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="bankAccountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.bankAccountNumber")}
                    label={t("label.bankAccountNumber")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="bankAccountName"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.bankAccountName")}
                    label={t("label.bankAccountName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
            </Stack>
          </GridItem>
        </Grid>
        <GridItem>
          <Text fontSize="18px" fontWeight={600}>
            {t("label.personalDocumentations")}
          </Text>
          <UploadFileInput
            accept="image/*, video/*, application/pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx"
            //files={files || []}
            isEditing={true}
            sx={{ mb: 3 }}
            onFilesChange={(file: any) => {
              setFiles(file);
            }}
            maxSize={2 * 1024 * 1024} //2 MB
            clearIcon
            maxFiles={10}
          />
        </GridItem>
        <Box sx={{ display: "flex", justifyContent: "end", my: 5 }}>
          {_renderBottom()}
        </Box>
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    return (
      <Box
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5c6e6c"}>
          {t("title.createUser")}
        </Heading>
        <Box as={"form"}>{_renderForm()}</Box>
      </Box>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default CreateUser;
