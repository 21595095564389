import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import Utils from "@/Utils";

import {
  Box,
  HStack,
  Stack,
  Heading,
  Icon,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  EyeSlashIcon,
  EyeIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { Button, TextField } from "@/Components/Common";

import { Routers } from "@/Constants";
import { AuthenticationActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useWindowWidth } from "@/Helpers";

import backgroundImage_bigSize from "@/Assets/Login.jpg";
import logo from "@/Assets/cc-logo-white.svg";

const { resetPassword } = AuthenticationActions;

const DEFAULT_FORM = {
  email: "",
  newPassword: "",
  confirmationNewPassword: "",
  code: "",
};

const ResetPassword: React.FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const getToken = Utils.getSavedToken();
  const queryParams = useLocation().search;

  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth >= 1280;

  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.AUTH, "requestIsLoading")
  );

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .email("Email must be a valid email")
        .required("Email is required"),
      code: yup.string().required("Code is required"),
      newPassword: yup
        .string()
        .trim()
        .required("New password is required")
        .test(
          "length",
          "Password must be between 6 and 30 characters long!",
          (value) => {
            if (value) {
              return value.length >= 6 && value.length <= 30;
            }
            return true;
          }
        ),
      confirmationNewPassword: yup
        .string()
        .trim()
        .oneOf([yup.ref("newPassword"), ""], "Passwords do not match")
        .required("Confirm new password is required"),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM,
  });

  useEffect(() => {
    if (getToken) navigate(Routers.DASHBOARD);
    const params = _.split(queryParams, "&");
    const email = _.replace(params[0], "?e=", "");
    const code = _.replace(params[1], "c=", "");
    if (email) setValue("email", email);
    if (code) setValue("code", code);
  }, []);

  const onSubmit = (data: any) => {
    dispatch(resetPassword(data));
  };

  const _renderForm = () => (
    <Stack
      p={[5, 5, 5, 5, 5, 10, 12]}
      borderRightRadius={10}
      background="white"
      justifyContent="space-between"
    >
      <Stack
        mb={[2, 2, 2, 2, 2, 5]}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={logo} sx={{ w: 50, height: 50 }} />
        <Heading fontSize="24px" fontWeight={600} color="#000">
          Workspace
        </Heading>
      </Stack>
      <Stack
        direction="column"
        sx={{
          width: "100%",
          rowGap: ["0.7em", "0.7em", "0.7em", "0.7em", "0.7em", "1em"],
          my: "auto",
        }}
      >
        <Text
          fontSize={["1.4rem", "1.6rem", "1.8rem", "2rem"]}
          fontWeight={600}
          color="#000"
          textAlign="center"
        >
          Reset Password
        </Text>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              label="E-mail Address"
              placeholder="Email"
              type="email"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              onEnter={handleSubmit(onSubmit)}
              isError={!_.isEmpty(errors.email?.message)}
              errorMessage={errors.email?.message}
              isRequired
            />
          )}
        />

        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              label="Code"
              placeholder="Code"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              onEnter={handleSubmit(onSubmit)}
              isError={!_.isEmpty(errors.code?.message)}
              errorMessage={errors.code?.message}
              isRequired
            />
          )}
        />

        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <TextField
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              label="New Password"
              placeholder="New Password"
              value={field.value}
              type={isShowPassword ? "text" : "password"}
              onChange={(e) => field.onChange(e.target.value)}
              isError={!_.isEmpty(errors.newPassword?.message)}
              errorMessage={errors.newPassword?.message}
              rightElement={
                !isShowPassword ? (
                  <EyeSlashIcon
                    width={22}
                    onClick={() => setIsShowPassword(true)}
                  />
                ) : (
                  <EyeIcon
                    width={22}
                    onClick={() => setIsShowPassword(false)}
                  />
                )
              }
              isRequired
            />
          )}
        />

        <Controller
          name="confirmationNewPassword"
          control={control}
          render={({ field }) => (
            <TextField
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              label="Confirm New Password"
              placeholder="Confirm New Password"
              value={field.value}
              type={isShowConfirmPassword ? "text" : "password"}
              onChange={(e) => field.onChange(e.target.value)}
              isError={!_.isEmpty(errors.confirmationNewPassword?.message)}
              errorMessage={errors.confirmationNewPassword?.message}
              rightElement={
                !isShowConfirmPassword ? (
                  <EyeSlashIcon
                    width={22}
                    onClick={() => setIsShowConfirmPassword(true)}
                  />
                ) : (
                  <EyeIcon
                    width={22}
                    onClick={() => setIsShowConfirmPassword(false)}
                  />
                )
              }
              isRequired
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 5,
            gap: 3,
          }}
        >
          <Button
            variant="text"
            sx={{
              flex: 1,
            }}
            leftIcon={<Icon as={ChevronLeftIcon} />}
            onClick={() => Utils.redirect(Routers.SIGN_IN)}
            size={["sm", "sm", "sm", "sm", "sm", "md"]}
          >
            Back to login
          </Button>
          <Button
            sx={{
              bgColor: "#212B36",
              color: "#FFFFFF",
              flex: 1,
            }}
            _hover={{ bgColor: "none" }}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            size={["sm", "sm", "sm", "sm", "sm", "md"]}
          >
            Confirm
          </Button>
        </Box>
      </Stack>
    </Stack>
  );

  const _renderMain = () => (
    <HStack
      w="100vw"
      h="100vh"
      maxH="100vh"
      gap={0}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("${backgroundImage_bigSize}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "blur(7px)",
          position: "absolute",
          top: 0,
          left: 0,
          w: "100%",
          h: "100%",
          zIndex: -1,
        }}
      />
      <Grid
        gridTemplateColumns={isDesktop ? `2fr 1fr` : "1fr"}
        minH="50%"
        w={{
          base: "90%",
          sm: "90%",
          md: "90%",
          lg: "90%",
          xl: "85%",
          "2xl": "80%",
        }}
        p={1.5}
        borderRadius={10}
        background="rgba(225,225,225,0.7)"
      >
        {isDesktop && (
          <Box
            borderLeftRadius={10}
            sx={{
              backgroundImage: `url("${backgroundImage_bigSize}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              top: 0,
              left: 0,
              w: "100%",
              h: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;",
            }}
          />
        )}
        {_renderForm()}
      </Grid>
    </HStack>
  );

  return (
    <HStack w="100vw" h="100vh" maxH="100vh">
      {_renderMain()}
    </HStack>
  );
};

export default ResetPassword;
