import Cookies from "universal-cookie";
import { cookie_keys } from "@/Constants";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

const saveToken = (token: string, isRemember?: boolean) => {
  // const expirationDate = new Date();
  // expirationDate.setDate(expirationDate.getDate() + 7); // 7 days from now
  cookies.set(cookie_keys.SAVED_SECURE_TOKEN, token, {
    path: "/",
    // expires: expirationDate,
  });
  const decodedToken: any = jwt_decode(token);
  const expires = new Date(decodedToken.exp * 1000);

  if (isRemember)
    cookies.set(cookie_keys.SAVED_SECURE_TOKEN, token, {
      path: "/",
      expires,
    });
  else
    cookies.set(cookie_keys.SAVED_SECURE_TOKEN, token, {
      path: "/",
    });
};

const saveRefreshToken = (refreshToken: string) => {
  const decodedRefreshToken: any = jwt_decode(refreshToken);
  const expires = new Date(decodedRefreshToken.exp * 1000);
  cookies.set(cookie_keys.SAVED_SECURE_REFRESH_TOKEN, refreshToken, {
    path: "/",
    expires,
  });
};

const getSavedToken = () => {
  const token = cookies.get(cookie_keys.SAVED_SECURE_TOKEN);
  return token;
};

const getSavedRefreshToken = () => {
  const refreshToken = cookies.get(cookie_keys.SAVED_SECURE_REFRESH_TOKEN);
  return refreshToken;
};

const saveUserData = (userData: any) => {
  localStorage.setItem(cookie_keys.SAVED_USER_DATA, JSON.stringify(userData));
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(cookie_keys.SAVED_USER_DATA);
  if (userData && userData !== "undefined") return JSON.parse(userData);
  return null;
};

const getFileDataFromStorage = () => {
  const fileData = localStorage.getItem("FileDataOpenInEditor");
  if (fileData) return JSON.parse(fileData);
  return fileData;
};

const getFileFromURL = async (url: string, filename: string) => {
  const resolveUrl = url.replace(/\\/g, "/").replace("src/storage/file", "");
  const blob = await fetch(resolveUrl, {}).then((r) => r.blob());
  const file = new File([blob], filename);
  return file;
};

const saveFirebaseToken = (token: string) => {
  localStorage.setItem(cookie_keys.SAVED_FIREBASE_TOKEN, token);
};

const getSavedFirebaseToken = () => {
  const token = localStorage.getItem(cookie_keys.SAVED_FIREBASE_TOKEN);
  if (token && token !== "undefined") return token;
  return null;
};

const clearAllSavedData = async () => {
  cookies.remove(cookie_keys.SAVED_SECURE_TOKEN, { path: "/" });
  cookies.remove(cookie_keys.SAVED_SECURE_REFRESH_TOKEN, { path: "/" });
  // localStorage.removeItem(cookie_keys.SAVED_FIREBASE_TOKEN);
  localStorage.removeItem("isCheckedIn");
  localStorage.removeItem("isCheckedInAfternoon");
  localStorage.removeItem(cookie_keys.SAVED_USER_DATA);
};

export {
  saveToken,
  getSavedToken,
  saveUserData,
  getSavedUserData,
  clearAllSavedData,
  saveRefreshToken,
  getSavedRefreshToken,
  getFileDataFromStorage,
  getFileFromURL,
  saveFirebaseToken,
  getSavedFirebaseToken,
};
