import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { WORKING_TIME } = APIs;

export const fetchWorkingTime = async (payload: any) => {
  return request(WORKING_TIME.BASIC, "GET", payload);
};
export const updateWorkingTime = async (id: string, payload: any) => {
  return request(`${WORKING_TIME.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};
export const getWorkingTimeById = async (id: string) => {
  return request(`${WORKING_TIME.GET_BY_ID}/${id}`, "GET");
};
