import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { PERFORMANCE } = APIs;

export const fetchPerformances = async (payload?: any) => {
  return await request(PERFORMANCE.BASIC, "GET", payload);
};

export const getPerformanceById = async (id: string) => {
  return await request(`${PERFORMANCE.GET_BY_ID}/${id}`, "GET");
};

export const updatePerformance = async (id: string, payload: any) => {
  return await request(`${PERFORMANCE.GET_BY_ID}/${id}`, "PUT", payload);
};

export const createPerformance = async (payload: any) => {
  return await request(PERFORMANCE.BASIC, "POST", payload);
};
