import { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import { Box, Heading, Text, Tooltip, Stack, Checkbox } from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import CommonColors from "@/Themes/CommonColors";
import dayjs from "dayjs";
import { ContentWithMaxLines, RoundedContainer } from "@/Components/Common";
import { LogtimeActions } from "@/Actions";
import { ENUMS } from "@/Constants";
import Utils from "@/Utils";

const { performAction, updateLogtime } = LogtimeActions;

interface ISectionProps {}

const Calendar: React.FC<ISectionProps> = ({}) => {
  const dispatch = useTypedDispatch();
  const userLogged = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userLogged?.userRole, (userRole) => userRole?.role?.roleCode),
    []
  );

  const details: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "details")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "isGetLoading")
  );

  const [events, setEvents] = useState<any[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [daysInMonth, setDaysInMonth] = useState<any[]>([]);
  const [userColors, setUserColors] = useState<any>({});

  const calendarRef = useRef<FullCalendar>(null);

  const canlendarStyles = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      gap: 3,
      ".fc-datagrid-cell-cushion": {
        minH: 45,
      },
      ".fc .fc-scrollgrid-section-header > *": {
        borderBottomWidth: "1px!important",
      },
      ".fc.fc-media-screen.fc-direction-ltr.fc-theme-standard": {
        overflow: "auto",
        minWidth: "800px",
        //minHeight: "calc(100vh - 500px)", // header size
        maxHeight: "calc(100vh - 180px)",
        px: "1rem",
      },
      ".fc .fc-view-harness ": {
        overflow: "auto",
      },

      ".fc-button": {
        background: "transparent",
        outline: "none",
        boxShadow: "none !important",
        border: `2px solid ${CommonColors.burntSienna}`,
        color: CommonColors.burntSienna,
        fontSize: "14px",
        "&:hover": {
          background: "#E87844",
          border: `2px solid ${CommonColors.burntSienna}`,
          color: "#ffffff",
        },
      },

      ".fc-toolbar-title, .fc-button": {
        padding: "4px 10px",
        textTransform: "capitalize",
      },

      ".fc-today-button, .fc-button.fc-button-primary": {
        // background: `${CommonColors.LightSkyBlue3}!important`,
        "&.fc-button-active": {
          background: `#E87844 !important`,
          border: `2px solid ${CommonColors.burntSienna}`,
          color: "#ffffff",
          fontWeight: 700,
        },
        "&.fc-next-button, &.fc-button[title='month view']": {
          borderLeft: `1px solid ${CommonColors.burntSienna}`,
        },
        "&.fc-prev-button, &.fc-button[title='week view']": {
          borderRight: `1px solid ${CommonColors.burntSienna}`,
        },
      },
      //"& h2.chakra-heading.css-1r8joen": {
      //  background: "#d38d87 !important",
      //},
      "& a.fc-event": {
        //  borderColor: "#fff",
        border: "2px solid #fff",
      },
      ".fc-button-primary:not(:disabled)": {
        background: "unset !important",
        borderColor: "#e87844 !important",
        color: "#e87844",
      },
      ".fc-button:hover": {
        color: "#ffffff!important",
        background: "#E87844 !important",
      },
      "button.fc-today-button.fc-button.fc-button-primary": {
        color: "#ffffff!important",
        background: "#E87844 !important",
        borderColor: "#e87844 !important",
      },
      "td.fc-timeline-slot.fc-timeline-slot-lane.fc-day,th.fc-timeline-slot.fc-timeline-slot-label.fc-day":
        {
          //  width: "200px!important",
        },
      width: "100%",
      overflow: "auto",
      "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-today,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-today":
        {
          background: "#E2E8F0",
        },
      ".custom-header .fc-datagrid-table tbody tr": {
        marginBottom: "30px",
      },
      ".custom-header  .fc-resource": {
        paddingBottom: "50px!important",
      },
      "span.fc-datagrid-cell-main": {
        fontWeight: 700,
      },
      ".css-ci76py": { fontWeight: 600 },
      ".fc .fc-scroller-harness": {
        //overflow: "visible",
      },
      //  ".fc .fc-scroller": {
      //    overflow: "visible!important",
      //  },
    }),
    []
  );

  useEffect(() => {
    const daysInMonth = getDaysInCurrentMonth();
    setDaysInMonth(daysInMonth);
  }, []);

  useEffect(() => {
    let projectColors: any = {};
    let count = 0;
    _.forEach(details.logTime, (item: any) => {
      const colors = [
        "#5C6E6C", // balsamGreen
        "#A6B7AA", // aquatone
        "#D2A96A", // artermis
        "#D39D87", // dustyCoral
        "#BB7154", // warmCopper
      ];
      const userID = item?.user?.id;

      if (!projectColors[userID]) {
        count += 1;
        const randomColor = colors[count];
        projectColors[userID] = {
          color: randomColor,
        };
        setUserColors(projectColors);
      }
    });
  }, [details, daysInMonth]);

  useEffect(() => {
    const addedUsers = new Set();
    let eventDataInternal: any[] = [];
    let eventDataExternal: any[] = [];
    let resourceData: any[] = [];
    _.forEach(details?.logTime, (item: any) => {
      if (!addedUsers.has(item?.user?.id)) {
        resourceData.push({
          id: item?.user?.id,
          title: item?.user?.userData?.fullName,
        });
        addedUsers.add(item?.user?.id);
      }
      if (item?.timeType === "over time") {
        const startDateOvertime = new Date(`${item?.date}T${item?.startTime}`);
        const endDateOvertime = new Date(`${item?.date}T${item?.endTime}`);
        let event = {
          id: item?.id,
          //  title: findUser?.userData?.fullName,
          start: startDateOvertime,
          end: endDateOvertime,
          resourceId: item?.user?.id,
          extendedProps: {
            ...item,
            resourceId: item?.user?.id,
            backgroundColor: userColors[item?.user?.id]?.color,
            type: "log",
          },
          backgroundColor: userColors[item?.user?.id]?.color,
          //  borderColor: "transparent",
        };
        eventDataInternal.push(event);
      } else {
        const startDate = new Date(
          `${item?.workTimeStartDate}T${item?.workTimeStartTime}`
        );
        const endDate = new Date(
          `${item?.workTimeEndDate}T${item?.workTimeEndTime}`
        );

        let event = {
          id: item?.id,
          //  title: findUser?.userData?.fullName,
          start: startDate,
          end: endDate,
          resourceId: item?.user?.id,
          extendedProps: {
            ...item,
            resourceId: item?.user?.id,
            backgroundColor: userColors[item?.user?.id]?.color,
            type: "log",
          },
          backgroundColor: userColors[item?.user?.id]?.color,
          //  borderColor: "transparent",
        };
        eventDataInternal.push(event);
      }
    });
    setEvents(eventDataExternal.concat(eventDataInternal));
    setResources(resourceData);
  }, [details, daysInMonth, userColors]);

  const handleCheckPermissions = (
    currentUserRoles: string[],
    targetUserRoles: string[]
  ): boolean => {
    const isCurrentAdmin = currentUserRoles.includes(ENUMS.ROLES.ADMIN);
    const isCurrentManager = currentUserRoles.includes(ENUMS.ROLES.MANAGER);
    const isCurrentLeader = currentUserRoles.includes(ENUMS.ROLES.LEADER);
    let allowed = false;

    if (isCurrentAdmin) return true;

    if (
      isCurrentManager &&
      !targetUserRoles.includes(ENUMS.ROLES.MANAGER) &&
      !targetUserRoles.includes(ENUMS.ROLES.ADMIN)
    )
      allowed = true;

    if (
      isCurrentLeader &&
      !targetUserRoles.includes(ENUMS.ROLES.ADMIN) &&
      !targetUserRoles.includes(ENUMS.ROLES.LEADER) &&
      !targetUserRoles.includes(ENUMS.ROLES.MANAGER) &&
      !targetUserRoles.includes(ENUMS.ROLES.MARKETING)
    )
      allowed = true;
    return allowed;
  };

  const getDaysInCurrentMonth = () => {
    const currentMonth = dayjs().startOf("month");
    const daysInMonth = [];
    for (
      let date = currentMonth;
      date.isBefore(currentMonth.endOf("month"));
      date = date.add(1, "day")
    ) {
      daysInMonth.push(date.format("YYYY-MM-DD"));
    }
    return daysInMonth;
  };

  const handleDatesSet = (dateInfo: DatesSetArg) => {
    const daysInMonth = [];
    const startDate = dayjs(dateInfo.start);
    const endDate = dayjs(dateInfo.end);
    for (
      let date = startDate;
      date.isBefore(endDate);
      date = date.add(1, "day")
    )
      daysInMonth.push(date.format("YYYY-MM-DD"));
    setDaysInMonth(daysInMonth);
  };

  const handleChangeLogStatus = (logId: string, status: boolean) => {
    dispatch(
      updateLogtime(logId, { isDone: status }, "", "", false, "", details.id)
    );
  };

  const _renderElementTooltip = (arg: any) => {
    const log = arg?.extendedProps;

    return (
      <Stack
        sx={{
          w: "max-content",
        }}
      >
        <Stack direction="column">
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              Project Exterior:
            </Text>
            <Text fontSize="xs">{details?.title}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              Start Time:
            </Text>
            <Text fontSize="xs">
              {log.timeType === "over time"
                ? `${dayjs(log?.date).format("DD/MM/YYYY")}${" "}${
                    log?.startTime
                  }`
                : `${log?.workTimeStartDate} ${" "}${log?.workTimeStartTime}`}
            </Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              End Time:
            </Text>
            <Text fontSize="xs">
              {log?.timeType === "over time"
                ? `${dayjs(log?.date).format("DD/MM/YYYY")}${" "}${
                    log?.endTime
                  }`
                : `${log?.workTimeEndDate} ${" "}${log?.workTimeEndTime}`}
            </Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              Assigned:
            </Text>
            <Text fontSize="xs">{log?.user?.userData?.fullName || "--"}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              Working Time:
            </Text>
            <Text fontSize="xs">{log?.workingTime}h</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              Description:
            </Text>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
                fontSize: 12,
              }}
              dangerouslySetInnerHTML={{
                __html: log?.description ? log?.description : "--",
              }}
            />
          </Box>
        </Stack>
      </Stack>
    );
  };

  // handle tooltip event detail
  const _renderEventContent = (eventInfo: EventContentArg) => {
    const { event } = eventInfo;
    const log = event?.extendedProps;
    const canUpdateLogStatus =
      handleCheckPermissions(
        userRoles,
        _.map(log?.user?.userRole, (userRole) => userRole?.role?.roleCode)
      ) || userLogged?.id === log?.user?.id;

    const isVirtualEvent = _.includes(event?.id, "virtual_event");
    return (
      <Tooltip
        label={_renderElementTooltip(event)}
        hasArrow
        maxW="500px"
        minW={250}
      >
        <Box
          sx={{
            cursor: "pointer",
            overflow: "hidden",
            visibility: isVirtualEvent ? "hidden" : "visible",
            background: log?.backgroundColor,
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(performAction("editLog", log?.id));
          }}
        >
          <Heading
            sx={{
              color: "white",
              fontSize: "12px",
              p: 1,
              boxShadow: "10px 10px",
              display: "flex",
              fontWeight: 600,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                width: "calc(100% - 25px)",
              }}
            >
              {log?.timeType === "over time" && (
                <Text
                  fontSize="sm"
                  sx={{
                    minW: "max-content",
                    fontSize: 12,
                  }}
                >
                  {`${"OT |"}`}
                </Text>
              )}

              <ContentWithMaxLines
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  WebkitBoxOrient: "inherit",
                }}
                htmlContent={log?.description}
              />
              <div>|</div>
              <Text fontSize={"12"}> {`${log?.workingTime}H`}</Text>
            </Box>
            {log.timeType !== "over time" && canUpdateLogStatus && (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  ml: "auto",
                  position: "absolute",
                  right: 1,
                  top: "50%",
                  zIndex: 2,
                  transform: "translateY(-50%)",
                  background: "rgb(225, 248, 225)",
                  "label.chakra-checkbox": {
                    borderColor: "#38A169",
                  },
                }}
              >
                {
                  <Checkbox
                    isChecked={log?.isDone}
                    onChange={(e) => {
                      handleChangeLogStatus(log?.id, e.target.checked);
                    }}
                    colorScheme="green"
                    //isReadOnly={
                    //  !canUpdateLogStatus && log?.user?.id !== userData?.id
                    //}
                  />
                }
              </Box>
            )}
          </Heading>
        </Box>
      </Tooltip>
    );
  };

  const _renderResourceContent = (resourceInfo: any) => {
    const { _resource } = resourceInfo.resource;
    const { title } = _resource;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Text
          fontSize="sm"
          sx={{
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Text>
      </Box>
    );
  };

  const _renderCalendar = () => {
    return (
      <RoundedContainer sx={canlendarStyles}>
        <FullCalendar
          ref={calendarRef}
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          resourceAreaHeaderClassNames="custom-header"
          eventContent={!isFetchLoading ? _renderEventContent : []}
          events={events}
          resources={resources}
          resourceOrder={[
            "overall",
            ...resources?.map((resource) => resource.order),
          ]}
          resourceLabelContent={_renderResourceContent}
          stickyHeaderDates
          firstDay={1}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "resourceTimelineWeek,resourceTimelineMonth",
          }}
          // editable
          // eventResizableFromStart
          //  eventResize={handleResize}
          aspectRatio={1.5}
          initialView={"resourceTimelineWeek"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }}
          eventDisplay="block"
          eventOrder="priority"
          views={{
            resourceTimelineWeek: {
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { weekday: "short", day: "numeric" }, // top level of text
              ],
            },
            resourceTimelineMonth: {
              slotLabelFormat: [
                { weekday: "short", day: "2-digit" }, // lower level of text
              ],
            },
          }}
          eventClick={(eventInfo: EventClickArg) => {
            const { event } = eventInfo;
            const log = event?.extendedProps;
            const isEventPlaceholder = _.includes(
              eventInfo?.event?.id,
              "virtual_event"
            );
            if (isEventPlaceholder)
              dispatch(
                performAction("logExternal", "", {
                  date: dayjs(eventInfo?.event?.startStr).format("YYYY-MM-DD"),
                })
              );
            else {
              if (!eventInfo?.event?.extendedProps?.overTimeStatus) {
                if (
                  eventInfo?.event?.extendedProps?.timeType ===
                  ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
                )
                  dispatch(performAction("editLog", log?.id));
              }
            }
          }}
          resourceAreaWidth={225}
          datesSet={handleDatesSet}
          resourceAreaHeaderContent=""
          height={"auto"}
        />
      </RoundedContainer>
    );
  };

  return _renderCalendar();
};

export default Calendar;
