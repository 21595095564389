import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
};

const DEFAULT_PAGINATION_AGGREGATE = {
  page: 0,
  limit: 0,
  keyword: "",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  ...generateSuccessStateVariables([
    "CreateDayOffRequest",
    "ChangeStatus",
    "UpdateDayOffRequest",
    "Delete",
  ]),
  isFetchAggregateLoading: false,
  meta: DEFAULT_META_STATES,
  pagination: DEFAULT_PAGINATION,
  paginationAgregate: DEFAULT_PAGINATION_AGGREGATE,
  metaAggregate: DEFAULT_META_STATES,
  dayOffRequestList: [],
  aggregateList: [],
  details: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;

  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_REDUCER_DAY_OFF_REQUEST:
      return { ...initialState };
    case ActionTypes.RESET_DAY_OFF_REQUEST_DETAILS:
      return {
        ...state,
        details: {},
      };
    case ActionTypes.SET_DAY_OFF_REQUEST_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_DAY_OFF_REQUEST_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    case ActionTypes.SET_DAY_OFF_REQUEST_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isGetLoading: true,
      };
    case ActionTypes.SET_META_DAY_OFF_REQUEST:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_DAY_OFF_REQUEST:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };

    case ActionTypes.CREATE_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateDayOffRequestSuccess: true,
      };
    case ActionTypes.CREATE_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateDayOffRequestSuccess: false,
      };

    case ActionTypes.FETCH_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateDayOffRequestSuccess: false,
        isUpdateDayOffRequestSuccess: false,
        isDeleteSuccess: false,
        dayOffRequestList: payload,
      };
    case ActionTypes.FETCH_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateDayOffRequestSuccess: false,
        isUpdateDayOffRequestSuccess: false,
        isDeleteSuccess: false,
        dayOffRequestList: [],
      };

    case ActionTypes.GET_BY_ID_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        details: payload,
        isFetchLoading: false,
      };
    case ActionTypes.GET_BY_ID_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        isFetchLoading: false,
      };

    case ActionTypes.UPDATE_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateDayOffRequestSuccess: true,
      };
    case ActionTypes.UPDATE_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateDayOffRequestSuccess: false,
      };

    case ActionTypes.CHANGE_STATUS_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
      };
    case ActionTypes.CHANGE_STATUS_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
      };

    case ActionTypes.DELETE_DAY_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isDeleteSuccess: true,
      };
    case ActionTypes.DELETE_DAY_OFF_REQUEST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isDeleteSuccess: false,
      };

    case ActionTypes.FETCH_AGGREGATE_DAY_OFF_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        aggregateList: payload,
      };

    case ActionTypes.FETCH_AGGREGATE_DAY_OFF_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        aggregateList: [],
      };

    case ActionTypes.SET_AGGREGATE_DAY_OFF_META:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        metaAggregate: payload,
      };

    case ActionTypes.SET_AGGREGATE_DAY_OFF_PAGINATION:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        paginationAgregate: payload,
      };

    default:
      return state;
  }
};
