import React, { useRef, useState } from "react";
import _ from "lodash";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Select,
  Heading,
  Input,
  Textarea,
} from "@chakra-ui/react";
import CommonColors from "@/Themes/CommonColors";
import { Button } from "@/Components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const CreateTaskDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );

  const [value, onChange] = useState<any>([new Date(), new Date()]);
  const cancelRef = useRef<any>(null);

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          Project
        </Heading>
        <Select placeholder="Select option" size="sm">
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          Title
        </Heading>
        <Input size="sm" placeholder="Title" />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          Timeline
        </Heading>
        <DateTimeRangePicker onChange={onChange} value={value} />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          Description
        </Heading>
        <Textarea size="sm" placeholder="Description" />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          Assignee
        </Heading>
        <Input size="sm" placeholder="Assignee" />
        <Button
          sx={{ fontSize: 12, color: CommonColors.deepskyblue }}
          variant="text"
        >
          Assign to me
        </Button>
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 1px -1px gray",
          }}
        >
          Add Task
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflowY: "scroll",
          }}
        >
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px -1px 1px gray",
          }}
        >
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {}}
            ml={3}
            //colorScheme="whatsapp"
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
            isLoading={isActionLoading}
          >
            Create
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateTaskDialog;
