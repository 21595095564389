import {
  Autocomplete,
  Button,
  DatePicker,
  RoundedContainer,
  Select,
  TextField,
  UploadAvatar,
  UploadFileInput,
} from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import { AdminLayout } from "@/Components/DefaultLayout";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useBreakpoint,
  useMediaQuery,
  Icon,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { isEmpty } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { UserActions, RoleActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { IUser } from "@/Interfaces/User.interface";
import { UserDialog } from "@/Components/Popup";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import {
  UnlockIcon,
  ArrowBackIcon,
  EditIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { IUserRoleStructure } from "@/Interfaces/Role.interface";
import { useTranslation } from "react-multi-lang";

const { getForSelf, updateForSelf, updateUser } = UserActions;
const { fetchRoles } = RoleActions;

const Profile = () => {
  const t = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 500px)");
  const dispatch = useTypedDispatch();
  const [avatar, setAvatar] = React.useState<File[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);
  const [isShowPopup, setIsShowPopup] = React.useState(false);
  const [isEditAttachments, setIsEditAttachments] = React.useState(false);

  const forSelfData: IUser = useSelector((state: RootState) =>
    _.get(state.USER, "forSelfData")
  );

  const roles: any = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.USER, "isActionLoading")
  );
  const userData = Utils.getSavedUserData();

  const CheckRole = Utils.hasPermission(
    _.map(userData?.userRole, (userRole: any) => _.get(userRole, "role")),
    "Users",
    "checkRole"
  );

  const CheckUpdateProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole: any) => _.get(userRole, "role")),
    "Users",
    "updateProfile"
  );

  const checkEditEmail = Utils.hasPermission(
    _.map(userData?.userRole, (userRole: any) => _.get(userRole, "role")),
    "Users",
    "checkEditEmail"
  );

  const roleOptions = React.useMemo(
    () =>
      _.map(
        roles.filter((item: any) => item?.roleCode !== "admin"),
        (role: any) => ({
          label: role.name === "artist" ? "Staff" : _.startCase(role.name),
          value: role.id,
        })
      ),
    [roles]
  );

  //  const roleOptionsAdmin = React.useMemo(
  //    () =>
  //      _.map(roles, (role: any) => ({
  //        label: _.startCase(role.name),
  //        value: role.id,
  //      })),
  //    [roles]
  //  );

  const roleOptionsAdmin = React.useMemo(
    () =>
      _.map(
        forSelfData?.userRole?.filter(
          (item: any) => item?.role?.roleCode === "admin"
        ),
        (role: any) => ({
          label: _.startCase(role?.role?.name),
          value: role.role.id,
        })
      ),
    [forSelfData]
  );

  const breakpoints = {
    base: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    "2xl": 4,
  };

  const breakpointsDrop = {
    base: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
    "2xl": 2,
  };

  const currentBreakpoint = useBreakpoint();
  const currentBreakpointDrop = useBreakpoint();

  const columns =
    breakpoints[currentBreakpoint as keyof typeof breakpoints] || 1;
  const columnsDrop =
    breakpoints[currentBreakpointDrop as keyof typeof breakpointsDrop] || 1;

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .email(t("message.emailMustBeAValidEmail"))
        .required(t("message.emailIsRequired")),
      firstName: yup.string().trim().required(t("message.firtsNameIsRequired")),
      lastName: yup.string().trim().required("message.lastNameIsRequired"),
      roleCode: yup.array().required(t("message.roleIsRequired")),
      phoneNumber: yup.string().trim(),
      gender: yup.string().trim(),
      dob: yup.string().trim(),
      insuranceCompany: yup.string().trim(),
      probationWorkingDay: yup.string().trim(),
      officialWorkingDay: yup.string().trim(),
      contractTerm: yup.string().trim(),
      bankAccountNumber: yup.string().trim(),
      bankName: yup.string().trim(),
      address: yup.string().trim(),
      staffCode: yup.string().trim(),
      probationEndDay: yup.string().trim(),
      bankAccountName: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    //getValues,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const findDeletedAndNewFiles = (beforeUpload: any, afterUpload: any) => {
    // Find deleted files
    const deletedFiles = _.filter(
      beforeUpload,
      (beforeFile: any) =>
        !afterUpload.some((afterFile: any) => afterFile.id === beforeFile.id)
    );

    // Find newly added files
    const newFiles = _.filter(
      afterUpload,
      (afterFile: any) =>
        !beforeUpload.some((beforeFile: any) => beforeFile.id === afterFile.id)
    );
    return { deletedFiles, newFiles };
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (CheckUpdateProfile) {
      if (!_.isEmpty(files)) {
        const { deletedFiles, newFiles } = findDeletedAndNewFiles(
          forSelfData?.userData?.fileAttachment,
          files
        );
        if (!_.isEmpty(deletedFiles)) {
          _.forEach(deletedFiles, (file: any) =>
            formData.append("removeFileAttachments[]", file.id)
          );
        }

        if (newFiles) {
          _.forEach(newFiles, (item: any) => {
            if (item.valid)
              formData.append("userData[fileAttachment]", item.file);
          });
        }
      } else {
        if (!_.isEmpty(forSelfData?.userData?.fileAttachment)) {
          const deletedFiles = forSelfData?.userData?.fileAttachment;
          _.forEach(deletedFiles, (file: any) =>
            formData.append("removeFileAttachments[]", file.id)
          );
        }
      }
      if (!_.isEmpty(data.dob))
        formData.append(
          "userData[dob]",
          !_.isEmpty(data.dob) ? data.dob : null
        );
      if (!_.isEmpty(data.officialWorkingDay))
        formData.append(
          "userData[officialWorkingDay]",
          !_.isEmpty(data.officialWorkingDay) ? data.officialWorkingDay : ""
        );
      if (!_.isEmpty(data.probationWorkingDay))
        formData.append(
          "userData[probationWorkingDay]",
          !_.isEmpty(data.probationWorkingDay) ? data.probationWorkingDay : ""
        );
      if (!_.isEmpty(data.probationWorkingDay))
        formData.append(
          "userData[probationEndDay]",
          !_.isEmpty(data.probationEndDay) ? data.probationEndDay : ""
        );
      if (!_.isEmpty(avatar)) formData.append("userData[avatar]", avatar[0]);
      formData.append("email", data.email);
      formData.append("userData[firstName]", data.firstName);
      formData.append("userData[lastName]", data.lastName);

      if (!_.isEmpty(data.roleCode)) {
        const { addedItems, removedItems } = Utils.findAddedAndRemovedItems(
          _.map(forSelfData?.userRole, (userRole: any) => userRole?.role?.id),
          data.roleCode
        );
        if (addedItems) {
          _.forEach(addedItems, (item: any) => {
            formData.append("newRole[]", item);
          });
        }
        if (removedItems) {
          _.forEach(removedItems, (item: any) => {
            formData.append("removeRole[]", item);
          });
        }
      }

      formData.append(
        "userData[phoneNumber]",
        !_.isEmpty(data.phoneNumber) ? data.phoneNumber : ""
      );
      formData.append(
        "userData[bankAccountNumber]",
        !_.isEmpty(data.bankAccountNumber) ? data.bankAccountNumber : ""
      );

      formData.append(
        "userData[bankName]",
        !_.isEmpty(data.bankName) ? data.bankName : ""
      );
      formData.append(
        "userData[bankAccountName]",
        !_.isEmpty(data.bankAccountName) ? data.bankAccountName : ""
      );
      formData.append(
        "userData[insuranceCompany]",
        !_.isEmpty(data.insuranceCompany) ? data.insuranceCompany : ""
      );
      formData.append(
        "userData[contractTerm]",
        !_.isEmpty(data.contractTerm) ? data.contractTerm : ""
      );

      formData.append(
        "userData[gender]",
        !_.isEmpty(data.gender) ? data.gender : ""
      );
      formData.append(
        "userData[address]",
        !_.isEmpty(data.address) ? data.address : ""
      );
      if (forSelfData.id) dispatch(updateUser(forSelfData.id, formData));
    } else {
      formData.append("userData[firstName]", data.firstName);
      formData.append("userData[lastName]", data.lastName);
      if (!_.isEmpty(avatar)) formData.append("userData[avatar]", avatar[0]);
      dispatch(updateForSelf(formData));
    }
  };

  const generateDetails = async () => {
    if (!_.isEmpty(forSelfData)) {
      setValue("email", forSelfData.email);
      setValue("firstName", forSelfData.userData.firstName);
      setValue("lastName", forSelfData.userData.lastName);
      setValue("dob", forSelfData.userData.dob || "");
      setValue("gender", forSelfData.userData.gender || "");
      setValue(
        "roleCode",
        _.map(
          forSelfData?.userRole,
          (userRole: IUserRoleStructure) => userRole.role.id
        )
      );
      setValue("staffCode", forSelfData.staffCode || "");
      setValue(
        "probationWorkingDay",
        forSelfData.userData.probationWorkingDay || ""
      );
      setValue(
        "officialWorkingDay",
        forSelfData.userData.officialWorkingDay || ""
      );
      setValue("contractTerm", forSelfData.userData.contractTerm || "");
      setValue("bankName", forSelfData.userData.bankName || "");
      setValue(
        "bankAccountNumber",
        forSelfData.userData.bankAccountNumber || ""
      );
      // setValue("bankAccountName", forSelfData.userData.bankAccountName || "");
      setValue("insuranceCompany", forSelfData.userData.insuranceCompany || "");
      setValue("address", forSelfData.userData.address || "");
      setValue("phoneNumber", forSelfData.userData.phoneNumber || "");
      setValue("probationEndDay", forSelfData.userData.probationEndDay || "");
      //  if (forSelfData?.userData?.fileAttachment)
      //    setFiles(forSelfData?.userData?.fileAttachment);
      if (forSelfData?.userData?.avatar) {
        const isInternal =
          forSelfData?.userData?.avatar?.storageService === "Internal";
        const avatarSrc = isInternal
          ? await Utils.getFileFromURL(
              _.get(forSelfData, "userData.avatar.path") || "",
              _.get(forSelfData, "userData.avatar.nameOriginal") || ""
            )
          : await Utils.getAWSFileAsBlob(
              _.get(forSelfData, "userData.avatar.path") || "",
              _.get(forSelfData, "userData.avatar.nameOriginal") || ""
            );
        if (avatarSrc) setAvatar([avatarSrc as File]);
      }
      if (forSelfData?.userData?.fileAttachment)
        setFiles(forSelfData?.userData?.fileAttachment);
    }
  };

  React.useEffect(() => {
    dispatch(getForSelf());
    if (_.isEmpty(roles)) dispatch(fetchRoles());
  }, []);

  React.useEffect(() => {
    generateDetails();
  }, [forSelfData]);

  const _renderBottom = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          w: "100%",
        }}
      >
        <Box>
          <Button
            //  colorScheme="blue"
            onClick={() => setIsShowPopup(true)}
            rightIcon={
              <Icon as={UnlockIcon} boxSize={4} mr={isMobile ? 2 : 0} />
            }
            size="sm"
            sx={{
              color: "#fff",
              background: "#bb7154",
              "&:hover": {
                background: "#Db9d97",
              },
            }}
          >
            {isMobile ? "" : t("button.changePassword")}
          </Button>
        </Box>
        <Box display={"flex"} justifyContent={"end"} w={"100%"}>
          <Button
            leftIcon={<Icon as={ArrowBackIcon} boxSize={4} />}
            onClick={() => Utils.redirect(Routers.DASHBOARD)}
            size="sm"
          >
            {t("button.back")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            size="sm"
            //colorScheme="twitter"
            isLoading={isActionLoading}
            rightIcon={<Icon as={EditIcon} boxSize={4} />}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderForm = () => {
    return (
      <RoundedContainer as={"form"}>
        <Box mb={4}></Box>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3}>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <UploadAvatar
                direction="row"
                labelSx={{ minWidth: "250px" }}
                containerSx={{ mb: 2 }}
                files={avatar}
                onFileChange={(newFile) => setAvatar(newFile)}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size={"sm"}
                    disabled={!checkEditEmail}
                    placeholder="Email address"
                    value={field.value}
                    label="Email"
                    onChange={(e: any) => field.onChange(e)}
                    isError={!isEmpty(errors.email?.message)}
                    errorMessage={errors.email?.message}
                    //isRequired
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Text fontSize="18px" fontWeight={600}>
                {t("title.persionalInformations")}
              </Text>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size={"sm"}
                    placeholder={t("label.firstName")}
                    label={t("label.firstName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.firstName?.message}
                    isError={!isEmpty(errors.firstName?.message)}
                    isRequired={CheckUpdateProfile}
                    //disabled={!CheckUpdateProfile}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size={"sm"}
                    placeholder={t("label.lastName")}
                    label={t("label.lastName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.lastName?.message}
                    isError={!isEmpty(errors.lastName?.message)}
                    isRequired={CheckUpdateProfile}
                    //disabled={!CheckUpdateProfile}
                  />
                )}
              />
              <Stack direction="row" columnGap={2}>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={!CheckUpdateProfile}
                      maxDate={new Date()}
                      value={field.value}
                      onDateChange={(newDate: any) =>
                        field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                      }
                      label={t("label.birthday")}
                      size="sm"
                    />
                  )}
                />
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Select
                      disabled={!CheckUpdateProfile}
                      value={field.value}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      size="sm"
                      label={t("label.gender")}
                      options={[
                        { label: "", value: "" },
                        { label: t("label.male"), value: "male" },
                        { label: t("label.female"), value: "female" },
                        { label: t("label.other"), value: "other" },
                      ]}
                    />
                  )}
                />
              </Stack>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    size={"sm"}
                    placeholder={t("label.phoneNumber")}
                    label={t("label.phoneNumber")}
                    type="number"
                    phoneInputvalue={field.value}
                    onPhoneInputChange={(e: any) => field.onChange(e)}
                    autoComplete="false"
                    // onWheel={() =>
                    //   (document.activeElement as HTMLElement).blur()
                    // }
                    isPhoneInput
                    disablePhoneInput={!CheckUpdateProfile}
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextareaComponent
                    placeholder={t("label.address")}
                    label={t("label.address")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    //isError={!isEmpty(errors.fullName?.message)}
                    disabled={!CheckUpdateProfile}
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Text fontSize="18px" fontWeight={600}>
                {t("title.positionInformations")}
              </Text>
              <Controller
                name="roleCode"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value}
                    onChange={(newValue: any) => field.onChange(newValue)}
                    label={t("label.role")}
                    isMulti
                    options={CheckRole ? roleOptionsAdmin : roleOptions}
                    isRequired={CheckUpdateProfile}
                    disabled={
                      !CheckUpdateProfile || !_.isEmpty(roleOptionsAdmin)
                    }
                  />
                )}
              />
              <TextField
                value={forSelfData?.staffCode}
                size={"sm"}
                disabled
                placeholder={t("label.staffCode")}
                label={t("label.staffCode")}
                //value={field.value}
                //onChange={(e: any) => field.onChange(e)}
              />
              <Stack direction="row" columnGap={2}>
                <Controller
                  name="probationWorkingDay"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={!CheckUpdateProfile}
                      value={field.value}
                      onDateChange={(newDate: any) =>
                        field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                      }
                      label={t("label.probationWorkingDay")}
                    />
                  )}
                />
              </Stack>
              <Controller
                name="probationEndDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disabled={!CheckUpdateProfile}
                    value={field.value}
                    onDateChange={(newDate) =>
                      field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                    }
                    label={t("label.probationEndDay")}
                  />
                )}
              />
              <Controller
                name="officialWorkingDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disabled={!CheckUpdateProfile}
                    value={field.value}
                    onDateChange={(newDate: any) =>
                      field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                    }
                    label={t("label.officialWorkingDay")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="contractTerm"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disabled={!CheckUpdateProfile}
                    value={field.value}
                    onDateChange={(newDate: any) =>
                      field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                    }
                    label={t("label.contractExpirationDate")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="insuranceCompany"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!CheckUpdateProfile}
                    size={"sm"}
                    placeholder={t("label.insurancePlace")}
                    label={t("label.insurancePlace")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankName"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!CheckUpdateProfile}
                    size={"sm"}
                    placeholder={t("label.bankName")}
                    label={t("label.bankName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankAccountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!CheckUpdateProfile}
                    size={"sm"}
                    placeholder={t("label.bankAccountNumber")}
                    label={t("label.bankAccountNumber")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankAccountName"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.bankAccountName")}
                    label={t("label.bankAccountName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
            </Stack>
          </GridItem>
        </Grid>
        <Grid templateColumns={`repeat(${columnsDrop}, 1fr)`} gap={3}>
          <GridItem colSpan={4}>
            <Box sx={{ display: "flex", alignContent: "center" }}>
              <Text fontSize="18px" fontWeight={600}>
                {t("label.personalDocumentations")}
              </Text>
              <Box ml={2}>
                {CheckUpdateProfile && (
                  <>
                    {!isEditAttachments ? (
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            background: "none",
                            ":hover": { background: "none" },
                            ml: "auto",
                          }}
                          size="xs"
                          icon={<EditIcon fontSize={"20px"} />}
                          aria-label={""}
                          onClick={() => setIsEditAttachments(true)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            background: "none",
                            ":hover": { background: "none" },
                            ml: "auto",
                          }}
                          size="xs"
                          icon={<CloseIcon fontSize={"14px"} />}
                          aria-label={""}
                          onClick={() => setIsEditAttachments(false)}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <UploadFileInput
              accept="image/*, video/*, application/pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx"
              files={forSelfData?.userData?.fileAttachment || []}
              isEditing={
                forSelfData?.userData?.fileAttachment ? isEditAttachments : true
              }
              sx={{ mb: 3 }}
              onFilesChange={(files: any) => setFiles(files)}
              maxSize={1024 * 1024 * 2} // 2MB
              clearIcon
              maxFiles={10}
            />
          </GridItem>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "end", my: 5 }}>
          {_renderBottom()}
        </Box>
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    return (
      <Box
        sx={{
          width: "100%",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md">{t("title.profile")}</Heading>
        </Box>
        <Box>
          <Box sx={{ mx: 4, mt: 10 }}>{_renderForm()}</Box>
        </Box>
        <UserDialog.ChangePasswordDialog
          open={isShowPopup}
          onClose={() => setIsShowPopup(false)}
        />
      </Box>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Profile;
