import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { IChatFilters } from "@/Interfaces/Chat.interface";

const { CHAT } = APIs;

export const fetchMessageChannel = async () => {
  return request(CHAT.FETCH_MESSAGE_CHANNEL, "GET");
};

export const fetchMessageNotification = async () => {
  return request(CHAT.FETCH_MESSAGE_NOTIFICATION, "GET");
};

export const fetchMessageByChannelId = async (
  id: string,
  payload: IChatFilters
) => {
  return request(
    `${CHAT.FETCH_MESSAGE_BY_CHANEL_ID}/?channelId=${id}`,
    "GET",
    payload
  );
};

export const fetchAllUsers = async (payload?: any) => {
  return request(CHAT.FETCH_ALL_USERS, "GET", payload);
};

export const fetchUserNotConversation = async () => {
  return request(CHAT.FETCH_USER_NOT_CONVERSATION, "GET");
};

export const fetchConversationChannelDetail = async (id: string) => {
  return request(`${CHAT.FETCH_MESSAGE_CHANNEL_DETAIL}/${id}`, "GET");
};

export const createPrivateChat = async (payload: FormData) => {
  return request(CHAT.CREATE_PRIVATE_MESSAGE, "POST_FORM_DATA", payload);
};

export const createGroupChat = async (payload: FormData) => {
  return request(CHAT.CREATE_GROUP, "POST_FORM_DATA", payload);
};

export const createMessageChat = async (payload: FormData) => {
  return request(CHAT.CREATE_MESSAGE, "POST_FORM_DATA", payload);
};

export const recalledMessage = async (id: string) => {
  return request(`${CHAT.RECALLED_MESSAGE}/${id}`, "PUT");
};

export const deletePrimaryMessage = async (id: string) => {
  return request(`${CHAT.DELETE_PRIVATE_MESSAGE}/${id}`, "PUT");
};

export const fetchMediaByChannelId = async (
  id: string,
  pagination: IChatFilters
) => {
  return request(
    `${CHAT.FETCH_MEDIA_BY_CHANNEL_ID}/?channelId=${id}`,
    "GET",
    pagination
  );
};

export const addMemberToGroup = async (id: string, payload: FormData) => {
  return request(`${CHAT.ADD_MEMBER_GROUP}/${id}`, "PUT_FORM_DATA", payload);
};

export const removeMemberToGroup = async (id: string, payload: FormData) => {
  return request(`${CHAT.REMOVE_MEMBER_GROUP}/${id}`, "PUT_FORM_DATA", payload);
};

export const leaveGroup = async (id: string) => {
  return request(`${CHAT.LEAVE_GROUP}/${id}`, "PUT");
};

export const readConversation = async (id: string, payload: FormData) => {
  return request(`${CHAT.READ_CONVERSATION}/${id}`, "PUT", payload);
};

export const updateConversation = async (id: string, payload: FormData) => {
  return request(`${CHAT.UPDATE_CONVERSATION}/${id}`, "PUT_FORM_DATA", payload);
};

export const removeAllFile = async (data: { time: number; type: string }) => {
  return request(CHAT.REMOVE_ALL_FILE, "PUT", data);
};
