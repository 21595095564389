import React, { useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Icon, IconButton, Text, Tooltip, Box } from "@chakra-ui/react";
import { Table, Pagination } from "rsuite";
// import { Table } from "@/Components/Common";
import { type IPaginate, type TActions } from "@/Types/Common.types";

import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
  LinkIcon,
  BellSlashIcon,
  BellAlertIcon,
} from "@heroicons/react/24/outline";
import { ENUMS, Routers } from "@/Constants";
import { AvatarGroupWithOverflow, NoDataFound } from "@/Components/Common";
import Utils from "@/Utils";
import { RootState, useTypedDispatch } from "@/Store";
import { ProjectActions, ProjectExteriorActions } from "@/Actions";

import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

const { PROJECT_STATUS_COLOR, URGENCY_COLOR } = ENUMS;
const { updateProject } = ProjectActions;
const { fetchProjectsExterior } = ProjectExteriorActions;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onChangeLimit?: (limint: number) => void;
  onRowAction: (action: TActions, item: any) => void;
  showTotal?: boolean;
  isLoading?: boolean;
  onlyView?: boolean;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const ProjectExteriorTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  isLoading,
  onRowAction,
  onChangeLimit,
}) => {
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const t = useTranslation();

  const userData = Utils.getSavedUserData();
  const isShowNotificationOption = useMemo(
    () =>
      _.some(
        userData?.userRole,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ADMIN
      ),
    [userData]
  );

  const pagination: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "pagination")
  );

  //  const canEditProject = Utils.hasPermission(
  //    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
  //    "Project",
  //    "editProject"
  //  );

  //  const canChangeStatusProject = Utils.hasPermission(
  //    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
  //    "Project",
  //    "changeStatus"
  //  );

  const canDeleteProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "deleteProject"
  );

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "auto",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={payload}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        height={isMobile ? 400 : 570}
      >
        <Column width={200} fixed={!isMobile} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.name")}
          </HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column width={100} fixed={!isMobile} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.code")}
          </HeaderCell>
          <Cell dataKey="code" />
        </Column>
        <Column width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.managers")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const managers = rowData?.projectMember.filter((item: any) => {
                return _.some(
                  item?.user?.userRole,
                  (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
                );
              });
              return <AvatarGroupWithOverflow data={managers} />;
            }}
          </Cell>
        </Column>
        <Column width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.members")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const members = _.filter(rowData?.projectMember, (item) => {
                return !_.some(item?.user?.userRole, (userRole) => {
                  return userRole?.role?.roleCode === ENUMS.ROLES.MANAGER;
                });
              });
              return <AvatarGroupWithOverflow data={members} />;
            }}
          </Cell>
        </Column>
        <Column width={150} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.degreeOfUrgency")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Text
                  color={
                    URGENCY_COLOR[
                      rowData?.urgency as keyof typeof URGENCY_COLOR
                    ].text
                  }
                  bgColor={
                    URGENCY_COLOR[
                      rowData?.urgency as keyof typeof URGENCY_COLOR
                    ].background
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {t(`label.${rowData?.urgency}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column width={100} flexGrow={1} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.tasks")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const tasks = rowData?.kanbanBoard?.task || [];
              const taskTitles =
                tasks.length > 0
                  ? _.map(tasks, (task) => task?.title).join(", ")
                  : "";
              return taskTitles;
            }}
          </Cell>
        </Column>
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.createdAt")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              dayjs(rowData?.createdAt.slice(0, 10)).format("DD/MM/YYYY")
            }
          </Cell>
        </Column>
        <Column align="center" width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.status")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Text
                  color={
                    PROJECT_STATUS_COLOR[
                      rowData?.status as keyof typeof PROJECT_STATUS_COLOR
                    ].text
                  }
                  bgColor={
                    PROJECT_STATUS_COLOR[
                      rowData?.status as keyof typeof PROJECT_STATUS_COLOR
                    ].background
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {t(`status.${rowData?.status}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column
          align="center"
          width={isShowNotificationOption ? 220 : 180}
          fixed="right"
        >
          <HeaderCell
            verticalAlign="center"
            align="center"
            style={HEADER_STYLE}
          >
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData: any) => {
              const isUserCreated = rowData?.userCreated?.id === userData?.id;
              return (
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {isShowNotificationOption && (
                    <Tooltip
                      label={`${
                        rowData?.isReceiveNotification
                          ? t("tooltip.disableNotifications")
                          : t("tooltip.enableNotifications")
                      }`}
                    >
                      <IconButton
                        icon={
                          <Icon
                            as={
                              rowData?.isReceiveNotification
                                ? BellAlertIcon
                                : BellSlashIcon
                            }
                          />
                        }
                        aria-label={""}
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        onClick={() =>
                          dispatch(
                            updateProject(
                              rowData?.id,
                              {
                                isReceiveNotification:
                                  !rowData?.isReceiveNotification,
                              },
                              `${
                                rowData?.isReceiveNotification
                                  ? t(
                                      "message.disableNotificationsSuccessfully"
                                    )
                                  : t("message.enableNotificationsSuccessfully")
                              }`,
                              () => dispatch(fetchProjectsExterior(pagination))
                            )
                          )
                        }
                      />
                    </Tooltip>
                  )}
                  <Tooltip label={t("tooltip.goToBoardDetail")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={LinkIcon} />}
                      aria-label={""}
                      onClick={() =>
                        Utils.redirect(
                          `${Routers.BOARD_DETAIL}?id=${rowData?.kanbanBoard?.id}`
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip label={t("tooltip.view")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={EyeIcon} />}
                      aria-label={""}
                      onClick={() =>
                        Utils.redirect(
                          `${Routers.PROJECT_DETAILS}?id=${rowData.id}`
                        )
                      }
                    />
                  </Tooltip>
                  {/*{(canEditProject || canChangeStatusProject) && (*/}
                  <Tooltip label={t("tooltip.edit")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={PencilSquareIcon} />}
                      aria-label={""}
                      onClick={() =>
                        Utils.redirect(
                          `${Routers.UPDATE_PROJECT_EXTERIOR}?id=${rowData.id}`
                        )
                      }
                    />
                  </Tooltip>
                  {/*)}*/}
                  {isUserCreated || canDeleteProject ? (
                    <Tooltip label={t("tooltip.delete")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={TrashIcon} sx={{ color: "#bb7154" }} />}
                        aria-label={""}
                        color="error"
                        onClick={() => onRowAction("delete", rowData)}
                      />
                    </Tooltip>
                  ) : (
                    <IconButton
                      sx={{
                        bg: "transparent",
                        visibility: "hidden",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={TrashIcon} sx={{ color: "#bb7154" }} />}
                      aria-label={""}
                    />
                  )}
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "&.rs-pagination-btn:disabled": {
            color: "#c5c6c7!important",
          },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default ProjectExteriorTable;
