import CommonColors from "@/Themes/CommonColors";
const calendarStyles = {
  // "& .fc *": {
  //   transition: "all ease 0.5s",
  // },
  // "& .fc-timeline-events.fc-scrollgrid-sync-inner *": {
  //   transition: "none",
  // },
  // "& .fc-timeline-events.fc-scrollgrid-sync-inner > *": {
  //   transition: "none",
  // },
  position: "relative",
  // display: "flex",
  flexDirection: "column",
  overflow: "auto",
  width: "100%",
  gap: 3,
  ".fc.fc-media-screen.fc-direction-ltr.fc-theme-standard": {
    width: "100%",
  },
  ".fc-view": {
    background: "white",
  },
  ".fc-button": {
    background: "transparent",
    outline: "none",
    boxShadow: "none !important",
    border: `2px solid ${CommonColors.burntSienna}`,
    color: CommonColors.burntSienna,
    fontSize: "14px",
    "&:hover": {
      background: "rgb(234, 236,251,100)",
      border: `2px solid ${CommonColors.burntSienna}`,
      color: CommonColors.burntSienna,
    },
  },
  ".fc-toolbar-title": {
    fontSize: 20,
  },
  ".fc-toolbar-title, .fc-button": {
    padding: "4px 10px",
    textTransform: "capitalize",
  },
  ".fc-today-button, .fc-button.fc-button-primary": {
    // background: `${CommonColors.LightSkyBlue3}!important`,
    "&.fc-button-active": {
      background: `rgb(234, 236,251,100)`,
      border: `2px solid ${CommonColors.burntSienna}`,
      color: CommonColors.burntSienna,
      fontWeight: 700,
    },
    "&.fc-next-button, &.fc-button[title='month view']": {
      borderLeft: `1px solid ${CommonColors.burntSienna}`,
    },
    "&.fc-prev-button, &.fc-button[title='week view']": {
      borderRight: `1px solid ${CommonColors.burntSienna}`,
    },
  },
  ".custom-header": {
    ".fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner": {
      display: "none",
    },
  },
  ".fc .fc-datagrid-expander, span.fc-datagrid-expander": {
    display: "none",
  },
  // hidden resize
  // "td.fc-resource-timeline-divider.fc-cell-shaded": {
  //   visibility: "hidden",
  //   width: 0,
  // },
  // ".fc td, .fc th": {
  //   borderStyle: "none!important",
  // },
  "a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timeline-event.fc-h-event":
    {
      height: "100%",
    },
  // "th[role='presentation']": {
  //   ".fc-scroller": {
  //     overflow: "hidden !important",
  //   },
  // },
  //Highlight today
  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-today,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-today":
    {
      background: CommonColors.loblolly,
    },
  // ".fc-timeline-event-harness": {
  //   height: "100% !important",
  // },
  ".fc-timeline-event-harness": {
    margin: "1px",
  },
  ".fc-timeline-event .fc-event-main": {},
  "a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner": {
    color: "black",
    outline: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  // "a.fc-event": {
  //   border: "1px solid #fff",
  // },
  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.holiday, td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.holiday":
    {
      backgroundColor: CommonColors.edward,
    },
  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-sun,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-sun":
    {
      background: "#E2E8F0",
    },
};
export { calendarStyles };
