import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_META_STATES,
  DEFAULT_LOADING_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const initialState = {
  DEFAULT_LOADING_STATES,
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  meta: DEFAULT_META_STATES,
  ...generateSuccessStateVariables(["Update", "ChangeStatus"]),
  pagination: {},
  payload: [],
  statistical: {},
  details: {},
  userCanAdd: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_PROJECT_EXTERIOR_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
        isChangeStatusSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.SET_PROJECT_EXTERIOR_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
        isChangeStatusSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.SET_PROJECT_EXTERIOR_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
        isChangeStatusSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.SET_PROJECT_EXTERIOR_META:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        meta: payload,
      };
    case ActionTypes.SET_PROJECT_EXTERIOR_PAGINATION:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        pagination: payload,
      };
    case ActionTypes.RESET_PROJECT_EXTERIOR_STATUS: {
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };
    }

    case ActionTypes.RESET_PROJECT_EXTERIOR_REDUCER:
      return { ...initialState };

    case ActionTypes.FETCH_PROJECT_EXTERIORS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        payload: payload,
      };
    case ActionTypes.FETCH_PROJECT_EXTERIORS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        payload: [],
      };
    case ActionTypes.GET_PROJECT_EXTERIOR_BY_ID_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        details: payload,
      };
    case ActionTypes.GET_PROJECT_EXTERIOR_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_EXTERIOR_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.CREATE_PROJECT_EXTERIOR_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
      };

    case ActionTypes.UPDATE_PROJECT_EXTERIOR_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_PROJECT_EXTERIOR_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.DELETE_PROJECT_EXTERIOR_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.DELETE_PROJECT_EXTERIOR_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
      };

    case ActionTypes.CHANGE_PROJECT_EXTERIOR_STATUS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
      };
    case ActionTypes.CHANGE_PROJECT_EXTERIOR_STATUS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
      };

    case ActionTypes.FETCH_USER_CAN_ADD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        userCanAdd: payload,
      };
    case ActionTypes.FETCH_USER_CAN_ADD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        userCanAdd: [],
      };

    default:
      return state;
  }
};
