import React, { useEffect, useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
} from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import { TimeDeleteFilePrivateChatActions } from "@/Actions";
import { Select, TextField } from "@/Components/Common";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-multi-lang";
//import { ITaskStructure } from "@/Interfaces/Board.interface";

const { updateData, createData } = TimeDeleteFilePrivateChatActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: any;
}

const DEFAULT_FORM_DATA = {
  time: 1,
  type: "week",
};

const UpdateTimeDeleteFilePrivateChatDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIME_DELETE_FILE_PRIVATE_CHAT, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      time: yup
        .number()
        .default(0)
        .typeError("Time delete attachment file must be a number"),
      type: yup.string().trim(),
    })
    .required();

  const {
    control,
    setValue,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const watchTypeDeleteAttachment = watch("type");

  useEffect(() => {
    const time = payload?.time;
    const type = payload?.type;
    setValue("time", time ? _.toNumber(time) : 1);
    setValue("type", type || "week");
  }, [payload, open]);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const handleReset = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: any) => {
    if (payload?.id)
      dispatch(updateData(payload.id, data, () => handleReset()));
    else dispatch(createData(data, () => handleReset()));
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <Box mt={2}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
                if (e.target.value === "infinite") clearErrors("time");
              }}
              label={t("label.typeDeleteAttachmentFile")}
              size="sm"
              options={[
                { label: t("label.week"), value: "week" },
                { label: t("label.month"), value: "month" },
                { label: t("label.yea"), value: "year" },
                { label: t("label.infinite"), value: "infinite" },
              ]}
              //isRequired
              isError={!_.isEmpty(errors.type?.message)}
              errorMessage={errors?.type?.message}
            />
          )}
        />
      </Box>
      {watchTypeDeleteAttachment !== "infinite" && (
        <Box mt={2}>
          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                }}
                label={t("label.timeDeleteAttachmentFile")}
                placeholder="0"
                size="sm"
                min={1}
                isError={!_.isEmpty(errors.time?.message)}
                errorMessage={errors?.time?.message}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("title.editTimeDeleteAttachmentFileInPrivateChat")}
          </Text>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 450, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            size={"sm"}
            sx={{
              color: "#fff",
              bgColor: "rgba(92, 110, 108,1)",
              "&:hover": {
                bgColor: "rgba(92, 110, 108,0.8)",
              },
            }}
            isDisabled={isActionLoading}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateTimeDeleteFilePrivateChatDialog;
