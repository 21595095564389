import React from "react";
import { Text, Box } from "@chakra-ui/react";
import Utils from "@/Utils";

interface INoti {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  recordId: string;
  content: string;
  contentVI?: string;
  extraData?: {
    highlight?: string;
    highlightVI?: string;
    hightlightVI?: string;
  };
  status: string;
}

interface ISectionProps {
  payload: INoti;
}

const ProcessContent: React.FC<ISectionProps> = ({ payload }) => {
  const language = Utils.getSavedLanguage();

  const processContent = () => {
    if (payload.extraData && payload.extraData.highlight) {
      const highlightedText = payload.extraData.highlight;
      const content = payload.content;
      const contentVi = payload.contentVI;
      const highlightedTextVi =
        payload.extraData.highlightVI || payload.extraData.hightlightVI;

      if (language === "en") {
        if (content.includes(highlightedText)) {
          const highlightedContent = content.replace(
            highlightedText,
            `<b>${highlightedText}</b>`
          );
          return (
            <Box dangerouslySetInnerHTML={{ __html: highlightedContent }} />
          );
        }
      } else {
        if (contentVi && highlightedTextVi) {
          const highlightedContentVi = contentVi.replace(
            highlightedTextVi,
            `<b>${highlightedTextVi}</b>`
          );
          return (
            <Box dangerouslySetInnerHTML={{ __html: highlightedContentVi }} />
          );
        } else if (content.includes(highlightedText)) {
          const highlightedContent = content.replace(
            highlightedText,
            `<b>${highlightedText}</b>`
          );
          return (
            <Box dangerouslySetInnerHTML={{ __html: highlightedContent }} />
          );
        }
      }
    }
    return <Text fontSize="sm">{payload.content}</Text>;
  };

  return processContent();
};

export default ProcessContent;
