import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import {
  ICreateBoard,
  ICreateColumnInBoard,
  ITaskStructure,
  IUpdateColumnInBoard,
} from "@/Interfaces/Board.interface";

// SINGLE ACTIONS

const setBoardFetchLoading = () => {
  return {
    type: ActionTypes.SET_BOARD_FETCH_LOADING,
  };
};

const setBoardGetLoading = () => {
  return {
    type: ActionTypes.SET_BOARD_GET_LOADING,
  };
};

const setBoardActionLoading = () => {
  return {
    type: ActionTypes.SET_BOARD_ACTION_LOADING,
  };
};

const setMyTimeWeekdays = (weekDays: string[]) => ({
  type: ActionTypes.SET_MY_TIME_WEEKDAYS,
  payload: weekDays,
});

const setDashboardWeekdays = (weekDays: string[]) => ({
  type: ActionTypes.SET_DASHBOARD_WEEKDAYS,
  payload: weekDays,
});

const setTimelineActiveDates = (activeDates: string[]) => ({
  type: ActionTypes.SET_TIMELINE_ACTIVE_DATES,
  payload: activeDates,
});

const performAction = (actionType: string, itemId: string) => ({
  type: ActionTypes.BOARD_PERFORM_ACTION,
  payload: { actionType, itemId },
});

const resetBoardPerformAction = () => {
  return {
    type: ActionTypes.RESET_BOARD_PERFORM_ACTION,
  };
};

const resetWeekdays = () => ({
  type: ActionTypes.RESET_WEEKDAYS,
});

const resetBoardStatus = () => {
  return {
    type: ActionTypes.RESET_BOARD_STATUS,
  };
};

const resetTimelineInBoard = () => {
  return {
    type: ActionTypes.RESET_TIMELINE_IN_BOARD,
  };
};

const resetBoardDetail = () => {
  return {
    type: ActionTypes.RESET_BOARD_DETAIL,
  };
};

const resetBoardReducer = () => {
  return {
    type: ActionTypes.RESET_BOARD_REDUCER,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_BOARDS_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_BOARDS_FAILURE,
  };
};

const fetchBoards = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setBoardFetchLoading());
    await APIs.fetchBoards(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchFailure());
        } else {
          dispatch(fetchSuccess(result?.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchFailure());
      });
  };
};

const getSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BOARD_BY_ID_SUCCESS,
    payload,
  };
};

const getFailure = () => {
  return {
    type: ActionTypes.GET_BOARD_BY_ID_FAILURE,
  };
};

const getBoardById = (id: string, hiddenLoading?: boolean) => {
  return async (dispatch: any) => {
    if (!hiddenLoading) dispatch(setBoardGetLoading());
    await APIs.getBoardById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(getFailure());
        } else {
          dispatch(getSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getFailure());
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_BOARD_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_BOARD_FAILURE,
  };
};

const createBoard = (payload: ICreateBoard) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.createBoard(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(createFailure());
        } else {
          dispatch(createSuccess());
        }
      })
      .catch(async () => {
        await dispatch(createFailure());
      });
  };
};

const createColumnSuccess = () => {
  return {
    type: ActionTypes.CREATE_COLUMN_IN_BOARD_SUCCESS,
  };
};

const createColumnFailure = () => {
  return {
    type: ActionTypes.CREATE_COLUMN_IN_BOARD_FAILURE,
  };
};

const createColumnInBoard = (payload: ICreateColumnInBoard) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.createColumnInBoard(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(getBoardById(payload.kanbanBoard));
          dispatch(createColumnSuccess());

          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createColumnFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getColumnSuccess = (payload: ITaskStructure) => {
  return {
    type: ActionTypes.GET_COLUMN_IN_BOARD_SUCCESS,
    payload,
  };
};

const getColumnFailure = () => {
  return {
    type: ActionTypes.GET_COLUMN_IN_BOARD_FAILURE,
  };
};

const getColumnInBoard = (id: string, hiddenAlert?: boolean) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.getColumnInBoard(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(getColumnSuccess(result));
          if (!hiddenAlert)
            await Toast({
              title: message,
              status: "success",
            });
        }
      })
      .catch(async () => {
        await dispatch(getColumnFailure());
      });
  };
};

const updateColumnSuccess = () => {
  return {
    type: ActionTypes.UPDATE_COLUMN_IN_BOARD_SUCCESS,
  };
};

const updateColumnFailure = () => {
  return {
    type: ActionTypes.UPDATE_COLUMN_IN_BOARD_FAILURE,
  };
};

const updateColumnInBoard = (
  id: string,
  payload: IUpdateColumnInBoard | FormData,
  onSuccess?: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.updateColumnInBoard(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(getBoardById(result?.kanbanBoard?.id));
          dispatch(updateColumnSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess && onSuccess();
        }
      })
      .catch(async (error) => {
        await dispatch(updateColumnFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateColumnInBoardTwice = (
  activeColumn: { id: string; payload: IUpdateColumnInBoard | FormData },
  overColumn: { id: string; payload: IUpdateColumnInBoard | FormData },
  onSuccess: () => void,
  onFailure: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    try {
      const firstUpdate = APIs.updateColumnInBoard(
        activeColumn.id,
        activeColumn.payload
      );
      const secondUpdate = APIs.updateColumnInBoard(
        overColumn.id,
        overColumn.payload
      );
      await Promise.all([firstUpdate, secondUpdate]);
      dispatch(updateColumnSuccess());
      onSuccess();
    } catch (error: any) {
      await dispatch(updateColumnFailure());
      await Toast({
        title:
          error?.message || "An error occurred while updating position columns",
        status: "error",
      });
      onFailure();
    }
  };
};

const createCommentInColumnSuccess = () => {
  return {
    type: ActionTypes.CREATE_COMMENT_IN_COLUMN_SUCCESS,
  };
};

const createCommentInColumnFailure = () => {
  return {
    type: ActionTypes.CREATE_COMMENT_IN_COLUMN_FAILURE,
  };
};

const createCommentInColumn = (
  id: string,
  payload: FormData,
  boardId: string
) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.createCommentInColumn(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createCommentInColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(createCommentInColumnSuccess());
          await dispatch(getBoardById(boardId));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createCommentInColumnFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteCommentInColumnSuccess = () => {
  return {
    type: ActionTypes.DELETE_COMMENT_IN_COLUMN_SUCCESS,
  };
};

const deleteCommentInColumnFailure = () => {
  return {
    type: ActionTypes.DELETE_COMMENT_IN_COLUMN_FAILURE,
  };
};

const deleteCommentInColumn = (id: string, boardId: string) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.deleteCommentInColumn(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteCommentInColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(deleteCommentInColumnSuccess());
          await dispatch(getBoardById(boardId));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteCommentInColumnFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteColumnSuccess = () => {
  return {
    type: ActionTypes.DELETE_COLUMN_IN_BOARD_SUCCESS,
  };
};

const deleteColumnFailure = () => {
  return {
    type: ActionTypes.DELETE_COLUMN_IN_BOARD_FAILURE,
  };
};

const deleteColumnInBoard = (
  id: string,
  boardId: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setBoardActionLoading());
    await APIs.deleteColumnInBoard(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteColumnFailure());
          await Toast({
            title: message,
            status: "error",
          });
          onFailure();
        } else {
          await dispatch(deleteColumnSuccess());
          await dispatch(getBoardById(boardId));
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess();
        }
      })
      .catch(async (error) => {
        await dispatch(deleteColumnFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
        onFailure();
      });
  };
};

const getTimelineInBoardSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_TIMELINE_IN_BOARD_SUCCESS,
    payload,
  };
};

const getTimelineInBoardFailure = () => {
  return {
    type: ActionTypes.GET_TIMELINE_IN_BOARD_FAILURE,
  };
};

const getTimelineInBoard = (
  id: string,
  payload?: any,
  hiddenLoading?: boolean
) => {
  return async (dispatch: any) => {
    if (!hiddenLoading) dispatch(setBoardGetLoading());
    await APIs.getTimelineInBoard(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getTimelineInBoardFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(
            getTimelineInBoardSuccess({ data: result, pagination: payload })
          );
        }
      })
      .catch(async () => {
        await dispatch(getTimelineInBoardFailure());
      });
  };
};

export default {
  setMyTimeWeekdays,
  setDashboardWeekdays,
  performAction,
  resetWeekdays,
  resetBoardPerformAction,
  resetBoardStatus,
  resetBoardReducer,
  createBoard,
  fetchBoards,
  getBoardById,
  createColumnInBoard,
  getColumnInBoard,
  updateColumnInBoard,
  createCommentInColumn,
  deleteCommentInColumn,
  resetBoardDetail,
  updateColumnInBoardTwice,
  deleteColumnInBoard,
  getTimelineInBoard,
  resetTimelineInBoard,
  setTimelineActiveDates,
};
