import { BackupActions } from "@/Actions";
import { UploadFileInput } from "@/Components/Common";
import { useTypedDispatch } from "@/Store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-multi-lang";

const { restoreDatabase } = BackupActions;

interface SectionProps {
  open: boolean;
  close?(): void;
  onClose(): void;
}

const CreateFileUploadRestoreDialog: React.FC<SectionProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useTypedDispatch();
  const cancelRef = useRef<any>(null);
  const [files, setFiles] = useState<any[]>([]);
  const t = useTranslation();

  const handleClick = () => {
    const formData = new FormData();
    if (files) {
      files.forEach((file: any) => {
        if (file.valid) {
          formData.append("fileBackup", file.file);
        }
      });
    }
    dispatch(restoreDatabase(formData));
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogContent>
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          textAlign={"center"}
          sx={{ position: "relative" }}
        >
          {/*<Box
            sx={{
              position: "absolute",
              top: 0,
              right: !isMobile ? 1 : 3,
              cursor: "pointer",
            }}
          >
            <SmallCloseIcon onClick={() => onClose()} />
          </Box>*/}
        </AlertDialogHeader>
        <AlertDialogBody textAlign={"center"} mt={5}>
          <Box>
            <UploadFileInput
              accept=".sql"
              //files={files || []}
              isEditing={true}
              sx={{ mb: 3 }}
              onFilesChange={(file: any) => {
                setFiles(file);
              }}
              //  maxSize={20000 * 1024 * 1024}
              clearIcon
              maxFiles={1}
            />
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
          }}
        >
          <Button ref={cancelRef} onClick={onClose} size={"sm"}>
            {t("button.cancel")}
          </Button>
          <Button
            ml={3}
            //colorScheme="twitter"
            onClick={() => handleClick()}
            size={"sm"}
            //isLoading={isActionLoading}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateFileUploadRestoreDialog;
