import React, { memo, useMemo } from "react";

import { Text, Box } from "@chakra-ui/react";

interface IUser {
  id: string;
  display: string;
}

interface ISectionProps {
  content: string;
  users: IUser[];
  getFor?: "text" | "element";
}

const HighlightedText: React.FC<ISectionProps> = ({
  content,
  users,
  getFor = "element",
}) => {
  // Regular expression to match user mentions
  const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const matches: any[] = [];
  let match;

  // Find all matches in the text
  while ((match = regex.exec(content)) !== null) {
    matches.push({
      fullMatch: match[0],
      usernameWithBrackets: match[1],
      userId: match[2],
      index: match.index,
    });
  }

  const highlightedText = useMemo(() => {
    return matches.map((mention, index) => {
      const { usernameWithBrackets, userId, index: mentionIndex } = mention;

      // Check if the mention has the correct format
      const validFormat = usernameWithBrackets && userId;

      // Find the index of the mentioned user in the users array if the format is valid
      const userIndex = validFormat
        ? users.findIndex(
            (u: any) => u.id === userId && u.display === usernameWithBrackets
          )
        : -1;

      const mentionComponent =
        validFormat && userIndex !== -1 ? (
          getFor === "text" ? (
            `@${usernameWithBrackets}`
          ) : (
            // Render the username as bold and blue
            <Text
              key={mentionIndex}
              fontSize="sm"
              sx={{
                fontWeight: "bold",
                color: "blue.500",
                display: "inline",
              }}
            >
              @{usernameWithBrackets}
            </Text>
          )
        ) : getFor === "text" ? (
          `@${usernameWithBrackets}`
        ) : (
          <Text
            key={mentionIndex}
            fontSize="sm"
            sx={{ display: "inline", color: "red" }} // Display in red for non-existent users
          >
            @user doesn't exist
          </Text>
        );

      if (index === 0) {
        // If it's the first mention, include the content before it
        const contentBeforeFirstMention = content.substring(0, mentionIndex);
        return getFor === "text" ? (
          `${contentBeforeFirstMention} ${mentionComponent}`
        ) : (
          <React.Fragment key={index}>
            <Text fontSize="sm" sx={{ display: "inline" }}>
              {contentBeforeFirstMention}
            </Text>
            {mentionComponent}
          </React.Fragment>
        );
      }

      // Check if there's content between mentions
      const prevMention = matches[index - 1];
      const contentBetweenMentions = content.substring(
        prevMention.index + prevMention.fullMatch.length,
        mentionIndex
      );

      return getFor === "text" ? (
        `${contentBetweenMentions} ${mentionComponent}`
      ) : (
        <React.Fragment key={index}>
          <Text fontSize="sm" sx={{ display: "inline" }}>
            {contentBetweenMentions}
          </Text>
          {mentionComponent}
        </React.Fragment>
      );
    });
  }, [content, users]);

  // Include the content after the last mention
  const lastMention = matches[matches.length - 1];
  const contentAfterLastMention = content.substring(
    lastMention?.index + lastMention?.fullMatch?.length
  );

  highlightedText.push(
    getFor === "text" ? (
      contentAfterLastMention
    ) : (
      <Text
        key={`${lastMention?.index}-index`}
        fontSize="sm"
        sx={{ display: "inline" }}
      >
        {contentAfterLastMention}
      </Text>
    )
  );

  return getFor === "text" ? (
    highlightedText
  ) : (
    <Box as="p">{highlightedText}</Box>
  );
};

export default memo(HighlightedText);
