import _ from "lodash";

import { ActionTypes, Routers } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import {
  ICreateProject,
  IFilterProject,
  IProjectStructure,
  IUpdateProject,
} from "@/Interfaces/Project.interface";
import BoardAction from "./Board.action";

const { createBoard } = BoardAction;

// SINGLE ACTIONS

const setProjectFetchLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_FETCH_LOADING,
  };
};

const setProjectGetLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_GET_LOADING,
  };
};

const setProjectActionLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_ACTION_LOADING,
  };
};

const setProjectMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_PROJECT_META,
    payload,
  };
};

const setProjectPagination = (payload: IFilterProject) => {
  return {
    type: ActionTypes.SET_PROJECT_PAGINATION,
    payload,
  };
};

const saveCurrentDateInScroller = (payload: string) => {
  return {
    type: ActionTypes.SAVE_CURRENT_DATE_IN_SCROLLER,
    payload,
  };
};

const resetProjectStatus = () => {
  return {
    type: ActionTypes.RESET_PROJECT_STATUS,
  };
};

const resetProjectReducer = () => {
  return {
    type: ActionTypes.RESET_PROJECT_REDUCER,
  };
};

const fetchSuccess = (payload: IProjectStructure[]) => {
  return {
    type: ActionTypes.FETCH_PROJECTS_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_PROJECTS_FAILURE,
  };
};

const fetchProjects = (payload: IFilterProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectFetchLoading());
    await APIs.fetchProjects(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setProjectMeta(_.get(result, "meta")));
          await dispatch(setProjectPagination(payload));
          await dispatch(fetchSuccess(resolveResult.items));
        }
      })
      .catch(async (error) => {
        await dispatch(fetchFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getStatisticalSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_STATISTICAL_PROJECTS_SUCCESS,
    payload,
  };
};

const getStatisticalFailure = () => {
  return {
    type: ActionTypes.GET_STATISTICAL_PROJECTS_FAILURE,
  };
};

const getStatisticalProjects = () => {
  return async (dispatch: any) => {
    dispatch(setProjectGetLoading());
    await APIs.getStatistical()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getStatisticalFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(getStatisticalSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(getStatisticalFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchForDashboardSuccess = (payload: IProjectStructure[]) => {
  return {
    type: ActionTypes.FETCH_PROJECTS_FOR_DASHBOARD_SUCCESS,
    payload,
  };
};

const fetchForDashboardFailure = () => {
  return {
    type: ActionTypes.FETCH_PROJECTS_FOR_DASHBOARD_FAILURE,
  };
};

const fetchProjectsForDashboard = (payload: IFilterProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectFetchLoading());
    await APIs.fetchProjects(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchForDashboardFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setProjectPagination(payload));
          await dispatch(fetchForDashboardSuccess(resolveResult.items));
        }
      })
      .catch(async (error) => {
        await dispatch(fetchForDashboardFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_FAILURE,
  };
};

const createProject = (payload: ICreateProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectActionLoading());
    await APIs.createProject(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          // Dispatch createBoard action with the project details
          await dispatch(
            createBoard({
              name: result?.name,
              project: result?.id,
            })
          );
          await dispatch(createSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          Utils.redirect(Routers.PROJECT);
        }
      })
      .catch(async (error) => {
        await dispatch(createFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload: IProjectStructure) => {
  return {
    type: ActionTypes.GET_PROJECT_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_PROJECT_BY_ID_FAILURE,
  };
};

const getProjectById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setProjectGetLoading());
    await APIs.getProjectById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else await dispatch(getByIdSuccess(result));
      })
      .catch(async (error) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_SUCCESS,
  };
};

const deleteFailure = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_FAILURE,
  };
};

const deleteProject = (id: string, filters: IFilterProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectActionLoading());
    await APIs.deleteProject(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(deleteSuccess());
          await dispatch(fetchProjects(filters));
          Utils.redirect(Routers.PROJECT);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusSuccess = () => {
  return {
    type: ActionTypes.CHANGE_PROJECT_STATUS_SUCCESS,
  };
};

const changeStatusFailure = () => {
  return {
    type: ActionTypes.CHANGE_PROJECT_STATUS_FAILURE,
  };
};

const changeProjectStatus = (id: string, status: string) => {
  return async (dispatch: any) => {
    dispatch(setProjectActionLoading());
    await APIs.changeProjectStatus(id, {
      status,
    })
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(changeStatusSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateSuccess = () => {
  return {
    type: ActionTypes.UPDATE_PROJECT_SUCCESS,
  };
};

const updateFailure = () => {
  return {
    type: ActionTypes.UPDATE_PROJECT_FAILURE,
  };
};

const updateProject = (
  id: string,
  payload: IUpdateProject,
  customSuccessMsg?: string,
  onSuccess?: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setProjectActionLoading());
    await APIs.updateProject(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(updateSuccess());
          await Toast({
            title: customSuccessMsg || message,
            status: "success",
          });
          onSuccess && onSuccess();
        }
      })
      .catch(async (error) => {
        await dispatch(updateFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchProjects,
  fetchProjectsForDashboard,
  getStatisticalProjects,
  createProject,
  getProjectById,
  deleteProject,
  updateProject,
  changeProjectStatus,
  resetProjectStatus,
  resetProjectReducer,
  saveCurrentDateInScroller,
};
