import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  payload: [],
  date_deletion_schedule: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_DASHBOARD_REDUCER:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        date_deletion_schedule: [],
        payload: [],
      };
    case ActionTypes.SET_DASHBOARD_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
      };

    case ActionTypes.GET_STATISTICAL_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        payload: payload,
      };
    case ActionTypes.GET_STATISTICAL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        payload: [],
      };

    case ActionTypes.GET_DATE_DELETION_SCHEDULE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        date_deletion_schedule: payload,
      };
    case ActionTypes.GET_DATE_DELETION_SCHEDULE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        date_deletion_schedule: [],
      };
    default:
      return state;
  }
};
