import React, { useMemo, useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import dayjs from "dayjs";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";

import {
  DatePicker,
  Select,
  TextField,
  Textarea,
  TimePicker,
} from "@/Components/Common";
import Utils from "@/Utils";
//import { ITaskStructure } from "@/Interfaces/Board.interface";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import TextareaComponent from "@/Components/Common/Textarea";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";
import { IWorkingDayStructure } from "@/Interfaces/Workingday.interface";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";

const { TIMECARD_STATUS_COLOR } = ENUMS;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: any;
  onRowAction: (action: any, id: string) => void;
}

const dateTypeOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeWeekendOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsSunday = [
  { label: "Sunday", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsWeekend = [
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeWeekendOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsSundayVI = [
  { label: "Ngày chủ nhật", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsWeekendVI = [
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const DetailOvertimeDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
  onRowAction,
}) => {
  const cancelRef = useRef<any>(null);
  const userData = Utils.getSavedUserData();
  const t = useTranslation();
  const language = Utils.getSavedLanguage();

  //  const pagination: boolean = useSelector((state: RootState) =>
  //    _.get(state.LOG_TIME, "pagination")
  //  );

  //  const projects: any = useSelector((state: RootState) =>
  //    _.get(state.PROJECT, "payload")
  //  );

  //  const checkSendOvertime = Utils.hasPermission(
  //    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
  //    "Overtime",
  //    "sendOvertime"
  //  );
  const workingDays: IWorkingDayStructure = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );
  const approveOvertime = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "approved"
  );

  const changeStatus = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "changeStatus"
  );

  const defaultTimeLine = useMemo(
    () => Utils.extractTimeValues(payload?.startTime, payload?.endTime),
    [payload]
  );

  //  const projectOptions = useMemo(
  //    () =>
  //      _.map(projects, (project) => ({
  //        label: project?.name,
  //        value: project?.id,
  //      })),
  //    [projects]
  //  );

  //  const findOverTimeProccessOptions = useMemo(() => {
  //    const foundProject: any = _.find(
  //      projects,
  //      (project) => project.id === payload?.kanbanBoard?.project?.id
  //    );

  //    if (foundProject) {
  //      const kanbanBoard = foundProject.kanbanBoard || {};
  //      const tasks = kanbanBoard.task || [];

  //      return tasks.map((task: ITaskStructure) => ({
  //        value: task.id,
  //        label: task.title,
  //      }));
  //    }

  //    return [];
  //  }, [payload?.kanbanBoard?.project?.id, projects]);

  const getDateTypeOptions = (day: number, language: string) => {
    const isSunday = day === 0;
    const dayOptions = {
      0: { morning: "sundayMorning", afternoon: "sundayAfternoon" },
      1: { morning: "mondayMorning", afternoon: "mondayAfternoon" },
      2: { morning: "tuesdayMorning", afternoon: "tuesdayAfternoon" },
      3: { morning: "wednesdayMorning", afternoon: "wednesdayAfternoon" },
      4: { morning: "thursdayMorning", afternoon: "thursdayAfternoon" },
      5: { morning: "fridayMorning", afternoon: "fridayAfternoon" },
      6: { morning: "saturdayMorning", afternoon: "saturdayAfternoon" },
    };

    const { morning, afternoon } = dayOptions[day as keyof typeof dayOptions];
    const isMorningWorking = workingDays[morning as keyof typeof workingDays];
    const isAfternoonWorking =
      workingDays[afternoon as keyof typeof workingDays];

    let options;

    switch (true) {
      case isMorningWorking && isAfternoonWorking:
        options = "dateTypeOptions";
        break;
      case !isMorningWorking && !isAfternoonWorking:
        options = isSunday ? "dateTypeOptionsSunday" : "dateTypeOptionsWeekend";
        break;
      default:
        options = "dateTypeWeekendOptions";
        break;
    }

    const optionsMapping: any = {
      en: {
        dateTypeOptions,
        dateTypeOptionsSunday,
        dateTypeWeekendOptions,
        dateTypeOptionsWeekend,
      },
      vi: {
        dateTypeOptions: dateTypeOptionsVI,
        dateTypeOptionsSunday: dateTypeOptionsSundayVI,
        dateTypeWeekendOptions: dateTypeWeekendOptionsVI,
        dateTypeOptionsWeekend: dateTypeOptionsWeekendVI,
      },
    };

    return optionsMapping[language][options];
  };

  const _renderOptions = () => {
    const day = dayjs(payload?.date)?.day();
    return getDateTypeOptions(day, language);
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <>
        <Box>
          <TextField
            value={payload?.kanbanBoard?.project?.name}
            label={t("label.projects")}
            placeholder={t("label.projects")}
            size="sm"
            disabled
          />
        </Box>
        <Box>
          <TextField
            value={payload?.task?.title}
            label={t("label.process")}
            placeholder={t("label.process")}
            size="sm"
            disabled
          />
        </Box>
      </>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <DatePicker
          size="sm"
          label={t("label.date")}
          value={payload?.date || ""}
          onDateChange={() => {}}
          disabled
        />

        <Select
          value={payload?.dateType}
          label={t("label.dateType")}
          size="sm"
          options={_renderOptions()}
          placeholder={
            !_.some(
              _renderOptions(),
              (option) => option.value === payload?.dateType
            )
              ? _.startCase(payload?.dateType)
              : ""
          }
          disabled
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <TimePicker
          label={t("label.timeline")}
          defaultValue={defaultTimeLine}
          sx={{
            flex: 1,
          }}
          onTimeChange={() => {}}
          isDisabled
        />
        <TextField
          label={t("label.workedHours")}
          value={payload?.workingTime}
          placeholder="0"
          size="sm"
          isReadOnly
          disabled
          moreInfo={t(
            "message.workingHoursWillBeCalculatedBasedOnfilledTimelineValue"
          )}
        />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>

        <Textarea
          size="sm"
          value={payload?.description || ""}
          placeholder={t("label.description")}
          disabled
        />
      </Box>
      {payload?.note && payload?.overTimeStatus === "rejected" && (
        <Box>
          <Heading
            fontSize="sm"
            sx={{
              mb: 2,
              alignItems: "center",
            }}
          >
            <Icon as={NewspaperIcon} boxSize={3} mr={1} />
            {t("label.reasonForRefusal")}
          </Heading>
          <TextareaComponent size={"sm"} disabled value={payload?.note} />
        </Box>
      )}
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("title.requestOvertimeDetail")}
          </Text>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {payload?.overTimeStatus && (
              <Text
                color={
                  TIMECARD_STATUS_COLOR?.[
                    payload?.overTimeStatus as keyof typeof TIMECARD_STATUS_COLOR
                  ]?.text
                }
                bgColor={
                  TIMECARD_STATUS_COLOR?.[
                    payload?.overTimeStatus as keyof typeof TIMECARD_STATUS_COLOR
                  ]?.background
                }
                sx={{
                  fontSize: 16,
                  textTransform: "capitalize",
                  borderRadius: 15,
                  px: "15px",
                  textAlign: "center",
                }}
              >
                {t(`status.${payload?.overTimeStatus}`)}
              </Text>
            )}
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 450, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          {payload?.overTimeStatus === "pending" && approveOvertime && (
            <Box>
              <Button
                bgColor={"#FF0000"}
                color={"#ffffff"}
                onClick={() => onRowAction("rejected", payload?.id)}
                size={"sm"}
                w={75}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(187, 113, 84,1)",
                  "&:hover": {
                    bgColor: "rgba(187, 113, 84,0.8)",
                  },
                }}
              >
                {t("button.reject")}
              </Button>
              <Button
                onClick={() => onRowAction("approve", payload?.id)}
                ml={3}
                size={"sm"}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(92, 110, 108,1)",
                  "&:hover": {
                    bgColor: "rgba(92, 110, 108,0.8)",
                  },
                }}
              >
                {t("button.approve")}
              </Button>
            </Box>
          )}
          {changeStatus && payload?.overTimeStatus !== "pending" && (
            <Button
              onClick={() => onRowAction("pending", payload?.id)}
              ml={3}
              size={"sm"}
              sx={{
                color: "#fff",
                bgColor: "rgba( 210, 169, 106,1)",
                "&:hover": {
                  bgColor: "rgba( 210, 169, 106,0.8)",
                },
              }}
            >
              {t("button.pending")}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DetailOvertimeDialog;
