import React from "react";
import _ from "lodash";
import { Button as ChakraButton, type ButtonProps } from "@chakra-ui/react";

interface IProps extends ButtonProps {
  children?: JSX.Element | string;
  onClick?(e: any): void;
  isDisabled?: boolean;
}

const Button = React.forwardRef<HTMLElement, IProps>(
  ({ children, onClick, isDisabled, ...props }, ref) => {
    const [isClicked, setIsClicked] = React.useState(false);

    const handleClick = _.throttle((e) => {
      if (!isClicked) {
        onClick && onClick(e);
        setIsClicked(true);
        setTimeout(() => {
          setIsClicked(false);
        }, 1000);
      }
    }, 1000);

    return (
      <ChakraButton
        ref={ref}
        {...props}
        isDisabled={isDisabled}
        onClick={handleClick}
      >
        {children}
      </ChakraButton>
    );
  }
);

export default Button;
