import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";

const setWorkingTimeFetchLoading = () => {
  return {
    type: ActionTypes.SET_WORKING_TIME_FETCH_LOADING,
  };
};

//const setWorkingTimeGetLoading = () => {
//  return {
//    type: ActionTypes.SET_WORKING_TIME_GET_LOADING,
//  };
//};

const resetWorkingTime = () => {
  return {
    type: ActionTypes.RESET_WORKING_TIME,
  };
};

const setWorkingTimeActionLoading = () => {
  return {
    type: ActionTypes.SET_WORKING_TIME_ACTION_LOADING,
  };
};

const fetchWorkingTimeSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_WORKING_TIME_SUCCESS,
    payload,
  };
};

const fetchWorkingTimeFail = () => {
  return {
    type: ActionTypes.FETCH_WORKING_TIME_FAILURE,
  };
};

const fetchWorkingTime = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkingTimeFetchLoading());
    await APIs.fetchWorkingTime(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchWorkingTimeFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchWorkingTimeSuccess(resolveResult?.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchWorkingTimeFail());
      });
  };
};

const updateWorkingTimeSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_WORKING_TIME_SUCCESS,
    payload,
  };
};

const updateWorkingTimeFail = () => {
  return {
    type: ActionTypes.UPDATE_WORKING_TIME_FAILURE,
  };
};

const updateWorkingTime = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkingTimeActionLoading());
    await APIs.updateWorkingTime(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateWorkingTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateWorkingTimeSuccess(result));
          dispatch(fetchWorkingTime());
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateWorkingTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchWorkingTime,
  updateWorkingTime,
  resetWorkingTime,
};
