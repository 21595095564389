import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";

const setIsFetchLoading = () => {
  return {
    type: ActionTypes.SET_TIME_DELETE_FILE_PRIVATE_CHAT_FETCH_LOADING,
  };
};

const setIsActionLoading = () => {
  return {
    type: ActionTypes.SET_TIME_DELETE_FILE_PRIVATE_CHAT_ACTION_LOADING,
  };
};

const resetReducer = () => {
  return {
    type: ActionTypes.RESET_TIME_DELETE_FILE_PRIVATE_CHAT_REDUCER,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ActionTypes.FETCH_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE,
  };
};

const fetchData = () => {
  return async (dispatch: any) => {
    dispatch(setIsFetchLoading());
    await APIs.fetchTimeDeleteFilePrivateChat()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchFail());
        } else {
          await dispatch(fetchSuccess(result?.items?.[0]));
        }
      })
      .catch(async () => {
        await dispatch(fetchFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ActionTypes.CREATE_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE,
  };
};

const createData = (
  payload: { time: string; type: string },
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setIsActionLoading());
    await APIs.createTimeDeleteFilePrivateChat(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(createSuccess());
          console.log("result", response);
          if (result?.id)
            await dispatch(updateData(result?.id, payload, () => {}));
          onSuccess();
        }
      })
      .catch(async () => {
        await dispatch(createFail());
      });
  };
};

const updateSuccess = () => {
  return {
    type: ActionTypes.UPDATE_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS,
  };
};

const updateFail = () => {
  return {
    type: ActionTypes.UPDATE_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE,
  };
};

const updateData = (
  id: string,
  payload: { time: string; type: string },
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setIsActionLoading());
    await APIs.updateTimeDeleteFilePrivateChat(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(fetchData());
          await dispatch(updateSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess();
        }
      })
      .catch(async () => {
        await dispatch(updateFail());
      });
  };
};

export default { fetchData, createData, updateData, resetReducer };
