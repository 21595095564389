import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  DndContext,
  useSensor,
  useSensors,
  closestCorners,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  defaultDropAnimationSideEffects,
  KeyboardSensor,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import List from "./List";
import Column from "./Column";
import CardItem from "./CardItem";
import { Box } from "@chakra-ui/react";
import { ConfirmDialog } from "@/Components/Popup";
import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions } from "@/Actions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { getBoardById, updateColumnInBoardTwice } = BoardActions;

interface ISectionProps {
  data: any[];
}

interface CardStructure {
  id: string;
  name: string;
  desc: string;
  columnId: string;
  placeholderCard?: boolean;
  avatar?: string;
  logtimes?: any[];
}

interface ColumnStructure {
  id: string;
  name: string;
  desc?: string;
  cardOrderIds: string[];
  columnOrderNumber?: string[];
  status?: string;
  cards: CardStructure[];
  order?: string;
  startDate: string;
  endDate: string;
  totalFiles: number;
  totalComments: number;
}

const ACTIVE_DRAG_ITEM_TYPE = {
  COLUMN: "ACTIVE_DRAG_ITEM_TYPE_COLUMN",
  CARD: "ACTIVE_DRAG_ITEM_TYPE_CARD",
};

const Board: React.FC<ISectionProps> = ({ data }) => {
  const t = useTranslation();
  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const dispatch = useTypedDispatch();
  const customDropAnimaton = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [orderedColumns, setOrderedColumns] = useState<any[]>([]);
  const [backupOrderedColumns, setBackupOrderedColumns] = useState<any>(null);

  const [activeDragItemId, setActiveDragItemId] = useState<any>(null);
  const [activeDragItemType, setActiveDragItemType] = useState<any>(null);
  const [activeDragItemData, setActiveDragItemData] = useState<any>(null);
  const [overDragItemData, setOverDragItemData] = useState<any>(null);

  const [oldColumnWhenDraggingCard, setOldColumnWhenDraggingCard] =
    useState<any>(null);

  const [isShowConfirm, setShowIsConfirm] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    setOrderedColumns(data);
  }, [data]);

  useEffect(() => {
    if (
      isShowConfirm &&
      oldColumnWhenDraggingCard &&
      overDragItemData &&
      activeDragItemData
    ) {
      const msg = `${t("message.youChangingTheStatusOf")} ${
        activeDragItemData?.name
      } ${t("message.taskFrom")} ${oldColumnWhenDraggingCard?.name} ${t(
        "message.to"
      )} ${overDragItemData?.name}.`;
      setConfirmMsg(msg);
    }
  }, [
    isShowConfirm,
    oldColumnWhenDraggingCard,
    overDragItemData,
    activeDragItemData,
  ]);

  useEffect(() => {
    if (confirmed) handleChangeStatus();
  }, [confirmed]);

  const generatePlaceholderCard = (column: any) => {
    return {
      id: `${column.id}-placeholder-card`,
      columnId: column.id,
      placeholderCard: true,
    };
  };

  const handleDragStart = (event: any) => {
    setBackupOrderedColumns([...orderedColumns]);
    setActiveDragItemId(event?.active?.id);
    const dragItemType = event?.active?.data?.current?.columnId
      ? ACTIVE_DRAG_ITEM_TYPE.CARD
      : ACTIVE_DRAG_ITEM_TYPE.COLUMN;
    setActiveDragItemType(dragItemType);
    setActiveDragItemData(event?.active?.data?.current);

    //set old column when dragging card
    if (dragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD)
      setOldColumnWhenDraggingCard(findColumnByCardId(event?.active?.id));
  };

  const handleDragOver = (event: any) => {
    // event.preventDefault();
    if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN) return;
    const { active, over } = event;
    if (!active || !over) return;
    const {
      id: activeDraggingCardId,
      data: { current: activeDraggingCardData },
    } = active;
    const { id: overCardId } = over;

    const activeColumn = findColumnByCardId(activeDraggingCardId);
    const overColumn = findColumnByCardId(overCardId);

    if (!activeColumn || !overColumn) return;
    if (activeColumn.id !== overColumn.id) {
      setOverDragItemData(overColumn);
      moveCardBetweenDifferentColumns(
        overColumn,
        overCardId,
        active,
        over,
        activeColumn,
        activeDraggingCardId,
        activeDraggingCardData
      );
    }
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (!over || !active) return;
    // handle drag column
    // if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN) return;

    //handle drag card
    if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD) {
      const {
        id: activeDraggingCardId,
        data: { current: activeDraggingCardData },
      } = active;
      const { id: overCardId } = over;
      const activeColumn = findColumnByCardId(activeDraggingCardId);
      const overColumn = findColumnByCardId(overCardId);
      if (!activeColumn || !overColumn) return;
      setShowIsConfirm(true);
      if (oldColumnWhenDraggingCard?.id !== overColumn?.id) {
        setOrderedColumns((prevColumns) => {
          const overCardIndex = overColumn?.cards?.findIndex(
            (card: CardStructure) => card.id === overCardId
          );

          let newCardIndex;
          const isBelowOverItem =
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;
          const modifier = isBelowOverItem ? 1 : 0;
          // eslint-disable-next-line prefer-const
          newCardIndex =
            overCardIndex >= 0
              ? overCardIndex + modifier
              : overColumn?.cards?.length + 1;

          //clone deep
          const nextColumns = _.cloneDeep(prevColumns);
          const nextActiveColumn = _.find(
            nextColumns,
            (column) => column.id === activeColumn.id
          );
          const nextOverColumn = _.find(
            nextColumns,
            (column) => column.id === overColumn.id
          );
          if (nextActiveColumn) {
            // move card
            nextActiveColumn.cards = _.filter(
              nextActiveColumn?.cards,
              (card) => card.id !== activeDraggingCardId
            );

            // thêm placeholder cho column rỗng: bị kéo card đi
            if (_.isEmpty(nextActiveColumn.cards)) {
              nextActiveColumn.cards = [
                generatePlaceholderCard(nextActiveColumn),
              ];
            }

            nextActiveColumn.cardOrderIds = _.map(
              nextActiveColumn?.cards,
              (card) => card.id
            );
          }

          if (nextOverColumn) {
            // move card
            nextOverColumn.cards = nextOverColumn.cards.filter(
              (card: CardStructure) => card.id !== activeDraggingCardId
            );
            //Thêm card đang kéo vào overColumn theo vị trí index mới
            nextOverColumn.cards = nextOverColumn.cards.toSpliced(
              newCardIndex,
              0,
              {
                ...activeDraggingCardData,
                columnId: nextOverColumn.id,
              }
            );
            //xóa cái placeholder card đi nếu nó có tồn tại
            nextOverColumn.cards = nextOverColumn.cards.filter(
              (card: CardStructure) => !card?.placeholderCard
            );

            // Cập nhật mảng cardOrderIds
            nextOverColumn.cardOrderIds = nextOverColumn?.cards?.map(
              (card: CardStructure) => card.id
            );
          }

          return nextColumns;
        });
      } else {
        //same column
        // get old position
        const oldCardIndex = oldColumnWhenDraggingCard?.cards?.findIndex(
          (card: CardStructure) => card.id === activeDragItemId
        );
        // get new position
        const newCardIndex = overColumn?.cards?.findIndex(
          (card: CardStructure) => card.id === overCardId
        );
        const dndOrderedCards: any = arrayMove(
          oldColumnWhenDraggingCard?.cards,
          oldCardIndex,
          newCardIndex
        );
        setOrderedColumns((prevColumns) => {
          const nextColumns = _.cloneDeep(prevColumns);
          const targetColumn = nextColumns?.find(
            (column) => column.id === overColumn.id
          );
          targetColumn.cards = dndOrderedCards;
          targetColumn.cardOrderIds = dndOrderedCards?.map(
            (card: CardStructure) => card.id
          );
          return nextColumns;
        });
      }
    }
    if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN) {
      const updatedColumns = [...orderedColumns];
      const activeColumn = active?.data?.current;
      const overColumn = over?.data?.current;
      const draggedIndex = updatedColumns.findIndex(
        (col) => col?.id === overColumn?.id
      );
      const targetIndex = updatedColumns.findIndex(
        (col) => col?.id === activeColumn?.id
      );
      // Swap the positions of the draggedColumn and the targetColumn
      [updatedColumns[draggedIndex], updatedColumns[targetIndex]] = [
        updatedColumns[targetIndex],
        updatedColumns[draggedIndex],
      ];
      setOrderedColumns(updatedColumns);

      const activeColumnData = {
        id: activeColumn?.id,
        payload: {
          order: overColumn?.order,
        },
      };

      const overColumnData = {
        id: overColumn?.id,
        payload: {
          order: activeColumn?.order,
        },
      };
      if (activeColumnData?.id !== overColumnData?.id)
        dispatch(
          updateColumnInBoardTwice(
            activeColumnData,
            overColumnData,
            () => {
              boardDetails && dispatch(getBoardById(boardDetails?.id, true));
            },
            () => {
              setOrderedColumns(backupOrderedColumns);
            }
          )
        );
    }
  };

  const moveCardBetweenDifferentColumns = (
    overColumn: ColumnStructure,
    overCardId: string,
    active: any,
    over: any,
    activeColumn: ColumnStructure,
    activeDraggingCardId: string,
    activeDraggingCardData: any
  ) => {
    if (!activeColumn || !overColumn) return;

    const overCardIndex = overColumn?.cards?.findIndex(
      (card: CardStructure) => card.id === overCardId
    );

    let newCardIndex;
    const isBelowOverItem =
      active.rect.current.translated &&
      active.rect.current.translated.top > over.rect.top + over.rect.height;
    const modifier = isBelowOverItem ? 1 : 0;
    // eslint-disable-next-line prefer-const
    newCardIndex =
      overCardIndex >= 0
        ? overCardIndex + modifier
        : overColumn?.cards?.length + 1;

    const nextActiveColumn = { ...activeColumn };
    const nextOverColumn = { ...overColumn };

    if (nextActiveColumn) {
      // Move the card from the active column
      nextActiveColumn.cards = nextActiveColumn.cards.filter(
        (card: CardStructure) => card.id !== activeDraggingCardId
      );

      // Add a placeholder if the active column becomes empty
      if (nextActiveColumn.cards.length === 0) {
        nextActiveColumn.cards = [
          generatePlaceholderCard(nextActiveColumn) as any,
        ];
      }

      nextActiveColumn.cardOrderIds = nextActiveColumn.cards.map(
        (card: CardStructure) => card.id
      );
    }

    if (nextOverColumn) {
      // Remove the card from the over column
      nextOverColumn.cards = nextOverColumn.cards.filter(
        (card: CardStructure) => card.id !== activeDraggingCardId
      );

      // Place the dragged card into the overColumn at the determined index
      nextOverColumn.cards.splice(newCardIndex, 0, {
        ...activeDraggingCardData,
        columnId: nextOverColumn.id,
      });

      // Remove placeholder card if it exists
      nextOverColumn.cards = nextOverColumn.cards.filter(
        (card: CardStructure) => !card.placeholderCard
      );

      nextOverColumn.cardOrderIds = nextOverColumn.cards.map(
        (card: CardStructure) => card.id
      );
    }

    // Update columns accordingly
    setOrderedColumns((prevColumns) => {
      const nextColumns = prevColumns.map((column) => {
        if (column.id === nextActiveColumn.id) {
          return nextActiveColumn;
        } else if (column.id === nextOverColumn.id) {
          return nextOverColumn;
        }
        return column;
      });
      return nextColumns;
    });
  };

  const findColumnByCardId = (cardId: string) =>
    orderedColumns?.find((column) =>
      column?.cards?.map((card: CardStructure) => card.id)?.includes(cardId)
    );

  const handleChangeStatus = () => {};

  return (
    <Box>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCorners}
      >
        <Box w="100%" h="100%">
          <List columns={orderedColumns} />
          <DragOverlay dropAnimation={customDropAnimaton}>
            {(!activeDragItemData || !activeDragItemType) && null}
            {activeDragItemData &&
              activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN && (
                <Column column={activeDragItemData} />
              )}
            {activeDragItemData &&
              activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD && (
                <CardItem card={activeDragItemData} />
              )}
          </DragOverlay>
        </Box>
      </DndContext>

      <ConfirmDialog
        isOpen={isShowConfirm && !!confirmMsg}
        onClose={() => {
          setBackupOrderedColumns(null);
          setActiveDragItemId(null);
          setActiveDragItemType(null);
          setActiveDragItemData(null);
          setOverDragItemData(null);
          setShowIsConfirm(false);
          setConfirmMsg("");
          setOrderedColumns(backupOrderedColumns);
          setConfirmed(false);
        }}
        actionType="save"
        onAction={() => {
          setShowIsConfirm(false);
          setConfirmed(true);
        }}
        body={confirmMsg}
      />
    </Box>
  );
};

export default Board;
