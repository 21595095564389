import { CommonPages } from "@/Pages";

const CommonRouters = {
  children: [
    {
      path: '*',
      element: <CommonPages.NotFound />,
    },
  ],
};

export default CommonRouters;
