// import CommonColors from "@/Themes/CommonColors";
import { Box, Heading, Icon, Tooltip } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Bars4Icon, LinkIcon } from "@heroicons/react/24/outline";

import Utils from "@/Utils";
import { Routers, ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  card: any;
}

const CardItem: React.FC<ISectionProps> = ({ card }) => {
  const t = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: card.id, data: { ...card } });
  const dndKitCardStyles = {
    touchAction: "none",
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
    padding: "5px",
    backgroundColor: !card?.placeholderCard ? "white" : "transparent",
    borderRadius: "4px",
    // border: `1px solid ${
    //   isDragging ? CommonColors.mainBackground : "transparent"
    // }`,
    borderLeft: `2px solid ${
      ENUMS.URGENCY_COLOR[
        card?.extendProps?.urgency as keyof typeof ENUMS.URGENCY_COLOR
      ]?.background
    }`,
  };

  return (
    <Box
      ref={setNodeRef}
      sx={dndKitCardStyles}
      {...(!card?.placeholderCard && { ...attributes })}
      {...(!card?.placeholderCard && { ...listeners })}
      onClick={() =>
        !card?.placeholderCard &&
        Utils.redirect(`${Routers.BOARD_DETAIL}?id=${card.boardId}`)
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Icon
          as={Bars4Icon}
          sx={{
            display: card?.placeholderCard ? "none" : "block",
            cursor: "grabbing",
          }}
        />
        <Heading
          title={card?.name}
          sx={{
            fontWeight: 600,
            fontSize: "small",
            maxW: "200px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden !important",
          }}
        >
          {card?.placeholderCard ? t("label.noDataToDisplay") : card.name}
        </Heading>
        <Tooltip label={t("button.goToProjectDetail")} hasArrow>
          <Icon
            as={LinkIcon}
            sx={{
              ml: "auto",
              display: card?.placeholderCard ? "none" : "block",
            }}
            onClick={(e) => {
              e.stopPropagation();
              Utils.redirect(`${Routers.PROJECT_DETAILS}?id=${card?.id}`);
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CardItem;
