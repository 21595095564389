import React from "react";

// import { useSelector } from "react-redux";

// import Utils from "@/Utils";
import MenuOptions from "@/Components/LayoutPart/SideBar/SideBar.menu";

import {
  InternalIpDialog,
  // ProfileDialog,
  TimekeepingDialog,
} from "@/Components/Popup";
// import AuthenticationAction from "@/Actions/Authentication.action";

import {
  // Avatar,
  // Box,
  // HStack,
  // Text,
  VStack,
  // useDisclosure,
  // Tooltip,
  List,
  // ListItem,
  // Button,
} from "@chakra-ui/react";
// import {
//   ArrowRightOnRectangleIcon,
//   // ClockIcon,
//   // MinusCircleIcon,
// } from "@heroicons/react/24/outline";
//import {
//  // RootState,
//  useTypedDispatch,
//} from "@/Store";
import {
  ENUMS,
  //  Routers
} from "@/Constants";

import CommonColors from "@/Themes/CommonColors";
import NavigationMenu from "./NavigationMenu";
//import TimekeepingAction from "@/Actions/Timekeeping.action";

// interface IOption {
//   label: string;
//   value: string;
//   icon?: JSX.Element;
// }

// interface IMenu {
//   name: string;
//   path: string;
//   icon?: JSX.Element;
// }

interface ISectionProps {
  isOpen: boolean;
  onClick(): void;
}

// const { logout } = AuthenticationAction;
//const { fetchCheckIn } = TimekeepingAction;

const SideBar: React.FC<ISectionProps> = ({ isOpen, onClick }) => {
  //  const dispatch = useTypedDispatch();

  const [typeTimekeeping, setTypeTimekeeping] = React.useState<{
    open: boolean;
    type: string;
  }>({ open: false, type: "" });

  // const [checkIn, setCheckIn] = React.useState<boolean>(false);
  // const [checkOut, setCheckOut] = React.useState<boolean>(false);
  const [isOpenSetting, setIsOpenSetting] = React.useState<boolean>(false);
  // const [isOpenMesageTimekeeping, setIOpenMessageTimekeeping] =
  //   React.useState<boolean>(false);

  // const currentTime = dayjs();
  // const formatTime = currentTime.format("HH:mm:ss");
  // const checkCurrentime = formatTime < "07:30:00";

  // const timekeepingList: any = useSelector((state: RootState) =>
  //   _.get(state.TIMEKEEPING, "checkInList")
  // );

  // const messageTimekeeping: any = useSelector((state: RootState) =>
  //   _.get(state.TIMEKEEPING, "message")
  // );

  // const isCreaetTimekeepingFail: boolean = useSelector((state: RootState) =>
  //   _.get(state.TIMEKEEPING, "isCreaetTimekeepingFail")
  // );

  // const isUpdateForSelfSuccess: any = useSelector((state: RootState) =>
  //   _.get(state.USER, "isUpdateForSelfSuccess")
  // );

  // const profileData: any = React.useMemo(() => {
  //   const result = Utils.getSavedUserData();
  //   return result;
  // }, [isUpdateForSelfSuccess]);

  // const roleNames = _.map(profileData?.userRole, (userRole) =>
  //   userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
  //     ? `3D ${userRole?.role?.name}`
  //     : userRole?.role?.name
  // );
  // const concatenatedRoleNames = roleNames.join(", ");

  // const {
  //   isOpen: isProfileOpen,
  //   onClose: onProfileClose,
  //   onOpen: onProfileOpen,
  // } = useDisclosure();

  // const onAction = (action: string) => {
  //   if (action === "profile") onProfileOpen();
  //   if (action === "logout") dispatch(logout());
  // };

  // React.useEffect(() => {
  //   const uniqueUsers = _.filter(timekeepingList, (itemX: any) => {
  //     return itemX?.id === profileData?.id;
  //   });
  //   const timeCheck = _.filter(uniqueUsers[0]?.timekeeping, (itemX: any) => {
  //     return itemX?.date === dayjs(new Date()).format("YYYY-MM-DD");
  //   });
  //   _.map(timeCheck, (item: any) => {
  //     if (item.type === "check-in") setCheckIn(true);
  //     if (item.type === "check-out") setCheckOut(true);
  //   });
  // }, [timekeepingList, profileData]);

  //  const getDaysOfWeek = (date: Date, dateFormat: string) => {
  //    const daysOfWeek = [];
  //    const firstDayOfWeek = new Date(date);
  //    firstDayOfWeek.setDate(
  //      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
  //    );

  //    for (let i = 0; i < 7; i++) {
  //      const currentDate = new Date(firstDayOfWeek);
  //      currentDate.setDate(firstDayOfWeek.getDate() + i);
  //      daysOfWeek.push(dayjs(currentDate).format(dateFormat));
  //    }

  //    return daysOfWeek;
  //  };

  //  React.useEffect(() => {
  //    const currentDate = new Date();
  //    const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
  //    dispatch(
  //      fetchCheckIn({
  //        page: 0,
  //        limit: 0,
  //        startDate: _.first(weekdays),
  //        endDate: _.last(weekdays),
  //      })
  //    );
  //  }, []);

  // React.useEffect(() => {
  //   if (isCreaetTimekeepingFail) {
  //     setIOpenMessageTimekeeping(true);
  //   }
  // }, [isCreaetTimekeepingFail]);

  // const _renderPopup = () => (
  //   <>
  //     <ProfileDialog isOpen={isProfileOpen} onClose={onProfileClose} />
  //   </>
  // );

  const _renderMenu = () => {
    const findMenu = MenuOptions?.[ENUMS.ROLES.ADMIN];
    if (!findMenu) return null;

    return (
      <List
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: 0,
          position: "relative",
          zIndex: 999,
        }}
      >
        <NavigationMenu isOpen={isOpen} onClick={onClick} />
      </List>
    );
  };

  // const _renderBottomOptions = () => (
  //   <List
  //     sx={{
  //       w: "full",
  //       mt: "auto",
  //       justifyContent: isOpen ? "space-between" : "center",
  //       gap: 2,
  //       transition: "width .5s ease-in-out",
  //       px: "10px",
  //     }}
  //   >
  //     <ListItem>
  //       <HStack
  //         onClick={() => Utils.redirect(Routers.PROFILE)}
  //         sx={{
  //           padding: "10px 0",
  //           borderRadius: 0,
  //           width: "100%",
  //         }}
  //         _hover={{ cursor: "pointer" }}
  //       >
  //         <Avatar
  //           src={profileData?.userData?.avatar?.path}
  //           title={profileData?.userData?.fullName}
  //           boxSize={10}
  //         />
  //         {isOpen && (
  //           <VStack
  //             alignItems="flex-start"
  //             sx={{
  //               transition: "width .5s ease-in-out",
  //             }}
  //           >
  //             <Text
  //               sx={{
  //                 color: "#464C78",
  //                 fontSize: "14px",
  //                 fontStyle: "normal",
  //                 fontWeight: 500,
  //                 lineHeight: "normal",
  //               }}
  //             >
  //               {profileData?.userData?.fullName || "Administrator"}
  //             </Text>
  //             <Text
  //               sx={{
  //                 color: "#A5AACF",
  //                 fontSize: "11px",
  //                 fontStyle: "normal",
  //                 fontWeight: 600,
  //                 lineHeight: "normal",
  //                 textTransform: "upperCase",
  //                 WebkitLineClamp: 1,
  //                 WebkitBoxOrient: "vertical",
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //                 display: "-webkit-box",
  //                 maxW: 200,
  //               }}
  //               title={concatenatedRoleNames}
  //             >
  //               {/* need update */}
  //               {concatenatedRoleNames}
  //             </Text>
  //           </VStack>
  //         )}
  //       </HStack>
  //     </ListItem>
  //     {/* <ListItem sx={{ cursor: "pointer", borderRadius: "10px" }}>
  //       <Tooltip label="Check in" placement="right" hasArrow>
  //         <Button
  //           sx={{
  //             borderRadius: "10px",
  //             width: "100%",
  //             padding: 0,
  //             bgColor: "#ffffff",
  //             fontWeight: 400,
  //             "& .css-vqzcy0": {
  //               width: "100%",
  //             },
  //             "& .css-1fqcv0a": {
  //               width: "100%",
  //             },
  //           }}
  //           isDisabled={checkIn || checkCurrentime}
  //         >
  //           <Box
  //             sx={{
  //               display: "flex",
  //               alignItems: "center",
  //               gap: 1,
  //               p: "10px",
  //               "&:hover": {
  //                 background: CommonColors.mainBackground,
  //                 transition: ".3s ease-in-out",
  //                 color: "#fff",
  //               },
  //               borderRadius: "10px",
  //               transition: ".3s ease-in-out",
  //               justifyContent: isOpen ? "flex-start" : "center",
  //             }}
  //             onClick={() =>
  //               setTypeTimekeeping({ open: true, type: "check-in" })
  //             }
  //           >
  //             <ClockIcon width={20} height={20} />
  //             {isOpen && <Text fontSize="sm">Check In</Text>}
  //           </Box>
  //         </Button>
  //       </Tooltip>
  //     </ListItem>
  //     <ListItem sx={{ cursor: "pointer", borderRadius: "10px" }}>
  //       <Tooltip label="Check out" placement="right" hasArrow>
  //         <Button
  //           sx={{
  //             width: "100%",
  //             padding: 0,
  //             borderRadius: "10px",
  //             bgColor: "#ffffff",
  //             fontWeight: 400,
  //             "& .css-vqzcy0": {
  //               width: "100%",
  //             },
  //             "& .css-1fqcv0a": {
  //               width: "100%",
  //             },
  //           }}
  //           isDisabled={!checkIn || checkOut}
  //         >
  //           <Box
  //             sx={{
  //               display: "flex",
  //               alignItems: "center",
  //               gap: 1,
  //               p: "10px",
  //               "&:hover": {
  //                 background: CommonColors.mainBackground,
  //                 transition: ".3s ease-in-out",
  //                 color: "#fff",
  //               },
  //               borderRadius: "10px",
  //               transition: ".3s ease-in-out",
  //               justifyContent: isOpen ? "flex-start" : "center",
  //             }}
  //             onClick={() =>
  //               setTypeTimekeeping({ open: true, type: "check-out" })
  //             }
  //           >
  //             <MinusCircleIcon width={20} height={20} />
  //             {isOpen && <Text fontSize="sm">Check Out</Text>}
  //           </Box>
  //         </Button>
  //       </Tooltip>
  //     </ListItem> */}
  //     <ListItem
  //       sx={{ cursor: "pointer", borderRadius: "10px" }}
  //       onClick={() => onAction("logout")}
  //     >
  //       <Tooltip label="Check out" placement="auto" hasArrow>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             alignItems: "center",
  //             gap: 1,
  //             p: "10px",
  //             "&:hover": {
  //               background: CommonColors.mainBackground,
  //               transition: ".3s ease-in-out",
  //               color: "#fff",
  //             },
  //             borderRadius: "10px",
  //             transition: ".3s ease-in-out",
  //             justifyContent: isOpen ? "flex-start" : "center",
  //           }}
  //         >
  //           <ArrowRightOnRectangleIcon width={20} height={20} />
  //           {isOpen && <Text fontSize="sm">Logout</Text>}
  //         </Box>
  //       </Tooltip>
  //     </ListItem>
  //   </List>
  // );

  return (
    <VStack
      sx={{
        display: "flex",
        flexDirection: "column",
        transition: "width .5s ease-in-out",
        h: "calc(100vh - 65px)",
        overflow: "auto",
        background: CommonColors.white,
      }}
    >
      {/* {_renderPopup()} */}
      {_renderMenu()}
      {/* {_renderBottomOptions()} */}
      <TimekeepingDialog.CreateTimekeepingDialog
        open={typeTimekeeping.open}
        onClose={() => setTypeTimekeeping({ open: false, type: "" })}
        type={typeTimekeeping.type}
      />
      <InternalIpDialog
        open={isOpenSetting}
        onClose={() => setIsOpenSetting(false)}
      />
      {/* <TimekeepingDialog.MessageTimekeepingDialog
        onClose={() => setIOpenMessageTimekeeping(false)}
        type={messageTimekeeping}
        open={isOpenMesageTimekeeping}
      /> */}
    </VStack>
  );
};

export default SideBar;
