import React from "react";
import _ from "lodash";
import "react-phone-input-2/lib/style.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select as CSelect,
  InputGroup,
  type InputProps,
  type SelectProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-multi-lang";

interface IOption {
  label: string;
  value: string;
}

interface IProps extends SelectProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  onEnter?(): void;
  labelSx?: object;
  inputSx?: object;
  hoverInputSx?: object;
  options: IOption[] | any[];
  displayChoose?: boolean;
  disabled?: boolean;
}

const Select = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      inputSx,
      hoverInputSx,
      options,
      displayChoose,
      disabled,
      ...props
    },
    ref
  ) => {
    const t = useTranslation();
    return (
      <FormControl
        isInvalid={isError}
        isRequired={props.isRequired}
        style={style}
      >
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <InputGroup>
          <CSelect
            disabled={disabled}
            {...props}
            fontSize={14}
            borderRadius={5}
            width="100%"
            boxSizing="border-box"
            ref={ref}
            sx={{
              ...inputSx,
              borderRadius: "8px",
              w: "100%",
            }}
            _hover={{ ...hoverInputSx }}
          >
            {displayChoose && (
              <option disabled value="">
                {t("label.choose")}
              </option>
            )}
            {!_.isEmpty(options) ? (
              _.map(options, (option: IOption, index: number) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))
            ) : !displayChoose ? (
              <option disabled value="">
                No options
              </option>
            ) : (
              ""
            )}
          </CSelect>
        </InputGroup>

        {isError ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default Select;
