import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const initialState = {
  userConnectSocket: [],
  isSidebarExpanded: true,
  currentDateInScroller: "",
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.USER_CONNECT_SOCKET:
      return {
        ...state,
        userConnectSocket: payload,
      };
    case ActionTypes.SET_SIDEBAR_EXPANDED:
      return {
        ...state,
        isSidebarExpanded: payload,
      };
    case ActionTypes.SAVE_CURRENT_DATE_IN_SCROLLER: {
      return {
        ...state,
        currentDateInScroller: payload,
      };
    }

    case ActionTypes.RESET_SOCKET_REDUCER:
      return initialState;

    default:
      return state;
  }
};
