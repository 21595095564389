import React from "react";

import { Button, HStack, Icon, IconButton } from "@chakra-ui/react";

import { type IPaginate } from "@/Types/Common.types";

import { usePagination, DOTS } from "./usePagination";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
interface IProps {
  onPageChange?: (page: number) => void;
  payload: IPaginate;
  siblingCount?: number;
}

const Pagination: React.FC<IProps> = ({
  payload,
  onPageChange,
  siblingCount = 1,
}) => {
  const { totalItems, currentPage, itemsPerPage, totalPages } = payload;

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    itemsPerPage,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  // const onFirst = () => {
  //   onPageChange != null && onPageChange(1);
  // };

  const onPrevious = () => {
    onPageChange != null && onPageChange(currentPage - 1);
  };

  const onSelectPage = (page: number | string) => {
    currentPage !== page &&
      onPageChange != null &&
      typeof page === "number" &&
      onPageChange(page);
  };

  const onNext = () => {
    onPageChange != null && onPageChange(currentPage + 1);
  };

  // const onLast = () => {
  //   onPageChange != null && onPageChange(totalPages);
  // };

  const isDisableLeftArrow = currentPage === 1;

  const isDisabledRightArrow = currentPage === totalPages;

  return (
    <HStack
      sx={{
        justifyContent: "center",
        marginTop: 15,
        borderRadius: "8px",
        backgroundColor: "#F5F7FF",
        height: "55px",
        gap: "13px",
      }}
    >
      {/* <IconButton
        aria-label="paginate-double-left-icon"
        icon={<Icon as={ChevronDoubleLeftIcon} boxSize={4} />}
        size="sm"
        onClick={onFirst}
        isDisabled={isDisableLeftArrow}
      /> */}
      <IconButton
        variant="ghost"
        aria-label="paginate-left-icon"
        color="#515A96"
        icon={<Icon as={ChevronLeftIcon} boxSize={4} />}
        size="sm"
        onClick={onPrevious}
        isDisabled={isDisableLeftArrow}
      />
      {paginationRange.map((pageNumber: string | number, idx: number) => {
        const key = `paginate-page-${pageNumber}-${idx}`;
        if (pageNumber === DOTS)
          return (
            <Button
              variant="ghost"
              size="sm"
              color="#515A96"
              key={key}
              bgColor="none"
              _hover={{ bgColor: "none" }}
            >
              ...
            </Button>
          );

        return (
          <Button
            variant="ghost"
            size="sm"
            fontSize="12px"
            w="28px"
            h="28px"
            color="#515A96"
            fontWeight={500}
            isActive={pageNumber === currentPage}
            _active={{ bgColor: "#bb7154", color: "#fff" }}
            _hover={{ bgColor: "none" }}
            onClick={() => {
              onSelectPage(pageNumber);
            }}
            key={key}
          >
            {pageNumber}
          </Button>
        );
      })}
      <IconButton
        variant="ghost"
        aria-label="paginate-right-icon"
        color="#515A96"
        icon={<Icon as={ChevronRightIcon} boxSize={4} />}
        size="sm"
        onClick={onNext}
        isDisabled={isDisabledRightArrow}
      />
      {/* <IconButton
        aria-label="paginate-double-right-icon"
        icon={<Icon as={ChevronDoubleRightIcon} boxSize={4} />}
        size="sm"
        onClick={onLast}
        isDisabled={isDisabledRightArrow}
      /> */}
    </HStack>
  );
};

export default Pagination;
