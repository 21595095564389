import React, { useLayoutEffect, useState } from "react";

import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { type History } from "history";
import { Provider } from "react-redux";
import { Router, useRoutes } from "react-router-dom";
import { Notifications } from "react-push-notification";

// import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";

import { store } from "@/Store";
import theme from "@/Themes";

import MainRouters from "./MainRouters";
import AuthRouters from "./AuthenticationRouters";
import CommonRouters from "./CommonRouters";
import { GlobalWindowStateProvider } from "../Components/Common/Global/GlobalWindowState";
// import { defineStyleConfig } from "@chakra-ui/react";

import "../index.css";
interface Props {
  history: History;
}

const { ToastContainer, toast } = createStandaloneToast();

const RootRouter: React.FC<{
  history: History;
  children: JSX.Element | JSX.Element[];
}> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const App = () => {
  const elements = useRoutes([AuthRouters, MainRouters, CommonRouters]);
  return <>{elements}</>;
};

const Root = ({ history }: Props) => {
  // const persistor = persistStore(store);
  return (
    <ChakraProvider
      resetCSS={true}
      theme={theme}
      toastOptions={{ defaultOptions: { position: "bottom-left" } }}
    >
      <GlobalWindowStateProvider>
        <Provider store={store}>
          <ToastContainer />
          {/* <PersistGate loading={null} persistor={persistor}>
              <RootRouter history={history}>
                <App />
              </RootRouter>
            </PersistGate> */}
          <RootRouter history={history}>
            <App />
            <Notifications />
          </RootRouter>
        </Provider>
      </GlobalWindowStateProvider>
    </ChakraProvider>
  );
};

export { toast };
export default Root;
