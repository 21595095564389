import React, {
  useState,
  useEffect,
  useCallback,
  // useMemo,
} from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Table } from "rsuite";

import {
  Box,
  Text,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  List,
  ListItem,
  Tooltip,
  Stack,
  Button,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import Utils from "@/Utils";
import { TimesheetActions, UserActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";

import {
  ITimesheetPagination,
  ITimesheetResponse,
  ITimesheetStructure,
} from "@/Interfaces/Timesheet.interface";
import { IUser } from "@/Interfaces/User.interface";
import { NoDataFound } from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";
import CommonColors from "@/Themes/CommonColors";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

const {
  createTimesheet,
  updateTimesheet,
  deleteTimesheet,
  resetTimesheetStatus,
} = TimesheetActions;

const { performAction: userPerformAction, resetActionUserReducer } =
  UserActions;

interface IDayData {
  id: string;
  date: string;
  status: string;
  workFromHome: boolean;
  workFromHomeAfternoon?: boolean;
}

interface IDataStructure {
  id: string;
  name: string;
  avatar: string;
  days: IDayData[];
  workAtHome: number;
  totalWorkingDays: number;
  user?: any;
}

interface StatusDropdownProps {
  pagination: any;
  matchingDay: IDayData | undefined;
  targetValue: {
    user: string;
    date: string;
  };
  onCloseDropdown(): void;
  onChangeStatusSuccess(data: ITimesheetStructure): void;
  onDeleteStatusSuccess(): void;
}

const options = ["X", "K/2", "K", "P/2", "P", "L", "TV"];
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  matchingDay,
  targetValue,
  onCloseDropdown,
  onChangeStatusSuccess,
  onDeleteStatusSuccess,
  pagination,
}) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const handleClick = useCallback(
    (option: string) => {
      onCloseDropdown();
      if (matchingDay?.id) {
        if (option === matchingDay?.status)
          dispatch(
            deleteTimesheet(pagination, matchingDay?.id, () =>
              onDeleteStatusSuccess()
            )
          );
        else
          dispatch(
            updateTimesheet(
              pagination,
              matchingDay?.id,
              {
                tick: option,
              },
              (data) => onChangeStatusSuccess(data)
            )
          );
      } else {
        dispatch(
          createTimesheet(
            pagination,
            { ...targetValue, tick: option },
            (data) => onChangeStatusSuccess(data)
          )
        );
      }
    },
    [dispatch, matchingDay, onCloseDropdown]
  );

  return (
    <List
      sx={{
        position: "relative",
      }}
    >
      {_.map(options, (option) => (
        <Tooltip
          hasArrow
          key={option}
          label={
            option === matchingDay?.status
              ? t("message.unselect")
              : t("message.select")
          }
        >
          <ListItem
            value={option}
            sx={{
              cursor: "pointer",
              w: "50px",
              p: 2,
              "&:hover": {
                background: "gray.100",
              },
              fontWeight: 500,
              background:
                matchingDay?.status === option ? "gray.100" : "transparent",
              color: !_.includes(["X", "TV"], option) ? "red" : "inherit",
            }}
            onClick={() => handleClick(option)}
          >
            {option}
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
};

interface SectionProps {
  keyword: string;
}

const TableComponent: React.FC<SectionProps> = ({ keyword }) => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const userData = Utils.getSavedUserData();
  let popoverRefs: any = {};
  const dispatch = useTypedDispatch();
  const canUpdateTimesheet = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timesheet",
    "updateTimesheet"
  );
  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );
  const timesheets = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "payload")
  );

  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );
  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "pagination")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "isFetchLoading")
  );

  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "isGetLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "isActionLoading")
  );

  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "isCreateSuccess")
  );

  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "isUpdateSuccess")
  );

  const requestIsFailure: boolean = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "requestIsFailure")
  );

  const startDate = dayjs(pagination?.startDate);
  const daysInMonth = Array.from(
    { length: startDate.daysInMonth() },
    (_, index) =>
      startDate.startOf("month").add(index, "day").format("YYYY-MM-DD")
  );
  const [data, setData] = useState<any[]>([]);
  const [selectedUserAndDay, setSelectedUserAndDay] = useState<{
    userId: string;
    day: string;
  } | null>(null);

  /**
   * Transforms the provided timesheet data and user data into a unified array
   * of IDataStructure, ensuring that all users are included, even if they have
   * no timesheet entries. The function uses tickValueMapping to calculate total
   * working days based on timesheet entries.
   *
   * @param {ITimesheetResponse[]} timesheets - Array of timesheet data.
   * @param {IUser[]} users - Array of user data.
   * @returns {IDataStructure[]} - Transformed array containing user information
   * and timesheet data.
   */

  const transformData = (
    timesheets: ITimesheetResponse[],
    users: IUser[],
    keyword: string
  ): IDataStructure[] => {
    const data: IDataStructure[] = [];
    const tickValueMapping = {
      X: 1,
      "K/2": 0.5,
      K: 0,
      "P/2": 1,
      P: 1,
      L: 1,
      TV: 1,
    };

    // Iterate through users to create the transformed data
    if (users?.length > 0 && canUpdateTimesheet) {
      _.forEach(users, (user) => {
        // Skip users with 'admin' role
        // Filter by pagination
        const userId = user.id;

        if (!_.isEmpty(pagination?.roleCode)) {
          const userRoles = _.map(
            user?.userRole,
            (userRole) => userRole?.role?.roleCode
          );
          if (
            !_.some(pagination?.roleCode, (roleCode) =>
              _.includes(userRoles, roleCode)
            )
          )
            return;
        }

        if (
          !_.isEmpty(pagination?.userId) &&
          !_.includes(pagination?.userId, userId)
        ) {
          return;
        }

        if (
          !_.isEmpty(pagination?.keyword) &&
          !_.includes(user?.userData?.fullName, pagination?.keyword)
        ) {
          return;
        }

        const employee = _.find(timesheets, { id: userId });
        // Check if the user is in the timesheet, otherwise use user data
        const userItem: IUser = employee ? { ...user, ...employee } : user;
        const employeeData: IDataStructure = {
          id: userId,
          name: userItem?.userData?.fullName,
          avatar: userItem?.userData?.avatar?.path,
          days: [],
          workAtHome: 0,
          totalWorkingDays: 0,
          user: userItem,
        };

        // If user is in timesheet, iterate through timesheet entries
        if (userItem?.timesheet) {
          _.forEach(userItem?.timesheet, (entry) => {
            const dayData: IDayData = {
              id: entry.id,
              date: dayjs(entry.date).format("YYYY-MM-DD"),
              status: entry.tick,
              workFromHome: entry.workFromHome,
              workFromHomeAfternoon: entry.workFromHomeAfternoon,
            };
            if (entry.workFromHome && entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 1;
            }
            if (entry.workFromHome && !entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 0.5;
            }
            if (!entry.workFromHome && entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 0.5;
            }

            employeeData.days.push(dayData);
            employeeData.totalWorkingDays +=
              tickValueMapping[entry.tick as keyof typeof tickValueMapping] ||
              0;
          });
        }
        data.push(employeeData);
      });
    } else {
      _.forEach(timesheets, (employee) => {
        const employeeData: IDataStructure = {
          id: employee.id,
          name: employee.userData.fullName,
          avatar: employee.userData?.avatar?.path,
          days: [],
          workAtHome: 0,
          totalWorkingDays: 0,
          user: employee,
        };

        _.forEach(employee.timesheet, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            status: entry.tick,
            workFromHome: entry.workFromHome,
            workFromHomeAfternoon: entry.workFromHomeAfternoon,
          };
          if (entry.workFromHome && entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 1;
          }
          if (entry.workFromHome && !entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 0.5;
          }
          if (!entry.workFromHome && entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 0.5;
          }
          employeeData.days.push(dayData);
          employeeData.totalWorkingDays +=
            tickValueMapping[entry.tick as keyof typeof tickValueMapping] || 0;
        });

        data.push(employeeData);
      });
    }

    // Sort the data by user name in lowercase
    const sortedData = _.sortBy(data, (employeeData) =>
      employeeData.name.toLowerCase()
    );

    const filteredUsers = sortedData.filter((user) => {
      const normalizedUserName = user.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const normalizedKeyword = keyword
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return normalizedUserName
        .toLowerCase()
        .includes(normalizedKeyword.toLowerCase());
    });

    //const filteredUsers = sortedData.filter((user) =>
    //  user.name.toLowerCase().includes(keyword.toLowerCase())
    //);

    return filteredUsers;
  };

  const transformedData = React.useMemo(() => {
    return transformData(timesheets, users, keyword);
  }, [timesheets, users, keyword]);

  // const transformedData = useMemo(() => {
  //   return transformData(timesheets, users);
  // }, [timesheets, users]);

  useEffect(() => {
    setData(transformedData);
  }, [transformedData]);

  useEffect(() => {
    return () => {
      dispatch(resetActionUserReducer());
    };
  }, []);

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess || requestIsFailure) {
      dispatch(resetTimesheetStatus());
    }
  }, [isUpdateSuccess, isCreateSuccess, requestIsFailure]);

  const handlePopoverOpen = (userId: string, day: string) => {
    setSelectedUserAndDay({ userId, day });
  };

  const handlePopoverClose = () => {
    setSelectedUserAndDay(null);
  };

  const updateNewData = (newData: ITimesheetStructure) => {
    const { user } = newData;
    const resolvedData = [...data];
    const userIndex = _.findIndex(resolvedData, { id: user.id });
    if (userIndex !== -1) {
      const { days } = resolvedData[userIndex];
      const { id, tick, date, workFromHome } = newData;
      const dayIndex = _.findIndex(days, { id: id });
      if (dayIndex !== -1) resolvedData[userIndex].days[dayIndex].status = tick;
      else {
        const transformData = {
          id,
          status: tick,
          date,
          workFromHome,
        };
        resolvedData[userIndex].days.push(transformData);
      }
      setData(resolvedData);
    }
  };

  const removeData = (userId: string, dataId: string) => {
    setData((prevData) =>
      _.map(prevData, (user) =>
        user.id === userId
          ? { ...user, days: _.filter(user.days, (day) => day.id !== dataId) }
          : user
      )
    );
  };

  const _renderCellDayInMonth = useCallback(() => {
    return _.map(daysInMonth, (day) => {
      const isWeekend =
        Utils.getDayOfWeek(day, "short") === "Sun" ||
        Utils.getDayOfWeek(day, "short") === "CN";
      const today = dayjs(dayjs().format("YYYY-MM-DD")).isSame(
        dayjs(day).format("YYYY-MM-DD")
      );
      return (
        <Column key={day} align="center" width={80}>
          <HeaderCell
            verticalAlign="center"
            style={{
              ...HEADER_STYLE,
              background: isWeekend
                ? "rgb(234, 236,251,100)"
                : today
                ? "rgba(97, 149, 237, 0.5)"
                : "inherit",
              cursor: isWeekend ? "not-allowed" : "inherit",
            }}
          >
            <Stack
              direction="column"
              rowGap={1}
              justifyContent="center"
              alignItems="center"
            >
              <Box color={isWeekend ? "red" : "inherit"}>
                {Utils.getDayOfWeek(day, "short")}
              </Box>
              <Box color={isWeekend ? "red" : "inherit"}>
                {dayjs(day).get("date")}
              </Box>
            </Stack>
          </HeaderCell>
          <Cell
            style={{
              background: isWeekend ? "rgb(234, 236,251,100)" : "inherit",
              cursor: isWeekend ? "not-allowed" : "inherit",
            }}
          >
            {(rowData) => {
              // if (isWeekend) return null; // hidden sunday
              const matchingDay = _.find(rowData.days, (dayData: IDayData) =>
                dayjs(dayjs(dayData.date).format("YYYY-MM-DD")).isSame(
                  dayjs(day).format("YYYY-MM-DD")
                )
              );
              const shouldRenderPopover =
                selectedUserAndDay &&
                selectedUserAndDay.userId === rowData.id &&
                selectedUserAndDay.day === day;

              const goodAttendance = ["X", "TV"];

              return shouldRenderPopover ? (
                <Popover
                  placement="auto"
                  isLazy
                  isOpen={shouldRenderPopover}
                  onClose={() => {
                    handlePopoverClose();
                  }}
                >
                  <PopoverTrigger>
                    <Button
                      sx={{
                        w: "100%",
                        h: "100%",
                        fontSize: "14px",
                        fontWeight: 500,
                        background: CommonColors.dustyCoral,
                        "&:hover": {
                          background: CommonColors.warmCopper,
                        },
                        color:
                          _.includes(goodAttendance, matchingDay?.status) ||
                          !matchingDay?.status
                            ? "inherit"
                            : "red",
                      }}
                      variant="ghost"
                      rightIcon={<ChevronDownIcon />}
                    >
                      <Tooltip
                        label={`${
                          matchingDay?.workFromHome ||
                          matchingDay?.workFromHomeAfternoon
                            ? "Work At Home"
                            : ""
                        }`}
                      >
                        {matchingDay?.status || "--"}
                      </Tooltip>
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      sx={{
                        width: "max-content",
                      }}
                    >
                      <StatusDropdown
                        matchingDay={matchingDay}
                        targetValue={{
                          user: rowData?.id,
                          date: day,
                        }}
                        onCloseDropdown={() => {
                          if (popoverRefs[`${day}${rowData?.id}`])
                            popoverRefs[`${day}${rowData?.id}`].click();
                          setSelectedUserAndDay(null);
                        }}
                        onChangeStatusSuccess={updateNewData}
                        onDeleteStatusSuccess={() =>
                          removeData(rowData.id, matchingDay?.id)
                        }
                        pagination={pagination}
                      />
                    </PopoverContent>
                  </Portal>
                </Popover>
              ) : (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (canUpdateTimesheet) handlePopoverOpen(rowData?.id, day);
                  }}
                  sx={{
                    w: "100%",
                    h: "100%",
                    fontSize: "14px",
                    fontWeight: 500,
                    color:
                      _.includes(goodAttendance, matchingDay?.status) ||
                      !matchingDay?.status
                        ? "inherit"
                        : "red",
                  }}
                  variant="ghost"
                  rightIcon={
                    shouldRenderPopover ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )
                  }
                >
                  <Tooltip
                    label={`${
                      matchingDay?.workFromHome ||
                      matchingDay?.workFromHomeAfternoon
                        ? "Work At Home"
                        : ""
                    }`}
                  >
                    {matchingDay?.status || "--"}
                  </Tooltip>
                </Button>
              );
            }}
          </Cell>
        </Column>
      );
    });
  }, [selectedUserAndDay, daysInMonth]);

  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={data}
        autoHeight={data?.length <= 10}
        affixHeader
        // affixHorizontalScrollbar
        loading={isFetchLoading || isGetLoading || isActionLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={80}
        bordered
        cellBordered
        wordWrap
        height={isMobile ? 400 : 550}
      >
        <Column width={250} fixed={!isMobile} resizable verticalAlign="middle">
          <HeaderCell style={HEADER_STYLE}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='rgba(0, 0, 0, .5)' vector-effect='non-scaling-stroke'/></svg>")`,
                backgroundSize: "100% 100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: 5,
                  fontSize: 17,
                }}
              >
                {t("table.date")}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "5px",
                  left: 5,
                  fontSize: 17,
                }}
              >
                {t("table.name")}
              </Box>
            </Box>
          </HeaderCell>
          <Cell dataKey="user.userData.fullName">
            {(rowData) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Tooltip
                    label={canViewUserProfile ? t("tooltip.viewProfile") : ""}
                    hasArrow
                  >
                    <Avatar
                      size="xs"
                      sx={{
                        cursor: canViewUserProfile ? "pointer" : "default",
                      }}
                      name={rowData?.name}
                      src={rowData?.avatar}
                      onClick={() =>
                        canViewUserProfile &&
                        dispatch(
                          userPerformAction(
                            "viewProfile",
                            rowData?.id,
                            rowData?.user
                          )
                        )
                      }
                    />
                  </Tooltip>
                  <Text fontSize="sm" fontWeight={500}>
                    {rowData.name}
                  </Text>
                </Box>
              );
            }}
          </Cell>
        </Column>
        {_renderCellDayInMonth()}
        <Column
          align="center"
          width={100}
          resizable
          fixed={!isMobile && "right"}
        >
          <HeaderCell
            verticalAlign="center"
            style={{ ...HEADER_STYLE, textAlign: "center" }}
          >
            {t("table.workAtHome")}
          </HeaderCell>
          <Cell dataKey="workAtHome" />
        </Column>
        <Column
          align="center"
          width={100}
          resizable
          fixed={!isMobile && "right"}
        >
          <HeaderCell
            verticalAlign="center"
            style={{ ...HEADER_STYLE, textAlign: "center" }}
          >
            {t("table.totalWorkingDays")}
          </HeaderCell>
          <Cell dataKey="totalWorkingDays" />
        </Column>
      </Table>
    </Box>
  );
};

export default TableComponent;
