import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { TIME_DELETE_PRIVATE_CHAT } = APIs;

export const fetchTimeDeleteFilePrivateChat = async () => {
  return request(TIME_DELETE_PRIVATE_CHAT.BASIC, "GET");
};

export const createTimeDeleteFilePrivateChat = async (payload: {
  time: string;
  type: string;
}) => {
  return request(TIME_DELETE_PRIVATE_CHAT.BASIC, "POST", payload);
};

export const updateTimeDeleteFilePrivateChat = async (
  id: string,
  payload: {
    time: string;
    type: string;
  }
) => {
  return request(`${TIME_DELETE_PRIVATE_CHAT.BASIC}/${id}`, "PUT", payload);
};
