import AccountDeactivated from "./AccountDeactivated";
import ComingSoon from "./ComingSoon";
import Error404 from "./Error404";
import Error500 from "./Error500";
import SignIn from "./SignIn";
import WelcomeMessage from "./WelcomeMessage";
import ResetPassword from "./ResetPassword";
import VerifyAccount from "./VerifyAccount";
import VerifyAccountFail from "./VerifyFailure";
import VerifyAccountSuccess from "./VeriFySuccess";

export default {
  AccountDeactivated,
  ComingSoon,
  Error404,
  Error500,
  SignIn,
  WelcomeMessage,
  VerifyAccount,
  VerifyAccountFail,
  VerifyAccountSuccess,
  ResetPassword,
};
