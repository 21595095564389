import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import { ITaskNoteFilters } from "@/Interfaces/TaskNote.interface";

// SINGLE ACTIONS
const setTaskNoteFetchLoading = () => {
  return {
    type: ActionTypes.SET_TASK_NOTE_FETCH_LOADING,
  };
};

const setTaskNoteGetLoading = () => {
  return {
    type: ActionTypes.SET_TASK_NOTE_GET_LOADING,
  };
};

const setTaskNoteActionLoading = () => {
  return {
    type: ActionTypes.SET_TASK_NOTE_ACTION_LOADING,
  };
};

const resetTaskNoteDetails = () => {
  return {
    type: ActionTypes.RESET_TASK_NOTE_DETAILS,
  };
};

const resetTaskNoteRecucer = () => {
  return {
    type: ActionTypes.RESET_TASK_NOTE_REDUCER,
  };
};

//ASYNC ACTIONS

const fetchSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_TASK_NOTE_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_TASK_NOTE_FAILURE,
  };
};

const fetchTaskNote = (payload: ITaskNoteFilters) => {
  return async (dispatch: any) => {
    dispatch(setTaskNoteFetchLoading());
    await APIs.fetchTaskNote(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(fetchSuccess(result?.items));
      })
      .catch(async (error: any) => {
        await Toast({
          title: error?.message,
          status: "error",
        });
        await dispatch(fetchFailure());
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_TASK_NOTE_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_TASK_NOTE_FAILURE,
  };
};

const createTaskNote = (payload: FormData, onSuccess: () => void) => {
  return async (dispatch: any) => {
    dispatch(setTaskNoteActionLoading());
    await APIs.createTaskNote(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess();
        }
      })
      .catch(async (error) => {
        await Toast({
          title: error?.message,
          status: "error",
        });
        await dispatch(createFailure());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_TASK_NOTE_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_TASK_NOTE_BY_ID_FAILURE,
  };
};

const getTaskNoteById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setTaskNoteGetLoading());
    await APIs.getTaskNoteById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdSuccess(result));
      })
      .catch(async (error) => {
        await Toast({
          title: error?.message,
          status: "error",
        });
        await dispatch(getByIdFailure());
      });
  };
};

const updateTaskNoteSuccess = () => {
  return {
    type: ActionTypes.UPDATE_TASK_NOTE_SUCCESS,
  };
};

const updateTaskNoteFailure = () => {
  return {
    type: ActionTypes.UPDATE_TASK_NOTE_FAILURE,
  };
};

const updateTaskNote = (
  id: string,
  payload: FormData,
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setTaskNoteActionLoading());
    await APIs.updateTaskNote(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateTaskNoteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateTaskNoteSuccess());
          onSuccess();
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await Toast({
          title: error?.message,
          status: "error",
        });
        await dispatch(updateTaskNoteFailure());
      });
  };
};

const deleteTaskNoteSuccess = () => {
  return {
    type: ActionTypes.DELETE_TASK_NOTE_SUCCESS,
  };
};

const deleteTaskNoteFailure = () => {
  return {
    type: ActionTypes.DELETE_TASK_NOTE_FAILURE,
  };
};

const deleteTaskNote = (id: string, onSuccess: () => void) => {
  return async (dispatch: any) => {
    dispatch(setTaskNoteActionLoading());
    await APIs.deleteTaskNote(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteTaskNoteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await Toast({
            title: message,
            status: "success",
          });
          dispatch(deleteTaskNoteSuccess());
          onSuccess();
        }
      })
      .catch(async (error) => {
        await Toast({
          title: error?.message,
          status: "error",
        });
        await dispatch(deleteTaskNoteFailure());
      });
  };
};

export default {
  fetchTaskNote,
  createTaskNote,
  getTaskNoteById,
  updateTaskNote,
  deleteTaskNote,
  resetTaskNoteDetails,
  resetTaskNoteRecucer,
};
