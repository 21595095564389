import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Select, TextField, Button } from "@/Components/Common";
import { LeaveDaysActions, UserActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { IFilterLeaveDays } from "@/Interfaces/LeaveDays.interface";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";
//import { IDataLeaveDays } from "@/Interfaces/LeaveDays.interface";

const { createLeaveDays, fetchLeaveDays } = LeaveDaysActions;
const { fetchUser, resetUserReducer } = UserActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

interface DataYear {
  label: number;
  value: number;
}

const DEFAULT_FORM_DATA = {
  leaveDay: 0,
  user: "",
  year: new Date().getFullYear(),
};

const CreateLeaveDaysDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [dataOptions, setDataOptions] = useState<any[]>([]);

  const userList = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const isCreateSuccess = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "isCreateSuccess")
  );

  const payload: any = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "LeaveDaysFilterList")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "isActionLoading")
  );

  const pagination: IFilterLeaveDays = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "pagination")
  );

  const schema = yup
    .object()
    .shape({
      year: yup.number(),
      leaveDay: yup.number().required(t("message.leaveDaysIsRequired")),
      user: yup.string().trim().required(t("message.userIsRequired")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    dispatch(createLeaveDays(data, pagination));
  };

  const yearsOptions = useMemo(() => {
    let allyears: DataYear[] = [];
    const minYear = -(
      new Date().getFullYear() - new Date("2010-01-01T23:15:30").getFullYear()
    );
    const maxYear = 1;
    const thisYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      allyears.push({ label: thisYear + i, value: thisYear + i });
    }
    return allyears;
  }, []);

  const userOptions = useMemo(() => {
    return _.map(dataOptions, (user: any) => {
      return {
        value: user.id,
        label: user?.userData?.fullName,
        extendProps: {
          email: user?.email,
          avatar: user?.userData?.avatar?.path,
        },
      };
    });
  }, [dataOptions]);

  useEffect(() => {
    const uniqueUsers = _.filter(userList, (itemX: any) => {
      return !payload?.some((itemY: any) => itemX.id === itemY?.user?.id);
    });
    setDataOptions(uniqueUsers);
  }, [payload, userList]);

  useEffect(() => {
    if (open)
      dispatch(
        fetchUser({ page: 0, limit: 0, status: ENUMS.USER_STATUS.ACTIVE })
      );
    return () => {
      dispatch(resetUserReducer());
    };
  }, [open]);

  useEffect(() => {
    if (open)
      dispatch(
        fetchLeaveDays(
          { page: 0, limit: 0, year: new Date().getFullYear() },
          "filter"
        )
      );
  }, [open]);

  useEffect(() => {
    if (isCreateSuccess) {
      reset();
      onClose();
    }
  }, [isCreateSuccess]);
  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const _renderContent = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box w={150} mr={2}>
            <Controller
              name="year"
              control={control}
              render={({ field }) => (
                <Select
                  value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e.target.value);
                    dispatch(
                      fetchLeaveDays(
                        {
                          page: 0,
                          limit: 0,
                          year: e.target.value,
                        },
                        "filter"
                      )
                    );
                  }}
                  label={t("label.year")}
                  size="sm"
                  options={yearsOptions}
                  isRequired
                />
              )}
            />
          </Box>
          <Controller
            name="user"
            control={control}
            render={({ field }) => (
              <Autocomplete
                //  isClear={isClearFilters}
                onChange={(e: any) => field.onChange(e)}
                options={userOptions}
                placeholder={t(`label.selectUsers`)}
                isError={!_.isEmpty(errors.user?.message)}
                errorMessage={errors?.user?.message}
                label={t("label.users")}
                isRequired
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="leaveDay"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.leaveDays")}
                placeholder={t("label.leaveDays")}
                size="sm"
                isError={!_.isEmpty(errors.leaveDay?.message)}
                errorMessage={errors?.leaveDay?.message}
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <Box as={"form"}>
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 1px 2px -1px gray",
              borderRadius: "10px 10px 0 0",
            }}
          >
            {t("title.leaveDays")}
          </AlertDialogHeader>
          <AlertDialogBody>{_renderContent()}</AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              borderRadius: "0 0 10px 10px",
              boxShadow: "0px 0px 2px gray",
            }}
          >
            <Button
              ref={cancelRef}
              onClick={onClose}
              size={"sm"}
              isLoading={isActionLoading}
              w={75}
            >
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              //  colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              isLoading={isActionLoading}
              w={75}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Box>
    </AlertDialog>
  );
};

export default CreateLeaveDaysDialog;
