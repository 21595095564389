import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isActionLoading: false,
  isUpdataSuccess: false,
  isCreateSuccess: false,
  isChangeStatusSuccess: false,
  isGetDatabaseLoading: false,
  isDeleteSuccess: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  backupStatus: {},
  backupDatabase: [],
  allMemoryConversation: 0,
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_BACKUP:
      return {
        ...initialState,
      };
    case ActionTypes.SET_BACKUP_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_BACKUP_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_META_BACKUP:
      return {
        ...state,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_BACKUP:
      return {
        ...state,
        pagination: payload,
      };

    case ActionTypes.FETCH_BACKUP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
        backupStatus: payload,
      };

    case ActionTypes.FETCH_BACKUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
        backupStatus: {},
      };

    case ActionTypes.FETCH_BACKUP_DATABASE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        restoreDatabaseSuccess: false,
        backupDatabase: payload,
        isActionLoading: false,
        isDeleteSuccess: false,
      };

    case ActionTypes.FETCH_BACKUP_DATABASE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
        backupDatabase: [],
      };

    case ActionTypes.CHANGE_STATUS_BACKUP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
      };
    case ActionTypes.CHANGE_STATUS_BACKUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
      };

    case ActionTypes.GET_BACKUP_DATABASE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetDatabaseLoading: true,
        isChangeStatusSuccess: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
      };
    case ActionTypes.GET_BACKUP_DATABASE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isGetDatabaseLoading: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
      };

    case ActionTypes.RESTORE_DATABASE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetDatabaseLoading: true,
        restoreDatabaseSuccess: true,
        isChangeStatusSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
      };
    case ActionTypes.RESTORE_DATABASE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isGetDatabaseLoading: false,
        restoreDatabaseSuccess: false,
        isDeleteSuccess: false,
        isActionLoading: false,
      };

    case ActionTypes.DELETE_BACKUP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isDeleteSuccess: true,
      };
    case ActionTypes.DELETE_BACKUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isDeleteSuccess: false,
      };

    case ActionTypes.GET_ALL_MEMORY_CONVERSATION_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        allMemoryConversation: payload,
      };
    case ActionTypes.GET_ALL_MEMORY_CONVERSATION_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    default:
      return state;
  }
};
