import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Stack } from "@chakra-ui/react";

import { AdminLayout } from "@/Components/DefaultLayout";

import { ConfirmDialog, TimeLogDialog } from "@/Components/Popup";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import {
  LogtimeActions,
  ProjectActions,
  ProjectExteriorActions,
  UserActions,
  ClientActions,
  RoleActions,
} from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";
import {
  IFilterProject,
  IProjectStructure,
} from "@/Interfaces/Project.interface";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";

import ProjectListInterior from "./ProjectListInterior";
import ProjectListExterior from "./ProjectListExterior";
import { useTranslation } from "react-multi-lang";

const { fetchProjects, deleteProject, resetProjectReducer } = ProjectActions;
const { fetchProjectsExterior, resetProjectExteriorReducer } =
  ProjectExteriorActions;
const { fetchUser, resetUserReducer } = UserActions;
const { resetLogTimePerformAction } = LogtimeActions;
const { fetchClient, resetClientReducer } = ClientActions;
const { fetchRoles } = RoleActions;

const ProjectList: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  // const currentBreakpoint = useBreakpoint();

  const payload = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );

  const pagination: IFilterProject = useSelector((state: RootState) =>
    _.get(state.PROJECT, "pagination")
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const userData = Utils.getSavedUserData();

  const canViewScreenProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "fetchProject"
  );

  const canViewScreenProjectExterior = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "canViewScreenProjectExterior"
  );

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    edit: false,
    delete: false,
    filter: false,
    filterExterior: false,
  });

  const [selectedItem, setSelectedItem] = useState<IProjectStructure | null>(
    null
  );

  const canGetUserList = React.useMemo(() => {
    let hasManagerLeaderAdmin = false;
    _.forEach(userData?.userRole, (userRole) => {
      if (
        userRole?.role?.roleCode === ENUMS.ROLES.ADMIN ||
        userRole?.role?.roleCode === ENUMS.ROLES.MANAGER ||
        userRole?.role?.roleCode === ENUMS.ROLES.LEADER
      )
        hasManagerLeaderAdmin = true;
    });
    return hasManagerLeaderAdmin;
  }, [userData]);

  useEffect(() => {
    if (!_.isEmpty(payload)) dispatch(resetProjectReducer());
    dispatch(
      fetchClient({ page: 0, limit: 0, status: ENUMS.CLIENT_STATUS.ACTIVE })
    );
    dispatch(
      fetchUser({
        page: 0,
        limit: 0,
        status: ENUMS.USER_STATUS.ACTIVE,
      })
    );

    dispatch(
      fetchProjects({
        page: 1,
        limit: 10,
        type: ENUMS.PROJECT_TYPE.INTERIOR,
      })
    );
    dispatch(
      fetchProjectsExterior({
        page: 1,
        limit: 10,
        type: ENUMS.PROJECT_TYPE.EXTERIOR,
      })
    );
    return () => {
      setSelectedItem(null);
      dispatch(resetClientReducer());
      dispatch(resetUserReducer());
      dispatch(resetProjectReducer());
      dispatch(resetProjectExteriorReducer());
      dispatch(resetLogTimePerformAction());
    };
  }, []);

  React.useEffect(() => {
    if (canGetUserList) dispatch(fetchRoles());
  }, []);

  const handleShowPopup = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const _renderPopup = () => (
    <>
      <ConfirmDialog
        isOpen={isShowPopup.delete}
        onClose={() => handleShowPopup("delete", false)}
        body={t("message.thisWillDeleteThisProject")}
        onAction={() => {
          if (selectedItem)
            dispatch(deleteProject(selectedItem.id, pagination));
          handleShowPopup("delete", false);
        }}
        actionType="delete"
      />
      <>
        <TimeLogDialog.CreateTimelogDialog
          open={timeLogPerformAction?.actionType === "logExternal"}
          onClose={() => dispatch(resetLogTimePerformAction())}
        />

        {/*<TimeLogDialog.UpdateTimelogDialog
        open={timeLogPerformAction?.actionType === "editLog"}
        onClose={() => {
          if (searchParams) {
            searchParams.delete("id");
            setSearchParams(searchParams);
          }
          dispatch(resetLogTimePerformAction());
        }}
      />*/}
        {/*<ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(deleteLogTime(timeLogPerformAction.id));
        }}
        body="Do you want remove this log"
        actionType="delete"
      />*/}
      </>
    </>
  );

  const renderMain = () => {
    return (
      <Stack>
        {canViewScreenProject && <ProjectListInterior />}
        {canViewScreenProjectExterior && <ProjectListExterior />}
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default ProjectList;
