import * as React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Utils from "@/Utils";
import { AuthenticationActions } from "@/Actions";
import { useTypedDispatch } from "@/Store";
//import { getSocket } from "@/Configs/socket.config";

type TLocale = "en" | "vi";

const { changeLocale } = AuthenticationActions;

interface ISectionsProps {
  isOpen?: boolean;
  toClient?: boolean;
}

const LanguageSwitch: React.FC<ISectionsProps> = ({ isOpen, toClient }) => {
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [currentLanguage, setCurrentLanguage] = React.useState<TLocale>("en");
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const firebaseToken = Utils.getSavedFirebaseToken();

  React.useEffect(() => {
    const localeStorage = Utils.getSavedLanguage();
    setCurrentLanguage(localeStorage);
  }, []);

  const handleChangeLanguage = async (language: TLocale) => {
    setCurrentLanguage(language);
    Utils.saveLanguage(language);
    if (userData && firebaseToken)
      dispatch(
        changeLocale({
          userId: userData.id,
          locale: language,
          token: firebaseToken,
        })
      );

    window.location.reload();
  };

  React.useEffect(() => {
    const closeTooltip = () => {
      setMenuIsOpen(false);
    };
    document.addEventListener("click", closeTooltip);
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      {currentLanguage === "en" ? (
        <Box
          sx={{
            display: "flex",
            p: 2,
            justifyContent: "space-between",
            cursor: "pointer",
            position: "relative",
            ml: 1,
            border: isOpen && "1px solid white",
            background: isOpen && "#fff",
            borderRadius: isOpen && 5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/1200px-Flag_of_the_United_Kingdom_%281-2%29.svg.png"
              w={!toClient ? "15px" : "25px"}
              height={!toClient ? "10px" : "15px"}
            />
            {!toClient && <Text>English</Text>}
          </Box>
          {!toClient && (
            <ChevronDownIcon
              fontSize={25}
              style={{ width: !toClient ? 15 : 10 }}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            p: 2,
            justifyContent: "space-between",
            cursor: "pointer",
            position: "relative",
            alignItems: "center",
            ml: 1,
            border: isOpen && "1px solid white",
            background: isOpen && "#fff",
            borderRadius: isOpen && 5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Image
              src="https://st.quantrimang.com/photos/image/2021/09/05/Co-Vietnam.png"
              w={!toClient ? "15px" : "25px"}
              height={!toClient ? "10px" : "15px"}
            />
            {!toClient && <Text>Viet Nam</Text>}
          </Box>
          {!toClient && (
            <ChevronDownIcon
              fontSize={25}
              style={{ width: !toClient ? 15 : 10 }}
            />
          )}
        </Box>
      )}

      {menuIsOpen && (
        <Box
          sx={{
            position: "absolute",
            boxShadow: "-8px 8px 24px -4px rgba(145, 158, 171, 0.08)",
            borderRadius: "8px",
            backgroundColor: "white",
            top: toClient ? "25px" : "45px",
            w: toClient ? "50px" : "120px",
            py: 2,
            border: "1px solid grey",
          }}
        >
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              background:
                currentLanguage === "en" && "rgba(166, 183, 175, 0.5)",
              "&:hover": {
                background: "rgba(166, 183, 175, 0.5)",
              },
              p: "2px",
            }}
            onClick={() => {
              handleChangeLanguage("en");
              setMenuIsOpen(false);
            }}
          >
            <Box sx={{ display: "flex", gap: 2, mx: 2 }}>
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/1200px-Flag_of_the_United_Kingdom_%281-2%29.svg.png"
                w={"25px"}
              />
              {!toClient && <Text>English</Text>}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              cursor: "pointer",
              p: "2px",
              mt: "2px",
              background:
                currentLanguage === "vi" && "rgba(166, 183, 175, 0.5)",
              "&:hover": {
                background: "rgba(166, 183, 175, 0.5)",
              },
            }}
            onClick={() => {
              handleChangeLanguage("vi");
              setMenuIsOpen(false);
            }}
          >
            <Box sx={{ display: "flex", gap: 2, mx: 2 }}>
              <Image
                src="https://st.quantrimang.com/photos/image/2021/09/05/Co-Vietnam.png"
                w={"25px"}
              />
              {!toClient && <Text>Viet Nam</Text>}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LanguageSwitch;
