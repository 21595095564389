import React from "react";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
  type TextareaProps,
} from "@chakra-ui/react";

interface IProps extends TextareaProps {
  ref?: any;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  disabled?: boolean;
}

const TextareaComponent = React.forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        isInvalid={isError}
        isRequired={props.isRequired}
        style={style}
      >
        {label ? (
          <FormLabel fontSize="14" fontWeight={700}>
            {label}
          </FormLabel>
        ) : null}
        <Textarea
          disabled={disabled}
          type="text"
          size="sm"
          fontSize={14}
          borderRadius={5}
          errorBorderColor={errorMessage}
          ref={ref}
          {...props}
        />
        {!isError ? (
          <FormHelperText>{helperText}</FormHelperText>
        ) : (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

TextareaComponent.displayName = "Textarea";

export default TextareaComponent;
