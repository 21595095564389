import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Routers } from "@/Constants";
import Utils from "@/Utils";

import backgroundImage_bigSize from "@/Assets/Login.jpg";

const VerifyAccountSuccess: React.FC = () => {
  const [seconds, setSeconds] = useState(5);
  // Constructors

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      Utils.redirect(Routers.SIGN_IN);
    }
  }, [seconds]);

  // Events
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage_bigSize})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          zIndex: 2,
          textAlign: "center",
        }}
      >
        <CheckCircleIcon color="green.500" boxSize={8} />
        <Text
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          mt={4}
          color="#fff"
        >
          The account was successfully verified! The system will reopen in{" "}
          {seconds} seconds...
        </Text>
      </Box>
    </Box>
  );
};

export default VerifyAccountSuccess;
