import React, { useEffect, useState, useMemo } from "react";
import { validate } from "uuid";
import { useSearchParams } from "react-router-dom";
import OvertimeDataTable from "@/Components/LayoutPart/DataTable/Overtime";
import { ConfirmDialog, OvertimeDialog } from "@/Components/Popup";
import { TActions } from "@/Types/Common.types";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Icon,
  InputGroup,
  Select,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverFooter,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { LogtimeActions, RoleActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import {
  IFilterAggregate,
  IFilterOverTime,
  ILogTimeStructure,
} from "@/Interfaces/LogTime.interface";
import { ENUMS } from "@/Constants";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import {
  DateRangePicker,
  RoundedContainer,
  TextField,
} from "@/Components/Common";
import Utils from "@/Utils";
import { IRoleStructure } from "@/Interfaces/Role.interface";
import { useTranslation } from "react-multi-lang";

const {
  fetchLogTime,
  changeStatusLogtime,
  deleteLogTime,
  resetLogTimeReducer,
  getByIdLogTime,
  resetLogTimeDetail,
  fetchAggregate,
} = LogtimeActions;
const { fetchRoles } = RoleActions;
const { DAY_OFF_REQUEST_STATUS } = ENUMS;

const DEFAULT_FILTERS: IFilterOverTime = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
  timeType: "over time",
  roleCode: "",
};

const OverTimeOverview = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const showFilterByRole = useMemo(
    () =>
      _.includes(userRoles, ENUMS.ROLES.ADMIN) ||
      _.includes(userRoles, ENUMS.ROLES.MANAGER) ||
      _.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES),
    [userRoles]
  );

  const [isShowPopup, setIsShowPopup] = useState({
    edit: false,
    approve: false,
    details: false,
    rejected: false,
    delete: false,
    filter: false,
    pending: false,
  });

  const [idOvertime, setIdOvertime] = useState<string>("");
  const [isMessage, setIsMessage] = useState<string>("");
  const [noteRejected, setNoteRejected] = useState<string>("");
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [dateFilter, setDateFilter] = useState<any>();
  const [overtimeDetail, setOvertimeDetail] = useState<ILogTimeStructure>();

  const roles: IRoleStructure[] = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );

  const logTimeList: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logTimeList")
  );
  const logTimeDetails: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logtimeDetail")
  );

  const pagination: IFilterOverTime = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "pagination")
  );

  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );

  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  const isChangeStatus: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isChangeStatus")
  );
  const isDeleteSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isDeleteSuccess")
  );

  const meta: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "meta")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isFetchLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );

  const paginationAgregate: IFilterAggregate = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "paginationAgregate")
  );

  const checkFilterKeyword = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "filterKeyword"
  );

  const fieldGroupSize = React.useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "275px",
      xl: "275px",
      "2xl": "275px",
    };
  }, []);

  const roleOptions = React.useMemo(
    () =>
      _.map(roles, (role: any) => ({
        label: role.roleCode === "artist" ? "Staff" : _.startCase(role.name),
        value: role.roleCode,
      })),
    [roles]
  );

  React.useEffect(() => {
    if (_.isEmpty(roles)) dispatch(fetchRoles());
  }, []);

  React.useEffect(() => {
    // Access the 'id' parameter from searchParams
    const recordId = searchParams.get("id");
    if (recordId && validate(recordId)) {
      dispatch(getByIdLogTime(recordId));
    }
  }, [searchParams]);

  React.useEffect(() => {
    const recordId = searchParams.get("id");
    if (!_.isEmpty(logTimeDetails) && recordId) {
      setOvertimeDetail(logTimeDetails);
      handleShowPopup("details", true);
    }
  }, [logTimeDetails]);

  const handleShowPopup = (name: string, value: boolean) => {
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const onRowActionChange = (action: TActions, item: any) => {
    setOvertimeDetail(item);
    setIsShowPopup({
      ...isShowPopup,
      [action]: true,
      approve: false,
      rejected: false,
      pending: false,
    });
  };

  useEffect(() => {
    if (isChangeStatus) {
      dispatch(fetchAggregate(paginationAgregate));
    }
  }, [isChangeStatus]);

  const onChangeACtionConfitm = (
    action: "approve" | "rejected" | "pending",
    item: string
  ) => {
    setIdOvertime(item);
    setIsShowPopup({ ...isShowPopup, [action]: true });
  };

  const onChangStatus = (
    action: "approved" | "rejected" | "delete" | "pending"
  ) => {
    if (!_.isEmpty(idOvertime)) {
      if (action === "pending")
        dispatch(
          changeStatusLogtime(idOvertime, { status: "pending" }, pagination)
        );
      if (action === "approved")
        dispatch(
          changeStatusLogtime(idOvertime, { status: "approved" }, pagination)
        );
      if (action === "rejected")
        if (_.trim(noteRejected))
          dispatch(
            changeStatusLogtime(
              idOvertime,
              { status: "rejected", note: noteRejected },
              pagination
            )
          );
        else
          setIsMessage(
            t("message.reasonForDenyingEmployeeLeaveRequestDateIsRequired")
          );
    }
    if (overtimeDetail?.id)
      if (action === "delete")
        dispatch(deleteLogTime(overtimeDetail?.id, "", pagination));
  };

  const handleFiltersChange = (
    key: string,
    value: string | string[] | Date[]
  ) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSubmit = () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters)
      dispatch(fetchLogTime(resolvedFilters as IFilterOverTime));
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchLogTime(DEFAULT_FILTERS));
    setIsShowPopup({ ...isShowPopup, filter: false });
    const currentDate = dayjs();
    const startDate = currentDate.startOf("month");
    const endDate = dayjs().endOf("month");
    setDateFilter([startDate, endDate]);
  };

  useEffect(() => {
    dispatch(
      fetchLogTime({
        page: 1,
        limit: 10,
        timeType: "over time",
        startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
        endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
      })
    );
    const currentDate = dayjs();
    const startDate = currentDate.startOf("month");
    const endDate = dayjs().endOf("month");
    setDateFilter([startDate, endDate]);
    return () => {
      dispatch(resetLogTimeReducer());
    };
  }, []);

  //  const summary = React.useMemo(() => {
  //    let sum: any = 0;
  //    _.forEach(
  //      logTimeList,
  //      (item: any) => (sum += _.toNumber(item.workingTime))
  //    );
  //    return sum;
  //  }, [logTimeList]);

  useEffect(() => {
    if (
      isCreateSuccess ||
      isUpdateSuccess ||
      isChangeStatus ||
      isDeleteSuccess
    ) {
      setIdOvertime("");
      setIsShowPopup({
        edit: false,
        approve: false,
        details: false,
        rejected: false,
        delete: false,
        filter: false,
        pending: false,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess, isChangeStatus, isDeleteSuccess]);

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName) => {
      if (fieldName === "startDate") {
        updatedPagination.startDate = dayjs(dayjs().startOf("month")).format(
          "YYYY-MM-DD"
        );
      } else if (fieldName === "endDate") {
        updatedPagination.endDate = dayjs(dayjs().endOf("month")).format(
          "YYYY-MM-DD"
        );
      } else {
        updatedPagination[fieldName] = _.isArray(updatedPagination[fieldName])
          ? []
          : "";
      }
    });

    setFilters(updatedPagination);
    const currentDate = dayjs();
    const startDate = currentDate.startOf("month");
    const endDate = dayjs().endOf("month");
    setDateFilter([startDate, endDate]);
    dispatch(fetchLogTime(updatedPagination));
  };

  const onPageChange = (page: number) => {
    const newFilter = { ...filters, page };
    setFilters(newFilter);
    dispatch(fetchLogTime(newFilter));
  };

  const onLimitChange = (limit: number) => {
    const newFilter = { ...filters, page: 1, limit };
    setFilters(newFilter);
    dispatch(fetchLogTime(newFilter));
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag size="md" borderRadius="full" variant="solid" key={label}>
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (pagination?.keyword) {
      renderedTags.push(renderTag(pagination?.keyword, "keyword"));
    }
    if (pagination?.status) {
      renderedTags.push(renderTag(t(`status.${pagination.status}`), "status"));
    }

    if (pagination?.roleCode) {
      renderedTags.push(
        renderTag(
          pagination?.roleCode === ENUMS.ROLES.ARTIST
            ? "Staff"
            : pagination?.roleCode,
          "roleCode"
        )
      );
    }

    if (
      pagination?.startDate &&
      !dayjs(dayjs().startOf("month").format("YYYY-MM-DD")).isSame(
        dayjs(pagination?.startDate).format("YYYY-MM-DD")
      )
    ) {
      renderedTags.push(
        renderTag(
          `${dayjs(pagination?.startDate).format("DD/MM/YYYY")} - ${dayjs(
            pagination?.endDate
          ).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HStack>
          <Text fontSize="sm">
            {/* Any additional information you want to display */}
          </Text>
          <HStack spacing={4}>
            <HStack spacing={1}>
              <Text fontSize="sm" fontWeight={500} color={"#5C6e6c"}>
                {t("label.filterBy")}:
              </Text>
              <HStack spacing={1} sx={{ textTransform: "capitalize" }}>
                {renderedTags}
              </HStack>
            </HStack>
          </HStack>
        </HStack>
      </Box>
    );
  };

  const _renderFilterOptions = () => (
    <Popover isOpen={isShowPopup.filter} placement="auto-start">
      <PopoverTrigger>
        <Button
          size="sm"
          onClick={(e: any) => {
            e.stopPropagation();
            handleShowPopup("filter", true);
          }}
          //  colorScheme="whatsapp"
          leftIcon={<Icon as={FunnelIcon} />}
          sx={{
            color: "#fff",
            background: "#D2A96A",
            "&:hover": {
              background: "#D2BD6A",
            },
          }}
        >
          {t("button.filter")}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader> {t("label.filterOptions")}</PopoverHeader>
        <PopoverBody
          sx={{
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            {showFilterByRole && (
              <InputGroup size="sm">
                <Select
                  placeholder={t("label.role")}
                  value={filters.roleCode}
                  onChange={(e: any) =>
                    handleFiltersChange("roleCode", e.target.value)
                  }
                >
                  {_.map(roleOptions, (role: any, index: number) => (
                    <option key={index} value={role?.value}>
                      {_.startCase(role?.label)}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            )}
            <InputGroup size="sm">
              <Select
                placeholder={t("label.status")}
                value={filters.status}
                onChange={(e: any) =>
                  handleFiltersChange("status", e.target.value)
                }
              >
                {_.map(
                  _.values(DAY_OFF_REQUEST_STATUS),
                  (status: any, index: number) => (
                    <option key={index} value={status}>
                      {t(`status.${status}`)}
                    </option>
                  )
                )}
              </Select>
            </InputGroup>
            <DateRangePicker
              sx={{
                maxWidth: 350,
              }}
              onDateChange={(newDate: any) => {
                setFilters({
                  ...filters,
                  startDate: dayjs(newDate[0]).format("YYYY-MM-DD"),
                  endDate: dayjs(newDate[1]).format("YYYY-MM-DD"),
                });
                setDateFilter(newDate);
              }}
              value={dateFilter}
            />
          </Box>
        </PopoverBody>
        <PopoverFooter sx={{ zIndex: -1 }}>
          <HStack justifyContent="space-between">
            <Button
              size="sm"
              onClick={() => handleShowPopup("filter", false)}
              variant="ghost"
              colorScheme="red"
            >
              {t("button.close")}
            </Button>
            <HStack spacing={3}>
              <Box sx={{ mr: 2 }}>
                <Button
                  size="sm"
                  onClick={handleReset}
                  leftIcon={<Icon as={ArrowPathIcon} />}
                  //  colorScheme="orange"
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                >
                  {t("button.reset")}
                </Button>
              </Box>
              <Box>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  //  colorScheme="whatsapp"
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                >
                  {t("button.filter")}
                </Button>
              </Box>
            </HStack>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );

  const _renderFilterOptionsUser = () => {
    return (
      <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexWrap: "wrap",
            //flex: 1,
          }}
        >
          <InputGroup size="sm" w={110}>
            <Select
              placeholder={t("label.status")}
              value={filters.status}
              sx={{ borderRadius: 10, minW: "110px", maxW: "115px" }}
              onChange={(e: any) =>
                handleFiltersChange("status", e.target.value)
              }
            >
              {_.map(
                _.values(DAY_OFF_REQUEST_STATUS),
                (status: any, index: number) => (
                  <option key={index} value={status}>
                    {_.startCase(status)}
                  </option>
                )
              )}
            </Select>
          </InputGroup>
          <Box>
            <DateRangePicker
              onDateChange={(newDate) => {
                setFilters({
                  ...filters,
                  startDate: dayjs(newDate[0]).format("YYYY-MM-DD"),
                  endDate: dayjs(newDate[1]).format("YYYY-MM-DD"),
                });
                setDateFilter(newDate);
              }}
              value={dateFilter}
            />
          </Box>
        </Box>
        <Box ml={2}>
          <InputGroup gap={3}>
            <Button
              size="sm"
              onClick={handleSubmit}
              colorScheme="whatsapp"
              leftIcon={<Icon as={FunnelIcon} />}
              sx={{
                color: "#fff",
                background: "#D2A96A",
                "&:hover": {
                  background: "#D2BD6A",
                },
              }}
            >
              {t("button.filter")}
            </Button>
            <Button
              size="sm"
              onClick={handleReset}
              leftIcon={<Icon as={ArrowPathIcon} />}
              sx={{
                color: "#fff",
                background: "#bb7154",
                "&:hover": {
                  background: "#Db9d97",
                },
              }}
            >
              {t("button.reset")}
            </Button>
          </InputGroup>
        </Box>
      </Box>
    );
  };

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {checkFilterKeyword && (
            <Box sx={{ maxW: fieldGroupSize }}>
              <TextField
                size={"sm"}
                placeholder={t("label.search")}
                value={filters.keyword}
                onChange={(e: any) =>
                  handleFiltersChange("keyword", e.target.value)
                }
                onKeyDown={(e) => {
                  const element = e.currentTarget as HTMLInputElement;
                  const value = element.value;
                  if (e.key === "Enter")
                    dispatch(
                      fetchLogTime({
                        ...pagination,
                        keyword: _.trim(value),
                      })
                    );
                }}
                rightElement={
                  <Tooltip label={t("label.search")} hasArrow>
                    <Icon
                      as={SearchIcon}
                      boxSize={5}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        dispatch(
                          fetchLogTime({
                            ...pagination,
                            keyword: _.trim(filters.keyword),
                          })
                        )
                      }
                    />
                  </Tooltip>
                }
              />
            </Box>
          )}
          {checkFilterKeyword ? (
            <Box>{_renderFilterOptions()}</Box>
          ) : (
            <Box>{_renderFilterOptionsUser()}</Box>
          )}
        </Box>
        <Box mt={2} sx={{ display: "flex" }}>
          <Box>{_renderTags()}</Box>
        </Box>
      </Box>
    );
  };

  const _renderTable = () => {
    return (
      <OvertimeDataTable
        payload={logTimeList}
        paginate={meta}
        onRowAction={onRowActionChange}
        isLoading={isFetchLoading}
        onPageChange={onPageChange}
        onChangeLimit={onLimitChange}
      />
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <RoundedContainer>
          {_renderTopSection()}
          {_renderTable()}
        </RoundedContainer>
        <OvertimeDialog.UpdateOvertimeDialog
          open={isShowPopup.edit}
          onClose={() => handleShowPopup("edit", false)}
          payload={overtimeDetail}
        />
        <OvertimeDialog.DetailOvertimeDialog
          open={isShowPopup.details}
          onClose={() => {
            handleShowPopup("details", false);
            const recordId = searchParams.get("id");
            if (recordId) {
              searchParams.delete("id");
              setSearchParams(searchParams);
            }
            if (!_.isEmpty(logTimeDetails)) dispatch(resetLogTimeDetail());
          }}
          payload={overtimeDetail}
          onRowAction={onChangeACtionConfitm}
        />
        <ConfirmDialog
          isOpen={isShowPopup.approve}
          onClose={() => setIsShowPopup({ ...isShowPopup, approve: false })}
          body={t("message.doYouWantToApprovalThisOvertimeOfEmployee")}
          onAction={() => onChangStatus("approved")}
          actionType={"approve"}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={isShowPopup.rejected}
          onClose={() => setIsShowPopup({ ...isShowPopup, rejected: false })}
          body={t("message.reasonForRefusingToWorkOvertime")}
          onAction={() => onChangStatus("rejected")}
          actionType={"rejected"}
          onChange={(e) => {
            setNoteRejected(e);
            setIsMessage("");
          }}
          isError={!_.isEmpty(isMessage)}
          errorMessage={isMessage}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={isShowPopup.delete}
          onClose={() => {
            setIsShowPopup({ ...isShowPopup, delete: false });
          }}
          body={t("message.doYouWantToDeleteOvertime")}
          onAction={() => onChangStatus("delete")}
          actionType={"delete"}
          isLoading={isActionLoading}
        />
        <ConfirmDialog
          isOpen={isShowPopup.pending}
          onClose={() => {
            setIsShowPopup({ ...isShowPopup, pending: false });
          }}
          body={t("message.doYouWantToChangeTheOvertimeStatusToPending")}
          onAction={() => onChangStatus("pending")}
          actionType={"save"}
          isLoading={isActionLoading}
        />
      </Box>
    );
  };
  return <Box>{renderMain()}</Box>;
};

export default OverTimeOverview;
