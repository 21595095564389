import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Checkbox,
  Avatar,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@/Components/Common";

import { RootState, useTypedDispatch } from "@/Store";
import { ChatActions } from "@/Actions";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { IUser } from "@/Interfaces/User.interface";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  open: boolean;
  onClose(): void;
}

const { createNewChat, fetchUserNotConversation, performAction } = ChatActions;

const DEFAULT_FORM_DATA = {
  member: "",
};

const CreateChat: React.FC<ISectionProps> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const userLogged = Utils.getSavedUserData();
  const userNotConversation: IUser[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "userNotConversation")
  );
  const t = useTranslation();

  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");

  const schema = yup
    .object()
    .shape({
      member: yup.string().trim().required(t("message.memberIsaRequiredDield")),
    })
    .required();

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    setFilteredUsers(userNotConversation);
  }, [userNotConversation]);

  useEffect(() => {
    setValue("member", selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (!open) {
      reset();
      setSearchValue("");
      setSelectedValue("");
    } else {
      dispatch(fetchUserNotConversation());
    }
    return () => {};
  }, [open]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(event.target.value);
    const regex = new RegExp(_.escapeRegExp(value), "i");
    const filtered = !_.isEmpty(value)
      ? _.filter(
          filteredUsers,
          (user) =>
            regex.test(user?.email) || regex.test(user?.userData?.fullName)
        )
      : userNotConversation;

    setFilteredUsers(filtered);
  };

  const handleCheckboxChange = (e: any) => {
    clearErrors();
    const checkboxValue = e.target.value;
    setSelectedValue(checkboxValue);
  };

  const handleReset = (event: any) => {
    event.stopPropagation();
    setSearchValue("");
    setFilteredUsers(userNotConversation);
  };

  const onSubmit = (data: any) => {
    const findUser = _.find(
      userNotConversation,
      (user) => user?.id === data.member
    );
    const FAKE_NEW_CHAT_DATA = {
      id: "new-chat-data-placeholder",
      name: findUser?.userData?.fullName,
      sender: {
        id: userLogged?.id,
        name: userLogged?.userData?.fullName || "Sender",
        avatar: userLogged?.userData?.avatar?.path,
      },
      receiver: {
        id: findUser?.id,
        name: findUser?.userData?.fullName || "Receiver",
        avatar: findUser?.userData?.avatar?.path,
      },
      latestMessage: null,
      timestamp: "",
      messageType: "personal",
    };

    dispatch(createNewChat(FAKE_NEW_CHAT_DATA));
    dispatch(performAction("view", "new-chat-data-placeholder"));
    if (pathname !== Routers.CHAT) Utils.redirect(Routers.CHAT);
    onClose();
  };

  return (
    <>
      <Drawer isOpen={open} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            sx={{
              borderBottom: "1px solid #ddd",
              p: 4,
            }}
          >
            <Heading fontSize="lg">{t("label.createNewChat")}</Heading>
          </DrawerHeader>

          <DrawerBody
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              p: 4,
            }}
          >
            <InputGroup>
              <Input
                value={searchValue}
                onChange={handleSearchChange}
                size="sm"
                placeholder={t("label.searchUserBynameEmail")}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
              <InputRightElement
                sx={{
                  height: "100%",
                }}
              >
                {searchValue.length > 0 && (
                  <Tooltip label={t("tooltip.remove")} hasArrow>
                    <IconButton
                      isRound
                      sx={{
                        background: "none",
                      }}
                      size="sm"
                      icon={<Icon as={XMarkIcon} />}
                      aria-label={""}
                      onClick={handleReset}
                    />
                  </Tooltip>
                )}
              </InputRightElement>
            </InputGroup>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflow: "auto",
                p: 2,
              }}
            >
              {!_.isEmpty(filteredUsers) ? (
                _.map(filteredUsers, (user, index) => (
                  <Box
                    key={`${user?.id}-${index}`}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      size="sm"
                      name={user?.userData?.fullName}
                      src={user?.userData?.avatar?.path}
                    />
                    <Box>
                      <Text fontSize="sm" fontWeight="600">
                        {user?.userData?.fullName}
                      </Text>
                    </Box>
                    <Checkbox
                      sx={{ ml: "auto" }}
                      value={user?.id}
                      isChecked={selectedValue === user?.id}
                      onChange={handleCheckboxChange}
                    />
                  </Box>
                ))
              ) : (
                <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
              )}
              <Text fontSize="sm" color="error">
                {errors?.member?.message}
              </Text>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button size="sm" variant="outline" mr={3} onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              //  colorScheme="blue"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!_.isEmpty(errors)}
              sx={{
                minW: 65,
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateChat;
