import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
} from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  year: new Date().getFullYear(),
  status: "active",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  meta: DEFAULT_META_STATES,
  pagination: DEFAULT_PAGINATION,
  isUpdateSuccess: false,
  workingTimeList: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_WORKING_TIME:
      return {
        ...initialState,
      };
    case ActionTypes.SET_WORKING_TIME_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_WORKING_TIME_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    //case ActionTypes.SET_HOLIDAY_GET_LOADING:
    //  return {
    //    ...state,
    //    requestHasError: false,
    //    requestIsSuccess: false,
    //    requestIsLoading: true,
    //    isGetLoading: true,
    //  };

    case ActionTypes.FETCH_WORKING_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        workingTimeList: payload,
      };

    case ActionTypes.FETCH_WORKING_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        workingTimeList: [],
      };

    case ActionTypes.GET_BY_ID_WORKING_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.GET_BY_ID_WORKING_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.UPDATE_WORKING_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_WORKING_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    default:
      return state;
  }
};
