import React from "react";
import _ from "lodash";
import {
  Box,
  Text,
  Icon,
  Tooltip,
  //  Avatar,
} from "@chakra-ui/react";

import { Table } from "rsuite";
import { type IPaginate, type TActions } from "@/Types/Common.types";

import { NoDataFound } from "@/Components/Common";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";

const { Column, HeaderCell, Cell } = Table;
interface IProps {
  payload: any;
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  //  onRowAction: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  type?: string;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const AggregateOvertimeDataTableDayOff: React.FC<IProps> = ({
  payload,
  isLoading,
  // type,
}) => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={payload}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        height={isMobile ? 400 : 550}
      >
        <Column flexGrow={1} resizable fixed>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.fullName")}
          </HeaderCell>
          <Cell dataKey="fullName" />
        </Column>
        <Column width={100} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.staffCode")}
          </HeaderCell>
          <Cell dataKey="staffCode" />
        </Column>
        <Column flexGrow={1} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.role")}
          </HeaderCell>
          <Cell style={{ textTransform: "capitalize" }}>
            {(rowData) => {
              const roles: string[] = [];
              _.forEach(rowData?.userRole, (itemRole: any) => {
                if (itemRole?.role?.roleCode === "artist") roles.push("Staff");
                else roles.push(itemRole?.role?.name);
              });
              return roles.join(", ");
            }}
          </Cell>
        </Column>
        <Column width={100} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.totalDays")}
          </HeaderCell>
          <Cell dataKey="total">
            {(rowData) => {
              return !rowData?.warning ? (
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Text>{rowData?.total}</Text>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "max-content",
                    mx: "auto",
                  }}
                >
                  <Tooltip
                    placement="auto"
                    hasArrow
                    label={t(
                      "message.TheDataInThisRowContainsInformationForAnotherMonth"
                    )}
                  >
                    <Text
                      sx={{
                        position: "relative",
                      }}
                    >
                      {rowData?.total}

                      <Icon
                        as={ExclamationCircleIcon}
                        color="error"
                        boxSize={5}
                      />
                    </Text>
                  </Tooltip>
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
    </Box>
  );
};

export default AggregateOvertimeDataTableDayOff;
