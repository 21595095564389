import React from "react";
import _ from "lodash";
import { Box, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { Table, Pagination } from "rsuite";

import { NoDataFound, TextComponent } from "@/Components/Common";
import {
  type IPaginate,
  type TActions,
  type TStatus,
} from "@/Types/Common.types";
import { TrashIcon } from "@heroicons/react/24/outline";
import Utils from "@/Utils";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onChangeLimit?: (limint: number) => void;
  onRowAction: (action: TStatus | TActions, item: any) => void;
  showTotal?: boolean;
  isLoading?: boolean;
  type?: string;
}
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const HolidayDataTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  isLoading,
  onRowAction,
  onChangeLimit,
}) => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const update = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Holiday",
    "create"
  );

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box>
      <Table
        data={payload}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
      >
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.year")}
          </HeaderCell>
          <Cell dataKey="year" />
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.date")}
          </HeaderCell>
          <Cell dataKey="day" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.description")}
          </HeaderCell>
          {/*<Cell dataKey="description" />*/}
          <Cell>
            {(rowData: any) => (
              <Box>
                <Tooltip w={"auto"} label={rowData.description}>
                  <TextComponent content={rowData.description} maxW={"100%"} />
                </Tooltip>
              </Box>
            )}
          </Cell>
        </Column>
        {update && (
          <Column align="center" width={100} fixed="right">
            <HeaderCell
              verticalAlign="center"
              align="center"
              style={HEADER_STYLE}
            >
              {t("table.actions")}
            </HeaderCell>
            <Cell style={{ padding: "5px" }}>
              {(rowData: any) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      // justifyContent: "center",
                    }}
                  >
                    <Tooltip label={t("tooltip.delete")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={TrashIcon} sx={{ color: "#bb7154" }} />}
                        aria-label={""}
                        onClick={() => onRowAction("delete", rowData)}
                      />
                    </Tooltip>
                  </Box>
                );
              }}
            </Cell>
          </Column>
        )}
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "&.rs-pagination-btn:disabled": {
            color: "#c5c6c7!important",
          },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default HolidayDataTable;
