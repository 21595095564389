import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";

const { LOG_TIME } = APIs;

export const createLogtime = async (payload: any) => {
  return request(LOG_TIME.BASIC, "POST", payload);
};

export const fetchLogtime = async (payload: any) => {
  return request(LOG_TIME.BASIC, "GET", payload);
};

export const fetchLogtimeByBoardId = async (id: string) => {
  return request(`${LOG_TIME.BASIC}?kanbanBoardId=${id}`, "GET");
};

export const getLogtimeById = async (id: string) => {
  return request(`${LOG_TIME.GET_BY_ID}/${id}`, "GET");
};

export const updateLogtime = async (id: string, payload: any) => {
  return request(`${LOG_TIME.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const changeStatusLogtime = async (id: string, payload: any) => {
  return request(`${LOG_TIME.CHANGE_STATUS}/${id}`, "PUT_FORM_DATA", payload);
};

export const deleteLogtime = async (id: string) => {
  return request(`${LOG_TIME.DELETE}/${id}`, "PUT");
};

export const getMyTime = async (payload: {
  userId?: [];
  startDate?: string;
  endDate?: string;
  forSelf?: boolean;
}) => {
  return request(LOG_TIME.BASIC, "GET", payload);
};

export const fetchAggregate = async (payload: IFilterAggregate) => {
  return request(LOG_TIME.AGGREGATE, "GET", payload);
};

export const fetchLogtimeDashboard = async (payload: any) => {
  return request(LOG_TIME.FOR_DASHBOARD, "GET", payload);
};

export const fetchLogtimeDelineDashboard = async (payload: any) => {
  return request(LOG_TIME.DEADLINE_DASHBOARD, "GET", payload);
};

export const fetchMyWorkDashboard = async (payload: any) => {
  return request(LOG_TIME.MY_WORK_DASHBOARD, "GET", payload);
};
