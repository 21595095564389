import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setBackupFetchLoading = () => {
  return {
    type: ActionTypes.SET_BACKUP_FETCH_LOADING,
  };
};

//const setBackupGetLoading = () => {
//  return {
//    type: ActionTypes.SET_BACKUP_GET_LOADING,
//  };
//};

//const resetStatusBackup = () => {
//  return {
//    type: ActionTypes.RESET_STATUS_BACKUP,
//  };
//};

const setBackupActionLoading = () => {
  return {
    type: ActionTypes.SET_BACKUP_ACTION_LOADING,
  };
};

//const resetBackupReducer = () => {
//  return {
//    type: ActionTypes.RESET_BACKUP,
//  };
//};

const setMetaBackup = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_BACKUP,
    payload,
  };
};

const setPaginationBackup = (payload?: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_BACKUP,
    payload,
  };
};

const fetchBackupSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_BACKUP_SUCCESS,
    payload,
  };
};

const fetchBackupFail = () => {
  return {
    type: ActionTypes.FETCH_BACKUP_FAILURE,
  };
};

const fetchBackup = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setBackupFetchLoading());
    await APIs.fetchBackup(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchBackupFail());
        } else {
          await dispatch(fetchBackupSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(fetchBackupFail());
      });
  };
};

const changeStatusBackupSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_BACKUP_SUCCESS,
  };
};

const changeStatusBackupFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_BACKUP_FAILURE,
  };
};

const changstatusBackup = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setBackupActionLoading());
    await APIs.changeBackup(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusBackupFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchBackup());
          dispatch(changeStatusBackupSuccess());
          await dispatch(fetchBackup({ page: 1, limit: 10 }));
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(changeStatusBackupFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchBackupDatabaseSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_BACKUP_DATABASE_SUCCESS,
    payload,
  };
};

const fetchBackupDatabaseFail = () => {
  return {
    type: ActionTypes.FETCH_BACKUP_DATABASE_FAILURE,
  };
};

const fetchBackupDatabase = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setBackupFetchLoading());
    await APIs.fetchBackupList(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchBackupDatabaseFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaBackup(_.get(result, "meta")));
          await dispatch(setPaginationBackup(payload));
          await dispatch(fetchBackupDatabaseSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchBackupDatabaseFail());
      });
  };
};

const getBackupDatabaseSuccess = () => {
  return {
    type: ActionTypes.GET_BACKUP_DATABASE_SUCCESS,
  };
};

const getBackupDatabaseFail = () => {
  return {
    type: ActionTypes.GET_BACKUP_DATABASE_FAILURE,
  };
};

const getBackupDatabase = () => {
  return async (dispatch: any) => {
    dispatch(setBackupActionLoading());
    await APIs.getBackupList()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await Toast({
            title: message,
            status: "error",
          });
          await dispatch(getBackupDatabaseFail());
        } else {
          await Toast({
            title: message,
            status: "success",
          });
          dispatch(getBackupDatabaseSuccess());
          await dispatch(fetchBackupDatabase({ page: 1, limit: 10 }));
        }
      })
      .catch(async (error) => {
        await dispatch(getBackupDatabaseFail());
        await Toast({
          title: ``,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const restoreDatabaseSuccess = () => {
  return {
    type: ActionTypes.RESTORE_DATABASE_SUCCESS,
  };
};

const restoreDatabaseFail = () => {
  return {
    type: ActionTypes.RESTORE_DATABASE_FAILURE,
  };
};

const restoreDatabase = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setBackupActionLoading());
    await APIs.restoreDatabase(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await Toast({
            title: message,
            status: "error",
          });
          await dispatch(restoreDatabaseFail());
        } else {
          await Toast({
            title: message,
            status: "success",
          });
          dispatch(restoreDatabaseSuccess());
          await dispatch(fetchBackup({ page: 1, limit: 10 }));
        }
      })
      .catch(async (error) => {
        await Toast({
          title: ``,
          description: error?.message,
          status: "error",
        });
        await dispatch(restoreDatabaseFail());
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ActionTypes.DELETE_BACKUP_SUCCESS,
  };
};

const deleteFailure = () => {
  return {
    type: ActionTypes.DELETE_BACKUP_FAILURE,
  };
};

const deleteDatabase = (id: string, filters: any) => {
  return async (dispatch: any) => {
    dispatch(setBackupActionLoading());
    await APIs.deleteDatabase(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(deleteSuccess());
          await dispatch(fetchBackupDatabase(filters));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getAllMemoryConversationSuccess = (payload: number) => {
  return {
    type: ActionTypes.GET_ALL_MEMORY_CONVERSATION_SUCCESS,
    payload,
  };
};

const getAllMemoryConversationFailure = () => {
  return {
    type: ActionTypes.GET_ALL_MEMORY_CONVERSATION_FAILURE,
  };
};

const getAllMemoryConversation = () => {
  return async (dispatch: any) => {
    dispatch(setBackupActionLoading());
    await APIs.getAllMemoryConversation()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getAllMemoryConversationFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(getAllMemoryConversationSuccess(result));
        }
      })
      .catch(async (error) => {
        await dispatch(getAllMemoryConversationFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchBackup,
  changstatusBackup,
  fetchBackupDatabase,
  getBackupDatabase,
  restoreDatabase,
  deleteDatabase,
  getAllMemoryConversation,
};
