import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Stack,
  Button,
  Icon,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { ChevronLeftIcon, LinkIcon } from "@heroicons/react/24/outline";

import { AdminLayout } from "@/Components/DefaultLayout";
import { ENUMS, Routers } from "@/Constants";
import Utils from "@/Utils";
import {
  ProjectActions,
  LogtimeActions,
  BoardActions,
  UserActions,
  ProjectExteriorActions,
  WorkingDayActions,
} from "@/Actions";

import Information from "./Information";
import Members from "./Members";
import { RootState, useTypedDispatch } from "@/Store";

import CommonColors from "@/Themes/CommonColors";

import { TimeLogDialog, ConfirmDialog, BoardDialog } from "@/Components/Popup";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import { IProjectStructure } from "@/Interfaces/Project.interface";
// import Calendar from "./Calendar";
import Timeline from "./Timeline";
import { EditIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-multi-lang";

const { getProjectById } = ProjectActions;
const { resetLogTimePerformAction, deleteLogTime } = LogtimeActions;
const { getBoardById, resetBoardDetail, resetBoardPerformAction } =
  BoardActions;

const { fetchUser, resetUserReducer } = UserActions;
const { fetchUserCanAdd } = ProjectExteriorActions;
const { fetchWorkingDay, resetWorkingDayReducer } = WorkingDayActions;

const ProjectDetail: React.FC = () => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const dispatch = useTypedDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );
  const boardPerformAction: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "performAction")
  );

  const isGetProjectLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isGetLoading")
  );
  const isGetBoardLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isGetLoading")
  );

  const details: IProjectStructure = useSelector((state: RootState) =>
    _.get(state.PROJECT, "details")
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(fetchWorkingDay());
    return () => {
      dispatch(resetUserReducer());
      dispatch(resetBoardDetail());
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    // Access the 'id' parameter from searchPara
    const projectId = searchParams.get("id");
    const activeTab = searchParams.get("activeTab");
    if (activeTab === "timeline") {
      setTabIndex(2);
      searchParams.delete("activeTab");
      setSearchParams(searchParams);
    }
    if (projectId) dispatch(getProjectById(projectId));
  }, [searchParams]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      const projectType = details?.type;
      if (projectType === ENUMS.PROJECT_TYPE.INTERIOR)
        dispatch(
          fetchUser({
            page: 0,
            limit: 0,
            status: ENUMS.USER_STATUS.ACTIVE,
          })
        );
      else
        dispatch(
          fetchUserCanAdd({
            page: 0,
            limit: 0,
            status: ENUMS.USER_STATUS.ACTIVE,
          })
        );
      dispatch(getBoardById(details?.kanbanBoard?.id));
    }
  }, [details]);

  const canEditProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "editProject"
  );

  const canChangeStatusProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "changeStatus"
  );

  const _renderPopup = () => (
    <>
      <TimeLogDialog.CreateTimelogDialog
        open={
          timeLogPerformAction?.actionType === "logExternal" ||
          timeLogPerformAction?.actionType === "logInternal"
        }
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <TimeLogDialog.UpdateTimelogDialog
        open={timeLogPerformAction?.actionType === "editLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(
            deleteLogTime(timeLogPerformAction?.id, details?.kanbanBoard?.id)
          );
        }}
        body={t("message.doYouWantRemoveThisLog")}
        actionType="delete"
      />
      <BoardDialog.EditTaskDialog
        open={boardPerformAction?.actionType === "edit"}
        onClose={() =>
          boardPerformAction?.actionType === "edit" &&
          dispatch(resetBoardPerformAction())
        }
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
        ref={containerRef}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="sm"
            variant="ghost"
            colorScheme="twitter"
            leftIcon={<Icon as={ChevronLeftIcon} />}
            color={"#bb7154"}
            onClick={() => Utils.redirect(Routers.PROJECT)}
          >
            {t("button.backToProject")}
          </Button>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="twitter"
            leftIcon={<Icon as={LinkIcon} />}
            onClick={(e) => {
              e.stopPropagation();
              Utils.redirect(
                `${Routers.BOARD_DETAIL}?id=${details?.kanbanBoard?.id}`
              );
            }}
            isDisabled={_.isEmpty(details)}
          >
            {t("button.goToBoardDetail")}
          </Button>
        </Box>
        <Tabs size="sm" onChange={(index) => setTabIndex(index)}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TabList>
              <Tab
                sx={{
                  px: 2,
                  color: CommonColors.mainBackground,
                }}
                isDisabled={isGetBoardLoading || isGetProjectLoading}
              >
                {t("button.informations")}
              </Tab>
              <Tab
                sx={{
                  px: 2,
                  color: CommonColors.mainBackground,
                }}
                isDisabled={isGetBoardLoading || isGetProjectLoading}
              >
                {t("button.members")}
              </Tab>
              <Tab
                sx={{
                  px: 2,
                  color: CommonColors.mainBackground,
                }}
                isDisabled={isGetBoardLoading || isGetProjectLoading}
              >
                {t("button.timeline")}
              </Tab>
            </TabList>
            {(canEditProject || canChangeStatusProject) && (
              <Box>
                <Button
                  size="sm"
                  //  colorScheme="twitter"
                  leftIcon={<Icon as={EditIcon} boxSize={3} />}
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                  onClick={() => {
                    if (details?.type === ENUMS.PROJECT_TYPE.INTERIOR) {
                      Utils.redirect(
                        `${Routers.UPDATE_PROJECT}?id=${details.id}`,
                        {
                          projectId: details.id,
                        }
                      );
                    }
                    if (details?.type === ENUMS.PROJECT_TYPE.EXTERIOR) {
                      Utils.redirect(
                        `${Routers.UPDATE_PROJECT_EXTERIOR}?id=${details.id}`,
                        {
                          projectId: details.id,
                        }
                      );
                    }
                  }}
                >
                  {t("button.edit")}
                </Button>
              </Box>
            )}
          </Box>

          <TabPanels>
            <TabPanel
              sx={{
                p: 0,
                mt: 3,
              }}
            >
              <Information />
            </TabPanel>
            <TabPanel
              sx={{
                p: 0,
                mt: 3,
              }}
            >
              <Members />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {tabIndex === 2 && <Timeline />}
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default ProjectDetail;
