import React from "react";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  ButtonGroup,
  useDisclosure,
  Text,
  VStack,
  Icon,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface IProps extends IconButtonProps {
  isAnchor?: boolean;
  to?: string;
  isConfirm?: boolean;
  confirmTitle?: string;
  confirmMessage?: string;
  confirmPlacement?: "top" | "right" | "left" | "bottom";
  onConfirm?(): void;
}

const IconButtonConfirm = React.forwardRef<HTMLElement, IProps>(
  (
    {
      onConfirm,
      isAnchor = false,
      to = "",
      children = "",
      confirmMessage = "N/A",
      confirmTitle = "N/A",
      confirmPlacement = "top",
      ...props
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onConfirmAction = (action: string) => {
      if (action === "confirm" && onConfirm) onConfirm();
      onClose();
    };

    const button = (
      <IconButton
        size="sm"
        {...props}
        ref={ref}
        isDisabled={props.isLoading || props.isDisabled}
      >
        {children}
      </IconButton>
    );

    if (isAnchor && to) return <Link to={to}>{button}</Link>;

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={confirmPlacement}
        closeOnBlur={true}
      >
        <PopoverTrigger>{button}</PopoverTrigger>
        <PopoverContent p="5px 15px">
          <PopoverHeader
            fontSize="13px"
            fontWeight={600}
            textAlign="left"
            color="#6F77B0"
          >
            <Icon width="21px" height="21px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21px"
                height="21px"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.05295 5.45712C3.19804 4.21656 4.24912 3.28125 5.49813 3.28125H14.898C15.0573 3.28125 15.2112 3.33923 15.331 3.44436L17.7464 5.56517C18.2894 6.04197 18.5894 6.73757 18.5634 7.45975L18.2624 15.8364C18.2159 17.1309 17.1531 18.1562 15.8577 18.1562H5.34877C4.18535 18.1562 3.20314 17.2918 3.0554 16.1378C2.62097 12.7443 2.60225 9.31058 2.99967 5.91262L3.05295 5.45712ZM5.49813 4.59375C4.91501 4.59375 4.4243 5.03042 4.35656 5.60959L4.30329 6.06509C3.91827 9.35699 3.9364 12.6836 4.35728 15.9711C4.42115 16.47 4.84579 16.8438 5.34877 16.8438H5.90623V13.125C5.90623 12.2793 6.59179 11.5938 7.43748 11.5938H13.5625C14.4082 11.5938 15.0937 12.2793 15.0937 13.125V16.8438H15.8577C16.4465 16.8438 16.9296 16.3777 16.9508 15.7893L17.2518 7.41262C17.2636 7.08436 17.1272 6.76817 16.8804 6.55145L14.6562 4.59856V6.64998C14.6562 7.49566 13.9707 8.18123 13.125 8.18123H7.87498C7.02929 8.18123 6.34373 7.49566 6.34373 6.64998V4.59375H5.49813ZM7.65623 4.59375V6.64998C7.65623 6.77079 7.75416 6.86873 7.87498 6.86873H13.125C13.2458 6.86873 13.3437 6.77079 13.3437 6.64998V4.59375H7.65623ZM13.7812 16.8438H7.21873V13.125C7.21873 13.0042 7.31666 12.9062 7.43748 12.9062H13.5625C13.6833 12.9062 13.7812 13.0042 13.7812 13.125V16.8438Z"
                  fill="#6F77B0"
                />
              </svg>
            </Icon>
            {confirmTitle || "Confirmation"}
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody
            sx={{
              padding: "10px",
            }}
          >
            <VStack direction="column">
              <Text
                sx={{
                  fontSize: "13px",
                  fontWeight: 400,
                  textAlign: "center",
                  width: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                {confirmMessage}
              </Text>

              <ButtonGroup size="sm" sx={{ marginTop: "10px" }}>
                <Button
                  variant="ghost"
                  size="xs"
                  sx={{ color: "#444444" }}
                  _hover={{ bgColor: "none" }}
                  onClick={() => onConfirmAction("cancel")}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    bgColor: "#FCD0D0",
                    color: "#E55252",
                  }}
                  _hover={{ bgColor: "none" }}
                  size="xs"
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7891 0.206748C12.0703 0.482412 12.0703 0.929352 11.7891 1.20502L5.06912 7.79325C4.78794 8.06892 4.33206 8.06892 4.05088 7.79325L0.210883 4.02855C-0.0702944 3.75288 -0.0702944 3.30594 0.210883 3.03028C0.492061 2.75461 0.947939 2.75461 1.22912 3.03028L4.56 6.29585L10.7709 0.206748C11.0521 -0.0689161 11.5079 -0.0689161 11.7891 0.206748Z"
                        fill="#E55252"
                      />
                    </svg>
                  }
                  onClick={() => onConfirmAction("confirm")}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

IconButtonConfirm.displayName = "Button";

export default IconButtonConfirm;
