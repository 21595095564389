import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  generateSuccessStateVariables,
} from "@/Constants";
import dayjs from "dayjs";

const DEFAULT_PAGINATION = {
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
};

const initialState = {
  ...DEFAULT_LOADING_STATES,
  ...generateSuccessStateVariables(["Create", "Update"]),
  payload: [],
  details: {},
  pagination: DEFAULT_PAGINATION,
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_TIMESHEET_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_TIMESHEET_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_TIMESHEET_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.SET_TIMESHEET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_TIMESHEET_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.RESET_TIMESHEET_STATUS:
      return {
        ...state,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.RESET_TIMESHEET_REDUCER:
      return initialState;

    case ActionTypes.FETCH_TIMESHEET_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    }
    case ActionTypes.FETCH_TIMESHEET_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }
    case ActionTypes.GET_TIMESHEET_BY_ID_SUCCESS: {
      return {
        ...state,
        isGetLoading: false,
        details: payload,
      };
    }
    case ActionTypes.GET_TIMESHEET_BY_ID_FAILURE: {
      return {
        ...state,
        isGetLoading: false,
      };
    }
    case ActionTypes.CREATE_TIMESHEET_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    }
    case ActionTypes.CREATE_TIMESHEET_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: false,
      };
    }
    case ActionTypes.UPDATE_TIMESHEET_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    }
    case ActionTypes.UPDATE_TIMESHEET_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: false,
      };
    }
    case ActionTypes.DELETE_TIMESHEET_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    }
    case ActionTypes.DELETE_TIMESHEET_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: false,
      };
    }

    default:
      return state;
  }
};
