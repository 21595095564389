import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Icon, IconButton, Stack, Text, Tooltip } from "@chakra-ui/react";

import { Table } from "@/Components/Common";
import {
  type IColumn,
  type IPaginate,
  type IRow,
  type TActions,
} from "@/Types/Common.types";
import { IUser } from "../../../Interfaces/User.interface";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction?: (item: IUser, action: TActions) => void;
  showTotal?: boolean;
  isLoading?: boolean;
}

const Columns: IColumn[] = [
  { label: "No.", key: "no", textAlign: "center" },
  { label: "Client", key: "client", textAlign: "center" },
  {
    label: "Project",
    key: "project",
    textAlign: "left",
  },
  {
    label: "Server Link",
    key: "serverLink",
    textAlign: "left",
  },
  {
    label: "Manager",
    key: "manager",
    textAlign: "left",
  },
  {
    label: "Leader",
    key: "leaders",
    textAlign: "left",
  },
  {
    label: "Members",
    key: "members",
    textAlign: "left",
  },
  {
    label: "Tasks",
    key: "tasks",
    textAlign: "center",
  },
  {
    label: "Reviewer",
    key: "reviewer",
    textAlign: "center",
  },
  {
    label: "Performance",
    key: "performance",
    textAlign: "center",
  },
  {
    label: "Actions",
    key: "actions",
    textAlign: "center",
  },
];

const createRow = (
  no: JSX.Element,
  client: JSX.Element,
  project: JSX.Element,
  serverLink: JSX.Element,
  manager: JSX.Element,
  leaders: JSX.Element,
  members: JSX.Element,
  tasks: JSX.Element,
  reviewer: JSX.Element,
  performance: JSX.Element,
  actions: JSX.Element
): IRow => ({
  no,
  client,
  project,
  manager,
  serverLink,
  leaders,
  members,
  tasks,
  reviewer,
  performance,
  actions,
});

const DATA = [
  {
    id: "1",
    name: "Project 1",
    client: "Client 1",
    serverLink: "google.com",
    managers: [
      {
        id: "m-1",
        name: "Manager 1",
        avatar: "",
      },
      {
        id: "m-2",
        name: "Manager 2",
        avatar: "",
      },
    ],
    leaders: [
      {
        id: "l-1",
        name: "Leader 1",
        avatar: "",
      },
    ],
    artists: [
      {
        id: "a-1",
        name: "Staff 1",
        tasks: [
          {
            id: "t-1",
            name: "D00",
          },
        ],
        mass: "",
        performance: 80,
        note: "good",
      },
      {
        id: "a-2",
        name: "Staff 2",
        tasks: [
          {
            id: "t-2",
            name: "D01",
          },
        ],
        mass: "",
        performance: 80,
        note: "good",
      },
    ],
  },
];

const PerformanceDataTable: React.FC<IProps> = ({
  payload = DATA,
  paginate,
  onPageChange,
  // onRowAction,
  showTotal = false,
  isLoading,
}) => {
  const [rows, setRows] = useState<IRow[]>([]);

  useEffect(() => {
    const resolveRows: IRow[] = [];
    if (!_.isEmpty(DATA)) {
      _.forEach(DATA, (item: any, index: number) => {
        // const {
        //   client,
        //   project,
        //   manager,
        //   serverLink,
        //   leaders,
        //   members,
        //   tasks,
        //   reviewer,
        //   performance,
        //   status,
        // } = item;
        const currentPage = _.get(paginate, "currentPage");
        const resolveRowIndex = index + 1 + (currentPage - 1) * 10;
        const row = createRow(
          <Text>{resolveRowIndex}</Text>,
          <Text>{item.name}</Text>,
          <Text>serverLink</Text>,
          <Text>Manager 1</Text>,
          <Text>Leaders</Text>,
          <Text>Members</Text>,
          <Text>Tasks 1, Task 2</Text>,
          <Text>Members</Text>,
          <Text>Manager 1</Text>,
          <Text>100%</Text>,
          <Stack direction="row" justifyContent="center">
            <Tooltip label="View">
              <IconButton
                size="sm"
                isRound
                icon={<Icon as={EyeIcon} />}
                aria-label={""}
                onClick={() => {}}
              />
            </Tooltip>
            <Tooltip label="Edit">
              <IconButton
                size="sm"
                isRound
                icon={<Icon as={PencilSquareIcon} />}
                aria-label={""}
              />
            </Tooltip>
            <Tooltip label="Delete">
              <IconButton
                size="sm"
                isRound
                icon={<Icon as={TrashIcon} />}
                aria-label={""}
              />
            </Tooltip>
          </Stack>
        );
        resolveRows.push(row);
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <Table
      columns={Columns}
      payload={rows}
      paginate={paginate}
      onPageChange={onPageChange}
      showTotal={showTotal}
      isLoading={isLoading}
    />
  );
};

export default PerformanceDataTable;
