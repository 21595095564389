import React, { useEffect, useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react";

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: any;
  onRowAction?: (action: any, id: string) => void;
}

const AggregateOvertimeDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);

  useEffect(() => {
    if (!open) onClose();
  }, [open]);

  const _renderContent = () => {
    return (
      <Box>
        {_.map(payload, (item, index) => {
          return (
            <Box
              key={index}
              my={4}
              sx={{ display: "flex", textTransform: "capitalize" }}
            >
              <Box>
                <Text sx={{ size: "sm", fontWeight: 700, mr: 4 }}>
                  {index + 1}.
                </Text>
              </Box>
              <Box>
                <Box sx={{ display: "flex", gap: 14 }}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      Date:
                    </Text>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      {item.date}
                    </Text>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      Date Type:
                    </Text>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      {item.dateType}
                    </Text>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 8 }}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      Start Time:
                    </Text>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      {item.startTime}
                    </Text>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      End Time:
                    </Text>
                    <Text size={"sm"} sx={{ fontWeight: 500 }}>
                      {item.endTime}
                    </Text>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Text size={"sm"} sx={{ fontWeight: 500 }}>
                    Working Time:
                  </Text>
                  <Text size={"sm"} sx={{ fontWeight: 500 }}>
                    {item.workingTime}
                  </Text>
                </Box>
                <Divider orientation="horizontal" mt={2} />
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 1px -1px gray",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}> Aggregate Overtime Detail</Text>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 450, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px -1px 1px gray",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            Cancel
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AggregateOvertimeDialog;
