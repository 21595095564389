import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  role: "",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isDeleteSuccess: false,
  isUpdataPasswordSuccess: false,
  isUpdateForSelfSuccess: false,

  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  userList: [],
  userDetail: {},
  forSelfData: {},
  isMessage: "",
  performAction: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_USER_REDUCER:
      return {
        ...initialState,
      };
    case ActionTypes.SET_USER_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: true,
        isUpdateForSelfSuccess: false,
      };
    case ActionTypes.SET_USER_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
        isActionLoading: false,
        isUpdateForSelfSuccess: false,
      };
    case ActionTypes.SET_USER_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: false,
        isGetLoading: true,
        isActionLoading: false,
      };
    case ActionTypes.SET_META_USER:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_USER:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };
    case ActionTypes.USER_PERFORM_ACTION:
      return {
        ...state,
        performAction: payload,
      };
    case ActionTypes.RESET_USER_PERFORM_ACTION:
      return {
        ...state,
        performAction: {},
      };

    case ActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
      };

    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isDeleteSuccess: false,
        isGetLoading: false,
        userList: payload,
        isActionLoading: false,
      };

    case ActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isDeleteSuccess: false,
        isGetLoading: false,
        userList: [],
        isActionLoading: false,
      };

    case ActionTypes.GET_BY_ID_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        userDetail: payload,
      };
    case ActionTypes.GET_BY_ID_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        userDetail: {},
      };

    case ActionTypes.GET_FOR_SELF_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        forSelfData: payload,
      };
    case ActionTypes.GET_FOR_SELF_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        forSelfData: {},
      };

    case ActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isUpdateForSelfSuccess: true,
        userDetail: payload,
      };
    case ActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isUpdateForSelfSuccess: false,
      };

    case ActionTypes.UPDATE_FOR_SELF_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isUpdateForSelfSuccess: true,
      };
    case ActionTypes.UPDATE_FOR_SELF_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isUpdateForSelfSuccess: false,
      };

    case ActionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.UPDATE_PASSWORD_FOR_SELF_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isUpdataPasswordSuccess: true,
        isMessage: "",
        isGetLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.UPDATE_PASSWORD_FOR_SELF_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isUpdataPasswordSuccess: false,
        isMessage: payload,
        isGetLoading: false,
        isActionLoading: false,
      };

    case ActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isDeleteSuccess: true,
        isActionLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isDeleteSuccess: false,
        isGetLoading: false,
      };

    case ActionTypes.RESET_ACTION_USER_REDUCER:
      return {
        ...state,
        isUpdataPasswordSuccess: false,
      };

    case ActionTypes.RESET_USER_MESSAGE:
      return {
        ...state,
        isMessage: "",
      };

    case ActionTypes.RESET_USER_DETAIL:
      return {
        ...state,
        userDetail: {},
      };

    default:
      return state;
  }
};
