import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

import { BoardActions } from "@/Actions";
import ProjectExteriorActions from "./ProjectExterior.actions";
import {
  IFilterAggregate,
  IFilterOverTime,
  ILogTimeStructure,
} from "@/Interfaces/LogTime.interface";

const { getBoardById } = BoardActions;
const { getProjectExteriorById } = ProjectExteriorActions;

const setLogTimeFetchLoading = () => {
  return {
    type: ActionTypes.SET_LOG_TIME_FETCH_LOADING,
  };
};

const setAggregateFetchLoading = () => {
  return {
    type: ActionTypes.SET_AGGREGATE_FETCH_LOADING,
  };
};

const setLogTimeGetLoading = () => {
  return {
    type: ActionTypes.SET_LOG_TIME_GET_LOADING,
  };
};

const setLogTimeActionLoading = () => {
  return {
    type: ActionTypes.SET_LOG_TIME_ACTION_LOADING,
  };
};

const setMetaLogTime = (payload: any) => {
  return {
    type: ActionTypes.SET_LOG_TIME_META,
    payload,
  };
};

const setMyTimePagination = (payload: any) => {
  return { type: ActionTypes.SET_MY_TIME_PAGINATION, payload };
};

const setPaginationLogTime = (payload: any) => {
  return {
    type: ActionTypes.SET_LOG_TIME_PAGINATION,
    payload,
  };
};

export const performAction = (
  actionType: string,
  id: string,
  extraData?: any
) => ({
  type: ActionTypes.LOG_TIME_PERFORM_ACTION,
  payload: { actionType, id, extraData },
});

const resetLogTimePerformAction = () => {
  return {
    type: ActionTypes.RESET_LOG_TIME_PERFORM_ACTION,
  };
};

const resetLogTimeStatus = () => {
  return {
    type: ActionTypes.RESET_LOG_TIME_STATUS,
  };
};

const resetLogTimeDetail = () => {
  return {
    type: ActionTypes.RESET_LOG_TIME_DETAIL,
  };
};

const resetLogTimeReducer = () => {
  return {
    type: ActionTypes.RESET_LOG_TIME_REDUCER,
  };
};
const createLogTimeSuccess = () => {
  return {
    type: ActionTypes.CREATE_LOG_TIME_SUCCESS,
  };
};

const createLogTimeFail = () => {
  return {
    type: ActionTypes.CREATE_LOG_TIME_FAILURE,
  };
};

const createLogtime = (
  payload: any,
  type?: boolean,
  getForTimeCard?: boolean,
  pagination?: any
) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.createLogtime(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createLogTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createLogTimeSuccess());
          if (!type)
            await Toast({
              title: message,
              status: "success",
            });
          if (getForTimeCard) await dispatch(fetchLogTime(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(createLogTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createLogtimeInMyTime = (
  payload: any,
  pagination: any,
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.createLogtime(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createLogTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createLogTimeSuccess());
          dispatch(getMyTime(pagination));
          onSuccess();
        }
      })
      .catch(async (error) => {
        await dispatch(createLogTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchLogTimeSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_SUCCESS,
    payload,
  };
};

const fetchLogTimeFail = () => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_FAILURE,
  };
};

const fetchLogTime = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.fetchLogtime(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchLogTimeFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaLogTime(_.get(result, "meta")));
          await dispatch(setPaginationLogTime(payload));
          await dispatch(fetchLogTimeSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchLogTimeFail());
      });
  };
};

const fetchBoardIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_BY_BOARD_ID_SUCCESS,
    payload,
  };
};

const fetchByBoardIdFailure = () => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_BY_BOARD_ID_FAILURE,
  };
};

const fetchLogTimeByBoardId = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.fetchLogtimeByBoardId(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchByBoardIdFailure());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchBoardIdSuccess(resolveResult.items));
        }
      })
      .catch(async (error) => {
        await dispatch(fetchByBoardIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdLogTimeSuccess = (payload: ILogTimeStructure) => {
  return {
    type: ActionTypes.GET_BY_ID_LOG_TIME_SUCCESS,
    payload,
  };
};

const getByIdLogTimeFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_LOG_TIME_FAILURE,
  };
};

const getByIdLogTime = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeGetLoading());
    await APIs.getLogtimeById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(getByIdLogTimeFail());
        } else {
          dispatch(getByIdLogTimeSuccess(result));
        }
      })
      .catch(async (error) => {
        await dispatch(getByIdLogTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateLogTimeSuccess = () => {
  return {
    type: ActionTypes.UPDATE_LOG_TIME_SUCCESS,
  };
};

const updateLogTimeFail = () => {
  return {
    type: ActionTypes.UPDATE_LOG_TIME_FAILURE,
  };
};

const updateLogtime = (
  id: string,
  payload: any,
  boardId?: string,
  pagination?: any,
  getForTimeCard?: boolean,
  timelinePagination?: any,
  exteriorId?: string
) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.updateLogtime(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateLogTimeFail());
          await Toast({
            title: message,
            //description:
            //  message === "start time or end time invalid"
            //    ? "Start time or end time invalid"
            //    : message,
            status: "error",
          });
        } else {
          dispatch(updateLogTimeSuccess());
          if (exteriorId) {
            dispatch(getProjectExteriorById(exteriorId));
          }
          if (boardId) {
            dispatch(getBoardById(boardId));
            console.log(timelinePagination);

            //if (timelinePagination)
            //  dispatch(getTimelineInBoard(boardId, timelinePagination));
            //else dispatch(getBoardById(boardId));
          }
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLogTime(pagination));
          if (getForTimeCard)
            await dispatch(fetchAggregate({ page: 0, limit: 0 }));
        }
      })
      .catch(async (error) => {
        await dispatch(updateLogTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateLogtimeInTimeline = (id: string, payload: any, boardId: string) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.updateLogtime(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateLogTimeFail());
          await Toast({
            title: message,
            //description:
            //  message === "start time or end time invalid"
            //    ? "Start time or end time invalid"
            //    : message,
            status: "error",
          });
        } else {
          dispatch(getBoardById(boardId));
          Toast({
            title: message,
            status: "success",
          });
          dispatch(updateLogTimeSuccess());
        }
      })
      .catch(async (error) => {
        await dispatch(updateLogTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusLogTimeSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_LOG_TIME_SUCCESS,
  };
};

const changeStatusTimeFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_LOG_TIME_FAILURE,
  };
};

const changeStatusLogtime = (
  id: string,
  payload: any,
  pagination: IFilterOverTime
) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.changeStatusLogtime(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusTimeFail());
          await Toast({
            title: message,
            description: message,
            status: "error",
          });
        } else {
          dispatch(changeStatusLogTimeSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLogTime(pagination));
          //  await dispatch(fetchAggregate({ page: 0, limit: 0 }));
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusTimeFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const deleteLogTimeSuccess = () => {
  return {
    type: ActionTypes.DELETE_LOG_TIME_SUCCESS,
  };
};

const deleteTimeFail = () => {
  return {
    type: ActionTypes.DELETE_LOG_TIME_FAILURE,
  };
};

const deleteLogTime = (
  id: string,
  boardId?: string,
  pagination?: IFilterOverTime
) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.deleteLogtime(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteLogTimeSuccess());
          if (boardId) {
            dispatch(getBoardById(boardId));
            dispatch(resetLogTimePerformAction());
          }
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLogTime(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(deleteTimeFail());
        await dispatch(resetLogTimePerformAction());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteLogTimeInMyTime = (id: string, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.deleteLogtime(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteLogTimeSuccess());

          Toast({
            title: message,
            status: "success",
          });
          await dispatch(getMyTime(pagination));
          await dispatch(resetLogTimePerformAction());
        }
      })
      .catch(async (error) => {
        await dispatch(deleteTimeFail());
        await dispatch(resetLogTimePerformAction());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteLogTimeInDashboard = (id: string, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeActionLoading());
    await APIs.deleteLogtime(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteLogTimeSuccess());

          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLogTimeDashboard(pagination));
          await dispatch(resetLogTimePerformAction());
        }
      })
      .catch(async (error) => {
        await dispatch(deleteTimeFail());
        await dispatch(resetLogTimePerformAction());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getMyTimeSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_MY_TIME_SUCCESS,
    payload,
  };
};

const getMyTimeFail = () => {
  return {
    type: ActionTypes.GET_MY_TIME_FAILURE,
  };
};

const getMyTime = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.getMyTime(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        dispatch(setMyTimePagination(payload));
        if (!result) {
          await dispatch(getMyTimeFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(getMyTimeSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(getMyTimeFail());
      });
  };
};

const setMetaAggregate = (payload: any) => {
  return {
    type: ActionTypes.SET_AGGREGATE_META,
    payload,
  };
};

const setPaginationAggregate = (payload: IFilterAggregate) => {
  return {
    type: ActionTypes.SET_AGGREGATE_PAGINATION,
    payload,
  };
};

const fetchAggregateSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_AGGREGATE_SUCCESS,
    payload,
  };
};

const fetchAggregateFail = () => {
  return {
    type: ActionTypes.FETCH_AGGREGATE_FAILURE,
  };
};

const fetchAggregate = (payload: IFilterAggregate) => {
  return async (dispatch: any) => {
    dispatch(setAggregateFetchLoading());
    await APIs.fetchAggregate(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchAggregateFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaAggregate(_.get(result, "meta")));
          await dispatch(setPaginationAggregate(payload));
          await dispatch(fetchAggregateSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchAggregateFail());
      });
  };
};

const fetchLogTimeDashboardSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_DASH_BOARD_SUCCESS,
    payload,
  };
};

const fetchLogDashboardTimeFail = () => {
  return {
    type: ActionTypes.FETCH_LOG_TIME_DASH_BOARD_FAILURE,
  };
};

const fetchLogTimeDashboard = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.fetchLogtimeDashboard(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchLogDashboardTimeFail());
        } else {
          //  await dispatch(setMetaLogTime(_.get(result, "meta")));
          //  await dispatch(setPaginationLogTime(payload));
          await dispatch(fetchLogTimeDashboardSuccess(response?.data?.payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchLogDashboardTimeFail());
      });
  };
};

const fetchDealineDashboardSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_DEADLINE_DASH_BOARD_SUCCESS,
    payload,
  };
};

const fetchDealineDashboardFail = () => {
  return {
    type: ActionTypes.FETCH_DEADLINE_DASH_BOARD_FAILURE,
  };
};

const fetchDealineDashboard = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.fetchLogtimeDelineDashboard(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchDealineDashboardFail());
        } else {
          //  await dispatch(setMetaLogTime(_.get(result, "meta")));
          //  await dispatch(setPaginationLogTime(payload));
          await dispatch(fetchDealineDashboardSuccess(response?.data?.payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchDealineDashboardFail());
      });
  };
};

const fetchMyWorkSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MY_WORK_DASH_BOARD_SUCCESS,
    payload,
  };
};

const fetchMyWorkFail = () => {
  return {
    type: ActionTypes.FETCH_MY_WORK_DASH_BOARD_FAILURE,
  };
};

const fetchMyWorkDashboard = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLogTimeFetchLoading());
    await APIs.fetchMyWorkDashboard(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchMyWorkFail());
        } else {
          await dispatch(fetchMyWorkSuccess(response?.data?.payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchMyWorkFail());
      });
  };
};

export default {
  createLogtime,
  fetchLogTime,
  getMyTime,
  getByIdLogTime,
  updateLogtime,
  changeStatusLogtime,
  deleteLogTime,
  deleteLogTimeInMyTime,
  performAction,
  resetLogTimePerformAction,
  fetchLogTimeByBoardId,
  resetLogTimeStatus,
  resetLogTimeDetail,
  resetLogTimeReducer,
  fetchAggregate,
  fetchLogTimeDashboard,
  fetchDealineDashboard,
  fetchMyWorkDashboard,
  updateLogtimeInTimeline,
  setMyTimePagination,
  createLogtimeInMyTime,
  deleteLogTimeInDashboard,
};
