import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { EMAIL_RECEIVE } = APIs;

export const createMailReceive = async (payload: {
  mail: string;
  type: string;
}) => {
  return request(EMAIL_RECEIVE.BASIC, "POST", payload);
};

export const updateMailReceive = async (
  id: string,
  payload: {
    mail: string;
    type: string;
  }
) => {
  return request(`${EMAIL_RECEIVE.BASIC}/${id}`, "PUT", payload);
};

export const fetchMailReceive = async () => {
  return request(EMAIL_RECEIVE.BASIC, "GET");
};
