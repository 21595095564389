import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { TIME_SHEET } = APIs;

export const fetchTimesheets = async (payload?: any) => {
  return await request(TIME_SHEET.BASIC, "GET", payload);
};

export const getTimesheetById = async (id: string) => {
  return await request(`${TIME_SHEET.GET_BY_ID}/${id}`, "GET");
};

export const createTimesheet = async (payload: any) => {
  return await request(TIME_SHEET.BASIC, "POST", payload);
};

export const updateTimesheet = async (id: string, payload: any) => {
  return await request(`${TIME_SHEET.BASIC}/${id}`, "PUT", payload);
};

export const deleteTimesheet = async (id: string) => {
  return await request(`${TIME_SHEET.DELETE}/${id}`, "PUT");
};
