import React from "react";
import { Box } from "@chakra-ui/react";

import PortentialChats from "../PortentialChats";

import UserChat from "../UserChat";

const ChatDesktop: React.FC = () => {
  const _renderChat = () => {
    return (
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            maxW: "250px",
          }}
        >
          <PortentialChats />
        </Box>
        <Box sx={{ h: "100%", w: "100%", borderLeft: "1px solid silver" }}>
          <UserChat />
        </Box>
      </Box>
    );
  };

  return _renderChat();
};

export default ChatDesktop;
