import { useState, useEffect } from "react";

const useWindowWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []); // Empty dependency array to ensure effect runs only once

  return width;
};

export default useWindowWidth;
