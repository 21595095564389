import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { WORKSPACE, WORKSPACE_SHORT } = APIs;

export const fetchWorkspace = async (payload: any) => {
  return request(WORKSPACE.BASIC, "GET", payload);
};

export const updateWorkspace = async (id: string, payload: any) => {
  return request(`${WORKSPACE.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const getWorkspaceById = async (id: string) => {
  return request(`${WORKSPACE.GET_BY_ID}/${id}`, "GET");
};

export const createWorkspace = async (payload: any) => {
  return await request(WORKSPACE.BASIC, "POST_FORM_DATA", payload);
};

export const deleteWorkspace = async (id: string) => {
  return await request(`${WORKSPACE.DELETE}/${id}`, "PUT");
};

export const fetchWorkspaceShort = async (payload: any) => {
  return request(WORKSPACE_SHORT.BASIC, "GET", payload);
};

export const updateWorkspaceShort = async (id: string, payload: any) => {
  return request(`${WORKSPACE_SHORT.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const getWorkspaceShortById = async (id: string) => {
  return request(`${WORKSPACE_SHORT.GET_BY_ID}/${id}`, "GET");
};

export const createWorkspaceShort = async (payload: any) => {
  return await request(WORKSPACE_SHORT.BASIC, "POST_FORM_DATA", payload);
};

export const deleteWorkspaceShort = async (id: string) => {
  return await request(`${WORKSPACE_SHORT.DELETE}/${id}`, "PUT");
};
