import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  useOutsideClick,
  HStack,
  Box,
  Text,
  Portal,
  Popover,
  PopoverContent,
  PopoverAnchor,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Utils from "@/Utils";

type CalendarView = "decade" | "year";

interface IProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  defaultValue?: { startDate?: string; endDate?: string };
  onDateChange(newTime: { startDate?: string; endDate?: string }): void;
}

const MonthPicker: React.FC<IProps> = ({
  isError,
  defaultValue,
  onDateChange,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const language = Utils.getSavedLanguage();
  const [date, setDate] = useState<any>(dayjs());
  const [showMonthCalendar, setShowMonthCalendar] = useState(false);
  const [showYearCalendar, setShowYearCalendar] = useState(false);
  const [monthInputValue, setMonthInputValue] = useState(
    dayjs(date).month() + 1
  );
  const [yearInputValue, setYearInputValue] = useState(dayjs(date).year());

  const [selectedView, setSelectedView] = useState<CalendarView>("year");
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (defaultValue && defaultValue.startDate && defaultValue.endDate) {
      setMonthInputValue(dayjs(defaultValue.startDate).month() + 1);
      setYearInputValue(dayjs(defaultValue.endDate).year());
    }
  }, [defaultValue]);

  const onClickOutside = () => {
    setShowMonthCalendar(false);
    setShowYearCalendar(false);
  };

  useOutsideClick({
    ref,
    handler: onClickOutside,
  });

  const handleMonthInputClick = () => {
    setShowMonthCalendar(true);
    setShowYearCalendar(false);
    setSelectedView("year");
  };

  const handleYearInputClick = () => {
    setShowMonthCalendar(false);
    setShowYearCalendar(true);
    setSelectedView("decade");
  };

  const handleMonthClick = (value: any, event: any) => {
    event.stopPropagation();

    const updatedDate = {
      ...date,
      startDate: dayjs(dayjs(value).startOf("month")).format("YYYY-MM-DD"),
      endDate: dayjs(dayjs(value).endOf("month")).format("YYYY-MM-DD"),
    };

    setDate(value);
    setMonthInputValue(dayjs(value).month() + 1);

    onDateChange(updatedDate);

    setShowMonthCalendar(false);
    setShowYearCalendar(false);
  };

  const handleYearClick = (value: any, event: any) => {
    event.stopPropagation();

    const updatedDate = {
      ...date,
      startDate: dayjs(dayjs(value).startOf("month")).format("YYYY-MM-DD"),
      endDate: dayjs(dayjs(value).endOf("month")).format("YYYY-MM-DD"),
    };

    setDate(value);
    setYearInputValue(dayjs(value).year());

    onDateChange(updatedDate);

    // setShowMonthCalendar(false);
    // setShowYearCalendar(false);
  };

  return (
    <Box
      sx={{
        w: "full",
      }}
    >
      <Popover isOpen={showMonthCalendar || showYearCalendar} placement="auto">
        <PopoverAnchor>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              px: 2,
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              outline: `2px solid ${
                isFocus || showMonthCalendar || showYearCalendar
                  ? "#3182ce"
                  : isError
                  ? "#E53E3E"
                  : "transparent"
              }`,
              outlineOffset: "-2px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HStack>
              <Box
                as="input"
                type="text"
                value={monthInputValue.toString().padStart(2, "0")}
                onClick={handleMonthInputClick}
                readOnly
                sx={{
                  outline: "none",
                  maxW: 5,
                  textAlign: "center",
                  background:
                    isFocus || showMonthCalendar || showYearCalendar
                      ? "rgba(255, 0, 0, 0.1)"
                      : "transparent",
                }}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              />
              <Text>/</Text>
              <Box
                as="input"
                type="text"
                value={yearInputValue}
                onClick={handleYearInputClick}
                readOnly
                sx={{
                  outline: "none",
                  maxW: 10,
                  textAlign: "center",
                  background:
                    isFocus || showMonthCalendar || showYearCalendar
                      ? "rgba(255, 0, 0, 0.1)"
                      : "transparent",
                }}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              />
            </HStack>
            <Box
              sx={{
                cursor: "pointer",
                svg: {
                  borderColor:
                    isFocus || showMonthCalendar || showYearCalendar
                      ? "#0078d7"
                      : "inherit",
                },
              }}
              onClick={() => setShowMonthCalendar(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                stroke="black"
                strokeWidth="2"
                className="react-daterange-picker__calendar-button__icon react-daterange-picker__button__icon"
              >
                <rect fill="none" height="15" width="15" x="2" y="2"></rect>
                <line x1="6" x2="6" y1="0" y2="4"></line>
                <line x1="13" x2="13" y1="0" y2="4"></line>
              </svg>
            </Box>
          </Box>
        </PopoverAnchor>
        <Portal>
          <Box
            sx={{
              ".css-1qq679y": {
                zIndex: "9999 !important",
              },
            }}
          >
            <PopoverContent ref={ref}>
              <Box zIndex="popover">
                <Calendar
                  value={date}
                  view={selectedView}
                  onClickYear={handleYearClick}
                  onClickMonth={handleMonthClick}
                  onViewChange={({ view }) => {
                    setSelectedView((prevState) =>
                      view === "decade" || view === "year" ? view : prevState
                    );
                  }}
                  locale={language === "en" ? "en" : "vi"}
                />
              </Box>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </Box>
  );
};

export default MonthPicker;
