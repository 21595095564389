import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import {
  ICreateLeaveDays,
  IFilterLeaveDays,
} from "@/Interfaces/LeaveDays.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setLeaveDaysFetchLoading = () => {
  return {
    type: ActionTypes.SET_LEAVE_DAYS_FETCH_LOADING,
  };
};

const setLeaveDaysGetLoading = () => {
  return {
    type: ActionTypes.SET_LEAVE_DAYS_GET_LOADING,
  };
};

const setLeaveDaysActionLoading = () => {
  return {
    type: ActionTypes.SET_LEAVE_DAYS_ACTION_LOADING,
  };
};

const resetLeaveDaysReducer = () => {
  return {
    type: ActionTypes.RESET_LEAVE_DAYS,
  };
};

const resetLeaveDayDetails = () => {
  return {
    type: ActionTypes.RESET_LEAVE_DAY_DETAILS,
  };
};

const setMetaLeaveDays = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_LEAVE_DAYS,
    payload,
  };
};

const setPaginationLeaveDays = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_LEAVE_DAYS,
    payload,
  };
};

const fetchLeaveDaysFilterCreatedSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_LEAVE_DAYS_FILTER_CREATE_SUCCESS,
    payload,
  };
};

const fetchLeaveDaysSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_LEAVE_DAYS_SUCCESS,
    payload,
  };
};

const fetchLeaveDaysFail = () => {
  return {
    type: ActionTypes.FETCH_LEAVE_DAYS_FAILURE,
  };
};

const fetchLeaveDays = (payload: any, action?: string) => {
  return async (dispatch: any) => {
    dispatch(setLeaveDaysFetchLoading());
    await APIs.fetchLeaveDays(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchLeaveDaysFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          if (action === "filter")
            dispatch(fetchLeaveDaysFilterCreatedSuccess(items));
          else {
            dispatch(fetchLeaveDaysSuccess(items));
            dispatch(setMetaLeaveDays(meta));
            dispatch(setPaginationLeaveDays(payload));
          }
        }
      })
      .catch(async () => {
        await dispatch(fetchLeaveDaysFail());
      });
  };
};

const createLeaveDaysSuccess = () => {
  return {
    type: ActionTypes.CREATE_LEAVE_DAYS_SUCCESS,
  };
};

const createLeaveDaysFail = () => {
  return {
    type: ActionTypes.CREATE_LEAVE_DAYS_FAILURE,
  };
};

const createLeaveDays = (
  payload: ICreateLeaveDays,
  pagination: IFilterLeaveDays
) => {
  return async (dispatch: any) => {
    dispatch(setLeaveDaysActionLoading());
    await APIs.createLeaveDays(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createLeaveDaysFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createLeaveDaysSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLeaveDays(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(createLeaveDaysFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdLeaveDaysSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_LEAVE_DAYS_SUCCESS,
    payload,
  };
};

const getByIdLeaveDaysFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_LEAVE_DAYS_FAILURE,
  };
};

const getByIdLeaveDays = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setLeaveDaysGetLoading());
    await APIs.getLeaveDaysById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdLeaveDaysFail());
        else {
          dispatch(getByIdLeaveDaysSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdLeaveDaysFail());
      });
  };
};

const updateLeaveDaysSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_LEAVE_DAYS_SUCCESS,
    payload,
  };
};

const updateLeaveDaysFail = () => {
  return {
    type: ActionTypes.UPDATE_LEAVE_DAYS_FAILURE,
  };
};

const updateLeaveDays = (id: string, payload: any, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setLeaveDaysActionLoading());
    await APIs.updateLeaveDays(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateLeaveDaysFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateLeaveDaysSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchLeaveDays(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(updateLeaveDaysFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  createLeaveDays,
  fetchLeaveDays,
  getByIdLeaveDays,
  updateLeaveDays,
  resetLeaveDaysReducer,
  resetLeaveDayDetails,
};
