import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Stack,
  Heading,
  Grid,
  GridItem,
  Text,
  Box,
  //  Card,
  //  CardHeader,
  //  CardBody,
  CircularProgress,
  Tabs,
  TabList,
  Tab,
  //  Image,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";
import { RootState, useTypedDispatch } from "@/Store";
import {
  HolidayActions,
  ProjectActions,
  DayOffRequestActions,
  ChatActions,
  WorkingDayActions,
} from "@/Actions";
import { ENUMS } from "@/Constants";

import StatisticBox from "./StatisticBox";
import TimeSheet from "./TimeSheet";
//import { IProjectStructure } from "@/Interfaces/Project.interface";
import {
  //  AvatarGroupWithOverflow,
  //  TextComponent,
  ChartComponent,
} from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";

import processImage from "@/Assets/process.svg";
import pendingImage from "@/Assets/pending.svg";
import historyImage from "@/Assets/history.svg";
import finalImage from "@/Assets/final.svg";
//import noDataFound from "@/Assets/no_data.png";
import LogTimeAction from "@/Actions/LogTime.action";
import DealineDashboardDataTable from "@/Components/LayoutPart/DataTable/DealineDashboardDataTable";
import TimeSheetMyWork from "./TimesheetsMyWork";
import { ConfirmDialog, TimeLogDialog } from "@/Components/Popup";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

//import { TActions } from "@/Types/Common.types";

const {
  //  fetchProjectsForDashboard,
  getStatisticalProjects,
  resetProjectReducer,
} = ProjectActions;
const { fetchDayOffRequest, resetDayOffRequestReducer } = DayOffRequestActions;
const { fetchDealineDashboard, resetLogTimePerformAction, deleteLogTime } =
  LogTimeAction;
const { fetchHoliday, resetHolidayReducer } = HolidayActions;
const { fetchAllUsers } = ChatActions;
const { fetchWorkingDay, resetWorkingDayReducer } = WorkingDayActions;

const { PROJECT_STATUS_COLOR } = ENUMS;

const Dashboard: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const [openMyWork, setOpenMyWork] = useState<string>("overview");
  const [searchParams, setSearchParams] = useSearchParams();

  const statistical = useSelector((state: RootState) =>
    _.get(state.PROJECT, "statistical")
  );

  //  const payload = useSelector((state: RootState) =>
  //    _.get(state.PROJECT, "getForDashboard")
  //  );
  const deline = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logTimeDeadlineDashboard")
  );

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isGetLoading")
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isFetchLoading")
  );

  const timeLogPerformAction: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );

  const isDeleteLogSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isDeleteSuccess")
  );

  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  useEffect(() => {
    dispatch(fetchWorkingDay());
    dispatch(fetchAllUsers());
    dispatch(getStatisticalProjects());
    dispatch(fetchDealineDashboard({ page: 0, limit: 0 }));
    dispatch(
      fetchDayOffRequest({
        status: ENUMS.DAY_OFF_REQUEST_STATUS.APPROVED,
        page: 0,
        limit: 0,
      })
    );
    return () => {
      dispatch(resetProjectReducer());
      dispatch(resetDayOffRequestReducer());
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    if (isDeleteLogSuccess) {
      dispatch(resetLogTimePerformAction());
    }
  }, [isDeleteLogSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(fetchDealineDashboard({ page: 0, limit: 0 }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  const _renderStatisticSection = () => {
    return (
      <Grid templateColumns="repeat(4, 1fr)" gap={isMobile ? 2 : 3}>
        <GridItem>
          <StatisticBox
            label={t("label.waitingProcess")}
            value={statistical?.[ENUMS.PROJECT_STATUS.WAITING_PROCESS] || 0}
            sxContainer={{
              //  backgroundImage: `url("${staticGrayImage}")`,
              background: "rgba(92, 110, 108, 0.6)",
            }}
            icon={pendingImage}
            isLoading={isGetLoading}
          />
        </GridItem>
        <GridItem>
          <StatisticBox
            label={t("label.ongoing")}
            value={
              (statistical?.[ENUMS.PROJECT_STATUS.STARTING] || 0) +
              (statistical?.[ENUMS.PROJECT_STATUS.IN_PROCESS] || 0)
            }
            sxContainer={{
              //  backgroundImage: `url("${staticBlueImage}")`,
              background: "rgba(210, 169, 106,0.6)",
            }}
            icon={processImage}
            isLoading={isGetLoading}
          />
        </GridItem>
        <GridItem>
          <StatisticBox
            label={t("label.finalDeliver")}
            value={statistical?.[ENUMS.PROJECT_STATUS.FINAL_DELIVER] || 0}
            sxContainer={{
              //  backgroundImage: `url("${staticGreenImage}")`,
              background: "rgba(187, 113, 84, 0.6)",
            }}
            icon={finalImage}
            isLoading={isGetLoading}
          />
        </GridItem>
        <GridItem>
          <StatisticBox
            label={t("label.history")}
            value={statistical?.[ENUMS.PROJECT_STATUS.HISTORY] || 0}
            sxContainer={{
              //  backgroundImage: `url("${staticYellowImage}")`,
              background: "rgba(166, 183, 170, 0.6)",
            }}
            icon={historyImage}
            isLoading={isGetLoading}
          />
        </GridItem>
      </Grid>
    );
  };

  //  const _renderChip = (color: string, bgColor: string, value: string) => (
  //    <Text
  //      color={color}
  //      bgColor={bgColor}
  //      sx={{
  //        textTransform: "capitalize",
  //        fontWeight: 700,
  //        borderRadius: "20px",
  //        px: 2,
  //        py: 1,
  //        fontSize: "12px",
  //        w: "max-content",
  //      }}
  //    >
  //      {value}
  //    </Text>
  //  );

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Tabs>
          <TabList
            sx={{
              width: "100%",
            }}
          >
            <Tab
              sx={{
                w: 200,
                fontSize: 16,
              }}
              _selected={{
                color: "#bb7154",
                fontWeight: 600,
                borderBottom: "4px solid #bb7154",
              }}
              onClick={() => setOpenMyWork("overview")}
            >
              {t("label.overview")}
            </Tab>
            <Tab
              sx={{
                w: 200,
                fontSize: 16,
              }}
              _selected={{
                color: "#bb7154",
                fontWeight: 600,
                borderBottom: "4px solid #bb7154",
              }}
              onClick={() => setOpenMyWork("myWork")}
            >
              {t("label.myWork")}
            </Tab>
          </TabList>
        </Tabs>
      </Stack>
    );
  };

  const _renderTimesheetSection = (typeChat: string) => {
    switch (typeChat) {
      case "overview":
        return <TimeSheet />;
      case "myWork":
        return <TimeSheetMyWork />;
      default:
        return;
    }
  };

  const _renderPopup = () => {
    return (
      <>
        <TimeLogDialog.UpdateTimelogDialog
          open={timeLogPerformAction?.actionType === "editLog"}
          onClose={() => {
            if (searchParams) {
              searchParams.delete("id");
              setSearchParams(searchParams);
            }
            dispatch(resetLogTimePerformAction());
          }}
        />
        <ConfirmDialog
          isOpen={timeLogPerformAction?.actionType === "deleteLog"}
          onClose={() => dispatch(resetLogTimePerformAction())}
          onAction={() => {
            dispatch(deleteLogTime(timeLogPerformAction.id));
          }}
          body={t("message.doYouWantRemoveThisLog")}
          actionType="delete"
        />
      </>
    );
  };

  //  const _renderProjectCards = () => (
  //    <Stack spacing="4" sx={{ flex: 1 }}>
  //      {_.isEmpty(projectsWithEndDateToday) && !isFetchLoading && (
  //        <Card variant="elevated">
  //          <CardBody>
  //            <Image src={noDataFound} />
  //          </CardBody>
  //        </Card>
  //      )}
  //      {_.map(projectsWithEndDateToday, (pr) => {
  //        const tasks = pr?.kanbanBoard?.task || [];
  //        const taskTitles =
  //          tasks.length > 0
  //            ? _.map(tasks, (task) => task?.title).join(", ")
  //            : "";
  //        const managers = pr?.projectMember.filter((item: any) => {
  //          return _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
  //          );
  //        });
  //        const leaders = pr?.projectMember.filter((item: any) => {
  //          const hasLeaderRole = _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
  //          );
  //          const hasManagerRole = _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
  //          );
  //          return hasLeaderRole && !hasManagerRole;
  //        });
  //        const artists = pr?.projectMember.filter((item: any) => {
  //          const hasArtistRole = _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
  //          );

  //          const hasLeaderRole = _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
  //          );
  //          const hasManagerRole = _.some(
  //            item?.user?.userRole,
  //            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
  //          );
  //          return hasArtistRole && !hasLeaderRole && !hasManagerRole;
  //        });
  //        return (
  //          <Card variant="elevated" key={pr?.id}>
  //            <CardHeader>
  //              <Text fontSize="16px" fontWeight={700}>
  //                {pr?.name} ({pr?.code})
  //              </Text>
  //            </CardHeader>
  //            <CardBody sx={{ pt: 0 }}>
  //              <Grid gridTemplateColumns="repeat(4, 1fr)" gap={2}>
  //                <Box>
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Client
  //                  </Text>
  //                  <TextComponent
  //                    content={pr?.client || "--"}
  //                    sx={{ fontSize: "14px" }}
  //                    maxW={"100%"}
  //                  />
  //                </Box>
  //                <Box>
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Server Path
  //                  </Text>
  //                  <TextComponent
  //                    content={pr?.serverPath || "--"}
  //                    sx={{ fontSize: "14px", textAlign: "center" }}
  //                    maxW={"100px"}
  //                  />
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text
  //                    fontSize="15px"
  //                    sx={{ width: "max-content" }}
  //                    fontWeight={700}
  //                  >
  //                    Degree Of Urgency
  //                  </Text>
  //                  <Box>
  //                    {_renderChip(
  //                      URGENCY_COLOR[pr?.urgency as keyof typeof URGENCY_COLOR]
  //                        .text,
  //                      URGENCY_COLOR[pr?.urgency as keyof typeof URGENCY_COLOR]
  //                        .background,
  //                      pr?.urgency
  //                    )}
  //                  </Box>
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Status
  //                  </Text>
  //                  {_renderChip(
  //                    PROJECT_STATUS_COLOR[
  //                      pr?.status as keyof typeof PROJECT_STATUS_COLOR
  //                    ].text,
  //                    PROJECT_STATUS_COLOR[
  //                      pr?.status as keyof typeof PROJECT_STATUS_COLOR
  //                    ].background,
  //                    pr?.status
  //                  )}
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Managers
  //                  </Text>
  //                  {!_.isEmpty(managers) ? (
  //                    <AvatarGroupWithOverflow data={managers} />
  //                  ) : (
  //                    "--"
  //                  )}
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Leaders
  //                  </Text>
  //                  {!_.isEmpty(leaders) ? (
  //                    <AvatarGroupWithOverflow data={leaders} />
  //                  ) : (
  //                    "--"
  //                  )}
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    3D Artists
  //                  </Text>
  //                  {!_.isEmpty(artists) ? (
  //                    <AvatarGroupWithOverflow data={artists} />
  //                  ) : (
  //                    "--"
  //                  )}
  //                </Box>
  //                <Box
  //                  sx={{
  //                    display: "flex",
  //                    flexDirection: "column",
  //                    alignItems: "center",
  //                  }}
  //                >
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Tasks
  //                  </Text>
  //                  <TextComponent
  //                    content={taskTitles || "--"}
  //                    sx={{ fontSize: "14px" }}
  //                    maxW={"100%"}
  //                  />
  //                </Box>
  //                <Box>
  //                  <Text fontSize="15px" fontWeight={700}>
  //                    Created At
  //                  </Text>
  //                  <Text sx={{ fontSize: "14px" }}>
  //                    {dayjs(pr?.createdAt.slice(0, 10)).format("DD/MM/YYYY")}
  //                  </Text>
  //                </Box>
  //              </Grid>
  //            </CardBody>
  //          </Card>
  //        );
  //      })}
  //    </Stack>
  //  );

  const _renderDeadline = () => {
    return (
      <Box>
        <DealineDashboardDataTable payload={deline} />
      </Box>
    );
  };

  const _generatePieData = () => {
    const labels = [
      t("label.waitingProcessProjects"),
      t("label.startingProjects"),
      t("label.onGoingProjects"),
      t("label.finalDeliverProjects"),
      t("label.historyProjects"),
    ];
    const backgroundColor = [
      PROJECT_STATUS_COLOR["waiting process"].rgba,
      PROJECT_STATUS_COLOR["starting"].rgba,
      PROJECT_STATUS_COLOR["in process"].rgba,
      PROJECT_STATUS_COLOR["final deliver"].rgba,
      PROJECT_STATUS_COLOR["history"].rgba,
    ];
    return {
      labels,
      datasets: [
        {
          data: _.map(ENUMS.PROJECT_STATUS, (key) => statistical[key]),
          backgroundColor,
          hoverOffset: 6,
        },
      ],
    };
  };

  const totalProjects = () => {
    let total = 0;
    _.forEach(ENUMS.PROJECT_STATUS, (key) => {
      total += statistical[key];
    });
    return total;
  };

  const _renderBottomSection = () => (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={2}
      textAlign={"center"}
    >
      <Box flex={1}>
        <Box>
          <Text fontSize="18px" fontWeight={500} sx={{ mb: 3 }}>
            {t("label.projectsStatistical")}
          </Text>
          {isGetLoading && <CircularProgress size="18px" isIndeterminate />}
        </Box>
        {!isGetLoading && (
          <ChartComponent.DoughnutChart
            title=""
            centerText={`${totalProjects() || 0} ${t("label.projects")}`}
            data={_generatePieData()}
            subtitle={false}
          />
        )}
      </Box>
      <Box flex={2}>
        <Box>
          <Text fontSize="18px" fontWeight={500} sx={{ mb: "10px !important" }}>
            {t("label.processNeedCompletedToday")}
          </Text>
          {isFetchLoading && <CircularProgress size="18px" isIndeterminate />}
        </Box>
        {/*{_renderProjectCards()}*/}
        {_renderDeadline()}
        {_renderPopup()}
      </Box>
    </Stack>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5C6e6c"}>
          {t("title.dashboard")}
        </Heading>
        {_renderStatisticSection()}
        {_renderTopSection()}
        {_renderTimesheetSection(openMyWork)}
        {_renderBottomSection()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Dashboard;
