import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import dayjs from "dayjs";
import _ from "lodash";

const getDaysOfWeek = (date: Date, dateFormat: string) => {
  const daysOfWeek = [];
  const firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(
    date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
  );

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(firstDayOfWeek);
    currentDate.setDate(firstDayOfWeek.getDate() + i);
    daysOfWeek.push(dayjs(currentDate).format(dateFormat));
  }

  return daysOfWeek;
};
const currentDate = new Date();
const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");

const setTimekeepingFetchLoading = () => {
  return {
    type: ActionTypes.SET_TIMEKEEPING_FETCH_LOADING,
  };
};

const setTimekeepingGetLoading = () => {
  return {
    type: ActionTypes.SET_TIMEKEEPING_GET_LOADING,
  };
};

const setTimekeepingActionLoading = () => {
  return {
    type: ActionTypes.SET_TIMEKEEPING_ACTION_LOADING,
  };
};

const resetTimekeepingReducer = () => {
  return {
    type: ActionTypes.RESET_REDUCER_TIMEKEEPING,
  };
};

const resetMesageTimekeepingReducer = () => {
  return {
    type: ActionTypes.RESET_MESSAGE_TIMEKEEPING,
  };
};

const setMetaTimekeeping = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_TIMEKEEPING,
    payload,
  };
};

const setPaginationTimekeeping = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_TIMEKEEPING,
    payload,
  };
};

const fetchTimekeepingSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_TIMEKEEPING_SUCCESS,
    payload,
  };
};

const fetchTimekeepingFail = () => {
  return {
    type: ActionTypes.FETCH_TIMEKEEPING_FAILURE,
  };
};

const fetchTimekeeping = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setTimekeepingFetchLoading());
    await APIs.fetchTimekeeping(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchTimekeepingFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          dispatch(fetchTimekeepingSuccess(items));
          dispatch(setMetaTimekeeping(meta));
          dispatch(setPaginationTimekeeping(payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchTimekeepingFail());
      });
  };
};

const createCheckOutSuccess = () => {
  return {
    type: ActionTypes.CREATE_CHECK_OUT_SUCCESS,
  };
};

const createCheckOutFail = () => {
  return {
    type: ActionTypes.CREATE_CHECK_OUT_FAILURE,
  };
};

const createCheckOut = (payload: any, pagination?: ITimesheetPagination) => {
  return async (dispatch: any) => {
    await APIs.updateTimekeepingAdmin(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createCheckOutFail());
          await Toast({
            title: "check in failed",
            description: message,
            status: "error",
          });
        } else {
          dispatch(createCheckOutSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchTimekeeping(pagination));
          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(createCheckOutFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createTimekeepingSuccess = () => {
  return {
    type: ActionTypes.CREATE_TIMEKEEPING_SUCCESS,
  };
};

const createTimekeepingFail = (payload?: string) => {
  return {
    type: ActionTypes.CREATE_TIMEKEEPING_FAILURE,
    payload,
  };
};

const createTimekeeping = (
  payload: any,
  type?: string,
  pagination?: ITimesheetPagination,
  dataCreateCheckOut?: any
) => {
  return async (dispatch: any) => {
    dispatch(setTimekeepingActionLoading());
    await APIs.createTimekeeping(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const language = Utils.getSavedLanguage();
        if (!result) {
          const message = response?.data?.message;
          await dispatch(createTimekeepingFail(message));
        } else {
          if (result?.type === "check-in") {
            await localStorage.setItem("isCheckedIn", "true");
          }
          if (result?.type === "check-out-afternoon") {
            await localStorage.setItem("isCheckedInAfternoon", "true");
          }
          await dispatch(createTimekeepingSuccess());
          if (dataCreateCheckOut) {
            dispatch(createCheckOut(dataCreateCheckOut, pagination));
          } else if (pagination && type) {
            Toast({
              title: `${
                (language === "en"
                  ? type === "check-in" && "Check in successfully"
                  : type === "check-in" &&
                    "Điểm danh giờ vào làm buổi sáng thành công") ||
                (language === "en"
                  ? type === "check-in-afternoon" &&
                    "Check in afternoon successfully"
                  : type === "check-in-afternoon" &&
                    "Điểm danh giờ vào làm buổi chiều thành công") ||
                (language === "en"
                  ? type === "check-out" && "Check out successfully"
                  : type === "check-out" &&
                    "Điểm danh kết thúc giờ làm buổi sáng thành công") ||
                (language === "en"
                  ? type === "check-out-afternoon" &&
                    "Check out afternoon successfully"
                  : type === "check-out-afternoon" &&
                    "Điểm danh kết thúc giờ làm buổi chiều thành công")
              } `,
              status: "success",
            });
          } else
            Toast({
              title: `${
                (language === "en"
                  ? type === "check-in" && "Check in successfully"
                  : type === "check-in" &&
                    "Điểm danh giờ vào làm buổi sáng thành công") ||
                (language === "en"
                  ? type === "check-in-afternoon" &&
                    "Check in afternoon successfully"
                  : type === "check-in-afternoon" &&
                    "Điểm danh giờ vào làm buổi chiều thành công") ||
                (language === "en"
                  ? type === "check-out" && "Check out successfully"
                  : type === "check-out" &&
                    "Điểm danh kết thúc giờ làm buổi sáng thành công") ||
                (language === "en"
                  ? type === "check-out-afternoon" &&
                    "Check out afternoon successfully"
                  : type === "check-out-afternoon" &&
                    "Điểm danh kết thúc giờ làm buổi chiều thành công")
              } `,
              status: "success",
            });
          await dispatch(fetchTimekeeping(pagination));
          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(createTimekeepingFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdTimekeepingSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_TIMEKEEPING_SUCCESS,
    payload,
  };
};

const getByIdTimekeepingFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_TIMEKEEPING_FAILURE,
  };
};

const getByIdTimekeeping = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setTimekeepingGetLoading());
    await APIs.getTimekeepingById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdTimekeepingFail());
        else {
          dispatch(getByIdTimekeepingSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdTimekeepingFail());
      });
  };
};

const updateCheckOutSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CHECK_OUT_SUCCESS,
    payload,
  };
};

const updateCheckOutFail = () => {
  return {
    type: ActionTypes.UPDATE_CHECK_OUT_FAILURE,
  };
};

const updateCheckOut = (id: string, payload: any, pagination: any) => {
  return async (dispatch: any) => {
    await APIs.updateTimekeeping(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateCheckOutFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateCheckOutSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchTimekeeping(pagination));
          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(updateCheckOutFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateTimekeepingSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_TIMEKEEPING_SUCCESS,
    payload,
  };
};

const updateTimekeepingFail = () => {
  return {
    type: ActionTypes.UPDATE_TIMEKEEPING_FAILURE,
  };
};

const updateTimekeeping = (
  id: string,
  payload: any,
  pagination?: any,
  dataUpdateCheckOut?: any,
  type?: string
) => {
  return async (dispatch: any) => {
    dispatch(setTimekeepingActionLoading());
    await APIs.updateTimekeeping(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateTimekeepingFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          if (!_.isEmpty(dataUpdateCheckOut)) {
            if (type === "update") {
              if (dataUpdateCheckOut?.id) {
                dispatch(
                  updateCheckOut(
                    dataUpdateCheckOut.id,
                    dataUpdateCheckOut,
                    pagination
                  )
                );
              }
            } else {
              dispatch(
                createTimekeeping(
                  { ...dataUpdateCheckOut, type: "check-out" },
                  "",
                  pagination
                )
              );
            }
          } else {
            dispatch(updateTimekeepingSuccess(result));
          }
          await dispatch(fetchTimekeeping(pagination));
          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(updateTimekeepingFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchCheckInSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_CHECK_IN_SUCCESS,
    payload,
  };
};

const fetchCheckInFail = () => {
  return {
    type: ActionTypes.FETCH_CHECK_IN_FAILURE,
  };
};

const fetchCheckIn = (payload: any) => {
  return async (dispatch: any) => {
    //dispatch(setTimekeepingFetchLoading());
    await APIs.fetchTimekeeping(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchCheckInFail());
        else {
          const items = _.get(result, "items");
          dispatch(fetchCheckInSuccess(items));
        }
      })
      .catch(async () => {
        await dispatch(fetchCheckInFail());
      });
  };
};

const createTimekeepingAdminSuccess = () => {
  return {
    type: ActionTypes.CREATE_TIMEKEEPING_ADMIN_SUCCESS,
  };
};

const createTimekeepingAdminFail = () => {
  return {
    type: ActionTypes.CREATE_TIMEKEEPING_ADMIN_FAILURE,
  };
};

const createTimekeepingAdmin = (
  payload: any,
  //  type?: string,
  pagination?: ITimesheetPagination,
  dataCreateCheckOut?: any
) => {
  return async (dispatch: any) => {
    dispatch(setTimekeepingActionLoading());
    await APIs.updateTimekeepingAdmin(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          const message = response?.data?.message;
          await dispatch(createTimekeepingAdminFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createTimekeepingAdminSuccess());
          if (dataCreateCheckOut) {
            dispatch(createCheckOut(dataCreateCheckOut, pagination));
          }
          //   else if (pagination && type) {
          //    Toast({
          //      title: `${type} successfully`,
          //      status: "success",
          //    });
          //  } else
          //    Toast({
          //      title: `${type} successfully`,
          //      status: "success",
          //    });
          await dispatch(fetchTimekeeping(pagination));

          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(createTimekeepingAdminFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  createTimekeeping,
  getByIdTimekeeping,
  updateTimekeeping,
  fetchTimekeeping,
  resetTimekeepingReducer,
  fetchCheckIn,
  createTimekeepingAdmin,
  resetMesageTimekeepingReducer,
};
