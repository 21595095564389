import _ from "lodash";
import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { IChatFilters } from "@/Interfaces/Chat.interface";
import { Toast } from "@/Widgets";

const setChatFetchLoading = () => {
  return {
    type: ActionTypes.SET_CHAT_FETCH_LOADING,
  };
};

const setChatFetchChannelLoading = () => {
  return {
    type: ActionTypes.SET_CHAT_FETCH_CHANNEL_LOADING,
  };
};

const setChatGetLoading = () => {
  return {
    type: ActionTypes.SET_CHAT_GET_LOADING,
  };
};

const setChatActionLoading = () => {
  return {
    type: ActionTypes.SET_CHAT_ACTION_LOADING,
  };
};

const setListMessages = (payload: any) => {
  const resolvedData = Utils.resolveMessages(payload);
  return {
    type: ActionTypes.SET_LIST_MESSAGES,
    payload: resolvedData,
  };
};

const setTypeChat = (payload: string) => {
  return {
    type: ActionTypes.SET_TYPE_CHAT,
    payload,
  };
};

const performAction = (
  actionType: string,
  chatId: string,
  extraData?: any
) => ({
  type: ActionTypes.CHAT_PERFORM_ACTION,
  payload: { actionType, chatId, extraData },
});

const setNewPerformAction = (payload: any) => {
  return {
    type: ActionTypes.SET_NEW_PERFORM_ACTION,
    payload,
  };
};

const setNotification = (payload: any) => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    payload,
  };
};

const resetChatPerformAction = () => {
  return {
    type: ActionTypes.RESET_CHAT_PERFORM_ACTION,
  };
};

const createNewChat = (payload: any) => ({
  type: ActionTypes.CREATE_NEW_CHAT,
  payload,
});

const resetNewChatData = () => ({
  type: ActionTypes.RESET_NEW_CHAT_DATA,
});

const resetChatStatus = () => ({
  type: ActionTypes.RESET_CHAT_STATUS,
});

const resetMediaInChannel = () => {
  return {
    type: ActionTypes.RESET_MEDIA_IN_CHANNEL,
  };
};

const resetChatRecucer = () => {
  return {
    type: ActionTypes.RESET_CHAT_REDUCER,
  };
};

const resetConversationChannelDetail = () => {
  return {
    type: ActionTypes.RESET_CONVERSATION_CHANNEL_DETAIL,
  };
};

const setPotentialChats = (payload: any) => {
  return {
    type: ActionTypes.SET_POTENTIAL_CHATS,
    payload,
  };
};

const setMessageDetailsPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_MESSAGE_DETAILS_PAGINATION,
    payload,
  };
};

const setMessageDetailsMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_MESSAGE_DETAILS_META,
    payload,
  };
};

//ASYNC ACTIONS

const fetchChannelSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MESSAGE_CHANNEL_SUCCESS,
    payload,
  };
};

const fetchChannelFailure = () => {
  return {
    type: ActionTypes.FETCH_MESSAGE_CHANNEL_FAILURE,
  };
};

const fetchMessageChannel = (isLoading: boolean) => {
  return async (dispatch: any) => {
    if (isLoading) dispatch(setChatFetchChannelLoading());
    await APIs.fetchMessageChannel()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchChannelFailure());
          await Toast({ title: message, status: "error" });
        } else {
          await dispatch(fetchChannelSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchChannelFailure());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const fetchMessageNotificationSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MESSAGE_NOTIFICATION_SUCCESS,
    payload,
  };
};

const fetchMessageNotificationFailure = () => {
  return {
    type: ActionTypes.FETCH_MESSAGE_NOTIFICATION_FAILURE,
  };
};

const fetchMessageNotification = () => {
  return async (dispatch: any) => {
    dispatch(setChatFetchLoading());
    await APIs.fetchMessageNotification()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(fetchMessageNotificationFailure());
          await Toast({ title: message, status: "error" });
        } else {
          await dispatch(fetchMessageNotificationSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchChannelFailure());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const fetchConversationChannelDetailSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_CONVERSATION_CHANNEL_DETAIL_SUCCESS,
    payload,
  };
};

const fetchConversationChannelDetailFailure = () => {
  return {
    type: ActionTypes.FETCH_CONVERSATION_CHANNEL_DETAIL_FAILURE,
  };
};

const fetchConversationChannelDetail = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setChatFetchLoading());
    await APIs.fetchConversationChannelDetail(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchConversationChannelDetailFailure());
        } else {
          await dispatch(fetchConversationChannelDetailSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(fetchConversationChannelDetailFailure());
      });
  };
};

const getMessageByChannelIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MESSAGE_BY_CHANNEL_ID_SUCCESS,
    payload,
  };
};

const getMessageByChannelIdFailure = () => {
  return {
    type: ActionTypes.FETCH_MESSAGE_BY_CHANNEL_ID_FAILURE,
  };
};

const getMessageByChannelId = (
  id: string,
  payload: any,
  isLoading?: boolean
) => {
  return async (dispatch: any) => {
    if (isLoading) dispatch(setChatGetLoading());

    try {
      const response: any = await APIs.fetchMessageByChannelId(id, payload);
      const result: any = await Utils.resolveResponse(response as any);
      const message = response?.data?.message;
      if (!result) {
        await dispatch(getMessageByChannelIdFailure());
        await Toast({
          title: message,
          status: "error",
        });
      } else {
        await dispatch(getMessageByChannelIdSuccess(result?.items));
        await dispatch(setMessageDetailsPagination(payload));
        await dispatch(setMessageDetailsMeta(result?.meta));
        return result?.items; // Resolved value of the overall promise
      }
    } catch (error) {
      await dispatch(getMessageByChannelIdFailure());
      return Promise.reject(error); // Propagate the error
    }
  };
};

const fetchAllUsersSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_ALL_USER_FOR_CHAT_SUCCESS,
    payload,
  };
};

const fetchAllUsersFailure = () => {
  return {
    type: ActionTypes.FETCH_ALL_USER_FOR_CHAT_FAILURE,
  };
};

const fetchAllUsers = () => {
  return async (dispatch: any) => {
    dispatch(setChatFetchLoading());
    await APIs.fetchAllUsers()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchAllUsersFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchAllUsersSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchAllUsersFailure());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const fetchUserNotConversationSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_USER_NOT_CONVERSATION_SUCCESS,
    payload,
  };
};

const fetchUserNotConversationFailure = () => {
  return {
    type: ActionTypes.FETCH_USER_NOT_CONVERSATION_FAILURE,
  };
};

const fetchUserNotConversation = () => {
  return async (dispatch: any) => {
    dispatch(setChatFetchLoading());
    await APIs.fetchUserNotConversation()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(fetchUserNotConversationFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchUserNotConversationSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchUserNotConversationFailure());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const createPrivateChatSuccess = () => {
  return {
    type: ActionTypes.CREATE_PRIVATE_CHAT_SUCCESS,
  };
};

const createPrivateChatFailure = () => {
  return {
    type: ActionTypes.CREATE_PRIVATE_CHAT_FAILURE,
  };
};

const createPrivateChat = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.createPrivateChat(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createPrivateChatFailure());
          await dispatch(resetNewChatData());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(createPrivateChatSuccess());
          await dispatch(fetchMessageChannel(false));
          await dispatch(performAction("view", result?.channel?.id, result));
          await dispatch(resetNewChatData());
        }
      })
      .catch(async (error: any) => {
        await dispatch(createPrivateChatFailure());
        await dispatch(resetNewChatData());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const createGroupChatSuccess = () => {
  return {
    type: ActionTypes.CREATE_GROUP_CHAT_SUCCESS,
  };
};

const createGroupChatFailure = () => {
  return {
    type: ActionTypes.CREATE_GROUP_CHAT_FAILURE,
  };
};

const createGroupChat = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.createGroupChat(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(createGroupChatFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(createGroupChatSuccess());
          await dispatch(fetchMessageChannel(false));
          await dispatch(performAction("view", result?.channel?.id, result));
          const channelId = result?.channel?.id;
          if (channelId) {
            const formData = new FormData();
            formData.append("channelId", channelId);
            dispatch(readConversation(channelId, formData, () => {}));
          }
        }
      })
      .catch(async (error: any) => {
        await dispatch(createGroupChatFailure());
        await Toast({ title: error?.message, status: "error" });
      });
  };
};

const createMessageSuccess = () => {
  return {
    type: ActionTypes.CREATE_MESSAGE_CHAT_SUCCESS,
  };
};

const createMessageFailure = () => {
  return {
    type: ActionTypes.CREATE_MESSAGE_CHAT_FAILURE,
  };
};

const createMessage = (
  payload: FormData,
  _channelId: string,
  onSuccess: (data: any) => void,
  onFailure: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.createMessageChat(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(createMessageFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          onSuccess(result);
          await dispatch(createMessageSuccess());
          await dispatch(fetchMessageChannel(false));
        }
      })
      .catch(async (error: any) => {
        onFailure();
        await dispatch(createMessageFailure());
        await Toast({
          title: error?.message || "Create message failure",
          status: "error",
        });
      });
  };
};

const recalledMessageSuccess = () => {
  return {
    type: ActionTypes.RECALLED_MESSAGE_SUCCESS,
  };
};

const recalledMessageFailure = () => {
  return {
    type: ActionTypes.RECALLED_MESSAGE_FAILURE,
  };
};

const recalledMessage = (messsageId: string, onSuccess: () => void) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.recalledMessage(messsageId)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(recalledMessageFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          onSuccess();
          await dispatch(recalledMessageSuccess());
          await dispatch(fetchMessageChannel(false));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(recalledMessageFailure());
        await Toast({
          title: error?.message || "Recall message failure",
          status: "error",
        });
      });
  };
};

const deletePrivateMessageSuccess = () => {
  return {
    type: ActionTypes.DELETE_PRIVATE_MESSAGE_SUCCESS,
  };
};

const deletePrivateMessageFailure = () => {
  return {
    type: ActionTypes.DELETE_PRIVATE_MESSAGE_FAILURE,
  };
};

const deletePrivateMessage = (channelId: string, nextChannel: any) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.deletePrimaryMessage(channelId)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(deletePrivateMessageFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(deletePrivateMessageSuccess());
          await dispatch(fetchMessageChannel(false));
          if (!_.isEmpty(nextChannel)) {
            await dispatch(
              performAction("view", nextChannel?.channel?.id, nextChannel)
            );
          }

          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(deletePrivateMessageFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchMediaByChannelIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MEDIA_BY_CHANNEL_ID_SUCCESS,
    payload,
  };
};

const fetchMediaByChannelIdFailure = () => {
  return {
    type: ActionTypes.FETCH_MEDIA_BY_CHANNEL_ID_FAILURE,
  };
};

const fetchMediaByChannelId = (channelId: string, pagination: IChatFilters) => {
  return async (dispatch: any) => {
    dispatch(setChatFetchLoading());
    await APIs.fetchMediaByChannelId(channelId, pagination)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(fetchMediaByChannelIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(fetchMediaByChannelIdSuccess(result?.items));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchMediaByChannelIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const addMemberToGroupSuccess = () => {
  return {
    type: ActionTypes.ADD_MEMBER_TO_GROUP_SUCCESS,
  };
};

const addMemberToGroupFailure = () => {
  return {
    type: ActionTypes.ADD_MEMBER_TO_GROUP_FAILURE,
  };
};

const addMemberToGroup = (
  channelId: string,
  payload: FormData,
  pagination: IChatFilters
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.addMemberToGroup(channelId, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(addMemberToGroupFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(addMemberToGroupSuccess());
          await dispatch(fetchMessageChannel(false));
          await dispatch(getMessageByChannelId(channelId, pagination));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(addMemberToGroupFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const removeMemberToGroupSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MEMBER_TO_GROUP_SUCCESS,
  };
};

const removeMemberToGroupFailure = () => {
  return {
    type: ActionTypes.REMOVE_MEMBER_TO_GROUP_FAILURE,
  };
};

const removeMemberToGroup = (
  channelId: string,
  payload: FormData,
  pagination: IChatFilters
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.removeMemberToGroup(channelId, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(removeMemberToGroupFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(removeMemberToGroupSuccess());
          await dispatch(fetchMessageChannel(false));
          await dispatch(getMessageByChannelId(channelId, pagination));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(removeMemberToGroupFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const leaveGroupSuccess = () => {
  return {
    type: ActionTypes.LEAVE_GROUP_CHAT_SUCCESS,
  };
};

const leaveGroupFailure = () => {
  return {
    type: ActionTypes.LEAVE_GROUP_CHAT_FAILURE,
  };
};

const leaveGroup = (channelId: string, nextChannel: any) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.leaveGroup(channelId)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(leaveGroupFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(leaveGroupSuccess());
          await dispatch(fetchMessageChannel(false));
          if (!_.isEmpty(nextChannel)) {
            await dispatch(
              performAction("view", nextChannel?.channel?.id, nextChannel)
            );
          } else {
            await dispatch(resetChatPerformAction());
          }
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(leaveGroupFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const readConversationSuccess = () => {
  return {
    type: ActionTypes.READ_CONVERSATION_SUCCESS,
  };
};

const readConversationFailure = () => {
  return {
    type: ActionTypes.READ_CONVERSATION_FAILURE,
  };
};

const readConversation = (
  channelId: string,
  payload: FormData,
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.readConversation(channelId, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(readConversationFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          onSuccess();
          await dispatch(readConversationSuccess());
          // await dispatch(fetchMessageNotification());
        }
      })
      .catch(async (error: any) => {
        await dispatch(readConversationFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateConversationSuccess = () => {
  return {
    type: ActionTypes.UPDATE_CONVERSATION_SUCCESS,
  };
};

const updateConversationFailure = () => {
  return {
    type: ActionTypes.UPDATE_CONVERSATION_FAILURE,
  };
};

const updateConversation = (
  channelId: string,
  payload: FormData,
  chatPerformAction: any,
  onSuccess?: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.updateConversation(channelId, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(updateConversationFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(updateConversationSuccess());
          await dispatch(fetchConversationChannelDetail(channelId));
          await dispatch(
            setNewPerformAction({
              ...chatPerformAction,
              extraData: {
                ...chatPerformAction?.extraData,
                channel: result,
              },
            })
          );
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess && onSuccess();
        }
      })
      .catch(async (error: any) => {
        await dispatch(updateConversationFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const removeAllFileSuccess = () => {
  return {
    type: ActionTypes.REMOVE_ALL_FILE_IN_CHAT_SUCCESS,
  };
};

const removeAllFileFailure = () => {
  return {
    type: ActionTypes.REMOVE_ALL_FILE_IN_CHAT_FAILURE,
  };
};

const removeAllFile = (
  data: { time: number; type: string },
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setChatActionLoading());
    await APIs.removeAllFile(data)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(removeAllFileFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(removeAllFileSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          onSuccess();
        }
      })
      .catch(async (error: any) => {
        await dispatch(removeAllFileFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  performAction,
  resetChatPerformAction,
  createNewChat,
  resetNewChatData,
  resetChatRecucer,
  resetChatStatus,
  setPotentialChats,
  fetchMessageChannel,
  fetchMessageNotification,
  getMessageByChannelId,
  fetchUserNotConversation,
  fetchAllUsers,
  createPrivateChat,
  createGroupChat,
  createMessage,
  recalledMessage,
  deletePrivateMessage,
  fetchMediaByChannelId,
  resetMediaInChannel,
  addMemberToGroup,
  removeMemberToGroup,
  leaveGroup,
  readConversation,
  updateConversation,
  fetchChannelSuccess,
  setListMessages,
  setMessageDetailsPagination,
  setMessageDetailsMeta,
  fetchConversationChannelDetail,
  resetConversationChannelDetail,
  setNewPerformAction,
  setNotification,
  setTypeChat,
  removeAllFile,
  fetchMessageNotificationSuccess,
};
