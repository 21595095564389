import BackupRestore from "./Backup";
import InternalIP from "./InternalIP";
import FileSystem from "./FileSystem";
import Workspace from "./Workspace";
import workspaceIframe from "./Workspace/workSpaceIframe";

export default {
  InternalIP,
  BackupRestore,
  FileSystem,
  Workspace,
  workspaceIframe,
};
