import { request } from "@/Configs";
import { APIs } from "@/Constants";
import {
  ICreateBoard,
  ICreateColumnInBoard,
  IFilterBoard,
  IUpdateColumnInBoard,
} from "@/Interfaces/Board.interface";

const { KANBAN_BOARD, TASK } = APIs;

export const fetchBoards = async (payload: IFilterBoard) => {
  return request(KANBAN_BOARD.BASIC, "GET", payload);
};

export const createBoard = async (payload: ICreateBoard) => {
  return request(KANBAN_BOARD.BASIC, "POST", payload);
};

export const getBoardById = async (id: string) => {
  return request(`${KANBAN_BOARD.GET_BY_ID}/${id}`, "GET");
};

export const getTimelineInBoard = async (id: string, payload: any) => {
  return request(`${KANBAN_BOARD.GET_TIMELINE}/${id}`, "GET", payload);
};

export const createColumnInBoard = async (payload: ICreateColumnInBoard) => {
  return request(TASK.BASIC, "POST", payload);
};

export const getColumnInBoard = async (id: string) => {
  return request(`${TASK.GET_BY_ID}/${id}`, "GET");
};

export const updateColumnInBoard = async (
  id: string,
  payload: IUpdateColumnInBoard | FormData
) => {
  return request(`${TASK.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const createCommentInColumn = async (id: string, payload: FormData) => {
  return request(`${TASK.COMMENT}/${id}`, "PUT_FORM_DATA", payload);
};

export const deleteCommentInColumn = async (id: string) => {
  return request(`${TASK.DELETE_COMMENT}/${id}`, "PUT");
};

export const deleteColumnInBoard = async (id: string) => {
  return request(`${TASK.DELETE_COLUMN}/${id}`, "PUT");
};
