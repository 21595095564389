import React, { useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  Box,
  Select,
  Heading,
  Text,
  Stack,
} from "@chakra-ui/react";
import { CalendarDaysIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import * as yup from "yup";
import { DayOffRequestActions, HolidayActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";
import { useWorkingDay } from "@/Helpers";
import { IWorkingDayStructure } from "@/Interfaces/Workingday.interface";

const { createDayOffRequest } = DayOffRequestActions;
const { fetchHoliday, resetHolidayReducer } = HolidayActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_FORM_DATA = {
  startDate: "",
  startDateType: "am",
  endDate: "",
  endDateType: "am",
  reason: "",
};

const SendDateRequestDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const workingDays: IWorkingDayStructure = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );

  const [duration, setDuration] = React.useState<any>(0);
  const [isMessage, setIsMessage] = React.useState<string>("");
  const [isClicked, setIsClicked] = React.useState(false);
  const [countHolidays, setCountHolidays] = React.useState<any>(0);

  const isCreateDayOffRequestSuccess: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "isCreateDayOffRequestSuccess")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      startDate: yup
        .string()
        .trim()
        .required(t("message.startTimeOffDateIsRequired")),
      endDate: yup
        .string()
        .trim()
        .required(t("message.endTimeOffDateIsRequired")),
      reason: yup.string().trim().required(t("message.reasonIsRequired")),
      startDateType: yup.string().trim(),
      endDateType: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    //setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });
  const startDate: any = watch("startDate");
  const startDateType: any = watch("startDateType");
  const endDate: any = watch("endDate");
  const endDateType: any = watch("endDateType");

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const onDateChangeRequest = (
    dateStart: any,
    endDate: any,
    startDateType: string,
    endDateType: string
  ) => {
    const holidays = _.map(holidaysList, (item) => item.day);
    const formatWorkingDays: IWorkingDayStructure = workingDays || {
      mondayMorning: false,
      mondayAfternoon: false,
      tuesdayMorning: false,
      tuesdayAfternoon: false,
      wednesdayMorning: false,
      wednesdayAfternoon: false,
      thursdayMorning: false,
      thursdayAfternoon: false,
      fridayMorning: false,
      fridayAfternoon: false,
      saturdayMorning: false,
      saturdayAfternoon: false,
      sundayMorning: false,
      sundayAfternoon: false,
    };

    const duration = useWorkingDay.calculateDuration(
      dateStart,
      endDate,
      startDateType,
      endDateType,
      formatWorkingDays,
      holidays
    );

    setDuration(duration);

    const countHolidays = (
      startDate: string,
      endDate: string,
      holidays: any[]
    ) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      let holidayCount = 0;

      let tempDate = new Date(start);
      while (tempDate <= new Date(end)) {
        const currentDate = dayjs(tempDate).format("YYYY-MM-DD");

        if (holidays.some((holiday) => holiday.day === currentDate)) {
          holidayCount++;
        }
        tempDate.setDate(tempDate.getDate() + 1);
      }

      return holidayCount;
    };

    const holidayCount = countHolidays(startDate, endDate, holidaysList);
    setCountHolidays(holidayCount);
  };

  const onSubmit = _.throttle((data: any) => {
    if (!isClicked) {
      if (duration <= 0) {
        setIsMessage(t("message.leaveDateInvalid"));
        return;
      }
      const dataWorkingDay = useWorkingDay.convertSchedule(workingDays);
      dispatch(
        createDayOffRequest({
          startDate: dayjs(data.startDate).isValid()
            ? dayjs(data.startDate).format("YYYY-MM-DD")
            : "",
          endDate: dayjs(data.endDate).isValid()
            ? dayjs(data.endDate).format("YYYY-MM-DD")
            : "",
          startDateType: data.startDateType,
          endDateType: data.endDateType,
          reason: data.reason,
          duration,
          durationAdd: countHolidays,
          dataWorkingDay,
        })
      );
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  }, 1000);

  React.useEffect(() => {
    if (isCreateDayOffRequestSuccess) {
      onClose();
      reset();
      setDuration(0);
      setCountHolidays(0);
    }
  }, [isCreateDayOffRequestSuccess]);

  React.useEffect(() => {
    if (!open) {
      reset();
      setIsMessage("");
      setDuration(0);
      setCountHolidays(0);
    }
  }, [open]);

  React.useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  const _renderContent = () => {
    const startDateNew = dayjs(startDate).format("YYYY");
    const maxEndDate = `${_.toNumber(startDateNew)}-12-31`;
    const endDateNew = dayjs(endDate).format("YYYY");
    const minStartDate = `${_.toNumber(endDateNew)}-01-01`;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Heading
                fontSize="sm"
                sx={{
                  mb: 2,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
                {t("label.startTimeOffDate")}
                <Text color="error" ml={1} size="sm">
                  *
                </Text>
              </Heading>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Box>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <Stack mb={5}>
                        <DatePicker
                          maxDate={endDate && new Date(endDate)}
                          minDate={new Date(minStartDate)}
                          value={startDate}
                          onDateChange={(e: any) => {
                            field.onChange(e);
                            setIsMessage("");
                            onDateChangeRequest(
                              e,
                              endDate,
                              startDateType,
                              endDateType
                            );
                          }}
                          isError={!_.isEmpty(errors.startDate?.message)}
                          //  errorMessage={errors.startDate?.message}
                          //label="Birthday"
                          size="sm"
                          disabledTitle
                        />
                      </Stack>
                    )}
                  />
                </Box>
                <Controller
                  name="startDateType"
                  control={control}
                  render={({ field }) => (
                    <Stack mb={5}>
                      <Select
                        value={field.value}
                        placeholder=""
                        size={"sm"}
                        onChange={(e: any) => {
                          field.onChange(e.target.value);
                          setIsMessage("");
                          onDateChangeRequest(
                            startDate,
                            endDate,
                            e.target.value,
                            endDateType
                          );
                        }}
                        sx={{ borderRadius: "8px" }}
                        //disabled={startDate && startDate.getDay() === 6}
                      >
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </Select>
                    </Stack>
                  )}
                />
              </Box>
              {!_.isEmpty(errors.startDate?.message) && (
                <Text fontSize="sm" sx={{ color: "error" }}>
                  {errors.startDate?.message}
                </Text>
              )}
            </Box>
            <Box>
              <Box>
                <Heading
                  fontSize="sm"
                  sx={{
                    mb: 2,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
                  {t("label.endTimeOffDate")}
                  <Text color="error" ml={1} size="sm">
                    *
                  </Text>
                </Heading>
              </Box>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Box>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <Stack mb={5}>
                        <DatePicker
                          minDate={startDate && new Date(startDate)}
                          maxDate={new Date(maxEndDate)}
                          value={field.value}
                          onDateChange={(e: any) => {
                            field.onChange(e);
                            setIsMessage("");
                            onDateChangeRequest(
                              startDate,
                              e,
                              startDateType,
                              endDateType
                            );
                          }}
                          isError={!_.isEmpty(errors.endDate?.message)}
                          //  errorMessage={errors.endDate?.message}
                          //label="Birthday"
                          size="sm"
                          disabledTitle
                        />
                      </Stack>
                    )}
                  />
                </Box>
                <Controller
                  name="endDateType"
                  control={control}
                  render={({ field }) => (
                    <Stack mb={5}>
                      <Select
                        size={"sm"}
                        placeholder=""
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e.target.value);
                          setIsMessage("");
                          onDateChangeRequest(
                            startDate,
                            endDate,
                            startDateType,
                            e.target.value
                          );
                        }}
                        sx={{ borderRadius: "8px" }}
                        //disabled={endDate && endDate.getDay() === 6}
                      >
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </Select>
                    </Stack>
                  )}
                />
              </Box>
              {!_.isEmpty(errors.endDate?.message) && (
                <Text fontSize="sm" sx={{ color: "error" }}>
                  {errors.endDate?.message}
                </Text>
              )}
            </Box>
          </Box>
          {!_.isEmpty(isMessage) && (
            <Text fontSize="sm" sx={{ color: "error" }}>
              {isMessage}
            </Text>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Heading
            fontSize="sm"
            sx={{
              mb: 2,
              alignItems: "center",
            }}
          >
            {t("label.duration")}:
          </Heading>
          <Text sx={{ fontSize: 15, fontWeight: 700, ml: 2 }}>{duration}</Text>
        </Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Icon as={NewspaperIcon} boxSize={3} mr={1} />
          {t("label.timeOffReason")}
          <Text color="error" ml={1} size="sm">
            *
          </Text>
        </Heading>
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Stack mb={5}>
              <TextareaComponent
                size={"sm"}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                  setIsMessage("");
                }}
                isError={!_.isEmpty(errors.reason?.message)}
                errorMessage={errors.reason?.message}
              />
            </Stack>
          )}
        />
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.sendDateOffRequest")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 10px 10px ",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
              reset();
              setDuration(0);
              setCountHolidays(0);
            }}
            size={"sm"}
            disabled={isActionLoading}
            w={70}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            size={"sm"}
            isLoading={isActionLoading}
            w={70}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.send")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SendDateRequestDialog;
