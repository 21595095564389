import TimeOffRequest from "./DayOffRequest/index";
//import OverTime from "./Overtime";
import Timesheet from "./Timesheet";
import LeaveDays from "./LeaveDays";
import CheckinHistory from "./TimeKeeping/index";
import RequestUpdateTimeCard from "./RequestUpdateTimeCard";
import OverTime from "./OvertimeRequest/index";

export default {
  TimeOffRequest,
  OverTime,
  Timesheet,
  LeaveDays,
  CheckinHistory,
  RequestUpdateTimeCard,
};
