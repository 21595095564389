import React, { useState, useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  Stack,
  Heading,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  HStack,
  Icon,
  List,
  ListItem,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";

import { ProjectActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";
import Utils from "@/Utils";

import Board from "./Board";
import { IColumnStructure, ICardStructure } from "@/Interfaces/Board.interface";
import { PROJECT_STATUS } from "@/Constants/Enums.contant";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FunnelIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { getSocket } from "@/Configs/socket.config";
import { IProjectStructure } from "@/Interfaces/Project.interface";
import { RoundedContainer, DateRangePicker } from "@/Components/Common";
import CommonColors from "@/Themes/CommonColors";
import { useTranslation } from "react-multi-lang";
import "dayjs/locale/vi";

const { fetchProjects, resetProjectReducer } = ProjectActions;

const thisMonthStartDate = dayjs().startOf("month").toDate();
const thisMonthEndDate = dayjs().endOf("month").toDate();
const threeMonthsStartDate = dayjs()
  .subtract(2, "month")
  .startOf("month")
  .toDate();
const threeMonthsEndDate = dayjs().endOf("month").toDate();
const sixMonthsStartDate = dayjs()
  .subtract(5, "month")
  .startOf("month")
  .toDate();
const sixMonthsEndDate = dayjs().endOf("month").toDate();
const thisYearStartDate = dayjs().startOf("year").toDate();
const thisYearEndDate = dayjs().endOf("year").toDate();

const selectionRanges = [
  {
    label: "thisMonth",
    startDate: thisMonthStartDate,
    endDate: thisMonthEndDate,
  },
  {
    label: "3Months",
    startDate: threeMonthsStartDate,
    endDate: threeMonthsEndDate,
  },
  {
    label: "6Months",
    startDate: sixMonthsStartDate,
    endDate: sixMonthsEndDate,
  },
  {
    label: "thisYear",
    startDate: thisYearStartDate,
    endDate: thisYearEndDate,
  },
];

const BoardOverview: React.FC = () => {
  const socket = getSocket();
  const dispatch = useTypedDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const currentLocale = dayjs.locale();

  const projects = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );
  const defaultTimeline = Utils.generateDaysInCurrentMonth();
  const [currentPayload, setCurrentPayload] = useState<IProjectStructure[]>([]);

  const [activeDates, setActiveDates] = useState<string[]>(
    Utils.generateDaysInCurrentMonth()
  );

  const [currentDateRange, setCurrentDateRange] = useState<any[]>([]);
  const [isShowFilter, setIsShowFilter] = useState(false);

  useEffect(() => {
    if (_.first(defaultTimeline) && _.last(defaultTimeline))
      filterTimeline(
        _.first(defaultTimeline) || "",
        _.last(defaultTimeline) || ""
      );
    return () => {
      dispatch(resetProjectReducer());
      setActiveDates(Utils.generateDaysInCurrentMonth());
    };
  }, []);

  useEffect(() => {
    socket.on("changeStatusProject", (data: IProjectStructure) => {
      if (data) updateProjectInPayload(data);
    });

    return () => {
      socket.off("changeStatusProject");
      setCurrentPayload([]);
      setActiveDates(Utils.generateDaysInCurrentMonth());
      setCurrentDateRange([_.first(defaultTimeline), _.last(defaultTimeline)]);
    };
  }, []);

  useEffect(() => {
    setCurrentPayload(projects);
  }, [projects]);

  useEffect(() => {
    if (!_.isEmpty(activeDates))
      setCurrentDateRange([
        _.first(activeDates) || "",
        _.last(activeDates) || "",
      ]);
  }, [activeDates]);

  const resolvedData = React.useMemo(() => {
    // Initialize columns with empty arrays
    const columns = _.map(PROJECT_STATUS, (status, key) => ({
      id: `column-${key}`,
      name: _.startCase(_.toLower(status)),
      desc: "",
      cardOrderIds: [] as string[], // Explicitly specify the type here
      status,
      cards: [] as ICardStructure[], // Explicitly specify the type here
    }));

    if (!_.isEmpty(currentPayload)) {
      // Loop through each project and organize them into columns
      _.forEach(currentPayload, (project: any) => {
        const columnIndex = columns.findIndex(
          (col) => col.status === project.status
        );

        if (columnIndex !== -1) {
          const column: IColumnStructure = columns[columnIndex];

          const projectCard: ICardStructure = {
            id: project.id,
            name: project.name,
            desc: project.description || "",
            status: project.status,
            columnId: column.id,
            boardId: project?.kanbanBoard?.id,
            extendProps: {
              urgency: project?.urgency,
              type: project?.type,
              userCreatedId: project?.userCreated?.id,
            },
          };

          column?.cards?.push(projectCard);
          column?.cardOrderIds?.push(project.id);
        }
      });
      // Add a placeholderCard for each column without any cards
      _.forEach(columns, (column) => {
        if (_.isEmpty(column?.cards)) {
          const placeholderCard: ICardStructure = {
            id: `placeholder-card-${column.id}`,
            columnId: column?.id,
            placeholderCard: true,
            name: "",
            desc: "",
          };

          column?.cards?.push(placeholderCard);
          column?.cardOrderIds?.push(placeholderCard.id);
        }
      });
    }
    return columns;
  }, [currentPayload]);

  const formattedDateRange = React.useMemo(() => {
    const startDate = dayjs(_.first(activeDates));
    const endDate = dayjs(_.last(activeDates));

    const isValidStartDate = dayjs(startDate).isValid();
    const isValidEndDate = endDate.isValid();

    const formattedStartDate = isValidStartDate
      ? startDate.format("MMM D")
      : "";
    const formattedEndDate = isValidEndDate
      ? endDate.format("MMM D, YYYY")
      : "";

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [activeDates, currentLocale]);

  const isValueInRange = (
    value: Date[] | string[] | any,
    range: Date[] | string[] | any
  ) => {
    const [start, end] = value;
    return (
      dayjs(start).format("YYYY-MM-DD") ===
        dayjs(range.startDate).format("YYYY-MM-DD") &&
      dayjs(end).format("YYYY-MM-DD") ===
        dayjs(range.endDate).format("YYYY-MM-DD")
    );
  };

  const getDatesInRange = (
    startDate: string | Date,
    endDate: string | Date
  ): string[] => {
    const datesArray: string[] = [];
    let currentDate = dayjs(startDate);
    const lastDate = dayjs(endDate);

    while (
      currentDate.isSame(lastDate, "day") ||
      currentDate.isBefore(lastDate, "day")
    ) {
      const formattedDate = currentDate.format("YYYY-MM-DD");
      datesArray.push(formattedDate);
      currentDate = currentDate.add(1, "day");
    }
    return datesArray;
  };

  const filterTimeline = (startDate: string, endDate: string) => {
    dispatch(fetchProjects({ startDate, endDate, page: 0, limit: 0 }));
  };

  const updateProjectInPayload = (updatedProject: IProjectStructure) => {
    setCurrentPayload((prevPayload) => {
      const updatedPayload = prevPayload.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      );
      return updatedPayload;
    });
  };

  const getActiveDates = () => {
    const today = dayjs().startOf("month");
    const firstDayOfMonth = today.startOf("month");
    const lastDayOfMonth = today.endOf("month");
    const daysInMonth = lastDayOfMonth.date();

    const monthDates = Array(daysInMonth)
      .fill(0)
      .reduce((acc, _, index) => {
        const nextDay = firstDayOfMonth.add(index, "day");
        return [...acc, nextDay.format("YYYY-MM-DD")];
      }, []);

    setActiveDates(monthDates);
    filterTimeline(_.first(monthDates) || "", _.last(monthDates) || "");
  };

  const handlePrevClick = () => {
    const firstDayOfCurrentMonth = dayjs(activeDates[0]).startOf("month");
    const firstDayOfPreviousMonth = firstDayOfCurrentMonth.subtract(1, "month");
    const daysInPreviousMonth = firstDayOfPreviousMonth.daysInMonth();
    const previousMonthDates = Array(daysInPreviousMonth)
      .fill(0)
      .reduce((acc, _, index) => {
        const day = firstDayOfPreviousMonth.add(index, "day");
        return [...acc, day.format("YYYY-MM-DD")];
      }, []);
    setActiveDates(previousMonthDates);
    filterTimeline(
      _.first(previousMonthDates) || "",
      _.last(previousMonthDates) || ""
    );
  };

  const handleNextClick = () => {
    // Get the last date of the current month
    const lastDayOfMonth = dayjs(_.last(activeDates)).endOf("month");
    // Calculate the start date of the next month
    const nextMonthStart = lastDayOfMonth.add(1, "day"); // Add 1 day to move to the next month
    // Get the number of days in the next month
    const daysInNextMonth = nextMonthStart.daysInMonth();
    const nextMonthDates = Array(daysInNextMonth)
      .fill(0)
      .reduce((acc, _, index) => {
        const nextDay = nextMonthStart.add(index, "day");
        return [...acc, nextDay.format("YYYY-MM-DD")];
      }, []);
    setActiveDates(nextMonthDates);
    filterTimeline(_.first(nextMonthDates) || "", _.last(nextMonthDates) || "");
  };

  const handleFilterTimelineChange = () => {
    const generateDateRanges = getDatesInRange(
      currentDateRange[0],
      currentDateRange[1]
    );
    setActiveDates(generateDateRanges);
    if (_.first(currentDateRange) && _.last(currentDateRange))
      filterTimeline(
        _.first(currentDateRange) || "",
        _.last(currentDateRange) || ""
      );
    setIsShowFilter(false);
  };

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  const _renderFilterPopper = () => {
    return (
      <Popover
        isOpen={isShowFilter}
        onClose={() => setIsShowFilter(false)}
        // isLazy
      >
        <PopoverTrigger>
          <Button size="sm" bg="none" onClick={() => setIsShowFilter(true)}>
            <Heading size="sm">{formattedDateRange}</Heading>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          sx={{
            width: "max-content",
          }}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader
            sx={{ textAlign: "center", fontSize: "md", fontWeight: 500 }}
          >
            {t("label.selectTimeRange")}
          </PopoverHeader>
          <PopoverBody
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: "max-content",
                mx: "auto",
              }}
            >
              <DateRangePicker
                value={currentDateRange}
                onDateChange={(newValue) => setCurrentDateRange(newValue)}
              />
            </Box>

            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                gap: 3,
              }}
            >
              {_.map(selectionRanges, (range, index) => (
                <ListItem key={index}>
                  <Button
                    size="xs"
                    onClick={() =>
                      setCurrentDateRange([range.startDate, range.endDate])
                    }
                    variant={
                      isValueInRange(currentDateRange, range)
                        ? "solid"
                        : "outline"
                    }
                    sx={{
                      background: isValueInRange(currentDateRange, range)
                        ? CommonColors.warmCopper
                        : "",
                      "&:hover": {
                        color: "#fff",
                        background: CommonColors.dustyCoral,
                      },
                      color: isValueInRange(currentDateRange, range)
                        ? "white"
                        : "inherit",
                    }}
                  >
                    {t(`label.${range.label}`)}
                  </Button>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
          <PopoverFooter
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setIsShowFilter(false)}
            >
              {t("button.close")}
            </Button>
            <HStack spacing={3}>
              <Box gap={2} sx={{ display: "flex" }}>
                <Box>
                  <Button
                    size="sm"
                    leftIcon={<Icon as={ArrowPathIcon} />}
                    sx={{
                      color: "#fff",
                      background: "#bb7154",
                      "&:hover": {
                        background: "#Db9d97",
                      },
                    }}
                    onClick={() => {
                      const defaultTimeline =
                        Utils.generateDaysInCurrentMonth();
                      if (_.first(defaultTimeline) && _.last(defaultTimeline))
                        filterTimeline(
                          _.first(defaultTimeline) || "",
                          _.last(defaultTimeline) || ""
                        );
                      setActiveDates(defaultTimeline);
                      setIsShowFilter(false);
                    }}
                  >
                    {t("button.reset")}
                  </Button>
                </Box>
              </Box>
              <Box>
                <Button
                  size="sm"
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                  onClick={handleFilterTimelineChange}
                >
                  {t("button.filter")}
                </Button>
              </Box>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    );
  };

  const _renderTopSection = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Button
          size="sm"
          isDisabled={
            _.isEqual(activeDates, Utils.generateDefaultWeekDay()) ||
            _.isEqual(activeDates, Utils.generateDaysInCurrentMonth())
          }
          onClick={getActiveDates}
        >
          {t("button.today")}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          ml: isMobile ? "auto" : 0,
        }}
      >
        <IconButton
          isRound
          size="sm"
          background="transparent"
          aria-label={""}
          onClick={handlePrevClick}
        >
          <ChevronLeftIcon boxSize={5} />
        </IconButton>
        {_renderFilterPopper()}
        <IconButton
          isRound
          size="sm"
          background="transparent"
          aria-label={""}
          onClick={handleNextClick}
        >
          <ChevronRightIcon boxSize={5} />
        </IconButton>
      </Box>
      <Box
        sx={{
          opacity: 0,
        }}
      />
    </Box>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading
            size="md"
            textAlign={!isMobile ? "left" : "center"}
            color={"#5C6e6c"}
          >
            {t("title.boardOverview")}
          </Heading>
        </Box>
        <RoundedContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {_renderTopSection()}
          <Board data={resolvedData} />
        </RoundedContainer>
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default BoardOverview;
