import React from "react";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";

import { Text, Box, VStack, Stack, Icon } from "@chakra-ui/react";

import { PencilIcon } from "@heroicons/react/24/outline";

interface IProps {
  label?: string;
  onFileChange(newFiles: File[]): void;
  files: File[];
  message?: string;
  accept?: string;
  direction?: "row" | "column";
  containerSx?: object;
  labelSx?: object;
  required?: boolean;
  preview?: string;
  isOnlyRead?: boolean;
  previewUrl?: string;
}

const ALLOWED_IMAGE = ["image/jpg", "image/jpeg", "image/png"];

const DEFAULT_PREVIEW =
  "https://cdn-icons-png.flaticon.com/512/3135/3135715.png";

const UploadAvatar: React.FC<IProps> = ({
  onFileChange,
  message = "",
  files = [],
  accept = ".png,.jpg,.jpeg",
  direction = "column",
  containerSx,
  isOnlyRead = false,
  preview = "",
  previewUrl = DEFAULT_PREVIEW,
}) => {
  const [validateMessage, setValidateMessage] = React.useState<string>("");
  const [previewImage, setPreviewImage] = React.useState<string>(preview);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (previewUrl) setPreviewImage(previewUrl);
  }, [previewUrl]);

  React.useEffect(() => {
    if (isEmpty(files)) {
      if (inputRef && inputRef.current) inputRef.current.value = "";
    } else {
      const imageUrl = URL.createObjectURL(files[0]);
      setPreviewImage(imageUrl);
    }
  }, [files]);

  React.useEffect(() => {
    setValidateMessage(message);
  }, [message]);

  const onChangeFile = (e: any) => {
    if (e) {
      setValidateMessage("");
      const targetFiles = e.target.files;
      if (targetFiles.length !== 0) {
        const resolveFile: File = targetFiles[0];
        let isValid = true;
        if (resolveFile.size > 5000000) {
          setValidateMessage("Image large than 5MB");
          isValid = false;
        }
        if (!includes(ALLOWED_IMAGE, resolveFile.type)) {
          setValidateMessage("Image is wrong type");
          isValid = false;
        }
        if (isValid) onFileChange([resolveFile]);
        else onFileChange([]);
      }
    }
  };

  return (
    <Stack>
      <Box
        sx={{
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: direction,
          ...containerSx,
        }}
      >
        <Box
          onClick={() => {
            if (inputRef.current && !isOnlyRead) inputRef.current.click();
          }}
          sx={{
            position: "relative",
            boxShadow: "0 7px 25px rgba(0,0,0,0.08)",
            boxSizing: "border-box",
            transition: "all ease 0.25s",
            overflow: "hidden",
            borderRadius: "50%",
            width: "80px",
            height: "80px",
            maxHeight: "250px",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: !isEmpty(message) ? "error.main" : "#ccc",
            "&:hover": {
              borderColor: !isEmpty(message)
                ? "error.main"
                : !isOnlyRead
                ? "rgba(0, 0, 0, 0.87)"
                : "none",
              "& .image-buttons": {
                opacity: !isOnlyRead ? 1 : 0,
              },
            },
          }}
        >
          <img
            src={previewImage ? previewImage : DEFAULT_PREVIEW}
            style={{
              objectFit: "cover",
              maxWidth: "100%",
              width: "90px",
              height: "90px",
              borderRadius: "4px",
            }}
          />

          <VStack
            className="image-buttons"
            sx={{
              opacity: 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.5)",
              transition: "all ease 0.25s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isOnlyRead && (
              <Icon as={PencilIcon} sx={{ fontSize: "24px", color: "#fff" }} />
            )}
            <input
              type="file"
              accept={accept}
              ref={inputRef}
              onChange={(e: any) => onChangeFile(e)}
              style={{
                display: "none",
                width: "100%",
                height: "100%",
                backgroundColor: "red",
              }}
            />
          </VStack>
        </Box>
      </Box>
      {validateMessage && (
        <Text fontSize="13px" color="error">
          *{validateMessage}
        </Text>
      )}
    </Stack>
  );
};

export default UploadAvatar;
