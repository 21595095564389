import { useEffect, useMemo, useState } from "react";

import { AddIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  HStack,
  InputGroup,
} from "@chakra-ui/react";
import _ from "lodash";

import { useSelector } from "react-redux";
import { AdminLayout } from "@/Components/DefaultLayout";
import DataTable from "@/Components/LayoutPart/DataTable";
import {
  IFilterHoliday,
  IHolidayStructure,
} from "@/Interfaces/Holiday.interface";
import { RootState, useTypedDispatch } from "@/Store";
import { TActions, TStatus } from "@/Types/Common.types";
import Utils from "@/Utils";
import { HolidayActions } from "@/Actions";
import { ConfirmDialog, HolidaysDialog } from "@/Components/Popup";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { RoundedContainer, Select } from "@/Components/Common";
import { Routers } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const { fetchHoliday, resetHolidayReducer, changeStatusHoliday } =
  HolidayActions;

const DEFAULT_FILTERS: IFilterHoliday = {
  page: 1,
  limit: 10,
  status: "active",
  year: new Date().getFullYear(),
};

interface DataYear {
  label: number;
  value: number;
}

const HolidayList = () => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const dispatch = useTypedDispatch();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    delete: false,
    filter: false,
  });

  const [holidayDetail, setHolidayDetail] = useState<IHolidayStructure>();

  const meta = useSelector((state: RootState) => _.get(state.HOLIDAY, "meta"));

  const payload: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "isFetchLoading")
  );

  const pagination: IFilterHoliday = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "pagination")
  );

  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "isChangeStatusSuccess")
  );

  const createHoliday = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Holiday",
    "create"
  );

  const handleShowPopup = (name: string, value: boolean) => {
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const onRowActionChange = (action: TActions | TStatus, item: any) => {
    setHolidayDetail(item);
    setIsShowPopup({ ...isShowPopup, [action]: true });
  };

  const onPageChange = (page: number) => {
    const newFilter = { ...filters, page };
    setFilters(newFilter);
    dispatch(fetchHoliday(newFilter));
  };

  const onChangeLimit = (limit: number) => {
    const newFilter = { ...filters, page: 1, limit };
    setFilters(newFilter);
    dispatch(fetchHoliday(newFilter));
  };

  const onChangStatus = (action: "delete") => {
    if (holidayDetail?.id) {
      if (action === "delete") {
        dispatch(
          changeStatusHoliday(
            holidayDetail?.id,
            { status: "inactive" },
            pagination,
            t("button.delete")
          )
        );
      }
    }
  };

  const fieldGroupSize = useMemo(() => {
    let allyears: DataYear[] = [];
    const minYear = -(
      new Date().getFullYear() - new Date("2010-01-01T23:15:30").getFullYear()
    );
    const maxYear = 1;
    const thisYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      allyears.push({ label: thisYear + i, value: thisYear + i });
    }
    return allyears;
  }, []);

  const handleFiltersChange = (
    key: string,
    value: string | string[] | Date[]
  ) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSubmit = () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters)
      dispatch(fetchHoliday(resolvedFilters as IFilterHoliday));
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    setIsShowPopup({ ...isShowPopup, filter: false });
    dispatch(fetchHoliday(DEFAULT_FILTERS));
  };

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setIsShowPopup({
        create: false,
        delete: false,
        filter: false,
      });
    }
  }, [isChangeStatusSuccess]);

  useEffect(() => {
    dispatch(fetchHoliday(pagination));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  //  const handleResetField = (fieldNames: string | string[]) => {
  //    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
  //    const updatedPagination: any = { ...pagination };
  //    _.forEach(resetFields, (fieldName: any) => {
  //      updatedPagination[fieldName] = _.isArray(
  //        pagination[fieldName as keyof typeof pagination]
  //      )
  //        ? []
  //        : "";
  //    });

  //    setFilters({ ...updatedPagination, year: new Date().getFullYear() });
  //    dispatch(
  //      fetchHoliday({ ...updatedPagination, year: new Date().getFullYear() })
  //    );
  //  };

  //  const renderTag = (label: string | number, field: string | string[]) => {
  //    return (
  //      <Tag size="md" borderRadius="full" variant="solid" key={label}>
  //        <TagLabel>{label}</TagLabel>
  //        <TagCloseButton onClick={() => handleResetField(field)} />
  //      </Tag>
  //    );
  //  };

  //  const _renderTags = () => {
  //    const renderedTags: JSX.Element[] = [];
  //    if (pagination?.year && pagination?.year !== new Date().getFullYear()) {
  //      renderedTags.push(renderTag(pagination?.year, "year"));
  //    }

  //    return (
  //      <Box
  //        sx={{
  //          width: "100%",
  //        }}
  //      >
  //        <HStack>
  //          <Text fontSize="sm">
  //            {/* Any additional information you want to display */}
  //          </Text>
  //          <HStack spacing={4}>
  //            {!_.isEmpty(renderedTags) ? (
  //              <HStack spacing={1}>
  //                <Text fontSize="sm" fontWeight={500}>
  //                  Filter by:
  //                </Text>
  //                <HStack spacing={1}>{renderedTags}</HStack>
  //              </HStack>
  //            ) : (
  //              ""
  //            )}
  //          </HStack>
  //        </HStack>
  //      </Box>
  //    );
  //  };

  //  const _renderFilterOptions = () => (
  //    <Popover isOpen={isShowPopup.filter} placement="auto-start">
  //      <PopoverTrigger>
  //        <Button
  //          size="sm"
  //          onClick={(e: any) => {
  //            e.stopPropagation();
  //            handleShowPopup("filter", true);
  //          }}
  //          colorScheme="facebook"
  //          leftIcon={<Icon as={FunnelIcon} />}
  //        >
  //          Filter
  //        </Button>
  //      </PopoverTrigger>
  //      <PopoverContent>
  //        <PopoverArrow />
  //        <PopoverHeader>Filter Options</PopoverHeader>
  //        <PopoverBody
  //          sx={{
  //            flexDirection: "column",
  //            gap: 3,
  //          }}
  //        >
  //          <Box
  //            sx={{
  //              display: "flex",
  //              gap: 3,
  //              flexWrap: "wrap",
  //              flex: 1,
  //            }}
  //          >
  //            <InputGroup
  //              sx={{
  //                maxWidth: 100,
  //              }}
  //              size="sm"
  //            >
  //              <Select
  //                value={filters.year}
  //                onChange={(e: any) =>
  //                  handleFiltersChange("year", e.target.value)
  //                }
  //                size="sm"
  //                options={fieldGroupSize}
  //              />
  //            </InputGroup>
  //          </Box>
  //        </PopoverBody>
  //        <PopoverFooter>
  //          <HStack justifyContent="space-between">
  //            <Button
  //              size="sm"
  //              onClick={() => handleShowPopup("filter", false)}
  //              variant="ghost"
  //            >
  //              Close
  //            </Button>
  //            <HStack spacing={3}>
  //              <Box sx={{ mr: 2 }}>
  //                <Button
  //                  size="sm"
  //                  onClick={handleReset}
  //                  leftIcon={<Icon as={ArrowPathIcon} />}
  //                >
  //                  Reset
  //                </Button>
  //              </Box>
  //              <Box>
  //                <Button
  //                  size="sm"
  //                  onClick={handleSubmit}
  //                  colorScheme="facebook"
  //                  leftIcon={<Icon as={FunnelIcon} />}
  //                >
  //                  Filter
  //                </Button>
  //              </Box>
  //            </HStack>
  //          </HStack>
  //        </PopoverFooter>
  //      </PopoverContent>
  //    </Popover>
  //  );

  const _renderTable = () => {
    return (
      <DataTable.HolidayDataTable
        payload={payload}
        paginate={meta}
        onRowAction={onRowActionChange}
        isLoading={isFetchLoading}
        onPageChange={onPageChange}
        onChangeLimit={onChangeLimit}
      />
    );
  };

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box>
          <Button
            size="sm"
            leftIcon={<Icon as={ChevronLeftIcon} />}
            onClick={() => Utils.redirect(Routers.TIME_SHEET)}
            variant="ghost"
            colorScheme="twitter"
            sx={{ color: "#bb7154" }}
          >
            {t("button.backToAttendanceSheet")}
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md" color={"#5C6e6c"}>
            {t("label.holiday")}
          </Heading>
          <Box sx={{ display: "flex" }}>
            {createHoliday && (
              <Button
                //colorScheme="whatsapp"
                size="sm"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleShowPopup("create", true);
                }}
                leftIcon={<Icon as={AddIcon} boxSize={3} />}
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
              >
                {t("button.create")}
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box>{_renderTopSection()}</Box>
        <RoundedContainer>
          <Box
            sx={{
              flexWrap: "wrap",
              gap: 3,
              p: 2,
              display: "flex",
            }}
          >
            <InputGroup
              sx={{
                maxWidth: 100,
              }}
              size="sm"
            >
              <Select
                value={filters.year}
                onChange={(e: any) =>
                  handleFiltersChange("year", e.target.value)
                }
                size="sm"
                options={fieldGroupSize}
              />
            </InputGroup>
            <HStack spacing={3}>
              <Box>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  colorScheme="whatsapp"
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                >
                  {t("button.filter")}
                </Button>
              </Box>
              <Box sx={{ mr: 2 }}>
                <Button
                  size="sm"
                  onClick={handleReset}
                  leftIcon={<Icon as={ArrowPathIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                >
                  {t("button.reset")}
                </Button>
              </Box>
            </HStack>
            {/*{_renderFilterOptions()}*/}
          </Box>
          {_renderTable()}
        </RoundedContainer>
        <HolidaysDialog.CreateHolidaysDialog
          open={isShowPopup.create}
          onClose={() => handleShowPopup("create", false)}
        />
        {/*<HolidaysDialog.UpdateHolidaysDialog
          payload={holidayDetail}
          open={isShowPopup.edit}
          onClose={() => handleShowPopup("edit", false)}
        />*/}
        <ConfirmDialog
          isOpen={isShowPopup.delete}
          onClose={() => {
            setIsShowPopup({ ...isShowPopup, delete: false });
          }}
          body={t("message.doYouWantToDeleteHolidays")}
          onAction={() => onChangStatus("delete")}
          actionType={"delete"}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};
export default HolidayList;
