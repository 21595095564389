import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  HStack,
  Heading,
  Link,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";

import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { WorkspaceActions } from "@/Actions";
import { LinkIcon } from "@chakra-ui/icons";
import { useCenteredPopup, useWindowWidth } from "@/Helpers";

const { getWorkspaceWorkspaceShorCutById } = WorkspaceActions;

const workspaceIframe: React.FC = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const openCenteredPopup = useCenteredPopup();

  const idActive: any = searchParams.get("id");

  const [dataIframe, setDataIframe] = useState<any>();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const workspaceShortByid: any = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceShortByid")
  );

  const isGetLoading: any = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isGetLoading")
  );

  useEffect(() => {
    if (idActive) dispatch(getWorkspaceWorkspaceShorCutById(idActive));
  }, [searchParams]);

  useEffect(() => {
    if (!_.isEmpty(workspaceShortByid) && idActive) {
      setDataIframe(workspaceShortByid);
    } else {
      setDataIframe({});
    }
  }, [workspaceShortByid, searchParams]);

  useEffect(() => {
    setIframeLoaded(false);
  }, [searchParams]);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const iframeElement = React.useMemo(() => {
    return (
      <iframe
        id={"iframe_id"}
        src={dataIframe && dataIframe?.link}
        key={dataIframe?.id}
        title={dataIframe && dataIframe?.name}
        height={"100%"}
        width={"100%"}
        style={{ borderRadius: "15px" }}
        onLoad={handleIframeLoad}
      />
    );
  }, [dataIframe]);

  const renderMain = () => {
    return (
      <Stack
        sx={{
          //  p: "0.5rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              w: "full",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {dataIframe?.name}
            </Heading>
            {dataIframe?.link && (
              <Box>
                {isMobile ? (
                  <Link href={dataIframe?.link} target="_blank">
                    <Tooltip label={""}>
                      <LinkIcon
                        sx={{
                          "&:hover": {
                            color: "rgba(0, 0, 255, 0.6)",
                          },
                        }}
                      />
                    </Tooltip>
                  </Link>
                ) : (
                  <Tooltip label={""}>
                    <LinkIcon
                      onClick={() => {
                        openCenteredPopup(dataIframe?.link);
                      }}
                      sx={{
                        "&:hover": {
                          color: "rgba(0, 0, 255, 0.6)",
                        },
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
          </HStack>
          <Box
            textAlign={"center"}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            {(isGetLoading || !iframeLoaded) && (
              <CircularProgress size="50px" isIndeterminate />
            )}
          </Box>
        </Box>
        {iframeElement}
        {/*<iframe
          id={"iframe_id"}
          src={dataIframe && dataIframe?.link}
          title="W3Schools Free Online Web Tutorials"
          height={"100%"}
          width={"100%"}
          style={{ borderRadius: "15px" }}
        />*/}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default workspaceIframe;
