import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { UPDATE_TIME_CARD } = APIs;

export const fetchRequestUpdateTimeCard = async (payload: any) => {
  return request(UPDATE_TIME_CARD.BASIC, "GET", payload);
};

export const getRequestUpdateTimeCardById = async (id: string) => {
  return request(`${UPDATE_TIME_CARD.GET_BY_ID}/${id}`, "GET");
};

export const createRequestUpdateTimeCard = async (payload: FormData) => {
  return request(UPDATE_TIME_CARD.BASIC, "POST_FORM_DATA", payload);
};

export const updateRequestUpdateTimeCard = async (id: string, payload: any) => {
  return request(`${UPDATE_TIME_CARD.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const ChangeStatusRequestUpdateTimeCard = async (
  id: string,
  payload: any
) => {
  return request(
    `${UPDATE_TIME_CARD.CHANGE_STATUS}/${id}`,
    "PUT_FORM_DATA",
    payload
  );
};

export const deleteUpdateTimeCard = async (id: string) => {
  return request(`${UPDATE_TIME_CARD.DELETE}/${id}`, "PUT");
};
