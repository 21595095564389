import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import {
  ICreateIp,
  IInternalStructure,
} from "@/Interfaces/InternalIp.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setInternalIpFetchLoading = () => {
  return {
    type: ActionTypes.SET_INTERNAL_IP_FETCH_LOADING,
  };
};

const setInternalIpGetLoading = () => {
  return {
    type: ActionTypes.SET_INTERNAL_IP_GET_LOADING,
  };
};

const resetStatusInternalIp = () => {
  return {
    type: ActionTypes.RESET_STATUS_INTERNAL_IP,
  };
};

const setInternalIpActionLoading = () => {
  return {
    type: ActionTypes.SET_INTERNAL_IP_ACTION_LOADING,
  };
};

const resetInternalIpReducer = () => {
  return {
    type: ActionTypes.RESET_INTERNAL_IP,
  };
};

const setMetaInternalIp = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_INTERNAL_IP,
    payload,
  };
};

const setPaginationInternalIp = (payload?: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_INTERNAL_IP,
    payload,
  };
};

const createInternalIpSuccess = () => {
  return {
    type: ActionTypes.CREATE_INTERNAL_IP_SUCCESS,
  };
};

const createInternalIpFail = () => {
  return {
    type: ActionTypes.CREATE_INTERNAL_IP_FAILURE,
  };
};

const createInternalIp = (payload: ICreateIp) => {
  return async (dispatch: any) => {
    dispatch(setInternalIpActionLoading());
    dispatch(resetStatusInternalIp());
    await APIs.createInternal(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createInternalIpFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createInternalIpSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchInternalIp());
        }
      })
      .catch(async (error) => {
        await dispatch(createInternalIpFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchInternalIpSuccess = (payload: IInternalStructure) => {
  return {
    type: ActionTypes.FETCH_INTERNAL_IP_SUCCESS,
    payload,
  };
};

const fetchInternalIpFail = () => {
  return {
    type: ActionTypes.FETCH_INTERNAL_IP_FAILURE,
  };
};

const fetchInternalIp = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setInternalIpFetchLoading());
    await APIs.fetchInternal(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchInternalIpFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaInternalIp(_.get(result, "meta")));
          await dispatch(setPaginationInternalIp(payload));
          await dispatch(fetchInternalIpSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchInternalIpFail());
      });
  };
};

const getByIdInternalIpSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_INTERNAL_IP_SUCCESS,
    payload,
  };
};

const getByIdInternalIpFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_INTERNAL_IP_FAILURE,
  };
};

const getByIdInternalIp = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setInternalIpGetLoading());
    await APIs.getInternalById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdInternalIpFail());
        else {
          dispatch(getByIdInternalIpSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdInternalIpFail());
      });
  };
};

const updateInternalIpSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_INTERNAL_IP_SUCCESS,
    payload,
  };
};

const updateInternalIpFail = () => {
  return {
    type: ActionTypes.UPDATE_INTERNAL_IP_FAILURE,
  };
};

const updateInternalIp = (id: string, payload: ICreateIp, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setInternalIpFetchLoading());
    dispatch(resetStatusInternalIp());
    await APIs.updateInternal(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateInternalIpFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateInternalIpSuccess(result));
          dispatch(fetchInternalIp(pagination));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchInternalIp());
        }
      })
      .catch(async (error) => {
        await dispatch(updateInternalIpFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusInternalIpSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_INTERNAL_IP_SUCCESS,
  };
};

const changeStatusInternalIpFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_INTERNAL_IP_FAILURE,
  };
};

const changeStatusInternalIp = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setInternalIpActionLoading());
    await APIs.changeStatusInternal(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusInternalIpFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(changeStatusInternalIpSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchInternalIp());
        }
      })
      .catch(async (error: any) => {
        await dispatch(changeStatusInternalIpFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const createInternalIpRemoteSuccess = () => {
  return {
    type: ActionTypes.CHANGE_INTERNAL_IP_REMOTE_SUCCESS,
  };
};

const createInternalIpRemoteFail = () => {
  return {
    type: ActionTypes.CHANGE_INTERNAL_IP_REMOTE_FAILURE,
  };
};

const createInternalIpRemote = () => {
  return async (dispatch: any) => {
    dispatch(setInternalIpActionLoading());
    await APIs.changeInternalRemote()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          const language = Utils.getSavedLanguage();
          await dispatch(createInternalIpRemoteFail());
          await Toast({
            title: `${
              response?.data?.payload?.status
                ? language === "en"
                  ? "Enable remote working feature error"
                  : "Bật tính năng làm việc từ xa lỗi"
                : language === "en"
                ? "Turn off the remote working feature error"
                : "Tắt tính năng làm việc từ xa lỗi"
            } `,
            status: "error",
          });
        } else {
          const language = Utils.getSavedLanguage();
          dispatch(createInternalIpRemoteSuccess());
          dispatch(fetchInternalIpRemote());
          await Toast({
            title: `${
              response?.data?.payload?.status
                ? language === "en"
                  ? "Enable remote working feature successfully"
                  : "Bật tính năng làm việc từ xa thành công"
                : language === "en"
                ? "Turn off the remote working feature successfully"
                : "Tắt tính năng làm việc từ xa thành công"
            } `,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(createInternalIpRemoteFail());
        await Toast({
          title: ``,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const fetchInternalIpRemoteSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_INTERNAL_REMOTE_SUCCESS,
    payload,
  };
};

const fetchInternalIpRemoteFail = () => {
  return {
    type: ActionTypes.FETCH_INTERNAL_REMOTE_FAILURE,
  };
};

const fetchInternalIpRemote = () => {
  return async (dispatch: any) => {
    dispatch(setInternalIpFetchLoading());
    await APIs.fetchInternalIpRemote()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(fetchInternalIpRemoteFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchInternalIpRemoteSuccess(resolveResult));
        }
      })
      .catch(async () => {
        await dispatch(fetchInternalIpRemoteFail());
      });
  };
};

export default {
  resetInternalIpReducer,
  getByIdInternalIp,
  fetchInternalIp,
  updateInternalIp,
  changeStatusInternalIp,
  createInternalIp,
  createInternalIpRemote,
  fetchInternalIpRemote,
};
