import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { IUpdateProject } from "@/Interfaces/Project.interface";

const { PROJECT_EXTERIOR } = APIs;

export const fetchProjectsExterior = async (payload: any) => {
  return request(PROJECT_EXTERIOR.BASIC, "GET", payload);
};

export const createProjectExterior = async (payload: any) => {
  return request(PROJECT_EXTERIOR.BASIC, "POST", payload);
};

export const getProjectExteriorById = async (id: string) => {
  return request(`${PROJECT_EXTERIOR.GET_BY_ID}/${id}`, "GET");
};

export const updateProjectExterior = async (
  id: string,
  payload: IUpdateProject
) => {
  return request(`${PROJECT_EXTERIOR.BASIC}/${id}`, "PUT", payload);
};

export const changeProjectExteriorStatus = async (id: string, payload: any) => {
  return request(`${PROJECT_EXTERIOR.CHANGE_STATUS}/${id}`, "PUT", payload);
};

export const deleteProjectExterior = async (id: string) => {
  return request(`${PROJECT_EXTERIOR.DELETE}/${id}`, "PUT");
};

export const fetchUserCanAdd = async (payload: any) => {
  return request(PROJECT_EXTERIOR.FETCH_USER_CAN_ADD, "GET", payload);
};
