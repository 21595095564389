import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";

import {
  Stack,
  Heading,
  Text,
  Box,
  Button,
  Icon,
  InputGroup,
  IconButton,
  Flex,
  Avatar,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  Tag,
  TagLabel,
  TagCloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  chakraComponents,
  OptionBase,
  GroupBase,
  SelectComponentsConfig,
} from "chakra-react-select";

import "react-calendar/dist/Calendar.css";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import {
  FunnelIcon,
  ArrowPathIcon,
  // PlusIcon,
} from "@heroicons/react/24/outline";

import { AdminLayout } from "@/Components/DefaultLayout";
import {
  Autocomplete,
  MonthPicker,
  RoundedContainer,
  TextField,
} from "@/Components/Common";

import { TimesheetActions, RoleActions, UserActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";
import Utils from "@/Utils";
import { ENUMS, Routers } from "@/Constants";

import TableComponent from "./TableComponent";
import { useSelector } from "react-redux";
import { IRoleStructure } from "@/Interfaces/Role.interface";
import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import { ExportExcelAttdance } from "@/Components/Export";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

import "dayjs/locale/vi";

const { fetchTimesheets, resetTimesheetRecucer } = TimesheetActions;
const { fetchRoles } = RoleActions;
const { fetchUser, resetUserReducer } = UserActions;

interface IOption extends OptionBase {
  label: string;
  value: string | string[];
  variant?: string;
  colorScheme?: string;
  extendProps?: any;
}

const DEFAULT_FILTERS = {
  page: 0,
  limit: 0,
  keyword: "",
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
};

const Timesheet: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [keyword, setKeyWord] = useState<string>("");
  const [valueKeyword, setValueKeyword] = useState<string>("");
  const language = Utils.getSavedLanguage();

  const canFetchUsers = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "fetchUsers"
  );

  const canFilter = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timesheet",
    "filter"
  );

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const roles: IRoleStructure[] = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );

  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "pagination")
  );

  const users: any = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const roleOptions = useMemo(
    () =>
      _.map(roles, (role) => ({
        label:
          role?.roleCode === ENUMS.ROLES.ARTIST
            ? `Staff`
            : _.startCase(role?.name),
        value: role?.roleCode,
      })),
    [roles]
  );

  const userOptions = useMemo(
    () =>
      _.map(users, (user) => ({
        label: _.startCase(user?.userData?.fullName),
        value: user?.id,
      })),
    [users]
  );

  const [filters, setFilters] = useState<ITimesheetPagination>(DEFAULT_FILTERS);

  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const recordStartDate = searchParams.get("startDate");
  const recordEndDate = searchParams.get("endDate");

  useEffect(() => {
    if (recordEndDate && recordStartDate) {
      dispatch(
        fetchTimesheets({
          ...DEFAULT_FILTERS,
          startDate: recordStartDate,
          endDate: recordEndDate,
        })
      );
      setFilters({
        ...filters,
        startDate: recordStartDate,
        endDate: recordEndDate,
      });
    } else dispatch(fetchTimesheets(DEFAULT_FILTERS));
    if (_.isEmpty(roles)) dispatch(fetchRoles());
    if (canFetchUsers)
      dispatch(
        fetchUser({
          page: 0,
          limit: 0,
          status: ENUMS.USER_STATUS.ACTIVE,
        })
      );
    return () => {
      dispatch(resetTimesheetRecucer());
      dispatch(resetUserReducer());
    };
  }, []);

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  const customComponents: SelectComponentsConfig<
    IOption,
    true,
    GroupBase<IOption>
  > = {
    Option: ({ children, ...props }) => {
      return (
        <chakraComponents.Option {...props}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar
              size="xs"
              src={props?.data?.extendProps?.avatar}
              name={(_.isString(children) && children) || ""}
            />
            <Box>
              {children}
              <Text
                fontSize="sm"
                sx={{
                  maxW: "250px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden !important",
                }}
              >
                {props?.data?.extendProps?.email}
              </Text>
            </Box>
          </Box>
        </chakraComponents.Option>
      );
    },
    MultiValueContainer: ({ children, ...props }) => (
      <chakraComponents.MultiValueContainer {...props}>
        {children}
      </chakraComponents.MultiValueContainer>
    ),
  };

  const handlePrevClick = async () => {
    const updatedFilters = {
      ...filters,
      startDate: dayjs(filters.startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    };
    searchParams.set("startDate", updatedFilters.startDate);
    searchParams.set("endDate", updatedFilters.endDate);
    setSearchParams(searchParams);

    setFilters(updatedFilters);
    dispatch(fetchTimesheets(updatedFilters));
  };

  const handleNextClick = async () => {
    const updatedFilters = {
      ...filters,
      startDate: dayjs(filters.startDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    };

    searchParams.set("startDate", updatedFilters.startDate);
    searchParams.set("endDate", updatedFilters.endDate);
    setSearchParams(searchParams);

    setFilters(updatedFilters);
    dispatch(fetchTimesheets(updatedFilters));
  };

  const handleFiltersChange = (key: string, value: string | string[]) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...pagination };

    _.forEach(resetFields, (fieldName) => {
      if (fieldName === "startDate") {
        updatedPagination.startDate = dayjs(dayjs().startOf("month")).format(
          "YYYY-MM-DD"
        );
      } else if (fieldName === "endDate") {
        updatedPagination.endDate = dayjs(dayjs().endOf("month")).format(
          "YYYY-MM-DD"
        );
      } else {
        updatedPagination[fieldName] = _.isArray(updatedPagination[fieldName])
          ? []
          : "";
      }
    });

    setFilters(updatedPagination);

    if (fieldNames === "userId" || fieldNames === "roleCode")
      setIsClearFilters(true);

    dispatch(fetchTimesheets(updatedPagination));
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    setSearchParams(searchParams);
  };

  const handleReset = () => {
    setIsClearFilters(true);
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchTimesheets(DEFAULT_FILTERS));
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    setSearchParams(searchParams);
    setKeyWord("");
    setValueKeyword("");
  };

  const handleSubmit = async () => {
    setIsClearFilters(false);
    setIsShowFilter(false);
    const resolvedFilters: any = Utils.removeEmptyFields({
      ...filters,
      keyword: _.trim(filters.keyword),
    });
    if (resolvedFilters) {
      dispatch(fetchTimesheets(resolvedFilters));
      searchParams.set("startDate", resolvedFilters?.startDate);
      searchParams.set("endDate", resolvedFilters?.endDate);
      setSearchParams(searchParams);
    }
    valueKeyword ? setKeyWord(valueKeyword) : setKeyWord("");
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        h="max-content"
        variant="solid"
        key={label}
      >
        <TagLabel
          sx={{
            textTransform: field !== "keyword" ? "capitalize" : "inherit",
          }}
        >
          {label}
        </TagLabel>
        {((_.isEqual(field, ["startDate", "endDate"]) &&
          !dayjs(dayjs().startOf("month").format("YYYY-MM-DD")).isSame(
            dayjs(pagination.startDate).format("YYYY-MM-DD")
          )) ||
          !_.isEqual(field, ["startDate", "endDate"])) && (
          <TagCloseButton onClick={() => handleResetField(field)} />
        )}
      </Tag>
    );
  };

  const getMemberLabel = (memberId: string) => {
    const userOption = userOptions.find((option) => option.value === memberId);
    return userOption?.label || memberId;
  };

  const getRoleLabel = (roleCode: string) => {
    const userOption = roleOptions.find((option) => option.value === roleCode);
    return userOption?.label || roleCode;
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (!pagination) return;

    if (pagination.keyword) {
      renderedTags.push(renderTag(pagination.keyword, "keyword"));
    }

    if (
      pagination.startDate &&
      !dayjs(dayjs().startOf("month").format("YYYY-MM-DD")).isSame(
        dayjs(pagination.startDate).format("YYYY-MM-DD")
      )
    ) {
      renderedTags.push(
        renderTag(
          `${dayjs(pagination.startDate).format("DD/MM/YYYY")} - ${dayjs(
            pagination.endDate
          ).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    if (!_.isEmpty(pagination.roleCode)) {
      const memberLabels = _.map(pagination.roleCode, getRoleLabel);
      const memberString = memberLabels.join(", ");
      renderedTags.push(renderTag(memberString, "roleCode"));
    }

    if (!_.isEmpty(pagination.userId)) {
      const memberLabels = _.map(pagination.userId, getMemberLabel);
      const memberString = memberLabels.join(", ");
      renderedTags.push(renderTag(memberString, "userId"));
    }

    <HStack spacing={4}>
      <HStack spacing={1}>
        <Text fontSize="sm" fontWeight={500} color={"#5C6e6c"}>
          {t("label.filterBy")}
        </Text>
        <HStack spacing={1}>{renderedTags}</HStack>
      </HStack>
    </HStack>;
  };

  const _renderFilterOptions = () => (
    <Popover isOpen={isShowFilter} placement="auto">
      <PopoverTrigger>
        <Button
          size="sm"
          onClick={() => setIsShowFilter(true)}
          colorScheme="whatsapp"
          leftIcon={<Icon as={FunnelIcon} />}
          sx={{
            color: "#fff",
            background: "#D2A96A",
            "&:hover": {
              background: "#D2BD6A",
            },
          }}
        >
          {t("button.filter")}
        </Button>
      </PopoverTrigger>
      <Box
        sx={{
          "& .css-1qq679y": {
            zIndex: isShowFilter ? 10 : -1,
          },
        }}
      >
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>{t("label.filterOptions")}</PopoverHeader>
          <PopoverBody
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <InputGroup size="sm">
              <Autocomplete
                value={filters.userId}
                label={t("label.roles")}
                isClear={isClearFilters}
                isMulti
                onChange={(newValue) =>
                  handleFiltersChange("roleCode", newValue)
                }
                options={roleOptions}
                placeholder={t("label.select")}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Autocomplete
                value={filters.userId}
                label={t("label.users")}
                isClear={isClearFilters}
                isMulti
                onChange={(newValue) => handleFiltersChange("userId", newValue)}
                options={userOptions}
                customComponents={customComponents}
                placeholder={t("label.select")}
              />
            </InputGroup>

            <InputGroup
              sx={{
                w: "full",
              }}
            >
              <MonthPicker
                defaultValue={{
                  startDate: filters?.startDate,
                  endDate: filters?.endDate,
                }}
                onDateChange={({ startDate, endDate }) =>
                  setFilters({ ...filters, startDate, endDate })
                }
              />
            </InputGroup>
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent="space-between">
              <Button
                size="sm"
                onClick={() => setIsShowFilter(false)}
                variant="ghost"
                colorScheme="red"
              >
                {t("button.close")}
              </Button>
              <HStack spacing={3}>
                <Button
                  size="sm"
                  onClick={handleReset}
                  colorScheme="orange"
                  leftIcon={<Icon as={ArrowPathIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                >
                  {t("button.reset")}
                </Button>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  //  colorScheme="whatsapp"
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                >
                  {t("button.filter")}
                </Button>
              </HStack>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Box>
    </Popover>
  );

  const _renderFilterOptionUser = () => {
    return (
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        <Box sx={{ w: 130 }}>
          <MonthPicker
            defaultValue={{
              startDate: filters?.startDate,
              endDate: filters?.endDate,
            }}
            onDateChange={({ startDate, endDate }) =>
              setFilters({ ...filters, startDate, endDate })
            }
          />
        </Box>
        <Box sx={{ display: "flex ", gap: 2 }}>
          <Button
            size="sm"
            onClick={handleReset}
            leftIcon={<Icon as={ArrowPathIcon} />}
            sx={{
              color: "#fff",
              background: "#bb7154",
              "&:hover": {
                background: "#Db9d97",
              },
            }}
          >
            {t("button.reset")}
          </Button>
          <Button
            size="sm"
            onClick={handleSubmit}
            //colorScheme="whatsapp"
            leftIcon={<Icon as={FunnelIcon} />}
            sx={{
              color: "#fff",
              background: "#D2A96A",
              "&:hover": {
                background: "#D2BD6A",
              },
            }}
          >
            {t("button.filter")}
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderFilterSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        {canFilter && (
          <Box sx={{ maxW: isMobile ? "210px" : "275px" }}>
            <TextField
              size="sm"
              placeholder={t("label.search")}
              value={valueKeyword}
              onChange={(e) => {
                setValueKeyword(e.target.value);
                //handleFiltersChange("keyword", e.target.value);
              }}
              onEnter={() => {
                //filters?.keyword && dispatch(fetchTimesheets(filters));
                valueKeyword ? setKeyWord(valueKeyword) : setKeyWord("");
              }}
              rightElement={
                <Tooltip label={t("label.search")} hasArrow>
                  <Icon
                    as={SearchIcon}
                    boxSize={5}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      //  dispatch(fetchTimesheets(filters));
                      valueKeyword ? setKeyWord(valueKeyword) : setKeyWord("");
                    }}
                  />
                </Tooltip>
              }
            />
          </Box>
        )}
        <Box
          sx={{
            ".chakra-popover__popper": {
              zIndex: 9,
            },
          }}
        >
          {canFilter ? _renderFilterOptions() : _renderFilterOptionUser()}
        </Box>
      </Box>
    );
  };

  const _renderDataTableSection = () => {
    const startDate = dayjs(pagination?.startDate).format("MMMM");
    const endDate = dayjs(pagination?.endDate).get("year");

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        <Box
          sx={{
            mx: "auto",
            display: "flex",
            textAlign: "center",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            isRound
            size="sm"
            background="transparent"
            aria-label={""}
            onClick={handlePrevClick}
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <ChevronLeftIcon boxSize={5} />
          </IconButton>
          <Text
            fontSize="18px"
            fontWeight={700}
            sx={{ textTransform: "capitalize" }}
          >
            {`${startDate} - ${endDate}`}
          </Text>
          <IconButton
            isRound
            size="sm"
            background="transparent"
            aria-label={""}
            onClick={handleNextClick}
          >
            <ChevronRightIcon boxSize={5} />
          </IconButton>
        </Box>
        <TableComponent keyword={keyword} />
        <Box>
          <Text fontSize="18px" fontWeight={700}>
            {t("label.note")}:
          </Text>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" fontWeight={700}>
              X:
            </Text>
            <Text fontSize="sm">{t("label.workingDay")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" color="red" fontWeight={700}>
              K/2:
            </Text>
            <Text fontSize="sm">{t("label.aHalfOfUnpaidLeav")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" color="red" fontWeight={700}>
              K:
            </Text>
            <Text fontSize="sm">{t("label.unpaidLeave")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" color="red" fontWeight={700}>
              P/2:
            </Text>
            <Text fontSize="sm">{t("label.aHalfOfAnnualLeave")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" color="red" fontWeight={700}>
              P:
            </Text>
            <Text fontSize="sm">{t("label.annualLeave")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" color="red" fontWeight={700}>
              L:
            </Text>
            <Text fontSize="sm">{t("label.holiday")}</Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            <Text fontSize="sm" fontWeight={700}>
              TV:
            </Text>
            <Text fontSize="sm">{t("label.probationaryDay")}</Text>
          </Flex>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.attendanceSheet")}
          </Heading>
          <Box gap={2} sx={{ display: "flex" }}>
            <Box>{canFetchUsers && <ExportExcelAttdance />}</Box>
            <Button
              colorScheme="twitter"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Utils.redirect(Routers.HOLIDAYS);
              }}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
              // leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            >
              {t("button.holiDay")}
            </Button>
          </Box>
        </Box>
        <RoundedContainer>
          {_renderFilterSection()}
          {_renderTags()}
          {_renderDataTableSection()}
        </RoundedContainer>
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Timesheet;
