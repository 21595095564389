import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Box, Divider, Heading, Image } from "@chakra-ui/react";

import SideBar from "@/Components/LayoutPart/SideBar";
import Appbar from "../LayoutPart/Appbar";
import Utils from "@/Utils";
import { Routers, ENUMS } from "@/Constants";
import { UserActions, SocketActions, WorkspaceActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { getSocket } from "@/Configs/socket.config";

import { UserDialog } from "@/Components/Popup";
import { IUserPerformAction } from "@/Interfaces/User.interface";
import { ArrowLeftIcon } from "@chakra-ui/icons";
import { useWindowWidth } from "@/Helpers";
import logo from "@/Assets/cc-logo-white.svg";

import { generateToken } from "@/Configs/firebase.config";

const { resetUserPerformAction } = UserActions;
const { setUserConnectSocket, setSidebarExpanded } = SocketActions;
const { fetchWorkspaceTime, fetchWorkspaceShortCut } = WorkspaceActions;

interface SectionProps {
  content?: any;
}

const drawerWidth = 250;

const AdminLayout: React.FC<SectionProps> = ({ content }) => {
  const socket = getSocket();
  const dispatch = useTypedDispatch();
  const performAction: IUserPerformAction = useSelector((state: RootState) =>
    _.get(state.USER, "performAction")
  );
  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const windowWidth = useWindowWidth();

  const isDesktop = windowWidth >= 1280;
  const isMobile = windowWidth <= 768;

  const [newUserConnect, setNewUserConnect] = useState<any>(null);

  useEffect(() => {
    generateToken();

    socket.on(ENUMS.SOCKET_EVENT.USER_CONNECT_SOCKET, (user) => {
      setNewUserConnect(user);
    });
  }, []);

  useEffect(() => {
    if (newUserConnect) {
      dispatch(setUserConnectSocket(newUserConnect));
      setNewUserConnect(null);
    }
  }, [newUserConnect]);

  useEffect(() => {
    dispatch(fetchWorkspaceTime({ page: 0, limit: 0 }));
    dispatch(fetchWorkspaceShortCut({ page: 0, limit: 0, type: "single" }));
  }, []);

  const _renderAppbar = () => <Appbar />;

  const _renderPopup = () => (
    <>
      <UserDialog.UserProfile
        open={performAction?.actionType === "viewProfile"}
        onClose={() => dispatch(resetUserPerformAction())}
      />
    </>
  );

  return (
    <Box
      sx={{
        w: "100vw",
        h: "100vh",
        overflow: "auto",
        display: "flex",
        //backgroundColor: "white",
        position: "relative",
      }}
    >
      {isDesktop && (
        <Box
          sx={{
            width: isSidebarExpanded ? drawerWidth : "4rem",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            display: "flex",
            flexDirection: "column",
            transition: "width .5s ease-in-out",
            willChange: "width",
            height: "full",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              height: "4rem",
              minH: "4rem",
              position: "relative",
            }}
          >
            <Heading
              variant="subtitle2"
              sx={{
                fontSize: 18,
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                h: "full",
                whiteSpace: "nowrap",
                overflow: "hidden",
                transition: "width .5s ease-in-out",
                backgroundColor: "white",
                ml: 3,
                // color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => Utils.redirect(Routers.DASHBOARD)}
            >
              <Image src={logo} sx={{ w: 35, height: 35, mr: 2 }} />
              {isSidebarExpanded && "Workspace"}
            </Heading>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: "0px",
                cursor: "pointer",
                // zIndex: "popover",
                // color: "#",
              }}
            >
              <ArrowLeftIcon
                width={3}
                height={3}
                onClick={() => dispatch(setSidebarExpanded(!isSidebarExpanded))}
                color="#bb7154"
                style={{
                  transition: "transform .5s ease-in-out",
                  transform: isSidebarExpanded ? "rotate(0)" : "rotate(180deg)",
                }}
              />
            </Box>
            <Divider sx={{ m: 0 }} />
          </Box>
          <SideBar
            isOpen={isSidebarExpanded}
            onClick={() => dispatch(setSidebarExpanded(true))}
          />
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
          }}
        >
          {_renderAppbar()}
          <Box
            sx={{
              height: "calc(100vh - 4rem)",
              width: "100%",
              overflowY: "auto",
              py: isMobile ? 0 : 2,
              px: isMobile ? 0 : 2,
            }}
            ref={calendarContainerRef}
            className="container"
          >
            {content}
          </Box>
        </Box>
      </Box>
      {_renderPopup()}
    </Box>
  );
};

export default React.memo(AdminLayout);
