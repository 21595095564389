import React from "react";

import CountUp from "react-countup";
import { Stack, Text, Box, Image, CircularProgress } from "@chakra-ui/react";
import { useWindowWidth } from "@/Helpers";

interface SectionProps {
  label: string;
  value: number;
  sxContainer?: object;
  icon: any;
  isLoading: boolean;
}
const StatisticBox: React.FC<SectionProps> = ({
  label,
  value,
  sxContainer,
  icon,
  isLoading,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  return (
    <Stack
      sx={{
        ...sxContainer,
        p: 2,
        // borderRadius: 5,
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        color: "#464C78",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: isMobile ? "auto" : "150px",
        justifyContent: "center",
        alignItems: "center",
        gap: isMobile ? 0 : 5,
      }}
      direction={isMobile ? "column" : "row"}
    >
      {isLoading && <CircularProgress size="38px" isIndeterminate />}
      {!isLoading && (
        <Image
          src={icon}
          sx={{ width: isMobile ? "25px" : "50px", objectFit: "contain" }}
        />
      )}
      {!isLoading && (
        <Box>
          {!isMobile && (
            <Text
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 500,
                color: "white",
              }}
            >
              {label}
            </Text>
          )}

          <Text
            sx={{
              textAlign: "center",
              fontSize: "26px",
              fontWeight: 800,
              color: "white",
            }}
          >
            <CountUp end={value || 0} delay={1} />
          </Text>
        </Box>
      )}
    </Stack>
  );
};

export default StatisticBox;
