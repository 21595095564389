// GlobalWindowState.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

interface GlobalWindowStateContextType {
  isGlobalWindowOpen: boolean;
  toggleGlobalWindow: () => void;
}

const GlobalWindowStateContext = createContext<
  GlobalWindowStateContextType | undefined
>(undefined);

export const useGlobalWindowState = () => {
  const context = useContext(GlobalWindowStateContext);
  if (!context) {
    throw new Error(
      "useGlobalWindowState must be used within a GlobalWindowStateProvider"
    );
  }
  return context;
};

interface GlobalWindowStateProviderProps {
  children: ReactNode;
}

export const GlobalWindowStateProvider: React.FC<
  GlobalWindowStateProviderProps
> = ({ children }) => {
  const [isGlobalWindowOpen, setIsGlobalWindowOpen] = useState(false);

  const toggleGlobalWindow = () => {
    setIsGlobalWindowOpen(!isGlobalWindowOpen);
  };

  return (
    <GlobalWindowStateContext.Provider
      value={{ isGlobalWindowOpen, toggleGlobalWindow }}
    >
      {children}
    </GlobalWindowStateContext.Provider>
  );
};
