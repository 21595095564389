import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Stack, Heading, Box, Text } from "@chakra-ui/react";

import { AdminLayout } from "@/Components/DefaultLayout";
import { RootState, useTypedDispatch } from "@/Store";
import { WorkingDayActions } from "@/Actions";
import { RoundedContainer, Button } from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";

import { useTranslation } from "react-multi-lang";
import { IWorkingDayStructure } from "@/Interfaces/Workingday.interface";
import CommonColors from "@/Themes/CommonColors";
import { useWorkingDay } from "@/Helpers";
import Utils from "@/Utils";

const { updateWorkingDay, fetchWorkingDay, resetWorkingDayReducer } =
  WorkingDayActions;

const WORKING_DAY: IWorkingDayStructure = {
  mondayMorning: false,
  mondayAfternoon: false,
  tuesdayMorning: false,
  tuesdayAfternoon: false,
  wednesdayMorning: false,
  wednesdayAfternoon: false,
  thursdayMorning: false,
  thursdayAfternoon: false,
  fridayMorning: false,
  fridayAfternoon: false,
  saturdayMorning: false,
  saturdayAfternoon: false,
  sundayMorning: false,
  sundayAfternoon: false,
};

const WorkingDay: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const currentLanguage = Utils.getSavedLanguage();
  const workingDayFromStore: any = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );

  const [workingDay, setWorkingDay] =
    useState<IWorkingDayStructure>(WORKING_DAY);

  const [confirmMsg, setConfirmMsg] = useState("");
  const [isShowPopup, setIsShowPopup] = useState({
    confirmSubmit: false,
  });

  useEffect(() => {
    dispatch(fetchWorkingDay());
    return () => {
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    if (workingDayFromStore) {
      const resolvedData: any = _.omit(workingDayFromStore, [
        "id",
        "createdAt",
        "updatedAt",
      ]);
      setWorkingDay(resolvedData);
    } else setWorkingDay(WORKING_DAY);
  }, [workingDayFromStore]);

  const handleToggle = (day: keyof IWorkingDayStructure) => {
    setWorkingDay((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const handleConfirm = (name: string, value: boolean) => {
    if (name === "confirmSubmit" && value) {
      const allFalse = Object.values(workingDay).every((value) => !value);
      const message = allFalse
        ? t("message.confirmAllDaysInactive")
        : t("message.confirmChangeWorkingDay");
      setConfirmMsg(message);
    }
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(updateWorkingDay(workingDay));
    handleConfirm("confirmSubmit", false);
  };

  const _renderDay = (
    day:
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday",
    label: string
  ) => {
    const isDayActive =
      workingDay[`${day}Morning`] || workingDay[`${day}Afternoon`];
    const dayLabelBgColor = isDayActive ? CommonColors.lightOrange : "#ddd";

    return (
      <Box
        key={day}
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            p: 5,
            borderRadius: 5,
            bg: dayLabelBgColor,
            w: 150,
            h: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            if (isDayActive) {
              const updatedWorkingDay = { ...workingDay };
              for (const key in updatedWorkingDay) {
                if (key.startsWith(day)) {
                  updatedWorkingDay[key as keyof typeof updatedWorkingDay] =
                    false;
                }
              }
              setWorkingDay(updatedWorkingDay);
            } else {
              const updatedWorkingDay = { ...workingDay };
              for (const key in updatedWorkingDay) {
                if (key.startsWith(day)) {
                  updatedWorkingDay[key as keyof typeof updatedWorkingDay] =
                    true;
                }
              }
              setWorkingDay(updatedWorkingDay);
            }
          }}
        >
          <Text
            fontSize="sm"
            sx={{
              color: "#fff",
            }}
          >
            {label}
          </Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Text
            sx={{
              p: 1,
              bg: workingDay[`${day}Morning`]
                ? CommonColors.lightOrange
                : "#ddd",
              border: "1px solid #ddd",
              w: "max-content",
              cursor: "pointer",
              color: "#fff",
              textTransform: "uppercase",
            }}
            onClick={() =>
              handleToggle(`${day}Morning` as keyof IWorkingDayStructure)
            }
          >
            {t("label.morning")}
          </Text>
          <Box>-</Box>
          <Text
            sx={{
              p: 1,
              bg: workingDay[`${day}Afternoon`]
                ? CommonColors.lightOrange
                : "#ddd",
              border: "1px solid #ddd",
              w: "max-content",
              cursor: "pointer",
              color: "#fff",
              textTransform: "uppercase",
            }}
            onClick={() =>
              handleToggle(`${day}Afternoon` as keyof IWorkingDayStructure)
            }
          >
            {t("label.afternoon")}
          </Text>
        </Box>
      </Box>
    );
  };

  const days = [
    { key: "monday", label: currentLanguage === "en" ? "MONDAY" : "Thứ 2" },
    { key: "tuesday", label: currentLanguage === "en" ? "TUESDAY" : "Thứ 3" },
    {
      key: "wednesday",
      label: currentLanguage === "en" ? "WEDNESDAY" : "Thứ 4",
    },
    { key: "thursday", label: currentLanguage === "en" ? "THURSDAY" : "Thứ 5" },
    { key: "friday", label: currentLanguage === "en" ? "FRIDAY" : "Thứ 6" },
    { key: "saturday", label: currentLanguage === "en" ? "SATURDAY" : "Thứ 7" },
    { key: "sunday", label: currentLanguage === "en" ? "SUNDAY" : "Chủ Nhật" },
  ] as const;

  const _renderSetting = () => {
    return (
      <Box sx={{}}>
        <Heading size="md" sx={{ mb: 2 }}>
          {t("table.totalDays")}:{" "}
          {useWorkingDay.calculateTotalDuration(workingDay)}
        </Heading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {_.map(days, (day) => _renderDay(day.key, day.label))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        ></Box>
      </Box>
    );
  };

  const _renderPopup = () => {
    return (
      <>
        <ConfirmDialog
          isOpen={isShowPopup.confirmSubmit}
          onClose={() => handleConfirm("confirmSubmit", false)}
          body={confirmMsg}
          onAction={handleSubmit}
          actionType={"confirm"}
        />
      </>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5C6e6c"}>
          {t("title.workingDay")}
        </Heading>
        <RoundedContainer
          sx={{
            p: 5,
          }}
        >
          {_renderSetting()}
          {/* <DaySchedule schedule={workingDay} /> */}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              gap: 2,
            }}
          >
            <Button
              size="sm"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
              onClick={() => handleConfirm("confirmSubmit", true)}
            >
              {t("button.update")}
            </Button>
          </Box>
        </RoundedContainer>
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default WorkingDay;
