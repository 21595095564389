import React, { useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Cards from "./Card";
import {
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { BoardActions, LogtimeActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { performAction: boardPerformActions } = BoardActions;
const { performAction: timeLogPerformActions } = LogtimeActions;

interface ISectionProps {
  column: any;
}

const headingStyles = {
  fontSize: "sm",
  fontWeight: 600,
  textTransform: "uppercase",
  mb: 2,
  maxW: "200px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden !important",
};

const Column: React.FC<ISectionProps> = ({ column }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const details: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const canEditProcess = useMemo(() => {
    const userRoles = _.map(userData?.userRole, (userRole) =>
      _.get(userRole, "role")
    );
    return (
      // details?.project?.type === ENUMS.PROJECT_TYPE.EXTERIOR ||
      Utils.hasPermission(userRoles, "Board", "editProcess")
    );
  }, [userData]);

  const projectType = useMemo(() => _.get(details, "project.type"), [details]);

  const canDeleteProcess = useMemo(() => {
    const userRoles = _.map(userData?.userRole, (userRole) =>
      _.get(userRole, "role")
    );
    return (
      (details?.project?.type === ENUMS.PROJECT_TYPE.EXTERIOR &&
        userData?.id === details?.project?.userCreated?.id) ||
      Utils.hasPermission(userRoles, "Board", "deleteProcess")
    );
  }, [userData]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column?.id, data: { ...column } });

  const dndKitColumnStyles = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
    touchAction: "none",
  };

  const _renderTooltip = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 1,
          maxW: 500,
        }}
      >
        <Text
          fontSize="sm"
          sx={{
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {t("label.processName")}: {column?.name}
        </Text>
        <Text fontSize="sm">
          {t("label.timeline")}:{" "}
          {`${dayjs(column?.startDate).format("DD/MM/YYYY")} - ${dayjs(
            column?.endDate
          ).format("DD/MM/YYYY")} `}
        </Text>
        <Box>
          <Text fontSize="sm">{t("label.description")}:</Text>
          <Text
            fontSize="sm"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {column?.desc || "--"}
          </Text>
        </Box>
        <Text fontSize="sm">
          {t("label.totalFiles")}: {column?.totalFiles || 0}
        </Text>
        <Text fontSize="sm">
          {t("label.totalComments")}: {column?.totalComments || 0}
        </Text>
      </Box>
    );
  };

  const _renderMoreOptions = () => (
    <Popover>
      <PopoverTrigger>
        <IconButton
          isRound
          size="sm"
          icon={<EllipsisHorizontalIcon />}
          aria-label={""}
          sx={{
            bg: "none",
          }}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            maxW: 200,
          }}
        >
          <PopoverArrow />
          <PopoverHeader>
            <Heading size="sm">{t("label.moreOptions")}</Heading>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(boardPerformActions("view", column.id));
                }}
                sx={{
                  color: "#d2a96a",
                  border: "1px solid #d2a96a",
                }}
              >
                {t("button.view")}
              </Button>
              {canEditProcess && (
                <Button
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(boardPerformActions("edit", column.id));
                  }}
                  sx={{
                    color: "#5c6e6c",
                    border: "1px solid #5c6e6c",
                  }}
                >
                  {t("button.edit")}
                </Button>
              )}
              {canDeleteProcess && (
                <Button
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(boardPerformActions("deleteProcess", column.id));
                  }}
                  sx={{
                    color: "rgba( 187, 113, 84,1)",
                    border: "1px solid rgba( 187, 113, 84,1)",
                  }}
                >
                  {t("button.delete")}
                </Button>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  return (
    <Box
      ref={setNodeRef}
      style={dndKitColumnStyles}
      // disabled drag column
      sx={{
        borderRadius: "0.375rem",
        backgroundColor: "gray.300",
        padding: "10px",
        minHeight: "calc(100vh - 270px)",
        mb: 5,
        display: "flex",
        flexDirection: "column",
        w: 250,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
        {...attributes}
        {...listeners}
      >
        <Tooltip hasArrow label={_renderTooltip()}>
          <Box>
            <Heading sx={headingStyles}>{column?.name}</Heading>
          </Box>
        </Tooltip>
        {_renderMoreOptions()}
      </Box>
      <Cards cards={column?.cards || []} />
      <Button
        size="sm"
        w="full"
        sx={{
          mt: "auto",
        }}
        onClick={() =>
          column?.id &&
          dispatch(
            timeLogPerformActions(
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? "logInternal"
                : "logExternal",
              "",
              {
                projectId: details?.project?.id,
                processId: column?.id,
              }
            )
          )
        }
      >
        {t("button.logTime")}
      </Button>
    </Box>
  );
};

export default Column;
