import Dashboard from "./Dashboard";
import Project from "./Project";
import User from "./User";
import Chat from "./Chat";
import TimeCard from "./TimeCard";
import Board from "./Board";
import MyTime from "./MyTime";
import Performance from "./Performance";
import Profile from "./Profile";
import Notification from "./Notification";
import HolidayList from "./Holiday";
import Setting from "./Setting";
import Client from "./Client";
import WorkingTimeList from "./workingTime";
import GetMyIp from "./Setting/GetInternalIp";
import WorkingDay from "./WorkingDay";

export default {
  Dashboard,
  Project,
  User,
  Chat,
  TimeCard,
  Board,
  MyTime,
  Performance,
  Profile,
  Notification,
  HolidayList,
  Setting,
  Client,
  WorkingTimeList,
  GetMyIp,
  WorkingDay,
};
