import { RootState } from "@/Store";
import _ from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import useWindowWidth from "./useWindowWidth";

const useCenteredPopup = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 1279;
  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const openCenteredPopup = useCallback((url: string) => {
    // Get the width and height of the browser window
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Get the position of the browser window on the screen
    const browserX = window.screenX;
    const browserY = window.screenY;

    // Calculate the size of the pop-up window

    const sidebarWidth = isMobile
      ? isSidebarExpanded
        ? 340
        : 25
      : !isSidebarExpanded
      ? 65
      : 270;
    const appbarHeight = 150;
    const popupWidth = windowWidth - sidebarWidth;
    const popupHeight = windowHeight - appbarHeight;
    const left = browserX + windowWidth - popupWidth - (isMobile ? 15 : 0);
    const top = browserY + windowHeight - popupHeight + 150;
    // Open the pop-up window with the calculated size and position
    window.open(
      url,
      "popUpWindow",
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,menubar=no`
    );
  }, []);

  return openCenteredPopup;
};

export default useCenteredPopup;
