import React, { useState, useEffect } from "react";
import _ from "lodash";
import DateTimeRangePicker, {
  DateTimeRangePickerProps,
} from "@wojtekmaj/react-datetimerange-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  type InputProps,
  Stack,
} from "@chakra-ui/react";
import Utils from "@/Utils";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  value?: any;
  onDateChange(newDate: Date[] | string[]): void;
}

const DateTimeRange = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      value,
      onDateChange,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [key, setKey] = useState(Math.random());
    const language = Utils.getSavedLanguage();

    // const [isOpen, setIsOpen] = useState(false);
    const config: DateTimeRangePickerProps = {
      // dayPlaceholder: "DD",
      // monthPlaceholder: "MM",
      // yearPlaceholder: "YYYY",
      // hourPlaceholder: "HH",
      // minutePlaceholder: "mm",
      // locale: "vi",
      onFocus: () => setIsFocus(true),
      onBlur: () => setIsFocus(false),
      value,
      onChange: (value) => onDateChange(value as any),
      clearIcon: false,
    };

    useEffect(() => {
      convertLanguageVietnameseToEnglish();
    }, []);

    useEffect(() => {}, [ref]);

    const convertVietnameseMonthToEnglishString = (dateString: string) => {
      if (!dateString) return "";
      // Array containing names of months
      const monthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Use regular expression to extract month and year
      const match = dateString.match(/tháng (\d+) năm (\d+)/);

      // Check if the match is valid
      if (!match || match.length !== 3) {
        return dateString;
      }

      // Parse the month number
      const monthNumber = parseInt(match[1], 10);

      // Check if the monthNumber is within the range of 1 to 12
      if (monthNumber < 1 || monthNumber > 12 || isNaN(monthNumber)) {
        return dateString;
      }

      // Get the year from the match
      const year = match[2];

      // Return the formatted string in English
      return `${monthsArray[monthNumber - 1]} ${year}`;
    };

    const convertVietnameseDayToEnglish = (dayAbbreviation: string) => {
      const daysMapping = {
        "Th 2": "Mon",
        "Th 3": "Tue",
        "Th 4": "Wed",
        "Th 5": "Thu",
        "Th 6": "Fri",
        "Th 7": "Sat",
        Cn: "Sun",
      };

      return (
        daysMapping[dayAbbreviation as keyof typeof daysMapping] ||
        dayAbbreviation
      );
    };

    const convertVietnameseMonthToEnglish = (monthAbbreviation: string) => {
      const monthsMapping = {
        "Tháng 1": "January",
        "Tháng 2": "February",
        "Tháng 3": "March",
        "Tháng 4": "April",
        "Tháng 5": "May",
        "Tháng 6": "June",
        "Tháng 7": "July",
        "Tháng 8": "August",
        "Tháng 9": "September",
        "Tháng 10": "October",
        "Tháng 11": "November",
        "Tháng 12": "December",
      };

      return (
        monthsMapping[monthAbbreviation as keyof typeof monthsMapping] ||
        monthAbbreviation
      );
    };

    const convertLanguageVietnameseToEnglish = () => {
      const calendarNode = document.querySelector(
        ".react-datetimerange-picker"
      );
      if (calendarNode) {
        const calendarLabelNode = calendarNode.querySelector(
          ".react-calendar__navigation__label"
        );
        if (calendarLabelNode) {
          const dateString = calendarLabelNode.textContent;
          const convertDateString = convertVietnameseMonthToEnglishString(
            dateString || ""
          );

          calendarLabelNode.innerHTML = `<span>${convertDateString}</span>`;
        }
        const calendarWeekDaysNode = calendarNode.querySelectorAll(
          ".react-calendar__month-view__weekdays__weekday"
        );
        if (calendarWeekDaysNode) {
          calendarWeekDaysNode.forEach((dayNode) => {
            const dayAbbreviation = dayNode.textContent?.trim();
            if (dayAbbreviation) {
              const englishDayAbbreviation =
                convertVietnameseDayToEnglish(dayAbbreviation);
              dayNode.textContent = englishDayAbbreviation;
            }
          });
        }
        const calendarYearMonthNode = calendarNode?.querySelectorAll(
          ".react-calendar__year-view__months button"
        );
        if (calendarYearMonthNode) {
          calendarYearMonthNode.forEach((monthNode) => {
            const monthString = monthNode.textContent?.trim();
            if (monthString) {
              const englishMonthString =
                convertVietnameseMonthToEnglish(monthString);
              monthNode.textContent = englishMonthString;
            }
          });
        }
      }
    };

    return (
      <FormControl sx={style} isInvalid={isError} isRequired={props.isRequired}>
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Stack
          sx={{
            fontSize: 14,
            ".react-datetimerange-picker.react-datetimerange-picker--closed.react-datetimerange-picker--enabled":
              {
                height: "100%",
              },
            ".react-datetimerange-picker__wrapper": {
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              // "#E53E3E"
              //       : "#3182ce"
              outline: `2px solid ${
                isFocus ? "#3182ce" : isError ? "#E53E3E" : "transparent"
              }`,
              outlineOffset: "-2px",
            },
            ".react-datetimerange-picker__inputGroup": {
              height: "100%",
              px: "12px",
              input: {
                outline: "none",
              },
            },
            //hidden clean icon
            ".react-datetimerange-picker__clock, .react-datetimerange-picker__clear-button":
              {
                display: "none",
              },
          }}
        >
          <DateTimeRangePicker
            locale={language === "en" ? "en" : "vi"}
            key={key}
            onCalendarClose={() => setKey(Math.random())}
            // onCalendarOpen={() => setIsOpen(!isOpen)}
            // onCalendarClose={() => setIsOpen(!isOpen)}
            // onViewChange={() => setIsOpen(!isOpen)}
            // onClickMonth={() => setIsOpen(!isOpen)}
            // onClickYear={() => setIsOpen(!isOpen)}
            // onActiveStartDateChange={() => setIsOpen(!isOpen)}
            {...config}
          />
        </Stack>
        {!_.isEmpty(errorMessage) ? (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        ) : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default DateTimeRange;
