import { useEffect, useRef } from "react";

import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button } from "@/Components/Common";
import _ from "lodash";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { WorkingTimeActions } from "@/Actions";
import TimeField from "react-simple-timefield";
import { useTranslation } from "react-multi-lang";

const { updateWorkingTime } = WorkingTimeActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: any;
}

const DEFAULT_FORM_DATA = {
  morningStart: "",
  morningEnd: "",
  afternoonStart: "",
  afternoonEnd: "",
};

const UpdateWorkingTime: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "isActionLoading")
  );
  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "isUpdateSuccess")
  );

  const schema = yup
    .object()
    .shape({
      morningStart: yup.string().required(t("message.morningStartIsRequired")),
      morningEnd: yup.string().required(t("message.morningEndIsRequired")),
      afternoonStart: yup
        .string()
        .required(t("message.afternoonStartIsRequired")),
      afternoonEnd: yup.string().required(t("message.afternoonEndIsRequired")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const convertToDecimalTime = (timeString: any) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + seconds / 60;

    return totalMinutes / 60;
  };

  const onSubmit = (data: any) => {
    if (payload?.id)
      dispatch(
        updateWorkingTime(payload?.id, {
          morningStart: convertToDecimalTime(data.morningStart),
          morningEnd: convertToDecimalTime(data.morningEnd),
          afternoonStart: convertToDecimalTime(data.afternoonStart),
          afternoonEnd: convertToDecimalTime(data.afternoonEnd),
        })
      );
  };

  const convertToTime = (decimalTime: any) => {
    const hours = Math.floor(decimalTime);
    const decimalMinutes = (decimalTime - hours) * 60;
    const minutes = Math.round(decimalMinutes);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:00`;
  };

  useEffect(() => {
    if (!open || isUpdateSuccess) {
      onClose();
    }
  }, [open, isUpdateSuccess]);

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      setValue("morningStart", convertToTime(payload?.morningStart));
      setValue("morningEnd", convertToTime(payload?.morningEnd));
      setValue("afternoonStart", convertToTime(payload?.afternoonStart));
      setValue("afternoonEnd", convertToTime(payload?.afternoonEnd));
    }
  }, [payload]);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          sx={{
            maxH: "full",
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 1px 2px -1px gray",
              borderRadius: "15px 15px 0 0",
            }}
          >
            {t("title.updateWorkingTime")}
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
            }}
          >
            <Box mt={2}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <Text size={"sm"} fontWeight={700}>
                  {t("label.morningStart")}
                </Text>
                <Text
                  size={"sm"}
                  fontWeight={700}
                  color={"red"}
                  sx={{ ml: "2px !important" }}
                >
                  *
                </Text>
              </Box>
              <Controller
                name="morningStart"
                control={control}
                render={({ field }) => (
                  <TimeField
                    //format={"00:00:00"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    showSeconds
                    input={<TextField size="sm" />}
                  />
                )}
              />
              {errors?.morningStart?.message ? (
                <FormErrorMessage>
                  {errors?.morningStart?.message}
                </FormErrorMessage>
              ) : null}
            </Box>
            <Box mt={2}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <Text size={"sm"} fontWeight={700}>
                  {t("label.morningEnd")}
                </Text>
                <Text
                  size={"sm"}
                  fontWeight={700}
                  color={"red"}
                  sx={{ ml: "2px !important" }}
                >
                  *
                </Text>
              </Box>
              <Controller
                name="morningEnd"
                control={control}
                render={({ field }) => (
                  <TimeField
                    //format={"00:00:00"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    showSeconds
                    input={<TextField size="sm" />}
                  />
                )}
              />
              {errors?.morningEnd?.message ? (
                <FormErrorMessage>
                  {errors?.morningEnd?.message}
                </FormErrorMessage>
              ) : null}
            </Box>
            <Box mt={2}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <Text size={"sm"} fontWeight={700}>
                  {t("label.afternoonStart")}
                </Text>
                <Text
                  size={"sm"}
                  fontWeight={700}
                  color={"red"}
                  sx={{ ml: "2px !important" }}
                >
                  *
                </Text>
              </Box>
              <Controller
                name="afternoonStart"
                control={control}
                render={({ field }) => (
                  <TimeField
                    //format={"00:00:00"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    showSeconds
                    input={<TextField size="sm" />}
                  />
                )}
              />
              {errors?.afternoonStart?.message ? (
                <FormErrorMessage>
                  {errors?.afternoonStart?.message}
                </FormErrorMessage>
              ) : null}
            </Box>
            <Box mt={2}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <Text size={"sm"} fontWeight={700}>
                  {t("label.afternoonEnd")}
                </Text>
                <Text
                  size={"sm"}
                  fontWeight={700}
                  color={"red"}
                  sx={{ ml: "2px !important" }}
                >
                  *
                </Text>
              </Box>
              <Controller
                name="afternoonEnd"
                control={control}
                render={({ field }) => (
                  <TimeField
                    //format={"00:00:00"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    showSeconds
                    input={<TextField size="sm" />}
                  />
                )}
              />
              {errors?.afternoonEnd?.message ? (
                <FormErrorMessage>
                  {errors?.afternoonEnd?.message}
                </FormErrorMessage>
              ) : null}
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              boxShadow: "0px 0px 2px gray",
              borderRadius: "0 0 15px 15px",
            }}
          >
            <Button
              size={"sm"}
              onClick={() => onClose()}
              isLoading={isActionLoading}
              w={75}
            >
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              isLoading={isActionLoading}
              w={75}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default UpdateWorkingTime;
