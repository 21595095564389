import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Checkbox,
  Avatar,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  IconButton,
  Tooltip,
  HStack,
  Button,
  DrawerFooter,
} from "@chakra-ui/react";

import { XMarkIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ConfirmDialog } from "@/Components/Popup";
import { RootState, useTypedDispatch } from "@/Store";
import { ChatActions } from "@/Actions";
import Utils from "@/Utils";
import { IUser } from "@/Interfaces/User.interface";
import { IChatFilters, IChatPerformAction } from "@/Interfaces/Chat.interface";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  open: boolean;
  onClose(): void;
}

const { addMemberToGroup, removeMemberToGroup } = ChatActions;

const DEFAULT_FORM_DATA = {
  member: [],
};

const Member: React.FC<ISectionProps> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const userLogged = Utils.getSavedUserData();
  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "userList")
  );
  const t = useTranslation();

  const chatPerformAction: IChatPerformAction = useSelector(
    (state: RootState) => _.get(state.CHAT, "performAction")
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.CHAT, "isActionLoading")
  );
  const groupChats: any[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "groupChat")
  );
  const messageDetailsPagination: IChatFilters = useSelector(
    (state: RootState) => _.get(state.CHAT, "messageDetailsPagination")
  );
  const isAddMemberToGroupSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isAddMemberToGroupSuccess")
  );
  const isRemoveMemberToGroupSuccess: boolean = useSelector(
    (state: RootState) => _.get(state.CHAT, "isRemoveMemberToGroupSuccess")
  );

  const membersWithUsers = useMemo(() => {
    const members = _.get(
      _.find(
        groupChats,
        (groupInfo) => groupInfo?.channel?.id === chatPerformAction?.chatId
      ),
      "channel.messageChannelMember"
    );

    const membersWithUsers = _.filter(members, (member) => member.user);

    return membersWithUsers;
  }, [chatPerformAction?.chatId, groupChats]);

  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [isShowAddNew, setIsShowAddNew] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const schema = yup
    .object()
    .shape({
      member: yup
        .array()
        .of(yup.string())
        .min(1)
        .required(t("message.memberIsaRequiredDield")),
    })
    .required();

  const {
    setValue,
    formState: { errors },
    clearErrors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    generateUserList();
  }, [users, membersWithUsers, open]);

  useEffect(() => {
    setValue("member", selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (!open) {
      reset();
      setIsShowAddNew(false);
      setSelectedValues([]);
      setSearchValue("");
      setFilteredUsers([]);
    }
  }, [open]);

  useEffect(() => {
    if (isAddMemberToGroupSuccess || isRemoveMemberToGroupSuccess) {
      reset();
      setIsShowAddNew(false);
      setSelectedValues([]);
      setSearchValue("");
    }
  }, [isAddMemberToGroupSuccess, isRemoveMemberToGroupSuccess]);

  const generateUserList = () => {
    if (Array.isArray(users)) {
      const resolveFilter = _.filter(
        [...users, userLogged],
        (user) =>
          !_.some(membersWithUsers, (member) => user.id === member?.user?.id)
      );
      setFilteredUsers(resolveFilter);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    const filtered = !_.isEmpty(value)
      ? _.filter(
          filteredUsers,
          (user) => user.email.includes(value) || _.includes(user.name, value)
        )
      : _.filter(
          [...users, userLogged],
          (user) =>
            !_.some(membersWithUsers, (member) => user.id === member?.user?.id)
        );
    setFilteredUsers(filtered);
  };

  const handleCheckboxChange = (e: any) => {
    clearErrors();
    const checkboxValue = e.target.value;
    const isChecked = e.target.checked;
    setSelectedValues((prevValues: any) => {
      if (isChecked) {
        return [...prevValues, checkboxValue];
      } else {
        return _.filter(prevValues, (value) => value !== checkboxValue);
      }
    });
  };

  const handleReset = (event: any) => {
    event.stopPropagation();
    setSearchValue("");
    setFilteredUsers(
      _.filter(
        [...users, userLogged],
        (user) =>
          !_.some(membersWithUsers, (member) => user.id === member?.user?.id)
      )
    );
  };

  const handleAddMember = (data: any) => {
    const formData = new FormData();
    _.forEach(data?.member, (member) => formData.append("addMember[]", member));
    dispatch(
      addMemberToGroup(
        chatPerformAction?.chatId,
        formData,
        messageDetailsPagination
      )
    );
  };

  return (
    <>
      <Drawer isOpen={open} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            sx={{
              borderBottom: "1px solid #ddd",
              p: 4,
            }}
          >
            <Heading fontSize="lg">
              {isShowAddNew ? t("title.addNewMember") : t("title.groupMembers")}
            </Heading>
          </DrawerHeader>

          <DrawerBody
            sx={{
              p: 4,
            }}
          >
            {isShowAddNew ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  mt: 3,
                }}
              >
                <HStack>
                  <Heading fontSize="sm">{t("label.availableUsers")}</Heading>
                  {!isShowAddNew && (
                    <Button
                      size="xs"
                      ml="auto"
                      onClick={() => setIsShowAddNew(true)}
                    >
                      {t("button.addNewMembers")}
                    </Button>
                  )}
                </HStack>
                <InputGroup>
                  <Input
                    value={searchValue}
                    onChange={handleSearchChange}
                    size="sm"
                    placeholder={t("label.searchUserByNameEmail")}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                  <InputRightElement
                    sx={{
                      height: "100%",
                    }}
                  >
                    {searchValue.length > 0 && (
                      <Tooltip label={t("tooltip.remove")} hasArrow>
                        <IconButton
                          isRound
                          sx={{
                            background: "none",
                          }}
                          size="sm"
                          icon={<Icon as={XMarkIcon} />}
                          aria-label={""}
                          onClick={handleReset}
                          isDisabled={isActionLoading}
                        />
                      </Tooltip>
                    )}
                  </InputRightElement>
                </InputGroup>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {!_.isEmpty(filteredUsers) ? (
                    _.map(filteredUsers, (user, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            size="sm"
                            name={user?.userData?.fullName}
                            src={user?.userData?.avatar?.path}
                          />
                          <Box>
                            <Text fontSize="sm" fontWeight="600">
                              {user?.userData?.fullName}
                            </Text>
                          </Box>
                          <Checkbox
                            sx={{ ml: "auto" }}
                            isChecked={_.some(
                              selectedValues,
                              (userId) => userId === user?.id
                            )}
                            value={user?.id}
                            onChange={handleCheckboxChange}
                          />
                        </Box>
                      );
                    })
                  ) : (
                    <Text fontSize="sm"> {t("message.noDataWereFound")}</Text>
                  )}
                </Box>
                <Text fontSize="sm" color="error">
                  {!_.isEmpty(errors) &&
                    t("message.pleaseSelectAtLeastOneUser")}
                </Text>
              </Box>
            ) : (
              <Box>
                <HStack>
                  <Heading fontSize="sm">
                    {t("label.members")} ({_.size(membersWithUsers)})
                  </Heading>
                  {!isShowAddNew && (
                    <Button
                      size="xs"
                      ml="auto"
                      onClick={() => setIsShowAddNew(true)}
                    >
                      {t("button.addNewMembers")}
                    </Button>
                  )}
                </HStack>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {!_.isEmpty(membersWithUsers) ? (
                    _.map(membersWithUsers, (userInfo, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              size="sm"
                              name={userInfo?.user?.userData?.fullName}
                              src={userInfo?.user?.userData?.avatar?.path}
                            />
                            <Box>
                              <Text fontSize="sm" fontWeight="600">
                                {userInfo?.user?.userData?.fullName}
                              </Text>
                            </Box>
                          </Box>
                          {userLogged?.id !== userInfo?.user?.id && (
                            <IconButton
                              isRound
                              sx={{
                                background: "none",
                              }}
                              size="sm"
                              icon={<Icon as={TrashIcon} />}
                              color="red"
                              aria-label={""}
                              onClick={() =>
                                setSelectedUserId(userInfo?.user?.id)
                              }
                              isDisabled={isActionLoading}
                            />
                          )}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
                  )}
                </Box>
              </Box>
            )}
          </DrawerBody>
          {isShowAddNew && (
            <DrawerFooter
              sx={{
                p: 3,
                display: "flex",
                gap: 3,
              }}
            >
              <Button size="sm" onClick={() => setIsShowAddNew(false)}>
                {t("button.cancel")}
              </Button>
              <Button
                size="sm"
                colorScheme="twitter"
                onClick={handleSubmit(handleAddMember)}
              >
                {t("button.save")}
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
      <ConfirmDialog
        title={t("title.removeMember")}
        body={t("message.areYouSureYouWantToRemoveThisMemberInGroup")}
        isOpen={!!selectedUserId}
        onClose={() => setSelectedUserId("")}
        onAction={() => {
          const formData = new FormData();
          formData.append("removeMember", selectedUserId);
          dispatch(
            removeMemberToGroup(
              chatPerformAction?.chatId,
              formData,
              messageDetailsPagination
            )
          );
          setSelectedUserId("");
        }}
        actionType="confirm"
      />
    </>
  );
};

export default Member;
