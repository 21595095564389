import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Utils from "@/Utils";

// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_API_KEY,
//   authDomain: import.meta.env.VITE_AUTH_DOMAIN,
//   projectId: import.meta.env.VITE_PROJECT_ID,
//   storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
//   messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
//   appId: import.meta.env.VITE_APP_ID,
//   measurementId: import.meta.env.VITE_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyDWw9Z6Fzb0IXWKHL3s7tV1C1IHr9ryuD8",
  authDomain: "cav-workspace.firebaseapp.com",
  projectId: "cav-workspace",
  storageBucket: "cav-workspace.appspot.com",
  messagingSenderId: "759087416011",
  appId: "1:759087416011:web:a5fb80e85e58365854514e",
  measurementId: "G-DQFSEDY61S",
};

const VAPID_KEY =
  "BDNBksPuBGyvMOSeRhTAsgMk3FClkdYNpU0BD-Is8fUfsrzuaTQV5mYzNAATjzEPbKs3So0maGVdkXYafVsmwuk";

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    getToken(messaging, { vapidKey: VAPID_KEY })
      .then((currentToken) => {
        Utils.saveFirebaseToken(currentToken);
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
};
