import React from "react";
import isEmpty from "lodash/isEmpty";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Box, FormLabel, FormErrorMessage } from "@chakra-ui/react";

interface ISectionProps {
  label: string;
  labelSx?: object;
  containerSx?: object;
  value: string;
  onChange?(newValue: string): void;
  message?: string;
  placeholder?: string;
  direction?: "column" | "row";
  allowCopy?: boolean;
  required?: boolean;
  disabled?: boolean;
  height?: number;
}

const TextEditor: React.FC<ISectionProps> = ({
  label,
  containerSx,
  value,
  onChange,
  message = "",
  placeholder = "",
  direction = "column",
  disabled = false,
  height = 200,
}) => {
  const handleEditorChange = (
    content: any,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    let resolveValue = content;
    if (
      editor
        .getText()
        .replace(/<(.|\n)*?>/g, "")
        .trim().length === 0
    ) {
      resolveValue = "";
    } else resolveValue = content;

    if (onChange && !disabled) onChange(resolveValue);
  };

  //  const modules = {
  //    toolbar: [
  //      [{ header: [1, 2, false] }],
  //      ["bold", "italic", "underline", "strike"],
  //      [
  //        { list: "ordered" },
  //        { list: "bullet" },
  //        //{ indent: "-1" },
  //        //{ indent: "+1" },
  //      ],
  //      ["link"],
  //      //  [{ color: [] }, { background: [] }],
  //      [{ font: [] }, { size: [] }], // Thêm tùy chọn font và size vào toolbar
  //    ],
  //  };

  //  const formats = [
  //    "header",
  //    "bold",
  //    "italic",
  //    "underline",
  //    "strike",
  //    "list",
  //    "link",
  //    "font",
  //    "size",
  //  ];

  return (
    <Box
      className={!isEmpty(message) ? "required" : ""}
      sx={{
        display: "flex",
        flexDirection: direction,
        ...containerSx,
        ".quill": {
          minHeight: `${height}px`,
          boxSizing: "border-box",
          //resolve hover
          "&:hover .ql-toolbar.ql-snow, &:hover .ql-container.ql-snow": {
            borderColor: message ? "#d32f2f" : "black",
          },
          //resolve focus
          "&:focus-within .ql-toolbar.ql-snow": {
            outline: `2px solid ${message ? "#d32f2f" : "#3498db"}  `, // primary.main
            outlineOffset: "-2px",
          },
          "&:focus-within .ql-container.ql-snow": {
            outline: "none",
            borderWidth: "2px",
            borderColor: message ? "#d32f2f" : "#3498db",
          },
        },
        ".ql-toolbar.ql-snow": {
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
        ".ql-picker": {
          color: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
        },
        ".ql-stroke": {
          stroke: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
        },
        ".ql-container.ql-snow": {
          height: `${height}px`,
          position: "sticky",
          top: "0",
          width: "100%",
          wordBreak: "break-word",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
        ".ql-snow .ql-tooltip.ql-flip": {
          top: "0px!important",
          left: "0px!important",
        },
        ".ql-editor ol, .ql-editor ul": {
          padding: 0,
        },
      }}
    >
      {label ? (
        <FormLabel fontSize="14" fontWeight={700}>
          {label}
        </FormLabel>
      ) : null}
      <Box
        sx={{
          pointerEvents: disabled ? "none" : "unset",
          color: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
          flex: 1,
          "& li": {
            lineHeight: "20px",
          },
          "& p": {
            lineHeight: "20px",
          },
        }}
      >
        <ReactQuill
          className="default-font-size"
          //  modules={modules}
          //  formats={formats}
          style={{ flex: 1 }}
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          onKeyDown={(e: any) => disabled && e.preventDefault()}
          placeholder={placeholder}
        />
        {message && (
          <FormErrorMessage
            ml={2}
            mt={0.5}
            sx={{ fontSize: "13px" }}
            color="error"
          >
            {message}
          </FormErrorMessage>
        )}
      </Box>
    </Box>
  );
};

export default TextEditor;
