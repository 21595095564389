import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  type InputProps,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  leftElement?: JSX.Element;
  rightElement?: JSX.Element;
  onEnter?(): void;
  labelSx?: object;
  inputSx?: object;
  hoverInputSx?: object;
  isPhoneInput?: boolean;
  disablePhoneInput?: boolean;
  onPhoneInputChange?: (number: any) => void;
  phoneInputvalue?: string;
  disabled?: boolean;
  moreInfo?: string;
  type?: string;
}

const TextField = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      leftElement,
      rightElement,
      onEnter,
      labelSx,
      inputSx,
      hoverInputSx,
      isPhoneInput = false,
      disablePhoneInput = false,
      onPhoneInputChange,
      phoneInputvalue = "",
      disabled = false,
      moreInfo,
      type = "text",
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        isInvalid={isError}
        isRequired={props.isRequired}
        style={style}
      >
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}{" "}
            {moreInfo && (
              <Tooltip label={moreInfo} hasArrow>
                <Icon as={QuestionMarkCircleIcon} sx={{ cursor: "pointer" }} />
              </Tooltip>
            )}
          </FormLabel>
        ) : null}
        {isPhoneInput ? (
          <PhoneInput
            country="vn"
            value={phoneInputvalue}
            onChange={(number) =>
              onPhoneInputChange && onPhoneInputChange(number)
            }
            disabled={disablePhoneInput}
            inputStyle={{
              borderRadius: "8px",
              width: "100%",
              height: "32px",
              fontSize: "14px",
              opacity: disablePhoneInput ? 0.4 : 1,
              // borderColor: disablePhoneInput ? "rgb(226, 232, 240)" : "inherit",
              fontFamily: "Pretendard",
              // "&:hover": {
              //   cursor: disablePhoneInput ? "not-allowed" : "normal",
              // },
            }}
          />
        ) : (
          <InputGroup>
            {leftElement ? (
              <InputLeftElement
                width="max-content"
                height="full"
                padding="0 10px"
              >
                {leftElement}
              </InputLeftElement>
            ) : null}
            <Input
              disabled={disabled}
              type={type}
              fontSize={14}
              borderRadius={5}
              width="100%"
              boxSizing="border-box"
              ref={ref}
              onKeyDown={(event) => {
                if (event.key === "Enter" && onEnter) onEnter();
              }}
              {...props}
              sx={{ ...inputSx, borderRadius: "8px" }}
              _hover={{ ...hoverInputSx }}
            />
            {rightElement ? (
              <InputRightElement
                width="max-content"
                height="full"
                padding="0 10px"
              >
                {rightElement}
              </InputRightElement>
            ) : null}
          </InputGroup>
        )}

        {isError ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
