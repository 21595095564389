import React from "react";
import {
  mainStyles,
  labelStyles,
  desktopStyles,
  guideResizeStyles,
  rotateIcon,
} from "./FullHDWarning.styles";
import Utils from "@/Utils";
import { Box } from "@chakra-ui/react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";

interface IProps {
  onAction?: (value: boolean) => void;
}

const FullHDWarning: React.FC<IProps> = ({ onAction }) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const t = useTranslation();
  const deviceType = Utils.getDeviceType();
  const resize = () => {
    const currentRatio = window.innerWidth / window.innerHeight;
    const fullHDRatio = 1;
    setIsVisible(currentRatio < fullHDRatio);
  };

  React.useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
      const timeout = setTimeout(() => {
        //setIsVisible(false);
        if (onAction) {
          onAction(false);
        }
      }, 5000);
      return () => {
        window.removeEventListener("resize", resize);
        clearTimeout(timeout);
      };
    } else document.body.classList.remove("overflow-hidden");
    return () => {};
  }, [isVisible]);

  if (!isVisible) return null;

  const _renderGuideResize = () => {
    return (
      <Box sx={guideResizeStyles}>
        {deviceType === "desktop" && <Box sx={desktopStyles} />}
      </Box>
    );
  };

  return (
    <Box sx={mainStyles}>
      {_renderGuideResize()}
      <Box sx={{ ...labelStyles, p: 2 }}>
        {t("message.pleaseRotateTheScreenHorizontallyForFullScreenViewing")}
        <Box sx={rotateIcon}>
          <DevicePhoneMobileIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default FullHDWarning;
