import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  Box,
  Heading,
  Checkbox,
} from "@chakra-ui/react";
import { XMarkIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { ENUMS } from "@/Constants";
import { ProjectActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";
import Utils from "@/Utils";
import { useSelector } from "react-redux";
import { Button } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const { changeProjectStatus, getProjectById } = ProjectActions;
const { PROJECT_STATUS } = ENUMS;

interface SectionProps {
  open: boolean;
  onClose(): void;
  currentValue?: string;
  projectId: string;
}

const ChangeProjectStatusDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  currentValue,
  projectId,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const cancelRef = useRef<any>(null);
  const userData = Utils.getSavedUserData();

  const userRole = React.useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isChangeStatusSuccess")
  );

  const [selectedStatus, setSelectedStatus] = useState<string>("");

  const projectOptions = _.map(PROJECT_STATUS, (status) => ({
    label: _.startCase(status),
    value: status,
  }));

  useEffect(() => {
    if (currentValue) setSelectedStatus(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      onClose();
      dispatch(getProjectById(projectId));
    }
  }, [isChangeStatusSuccess, projectId]);

  const handleCheckboxChange = (value: string) => setSelectedStatus(value);

  const handleSubmit = () => {
    dispatch(changeProjectStatus(projectId, selectedStatus));
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ".chakra-checkbox__control": {
            borderRadius: "50%",
          },
        }}
      >
        {_.map(projectOptions, (option) => (
          <Checkbox
            key={option.value}
            isChecked={selectedStatus === option.value}
            onChange={() => handleCheckboxChange(option.value)}
            size="lg"
            colorScheme="teal"
            rounded={9999}
            disabled={
              !Utils.handleCheckCanDragAndModifyState(
                userRole,
                currentValue || "",
                option.value
              )
            }
          >
            <Heading fontSize="sm">
              {_.startCase(t(`status.${option.value}`))}
            </Heading>
          </Checkbox>
        ))}
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          {t("title.changeProjectStatus")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            mx: "auto",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={onClose}
            size="sm"
            leftIcon={<Icon as={XMarkIcon} />}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            ml={3}
            //colorScheme="whatsapp"
            size="sm"
            leftIcon={<Icon as={CheckCircleIcon} />}
            isDisabled={!selectedStatus}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.confirm")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChangeProjectStatusDialog;
