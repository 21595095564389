import React, { useEffect, useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";

import { useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
  Textarea,
  Avatar,
  Icon,
} from "@chakra-ui/react";

import { Button, TextField, TextComponent } from "@/Components/Common";
import { RootState, useTypedDispatch } from "@/Store";
import { PerformanceActions } from "@/Actions";
import Utils from "@/Utils";

import {
  IPerformAction,
  IPerformanceFilter,
} from "@/Interfaces/Performance.interface";
import { PROJECT_STATUS_COLOR } from "@/Constants/Enums.contant";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";

const { createPerformance, getPerformanceById } = PerformanceActions;

const DEFAULT_PERFORMANCE_FORM = {
  amountOfWork: null,
  workContent: "",
  note: "",
  performance: null,
};

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const PerformanceDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const performAction: IPerformAction = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "performAction")
  );

  const details: any = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "details")
  );

  const pagination: IPerformanceFilter = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "pagination")
  );
  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "isCreateSuccess")
  );

  const userData = Utils.getSavedUserData();
  const canEditAmountOfWork = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Performance",
    "editAmountOfWork"
  );

  const cancelRef = useRef<any>(null);

  const schema = yup
    .object()
    .shape({
      amountOfWork: yup
        .number()
        .nullable()
        .min(0, t("message.amountOfWorkMustBeAtLeast"))
        .max(100, t("message.amountOfWorkMustBeAtMost"))
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .notRequired(),
      workContent: yup.string().notRequired(),
      performance: yup
        .number()
        .nullable()
        .min(0, t("message.performanceMustBeAtLeast"))
        .max(100, t("message.performanceMustBeAtMost"))
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .notRequired(),
      note: yup.string().notRequired(),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_PERFORMANCE_FORM,
  });

  useEffect(() => {
    if (!_.isEmpty(performAction?.itemId))
      dispatch(getPerformanceById(performAction?.itemId));
    return () => {
      onReset();
    };
  }, [performAction]);

  useEffect(() => {
    setValue("performance", details?.performance || null);
    // setValue(
    //   "workContent",
    //   details?.workContent || performAction?.performance?.workContent || ""
    // );
    setValue(
      "amountOfWork",
      details?.amountOfWork || performAction?.performance?.amountOfWork || null
    );
    setValue("note", details?.note || "");
    return () => {};
  }, [details, performAction]);

  useEffect(() => {
    if (isCreateSuccess) {
      reset();
      onClose();
    }
  }, [isCreateSuccess]);

  const checkPerformanceLogChanges = (previousLog: any, currentLog: any) => {
    const logChanges: any = { id: currentLog.id, changes: {} };
    // Check for changes in amountOfWork
    // if (
    //   previousLog.amountOfWork &&
    //   previousLog.amountOfWork !== currentLog.amountOfWork
    // ) {
    //   logChanges.changes.amountOfWork = {
    //     previous: previousLog.amountOfWork,
    //     current: currentLog.amountOfWork,
    //   };
    // }

    logChanges.changes.amountOfWork = {
      previous: previousLog.amountOfWork,
      current: currentLog.amountOfWork,
    };

    // Check for changes in note
    // if (previousLog.note && previousLog.note !== currentLog.note) {
    //   logChanges.changes.note = {
    //     previous: previousLog.note,
    //     current: currentLog.note,
    //   };
    // }

    logChanges.changes.note = {
      previous: previousLog.note,
      current: currentLog.note,
    };

    // Check for changes in performance
    // if (
    //   previousLog.performance > 0 &&
    //   previousLog.performance !== currentLog.performance
    // ) {
    //   logChanges.changes.performance = {
    //     previous: previousLog.performance,
    //     current: currentLog.performance,
    //   };
    // }

    logChanges.changes.performance = {
      previous: previousLog.performance,
      current: currentLog.performance,
    };
    return Object.keys(logChanges.changes).length > 0 ? logChanges : null;
  };

  const onReset = () => reset();

  const onSubmit = (data: FormData) => {
    const resolvedPayload = {
      ...data,
      project: performAction?.performance?.project?.id,
      user: performAction?.performance?.member?.user?.id,
      performance: data.performance,
      amountOfWork: data.amountOfWork,
    };
    dispatch(createPerformance(resolvedPayload, pagination));
  };

  const _renderHistoryReview = () => {
    return (
      <Accordion
        defaultIndex={_.size(details?.performanceEvaluationLog) - 1}
        allowToggle
      >
        {!_.isEmpty(details?.performanceEvaluationLog)
          ? _.map(
              Utils.sortByProperty(
                details?.performanceEvaluationLog || [],
                "createdAt"
              ),
              (log, index) => {
                const logChanges = checkPerformanceLogChanges(log, details);
                if (!_.isEmpty(logChanges)) {
                  const changesKeys = Object.keys(logChanges?.changes);
                  const changesSize =
                    !canEditAmountOfWork && changesKeys.includes("amountOfWork")
                      ? changesKeys.length - 1
                      : changesKeys.length;
                  if (
                    changesSize === 1 &&
                    _.some(
                      logChanges?.changes,
                      (logChange) => logChange === "amountOfWork"
                    )
                  )
                    return;
                }

                return (
                  <AccordionItem key={index}>
                    <AccordionButton
                      sx={{
                        px: 2,
                      }}
                    >
                      <Text fontSize="sm" textAlign="left" flex={1}>
                        {dayjs(log?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",

                            w: "max-content",
                          }}
                        >
                          <Avatar
                            size="xs"
                            src={log?.user?.userData?.avatar?.path}
                            name={log?.user?.userData?.fullName}
                          />
                          <Text fontSize="sm">
                            {log?.user?.userData?.fullName}
                          </Text>
                        </Box>
                        <Text
                          color={
                            PROJECT_STATUS_COLOR[
                              log?.status as keyof typeof PROJECT_STATUS_COLOR
                            ].text
                          }
                          bgColor={
                            PROJECT_STATUS_COLOR[
                              log?.status as keyof typeof PROJECT_STATUS_COLOR
                            ].background
                          }
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 700,
                            borderRadius: "20px",
                            px: 2,
                            py: 1,
                            fontSize: "12px",
                            w: "max-content",
                          }}
                        >
                          {t(`status.${log?.status}`)}
                        </Text>
                      </Box>

                      <Box sx={{ ml: 2 }}>
                        {!_.isEmpty(logChanges?.changes) ? (
                          _.map(
                            _.keys(logChanges?.changes),
                            (keyLog, logChangeIndex) => {
                              if (
                                !canEditAmountOfWork &&
                                keyLog === "amountOfWork"
                              )
                                return;
                              return (
                                <Text key={logChangeIndex} fontSize="xs">
                                  +
                                  {/* {` Field "${_.startCase(
                                    keyLog
                                  )}" has changed from "${
                                    logChanges?.changes[keyLog]?.previous
                                  }" to "${
                                    logChanges?.changes[keyLog]?.current
                                  }".`} */}
                                  {` Field "${_.startCase(keyLog)}": ${
                                    logChanges?.changes[keyLog]?.previous ||
                                    "--"
                                  }`}
                                </Text>
                              );
                            }
                          )
                        ) : (
                          <Text fontSize="xs">
                            {t("message.nothingChanges")}
                          </Text>
                        )}
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                );
              }
            )
          : "--"}
      </Accordion>
    );
  };

  const _renderHistoryChange = () => {
    return (
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        finalFocusRef={cancelRef}
        onClose={onClose}
        isCentered
        size="md"
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
            {performAction?.actionType === "update"
              ? t("title.performanceEvaluation")
              : t("title.history")}
          </AlertDialogHeader>
          <AlertDialogCloseButton rounded="full" />
          <AlertDialogBody>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <Text fontSize={14} fontWeight={700}>
                  {t("label.project")}
                </Text>
                <Text fontSize="sm">
                  {performAction?.performance?.project?.name}
                </Text>
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={700}>
                  {t("label.members")}
                </Text>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",

                    w: "max-content",
                  }}
                >
                  <Avatar
                    size="xs"
                    src={
                      performAction?.performance?.member?.user?.userData?.avatar
                        ?.path
                    }
                    name={
                      performAction?.performance?.member?.user?.userData
                        ?.fullName
                    }
                  />
                  <Text fontSize="sm">
                    {
                      performAction?.performance?.member?.user?.userData
                        ?.fullName
                    }
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Text fontSize={14} fontWeight={700}>
                  {t("label.historyReview")}
                </Text>
                {_renderHistoryReview()}
              </Box>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  const _renderPerformanceForm = () => {
    return (
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        finalFocusRef={cancelRef}
        onClose={onClose}
        isCentered
        size="sm"
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              height: "max-content",
            }}
          >
            {performAction?.actionType === "update"
              ? t("title.performanceEvaluation")
              : t("title.history")}
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
              }}
            >
              <TextField
                label={t("label.project")}
                placeholder={t("label.project")}
                value={performAction?.performance?.project?.name}
                size="sm"
                isReadOnly
              />
              <TextField
                label={t("label.member")}
                placeholder={t("label.member")}
                value={
                  performAction?.performance?.member?.user?.userData?.fullName
                }
                size="sm"
                isReadOnly
              />
              <Controller
                control={control}
                name="performance"
                render={({ field }) => (
                  <TextField
                    type="number"
                    min={0}
                    max={100}
                    value={field.value as number}
                    onChange={(e) => field.onChange(e.target.value)}
                    // onFocus={(e) =>
                    //   (!e.target.value || e.target.value === "0") &&
                    //   field.onChange("")
                    // }
                    // onBlur={(e) =>
                    //   (!e.target.value || e.target.value === "0") &&
                    //   field.onChange("0")
                    // }
                    size="sm"
                    label={`${t("label.performance")}(%)`}
                    placeholder={t("label.performance")}
                    isError={!!errors?.performance?.message}
                    errorMessage={_.capitalize(errors?.performance?.message)}
                  />
                )}
              />
              <Box
                sx={{
                  w: "full",
                }}
              >
                <Heading fontSize="sm" sx={{ mb: 2 }}>
                  {t("label.workContent")}
                </Heading>
                <Box>
                  {!_.isEmpty(performAction?.performance?.userLogged) ? (
                    _.map(performAction?.performance?.userLogged, (userLog) => {
                      const firstTextContent = Utils.getFirstTextContent(
                        userLog?.description
                      );
                      return (
                        <Box
                          key={userLog?.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Icon>
                            <PlusIcon />
                          </Icon>
                          <TextComponent
                            key={userLog?.id}
                            content={firstTextContent || "--"}
                            maxW="100%"
                            sx={{
                              display: "block",
                            }}
                          />
                        </Box>
                      );
                    })
                  ) : (
                    <>--</>
                  )}
                </Box>
              </Box>
              {canEditAmountOfWork && (
                <Controller
                  control={control}
                  name="amountOfWork"
                  render={({ field }) => (
                    <Box
                      sx={{
                        w: "full",
                      }}
                    >
                      {/* <Heading fontSize="sm" sx={{ mb: 2 }}>
                        {t("label.amountOfWork")}
                      </Heading> */}
                      {/* <Textarea
                        rows={2}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value)}
                        size="sm"
                        placeholder={t("label.amountOfWork")}
                      /> */}
                      <TextField
                        type="number"
                        min={0}
                        max={100}
                        value={field.value as number}
                        onChange={(e) => field.onChange(e.target.value)}
                        // onFocus={(e) =>
                        //   (!e.target.value || e.target.value === "0") &&
                        //   field.onChange("")
                        // }
                        // onBlur={(e) =>
                        //   (!e.target.value || e.target.value === "0") &&
                        //   field.onChange("0")
                        // }
                        size="sm"
                        label={t("label.amountOfWork")}
                        placeholder={t("label.amountOfWork")}
                        isError={!!errors?.amountOfWork?.message}
                        errorMessage={_.capitalize(
                          errors?.amountOfWork?.message
                        )}
                      />
                    </Box>
                  )}
                />
              )}
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <Box
                    sx={{
                      w: "full",
                    }}
                  >
                    <Heading fontSize="sm" sx={{ mb: 2 }}>
                      {t("label.note")}
                    </Heading>
                    <Textarea
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      size="sm"
                      placeholder={t("label.note")}
                    />
                  </Box>
                )}
              />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              height: "max-content",
            }}
          >
            <Button size="sm" ref={cancelRef} onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              onClick={handleSubmit(onSubmit)}
              ml={3}
              //  colorScheme="twitter"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  return performAction?.actionType === "view"
    ? _renderHistoryChange()
    : _renderPerformanceForm();
};

export default PerformanceDialog;
