import { TActions } from "@/Types/Common.types";
import { Box, Icon, IconButton, Stack, Tooltip } from "@chakra-ui/react";
import { Table } from "rsuite";
import { NoDataFound } from "@/Components/Common";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: any[];
  onRowAction: (action: TActions, item: any) => void;
  onChangeLimit?: (limint: number) => void;
  isLoading?: boolean;
}
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};
const WorkingTimeDataTable: React.FC<IProps> = ({
  payload,
  onRowAction,
  isLoading,
}) => {
  const t = useTranslation();
  const convertToTime = (decimalTime: any) => {
    const hours = Math.floor(decimalTime);
    const decimalMinutes = (decimalTime - hours) * 60;
    const minutes = Math.round(decimalMinutes);

    // Định dạng lại giờ và phút
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:00`; // Format giờ và phút
  };

  return (
    <Box>
      <Table
        data={payload}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
      >
        <Column resizable width={270}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.morningStart")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData?.morningStart
                ? `${convertToTime(rowData.morningStart)}`
                : "--"
            }
          </Cell>
        </Column>
        <Column resizable width={270}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.morningEnd")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData?.morningEnd
                ? `${convertToTime(rowData.morningEnd)}`
                : "--"
            }
          </Cell>
        </Column>
        <Column align="center" verticalAlign="middle" width={270} resizable>
          <HeaderCell style={HEADER_STYLE}>
            {t("table.afternoonStart")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData?.afternoonStart
                ? `${convertToTime(rowData.afternoonStart)}`
                : "--"
            }
          </Cell>
        </Column>
        <Column align="center" verticalAlign="middle" width={270} resizable>
          <HeaderCell style={HEADER_STYLE}>
            {t("table.afternoonEnd")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData?.afternoonEnd
                ? `${convertToTime(rowData.afternoonEnd)}`
                : "--"
            }
          </Cell>
        </Column>
        <Column align="center" width={80} resizable fixed="right">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData) => {
              return (
                <Stack direction="row" justifyContent="center">
                  <Tooltip label={t("tooltip.edit")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={PencilSquareIcon} />}
                      aria-label={""}
                      onClick={() => onRowAction("edit", rowData)}
                    />
                  </Tooltip>
                </Stack>
              );
            }}
          </Cell>
        </Column>
      </Table>
    </Box>
  );
};

export default WorkingTimeDataTable;
