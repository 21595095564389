import _ from "lodash";
import dayjs from "dayjs";
import Utils from "@/Utils";

interface IWorkingDayStructure {
  mondayMorning: boolean;
  mondayAfternoon: boolean;
  tuesdayMorning: boolean;
  tuesdayAfternoon: boolean;
  wednesdayMorning: boolean;
  wednesdayAfternoon: boolean;
  thursdayMorning: boolean;
  thursdayAfternoon: boolean;
  fridayMorning: boolean;
  fridayAfternoon: boolean;
  saturdayMorning: boolean;
  saturdayAfternoon: boolean;
  sundayMorning: boolean;
  sundayAfternoon: boolean;
}

type DayOfWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

// type Schedule = {
//   [day in DayOfWeek]: {
//     morning: boolean;
//     afternoon: boolean;
//   };
// };

const convertSchedule = (originalSchedule: IWorkingDayStructure) => {
  const dayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const newSchedule: {
    [key: string]: { morning: boolean; afternoon: boolean };
  } = {};

  dayNames.forEach((day) => {
    const dayLower = day.toLowerCase() as DayOfWeek;
    newSchedule[day] = {
      morning:
        originalSchedule[`${dayLower}Morning` as keyof IWorkingDayStructure],
      afternoon:
        originalSchedule[`${dayLower}Afternoon` as keyof IWorkingDayStructure],
    };
  });

  return newSchedule;
};

const convertBackSchedule = (newSchedule: {
  [key: string]: { morning: boolean; afternoon: boolean };
}): IWorkingDayStructure => {
  const originalSchedule = {} as IWorkingDayStructure;

  Object.keys(newSchedule).forEach((day) => {
    const dayLower = day.toLowerCase() as DayOfWeek;
    originalSchedule[`${dayLower}Morning` as keyof IWorkingDayStructure] =
      newSchedule[day].morning;
    originalSchedule[`${dayLower}Afternoon` as keyof IWorkingDayStructure] =
      newSchedule[day].afternoon;
  });

  return originalSchedule;
};

// Helper function to get the day name and period suffix
const getDayAndPeriod = (
  date: Date,
  period: "Morning" | "Afternoon"
): keyof IWorkingDayStructure => {
  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const dayIndex = dayjs(date).day(); // 0 for Sunday, 1 for Monday, etc.
  const dayName = dayNames[dayIndex];
  return `${dayName}${period}` as keyof IWorkingDayStructure;
};

const calculateDuration = (
  dateStart: string,
  endDate: string,
  startDateType: string,
  endDateType: string,
  workingDays: IWorkingDayStructure,
  holidays: string[]
): number => {
  const start = new Date(dateStart);
  const end = new Date(endDate);

  const dateRanges = Utils.generateDateRanges(start, end);

  let totalDays = 0;

  if (!dateStart || !endDate || !startDateType || !endDateType)
    return totalDays;

  // Iterate through each day
  if (_.first(dateRanges) === _.last(dateRanges)) {
    //handle start / end same day
    if (!_.includes(holidays, dateRanges[0])) {
      const morningKey = getDayAndPeriod(
        dayjs(dateRanges[0]).toDate(),
        "Morning"
      );
      const afternoonKey = getDayAndPeriod(
        dayjs(dateRanges[0]).toDate(),
        "Afternoon"
      );
      if (workingDays[morningKey] && workingDays[morningKey]) {
        if (
          (startDateType === "am" && endDateType === "am") ||
          (startDateType === "pm" && endDateType === "pm")
        )
          totalDays += 0.5;
        if (startDateType === "am" && endDateType === "pm") {
          totalDays += 1;
        }
      } else {
        if (workingDays[morningKey]) {
          if (startDateType === "am") totalDays += 0.5;
        }
        if (workingDays[afternoonKey]) {
          if (endDateType === "pm") totalDays += 0.5;
        }
      }

      // if (startDateType === "am" && endDateType === "pm") {
      //   totalDays += 1;
      // }

      // if (
      //   (startDateType === "am" && endDateType === "am") ||
      //   (startDateType === "pm" && endDateType === "pm")
      // ) {
      //   totalDays += 0.5;
      // }
    }
    // Adjust the first day
  } else {
    _.forEach(dateRanges, (date) => {
      if (_.includes(holidays, date)) return;
      const morningKey = getDayAndPeriod(dayjs(date).toDate(), "Morning");
      const afternoonKey = getDayAndPeriod(dayjs(date).toDate(), "Afternoon");

      if (date === _.first(dateRanges) || date === _.last(dateRanges)) {
        if (workingDays[morningKey] || workingDays[afternoonKey]) {
          if (date === _.first(dateRanges)) {
            if (startDateType === "am") {
              if (workingDays[afternoonKey] && workingDays[morningKey]) {
                totalDays += 1;
              } else {
                if (workingDays[morningKey] || workingDays[afternoonKey]) {
                  totalDays += 0.5;
                }
              }
            }
            if (startDateType === "pm")
              totalDays += workingDays[afternoonKey] ? 0.5 : 0;
          }

          if (date === _.last(dateRanges)) {
            if (endDateType === "am")
              totalDays += workingDays[morningKey] ? 0.5 : 0;
            if (endDateType === "pm") {
              if (workingDays[afternoonKey] && workingDays[morningKey]) {
                totalDays += 1;
              } else {
                if (workingDays[morningKey] || workingDays[afternoonKey]) {
                  totalDays += 0.5;
                }
              }
            }
          }
        }
      } else {
        if (workingDays[morningKey]) {
          totalDays += 0.5;
        }
        if (workingDays[afternoonKey]) {
          totalDays += 0.5;
        }
      }
    });
  }

  return totalDays;
};

const calculateTotalDuration = (schedule: IWorkingDayStructure) => {
  let duration = 0;
  for (let key in schedule) {
    if (schedule[key as keyof typeof schedule]) {
      duration += 0.5;
    }
  }
  return duration;
};

export {
  convertSchedule,
  convertBackSchedule,
  calculateDuration,
  calculateTotalDuration,
  getDayAndPeriod,
};
