import { configureStore, Action } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import {
//   createStateSyncMiddleware,
//   initMessageListener,
// } from "redux-state-sync";
// import { persistReducer, persistStore, PERSIST, PURGE } from "redux-persist";
// import storage from 'redux-persist/lib/storage';
// import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";

import createRootReducer from "@/Reducers";

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;

const excludeLoggerEnvs = ["prod"];
const shouldIncludeLogger = !excludeLoggerEnvs.includes(
  import.meta.env.MODE || ""
);

let logger: any = undefined;

if (shouldIncludeLogger) {
  logger = createLogger({
    level: "info",
    collapsed: true,
  });
}

export const configuredStore = () => {
  // Create Store
  // const persistConfig = {
  //   key: "root",
  //   storage: createIdbStorage({ name: "cavt", storeName: "keyval" }),
  //   serialize: false, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools; Available since redux-persist@5.4.0
  //   deserialize: false, // Required to bear same value as `serialize` since redux-persist@6.0,
  //   blacklist: ["auth", "chat"],
  // };

  // const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = [
        ...getDefaultMiddleware({ serializableCheck: false }),
        routerMiddleware(history),
        // createStateSyncMiddleware({
        //   blacklist: [PERSIST, PURGE],
        // }),
      ];
      if (logger) return [...middleware, logger];
      return middleware;
    },
  });
  return store;
};

export const store = configuredStore();
// initMessageListener(store);
// export const persistor = persistStore(store);
export type Store = ReturnType<typeof configuredStore>;
export type ReduxState = ReturnType<typeof createRootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
