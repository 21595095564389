import { ActionTypes } from "@/Constants";
import { PURGE } from "redux-persist";

const initialState = {
  isFetchLoading: false,
  isActionLoading: false,
  isUpdateSuccess: false,
  isCreateSuccess: false,
  payload: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_MAIL_RECEIVE_REDUCER:
      return {
        ...initialState,
      };
    case ActionTypes.SET_MAIL_RECEIVE_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.SET_MAIL_RECEIVE_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };
    case ActionTypes.CREATE_MAIL_RECEIVE_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_MAIL_RECEIVE_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.FETCH_MAIL_RECEIVE_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
        payload,
      };

    case ActionTypes.FETCH_MAIL_RECEIVE_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
      };
    case ActionTypes.UPDATE_MAIL_RECEIVE_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_MAIL_RECEIVE_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    default:
      return state;
  }
};
