import React, { useRef, useEffect } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Stack,
  Heading,
  Image,
} from "@chakra-ui/react";

import { TextField, UploadAvatar } from "@/Components/Common";
import EmojiPicker from "emoji-picker-react";
import { WorkspaceActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import ConfirmDialog from "../Confirm";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

//import { RootState, useTypedDispatch } from "@/Store";

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: any;
}

const DEFAULT_FORM_DATA = {
  name: "",
  icon: "",
  isUserIconCustom: false,
};
const { updateWorkSpace, deleteWorkSpace } = WorkspaceActions;

const UpdateShortCutGroupDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const [files, setFiles] = React.useState<any[]>([]);
  const [isShowDeleteLog, setIsShowDeleteLog] = React.useState(false);

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t("message.titleIsRequired")),
      icon: yup.string().trim().notRequired(),
      isUserIconCustom: yup.boolean().notRequired(),
    })
    .required();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });
  const iconUrl: any = watch("icon");

  const onSubmit = (data: any) => {
    const formData = new FormData();

    if (!_.isEmpty(files)) {
      const fileData = files[0];
      if (fileData) formData.append("iconCustom", fileData);
    }
    formData.append("name", data?.name);
    formData.append("icon", data?.icon);
    formData.append("isUserIconCustom", data?.isUserIconCustom);
    if (payload?.id) dispatch(updateWorkSpace(payload.id, formData));
  };

  const generateDetails = async () => {
    if (payload) {
      setValue("icon", payload.icon);
      setValue("name", payload.name);
      setValue("isUserIconCustom", payload.isUserIconCustom);
      if (payload?.iconCustom) {
        const isInternal = payload?.iconCustom?.storageService === "Internal";
        const avatarSrc = isInternal
          ? await Utils.getFileFromURL(
              _.get(payload, "iconCustom.path") || "",
              _.get(payload, "iconCustom.nameOriginal") || ""
            )
          : await Utils.getAWSFileAsBlob(
              _.get(payload, "iconCustom.path") || "",
              _.get(payload, "iconCustom.nameOriginal") || ""
            );
        if (avatarSrc) setFiles([avatarSrc as File]);
      }
    }
  };

  useEffect(() => {
    generateDetails();
  }, [payload]);

  useEffect(() => {
    if (!open) {
      reset();
      setIsShowDeleteLog(false);
      setFiles([]);
    }
  }, [open]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ".epr-skin-tone-select.epr_mw4zr1.epr_-2hq8qm.epr_-l1995p.epr_-bbny0t":
            {
              display: "none",
            },
        }}
        as={"form"}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("label.title")}
              placeholder={t("label.title")}
              size={"sm"}
              value={field.value || ""}
              onChange={(e: any) => {
                field.onChange(e.target.value);
              }}
              isError={!_.isEmpty(errors?.name?.message)}
              errorMessage={errors?.name?.message}
              isRequired
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Heading fontSize={"14px"} mb={2}>
              {t("label.customIcon")}
            </Heading>
            <UploadAvatar
              direction="row"
              labelSx={{ minWidth: "250px" }}
              containerSx={{ mb: 2 }}
              files={files}
              onFileChange={(newFile) => setFiles(newFile)}
            />
          </Box>
        </Box>
        <Box>
          <Heading fontSize={"14px"}>{t("label.icon")}</Heading>
          <Image src={iconUrl} />
          <Controller
            name="icon"
            control={control}
            render={({ field }) => (
              <Stack>
                <EmojiPicker
                  onEmojiClick={(emoji) => {
                    field.onChange(emoji.imageUrl);
                  }}
                />
              </Stack>
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.updateGroup")}
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 800, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 10px 10px ",
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
          }}
        >
          <Button
            size="sm"
            onClick={() => setIsShowDeleteLog(true)}
            sx={{
              color: "#fff",
              background: "rgba( 187, 113, 84,0.9)",
              "&:hover": {
                background: "rgba( 187, 113, 84,1)",
              },
            }}
          >
            {t("button.delete")}
          </Button>
          <Box>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                reset();
              }}
              size="sm"
              isDisabled={isActionLoading}
            >
              {t("button.cancel")}
            </Button>
            <Button
              isDisabled={isActionLoading}
              onClick={handleSubmit(onSubmit)}
              ml={3}
              //colorScheme="twitter"
              size="sm"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
      <ConfirmDialog
        isOpen={isShowDeleteLog}
        onClose={() => setIsShowDeleteLog(false)}
        onAction={() => {
          if (payload?.id) dispatch(deleteWorkSpace(payload.id));
        }}
        body={t("message.allShortcutsInTheShortcutGroupWillBeDeleted")}
        actionType="delete"
        isLoading={isActionLoading}
      />
    </AlertDialog>
  );
};

export default UpdateShortCutGroupDialog;
