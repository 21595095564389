import React, { useEffect, useState } from "react";
import { Stack, Heading } from "@chakra-ui/react";

import { AdminLayout } from "@/Components/DefaultLayout";

import { RootState, useTypedDispatch } from "@/Store";
import { WorkingTimeActions } from "@/Actions";
import { RoundedContainer } from "@/Components/Common";
import _ from "lodash";
import { useSelector } from "react-redux";
import DataTable from "@/Components/LayoutPart/DataTable";
import UpdateWorkingTime from "@/Components/Popup/WorkingTime/UpdateWorkingTime";
import { useTranslation } from "react-multi-lang";

// import CommonColors from "@/Themes/CommonColors";
const { fetchWorkingTime } = WorkingTimeActions;

const WorkingTimeList: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const [isShowPopub, setIsShowPopub] = useState<boolean>(false);
  const [detailWorkingTime, setDetailWorkingTime] = useState<any>();

  const isFetchLoading: any = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "isFetchLoading")
  );
  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  useEffect(() => {
    dispatch(fetchWorkingTime());
  }, []);

  const onRowAction = (e: string, data: any) => {
    if (e === "edit") {
      setIsShowPopub(true);
      setDetailWorkingTime(data);
    }
  };

  const _renderDatatable = () => {
    return (
      <DataTable.WorkingTimeDataTable
        payload={workingTimeList}
        isLoading={isFetchLoading}
        onRowAction={(action, data) => {
          onRowAction(action, data);
        }}
      />
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5C6e6c"}>
          {t("title.workingTime")}
        </Heading>
        <RoundedContainer>{_renderDatatable()}</RoundedContainer>
        <UpdateWorkingTime
          open={isShowPopub}
          payload={detailWorkingTime}
          onClose={() => setIsShowPopub(false)}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default WorkingTimeList;
