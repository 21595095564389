import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Stack,
  Heading,
  Text,
  HStack,
  Button,
  IconButton,
  Icon,
  List,
  ListItem,
  ListIcon,
  Box,
} from "@chakra-ui/react";
import {
  CircleStackIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import { AdminLayout } from "@/Components/DefaultLayout";
import { RoundedContainer } from "@/Components/Common";
import {
  ConfirmDialog,
  ChatDialog,
  MailReceiveDialog,
} from "@/Components/Popup";

import Utils from "@/Utils";
import { RootState, useTypedDispatch } from "@/Store";
import {
  BackupActions,
  TimeDeleteFilePrivateChatActions,
  ChatActions,
  MailReceiveActions,
} from "@/Actions";
import { useTranslation } from "react-multi-lang";

// import CommonColors from "@/Themes/CommonColors";
const { removeAllFile } = ChatActions;
const { getAllMemoryConversation } = BackupActions;
const { fetchData: fetchTimeDeleteFilePrivateChat } =
  TimeDeleteFilePrivateChatActions;
const { fetchMailReceive } = MailReceiveActions;

const Overview: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const allMemoryConversation = useSelector((state: RootState) =>
    _.get(state.BACKUP, "allMemoryConversation")
  );

  const mailReceive: any[] = useSelector((state: RootState) =>
    _.get(state.MAIL_RECEIVE, "payload")
  );

  const mailReceiveMemory = useMemo(
    () =>
      _.find(mailReceive, (item) => item?.type === "memory") || {
        type: "memory",
        email: "",
      },
    [mailReceive]
  );

  const timeDeleteFilePrivateChat: { time: number; type: string } = useSelector(
    (state: RootState) => _.get(state.TIME_DELETE_FILE_PRIVATE_CHAT, "payload")
  );
  console.log(timeDeleteFilePrivateChat);

  const [isShowPopup, setIsShowPopup] = useState({
    deleteAllFileInChat: false,
    confirmDeleteAllFileInChat: false,
    updateTimeDeleteFilePrivateChat: false,
    updateMailReceive: false,
  });

  const [selectedTimeRemoveFilechat, setSelectedTimeRemoveFileChat] = useState({
    time: 1,
    type: "week",
  });

  useEffect(() => {
    dispatch(fetchMailReceive());
    dispatch(getAllMemoryConversation());
    dispatch(fetchTimeDeleteFilePrivateChat());
  }, []);

  const handlePopupChange = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const memoryFormatted = Utils.formatBytes(allMemoryConversation);
  const isApproachingThreshold =
    allMemoryConversation >= 10 * Math.pow(1024, 3);

  // const calculatePercentage = (bytes: number) => {
  //   const gb = bytes / (1024 * 1024 * 1024);
  //   const percent = Math.round((gb / 10) * 100);
  //   return percent;
  // };

  const formatTimeText = (time: number, type: string) => {
    if (!time || !type) return "";
    const startCaseTimeType = type;
    if (type === "infinite") return startCaseTimeType;
    return time === 1
      ? time + " " + startCaseTimeType
      : time + " " + startCaseTimeType + "s";
  };

  const _renderPopup = () => (
    <>
      <ConfirmDialog
        actionType="confirm"
        isOpen={isShowPopup.confirmDeleteAllFileInChat}
        onClose={() => handlePopupChange("confirmDeleteAllFileInChat", false)}
        body={`By clicking the 'Confirm' button, all files related to the chat system will be permanently deleted within ${formatTimeText(
          selectedTimeRemoveFilechat?.time,
          selectedTimeRemoveFilechat?.type
        )}. This action cannot be undone. Are you sure you want to proceed?`}
        onAction={() =>
          dispatch(
            removeAllFile(selectedTimeRemoveFilechat, () => {
              dispatch(getAllMemoryConversation());
              setSelectedTimeRemoveFileChat({
                type: "week",
                time: 1,
              });
              handlePopupChange("confirmDeleteAllFileInChat", false);
            })
          )
        }
      />
      <ChatDialog.AdminSettingClearChatDialog
        open={isShowPopup.deleteAllFileInChat}
        onClose={() => handlePopupChange("deleteAllFileInChat", false)}
        callback={(data) => {
          setSelectedTimeRemoveFileChat(data);
          setIsShowPopup({
            ...isShowPopup,
            deleteAllFileInChat: false,
            confirmDeleteAllFileInChat: true,
          });
        }}
      />
      <ChatDialog.UpdateTimeDeleteFilePrivateChatDialog
        open={isShowPopup.updateTimeDeleteFilePrivateChat}
        onClose={() =>
          handlePopupChange("updateTimeDeleteFilePrivateChat", false)
        }
        payload={timeDeleteFilePrivateChat}
      />
      <MailReceiveDialog
        open={isShowPopup.updateMailReceive}
        onClose={() => handlePopupChange("updateMailReceive", false)}
        data={mailReceiveMemory}
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5C6e6c"}>
          {t("title.fileSystem")}
        </Heading>
        <RoundedContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <List spacing={5}>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListIcon as={CircleStackIcon} color="green.500" />
                <Text
                  size="md"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {t("label.utilizedmemory")}:{" "}
                  <Box
                    as="span"
                    sx={{
                      fontWeight: "bold",
                    }}
                    color={isApproachingThreshold ? "red.500" : "inherit"}
                  >
                    {memoryFormatted} / 10GB
                  </Box>
                </Text>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListIcon as={EnvelopeIcon} color="green.500" />
              <HStack>
                <Text
                  size="md"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {t("label.emailAlert")}: {t("label.overflowMemoryWarningFor")}
                  :{" "}
                  <Box
                    as="span"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {mailReceiveMemory?.mail || "[Email Address]"}
                  </Box>
                </Text>
                <IconButton
                  size="xs"
                  aria-label={""}
                  onClick={() => handlePopupChange("updateMailReceive", true)}
                >
                  <Icon>
                    <PencilSquareIcon />
                  </Icon>
                </IconButton>
              </HStack>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListIcon as={ClockIcon} color="green.500" />
              <HStack>
                <Text
                  size="md"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {t("label.attachmentDeletionTimeInPrivateChatMessages")}:
                  <Box
                    as="span"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {formatTimeText(
                      timeDeleteFilePrivateChat?.time,
                      t(`label.${timeDeleteFilePrivateChat?.type}`)
                    )}
                  </Box>
                </Text>
                <IconButton
                  size="xs"
                  aria-label={""}
                  onClick={() =>
                    handlePopupChange("updateTimeDeleteFilePrivateChat", true)
                  }
                >
                  <Icon>
                    <PencilSquareIcon />
                  </Icon>
                </IconButton>
              </HStack>
            </ListItem>
          </List>

          <HStack>
            <Button
              size="xs"
              colorScheme="red"
              variant="outline"
              leftIcon={<ExclamationTriangleIcon />}
              onClick={() => handlePopupChange("deleteAllFileInChat", true)}
            >
              {t("button.deleteEntireChatSystemFiles")}
            </Button>
          </HStack>
        </RoundedContainer>
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Overview;
