import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateClient } from "@/Interfaces/Client.interface";

const { CLIENT } = APIs;

export const createClient = async (payload: ICreateClient) => {
  return request(CLIENT.BASIC, "POST_FORM_DATA", payload);
};

export const fetchClient = async (payload: any) => {
  return request(CLIENT.BASIC, "GET", payload);
};

export const updateClient = async (id: string, payload: ICreateClient) => {
  return request(`${CLIENT.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const changeStatusClient = async (id: string, payload: any) => {
  return request(`${CLIENT.CHANGE_STATUS}/${id}`, "PUT", payload);
};

export const getClientById = async (id: string) => {
  return request(`${CLIENT.GET_BY_ID}/${id}`, "GET");
};
