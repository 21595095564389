interface StateVariables {
  [key: string]: boolean;
}

const generateSuccessStateVariables = (
  variableNames: string[]
): StateVariables => {
  const stateVariables: StateVariables = {};
  variableNames.forEach((name) => {
    stateVariables[`is${name}Success`] = false;
  });
  return stateVariables;
};

const DEFAULT_LOADING_STATES = {
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
};

const DEFAULT_META_STATES = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

export {
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
  generateSuccessStateVariables,
};
