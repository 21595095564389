import React, { useState, useEffect } from "react";
import _ from "lodash";
import ReactDatePicker, { DatePickerProps } from "react-date-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-date-picker/dist/DatePicker.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  type InputProps,
  Stack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import Utils from "@/Utils";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  value?: string;
  onDateChange(newDate: Date | string): void;
  minDate?: Date | any;
  maxDate?: Date | any;
  disabled?: boolean;
  disabledTitle?: boolean;
}

const DatePicker = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      value,
      onDateChange,
      minDate,
      maxDate,
      disabledTitle = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [key, setKey] = useState(Math.random());
    const language = Utils.getSavedLanguage();

    const config: DatePickerProps = {
      format: "dd/MM/yyyy",
      onFocus: () => setIsFocus(true),
      onBlur: () => setIsFocus(false),
      value,
      onChange: (value) => onDateChange(value as any),
    };

    useEffect(() => {}, [ref]);

    const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
      _.get(state.HOLIDAY, "holidaysList")
    );

    const isSunday = (date: Date) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      return (
        // date.getDay() === 0 ||
        holidaysList.some((holiday) => holiday.day === formattedDate)
      ); // 0 represents Sunday
    };

    const titleDisabled: any = ({ date, view }: any) => {
      if (view === "month" && isSunday(date)) {
        return true; // Disable days
      }
      return false;
    };

    return (
      <FormControl sx={style} isInvalid={isError} isRequired={props.isRequired}>
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Stack
          sx={{
            fontSize: 14,
            ".react-date-picker.react-date-picker--closed.react-date-picker--enabled":
              {
                height: "100%",
              },
            ".react-date-picker__wrapper": {
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              // "#E53E3E"
              //       : "#3182ce"
              outline: `2px solid ${
                isFocus ? "#3182ce" : isError ? "#E53E3E" : "transparent"
              }`,
              outlineOffset: "-2px",
            },
            ".react-date-picker__inputGroup": {
              height: "100%",
              px: "12px",
              input: {
                outline: "none",
              },
            },
            ".react-date-picker--disabled": {
              background: "white",
              cursor: "not-allowed",
            },
            ".react-date-picker__button": {
              cursor: disabled ? "not-allowed" : "pointer",
            },
            ".react-date-picker__clear-button": {
              display: "none",
            },
          }}
        >
          <ReactDatePicker
            locale={language === "en" ? "en" : "vi"}
            key={key}
            onCalendarClose={() => setKey(Math.random())}
            {...config}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            tileDisabled={disabledTitle && titleDisabled}
          />
        </Stack>
        {!_.isEmpty(errorMessage) ? (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        ) : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default DatePicker;
