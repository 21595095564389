import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import {
  ICreateHoliday,
  IFilterHoliday,
  IHolidayStructure,
} from "@/Interfaces/Holiday.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setHolidayFetchLoading = () => {
  return {
    type: ActionTypes.SET_HOLIDAY_FETCH_LOADING,
  };
};

const setHolidayGetLoading = () => {
  return {
    type: ActionTypes.SET_HOLIDAY_GET_LOADING,
  };
};

const setHolidayActionLoading = () => {
  return {
    type: ActionTypes.SET_HOLIDAY_ACTION_LOADING,
  };
};

const resetHolidayReducer = () => {
  return {
    type: ActionTypes.RESET_HOLIDAY,
  };
};

const setMetaHoliday = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_HOLIDAY,
    payload,
  };
};

const setPaginationHoliday = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_HOLIDAY,
    payload,
  };
};

const fetchHolidaysSuccess = (payload: IHolidayStructure) => {
  return {
    type: ActionTypes.FETCH_HOLIDAY_SUCCESS,
    payload,
  };
};

const fetchHolidaysFail = () => {
  return {
    type: ActionTypes.FETCH_HOLIDAY_FAILURE,
  };
};

const fetchHoliday = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setHolidayFetchLoading());
    await APIs.fetchHoliday(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchHolidaysFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          dispatch(setMetaHoliday(meta));
          dispatch(setPaginationHoliday(payload));
          if (items) dispatch(fetchHolidaysSuccess(items));
        }
      })
      .catch(async () => {
        await dispatch(fetchHolidaysFail());
      });
  };
};

const createHolidaySuccess = () => {
  return {
    type: ActionTypes.CREATE_HOLIDAY_SUCCESS,
  };
};

const createHolidayFail = () => {
  return {
    type: ActionTypes.CREATE_HOLIDAY_FAILURE,
  };
};

const createHoliday = (payload: ICreateHoliday, pagination: IFilterHoliday) => {
  return async (dispatch: any) => {
    dispatch(setHolidayActionLoading());
    await APIs.createHoliday(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createHolidayFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createHolidaySuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchHoliday(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(createHolidayFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdHolidaySuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_HOLIDAY_SUCCESS,
    payload,
  };
};

const getByIdHolidayFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_HOLIDAY_FAILURE,
  };
};

const getByIdHoliday = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setHolidayGetLoading());
    await APIs.getHolidayById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdHolidayFail());
        else {
          dispatch(getByIdHolidaySuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdHolidayFail());
      });
  };
};

const updateHolidaySuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_HOLIDAY_SUCCESS,
    payload,
  };
};

const updateHolidayFail = () => {
  return {
    type: ActionTypes.UPDATE_HOLIDAY_FAILURE,
  };
};

const updateHoliday = (id: string, payload: any, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setHolidayActionLoading());
    await APIs.updateHoliday(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateHolidayFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateHolidaySuccess(result));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchHoliday(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(updateHolidayFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusHolidaySuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_HOLIDAY_SUCCESS,
  };
};

const changeStatusTimeFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_HOLIDAY_FAILURE,
  };
};

const changeStatusHoliday = (
  id: string,
  payload: any,
  pagination: IFilterHoliday,
  type?: string
) => {
  return async (dispatch: any) => {
    dispatch(setHolidayActionLoading());
    await APIs.ChangeStatusHoliday(id, payload)
      .then(async (response: any) => {
        const language = Utils.getSavedLanguage();
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(changeStatusTimeFail());
          await Toast({
            title: ` ${type} ${
              language === "en" ? "holiday failed" : "ngày lễ lỗi"
            } `,
            status: "error",
          });
        } else {
          dispatch(changeStatusHolidaySuccess());
          Toast({
            title: ` ${type} ${
              language === "en" ? "holiday successfully" : "ngày lễ thành công"
            } `,
            status: "success",
          });
          await dispatch(fetchHoliday(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusTimeFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchHoliday,
  resetHolidayReducer,
  createHoliday,
  getByIdHoliday,
  updateHoliday,
  changeStatusHoliday,
};
