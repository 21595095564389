import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useSortable } from "@dnd-kit/sortable";
import { useSelector } from "react-redux";
import { CSS } from "@dnd-kit/utilities";
import { Box, Heading, Divider, Skeleton, HStack } from "@chakra-ui/react";

import { ENUMS } from "@/Constants";
import { RootState } from "@/Store";

import Cards from "./Card";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  column: any;
}

const Column: React.FC<ISectionProps> = ({ column }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id, data: { ...column } });

  const containerRef = useRef<HTMLDivElement>(null);
  const t = useTranslation();

  const dndKitColumnStyles = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  const [skeletonCount, setSkeletonCount] = useState<number>(10); // Default number of skeletons

  useEffect(() => {
    const skeletonHeight = 30; // Height of each skeleton
    const gap = 4; // Gap between skeletons
    const totalSkeletonHeight = skeletonHeight + gap; // Total height of skeleton including gap
    // Calculate the number of skeletons to display based on the height of the card list
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight instead of offsetHeight
      const calculatedSkeletonCount = Math.floor(
        containerHeight / totalSkeletonHeight
      ); // Use Math.floor to ensure that the skeletons fit without overflowing
      setSkeletonCount(calculatedSkeletonCount);
    }
  }, [containerRef]);

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isFetchLoading")
  );

  const _renderSkeleton = useMemo(() => {
    return (
      <HStack flexDirection="column" gap={1}>
        {Array.from({ length: skeletonCount }, (_, index) => (
          <Box key={index} w="full">
            <Skeleton height="30px" width="100%" />
          </Box>
        ))}
      </HStack>
    );
  }, [skeletonCount]);

  return (
    <Box
      ref={setNodeRef}
      style={dndKitColumnStyles}
      {...attributes}
      {...listeners} // disabled drag column
      sx={{
        borderRadius: "6px",
        backgroundColor: "gray.200",
        cursor: "default",
        minHeight: "calc(100vh - 270px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Heading
          sx={{
            padding: "5px 10px",
            fontSize: "sm",
            fontWeight: 600,
            textTransform: "uppercase",
            textAlign: "center",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            background:
              ENUMS.PROJECT_STATUS_COLOR[
                column.status as keyof typeof ENUMS.PROJECT_STATUS_COLOR
              ]?.background,
            color:
              ENUMS.PROJECT_STATUS_COLOR[
                column.status as keyof typeof ENUMS.PROJECT_STATUS_COLOR
              ]?.text,
            backdropFilter: "blur(8px)",
          }}
        >
          {t(`label.${column.name}`)}
        </Heading>
        <Divider />
      </Box>
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          overflow: "auto",
          flexGrow: 1,
          mb: 2,
        }}
        ref={containerRef}
      >
        <Box
          sx={{
            padding: "10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isFetchLoading ? (
            _renderSkeleton
          ) : (
            <Cards cards={column?.cards || []} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Column;
