import React, { useMemo } from "react";
import _ from "lodash";
import { Button, Icon } from "@chakra-ui/react";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";
import dayjs from "dayjs";
import { DownloadIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-multi-lang";

type Props = {
  //  csvData: any[];
  //  interval: string;
};

const headerExceljs = ["A", "B", "C", "D", "E"];

export const ExportToExcelDayOffRequest: React.FC<Props> = (
  {
    //  csvData,
    //  interval,
  }
) => {
  const t = useTranslation();
  const aggregateList: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "aggregateList")
  );

  const paginationAgregate: IFilterAggregate = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "paginationAgregate")
  );

  const titleCase = (string: string) => {
    let sentence = string.toLowerCase().split(",");

    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(",");
  };

  const transformData = (aggregateList: any[]) => {
    const data: any[] = [];
    _.forEach(aggregateList, (item) => {
      let total: number = 0;
      let role: string[] = [];
      _.forEach(item.dayOffRequest, (entry) => {
        total += _.toNumber(entry.duration);
      });
      _.forEach(item?.userRole, (entry) => {
        if (entry?.role?.roleCode === "artist") {
          role.push("Staff");
        } else role.push(entry?.role?.roleCode);
      });
      const employeeData: any = {
        fullName: item?.userData?.fullName,
        StaffCode: item?.staffCode,
        role: titleCase(role.toString()),
        total: total,
      };
      data.push(employeeData);
    });
    return data;
  };

  const transformedData = useMemo(() => {
    return transformData(aggregateList);
  }, [aggregateList]);

  const summary = useMemo(() => {
    let sum: any = 0;
    _.forEach(transformedData, (item: any) => (sum += _.toNumber(item.total)));
    return sum;
  }, [transformedData]);

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;

    //title
    worksheet.getCell("A1").value = t("title.employeeLeaveStatistics");
    worksheet.mergeCells("A1:E1");

    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A1").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(1).height = 30;
    // year
    worksheet.getCell("A2").value = `${dayjs(
      paginationAgregate?.startDate
    ).format("DD/MM/YYYY")} - ${dayjs(paginationAgregate?.endDate).format(
      "DD/MM/YYYY"
    )}`;
    worksheet.mergeCells("A2:E2");
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A2").font = {
      size: 13,
      bold: false,
      color: { argb: "FFFFFF" },
    };
    worksheet.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(2).height = 20;

    //header
    worksheet.addRow([
      `${t("table.no")}`,
      `${t("table.fullName")}`,
      `${t("table.staffCode")}`,
      `${t("table.role")}`,
      `${t("table.total")}`,
    ]);

    _.forEach(headerExceljs, (i) => {
      worksheet.getCell(`${i}3`).border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    //worksheet.getRow(worksheet.rowCount).border = {
    //  top: { style: "double", color: { argb: "FFFFFF" } },
    //  left: { style: "double", color: { argb: "FFFFFF" } },
    //  bottom: { style: "double", color: { argb: "FFFFFF" } },
    //  right: { style: "double", color: { argb: "FFFFFF" } },
    //};

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data
    transformedData.forEach((item, index) => {
      worksheet.addRow([
        index + 1,
        item?.fullName,
        item?.StaffCode,
        item?.role,
        item?.total,
      ]);

      _.forEach(headerExceljs, (i) => {
        worksheet.getCell(`${i}${index + 4}`).border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
        };
      });

      worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
        //if (index % 2 === 0) {
        //  cell.fill = {
        //    type: "pattern",
        //    pattern: "solid",
        //    fgColor: { argb: "E8E8E8" },
        //  };
        //}
        cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
      });
      worksheet.getRow(worksheet.rowCount).height = 25;
      worksheet.getRow(worksheet.rowCount).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
    });

    worksheet.addRow([`${t("table.total")}`, , "", "", "", summary]);

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      //  cell.fill = {
      //    type: "pattern",
      //    pattern: "solid",
      //    fgColor: { argb: "FFFFFF" },
      //  };
      cell.font = { size: 12, bold: true, color: { argb: "FF0000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "day-off-request.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(aggregateList)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};
