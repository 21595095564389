import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import AuthenticationReducer from "./Authentication.reducer";
import RoleReducer from "./Role.reducer";
import DashboardReducer from "./Dashboard.reducer";
import UserReducer from "./User.reducer";
import ProjectReducer from "./Project.reducer";
import BoardReducer from "./Board.reducer";
import LogTimeReducer from "./LogTime.reducer";
import dayOffRequest from "./DayOffRequest.reducer";
import LeaveDays from "./LeaveDays.reducer";
import RequestUpdateTimeCardReducer from "./RequestUpdateTimeCard.reducer";
import TimekeepingReducer from "./Timekeeping.reducer";
import TimesheetReducer from "./Timesheet.reducer";
import ChatReducer from "./Chat.reducer";
import InternalIpReducer from "./InternalIp.reducer";
import HolidayReducer from "./Holiday.reducer";
import PerformanceReducer from "./Performance.reducer";
import NotificationReducer from "./Notification.reducer";
import SocketReducer from "./Socket.reducer";
import TaskNoteReducer from "./TaskNote.reducer";
import Client from "./Client.reducer";
import CheckIn from "./Checkin.reducer";
import WorkingTime from "./WorkingTime.reducer";
import ProjectExteriorReducer from "./ProjectExterior.reducer";
import BackupReducer from "./Backup.reducer";
import TimeDeleteFilePrivateChatReducer from "./TimeDeleteFilePrivateChat.reducer";
import MailReceiveReducer from "./MailReceive.reducer";
import WorkspaceReducer from "./Workspace.reducer";
import WorkingDayReducer from "./WorkingDay.reducer";

const createRootReducer = (history: History) =>
  combineReducers({
    ROUTER: connectRouter(history),
    AUTH: AuthenticationReducer,
    ROLE: RoleReducer,
    DASHBOARD: DashboardReducer,
    USER: UserReducer,
    PROJECT: ProjectReducer,
    BOARD: BoardReducer,
    LOG_TIME: LogTimeReducer,
    DAY_OFF_REQUEST: dayOffRequest,
    LEAVE_DAYS: LeaveDays,
    REQUEST_UPDATE_TIME_CARD: RequestUpdateTimeCardReducer,
    TIMEKEEPING: TimekeepingReducer,
    TIMESHEET: TimesheetReducer,
    CHAT: ChatReducer,
    INTERNAL_IP: InternalIpReducer,
    HOLIDAY: HolidayReducer,
    PERFORMANCE: PerformanceReducer,
    NOTIFICATION: NotificationReducer,
    SOCKET: SocketReducer,
    TASK_NOTE: TaskNoteReducer,
    CLIENT: Client,
    CHECKIN: CheckIn,
    WORKING_TIME: WorkingTime,
    PROJECT_EXTERIOR: ProjectExteriorReducer,
    BACKUP: BackupReducer,
    TIME_DELETE_FILE_PRIVATE_CHAT: TimeDeleteFilePrivateChatReducer,
    MAIL_RECEIVE: MailReceiveReducer,
    WORKSPACE: WorkspaceReducer,
    WORKING_DAY: WorkingDayReducer,
  });

export default createRootReducer;
