import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
} from "@chakra-ui/react";

import { DatePicker, TextField } from "@/Components/Common";
import TimeField from "react-simple-timefield";

import TextareaComponent from "@/Components/Common/Textarea";
import { RootState, useTypedDispatch } from "@/Store";
import { TaskNoteActions, LogtimeActions } from "@/Actions";
import Utils from "@/Utils";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const { updateTaskNote, getTaskNoteById, resetTaskNoteDetails, fetchTaskNote } =
  TaskNoteActions;
const { performAction: logTimePerformAction } = LogtimeActions;

const DEFAULT_FORM_DATA = {
  title: "",
  description: "",
  date: "",
  startTime: "",
  endTime: "",
  workingTime: "",
};

const UpdateTaskNoteDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.TASK_NOTE, "isActionLoading")
  );
  const performAction: ITimeLogPerformAction = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );
  const taskDetails: any = useSelector((state: RootState) =>
    _.get(state.TASK_NOTE, "details")
  );
  const myTimeWeekDays = useSelector((state: RootState) =>
    _.get(state.BOARD, "myTimeWeekDays")
  );

  const schema = yup
    .object()
    .shape({
      title: yup.string().trim().required("Title is required"),
      description: yup.string().trim().notRequired(),
      date: yup.string().trim().required("Date is required"),
      startTime: yup.string().trim().notRequired(),
      endTime: yup.string().trim().notRequired(),
      workingTime: yup.string().trim().notRequired(),
    })
    .required();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });
  const watchStartTime = watch("startTime");
  const watchEndTime = watch("endTime");

  const [timelineErrorMsg, setTimelineErrorMsg] = useState("");

  useEffect(() => {
    if (!open) {
      reset();
      dispatch(resetTaskNoteDetails());
    }
  }, [open]);

  useEffect(() => {
    if (performAction?.actionType === "editTaskNote") {
      dispatch(getTaskNoteById(performAction.id));
    }
  }, [performAction]);

  useEffect(() => {
    setValue("title", taskDetails?.title);
    setValue("description", taskDetails?.description);
    setValue("date", taskDetails?.date);
    setValue("startTime", taskDetails?.startTime);
    setValue("endTime", taskDetails?.endTime);
    setValue("workingTime", taskDetails?.workingTime);
  }, [taskDetails]);

  useEffect(() => {
    if (watchStartTime || watchEndTime) {
      if (watchStartTime && watchEndTime) {
        const newValue = Utils.calculateWorkingTime(
          watchStartTime,
          watchEndTime
        );
        setValue("workingTime", newValue.toString());
      }
    }
  }, [watchStartTime, watchEndTime]);

  useEffect(() => {
    if (
      watchEndTime !== watchStartTime &&
      (watchStartTime !== "00:00:00" || watchEndTime !== "00:00:00")
    ) {
      const time1 = new Date(`2024-02-21T${watchStartTime}`);
      const time2 = new Date(`2024-02-21T${watchEndTime}`);
      if (dayjs(time1).isAfter(time2) || !watchStartTime)
        setTimelineErrorMsg("The start time must be greater than the end time");
      else {
        setTimelineErrorMsg("");
      }
    }
  }, [watchStartTime, watchEndTime]);

  const onSubmit = (data: any) => {
    if (data && _.isEmpty(errors) && !timelineErrorMsg) {
      const formData = new FormData();
      formData.append("title", data?.title);
      formData.append("date", dayjs(data?.date).format("YYYY-MM-DD"));
      formData.append("startTime", data?.startTime || "00:00:00");
      formData.append("endTime", data?.endTime || "00:00:00");
      formData.append("workingTime", data?.workingTime);
      dispatch(
        updateTaskNote(performAction?.id, formData, () => {
          onClose();
          dispatch(
            fetchTaskNote({
              startDate: _.first(myTimeWeekDays),
              endDate: _.last(myTimeWeekDays),
            })
          );
        })
      );
    }
  };

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              label="Title"
              placeholder="Title"
              size={"sm"}
              value={field.value || ""}
              onChange={(e: any) => {
                field.onChange(e.target.value);
              }}
              isError={!_.isEmpty(errors.title?.message)}
              errorMessage={errors.title?.message}
              isRequired
            />
          )}
        />

        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                minDate={dayjs().toDate()}
                label="Date"
                size="sm"
                sx={{
                  flex: 1,
                }}
                value={field.value || ""}
                onDateChange={(value) => field.onChange(value)}
                isError={!_.isEmpty(errors.date?.message)}
                errorMessage={errors.date?.message}
                isRequired
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => (
              <TimeField
                value={field.value || "00:00:00"}
                onChange={(value) => field.onChange(value)}
                input={<TextField label="Start Time" size="sm" flex={1} />}
                showSeconds
              />
            )}
          />
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => (
              <TimeField
                value={field.value || "00:00:00"}
                onChange={(value) => {
                  field.onChange(value);
                }}
                input={
                  <TextField
                    label="End Time"
                    size="sm"
                    flex={1}
                    isError={!!timelineErrorMsg}
                    errorMessage={timelineErrorMsg}
                  />
                }
                showSeconds
              />
            )}
          />
        </Box>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextareaComponent
              placeholder="Note"
              label="Note"
              size="sm"
              value={field.value || ""}
              onChange={(e: any) => field.onChange(e.target.value)}
            />
          )}
        />
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          Update Task Note
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 10px 10px ",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Button
              size="sm"
              colorScheme="red"
              isDisabled={false}
              onClick={() =>
                dispatch(
                  logTimePerformAction("deleteTaskNote", performAction?.id)
                )
              }
            >
              Delete
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                reset();
              }}
              size="sm"
              isDisabled={isActionLoading}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isActionLoading}
              onClick={handleSubmit(onSubmit)}
              ml={3}
              colorScheme="twitter"
              size="sm"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              Update
            </Button>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateTaskNoteDialog;
