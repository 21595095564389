import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Checkbox,
  Avatar,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Select, TextField } from "@/Components/Common";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { ChatActions } from "@/Actions";
import { Routers } from "@/Constants";
import Utils from "@/Utils";
import { IUser } from "@/Interfaces/User.interface";
import { useTranslation } from "react-multi-lang";

const { createGroupChat } = ChatActions;

interface ISectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_FORM_DATA = {
  name: "",
  member: [],
  timeDeleteAttachment: 1,
  typeDeleteAttachment: "month",
};

const dataClear = [
  // { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Infinite", value: "infinite" },
];

const dataClearVi = [
  // { label: "Day", value: "day" },
  { label: "Tuần", value: "week" },
  { label: "Tháng", value: "month" },
  { label: "Năm", value: "year" },
  { label: "Vô hạn", value: "infinite" },
];

const CreateGroup: React.FC<ISectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "userList")
  );

  const isCreateSuccess = useSelector((state: RootState) =>
    _.get(state.CHAT, "isCreateSuccess")
  );

  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<any>([]);

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t("message.groupNameIsRequired")),
      member: yup
        .array()
        .of(yup.string())
        .min(1)
        .required(t("message.pleaseSelectAtLeastOneUser")),
      timeDeleteAttachment: yup
        .number()
        .default(0)
        .typeError(t("message.typeDeleteAttachmentFileMustBeaNumber")),
      typeDeleteAttachment: yup.string(),
    })
    .required();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const watchTypeDeleteAttachment = watch("typeDeleteAttachment");

  useEffect(() => {
    setValue("timeDeleteAttachment", 1);
    setValue("typeDeleteAttachment", "month");
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    setValue("member", selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (!open) {
      reset();
      setSelectedValues([]);
    }
  }, [open]);

  useEffect(() => {
    if (isCreateSuccess) onClose();
  }, [isCreateSuccess]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(event.target.value);
    const regex = new RegExp(_.escapeRegExp(value), "i");
    const filtered = !_.isEmpty(value)
      ? _.filter(
          filteredUsers,
          (user) =>
            regex.test(user?.email) || regex.test(user?.userData?.fullName)
        )
      : users;

    setFilteredUsers(filtered);
  };

  const handleCheckboxChange = (e: any) => {
    const checkboxValue = e.target.value;
    const isChecked = e.target.checked;
    setSelectedValues((prevValues: any) => {
      if (isChecked) {
        return [...prevValues, checkboxValue];
      } else {
        return _.filter(prevValues, (value) => value !== checkboxValue);
      }
    });
  };

  const handleReset = (event: any) => {
    event.stopPropagation();
    setSearchValue("");
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("title", data.name);
    if (data.timeDeleteAttachment)
      formData.append("timeDeleteAttachment", data.timeDeleteAttachment);
    _.forEach(data?.member, (member) =>
      formData.append("userReceive[]", member)
    );
    dispatch(createGroupChat(formData));
    if (pathname !== Routers.CHAT) Utils.redirect(Routers.CHAT);
    onClose();
  };

  return (
    <>
      <Drawer isOpen={open} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            sx={{
              borderBottom: "1px solid #ddd",
              p: 4,
            }}
          >
            <Heading
              fontSize="lg"
              sx={{
                mb: 5,
              }}
            >
              {t("title.createNewGroup")}
            </Heading>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  size="sm"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  label={t("label.groupName")}
                  placeholder={t("label.groupName")}
                  isError={!_.isEmpty(errors.name?.message)}
                  errorMessage={errors?.name?.message}
                  isRequired
                />
              )}
            />
            <Box mt={2}>
              <Controller
                name="typeDeleteAttachment"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value || dataClear[1].value}
                    onChange={(e: any) => {
                      field.onChange(e.target.value);
                      if (e.target.value === "infinite")
                        clearErrors("timeDeleteAttachment");
                    }}
                    label={t("label.typeDeleteAttachmentFile")}
                    size="sm"
                    options={language === "en" ? dataClear : dataClearVi}
                    //isRequired
                    isError={!_.isEmpty(errors.typeDeleteAttachment?.message)}
                    errorMessage={errors?.typeDeleteAttachment?.message}
                  />
                )}
              />
            </Box>
            {watchTypeDeleteAttachment !== "infinite" && (
              <Box mt={2}>
                <Controller
                  name="timeDeleteAttachment"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.target.value);
                      }}
                      label={t("label.timeDeleteAttachmentFile")}
                      placeholder="0"
                      size="sm"
                      min={1}
                      isError={!_.isEmpty(errors.timeDeleteAttachment?.message)}
                      errorMessage={errors?.timeDeleteAttachment?.message}
                    />
                  )}
                />
              </Box>
            )}
          </DrawerHeader>

          <DrawerBody
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              p: 4,
            }}
          >
            <Heading fontSize="sm"> {t("label.availableUsers")}</Heading>
            <InputGroup>
              <Input
                value={searchValue}
                onChange={handleSearchChange}
                size="sm"
                placeholder={t("label.searchUserByNameEmail")}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
              <InputRightElement
                sx={{
                  height: "100%",
                }}
              >
                {searchValue.length > 0 && (
                  <Tooltip label={t("label.remove")} hasArrow>
                    <IconButton
                      isRound
                      sx={{
                        background: "none",
                      }}
                      size="sm"
                      icon={<Icon as={XMarkIcon} />}
                      aria-label={""}
                      onClick={handleReset}
                    />
                  </Tooltip>
                )}
              </InputRightElement>
            </InputGroup>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflow: "auto",
                p: 2,
              }}
            >
              {!_.isEmpty(filteredUsers) ? (
                _.map(filteredUsers, (user, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      size="sm"
                      name={user?.userData?.fullName}
                      src={user?.userData?.avatar?.path}
                    />
                    <Box>
                      <Text fontSize="sm" fontWeight="600">
                        {user?.userData?.fullName}
                      </Text>
                    </Box>
                    <Checkbox
                      sx={{ ml: "auto" }}
                      value={user?.id}
                      isChecked={_.some(
                        selectedValues,
                        (userId) => userId === user?.id
                      )}
                      onChange={handleCheckboxChange}
                    />
                  </Box>
                ))
              ) : (
                <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
              )}
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button size="sm" variant="outline" mr={3} onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              onClick={handleSubmit(onSubmit)}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateGroup;
