import React, { useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Select,
  Heading,
  Input,
  Textarea,
} from "@chakra-ui/react";

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const UpdatePerformanceEvaluationDialog: React.FC<SectionProps> = ({
  open,
  onClose,
}) => {
  const cancelRef = useRef<any>(null);

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          w: "full",
        }}
      >
        <Heading fontSize="sm" sx={{ mb: 2 }}>
          Member
        </Heading>
        <Select placeholder="User" size="sm">
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
      </Box>
      <Box
        sx={{
          w: "full",
        }}
      >
        <Heading fontSize="sm" sx={{ mb: 2 }}>
          Performance (%)
        </Heading>
        <Input size="sm" placeholder="Performance" />
      </Box>
      <Box
        sx={{
          w: "full",
        }}
      >
        <Heading fontSize="sm" sx={{ mb: 2 }}>
          Note
        </Heading>
        <Textarea size="sm" placeholder="Member" />
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          Add Performace Review
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter>
          <Button size="sm" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" onClick={onClose} ml={3} colorScheme="whatsapp">
            Update
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdatePerformanceEvaluationDialog;
