import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateLeaveDays } from "@/Interfaces/LeaveDays.interface";

const { LEAVE_DAYS } = APIs;

export const fetchLeaveDays = async (payload: any) => {
  return request(LEAVE_DAYS.BASIC, "GET", payload);
};

export const getLeaveDaysById = async (id: string) => {
  return request(`${LEAVE_DAYS.GET_BY_ID}/${id}`, "GET");
};

export const createLeaveDays = async (payload: ICreateLeaveDays) => {
  return request(LEAVE_DAYS.BASIC, "POST_FORM_DATA", payload);
};

export const updateLeaveDays = async (id: string, payload: any) => {
  return request(`${LEAVE_DAYS.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};
