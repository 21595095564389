import { Image, Icon, VStack } from "@chakra-ui/react";

import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { Button } from "@/Components/Common";
import Routers from "@/Constants/Routers.constant";
import Utils from "@/Utils";

import notFoundImage from "@/Assets/404.jpg";

const NotFound = () => {
  return (
    <VStack
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image src={notFoundImage} />
      <Button
        variant="solid"
        colorScheme="red"
        onClick={() => Utils.redirect(Routers.SIGN_IN)}
        leftIcon={<Icon as={ArrowLeftIcon} />}
      >
        Back to Home
      </Button>
    </VStack>
  );
};

export default NotFound;
