import { Center, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { Button } from "@/Components/Common";
import { Routers } from "@/Constants";

const Error404 = () => {
  return (
    <Stack
      bgGradient="/bg1.jpg"
      bgAttachment="fixed"
      bgPosition="center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Center p={20} flex={1}>
        <Center
          px={10}
          py={14}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width="100%"
          maxWidth="xl"
          bgColor="white"
          borderRadius={10}
        >
          <Heading size="md" textAlign="center">
            Oops!
          </Heading>
          <Text fontSize={14} textAlign="center" mt={2} color="gray.500">
            We can&apos;t find that page.
          </Text>
          <Image maxWidth="250px" src="/404-error.png" />
          <Link to={Routers.SIGN_IN}>
            <Button colorScheme="red" mt={4}>
              Return Home
            </Button>
          </Link>
        </Center>
      </Center>
    </Stack>
  );
};

export default Error404;
