import React, { useEffect, useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Box,
  Text,
} from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";
import TimekeepingAction from "@/Actions/Timekeeping.action";
import { useTypedDispatch } from "@/Store";

const { resetMesageTimekeepingReducer } = TimekeepingAction;

interface SectionProps {
  open: boolean;
  onClose(): void;
  type: string;
}

const MessageTimekeepingDialog: React.FC<SectionProps> = ({
  onClose,
  type,
  open,
}) => {
  const dispatch = useTypedDispatch();
  const cancelRef = useRef<any>(null);

  useEffect(() => {
    if (!open) {
      dispatch(resetMesageTimekeepingReducer());
    }
  }, [open]);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{ position: "relative", pb: 0 }}
        >
          <Box>
            <Box
              sx={{
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%",
                top: -4,
                width: 35,
                height: 35,
                borderRadius: "50%",
                background: "#BB7154",
                cursor: "pointer",
              }}
              onClick={() => onClose()}
            >
              <CloseIcon
                color={"#fff"}
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              />
            </Box>
            <Text sx={{ fontSize: "20px", fontWeight: 700, mt: 4 }}>
              Error!
            </Text>
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            textAlign: "center",
            borderBottom: "10px solid #BB7154",
          }}
        >
          <Text sx={{ fontSize: "18px", mb: 4 }}>{type}</Text>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MessageTimekeepingDialog;
