import React, { useEffect } from "react";
import _ from "lodash";
import { Stack, useMediaQuery } from "@chakra-ui/react";
import { UserActions, ChatActions } from "@/Actions";

import { AdminLayout } from "@/Components/DefaultLayout";
import { RootState, useTypedDispatch } from "@/Store";
import ChatDesktop from "./ChatDesktop";
import ChatMobile from "./ChatMobile";

import { useSelector } from "react-redux";
import { IChatPerformAction } from "@/Interfaces/Chat.interface";

const { resetActionUserReducer } = UserActions;
const { fetchMessageChannel, fetchAllUsers, resetChatPerformAction } =
  ChatActions;

const Chat: React.FC = () => {
  const dispatch = useTypedDispatch();

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const chatPerformAction: IChatPerformAction | any = useSelector(
    (state: RootState) => _.get(state.CHAT, "performAction")
  );

  useEffect(() => {
    dispatch(fetchMessageChannel(true));
    dispatch(fetchAllUsers());
    return () => {
      dispatch(resetActionUserReducer());
      dispatch(resetChatPerformAction());
    };
  }, []);

  const _renderChat = () => (!isMobile ? <ChatDesktop /> : <ChatMobile />);

  const renderMain = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: `calc(100vh - ${
            isMobile && !_.isEmpty(chatPerformAction) ? "120px" : "96px"
          })`,
          bg: "#fff",
        }}
      >
        {_renderChat()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Chat;
