import { Center, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { Button } from "@/Components/Common";
import { Routers } from "@/Constants";

const VerifyEmail = () => {
  return (
    <Stack
      bgGradient="/bg8.jpg"
      bgAttachment="fixed"
      bgPosition="center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Center p={20} flex={1}>
        <Center
          px={10}
          py={14}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width="100%"
          maxWidth="xl"
          bgColor="white"
          borderRadius={10}
        >
          <Heading size="md" textAlign="center">
            Welcome to Metronic
          </Heading>
          <Text fontSize={14} textAlign="center" mt={2} color="gray.500">
            This is your opportunity to get creative and make a name that gives
            readers an idea
          </Text>
          <Image maxWidth="250px" src="/welcome.png" />
          <RouterLink to={Routers.SIGN_IN}>
            <Button colorScheme="red" mt={4}>
              Go to Dashboard
            </Button>
          </RouterLink>
        </Center>
      </Center>
    </Stack>
  );
};

export default VerifyEmail;
