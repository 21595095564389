import { Routers } from "@/Constants";
import Utils from "@/Utils";
import { create, ApisauceInstance } from "apisauce";
import _ from "lodash";
import { getSocket } from "./socket.config";
import { APIs } from "@/Constants";

export type API_METHOD =
  | "GET"
  | "GET_BLOB"
  | "PUT"
  | "POST"
  | "DEL"
  | "POST_FORM_DATA"
  | "PUT_FORM_DATA";
export type ERROR_TYPE = "ERROR" | "WARNING" | "SERVER_ERROR";
const AUTHORIZED_ERROR = [401];
const INTERNAL_SERVER_ERROR = [500, 501];
const BAD_REQUEST_ERROR = [400, 422];
const WRONG_URL_ERROR = [404];

const EXCLUDE_API = [
  APIs.AUTHENTICATION.LOGIN,
  APIs.AUTHENTICATION.FORGOT_PASSWORD,
  APIs.AUTHENTICATION.REFRESH_TOKEN,
  "holidays",
  "kanban-board/get-by-id/",
];

const socket = getSocket();

const getAPIConfig = async (url: string, method: string) => {
  const BASE_URL = import.meta.env.VITE_BE_URL;
  const language = Utils.getSavedLanguage();

  const api = create({
    baseURL: `${BASE_URL}api/`,
    headers: {
      Accept: "application/json",
      locale: language,
    },
  });

  let flagCheckToken = true;
  await Promise.all(
    _.map(EXCLUDE_API, (apiExclude: string) => {
      if (_.includes(url, apiExclude)) {
        if (_.includes(url, "holidays")) {
          if (method == "GET") flagCheckToken = false;
        } else {
          flagCheckToken = false;
        }
      }
    })
  );

  if (flagCheckToken == true) {
    const validateToken = await Utils.checkTokenLifeTime(api);
    if (!validateToken) {
      Utils.redirect(Routers.SIGN_IN);
      return null;
    } else api.setHeader("Authorization", `Bearer ${validateToken}`);
  }

  return api;
};

// Handle error response
const handleErrorResponse = (
  type: ERROR_TYPE,
  params: { message: string; duration: number }
) => {
  const { message, duration } = params;
  const response = {
    type,
    message,
    duration,
  };
  return response;
};

// Handle response
const handleResponse = (res: any, resolve: any, reject: any) => {
  const message = _.get(res, "data.message");
  const duration = _.get(res, "duration") || 0;
  const status = _.get(res, "status");
  const problem = _.get(res, "problem");

  if (_.includes(AUTHORIZED_ERROR, status)) {
    Utils.clearAllSavedData();
    Utils.redirect(Routers.SIGN_IN);
    socket.disconnect();
    return reject(handleErrorResponse("WARNING", { message, duration }));
  }

  if (_.includes(INTERNAL_SERVER_ERROR, status))
    return reject(handleErrorResponse("ERROR", { message, duration }));

  if (_.includes(BAD_REQUEST_ERROR, status))
    return reject(
      handleErrorResponse("WARNING", {
        message: `Bad request: ${message}`,
        duration,
      })
    );
  if (_.includes(WRONG_URL_ERROR, status))
    return reject(
      handleErrorResponse("WARNING", { message: `URL not found`, duration })
    );

  if (problem)
    return reject(handleErrorResponse("SERVER_ERROR", { message, duration }));
  return resolve(res);
};

const post = async (api: ApisauceInstance, url: string, data?: any) => {
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const postFormData = async (api: ApisauceInstance, url: string, data?: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const res = await api.post(url, data, { headers });
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const putFormData = async (api: ApisauceInstance, url: string, data?: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return new Promise(async (resolve, reject) => {
    const res = await api.put(url, data, { headers });
    return handleResponse(res, resolve, reject);
  });
};

const get = async (api: ApisauceInstance, url: string, data?: any) => {
  return new Promise(async (resolve, reject) => {
    const res = await api.get(url, data);
    return handleResponse(res, resolve, reject);
  });
};

const getBlob = async (api: ApisauceInstance, url: string, data?: any) => {
  return new Promise(async (resolve, reject) => {
    const res = await api.get(url, data, { responseType: "blob" });
    handleResponse(res, resolve, reject);
  });
};

const put = async (api: ApisauceInstance, url: string, data?: any) => {
  return new Promise(async (resolve, reject) => {
    const res = await api.put(url, data);
    return handleResponse(res, resolve, reject);
  });
};

const del = async (api: ApisauceInstance, url: string, data?: any) => {
  return new Promise(async (resolve, reject) => {
    const res = await api.delete(url, data);
    return handleResponse(res, resolve, reject);
  });
};

const sendRequest = async (url: string, method: API_METHOD, params?: any) => {
  const api = await getAPIConfig(url, method);
  if (!api) return;
  if (method === "POST") return await post(api, url, params);
  if (method === "GET") return await get(api, url, params);
  if (method === "GET_BLOB") return getBlob(api, url, params);
  if (method === "PUT") return await put(api, url, params);
  if (method === "POST_FORM_DATA") return await postFormData(api, url, params);
  if (method === "DEL") return await del(api, url, params);
  if (method === "PUT_FORM_DATA") return await putFormData(api, url, params);
  return true;
};

export default sendRequest;
