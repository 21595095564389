import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useMiniSearch } from "react-minisearch";
import {
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Avatar,
  List,
  ListItem,
  Text,
  Icon,
  IconButton,
  Tooltip,
  Checkbox,
  useOutsideClick,
  useMediaQuery,
} from "@chakra-ui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  payload?: any[];
  onChangeValue(userId: string | string[]): void;
  disabledIds?: string[];
  isClear?: boolean;
  isShowCheckbox?: boolean;
  multiple?: boolean;
  placeholder?: string;
  sx?: object;
}

const SelectUser: React.FC<ISectionProps> = ({
  payload,
  onChangeValue,
  disabledIds,
  isClear,
  isShowCheckbox,
  multiple = false,
  placeholder,
  sx,
}) => {
  const t = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const containerRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  const { search, searchResults, autoSuggest } = useMiniSearch(payload || [], {
    fields: ["name", "email"],
    storeFields: ["name", "email"],
  });

  useOutsideClick({
    ref: containerRef,
    handler: () => setIsFocused(false),
  });

  useEffect(() => {
    if (!_.isEmpty(payload)) {
    }
  }, [payload]);

  useEffect(() => {
    if (multiple && selectedValues.length > 0) {
      const selectedIds = selectedValues.map((user) => user.id);
      onChangeValue(selectedIds);
    } else if (selectedValue) {
      onChangeValue(selectedValue?.id);
    }
  }, [selectedValue, selectedValues, multiple]);

  useEffect(() => {
    if (isClear) {
      if (multiple) {
        setSelectedValues([]);
      } else {
        setSelectedValue("");
      }
      onChangeValue(multiple ? [] : "");
    }
  }, [isClear, multiple]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    const value = event.target.value;
    search(value, { prefix: true, fuzzy: 0.2 });
    setSearchValue(value);
    if (value.length >= 2) {
      autoSuggest(value, { prefix: true, fields: ["name", "email"] });
    }
  };

  const handleReset = (event: any) => {
    event.stopPropagation();
    if (multiple) {
      setSelectedValues([]);
      onChangeValue([]);
    } else {
      setSelectedValue(undefined); // Set to undefined to clear the selected value
      onChangeValue(""); // Clear the selected value
      setSearchValue(""); // Clear the search value
    }
  };

  return (
    <Box position="relative" zIndex={99} sx={sx} ref={containerRef}>
      <InputGroup>
        <Input
          readOnly={selectedValue}
          value={
            multiple
              ? selectedValues.map((user) => user.name).join(", ") ||
                searchValue
              : selectedValue?.name || searchValue
          }
          onChange={handleSearchChange}
          size="sm"
          cursor={selectedValue ? "not-allowed" : "default"}
          placeholder={placeholder || t("label.searchUserByNameEmail")}
          onFocus={() => setIsFocused(true)}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
        <InputRightElement
          sx={{
            height: "100%",
          }}
        >
          {(selectedValue || (multiple && selectedValues.length > 0)) && (
            <Tooltip label={t("tooltip.remove")} hasArrow>
              <IconButton
                isRound
                sx={{
                  background: "none",
                }}
                size="sm"
                icon={<Icon as={XMarkIcon} />}
                aria-label={""}
                onClick={handleReset}
              />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>

      {isFocused && (
        <Box
          sx={{
            position: "absolute",
            top: "calc(100% + 5px)",
            left: "50%",
            width: "100%",
            transform: "translateX(-50%)",
            bgColor: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            maxH: "200px",
            overflow: "auto",
          }}
        >
          <List>
            {searchValue === "" &&
              _.map(payload, (user, index) => {
                const roleNames = _.map(user?.roles, (userRole) =>
                  userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                    ? `Staff`
                    : _.startCase(userRole?.role?.name)
                );
                const concatenatedRoleNames = roleNames.join(", ");
                return (
                  <ListItem
                    _hover={{
                      background: _.includes(disabledIds, user.id)
                        ? ""
                        : "gray.300",
                    }}
                    key={index}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!_.includes(disabledIds, user.id)) {
                        if (multiple) {
                          const isSelected = _.some(selectedValues, {
                            id: user.id,
                          });

                          if (isSelected) {
                            const newSelectedValues = selectedValues.filter(
                              (selectedUser) => selectedUser.id !== user.id
                            );
                            setSelectedValues(newSelectedValues);
                          } else {
                            const newSelectedValues = [...selectedValues, user];
                            setSelectedValues(newSelectedValues);
                          }
                        } else {
                          setSelectedValue(user);
                          setIsFocused(false);
                        }
                      }
                    }}
                    sx={{
                      padding: 2,
                      background:
                        (multiple && _.some(selectedValues, { id: user.id })) ||
                        selectedValue === user ||
                        _.includes(disabledIds, user.id)
                          ? "gray.300"
                          : "inherit",
                      cursor: _.includes(disabledIds, user.id)
                        ? "not-allowed"
                        : "pointer",
                      borderBottom: "1px solid gray",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          width: "100%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {isShowCheckbox && (
                          <Checkbox
                            isChecked={
                              (multiple &&
                                _.some(selectedValues, { id: user?.id })) ||
                              selectedValue?.id === user?.id
                            }
                            sx={{
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        <Avatar size="sm" src={user.avatar} name={user.name} />
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            sx={{
                              maxW: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.name}
                          >
                            {user.name}
                          </Text>
                          <Text
                            noOfLines={1}
                            fontSize="sm"
                            sx={{
                              maxW: "200px",
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.email}
                          >
                            {user.email}
                          </Text>
                          {isMobile && (
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              sx={{
                                maxW: "200px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden !important",
                              }}
                              title={user.name}
                            >
                              {concatenatedRoleNames}
                            </Text>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        {!isMobile && (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            sx={{
                              maxW: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.name}
                          >
                            {concatenatedRoleNames}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            {!_.isEmpty(searchResults) &&
              _.map(searchResults, (user, index) => {
                const roleNames = _.map(user?.roles, (userRole) =>
                  userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                    ? `Staff`
                    : _.startCase(userRole?.role?.name)
                );
                const concatenatedRoleNames = roleNames.join(", ");
                return (
                  <ListItem
                    _hover={{
                      background: _.includes(disabledIds, user.id)
                        ? ""
                        : "gray.300",
                    }}
                    key={index}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!_.includes(disabledIds, user.id)) {
                        if (multiple) {
                          const isSelected = _.some(selectedValues, {
                            id: user.id,
                          });

                          if (isSelected) {
                            const newSelectedValues = selectedValues.filter(
                              (selectedUser) => selectedUser.id !== user.id
                            );
                            setSelectedValues(newSelectedValues);
                          } else {
                            const newSelectedValues = [...selectedValues, user];
                            setSelectedValues(newSelectedValues);
                          }
                        } else {
                          setSelectedValue(user);
                          setIsFocused(false);
                        }
                      }
                    }}
                    sx={{
                      padding: 2,
                      background:
                        (multiple && _.some(selectedValues, { id: user.id })) ||
                        selectedValue === user ||
                        _.includes(disabledIds, user.id)
                          ? "gray.300"
                          : "inherit",
                      cursor: _.includes(disabledIds, user.id)
                        ? "not-allowed"
                        : "pointer",
                      borderBottom: "1px solid gray",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          width: "100%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {isShowCheckbox && (
                          <Checkbox
                            isChecked={
                              (multiple &&
                                _.some(selectedValues, { id: user?.id })) ||
                              selectedValue?.id === user?.id
                            }
                            sx={{
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        <Avatar size="sm" src={user.avatar} name={user.name} />
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            sx={{
                              maxW: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.name}
                          >
                            {user.name}
                          </Text>
                          <Text
                            noOfLines={1}
                            fontSize="sm"
                            sx={{
                              maxW: "200px",
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.email}
                          >
                            {user.email}
                          </Text>
                          {isMobile && (
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              sx={{
                                maxW: "200px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden !important",
                              }}
                              title={user.name}
                            >
                              {concatenatedRoleNames}
                            </Text>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        {!isMobile && (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            sx={{
                              maxW: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden !important",
                            }}
                            title={user.name}
                          >
                            {concatenatedRoleNames}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            {!_.isEmpty(searchResults) && _.isEmpty(payload) && (
              <ListItem
                sx={{
                  cursor: "pointer",
                  padding: "2",
                }}
              >
                <Text fontSize="sm">No data were found</Text>
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SelectUser;
