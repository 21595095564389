import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const RoundedContainer = styled(Box)(() => ({
  boxShadow: '-8px 8px 24px -4px rgba(145, 158, 171, 0.08)',
  borderRadius: "8px",
  backgroundColor: "white",
  padding: "14px",
}));

export default RoundedContainer;
