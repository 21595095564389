import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import {
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
} from "@heroicons/react/24/outline";

import CommonColors from "@/Themes/CommonColors";
import "rc-slider/assets/index.css";
import Utils from "@/Utils";
// import TooltipSlider from "./TooltipSlider";

interface ISectionProps {
  value: number;
  defaultValue?: number;
  onChange(newValue: number | number[]): void;
  disabled?: boolean;
  step: number;
  styles?: object;
  calendarWidth: number;
  maxDays: number;
}

const SingleSlider: React.FC<ISectionProps> = ({
  value,
  defaultValue,
  onChange,
  disabled,
  styles,
  step,
  calendarWidth,
  maxDays,
}) => {
  const language = Utils.getSavedLanguage();
  const [max, setMax] = useState<number>(1);

  useEffect(() => {
    if (calendarWidth > 0) {
      const minSlot = language === "vi" ? 20 : 10;
      const max = calendarWidth / minSlot;
      const formatValue = parseInt(max.toString());
      setMax(maxDays < formatValue ? maxDays : formatValue);
    }
  }, [calendarWidth, maxDays, language]);

  const plus = () => {
    const remaining = max - value;
    const addValue = Math.min(remaining, step);
    const newValue = value + addValue;
    onChange(newValue);
  };

  const minus = () => {
    const remaining = value - 1;
    const subtractValue = Math.min(remaining, step);
    const newValue = value - subtractValue;
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ".rc-slider-rail": {
          background: CommonColors.dustyCoral,
        },
        ".rc-slider-track.rc-slider-track-1.rc-slider-track-draggable": {
          "&:hover": {
            cursor: "grab",
          },
        },
        ".rc-slider-handle": {
          "&:hover": {
            cursor: "col-resize",
          },
        },
        ...styles,
      }}
    >
      <IconButton
        aria-label=""
        isRound
        sx={{
          bg: "none",
        }}
        onClick={minus}
        isDisabled={value === 1 || disabled}
      >
        <Icon boxSize={5} as={MagnifyingGlassMinusIcon} />
      </IconButton>
      {/* <TooltipSlider
        isDragging={false}
        step={step}
        max={max}
        min={1}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        tipFormatter={(value) => value}
        styles={{
          tracks: {
            background: CommonColors.burntSienna,
          },
          track: {
            background: "transparent",
          },
        }}
        disabled={disabled}
      /> */}
      <Slider
        step={step}
        max={max}
        min={1}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        isDisabled={disabled}
      >
        <SliderTrack>
          <SliderFilledTrack bg={CommonColors.burntSienna} />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <IconButton
        aria-label=""
        isRound
        sx={{
          bg: "none",
        }}
        onClick={plus}
        isDisabled={value === max || disabled}
      >
        <Icon boxSize={5} as={MagnifyingGlassPlusIcon} />
      </IconButton>
    </Box>
  );
};

export default SingleSlider;
