import React, { useMemo } from 'react';
import 'chart.js/auto';
import { ChartOptions, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

interface ISectionProps {
  data: ChartData<'bar'>;
  title: string;
  verticalText?: string;
  horizontalText?: string;
  subtitle?: boolean;
  isShowLegend?: boolean;
  axis?: 'x' | 'y';
}

const BarChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const {
    data,
    title,
    verticalText,
    horizontalText,
    subtitle = true,
    isShowLegend = true,
    axis = 'x',
  } = props;

  const options: ChartOptions<'bar'> = useMemo(() => {
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      indexAxis: axis,
      plugins: {
        filler: {
          propagate: false,
        },
        title: {
          display: true,
          text: title,
          align: 'start',
          fullSize: true,
          font: {
            size: 19,
          },
        },
        subtitle: {
          display: subtitle,
          align: 'end',
          position: 'bottom',
          text: '(*) Click on legend to show/hide element(s)',
        },
        legend: {
          display: isShowLegend,
          position: 'top',
          labels: {
            usePointStyle: true,
          },
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: Boolean(horizontalText),
            text: horizontalText,
          },
        },
        y: {
          display: true,
          title: {
            display: Boolean(verticalText),
            text: verticalText,
          },
        },
      },
    };
  }, [props]);

  return <Bar options={{ ...options }} data={data} />;
};

export default BarChart;

// cubicInterpolationMode: 'monotone',
// tension: 0.4,
// use for smooth line
