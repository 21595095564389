import React, { useState } from "react";
import _ from "lodash";
import {
  Box,
  Avatar,
  Text,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
} from "@chakra-ui/react";
import Utils from "@/Utils";
import { useTypedDispatch } from "@/Store";
import { UserActions } from "@/Actions";

const { performAction } = UserActions;
interface ISectionProps {
  data: any[];
}

const maxAvatars = 2;

const AvatarGroupWithOverflow: React.FC<ISectionProps> = ({ data }) => {
  const dispatch = useTypedDispatch();
  const visibleAvatars = data.slice(0, maxAvatars);
  const overflowAvatars = data.slice(maxAvatars);
  const overflowCount = data.length - maxAvatars;
  const userData = Utils.getSavedUserData();
  const [isShowPopper, setIsShowPopper] = useState(false);

  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const _renderPopper = () => (
    <Popover
      isOpen={isShowPopper}
      onClose={() => setIsShowPopper(false)}
      placement="auto-start"
    >
      <PopoverTrigger>
        <Avatar
          size="sm"
          sx={{
            cursor: "pointer",
            bg: "gray.300",
            svg: {
              display: "none",
            },
          }}
          onClick={() => setIsShowPopper(true)}
        >
          <Text fontSize="sm" fontWeight="bold">
            +{overflowCount}
          </Text>
        </Avatar>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            width: "max-content",
          }}
        >
          <PopoverArrow />
          <PopoverBody>
            {_.map(overflowAvatars, (userPayload, index) => (
              <Tooltip
                key={index}
                label={userPayload?.user?.userData?.fullName}
                hasArrow
              >
                <Avatar
                  size="sm"
                  name={userPayload?.user?.userData?.fullName}
                  src={userPayload?.user?.userData?.avatar?.path}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    canViewUserProfile &&
                    dispatch(
                      performAction("viewProfile", userPayload?.id, userPayload)
                    )
                  }
                />
              </Tooltip>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0,
      }}
    >
      {visibleAvatars.map((userPayload, index) => (
        <Tooltip
          key={index}
          label={userPayload?.user?.userData?.fullName}
          hasArrow
        >
          <Avatar
            size="sm"
            name={userPayload?.user?.userData?.fullName}
            src={userPayload?.user?.userData?.avatar?.path}
            sx={{
              cursor: "pointer",
            }}
            onClick={() =>
              canViewUserProfile &&
              dispatch(
                performAction("viewProfile", userPayload?.id, userPayload)
              )
            }
          />
        </Tooltip>
      ))}
      {overflowCount > 0 && _renderPopper()}
    </Box>
  );
};

export default AvatarGroupWithOverflow;
