import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  DndContext,
  useSensor,
  useSensors,
  closestCorners,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  // pointerWithin,
  // getFirstCollision,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
// import { arrayMove } from "@dnd-kit/sortable";
import List from "./List";
import Column from "./Column";
import CardItem from "./CardItem";
import { Box } from "@chakra-ui/react";
import { ConfirmDialog } from "@/Components/Popup";
import { ProjectActions } from "@/Actions";
import Utils from "@/Utils";
import { useTypedDispatch } from "@/Store";
import { ICardStructure, IColumnStructure } from "@/Interfaces/Board.interface";
import { ENUMS } from "@/Constants";

const { changeProjectStatus } = ProjectActions;

interface ISectionProps {
  data: any[];
}

const ACTIVE_DRAG_ITEM_TYPE = {
  COLUMN: "ACTIVE_DRAG_ITEM_TYPE_COLUMN",
  CARD: "ACTIVE_DRAG_ITEM_TYPE_CARD",
};

const Board: React.FC<ISectionProps> = ({ data }) => {
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const userRole =
    _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode) || [];

  const customDropAnimaton = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 500,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [orderedColumns, setOrderedColumns] = useState<any[]>([]);
  const [backupOrderedColumns, setBackupOrderedColumns] = useState<any>(null);

  const [activeDragItemId, setActiveDragItemId] = useState<any>(null);
  const [activeDragItemType, setActiveDragItemType] = useState<any>(null);
  const [activeDragItemData, setActiveDragItemData] = useState<any>(null);
  const [overDragItemData, setOverDragItemData] = useState<any>(null);

  const [oldColumnWhenDraggingCard, setOldColumnWhenDraggingCard] =
    useState<any>(null);

  const [isShowConfirm, setShowIsConfirm] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  // const lastOverId = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(data)) setOrderedColumns(data);
  }, [data]);

  useEffect(() => {
    if (
      isShowConfirm &&
      oldColumnWhenDraggingCard &&
      overDragItemData &&
      activeDragItemData
    ) {
      const msg = `You're changing the status of ${activeDragItemData?.name} project  from ${oldColumnWhenDraggingCard?.name} to ${overDragItemData?.name}.`;
      setConfirmMsg(msg);
    }
  }, [
    isShowConfirm,
    oldColumnWhenDraggingCard,
    overDragItemData,
    activeDragItemData,
  ]);

  useEffect(() => {
    if (confirmed) handleChangeStatus();
  }, [confirmed]);

  // const collisionDetectionStrategy = useCallback(
  //   (args: any) => {
  //     if (activeDragItemType.COLUMN)
  //       return closestCorners({
  //         ...args,
  //       });
  //     // Tìm các điểm giao nhau va chạm với con trỏ
  //     const pointerIntersections = pointerWithin(args);
  //     if (!pointerIntersections?.length) return [];

  //     // Thuật toán phát triển va chạm trả về một mảng các va chạm
  //     // const intersections = !!pointerIntersections.length
  //     //   ? pointerIntersections
  //     //   : rectIntersection(args);
  //     // Tìm overId đầu tiên trong intersections

  //     let overId: any = getFirstCollision(pointerIntersections, "id");

  //     if (overId) {
  //       const checkColumn = orderedColumns?.find(
  //         (column) => column.id === overId
  //       );
  //       if (checkColumn) {
  //         overId = closestCorners({
  //           ...args,
  //           droppableContainers: args.droppableContainers.filter(
  //             (container: any) =>
  //               container.id !== overId &&
  //               checkColumn?.cardOrderIds?.includes(container.id)
  //           ),
  //         })[0]?.id;
  //       }
  //       return [{ id: overId }];
  //     }
  //     return lastOverId?.current ? [{ id: lastOverId.current }] : [];
  //   },
  //   [activeDragItemType]
  // );

  const generatePlaceholderCard = (column: any) => {
    return {
      id: `${column.id}-placeholder-card`,
      columnId: column.id,
      placeholderCard: true,
    };
  };

  const handleDragStart = (event: any) => {
    setConfirmed(false);
    setBackupOrderedColumns([...orderedColumns]);
    setActiveDragItemId(event?.active?.id);
    const dragItemType = event?.active?.data?.current?.columnId
      ? ACTIVE_DRAG_ITEM_TYPE.CARD
      : ACTIVE_DRAG_ITEM_TYPE.COLUMN;
    setActiveDragItemType(dragItemType);
    setActiveDragItemData(event?.active?.data?.current);

    //set old column when dragging card
    if (dragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD)
      setOldColumnWhenDraggingCard(
        findColumnByCardId(event?.active?.id, orderedColumns)
      );
  };

  const handleDragOver = (event: any) => {
    if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN) return;
    const { active, over } = event;

    if (!active || !over) return;
    const {
      id: activeDraggingCardId,
      data: { current: activeDraggingCardData },
    } = active;
    const { id: overCardId } = over;

    const activeColumn = findColumnByCardId(
      activeDraggingCardId,
      orderedColumns
    );

    const overColumn = findColumnByCardId(overCardId, orderedColumns);

    if (!activeColumn || !overColumn) return;
    if (activeColumn.id !== overColumn.id) {
      setOverDragItemData(overColumn);
      moveCardBetweenDifferentColumns(
        overColumn,
        overCardId,
        active,
        over,
        activeColumn,
        activeDraggingCardId,
        activeDraggingCardData
      );
    }
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (
      !over ||
      !active ||
      activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN
    ) {
      setOrderedColumns(backupOrderedColumns);
      return;
    }

    //handle drag card
    if (activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD) {
      const {
        id: activeDraggingCardId,
        data: { current: activeDraggingCardData },
      } = active;
      const { id: overCardId } = over;
      const activeColumn = findColumnByCardId(
        activeDraggingCardId,
        orderedColumns
      );

      const oldActiveColumn = findColumnByCardId(
        activeDraggingCardId,
        backupOrderedColumns
      );

      const overColumn = findColumnByCardId(overCardId, orderedColumns);

      if (!activeColumn || !overColumn) {
        setOrderedColumns(backupOrderedColumns);
        return;
      }
      const canDragAndModifyStatus =
        activeDraggingCardData?.extendProps?.type ===
        ENUMS.PROJECT_TYPE.EXTERIOR
          ? activeDraggingCardData?.extendProps?.userCreatedId ===
              userData?.id ||
            _.some(
              userRole,
              (uRole) =>
                uRole === ENUMS.ROLES.ADMIN ||
                uRole === ENUMS.ROLES.MANAGER ||
                uRole === ENUMS.ROLES.HUMAN_RESOURCES
            )
          : Utils.handleCheckCanDragAndModifyState(
              userRole,
              oldColumnWhenDraggingCard?.status,
              overColumn?.status
            );

      if (!canDragAndModifyStatus) {
        setOrderedColumns(backupOrderedColumns);
        return;
      }

      if (oldActiveColumn?.id !== overColumn?.id) setShowIsConfirm(true);

      if (oldColumnWhenDraggingCard?.id !== overColumn?.id) {
        setOrderedColumns((prevColumns) => {
          const overCardIndex = overColumn?.cards?.findIndex(
            (card: ICardStructure) => card.id === overCardId
          );

          let newCardIndex;
          const isBelowOverItem =
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;
          const modifier = isBelowOverItem ? 1 : 0;
          newCardIndex =
            overCardIndex >= 0
              ? overCardIndex + modifier
              : overColumn?.cards?.length + 1;

          //clone deep
          const nextColumns = _.cloneDeep(prevColumns);
          const nextActiveColumn = _.find(
            nextColumns,
            (column) => column.id === activeColumn.id
          );
          const nextOverColumn = _.find(
            nextColumns,
            (column) => column.id === overColumn.id
          );
          if (nextActiveColumn) {
            // move card
            nextActiveColumn.cards = _.filter(
              nextActiveColumn?.cards,
              (card) => card.id !== activeDraggingCardId
            );

            // thêm placeholder cho column rỗng: bị kéo card đi
            if (_.isEmpty(nextActiveColumn.cards)) {
              nextActiveColumn.cards = [
                generatePlaceholderCard(nextActiveColumn),
              ];
            }

            nextActiveColumn.cardOrderIds = _.map(
              nextActiveColumn?.cards,
              (card) => card.id
            );
          }

          if (nextOverColumn) {
            // move card
            nextOverColumn.cards = nextOverColumn.cards.filter(
              (card: ICardStructure) => card.id !== activeDraggingCardId
            );
            //Thêm card đang kéo vào overColumn theo vị trí index mới
            nextOverColumn.cards = nextOverColumn.cards.toSpliced(
              newCardIndex,
              0,
              {
                ...activeDraggingCardData,
                columnId: nextOverColumn.id,
              }
            );
            //xóa cái placeholder card đi nếu nó có tồn tại
            nextOverColumn.cards = nextOverColumn.cards.filter(
              (card: ICardStructure) => !card?.placeholderCard
            );

            // Cập nhật mảng cardOrderIds
            nextOverColumn.cardOrderIds = nextOverColumn?.cards?.map(
              (card: ICardStructure) => card.id
            );
          }

          return nextColumns;
        });
      } else {
        //same column
        // get old position
        // const oldCardIndex = oldColumnWhenDraggingCard?.cards?.findIndex(
        //   (card: ICardStructure) => card.id === activeDragItemId
        // );
        // // get new position
        // const newCardIndex = overColumn?.cards?.findIndex(
        //   (card: ICardStructure) => card.id === overCardId
        // );
        // const dndOrderedCards: any = arrayMove(
        //   oldColumnWhenDraggingCard?.cards,
        //   oldCardIndex,
        //   newCardIndex
        // );
        // setOrderedColumns((prevColumns) => {
        //   const nextColumns = _.cloneDeep(prevColumns);
        //   const targetColumn = nextColumns?.find(
        //     (column) => column.id === overColumn.id
        //   );
        //   targetColumn.cards = dndOrderedCards;
        //   targetColumn.cardOrderIds = dndOrderedCards?.map(
        //     (card: ICardStructure) => card.id
        //   );
        //   return nextColumns;
        // });
      }
    }
  };

  const moveCardBetweenDifferentColumns = (
    overColumn: IColumnStructure,
    overCardId: string,
    active: any,
    over: any,
    activeColumn: IColumnStructure,
    activeDraggingCardId: string,
    activeDraggingCardData: any
  ) => {
    if (!activeColumn || !overColumn) return;

    const overCardIndex = overColumn?.cards?.findIndex(
      (card: ICardStructure) => card.id === overCardId
    );
    let newCardIndex;
    const isBelowOverItem =
      active.rect.current.translated &&
      active.rect.current.translated.top > over.rect.top + over.rect.height;
    const modifier = isBelowOverItem ? 1 : 0;
    newCardIndex =
      overCardIndex >= 0
        ? overCardIndex + modifier
        : overColumn?.cards?.length + 1;

    const nextActiveColumn = { ...activeColumn };
    const nextOverColumn = { ...overColumn };

    if (nextActiveColumn) {
      // Move the card from the active column
      nextActiveColumn.cards = nextActiveColumn.cards.filter(
        (card: ICardStructure) => card.id !== activeDraggingCardId
      );

      // Add a placeholder if the active column becomes empty
      if (nextActiveColumn.cards.length === 0) {
        nextActiveColumn.cards = [
          generatePlaceholderCard(nextActiveColumn) as any,
        ];
      }

      nextActiveColumn.cardOrderIds = nextActiveColumn.cards.map(
        (card: ICardStructure) => card.id
      );
    }

    if (nextOverColumn) {
      // Remove the card from the over column
      nextOverColumn.cards = nextOverColumn.cards.filter(
        (card: ICardStructure) => card.id !== activeDraggingCardId
      );

      // Place the dragged card into the overColumn at the determined index
      nextOverColumn.cards.splice(newCardIndex, 0, {
        ...activeDraggingCardData,
        columnId: nextOverColumn.id,
      });

      // Remove placeholder card if it exists
      nextOverColumn.cards = nextOverColumn.cards.filter(
        (card: ICardStructure) => !card.placeholderCard
      );

      nextOverColumn.cardOrderIds = nextOverColumn.cards.map(
        (card: ICardStructure) => card.id
      );
    }

    // Update columns accordingly
    setOrderedColumns((prevColumns) => {
      const nextColumns = prevColumns.map((column) => {
        if (column.id === nextActiveColumn.id) {
          return nextActiveColumn;
        } else if (column.id === nextOverColumn.id) {
          return nextOverColumn;
        }
        return column;
      });
      return nextColumns;
    });
  };

  const findColumnByCardId = (cardId: string, data: any[]) =>
    data?.find((column) =>
      column?.cards?.map((card: ICardStructure) => card.id)?.includes(cardId)
    );

  const handleChangeStatus = () => {
    dispatch(changeProjectStatus(activeDragItemId, overDragItemData.status));
  };

  return (
    <Box>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCorners}
      >
        <Box w="100%" h="100%" overflow="auto">
          <List columns={orderedColumns} />
          <DragOverlay dropAnimation={customDropAnimaton}>
            {(!activeDragItemData || !activeDragItemType) && null}
            {activeDragItemData &&
              activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.COLUMN && (
                <Column column={activeDragItemData} />
              )}
            {activeDragItemData &&
              activeDragItemType === ACTIVE_DRAG_ITEM_TYPE.CARD && (
                <CardItem card={activeDragItemData} />
              )}
          </DragOverlay>
        </Box>
      </DndContext>
      <ConfirmDialog
        isOpen={isShowConfirm && !!confirmMsg}
        onClose={() => {
          setBackupOrderedColumns(null);
          setActiveDragItemId(null);
          setActiveDragItemType(null);
          setActiveDragItemData(null);
          setOverDragItemData(null);
          setShowIsConfirm(false);
          setConfirmMsg("");
          setOrderedColumns(backupOrderedColumns);
          setConfirmed(false);
        }}
        actionType="save"
        onAction={() => {
          setShowIsConfirm(false);
          setConfirmed(true);
        }}
        body={confirmMsg}
      />
    </Box>
  );
};

export default Board;
