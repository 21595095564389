import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 20,
};

const initialState = {
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isFetchOutsideLoading: false,
  payload: [],
  count: {},
  details: {},
  pagination: DEFAULT_PAGINATION,
  meta: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_NOTIFICATION_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
        isGetLoading: false,
        isActionLoading: false,
        isFetchOutsideLoading: false,
      };
    case ActionTypes.SET_NOTIFICATION_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: false,
        isFetchOutsideLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.SET_NOTIFICATION_GET_LOADING:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: true,
        isActionLoading: false,
        isFetchOutsideLoading: false,
      };
    case ActionTypes.SET_NOTIFICATION_ACTION_LOADING:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: true,
        isFetchOutsideLoading: false,
      };

    case ActionTypes.SET_PAGINATION_NOTIFICATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_META_NOTIFICATION:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.RESET_NOTIFICATIONS_REDUCER:
      return initialState;

    case ActionTypes.FETCH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    }
    case ActionTypes.FETCH_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }
    case ActionTypes.FETCH_OUTSIDE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        count: payload,
      };
    }
    case ActionTypes.FETCH_OUTSIDE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }
    case ActionTypes.GET_NOTIFICATION_BY_ID_SUCCESS: {
      return {
        ...state,
        isGetLoading: false,
        details: payload,
      };
    }
    case ActionTypes.GET_NOTIFICATION_BY_ID_FAILURE: {
      return {
        ...state,
        isGetLoading: false,
      };
    }
    case ActionTypes.MARK_AS_READ_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.MARK_AS_READ_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.MARK_ALL_AS_READ_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        count: {},
      };
    }
    case ActionTypes.MARK_ALL_AS_READ_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }

    case ActionTypes.MARK_ALL_AS_READ_OUTSIDE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.MARK_ALL_AS_READ_OUTSIDE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }

    default:
      return state;
  }
};
