import React, { useState, useEffect } from "react";
import _ from "lodash";

import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  type InputProps,
  Stack,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  defaultValue?: any;
  onTimeChange(newTime: string[]): void;
}

const TimePicker = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      defaultValue = ["00:00", "00:00"],
      onTimeChange,
      ...props
    },
    ref
  ) => {
    const [startHours, setStartHours] = useState("00");
    const [startMinutes, setStartMinutes] = useState("00");
    const [endHours, setEndHours] = useState("00");
    const [endMinutes, setEndMinutes] = useState("00");

    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {}, [ref]);

    useEffect(() => {
      if (defaultValue) {
        const { startHours, startMinutes, endHours, endMinutes } = defaultValue;

        if (startHours) {
          setStartHours(startHours.toString().padStart(2, "0"));
        }

        if (endHours) {
          setEndHours(endHours.toString().padStart(2, "0"));
        }

        if (startMinutes)
          setStartMinutes(startMinutes.toString().padStart(2, "0"));

        if (endMinutes) setEndMinutes(endMinutes.toString().padStart(2, "0"));
      }
    }, [defaultValue]);

    const handleHoursChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      setHours: React.Dispatch<React.SetStateAction<string>>
    ) => {
      let value = event.target.value;
      value = Math.min(Math.max(parseInt(value) || 0, 0), 23).toString(); // Update max value to 23 for 24-hour format
      setHours(value.padStart(2, "0"));
      //  updateOnChangeCallback();
    };

    const handleMinutesChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      setMinutes: React.Dispatch<React.SetStateAction<string>>
    ) => {
      let value = event.target.value;
      value = Math.min(Math.max(parseInt(value) || 0, 0), 59).toString(); // Convert to string
      setMinutes(value.padStart(2, "0"));
      //  updateOnChangeCallback();
    };

    //const updateOnChangeCallback = () => {
    //  if (
    //    startHours !== "" &&
    //    startMinutes !== "" &&
    //    endHours !== "" &&
    //    endMinutes !== ""
    //  ) {
    //    const formatTime = (hours: string, minutes: string) => {
    //      return `${hours.toString().padStart(2, "0")}:${minutes
    //        .toString()
    //        .padStart(2, "0")}:00`;
    //    };

    //    const startTime = formatTime(startHours, startMinutes);

    //    const endTime = formatTime(endHours, endMinutes);
    //    onTimeChange([startTime, endTime]);
    //  }
    //};

    useEffect(() => {
      if (
        startHours !== "" &&
        startMinutes !== "" &&
        endHours !== "" &&
        endMinutes !== ""
      ) {
        const formatTime = (hours: string, minutes: string) => {
          return `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:00`;
        };

        const startTime = formatTime(startHours, startMinutes);

        const endTime = formatTime(endHours, endMinutes);
        onTimeChange([startTime, endTime]);
      }
    }, [startHours, startMinutes, endHours, endMinutes]);

    return (
      <FormControl sx={style} isInvalid={isError} isRequired={props.isRequired}>
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Stack
          sx={{
            fontSize: 14,
            input: {
              outline: "none",
            },
            select: {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              px: 2,
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              // "#E53E3E"
              //       : "#3182ce"
              outline: `2px solid ${
                isFocus ? "#3182ce" : isError ? "#E53E3E" : "transparent"
              }`,
              outlineOffset: "-2px",
              alignItems: "center",
              justifyContent: "space-between",
              opacity: props.isDisabled ? 0.4 : 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <input
                disabled={props.isDisabled}
                type="number"
                value={startHours}
                onChange={(event) => {
                  handleHoursChange(event, setStartHours);
                  //  updateOnChangeCallback();
                }}
                min="0"
                max="23"
                style={{
                  width: "25px",
                  textAlign: "center",
                  background: isFocus ? "rgba(255, 0, 0, 0.1)" : "transparent",
                }}
                onFocus={(e) => {
                  setIsFocus(true);
                  if (e.target.value === "00" || e.target.value === "0")
                    setStartHours("");
                }}
                onBlur={(e) => {
                  if (e.target.value === "") setStartHours("00");
                  setIsFocus(false);
                  //  updateOnChangeCallback();
                }}
              />
              :
              <input
                disabled={props.isDisabled}
                type="number"
                value={startMinutes}
                onChange={(event) => {
                  handleMinutesChange(event, setStartMinutes);
                  //  updateOnChangeCallback();
                }}
                min="0"
                max="59"
                style={{
                  width: "25px",
                  textAlign: "center",
                  background: isFocus ? "rgba(255, 0, 0, 0.1)" : "transparent",
                }}
                onFocus={(e) => {
                  setIsFocus(true);
                  if (e.target.value === "00" || e.target.value === "0")
                    setStartMinutes("");
                }}
                onBlur={(e) => {
                  if (e.target.value === "") setStartMinutes("00");
                  setIsFocus(false);
                  //  updateOnChangeCallback();
                }}
              />
            </Box>
            <Box
              sx={{
                width: "max-content",
              }}
            >
              -
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <input
                disabled={props.isDisabled}
                type="number"
                value={endHours}
                onChange={(event) => {
                  handleHoursChange(event, setEndHours);
                  //  updateOnChangeCallback();
                }}
                min="0"
                max="23"
                style={{
                  width: "25px",
                  textAlign: "center",
                  background: isFocus ? "rgba(255, 0, 0, 0.1)" : "transparent",
                }}
                onFocus={(e) => {
                  setIsFocus(true);
                  if (e.target.value === "00" || e.target.value === "0")
                    setEndHours("");
                }}
                onBlur={(e) => {
                  if (e.target.value === "") setEndHours("00");
                  setIsFocus(false);
                  //  updateOnChangeCallback();
                }}
              />
              :
              <input
                disabled={props.isDisabled}
                type="number"
                value={endMinutes}
                onChange={(event) => {
                  handleMinutesChange(event, setEndMinutes);
                  //  updateOnChangeCallback();
                }}
                min="0"
                max="59"
                style={{
                  width: "25px",
                  textAlign: "center",
                  background: isFocus ? "rgba(255, 0, 0, 0.1)" : "transparent",
                }}
                onFocus={(e) => {
                  setIsFocus(true);
                  if (e.target.value === "00" || e.target.value === "0")
                    setEndMinutes("");
                }}
                onBlur={(e) => {
                  if (e.target.value === "") setEndMinutes("00");
                  setIsFocus(false);
                  //  updateOnChangeCallback();
                }}
              />
            </Box>
          </Box>
        </Stack>
        {!_.isEmpty(errorMessage) ? (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        ) : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default TimePicker;
