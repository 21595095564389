import { PURGE } from "redux-persist";

import { ActionTypes, DEFAULT_LOADING_STATES } from "@/Constants";

const initialState = {
  ...DEFAULT_LOADING_STATES,
  payload: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_TIME_DELETE_FILE_PRIVATE_CHAT_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
        isUpdateSuccess: false,
        isCreateSuccess: false,
      };
    case ActionTypes.SET_TIME_DELETE_FILE_PRIVATE_CHAT_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
        isUpdateSuccess: false,
        isCreateSuccess: false,
      };
    case ActionTypes.FETCH_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    case ActionTypes.FETCH_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
      };
    case ActionTypes.CREATE_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: false,
      };
    case ActionTypes.UPDATE_TIME_DELETE_FILE_PRIVATE_CHAT_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_TIME_DELETE_FILE_PRIVATE_CHAT_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};
