import _ from "lodash";
import { useEffect, useRef, useState } from "react";

import { IFilterLeaveDays } from "@/Interfaces/LeaveDays.interface";
import { RootState, useTypedDispatch } from "@/Store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Collapse,
  Divider,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { TextField, Button } from "@/Components/Common";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { LeaveDaysActions } from "@/Actions";
import dayjs from "dayjs";
import { useTranslation } from "react-multi-lang";
//import { PlusIcon } from "@heroicons/react/24/outline";

const { updateLeaveDays } = LeaveDaysActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: any;
  //  onRowAction: (action: any, id: string) => void;
}

const DEFAULT_FORM_DATA = {
  leaveDay: 0,
  bonus: 0,
  dayOff: 0,
};

const UpdateLeaveDaysDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const { isOpen, onToggle } = useDisclosure();
  const [idLeaveDays, setIdLeaveDays] = useState<string>("");

  const paginate: IFilterLeaveDays = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "pagination")
  );

  const isUpdateSuccess: boolean[] = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "isUpdateSuccess")
  );
  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      //  year: yup.string().trim(),
      leaveDay: yup.number().required(t("message.leaveDaysIsRequired")),
      bonus: yup.number().required(t("message.bonusIsRequired")),
      dayOff: yup.number().required(t("message.dayOffIsRequired")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      setValue("bonus", payload?.bonus || 0);
      setValue("leaveDay", payload?.leaveDay || 0);
      setValue("dayOff", payload?.dayOff || 0);
    }
  }, [payload, open]);

  const onSubmit = (data: any) => {
    if (payload?.id) dispatch(updateLeaveDays(payload?.id, data, paginate));
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
      onClose();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!open) {
      reset();
      setIdLeaveDays("");
    }
  }, [open]);

  const _renderContent = () => {
    return (
      <Box sx={{ maxH: 350, overflowY: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text sx={{ fontSize: "sm", fontWeight: 700 }}>
            {t("label.history")}
          </Text>
          <Box onClick={onToggle} sx={{ cursor: "pointer" }}>
            {!isOpen ? (
              <ChevronDownIcon fontSize={25} />
            ) : (
              <ChevronUpIcon fontSize={25} />
            )}
          </Box>
        </Box>
        <Collapse in={isOpen} unmountOnExit>
          {!_.isEmpty(payload?.leaveDayHistory) ? (
            <Box>
              {_.map(payload?.leaveDayHistory, (item, index: number) => {
                return (
                  <Box>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Box sx={{ display: "flex" }}>
                          {/*<PlusIcon width={35} />*/}
                          <Box ml={2}>
                            <Box
                              sx={{ display: "flex", alignItems: "baseline" }}
                            >
                              <Heading
                                fontSize="sm"
                                sx={{
                                  mb: 2,
                                  fontWeight: 500,
                                }}
                              >
                                {t("label.updateAt")}:
                              </Heading>
                              <Text fontSize="sm" fontWeight={500} ml={2}>
                                {dayjs(item.updatedAt).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}
                              </Text>
                            </Box>
                            <Box
                              sx={{ display: "flex", alignItems: "baseline" }}
                            >
                              <Heading
                                fontSize="sm"
                                sx={{
                                  mb: 2,
                                  fontWeight: 500,
                                }}
                              >
                                {t("label.leaveDays")}:
                              </Heading>
                              <Text size={"sm"} fontWeight={500} ml={2}>
                                {item.leaveDayHistory}
                              </Text>
                            </Box>
                            <Box
                              sx={{ display: "flex", alignItems: "baseline" }}
                            >
                              <Heading
                                fontSize="sm"
                                sx={{
                                  mb: 2,
                                  fontWeight: 500,
                                }}
                              >
                                {t("label.bonus")}:
                              </Heading>
                              <Text size={"sm"} fontWeight={500} ml={2}>
                                {item.bonusHistory}
                              </Text>
                            </Box>
                            <Box
                              sx={{ display: "flex", alignItems: "baseline" }}
                            >
                              <Heading
                                fontSize="sm"
                                sx={{
                                  mb: 2,
                                  fontWeight: 500,
                                }}
                              >
                                {t("label.dayOff")}:
                              </Heading>
                              <Text size={"sm"} fontWeight={500} ml={2}>
                                {item.dayOffHistory}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {item.id !== idLeaveDays && (
                        <Button
                          size={"sm"}
                          disabled
                          onClick={() => {
                            setValue("bonus", item.bonusHistory);
                            setValue("leaveDay", item.leaveDayHistory);
                            setValue("dayOff", item.dayOffHistory || 0);
                            setIdLeaveDays(item?.id);
                          }}
                        >
                          {t("button.apply")}
                        </Button>
                      )}
                    </Box>
                    <Divider orientation="horizontal" />
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box>
              <Text size={"sm"}>{t("message.noDataHistoryChange")}</Text>
            </Box>
          )}
        </Collapse>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <Box as={"form"}>
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 1px 2px -1px gray",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Box>
                <Heading
                  fontSize="sm"
                  sx={{
                    fontWeight: 700,
                    fontFamily: "Pretendard",
                    mb: 1,
                    w: "75px",
                  }}
                >
                  {t("label.users")}
                </Heading>
                <Text size={"sm"}>
                  <Tooltip label={payload?.user?.userData?.fullName}>
                    <Avatar
                      mt={1}
                      size="sm"
                      name={payload?.user?.userData?.fullName}
                      src={payload?.user?.userData?.avatar?.path}
                    />
                  </Tooltip>
                </Text>
              </Box>
              <Box sx={{ mx: 4 }}>
                <Controller
                  name="leaveDay"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("label.leaveDays")}
                      value={field.value}
                      placeholder="0"
                      size="sm"
                      isError={!_.isEmpty(errors.leaveDay?.message)}
                      errorMessage={errors.leaveDay?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIdLeaveDays("");
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="bonus"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("label.bonus")}
                      value={field.value}
                      placeholder="0"
                      size="sm"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIdLeaveDays("");
                      }}
                      isError={!_.isEmpty(errors.leaveDay?.message)}
                      errorMessage={errors.leaveDay?.message}
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box ml={2}>
                <Controller
                  name="dayOff"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("label.dayOff")}
                      value={field.value}
                      placeholder="0"
                      size="sm"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIdLeaveDays("");
                      }}
                      isError={!_.isEmpty(errors.leaveDay?.message)}
                      errorMessage={errors.leaveDay?.message}
                      type="number"
                    />
                  )}
                />
              </Box>
            </Box>
          </AlertDialogHeader>
          <AlertDialogBody>{_renderContent()}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              size={"sm"}
              isLoading={isActionLoading}
            >
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              //  colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              isLoading={isActionLoading}
              w={70}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.save")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Box>
    </AlertDialog>
  );
};

export default UpdateLeaveDaysDialog;
