import React, { useState, useEffect, memo } from "react";
import { Grid } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";

const giphyFetch = new GiphyFetch("sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh");

const Giphy: React.FC<{ onGifClick: any }> = ({ onGifClick }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);

  const fetchGifs = (offset: number) => {
    return searchQuery
      ? giphyFetch.search(searchQuery, { offset, limit: 10 })
      : giphyFetch.trending({ offset, limit: 10 });
  };

  useEffect(() => {
    fetchGifs(offset);
  }, []);

  const handleOnGifsFetched = () => {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 3,
        position: "relative",
        height: "100%",
        overflow: "auto",
      }}
    >
      <InputGroup>
        <Input
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setOffset(0);
          }}
          placeholder="Search Gif"
          size="sm"
          w="full"
        />
        {searchQuery && (
          <InputRightElement color="gray.500">
            <Icon
              boxSize={5}
              as={XCircleIcon}
              sx={{
                mb: 2,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                setSearchQuery("");
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Box
        sx={{
          h: "350px",
          overflow: "auto",
        }}
      >
        <Grid
          hideAttribution
          key={searchQuery}
          onGifClick={onGifClick}
          fetchGifs={(offset) => fetchGifs(offset)}
          width={350}
          columns={3}
          gutter={6}
          onGifsFetched={handleOnGifsFetched}
        />
      </Box>
    </Box>
  );
};

export default memo(Giphy);
