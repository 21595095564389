export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        `${window.location.origin}/firebase-messaging-sw.js`
      );
      return registration.scope;
    } catch (err) {
      console.error("Service worker registration failed:", err);
      throw err;
    }
  } else {
    console.warn("Service workers are not supported in this browser.");
    return Promise.reject(new Error("Service workers are not supported."));
  }
};
