import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
} from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  year: new Date().getFullYear(),
  status: "active",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  meta: DEFAULT_META_STATES,
  pagination: DEFAULT_PAGINATION,
  isUpdateSuccess: false,
  isCreateSuccess: false,
  workspaceGroupList: [],
  workspaceShortList: [],
  workspaceAction: {},
  workspaceShortByid: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;

  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_WORKSPACE:
      return {
        ...initialState,
      };
    case ActionTypes.SET_WORKSPACE_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_WORKSPACE_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    case ActionTypes.SET_WORKSPACE_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isGetLoading: true,
      };

    case ActionTypes.FETCH_WORKSPACE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
        workspaceGroupList: payload,
      };

    case ActionTypes.FETCH_WORKSPACE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
        workspaceGroupList: [],
      };

    case ActionTypes.GET_BY_ID_WORKSPACE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.GET_BY_ID_WORKSPACE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_WORKSPACE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.UPDATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_WORKSPACE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.DELETE_WORKSPACE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.DELETE_WORKSPACE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    // short
    case ActionTypes.FETCH_WORKSPACE_SHORT_CUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
        workspaceShortList: payload,
      };

    case ActionTypes.FETCH_WORKSPACE_SHORT_CUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
        workspaceShortList: [],
      };

    case ActionTypes.GET_BY_ID_WORKSPACE_SHORT_CUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        workspaceShortByid: payload,
      };
    case ActionTypes.GET_BY_ID_WORKSPACE_SHORT_CUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        workspaceShortByid: [],
      };

    case ActionTypes.CREATE_WORKSPACE_SHORT_CUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_WORKSPACE_SHORT_CUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.UPDATE_WORKSPACE_SHORT_CUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_WORKSPACE_SHORT_CUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.DELETE_WORKSPACE_SHORT_CUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.DELETE_WORKSPACE_SHORT_CUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};
