import ReactDOM from "react-dom/client";
import LocaleConfig from "@/Locale";
import Root from "@/Routers";
import { history } from "@/Store";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/red-hat-display/300.css";
import "@fontsource/red-hat-display/400.css";
import "@fontsource/red-hat-display/500.css";
import "@fontsource/red-hat-display/600.css";
import "@fontsource/red-hat-display/700.css";
import "@fontsource/red-hat-display/800.css";
import "@fontsource/red-hat-display/900.css";
import "@fontsource/red-hat-display/300-italic.css";
import "@fontsource/red-hat-display/400-italic.css";
import "@fontsource/red-hat-display/500-italic.css";
import "@fontsource/red-hat-display/600-italic.css";
import "@fontsource/red-hat-display/700-italic.css";
import "@fontsource/red-hat-display/800-italic.css";
import "@fontsource/red-hat-display/900-italic.css";
import "rsuite/dist/rsuite.css";
import "./index.css";
import { registerServiceWorker } from "./serviceWorker";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Root history={history} />);

document.addEventListener("DOMContentLoaded", () => {
  LocaleConfig.initTranslations();
});
registerServiceWorker();
// serviceWorkerRegistration.register();
