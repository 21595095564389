import React, { useMemo, useState } from "react";
import { validate } from "uuid";
import { useSearchParams } from "react-router-dom";
import LeaveDaysDataTable from "@/Components/LayoutPart/DataTable/LeaveDaysDataTable";
import { RootState, useTypedDispatch } from "@/Store";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  InputGroup,
  Stack,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { LeaveDaysActions } from "@/Actions";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  IDataLeaveDays,
  IFilterLeaveDays,
} from "@/Interfaces/LeaveDays.interface";
import { LeaveDaysDialog } from "@/Components/Popup";
import { TActions } from "@/Types/Common.types";
import { AdminLayout } from "@/Components/DefaultLayout";
import { RoundedContainer, Select, TextField } from "@/Components/Common";
import Utils from "@/Utils";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { ExportToExcelLeaveDays } from "@/Components/Export";
import { useTranslation } from "react-multi-lang";

const {
  fetchLeaveDays,
  resetLeaveDaysReducer,
  getByIdLeaveDays,
  resetLeaveDayDetails,
} = LeaveDaysActions;

const DEFAULT_FILTERS: IFilterLeaveDays = {
  page: 0,
  limit: 0,
  keyword: "",
  year: new Date().getFullYear(),
};

interface DataYear {
  label: number;
  value: number;
}

const LeaveDays = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowPopup, setIsShowPopup] = useState<any>({
    edit: false,
    create: false,
    filter: false,
  });
  const [leaveDaysDetail, setLeaveDaysDetail] = useState<IDataLeaveDays>();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  //  const [disabledFilterBy, setDisabledFilterBy] = useState<boolean>(false);

  const userData = Utils.getSavedUserData();

  const meta = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "meta")
  );
  const payload: IDataLeaveDays[] = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "LeaveDaysList")
  );
  const details: any = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "details")
  );
  const pagination: IFilterLeaveDays = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "pagination")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LEAVE_DAYS, "isFetchLoading")
  );

  const checkCreateLeaveDays = Utils.hasPermission(
    _.map(userData?.userRole, (userRole: any) => _.get(userRole, "role")),
    "LeaveDays",
    "checkLeaveDays"
  );

  const viewTotal = Utils.hasPermission(
    _.map(userData?.userRole, (userRole: any) => _.get(userRole, "role")),
    "LeaveDays",
    "checkLeaveDays"
  );

  const fieldGroupSize = React.useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "275px",
      xl: "275px",
      "2xl": "275px",
    };
  }, []);
  const yearId = searchParams.get("year");

  React.useEffect(() => {
    // Access the 'id' parameter from searchParams
    const recordId = searchParams.get("id");

    if (recordId && validate(recordId)) {
      dispatch(getByIdLeaveDays(recordId));
    }
  }, [searchParams]);

  React.useEffect(() => {
    const recordId = searchParams.get("id");
    if (!_.isEmpty(details) && recordId) {
      setLeaveDaysDetail(details);
      if (checkCreateLeaveDays) setIsShowPopup({ ...isShowPopup, edit: true });
    }
  }, [details]);

  const onRowActionChange = (action: TActions, item: any) => {
    setLeaveDaysDetail(item);
    setIsShowPopup({ ...isShowPopup, [action]: true });
  };

  const onPageChange = (page: number) => {
    dispatch(fetchLeaveDays({ page: page, limit: 10 }));
  };

  const handleFiltersChange = (
    key: string,
    value: string | string[] | Date[]
  ) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSubmit = async () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters: any = Utils.removeEmptyFields(filters);
    if (resolvedFilters) {
      dispatch(fetchLeaveDays(resolvedFilters as IFilterLeaveDays));
      if (!_.isEmpty(yearId)) {
        searchParams.set("year", resolvedFilters?.year);
        setSearchParams(searchParams);
      } else {
        searchParams.append("year", resolvedFilters?.year);
        setSearchParams(searchParams);
      }
    }
  };

  const handleReset = async () => {
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchLeaveDays(DEFAULT_FILTERS));
    setIsShowPopup({ ...isShowPopup, filter: false });
    if (!_.isEmpty(yearId)) {
      searchParams.delete("year");
      await setSearchParams(searchParams);
    }
  };

  //  const handleShowPopup = (name: string, value: boolean) =>
  //    setIsShowPopup({ ...isShowPopup, [name]: value });

  const summaryLeaveDay = useMemo(() => {
    let sum: any = 0;
    _.forEach(payload, (item: any) => (sum += _.toNumber(item.leaveDay)));
    return sum;
  }, [payload]);

  const summaryBonus = useMemo(() => {
    let sum: any = 0;
    _.forEach(payload, (item: any) => (sum += _.toNumber(item.bonus)));
    return sum;
  }, [payload]);

  const summaryDayOff = useMemo(() => {
    let sum: any = 0;
    _.forEach(payload, (item: any) => (sum += _.toNumber(item.dayOff)));
    return sum;
  }, [payload]);

  const yearsOptions = useMemo(() => {
    let allyears: DataYear[] = [];
    const minYear = -(
      new Date().getFullYear() - new Date("2010-01-01T23:15:30").getFullYear()
    );
    const maxYear = 1;
    const thisYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      allyears.push({ label: thisYear + i, value: thisYear + i });
    }
    return allyears;
  }, []);

  React.useEffect(() => {
    if (yearId) {
      dispatch(fetchLeaveDays({ page: 0, limit: 0, year: yearId }));
      setFilters({ ...filters, year: yearId });
    } else
      dispatch(
        fetchLeaveDays({ page: 0, limit: 0, year: new Date().getFullYear() })
      );
    return () => {
      dispatch(resetLeaveDaysReducer());
    };
  }, [checkCreateLeaveDays]);

  const checkIdLevedays: any = useMemo(() => {
    const filterData = payload.find(
      (item: any) => item?.user?.id === userData.id
    );
    return filterData;
  }, [payload]);

  React.useEffect(() => {
    if (!checkCreateLeaveDays) {
      if (checkIdLevedays?.id) {
        if (yearId) {
          searchParams.set("id", checkIdLevedays?.id);
          searchParams.set("year", yearId);
          setSearchParams(searchParams);
          setFilters({ ...filters, year: yearId });
        } else {
          searchParams.set("id", checkIdLevedays?.id);
          searchParams.set("year", new Date().getFullYear().toString());
          setSearchParams(searchParams);
        }
      } else {
        searchParams.delete("id");
        setSearchParams(searchParams);
      }
    }
  }, [checkIdLevedays, checkCreateLeaveDays, yearId]);

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.leaveDays")}
          </Heading>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
            {viewTotal && (
              <ExportToExcelLeaveDays
                csvData={payload}
                totalDayOff={summaryDayOff}
                TotalLeaveDays={summaryLeaveDay + summaryBonus}
                year={pagination?.year}
              />
            )}
            {checkCreateLeaveDays && (
              <Button
                size="sm"
                //colorScheme="twitter"
                leftIcon={<Icon as={AddIcon} boxSize={3} />}
                onClick={() => setIsShowPopup({ ...isShowPopup, create: true })}
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
              >
                {t("button.create")}
              </Button>
            )}
          </Box>
        </Box>
        <RoundedContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {checkCreateLeaveDays && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    flexWrap: "wrap",
                    flex: 1,
                    minW: "75px",
                  }}
                >
                  <Box sx={{ maxW: fieldGroupSize }}>
                    <TextField
                      size="sm"
                      placeholder={t("label.search")}
                      value={filters.keyword}
                      onChange={(e: any) =>
                        handleFiltersChange("keyword", e.target.value)
                      }
                      onKeyDown={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        const value = element.value;
                        if (e.key === "Enter")
                          dispatch(
                            fetchLeaveDays({
                              ...pagination,
                              keyword: _.trim(value),
                            })
                          );
                      }}
                      rightElement={
                        <Tooltip label={t("label.search")} hasArrow>
                          <Icon
                            as={SearchIcon}
                            boxSize={5}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch(
                                fetchLeaveDays({
                                  ...pagination,
                                  keyword: _.trim(filters.keyword),
                                })
                              );
                            }}
                          />
                        </Tooltip>
                      }
                    />
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <InputGroup
                  sx={{
                    maxWidth: 100,
                  }}
                  size="sm"
                >
                  <Select
                    value={filters.year}
                    onChange={(e: any) =>
                      handleFiltersChange("year", e.target.value)
                    }
                    size="sm"
                    options={yearsOptions}
                    isRequired
                  />
                </InputGroup>
              </Box>
              <HStack spacing={3}>
                <Box>
                  <Button
                    size="sm"
                    onClick={handleSubmit}
                    //colorScheme="whatsapp"
                    leftIcon={<Icon as={FunnelIcon} />}
                    sx={{
                      color: "#fff",
                      background: "#D2A96A",
                      "&:hover": {
                        background: "#D2BD6A",
                      },
                    }}
                  >
                    {t("button.filter")}
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="sm"
                    onClick={handleReset}
                    leftIcon={<Icon as={ArrowPathIcon} />}
                    sx={{
                      color: "#fff",
                      background: "#bb7154",
                      "&:hover": {
                        background: "#Db9d97",
                      },
                    }}
                  >
                    {t("button.reset")}
                  </Button>
                </Box>
              </HStack>
            </Box>
            {viewTotal && (
              <Box>
                <Box sx={{ display: "flex", mr: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 0,
                      mr: 2,
                      color: "#bb7154",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Text sx={{ fontWeight: 600 }} size={"sm"} w={114}>
                        {t("title.totalLeaveDays")}
                      </Text>
                      <Text sx={{ fontWeight: 600, mt: 0 }} size={"sm"}>
                        :
                      </Text>
                    </Box>
                  </Box>
                  <Text
                    sx={{ fontWeight: 600, ml: 1, color: "#bb7154", mt: 0 }}
                  >
                    {summaryLeaveDay + summaryBonus}
                  </Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: 0,
                    mr: 2,
                    color: "#bb7154",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Text sx={{ fontWeight: 600 }} size={"sm"} w={114}>
                      {t("title.totalDayOff")}
                    </Text>
                    <Text sx={{ fontWeight: 600, mt: 0 }} size={"sm"}>
                      :
                    </Text>
                  </Box>
                  <Text sx={{ fontWeight: 600, ml: 1, mt: 0 }}>
                    {summaryDayOff}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
          <LeaveDaysDataTable
            payload={payload}
            paginate={meta}
            onRowAction={onRowActionChange}
            isLoading={isFetchLoading}
            onPageChange={onPageChange}
            year={pagination?.year}
          />
        </RoundedContainer>
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Box>{_renderTopSection()}</Box>
        <LeaveDaysDialog.UpdateLeaveDaysDialog
          open={isShowPopup.edit}
          payload={leaveDaysDetail}
          onClose={() => {
            setIsShowPopup({ ...isShowPopup, edit: false });
            const recordId = searchParams.get("id");
            if (recordId) {
              searchParams.delete("id");
              setSearchParams(searchParams);
            }
            if (!_.isEmpty(details)) dispatch(resetLeaveDayDetails());
          }}
        />
        <LeaveDaysDialog.CreateLeaveDaysDialog
          open={isShowPopup.create}
          onClose={() => setIsShowPopup({ ...isShowPopup, create: false })}
        />
      </Box>
    );
  };
  return <AdminLayout content={renderMain()} />;
};

export default LeaveDays;
