import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Button, TextField } from "@/Components/Common";
import { Routers } from "@/Constants";
import { AuthenticationActions } from "@/Actions";
import { ILoginStructure } from "@/Interfaces/Auth.interface";
import { RootState, useTypedDispatch } from "@/Store";
import {
  Box,
  HStack,
  Stack,
  Heading,
  Text,
  Checkbox,
  Grid,
  Image,
} from "@chakra-ui/react";
import { useWindowWidth } from "@/Helpers";

import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/outline";
import backgroundImage_bigSize from "@/Assets/Login.jpg";
import logo from "@/Assets/cc-logo-white.svg";
import Utils from "@/Utils";
import { generateToken } from "@/Configs/firebase.config";
// import BackgroundWhenCollectData from "@/Assets/BackgroundWhenCollectData.jpg";

import ForgotPassword from "./ForgotPassword";
import LanguageSwitch from "@/Components/LayoutPart/Appbar/LanguageSwitch";
import { useTranslation } from "react-multi-lang";

const { login, createFCMToken } = AuthenticationActions;

const DEFAULT_FORM: ILoginStructure = {
  account: "",
  password: "",
  isRemember: false,
};

type TCurrentPage = "home" | "forgotPassword";

const SignIn = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const getToken = Utils.getSavedToken();
  const firebaseToken = Utils.getSavedFirebaseToken();
  const t = useTranslation();

  // const [isMobile] = useMediaQuery("(max-width: 767px)");
  // const [isTablet] = useMediaQuery([
  //   "(min-width: 768px) and (max-width: 1279px)",
  // ]);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth >= 1280;
  const [currentPage, setCurrentPage] = useState<TCurrentPage>("home");

  useEffect(() => {
    generateToken();
    if (getToken) navigate(Routers.DASHBOARD);
    return () => {
      setCurrentPage("home");
    };
  }, []);

  const siginRequestIsLoading: boolean = useSelector((state: RootState) =>
    _.get(state.AUTH, "requestIsLoading")
  );

  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

  const schema = yup
    .object()
    .shape({
      account: yup
        .string()
        .trim()
        .email(t("message.emailMustBeAValidEmail"))
        .required(t("message.emailIsRequired")),
      password: yup.string().required(t("message.passwordIsRequired")),
      isRemember: yup.boolean(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM,
  });

  const onSubmit = (data: any) => {
    dispatch(
      login(data, Routers.DASHBOARD, (userData) => {
        if (firebaseToken && userData) {
          dispatch(
            createFCMToken({
              userId: userData?.id,
              token: firebaseToken,
              locale: "en",
            })
          );
        }
      })
    );
  };

  const _renderForm = (sx?: any) => (
    <Stack
      p={[5, 5, 5, 5, 5, 10, 12]}
      borderRightRadius={10}
      background="white"
      justifyContent="space-between"
      sx={{
        ...sx,
      }}
    >
      <Stack
        mb={[2, 2, 2, 2, 2, 5]}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={logo} sx={{ w: 50, height: 50 }} />
        <Heading fontSize="24px" fontWeight={600} color="#000">
          Workspace
        </Heading>
      </Stack>
      <Stack
        direction="column"
        sx={{
          width: "100%",
          rowGap: ["0.7em", "0.7em", "0.7em", "0.7em", "0.7em", "1em"],
          my: "auto",
        }}
      >
        <Box
          fontWeight={600}
          color="#000"
          textAlign="center"
          fontSize={{
            base: "1.5rem",
            md: "1.6rem",
            lg: "1.7rem",
            xl: "1.8rem",
          }}
        >
          {t("title.welcomeBack")}
          <Box fontSize="14px" color="gray">
            {t("label.enterYourEmailAndPasswordToAccessYourAccount")}
          </Box>
        </Box>
        <Controller
          name="account"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("label.emailAddress")}
              placeholder={t("label.emailAddress")}
              type="email"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value.trim())}
              onEnter={handleSubmit(onSubmit)}
              isError={!_.isEmpty(errors.account?.message)}
              errorMessage={errors.account?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              autoComplete="disabled"
              label={t("label.password")}
              placeholder={t("label.password")}
              value={field.value}
              type={isShowPassword ? "text" : "password"}
              onChange={(e) => field.onChange(e.target.value)}
              onEnter={handleSubmit(onSubmit)}
              isError={!_.isEmpty(errors.password?.message)}
              errorMessage={errors.password?.message}
              rightElement={
                !isShowPassword ? (
                  <EyeSlashIcon
                    width={22}
                    onClick={() => setIsShowPassword(true)}
                    cursor="pointer"
                  />
                ) : (
                  <EyeIcon
                    width={22}
                    onClick={() => setIsShowPassword(false)}
                    cursor="pointer"
                  />
                )
              }
              isRequired
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3,
          }}
        >
          <Controller
            name="isRemember"
            control={control}
            render={({ field }) => (
              <Checkbox
                isDisabled={siginRequestIsLoading}
                value="isRemember"
                onChange={(e) => field.onChange(e.target.checked)}
              >
                <Text fontSize="sm">{t("label.rememberMe")}</Text>
              </Checkbox>
            )}
          />
          <Text
            fontSize="sm"
            sx={{
              cursor: "pointer",
            }}
            _hover={{
              textDecor: "underline",
            }}
            onClick={() => {
              reset();
              setCurrentPage("forgotPassword");
            }}
          >
            {t("label.forgotPassword")}
          </Text>
        </Box>
        <Button
          size={["sm", "sm", "sm", "sm", "sm", "md"]}
          sx={{
            mt: 5,
            bgColor: "#212B36",
            color: "#FFFFFF",
          }}
          w="100%"
          _hover={{ bgColor: "none" }}
          onClick={handleSubmit(onSubmit)}
          isLoading={siginRequestIsLoading}
        >
          {t("button.login")}
        </Button>
      </Stack>
    </Stack>
  );

  const _renderMain = () => (
    <HStack
      w="100vw"
      h="100vh"
      maxH="100vh"
      gap={0}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("${backgroundImage_bigSize}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "blur(7px)",
          position: "absolute",
          top: 0,
          left: 0,
          w: "100%",
          h: "100%",
          zIndex: -1,
        }}
      />
      <Box sx={{ position: "absolute", top: 0, right: 10 }}>
        <LanguageSwitch isOpen />
      </Box>
      <Grid
        gridTemplateColumns={isDesktop ? `2fr 1fr` : "1fr"}
        minH="50%"
        w={{
          base: "90%",
          sm: "90%",
          md: "90%",
          lg: "90%",
          xl: "85%",
          "2xl": "80%",
        }}
        p={1.5}
        borderRadius={10}
        background="rgba(225,225,225,0.7)"
      >
        {isDesktop && (
          <Box
            borderLeftRadius={10}
            sx={{
              backgroundImage: `url("${backgroundImage_bigSize}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              top: 0,
              left: 0,
              w: "100%",
              h: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;",
            }}
          />
        )}
        {currentPage === "home" ? (
          _renderForm()
        ) : (
          <ForgotPassword onBack={() => setCurrentPage("home")} />
        )}
      </Grid>
    </HStack>
  );

  return (
    <HStack w="100vw" h="100vh" maxH="100vh">
      {_renderMain()}
    </HStack>
  );
};

export default SignIn;
