import CreateTaskDialog from "./CreateTask";
import EditTaskDialog from "./EditTask";
import TaskDetailDialog from "./TaskDetail";
import CreateColumnDialog from "./CreateColumn";

export default {
  CreateTaskDialog,
  EditTaskDialog,
  TaskDetailDialog,
  CreateColumnDialog,
};
