import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  Stack,
  Heading,
  Icon,
  Button,
  Box,
  Avatar,
  Text,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { EyeIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { RoundedContainer, SelectUser } from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { ProjectActions, UserActions } from "@/Actions";
import Utils from "@/Utils";
import { shawdowEffects } from "@/Themes/CommonStyles";
import { IProjectStructure } from "@/Interfaces/Project.interface";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const { updateProject, getProjectById } = ProjectActions;
const { performAction: userPerformAction } = UserActions;

const Members: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const t = useTranslation();

  const details: IProjectStructure = useSelector((state: RootState) =>
    _.get(state.PROJECT, "details")
  );

  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isUpdateSuccess")
  );

  const users: any[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const userCanAdd: any[] = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "userCanAdd")
  );

  const userOptions = useMemo(() => {
    const hasManagerRole = !!_.find(
      userRoles,
      (role) => role === ENUMS.ROLES.MANAGER
    );
    const hasLeaderRole = !!_.find(
      userRoles,
      (role) => role === ENUMS.ROLES.LEADER
    );

    const allowedInteriorRoles = [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
    ];

    const allowedExtoriorRoles = [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.MARKETING,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ];

    const allowedRoles =
      details?.type === ENUMS.PROJECT_TYPE.EXTERIOR
        ? allowedExtoriorRoles
        : allowedInteriorRoles;

    return _.map(users, (user) => {
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      const userHasManagerRole = _.find(
        user?.userRole,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );

      if (hasLeaderRole && !hasManagerRole && userHasManagerRole) {
        return null;
      }

      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        return {
          id: user.id,
          avatar: user?.userData?.avatar?.path,
          name: user?.userData?.fullName,
          email: user?.email,
          roles: user?.userRole,
          user,
        };
      }

      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean); // Filter out null values (users without allowed roles)
  }, [users, userRoles, details?.type]);

  const userExternalOptions = useMemo(() => {
    const allowedRoles = [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.MARKETING,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ];
    return _.map(userCanAdd, (user) => {
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        return {
          id: user.id,
          avatar: user?.userData?.avatar?.path,
          name: user?.userData?.fullName,
          email: user?.email,
          roles: user?.userRole,
        };
      }

      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean);
  }, [userCanAdd]);

  const targetUserOptions = useMemo(
    () =>
      details?.type === ENUMS.PROJECT_TYPE.INTERIOR
        ? userOptions
        : userExternalOptions,
    [details, userOptions, userExternalOptions]
  );

  const [selectedUserId, setSelectedUserId] = useState<string | string[]>("");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [isClearSelectedUser, setIsClearSelectedUser] = useState(false);
  const [selectedDeleteUserId, setSelectedDeleteUserId] = useState("");

  const selectedUserIds = useMemo(() => {
    return _.map(selectedUsers, "id");
  }, [selectedUsers]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      const allowedInteriorRoles = [
        ENUMS.ROLES.ARTIST,
        ENUMS.ROLES.LEADER,
        ENUMS.ROLES.MANAGER,
      ];

      const allowedExtoriorRoles = [
        ENUMS.ROLES.ARTIST,
        ENUMS.ROLES.MARKETING,
        ENUMS.ROLES.LEADER,
        ENUMS.ROLES.MANAGER,
        ENUMS.ROLES.HUMAN_RESOURCES,
      ];

      const allowedRoles =
        details?.type === ENUMS.PROJECT_TYPE.EXTERIOR
          ? allowedExtoriorRoles
          : allowedInteriorRoles;

      setSelectedUsers(
        _.map(details?.projectMember, (projectMember) => {
          const hasAllowedRole = _.some(
            projectMember?.user?.userRole,
            (userRole) => allowedRoles.includes(userRole?.role?.roleCode)
          );
          if (hasAllowedRole)
            return {
              id: projectMember?.user?.id,
              name: projectMember?.user?.userData?.fullName,
              email: projectMember?.user?.email,
              roles: projectMember?.user?.userRole,
              avatar: projectMember?.user?.userData?.avatar?.path,
              user: projectMember?.user,
            };
          return null;
        }).filter(Boolean)
      );
    }
  }, [details]);

  useEffect(() => {
    if (isUpdateSuccess) dispatch(getProjectById(details?.id));
  }, [isUpdateSuccess]);

  const handleAddNewMember = () => {
    const findUser = _.find(
      targetUserOptions,
      (user) => user?.id === selectedUserId
    );
    const findUserExist = _.find(
      selectedUsers,
      (user) => user.id === selectedUserId
    );
    if (!findUserExist) {
      dispatch(
        updateProject(
          details?.id,
          {
            member: [findUser?.id],
          },
          t("message.addMemberSuccessfully")
        )
      );
    }
    setSelectedUserId("");
    setIsClearSelectedUser(true);
  };

  const _renderMembers = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <RoundedContainer>
          <Heading
            sx={{
              fontSize: 22,
              mb: 3,
            }}
          >
            {t("label.listOfMembers")} {`(${_.size(details?.projectMember)})`}
          </Heading>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              alignItems: "center",
              mb: 3,
            }}
          >
            <SelectUser
              key={_.size(targetUserOptions)}
              payload={targetUserOptions}
              onChangeValue={(userId) => {
                setSelectedUserId(userId);
                setIsClearSelectedUser(false);
              }}
              isClear={isClearSelectedUser}
              disabledIds={selectedUserIds}
              sx={{
                width: isMobile ? "100%" : "50%",
              }}
            />
            <Button
              isDisabled={!selectedUserId}
              size="sm"
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              onClick={handleAddNewMember}
            >
              {t("button.add")}
            </Button>
          </Box>
        </RoundedContainer>
        <RoundedContainer>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              {!_.isEmpty(selectedUsers) ? (
                _.map(selectedUsers, (user, index) => {
                  const roleNames = _.map(user?.roles, (userRole) =>
                    userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                      ? `Staff`
                      : userRole?.role?.name
                  );
                  const roleCodes = _.map(
                    user?.roles,
                    (userRole) => userRole?.role?.roleCode
                  );
                  const concatenatedRoleNames = roleNames.join(", ");
                  const allowDeleted =
                    (details?.type === ENUMS.PROJECT_TYPE.EXTERIOR &&
                      userData?.id === details?.userCreated?.id) ||
                    Utils.handleCheckLogTimePermissions(
                      _.map(
                        userData?.userRole,
                        (userRole) => userRole?.role?.roleCode
                      ),
                      roleCodes,
                      details?.type as any
                    );
                  return (
                    <Box
                      key={index}
                      sx={{
                        boxShadow: shawdowEffects,
                        p: 3,
                        overflow: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "center",
                          }}
                        >
                          <Avatar src={user?.avatar} name={user.name} />
                          <Box>
                            <Heading size="sm">{user?.name}</Heading>
                            <Text fontSize="sm">{user?.email}</Text>
                            {isMobile && (
                              <Text fontSize="sm" textTransform="capitalize">
                                {concatenatedRoleNames}
                              </Text>
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          {!isMobile && (
                            <Text fontSize="sm" textTransform="capitalize">
                              {concatenatedRoleNames}
                            </Text>
                          )}
                          <Box>
                            {canViewUserProfile && (
                              <Tooltip label={t("tooltip.view")} hasArrow>
                                <IconButton
                                  isRound={true}
                                  variant="solid"
                                  aria-label={""}
                                  size="sm"
                                  sx={{
                                    background: "none",
                                  }}
                                  onClick={() =>
                                    canViewUserProfile &&
                                    dispatch(
                                      userPerformAction(
                                        "viewProfile",
                                        user?.id,
                                        user?.user
                                      )
                                    )
                                  }
                                >
                                  <Icon as={EyeIcon} boxSize={5} />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip label={t("tooltip.delete")} hasArrow>
                              <IconButton
                                isRound={true}
                                variant="solid"
                                aria-label={""}
                                size="sm"
                                sx={{
                                  background: "none",
                                }}
                                onClick={() => setSelectedDeleteUserId(user.id)}
                                isDisabled={
                                  !allowDeleted || userData?.id === user?.id
                                }
                              >
                                <Icon as={TrashIcon} boxSize={5} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
              )}
            </Box>
          </Box>
        </RoundedContainer>
      </Box>
    );
  };

  const _renderPopup = () => (
    <>
      <ConfirmDialog
        actionType="delete"
        isOpen={!!selectedDeleteUserId}
        onClose={() => setSelectedDeleteUserId("")}
        onAction={() => {
          dispatch(
            updateProject(
              details?.id,
              {
                removeMember: [selectedDeleteUserId],
              },
              t("message.deleteMemberSuccess")
            )
          );
          setSelectedDeleteUserId("");
        }}
        body={t(
          "message.ByConfirmingThisActionYouWillPermanentlyRemoveTheSelectedUserFromTheProject"
        )}
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {_renderMembers()}
        {_renderPopup()}
      </Stack>
    );
  };

  return renderMain();
};

export default Members;
