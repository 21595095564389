import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateIp } from "@/Interfaces/InternalIp.interface";

const { INTERNAL_IP } = APIs;

export const createInternal = async (payload: ICreateIp) => {
  return request(INTERNAL_IP.BASIC, "POST_FORM_DATA", payload);
};

export const fetchInternal = async (payload: any) => {
  return request(INTERNAL_IP.BASIC, "GET", payload);
};

export const updateInternal = async (id: string, payload: ICreateIp) => {
  return request(`${INTERNAL_IP.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const changeStatusInternal = async (id: string, payload: any) => {
  return request(`${INTERNAL_IP.CHANGE_STATUS}/${id}`, "PUT", payload);
};

export const getInternalById = async (id: string) => {
  return request(`${INTERNAL_IP.GET_BY_ID}/${id}`, "GET");
};

export const changeInternalRemote = async () => {
  return request(INTERNAL_IP.INTERNAL_IP_REMOTE, "POST");
};

export const fetchInternalIpRemote = async () => {
  return request(INTERNAL_IP.FETCH_INTERNAL_IP_REMOTE, "GET");
};
