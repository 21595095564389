import ChangePasswordAdminDialog from "./ChangePassWordAdminDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ChangeUserStatusDialog from "./ChangeStatusUserDialog";
import CreateUserDialog from "./CreateUser";
import UserProfile from "./UserProfile";

export default {
  CreateUserDialog,
  ChangeUserStatusDialog,
  ChangePasswordDialog,
  UserProfile,
  ChangePasswordAdminDialog,
};
