import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Textarea,
  Stack,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, DateTimeRangePicker, Button } from "@/Components/Common";
import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions } from "@/Actions";
import { useTranslation } from "react-multi-lang";

const { createColumnInBoard, resetBoardStatus } = BoardActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_DATA = {
  title: "",
  description: "",
  timeline: ["", ""],
};

const CreateColumnDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const cancelRef = useRef<any>(null);
  const schema = yup
    .object()
    .shape({
      title: yup.string().trim().required(t("message.processIsRequired")),
      description: yup.string().notRequired(),
      timeline: yup
        .array()
        .of(yup.string().trim().required(t("message.timelineIsRequired")))
        .test(
          t("message.timeline-valid"),
          t("message.startTimeMustBeBeforeEndTime"),
          function (value) {
            const [startTime, endTime] = value as [string, string];

            if (!startTime || !endTime) {
              return true; // Allow null values, as they are handled by the individual date validations
            }

            // Check if start and end dates are the same
            const sameDate = dayjs(startTime).isSame(dayjs(endTime), "day");

            // If the dates are the same and the start time is 12:00 PM or later, return false (invalid)

            if (sameDate) {
              const formatStartTime = dayjs(startTime).format("HH");
              const formatEndTime = dayjs(endTime).format("HH");
              if (
                _.toNumber(formatEndTime) >= 12 &&
                _.toNumber(formatStartTime) === 12
              )
                return false;
            }

            // Otherwise, compare UTC Date objects
            return dayjs(startTime).isBefore(dayjs(endTime));
          }
        ),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_DATA,
  });

  const details: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const isCreateColumnSuccess: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "isCreateColumnSuccess")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );

  const [timeline, setTimeline] = useState<any>([]);

  useEffect(() => {
    if (isCreateColumnSuccess) {
      onReset();
    }
  }, [isCreateColumnSuccess]);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const onReset = () => {
    reset();
    setTimeline([]);
    onClose();
    dispatch(resetBoardStatus());
  };

  const onSubmit = (data: FormData) => {
    if (details?.id)
      dispatch(
        createColumnInBoard({
          kanbanBoard: details?.id,
          title: data.title,
          description: data.description || "",
          timeLineStart: timeline[0],
          timeLineEnd: timeline[1],
        })
      );
  };

  const _renderContent = () => (
    <Stack>
      {/* <TextField
        label="Project"
        placeholder="Project"
        value={details?.project?.name}
        size="sm"
        disabled
        sx={{ mb: 3 }}
      /> */}
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("label.process")}
            placeholder="D00"
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            isError={!_.isEmpty(errors.title?.message)}
            errorMessage={errors.title?.message}
            size="sm"
            isRequired
            sx={{ mb: 3 }}
          />
        )}
      />
      <Box sx={{ mb: 3, width: "max-content" }}>
        <Controller
          name="timeline"
          control={control}
          render={({ field }) => (
            <DateTimeRangePicker
              isRequired
              label={t("label.timeline")}
              onDateChange={(newDate) => {
                setTimeline(newDate);
                field.onChange(newDate);
              }}
              value={field.value}
              isError={!_.isEmpty(errors.timeline)}
              errorMessage={
                errors.timeline?.message || errors.timeline?.[0]?.message
              }
            />
          )}
        />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              size="sm"
              value={field?.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t("label.description")}
            />
          )}
        />
      </Box>
    </Stack>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="lg"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          w: "max-content",
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          {t("title.addProcess")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} size="sm">
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            size="sm"
            minW={65}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
            isLoading={isActionLoading}
          >
            {t("button.create")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateColumnDialog;
