import React, { useState, useEffect } from "react";
import _ from "lodash";
import "react-phone-input-2/lib/style.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  type InputProps,
} from "@chakra-ui/react";
import {
  Select,
  OptionBase,
  ChakraStylesConfig,
  CSSObjectWithLabel,
} from "chakra-react-select";
import { useTranslation } from "react-multi-lang";

interface IOption extends OptionBase {
  label: string;
  value: string | string[];
  variant?: string;
  colorScheme?: string;
  extendProps?: any;
}

interface IProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  isClear?: boolean;
  isMulti?: boolean;
  isRequired?: boolean;
  value?: string | string[];
  options: IOption[] | any[];
  onChange(newValue: string | string[]): void;
  customComponents?: any;
  placeholder?: string;
  disabled?: boolean;
}

const Autocomplete = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      options,
      isRequired,
      isMulti,
      isClear,
      value,
      onChange,
      customComponents,
      disabled = false,
      placeholder,
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [currentValue, setCurrentValue] = useState<string | string[]>(
      isMulti ? [] : ""
    );
    const t = useTranslation();

    const chakraStyles: ChakraStylesConfig = {
      valueContainer: (provided) => ({
        ...provided,
        flexWrap: "nowrap",
        overflow: isFocus ? "auto" : "hidden",
      }),
      multiValue: (provided) => ({
        ...provided,
        minWidth: "max-content",
      }),
      // menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    useEffect(() => {}, [ref]);

    useEffect(() => {
      if (value) {
        const transformedValue = isMulti
          ? _.isArray(value)
            ? value
            : [value]
          : [value].toString();
        setCurrentValue(transformedValue);
      }
    }, [value, isMulti]);

    useEffect(() => {
      if (isClear) {
        if (isMulti) {
          setCurrentValue([]);
        } else {
          setCurrentValue("");
        }
        onChange(isMulti ? [] : "");
      }
    }, [isClear, isMulti]);

    return (
      <FormControl
        isInvalid={isError}
        isRequired={isRequired}
        style={style}
        isDisabled={disabled}
      >
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Select
          menuPortalTarget={document.body}
          chakraStyles={{
            ...chakraStyles,
          }}
          size="sm"
          isMulti={isMulti}
          options={options}
          value={
            isMulti
              ? options.filter((option: any) =>
                  currentValue.includes(option.value)
                )
              : options.find((option) => option.value === currentValue)
          }
          onChange={(selectedOptions: any) => {
            const selectedValues = isMulti
              ? _.map(selectedOptions, (option: IOption) => option.value)
              : selectedOptions.value;

            setCurrentValue(selectedValues);
            onChange(selectedValues);
          }}
          components={customComponents}
          placeholder={placeholder ? placeholder : t("label.select")}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          styles={{
            menuPortal: (styles): CSSObjectWithLabel => {
              return {
                ...styles,
                zIndex: 9999,
              } as CSSObjectWithLabel;
            },
          }}
        />

        {isError ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default Autocomplete;
