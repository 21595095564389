import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import { Box, Heading, IconButton, Select } from "@chakra-ui/react";

import { RepeatClockIcon } from "@chakra-ui/icons";
import { useWindowWidth } from "@/Helpers";
import Utils from "@/Utils";

import CommonColors from "@/Themes/CommonColors";
import SingleSlider from "@/Components/Common/DateRangeSlider/SingleRange";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { SocketActions } from "@/Actions";
import { useTranslation } from "react-multi-lang";
import "dayjs/locale/vi";

const { saveCurrentDateInScroller } = SocketActions;

interface ISectionProps {
  isLoading?: boolean;
  activeDates: string[];
  onActiveDatesChange(activeDates: string[]): void;
  activeView: "week" | "month";
  onActiveViewChange(view: "week" | "month"): void;
  timeline?: string[];
  onTimelineChange?(timeline: string[]): void;
  onMinSlotWidthDateChange(width: number): void;
  onReset(): void;
  calendarWidth?: number;
}

const CustomHeaderToolbar: React.FC<ISectionProps> = ({
  isLoading,
  timeline = [],
  onTimelineChange,
  calendarWidth = 0,
  onMinSlotWidthDateChange,
  onReset,
  onActiveDatesChange,
}) => {
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const t = useTranslation();
  const language = Utils.getSavedLanguage();

  const currentLocale = dayjs().locale();

  const [numberOfMonths, setNumberOfMonths] = useState<number>(1);

  const [visibleDays, setVisibleDays] = useState<number>(isMobile ? 7 : 14);

  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.SOCKET, "currentDateInScroller")
  );

  useEffect(() => {
    if (visibleDays > 0 && calendarWidth > 0) handleVisibleDaysChange();
  }, [visibleDays, calendarWidth]);

  const formattedDateRange = React.useMemo(() => {
    const startDate = dayjs(_.first(timeline));
    const endDate = dayjs(_.last(timeline));

    const isValidStartDate = dayjs(startDate).isValid();
    const isValidEndDate = endDate.isValid();

    const formattedStartDate = isValidStartDate
      ? startDate.format("MMM D")
      : "";
    const formattedEndDate = isValidEndDate
      ? endDate.format("MMM D, YYYY")
      : "";

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [timeline, currentLocale]);

  const handleVisibleDaysChange = async () => {
    const newValue = parseInt((calendarWidth / visibleDays).toString());
    onMinSlotWidthDateChange(newValue);
    const defaultTimelineStartDateIndex = _.findIndex(
      timeline,
      (date) => date === currentDateInScroller
    );

    if (defaultTimelineStartDateIndex !== -1) {
      const startDate = currentDateInScroller;
      const endDate =
        timeline[defaultTimelineStartDateIndex + (visibleDays - 1)] ||
        _.last(timeline);
      const newActiveDates = Utils.generateDateRanges(startDate, endDate);
      if (!_.isEmpty(newActiveDates)) {
        onActiveDatesChange(newActiveDates);
      }
    }
  };

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  const _renderToolbar = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          ></Box>
        </Box>
      </Box>
    );
  };

  const _renderTopSection = () => {
    return !isMobile ? (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          mb: 5,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          {/* {_renderFilterPopper()} */}
          <Heading size="sm">{formattedDateRange}</Heading>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            width: `calc(50% - ${language === "vi" ? "90" : "80"}px)`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <SingleSlider
              value={visibleDays}
              onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
              calendarWidth={calendarWidth}
              defaultValue={isMobile ? 7 : 14}
              step={7}
              maxDays={_.size(timeline)}
              disabled={isLoading}
            />
          </Box>
          <Box w="max-content">
            <Select
              size="sm"
              value={numberOfMonths}
              onChange={(e) => {
                const newValue = _.toNumber(e.target.value);
                setNumberOfMonths(newValue);
                const newTimeline = Utils.generateDateRangesByMonths(newValue);
                onTimelineChange && onTimelineChange(newTimeline);
              }}
              isDisabled={isLoading}
            >
              <option value={1}>{t("label.thisMonth")}</option>
              <option value={3}>{t("label.3Months")}</option>
              <option value={6}>{t("label.6Months")}</option>
              <option value={9}>{t("label.9Months")}</option>
              <option value={12}>1 {t("label.year")}</option>
            </Select>
          </Box>
          <IconButton
            size="sm"
            aria-label={"Reset"}
            sx={{
              ml: 2,
              background: CommonColors.dustyCoral,
              color: "#Fff",
              "&:hover": {
                background: "#B57D6E!important",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onReset();
              setVisibleDays(isMobile ? 7 : 15);
              dispatch(saveCurrentDateInScroller(""));
            }}
            isDisabled={isLoading}
          >
            <RepeatClockIcon />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          position: "relative",
          mb: 5,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <SingleSlider
              value={visibleDays}
              onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
              calendarWidth={calendarWidth}
              defaultValue={isMobile ? 7 : 14}
              step={7}
              maxDays={_.size(timeline)}
              disabled={isLoading}
            />
          </Box>
          <IconButton
            size="sm"
            aria-label={"Reset"}
            sx={{
              mr: 2,
              background: CommonColors.dustyCoral,
              color: "#Fff",
              "&:hover": {
                background: "#B57D6E!important",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onReset();
              setVisibleDays(isMobile ? 7 : 15);
              dispatch(saveCurrentDateInScroller(""));
            }}
            isDisabled={isLoading}
          >
            <RepeatClockIcon />
          </IconButton>
          <Box w="max-content">
            <Select
              size="sm"
              value={numberOfMonths}
              onChange={(e) => {
                const newValue = _.toNumber(e.target.value);
                setNumberOfMonths(newValue);
                const newTimeline = Utils.generateDateRangesByMonths(newValue);
                onTimelineChange && onTimelineChange(newTimeline);
              }}
              isDisabled={isLoading}
            >
              <option value={1}>{t("label.thisMonth")}</option>
              <option value={3}>{t("label.3Months")}</option>
              <option value={6}>{t("label.6Months")}</option>
              <option value={9}>{t("label.9Months")}</option>
              <option value={12}>1 {t("label.year")}</option>
            </Select>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Heading size="sm">{formattedDateRange}</Heading>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {_renderToolbar()}
      {_renderTopSection()}
    </Box>
  );
};

export default CustomHeaderToolbar;
