import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_META_STATES,
  DEFAULT_LOADING_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  role: "",
};

const DEFAULT_PAGINATION_AGGREGATE = {
  page: 0,
  limit: 0,
  keyword: "",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  ...generateSuccessStateVariables(["Create", "Update", "Delete"]),
  isChangeStatus: false,
  isAggregateFetchLoading: false,
  meta: DEFAULT_META_STATES,
  metaAggregate: DEFAULT_META_STATES,
  pagination: DEFAULT_PAGINATION,
  paginationAgregate: DEFAULT_PAGINATION_AGGREGATE,
  logTimeList: [],
  LogTimeDetail: {},
  getForBoard: [],
  getForMe: [],
  aggregateList: [],
  logTimeListDashboard: [],
  logTimeDeadlineDashboard: [],
  myWorkDashboard: [],
  myTimePagination: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_LOG_TIME_REDUCER:
      return {
        ...initialState,
      };
    case ActionTypes.SET_LOG_TIME_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_LOG_TIME_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_AGGREGATE_FETCH_LOADING:
      return {
        ...state,
        isAggregateFetchLoading: true,
      };
    case ActionTypes.SET_LOG_TIME_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_LOG_TIME_META:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_LOG_TIME_PAGINATION:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };
    case ActionTypes.SET_MY_TIME_PAGINATION:
      return {
        ...state,
        myTimePagination: payload,
      };
    case ActionTypes.LOG_TIME_PERFORM_ACTION:
      return {
        ...state,
        performAction: payload,
      };

    case ActionTypes.RESET_LOG_TIME_PERFORM_ACTION:
      return {
        ...state,
        performAction: {},
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isDeleteSuccess: false,
      };
    case ActionTypes.RESET_LOG_TIME_DETAIL: {
      return {
        ...state,
        logTimeDetail: {},
      };
    }
    case ActionTypes.RESET_LOG_TIME_STATUS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isDeleteSuccess: false,
      };

    case ActionTypes.CREATE_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isCreateSuccess: true,
        isActionLoading: false,
      };
    case ActionTypes.CREATE_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isCreateSuccess: false,
        isActionLoading: false,
      };

    case ActionTypes.FETCH_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeList: payload,
      };

    case ActionTypes.FETCH_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeList: [],
      };

    case ActionTypes.FETCH_LOG_TIME_BY_BOARD_ID_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        getForBoard: payload,
      };

    case ActionTypes.FETCH_LOG_TIME_BY_BOARD_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
      };

    case ActionTypes.GET_BY_ID_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        logtimeDetail: payload,
      };
    case ActionTypes.GET_BY_ID_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        logtimeDetail: {},
      };

    case ActionTypes.UPDATE_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.CHANGE_STATUS_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatus: true,
      };
    case ActionTypes.CHANGE_STATUS_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isChangeStatus: false,
        isActionLoading: false,
      };

    case ActionTypes.DELETE_LOG_TIME_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isDeleteSuccess: true,
        isActionLoading: false,
      };
    case ActionTypes.DELETE_LOG_TIME_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isDeleteSuccess: false,
        isActionLoading: false,
      };

    case ActionTypes.GET_MY_TIME_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        getForMe: payload,
      };
    case ActionTypes.GET_MY_TIME_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ActionTypes.FETCH_AGGREGATE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        isAggregateFetchLoading: false,
        aggregateList: payload,
      };

    case ActionTypes.FETCH_AGGREGATE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isAggregateFetchLoading: false,
        aggregateList: [],
      };

    case ActionTypes.SET_AGGREGATE_META:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        metaAggregate: payload,
      };

    case ActionTypes.SET_AGGREGATE_PAGINATION:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        paginationAgregate: payload,
      };

    case ActionTypes.FETCH_LOG_TIME_DASH_BOARD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeListDashboard: payload,
      };

    case ActionTypes.FETCH_LOG_TIME_DASH_BOARD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeListDashboard: [],
      };

    case ActionTypes.FETCH_DEADLINE_DASH_BOARD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeDeadlineDashboard: payload,
      };

    case ActionTypes.FETCH_DEADLINE_DASH_BOARD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        logTimeDeadlineDashboard: [],
      };

    case ActionTypes.FETCH_MY_WORK_DASH_BOARD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        myWorkDashboard: payload,
      };

    case ActionTypes.FETCH_MY_WORK_DASH_BOARD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangeStatus: false,
        isDeleteSuccess: false,
        myWorkDashboard: [],
      };

    default:
      return state;
  }
};
