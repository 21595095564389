import React, { useState, useEffect, CSSProperties } from "react";
import _ from "lodash";
// import dayjs from "dayjs";
import {
  FileMosaic,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";

import {
  HStack,
  Stack,
  Box,
  Icon,
  Text,
  Portal,
  // Image,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import Utils from "@/Utils";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { FileMosaicProps } from "@dropzone-ui/react";
import TextComponent from "../Text";
import { Toast } from "@/Widgets";

interface ISectionProps {
  files?: any[];
  sizeStyles?: CSSProperties;
  containerStyles?: CSSProperties;
  fileItemConfig?: FileMosaicProps;
  zIndex?: number;
  isLoading?: boolean;
}
const FileItem: React.FC<ISectionProps> = ({
  files,
  sizeStyles,
  containerStyles,
  fileItemConfig,
  zIndex,
}) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const [selectedCopyFile, setSelectedCopyFile] = useState<any>(null);

  useEffect(() => {
    if (selectedCopyFile !== null) {
      const timeoutId = setTimeout(() => {
        setSelectedCopyFile(null);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [selectedCopyFile]);

  const handleDownload = async (fileId: string, downloadUrl: string) => {
    // Check if downloadUrl is provided
    if (!downloadUrl) return;

    try {
      // Fetch the file content from the provided downloadUrl
      const image = await fetch(downloadUrl);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      // Create an anchor element for initiating the download
      const anchor = document.createElement("a");

      // Retrieve the fileName based on fileId from the 'files' array
      const fileName =
        _.filter(files, (file) => file.id === fileId)[0]?.name || "New file";

      // Set the fileName as the download attribute for the anchor element
      anchor.download = fileName;

      // Set the URL.createObjectURL result as the href for the anchor element
      anchor.href = imageURL;

      // Append the anchor to the document body, trigger the click event, and remove the anchor
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Revoke the object URL to free up resources
      URL.revokeObjectURL(imageURL);
    } catch (error: any) {
      // Handle any download errors
      Toast({ status: "error", title: error?.message });
      console.error(error);
    }
  };

  const handleSee = (imageSource: string) => {
    setImageSrc(imageSource);
  };

  const handleWatch = (videoSource: string) => {
    setVideoSrc(videoSource);
  };

  const handleCopy = (id: string) => {
    const findSelectedFile = _.find(files, (file) => file.id === id);
    if (findSelectedFile) {
      Utils.copyImageToClipboard(findSelectedFile?.downloadUrl);
    }
  };

  return (
    <Stack
      sx={{
        minH: "80px",
        ".files-ui-file-mosaic-main-container,.files-ui-file-mosaic-main-layer.files-ui-layer,.files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container":
          !_.isEmpty(sizeStyles) ? sizeStyles : "inherit",
        ".files-ui-file-item-status-container,.files-ui-file-mosaic-file-name":
          {
            display: "none",
          },
        ".files-ui-file-icon": {
          bg: "#757575",
          "&:hover": {
            bg: "rgba(32, 33, 36, 0.85)",
          },
        },
      }}
    >
      <HStack
        sx={{
          maxW: "100%",
          flexWrap: "wrap",
          ...containerStyles,
        }}
      >
        {!_.isEmpty(files) &&
          _.map(files, (extFile, index: number) => {
            return _.includes(extFile?.type, "image") ? (
              <Box
                sx={{
                  height: "auto",
                  position: "relative",
                  zIndex: zIndex || 99,
                  rounded: 5,
                  ".files-ui-file-mosaic-main-container,.files-ui-file-mosaic-main-layer.files-ui-layer,.files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container":
                    !_.isEmpty(sizeStyles)
                      ? sizeStyles
                      : {
                          w: "200px",
                          h: "200px",
                        },
                }}
              >
                {/* <Image
                  w="full"
                  h="full"
                  onClick={(e) => {
                    e.preventDefault();
                    setImageSrc(extFile?.imageUrl);
                  }}
                  rounded="5px"
                  src={extFile?.imageUrl}
                /> */}
                <FileMosaic
                  {...extFile}
                  onSee={handleSee}
                  onWatch={handleWatch}
                  onDownload={handleDownload}
                  resultOnTooltip
                  alwaysActive
                  preview
                  // info={
                  //   !_.includes(extFile?.type, "image") &&
                  //   !_.includes(extFile?.type, "video")
                  //     ? true
                  //     : undefined
                  // }
                  {...fileItemConfig}
                />

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    p: 0,
                    right: "46px",
                    zIndex: zIndex || 99,
                    lineHeight: 1,
                    w: "23px",
                    h: "23px",
                    rounded: "full",
                    bg: "#757575",
                    "&:hover": {
                      bg: "rgba(32, 33, 36, 0.85)",
                    },
                    cursor: "pointer",
                    pointerEvents: "auto",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCopy(extFile?.id);
                    setSelectedCopyFile(extFile);
                  }}
                >
                  <Icon
                    as={DocumentDuplicateIcon}
                    w="20px"
                    h="20px"
                    color="#ddd"
                  />
                </Box>
                {selectedCopyFile?.id === extFile?.id && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 99,
                      bg: "#757575",
                      inset: 0,
                      w: "100%",
                      h: "100%",
                      overflow: "hidden",
                      rounded: 5,
                      pointerEvents: "none",
                    }}
                  >
                    <Box
                      sx={{
                        position: "aboslute",
                        inset: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        w: "full",
                        h: "full",
                      }}
                    >
                      <Icon
                        as={CheckCircleIcon}
                        w="20px"
                        h="20px"
                        color="green.200"
                      />
                      <Text fontSize="sm" color="#ddd">
                        Copied
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                key={extFile?.id || index}
                sx={{
                  position: "relative",
                  zIndex: zIndex || 99,
                  rounded: 5,
                }}
              >
                {/* {_.includes(extFile?.type, "image") && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      p: 0,
                      right: "46px",
                      zIndex: zIndex || 99,
                      lineHeight: 1,
                      w: "23px",
                      h: "23px",
                      rounded: "full",
                      bg: "rgba(32, 33, 36, 0.65)",
                      "&:hover": {
                        bg: "rgba(32, 33, 36, 0.85)",
                      },
                      cursor: "pointer",
                      pointerEvents: "auto",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCopy(extFile?.id);
                      setSelectedCopyFile(extFile);
                    }}
                  >
                    <Icon
                      as={DocumentDuplicateIcon}
                      w="18px"
                      h="18px"
                      color="#ddd"
                    />
                  </Box>
                )} */}
                {/* Copied */}
                {/* {selectedCopyFile?.id === extFile?.id && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 99,
                      bg: "rgba(28, 29, 32, 0.65)",
                      inset: 0,
                      w: "100%",
                      h: "100%",
                      overflow: "hidden",
                      rounded: 5,
                      pointerEvents: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        w: "full",
                        h: "full",
                      }}
                    >
                      <Icon
                        as={CheckCircleIcon}
                        w="18px"
                        h="18px"
                        color="green.200"
                      />
                      <Text fontSize="sm" color="#ddd">
                        Copied
                      </Text>
                    </Box>
                  </Box>
                )} */}
                <HStack
                  alignItems="start"
                  flexDirection="column"
                  gap={0}
                  sx={{
                    ".files-ui-file-mosaic-main-container,.files-ui-file-mosaic-main-layer.files-ui-layer,.files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container":
                      {
                        w: "120px",
                        h: "120px",
                      },
                  }}
                >
                  <Tooltip label={extFile?.timestamp}>
                    <FileMosaic
                      {...extFile}
                      onSee={handleSee}
                      onWatch={handleWatch}
                      onDownload={handleDownload}
                      resultOnTooltip
                      alwaysActive
                      preview
                      // info={
                      //   !_.includes(extFile?.type, "image") &&
                      //   !_.includes(extFile?.type, "video")
                      //     ? true
                      //     : undefined
                      // }
                      {...fileItemConfig}
                    />
                  </Tooltip>
                  <Box>
                    <Link target="_blank" href={extFile?.downloadUrl}>
                      <TextComponent content={extFile?.name} maxW={120} />
                    </Link>
                    {/* {extFile?.timestamp && (
                      <Text
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "black",
                        }}
                      >
                        <Tooltip hasArrow label={extFile?.timestamp}>
                          {dayjs(extFile?.timestamp).format("HH:mm")}
                        </Tooltip>
                      </Text>
                    )} */}
                  </Box>
                </HStack>
              </Box>
            );
          })}
      </HStack>
      <Portal>
        <Box zIndex="sticky" w="full" h="full">
          {imageSrc && (
            <FullScreen
              open={imageSrc !== undefined}
              onClose={() => {
                setImageSrc(undefined);
              }}
            >
              <ImagePreview src={imageSrc} />
            </FullScreen>
          )}
          {videoSrc && (
            <FullScreen
              open={videoSrc !== undefined}
              onClose={() => {
                setVideoSrc(undefined);
              }}
            >
              <VideoPreview src={videoSrc} autoPlay controls />
            </FullScreen>
          )}
        </Box>
      </Portal>
    </Stack>
  );
};

export default FileItem;
