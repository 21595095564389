import socketIOClient, { Socket } from "socket.io-client";
import Utils from "@/Utils";

let socket: Socket;
const BASE_URL = import.meta.env.VITE_BE_URL;

export const initSocket = (token: string) => {
  const options = {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  };
  socket = socketIOClient(BASE_URL, options);
};

export const getSocket = () => {
  const token = Utils.getSavedToken();
  if (!socket && token) initSocket(token);
  return socket;
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const updateToken = (newToken: any) => {
  if (socket)
    socket.io.opts.extraHeaders = {
      Authorization: `Bearer ${newToken}`,
    };
};
