import { ActionTypes } from "@/Constants";
import { PURGE } from "redux-persist";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};
const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isUpdataSuccess: false,
  isCreateSuccess: false,
  isChangeStatusSuccess: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  clientList: [],
  clientDetail: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_CLIENT_REDUCER:
      return {
        ...initialState,
      };
    case ActionTypes.RESET_STATUS_CLIENT:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        isUpdataSuccess: false,
        isCreateSuccess: false,
        isChangeStatusSuccess: false,
      };
    case ActionTypes.SET_CLIENT_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_CLIENT_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_META_CLIENT:
      return {
        ...state,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_CLIENT:
      return {
        ...state,
        pagination: payload,
      };

    case ActionTypes.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        isCreateSuccess: false,
        clientList: payload,
      };

    case ActionTypes.FETCH_CLIENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        isCreateSuccess: false,
        clientList: [],
      };

    case ActionTypes.GET_BY_ID_CLIENT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        clientDetail: payload,
      };
    case ActionTypes.GET_BY_ID_CLIENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        internalDetail: {},
      };

    case ActionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdataSuccess: true,
      };
    case ActionTypes.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdataSuccess: false,
      };

    case ActionTypes.CHANGE_STATUS_CLIENT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
      };
    case ActionTypes.CHANGE_STATUS_CLIENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
      };

    default:
      return state;
  }
};
