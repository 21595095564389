import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const initialState = {
  ...DEFAULT_LOADING_STATES,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  ...generateSuccessStateVariables(["Create", "Update"]),
  payload: [],
  details: {},
  pagination: {},
  meta: {},
  performAction: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_PERFORMANCE_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_PERFORMANCE_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_PERFORMANCE_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_PERFORMANCE_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_PERFORMANCE_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.PERFORMANCE_PERFORM_ACTION:
      return {
        ...state,
        performAction: payload,
      };
    case ActionTypes.RESET_PERFORMANCE_PERFORM_ACTION:
      return {
        ...state,
        performAction: {},
        details: {},
        isCreateSuccess: false,
      };
    case ActionTypes.RESET_PERFORMANCE_REDUCER:
      return initialState;

    case ActionTypes.FETCH_PERFORMANCES_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    }
    case ActionTypes.FETCH_PERFORMANCES_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }
    case ActionTypes.GET_PERFORMANCE_BY_ID_SUCCESS: {
      return {
        ...state,
        isGetLoading: false,
        details: payload,
      };
    }
    case ActionTypes.GET_PERFORMANCE_BY_ID_FAILURE: {
      return {
        ...state,
        isGetLoading: false,
      };
    }
    case ActionTypes.CREATE_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    }
    case ActionTypes.CREATE_PERFORMANCE_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
        isCreateSuccess: false,
      };
    }

    default:
      return state;
  }
};
