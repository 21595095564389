import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { IUpdateProject } from "@/Interfaces/Project.interface";

const { PROJECT } = APIs;

export const fetchProjects = async (payload: any) => {
  return request(PROJECT.BASIC, "GET", payload);
};

export const createProject = async (payload: any) => {
  return request(PROJECT.BASIC, "POST", payload);
};

export const getProjectById = async (id: string) => {
  return request(`${PROJECT.GET_BY_ID}/${id}`, "GET");
};

export const updateProject = async (id: string, payload: IUpdateProject) => {
  return request(`${PROJECT.BASIC}/${id}`, "PUT", payload);
};

export const changeProjectStatus = async (id: string, payload: any) => {
  return request(`${PROJECT.CHANGE_STATUS}/${id}`, "PUT", payload);
};

export const deleteProject = async (id: string) => {
  return request(`${PROJECT.DELETE}/${id}`, "PUT");
};

export const getStatistical = async () => {
  return request(PROJECT.DASHBOARD_OVERVIEW, "GET");
};
