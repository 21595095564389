import { setDefaultLanguage, setTranslations } from "react-multi-lang";

import en from "./en";
import vi from "./vi";

const getSavedLanguage = () => {
  const lang = localStorage.getItem("language");
  if (lang) return JSON.parse(lang);
  return "en";
};

const initTranslations = () => {
  //  const language = Utils.getSavedLanguage();
  //  const localeStorage = Utils.getSavedLanguage();

  const language = getSavedLanguage();
  //  const language = "en";
  //  console.log(language);

  setTranslations({ en, vi });
  setDefaultLanguage(language);
};

export default { initTranslations };
