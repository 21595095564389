import React from "react";
import { Text, TextProps, Tooltip } from "@chakra-ui/react";

interface IProps extends TextProps {
  content: string;
  maxW: string | number;
  sx?: any;
  hover?: boolean;
  showTooltip?: boolean;
}

const TextComponent: React.FC<IProps> = ({
  content,
  maxW,
  sx,
  hover = false,
  showTooltip = true,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [showLink, setShowLink] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current && ref.current.clientWidth < ref.current.scrollWidth) {
      setShowLink(true);
    }
  }, [content]);

  return !showLink ? (
    <Text
      ref={ref}
      maxW={maxW}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      display="inline-block"
      sx={{ ...sx }}
    >
      {content}
    </Text>
  ) : (
    <Tooltip label={!hover && showTooltip && content} hasArrow placement="auto">
      <Text
        ref={ref}
        maxW={maxW}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        sx={{ ...sx }}
      >
        {content}
      </Text>
    </Tooltip>
  );
};

export default TextComponent;
