import React, { useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Box,
  Text,
  Icon,
} from "@chakra-ui/react";

import { useTypedDispatch } from "@/Store";
import { CloseIcon } from "@chakra-ui/icons";
import { LogtimeActions } from "@/Actions";
import { useTranslation } from "react-multi-lang";

interface SectionProps {
  open: boolean;
  onClose(): void;
  date?: string;
}

const { performAction } = LogtimeActions;

const MoreOptionsDialog: React.FC<SectionProps> = ({ open, onClose, date }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Button
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(performAction("logInternal", "", { date: date }));
            onClose();
          }}
        >
          {t("button.internalProject")}
        </Button>
        <Button
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(performAction("logExternal", "", { date: date }));
            onClose();
          }}
        >
          {t("button.externalProject")}
        </Button>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xs"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 5,
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 1px -1px gray",
            borderRadius: 5,
          }}
        >
          <Box>
            <Text> {t("label.moreOptions")}</Text>
            <Box sx={{ position: "absolute", top: 0, right: 5 }}>
              <Icon
                as={CloseIcon}
                boxSize={3}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => onClose()}
              />
            </Box>
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MoreOptionsDialog;
