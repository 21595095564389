import { PURGE } from "redux-persist";

import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const initialState = {
  ...DEFAULT_LOADING_STATES,
  ...generateSuccessStateVariables([
    "CreateColumn",
    "UpdateColumn",
    "CreateComment",
    "DeleteComment",
  ]),
  payload: [],
  taskDetails: {},
  performAction: {},
  myTimeWeekDays: [],
  dashboardWeekDays: [],
  timelineInBoard: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_BOARD_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_BOARD_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_BOARD_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_MY_TIME_WEEKDAYS:
      return {
        ...state,
        myTimeWeekDays: payload,
      };
    case ActionTypes.SET_DASHBOARD_WEEKDAYS:
      return {
        ...state,
        dashboardWeekDays: payload,
      };
    case ActionTypes.BOARD_PERFORM_ACTION:
      return {
        ...state,
        performAction: payload,
      };
    case ActionTypes.RESET_BOARD_PERFORM_ACTION:
      return {
        ...state,
        performAction: {},
      };
    case ActionTypes.RESET_WEEKDAYS:
      return {
        ...state,
        myTimeWeekDays: [],
        dashboardWeekDays: [],
      };
    case ActionTypes.RESET_BOARD_STATUS:
      return {
        ...state,
        isGetLoading: false,
        isFetchLoading: false,
        isActionLoading: false,
        isCreateColumnSuccess: false,
        isUpdateColumnSuccess: false,
        isCreateCommentSuccess: false,
        isDeleteCommentSuccess: false,
      };
    case ActionTypes.RESET_BOARD_DETAIL: {
      return {
        ...state,
        details: {},
      };
    }
    case ActionTypes.RESET_TIMELINE_IN_BOARD: {
      return {
        ...state,
        timelineInBoard: {},
      };
    }
    case ActionTypes.RESET_BOARD_REDUCER: {
      return initialState;
    }

    case ActionTypes.CREATE_BOARD_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
      };

    case ActionTypes.CREATE_BOARD_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };

    case ActionTypes.CREATE_COLUMN_IN_BOARD_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        isCreateColumnSuccess: true,
      };

    case ActionTypes.CREATE_COLUMN_IN_BOARD_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
        isCreateColumnSuccess: false,
      };

    case ActionTypes.GET_COLUMN_IN_BOARD_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        taskDetails: payload,
      };

    case ActionTypes.GET_COLUMN_IN_BOARD_FAILURE:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };

    case ActionTypes.UPDATE_COLUMN_IN_BOARD_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        isUpdateColumnSuccess: true,
      };

    case ActionTypes.UPDATE_COLUMN_IN_BOARD_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
        isUpdateColumnSuccess: false,
      };

    case ActionTypes.FETCH_BOARDS_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        payload,
      };

    case ActionTypes.FETCH_BOARDS_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };
    case ActionTypes.GET_BOARD_BY_ID_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        details: payload,
      };

    case ActionTypes.GET_BOARD_BY_ID_FAILURE:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };
    case ActionTypes.CREATE_COMMENT_IN_COLUMN_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        isCreateCommentSuccess: true,
      };
    case ActionTypes.CREATE_COMMENT_IN_COLUMN_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
        isCreateCommentSuccess: false,
      };
    case ActionTypes.DELETE_COMMENT_IN_COLUMN_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        isDeleteCommentSuccess: true,
      };
    case ActionTypes.DELETE_COMMENT_IN_COLUMN_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
        isDeleteCommentSuccess: false,
      };
    case ActionTypes.DELETE_COLUMN_IN_BOARD_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
      };
    case ActionTypes.DELETE_COLUMN_IN_BOARD_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };
    case ActionTypes.GET_TIMELINE_IN_BOARD_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        requestIsFailure: false,
        timelineInBoard: payload,
      };
    case ActionTypes.GET_TIMELINE_IN_BOARD_FAILURE:
      return {
        ...state,
        isGetLoading: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        requestIsFailure: true,
      };

    default:
      return state;
  }
};
