import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import Column from "./Column";
import {
  Box,
  Icon,
  Button,
  Skeleton,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { BoardDialog } from "@/Components/Popup";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  columns: any[];
}

const gridStyles = {
  display: "flex",
  flexWrap: "nowrap",
  gap: "1.25rem",
  overflow: "auto",
};

const List: React.FC<ISectionProps> = ({ columns }) => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const canCreateProcess = useMemo(() => {
    const userRoles = _.map(userData?.userRole, (userRole) =>
      _.get(userRole, "role")
    );
    return Utils.hasPermission(userRoles, "Board", "editProcess");
  }, [userData]);
  const details: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const canEditProcess = useMemo(() => {
    const userRoles = _.map(userData?.userRole, (userRole) =>
      _.get(userRole, "role")
    );
    return Utils.hasPermission(userRoles, "Board", "editProcess");
  }, [userData]);

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isFetchLoading")
  );

  const isActionProjectLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isActionLoading")
  );

  const [isShowCreateColumn, setIsShowCreateColumn] = useState(false);

  return (
    <SortableContext
      items={columns}
      disabled={!canEditProcess}
      strategy={horizontalListSortingStrategy}
    >
      <Box>
        {isFetchLoading || isActionProjectLoading ? (
          <Grid templateColumns={`repeat(${isMobile ? 2 : 5}, 1fr)`} gap={4}>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} height={`calc(100vh - 200px)`} />
            ))}
          </Grid>
        ) : (
          <Box sx={gridStyles}>
            {_.map(columns, (column, index) => (
              <Box key={index} sx={{ width: 250 }}>
                <Column column={column} />
              </Box>
            ))}
            {canCreateProcess && (
              <Button
                //sx={{ minWidth: "max-content" }}
                size="sm"
                leftIcon={<Icon boxSize={5} as={PlusIcon} />}
                onClick={() => setIsShowCreateColumn(!isShowCreateColumn)}
                //colorScheme="twitter"
                sx={{
                  minWidth: "max-content",
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
                isDisabled={_.isEmpty(details)}
              >
                {t("button.addProcess")}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <BoardDialog.CreateColumnDialog
        open={isShowCreateColumn}
        onClose={() => setIsShowCreateColumn(!isShowCreateColumn)}
      />
    </SortableContext>
  );
};

export default List;
