import React, { useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { RootState, useTypedDispatch } from "@/Store";
import { IUserPerformAction } from "@/Interfaces/User.interface";
import Utils from "@/Utils";
import { ChatActions } from "@/Actions";
import { ENUMS, Routers } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const {
  createNewChat,
  performAction: chatPerformAction,
  setTypeChat,
} = ChatActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const UserProfile: React.FC<SectionProps> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const userLogged = Utils.getSavedUserData();
  const performAction: IUserPerformAction = useSelector((state: RootState) =>
    _.get(state.USER, "performAction")
  );

  const personalChat: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "personalChat")
  );
  const typeChat: string = useSelector((state: RootState) =>
    _.get(state.CHAT, "typeChat")
  );

  const cancelRef = useRef<any>(null);

  const userId = performAction?.payload?.user?.id ?? performAction?.payload?.id;
  const avatarPath =
    performAction?.payload?.user?.userData?.avatar?.path ??
    performAction?.payload?.userData?.avatar?.path;

  const fullName =
    performAction?.payload?.user?.userData?.fullName ??
    performAction?.payload?.userData?.fullName ??
    "--";

  const email =
    performAction?.payload?.user?.email ?? performAction?.payload?.email;

  const phoneNumber =
    performAction?.payload?.user?.userData?.phoneNumber ??
    performAction?.payload?.userData?.phoneNumber;

  const address =
    performAction?.payload?.user?.userData?.address ??
    performAction?.payload?.userData?.address;

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          mx: "auto",
        }}
      >
        <Avatar size="xl" src={avatarPath} name={fullName} />
      </Box>
      <Text
        sx={{
          fontSize: "sm",
          textAlign: "left",
        }}
      >
        <b>{t("label.fullName")}:</b> {fullName}
      </Text>
      <Text
        sx={{
          fontSize: "sm",
          textAlign: "left",
        }}
      >
        <b>Email:</b> {email}
      </Text>
      <Text
        sx={{
          fontSize: "sm",
          textAlign: "left",
        }}
      >
        <b>{t("label.phoneNumber")}:</b> {phoneNumber || "--"}
      </Text>
      <Text
        sx={{
          fontSize: "sm",
          textAlign: "left",
        }}
      >
        <b>{t("label.address")}:</b> {address || "--"}
      </Text>
    </Box>
  );

  const roleNames = _.map(
    performAction?.payload?.user?.userRole ??
      performAction?.payload?.userRole ??
      [],
    (userRole) =>
      userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        ? `Staff`
        : _.startCase(userRole?.role?.name)
  );

  const concatenatedRoleNames = roleNames.join(", ");

  const handleSendMsg = () => {
    let foundChat: any = null;
    _.some(personalChat, (chat) => {
      const foundMember = _.find(
        chat?.channel?.messageChannelMember,
        (member) => member.user.id === userId
      );
      if (foundMember) {
        foundChat = chat;
        return true;
      }
      return false;
    });

    if (typeChat === "group") dispatch(setTypeChat("private"));
    if (foundChat) {
      onClose();
      Utils.redirect(Routers.CHAT);
      dispatch(chatPerformAction("view", foundChat?.channel?.id, foundChat));
    } else {
      const FAKE_NEW_CHAT_DATA = {
        id: "new-chat-data-placeholder",
        name: fullName,
        sender: {
          id: userLogged?.id,
          name: userLogged?.userData?.fullName || "Sender",
          avatar: userLogged?.userData?.avatar?.path,
        },
        receiver: {
          id: userId,
          name: fullName || "Receiver",
          avatar: avatarPath,
        },
        latestMessage: null,
        timestamp: "",
        messageType: "personal",
      };
      onClose();
      Utils.redirect(Routers.CHAT);
      dispatch(createNewChat(FAKE_NEW_CHAT_DATA));
      dispatch(chatPerformAction("view", "new-chat-data-placeholder"));
    }
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Heading size="md">{t("title.profile")}</Heading>
            <Heading
              size="sm"
              textTransform="uppercase"
              sx={{
                wordWrap: "break-word",
                textAlign: "center",
              }}
            >
              {concatenatedRoleNames}
            </Heading>
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} size="sm">
            {t("button.cancel")}
          </Button>
          {userLogged?.id !== userId && (
            <Button
              onClick={() => {
                handleSendMsg();
              }}
              ml={3}
              //  colorScheme="twitter"
              size="sm"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.send")}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UserProfile;
