export default {
  mainBackground: "rgba(92, 110, 108,1)",
  //  mainColor: "rgb(82, 101, 133)",
  burntSienna: "#E87844",
  bismark: "#517284",
  pickledBluewood: "#324A58",
  loblolly: "#C2D2D6",
  oceanGreen: "#4BB180",
  hulk: "#008000",
  fuzzyWuzzyBrown: "#C95454",
  diSerria: "#DA9555",
  yellow: "#FFFF00",
  mystic: "#E7ECEF",
  astronaut: "#274C77",
  hippieBlue: "#6096BA",
  perano: "#A3CEF1",
  lemonGrass: "#8B8C89",
  shark: "#24292E",
  black: "#000000",
  white: "#ffffff",
  deepskyblue: "#00BFFF",
  blueLight: "rgba(187, 113, 84,0.2)",
  //  button: "rgb(56, 88, 152)",
  //  hoverButton: "rgb(49, 78, 137)",
  button: "rgb(1, 143, 191)",
  hoverButton: "rgb(0, 194, 242)",
  mainColor: "rgb(1, 143, 191)",
  toggleButton: "#66BBD8",
  buttonClandar: "rgb(82, 101, 133)",
  bluehide: "#33CCFF",
  LightSkyBlue4: "#607B8B",
  LightSkyBlue3: "#8DB6CD",
  balsamGreen: "#5C6E6C",
  aquatone: "#A6B7AA",
  artermis: "#D2A96A",
  dustyCoral: "#D39D87",
  warmCopper: "#BB7154",
  //  blueLight: "#E1F0FF",
  edward: "rgba(166, 183, 170,0.8)",
  lightOrange: "#D2A96A",
  deepOrange: "#BB7154",
};
