import React, { useState } from "react";
import _ from "lodash";

import { IPaginate, TActions } from "@/Types/Common.types";
import { Table } from "rsuite";
import { Box, IconButton } from "@chakra-ui/react";

import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
//import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { NoDataFound } from "@/Components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  //  onRowAction: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  type?: string;
  onChangeLimit?: (limint: number) => void;
}
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const TableTest: React.FC<IProps> = ({ payload, isLoading }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const rowKey = "id";
  const t = useTranslation();
  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );
  const checkCurrentimeMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key: any) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const ExpandCell = ({ rowData, onChange, ...props }: any) => (
    <Cell {...props} style={{ padding: 5 }}>
      <IconButton
        onClick={() => {
          onChange(rowData);
        }}
        sx={{
          bg: "transparent",
        }}
        isRound
        aria-label={""}
        icon={
          expandedRowKeys.some((key: any) => key === rowData[rowKey]) ? (
            <ChevronDownIcon />
          ) : (
            <ChevronRightIcon />
          )
        }
      />
    </Cell>
  );

  const renderRowExpanded = (rowData: any) => {
    const logTime = rowData?.logTime || [];
    return (
      <Box>
        <Table
          data={logTime}
          hover={false}
          cellBordered
          bordered
          style={{ height: "100%" }}
          affixHeader
          loading={isLoading}
          height={400}

          // renderEmpty={() => (
          //   <Box sx={{ bg: "white", height: "100%" }}>render empty here</Box>
          // )}
        >
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}>{t("table.date")}</HeaderCell>
            <Cell dataKey="date" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}>{t("table.startTime")}</HeaderCell>
            <Cell dataKey="startTime" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}>{t("table.endTime")}</HeaderCell>
            <Cell dataKey="endTime" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}>
              {t("table.workingTime")} (h)
            </HeaderCell>
            <Cell>
              {(rowData) => Math.round(rowData.workingTime * 1000) / 1000}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}> {t("table.dateType")}</HeaderCell>
            <Cell style={{ textTransform: "capitalize" }}>
              {(rowData) => {
                const dateObject = new Date(rowData?.date);
                const dayOfWeek = dateObject.getDay();
                return rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND
                  ? dayOfWeek !== 0
                    ? t(`label.weekend`)
                    : t(`label.sunday`)
                  : t(`label.${rowData?.dateType}`);
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}>
              {t("table.coefficient")}
            </HeaderCell>
            <Cell>
              {(rowData) => {
                if (rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND)
                  return 2;
                if (rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY)
                  return 3;
                if (
                  rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
                  rowData?.startTime >= checkCurrentimeMorning
                )
                  return 1.5;
                if (
                  rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
                  rowData?.startTime < checkCurrentimeMorning
                )
                  return 2;
                return 0;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell style={HEADER_STYLE}> {t("table.total")}</HeaderCell>
            <Cell>
              {(rowData) => {
                if (rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND)
                  return Math.round(rowData.workingTime * 1000 * 2) / 1000;
                if (rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY)
                  return Math.round(rowData.workingTime * 1000 * 3) / 1000;
                if (
                  rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
                  rowData?.startTime >= checkCurrentimeMorning
                )
                  return Math.round(rowData.workingTime * 1000 * 1.5) / 1000;
                if (
                  rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
                  rowData?.startTime < checkCurrentimeMorning
                )
                  return Math.round(rowData.workingTime * 1000 * 2) / 1000;
                return 0;
              }}
            </Cell>
          </Column>
        </Table>
      </Box>
    );
  };
  return (
    <Box>
      <Table
        data={payload}
        autoHeight
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        rowKey={rowKey}
        shouldUpdateScroll={false}
        expandedRowKeys={expandedRowKeys}
        renderRowExpanded={renderRowExpanded}
        rowExpandedHeight={400}
      >
        <Column width={70} align="center">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            #
          </HeaderCell>
          <ExpandCell
            dataKey="id"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Column>
        <Column flexGrow={1} fixed>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.fullName")}
          </HeaderCell>
          <Cell dataKey="fullName" />
        </Column>
        <Column width={120} align="center">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.staffCode")}
          </HeaderCell>
          <Cell dataKey="staffCode" />
        </Column>
        <Column flexGrow={1} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.role")}
          </HeaderCell>
          <Cell style={{ textTransform: "capitalize" }}>
            {(rowData) => {
              const roles: string[] = [];
              _.forEach(rowData?.userRole, (itemRole: any) => {
                if (itemRole?.role?.roleCode === "artist") roles.push("Staff");
                else roles.push(itemRole?.role?.name);
              });
              return roles.join(", ");
            }}
          </Cell>
        </Column>
        <Column align="center">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.total")} (h)
          </HeaderCell>
          <Cell dataKey="total">
            {(rowData) => {
              return Math.round(rowData.total * 1000) / 1000;
            }}
          </Cell>
        </Column>
      </Table>
    </Box>
  );
};

export default TableTest;
