import { toast } from "@/Routers";

interface IProps {
  title: string;
  description?: string;
  status: "error" | "warning" | "info" | "success" | "loading";
}

const Toast = async (payload: IProps) => {
  const { status = "info", title = "", description = "", ...props } = payload;

  const toastOptions = {
    title,
    description,
    isClosable: true,
    duration: 3000,
    status,
    variant: "left-accent",
    // icon,
    ...props,
  };

  const toastInstance = toast(toastOptions);

  setTimeout(() => {
    toast.close(toastInstance);
  }, toastOptions.duration);
};

export default Toast;
