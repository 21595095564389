import { Toast } from "@/Widgets";

import { ActionTypes } from "@/Constants";

import Utils from "@/Utils";
import APIs from "@/APIs";

const setMailReceiveFetchLoading = () => {
  return {
    type: ActionTypes.SET_MAIL_RECEIVE_FETCH_LOADING,
  };
};

const setMailReceiveActionLoading = () => {
  return {
    type: ActionTypes.SET_MAIL_RECEIVE_ACTION_LOADING,
  };
};

const resetMailReceiveReducer = () => {
  return {
    type: ActionTypes.RESET_MAIL_RECEIVE_REDUCER,
  };
};

const fetchMailReceivetSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MAIL_RECEIVE_SUCCESS,
    payload,
  };
};

const fetchMailReceivetFail = () => {
  return {
    type: ActionTypes.FETCH_MAIL_RECEIVE_FAILURE,
  };
};

const fetchMailReceive = () => {
  return async (dispatch: any) => {
    dispatch(setMailReceiveFetchLoading());
    await APIs.fetchMailReceive()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchMailReceivetFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchMailReceivetSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchMailReceivetFail());
      });
  };
};

const createMailReceiveSuccess = () => {
  return {
    type: ActionTypes.CREATE_MAIL_RECEIVE_SUCCESS,
  };
};

const createMailReceiveFail = () => {
  return {
    type: ActionTypes.CREATE_MAIL_RECEIVE_FAILURE,
  };
};

const createMailReceive = (
  payload: { mail: string; type: string },
  onSucess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setMailReceiveActionLoading());
    await APIs.createMailReceive(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await dispatch(createMailReceiveFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createMailReceiveSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchMailReceive());
          onSucess();
        }
      })
      .catch(async (error) => {
        await dispatch(createMailReceiveFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateMailReceiveSuccess = () => {
  return {
    type: ActionTypes.UPDATE_MAIL_RECEIVE_SUCCESS,
  };
};

const updateMailReceiveFail = () => {
  return {
    type: ActionTypes.UPDATE_MAIL_RECEIVE_FAILURE,
  };
};

const updateMailReceive = (
  id: string,
  payload: { mail: string; type: string },
  onSucess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setMailReceiveActionLoading());
    await APIs.updateMailReceive(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          const message = response?.data?.message;
          await dispatch(updateMailReceiveFail());
          await Toast({
            title: "Update client failed",
            description: message,
            status: "error",
          });
        } else {
          dispatch(updateMailReceiveSuccess());
          dispatch(fetchMailReceive());
          Toast({
            title: "Update client successfully",
            status: "success",
          });
          onSucess();
        }
      })
      .catch(async (error) => {
        await dispatch(updateMailReceiveFail());
        await Toast({
          title: "Update client failed",
          description: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchMailReceive,
  createMailReceive,
  updateMailReceive,
  resetMailReceiveReducer,
};
