import {
  TextField,
  UploadAvatar,
  UploadFileInput,
  Autocomplete,
  Select,
  DatePicker,
  RoundedContainer,
} from "@/Components/Common";
import { AdminLayout } from "@/Components/DefaultLayout";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useBreakpoint,
  Icon,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  UnlockIcon,
  ArrowBackIcon,
  EditIcon,
  CloseIcon,
} from "@chakra-ui/icons";

import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { UserActions, RoleActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { IUser } from "@/Interfaces/User.interface";
import { UserDialog } from "@/Components/Popup";
import Utils from "@/Utils";
import { ENUMS, Routers } from "@/Constants";
import TextareaComponent from "@/Components/Common/Textarea";
import { IUserRoleStructure } from "@/Interfaces/Role.interface";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";

const {
  getByIdUser,
  activateUser,
  deactivateUser,
  updateUser,
  resetUserDetail,
} = UserActions;
const { fetchRoles } = RoleActions;

const DEFAULT_FORM_DATA = {
  email: "",
  roleCode: [],
  phoneNumber: "",
  lastName: "",
  firstName: "",
  address: "",
  bankAccountNumber: "",
  bankName: "",
  insuranceCompany: "",
  contractTerm: "",
  officialWorkingDay: "",
  probationWorkingDay: "",
  extraData: "",
  gender: "",
  dob: "",
  staffCode: "",
  probationEndDay: "",
  bankAccountName: "",
};

const UpdateUser = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 400;

  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");
  const [avatar, setAvatar] = React.useState<File[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);

  const [isShowPopup, setIsShowPopup] = React.useState({
    changeStatus: false,
    changePassword: false,
  });
  const [id, setId] = useState<any>();

  const [isEditAttachments, setIsEditAttachments] = React.useState(false);

  const userDetail: IUser = useSelector((state: RootState) =>
    _.get(state.USER, "userDetail")
  );

  const roles: any = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.USER, "isActionLoading")
  );

  const isUpdateForSelfSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.USER, "isUpdateForSelfSuccess")
  );

  const checkChangeStatus = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "CheckPassWord"
  );
  const checkEditEmail = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "checkEditEmail"
  );

  const CheckPassWord = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "CheckPassWord"
  );

  const roleOptions = React.useMemo(
    () =>
      _.map(
        roles.filter((item: any) => item?.roleCode !== ENUMS.ROLES.ADMIN),
        (role: any) => ({
          label: role.name === "artist" ? "Staff" : _.startCase(role.name),
          value: role.id,
        })
      ),
    [roles]
  );

  const roleOptionsAdmin = React.useMemo(
    () =>
      _.map(
        userDetail?.userRole?.filter(
          (item: any) => item?.role?.roleCode === ENUMS.ROLES.ADMIN
        ),
        (role: any) => ({
          label: _.startCase(role?.role?.name),
          value: role.role.id,
        })
      ),
    [userDetail]
  );

  const breakpoints = {
    base: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    "2xl": 3,
  };

  const breakpointsDrop = {
    base: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
    "2xl": 2,
  };

  const currentBreakpoint = useBreakpoint();
  const currentBreakpointDrop = useBreakpoint();

  const columns =
    breakpoints[currentBreakpoint as keyof typeof breakpoints] || 1;
  const columnsDrop =
    breakpoints[currentBreakpointDrop as keyof typeof breakpointsDrop] || 1;

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .email(t("message.emailMustBeAValidEmail"))
        .required(t("message.emailIsRequired")),
      firstName: yup.string().trim().required(t("message.firtsNameIsRequired")),
      lastName: yup.string().trim().required(t("message.lastNameIsRequired")),
      roleCode: yup.array().required(t("message.roleIsRequired")),
      phoneNumber: yup.string().trim(),
      gender: yup.string().trim(),
      dob: yup.string().trim(),
      insuranceCompany: yup.string().trim(),
      probationWorkingDay: yup.string().trim(),
      officialWorkingDay: yup.string().trim(),
      contractTerm: yup.string().trim(),
      bankAccountNumber: yup.string().trim(),
      bankName: yup.string().trim(),
      address: yup.string().trim(),
      staffCode: yup.string().trim(),
      probationEndDay: yup.string().trim(),
      bankAccountName: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    //getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const findDeletedAndNewFiles = (beforeUpload: any, afterUpload: any) => {
    // Find deleted files
    const deletedFiles = _.filter(
      beforeUpload,
      (beforeFile: any) =>
        !afterUpload.some((afterFile: any) => afterFile.id === beforeFile.id)
    );

    // Find newly added files
    const newFiles = _.filter(
      afterUpload,
      (afterFile: any) =>
        !beforeUpload.some((beforeFile: any) => beforeFile.id === afterFile.id)
    );
    return { deletedFiles, newFiles };
  };

  const generateDetails = async () => {
    if (!_.isEmpty(userDetail)) {
      setValue("email", userDetail.email);
      setValue("firstName", userDetail.userData.firstName);
      setValue("lastName", userDetail.userData.lastName);
      setValue("dob", userDetail.userData.dob || "");
      setValue("gender", userDetail.userData.gender || "");
      setValue(
        "roleCode",
        _.map(
          userDetail?.userRole,
          (userRole: IUserRoleStructure) => userRole.role.id
        )
      );
      setValue("staffCode", userDetail.staffCode || "");
      setValue(
        "probationWorkingDay",
        userDetail.userData.probationWorkingDay || ""
      );
      setValue(
        "officialWorkingDay",
        userDetail.userData.officialWorkingDay || ""
      );
      setValue("contractTerm", userDetail.userData.contractTerm || "");
      setValue("bankName", userDetail.userData.bankName || "");
      setValue("probationEndDay", userDetail.userData.probationEndDay || "");
      setValue(
        "bankAccountNumber",
        userDetail.userData.bankAccountNumber || ""
      );
      // setValue("bankAccountName", userDetail.userData.bankAccountName || "");
      setValue("insuranceCompany", userDetail.userData.insuranceCompany || "");
      setValue("address", userDetail.userData.address || "");
      setValue("phoneNumber", userDetail.userData.phoneNumber || "");
      //  if (userDetail?.userData?.fileAttachment)
      //    setFiles(userDetail?.userData?.fileAttachment);
      if (userDetail?.userData?.avatar) {
        const isInternal =
          userDetail?.userData?.avatar?.storageService === "Internal";
        const avatarSrc = isInternal
          ? await Utils.getFileFromURL(
              _.get(userDetail, "userData.avatar.path") || "",
              _.get(userDetail, "userData.avatar.nameOriginal") || ""
            )
          : await Utils.getAWSFileAsBlob(
              _.get(userDetail, "userData.avatar.path") || "",
              _.get(userDetail, "userData.avatar.nameOriginal") || ""
            );
        if (avatarSrc) setAvatar([avatarSrc as File]);
      }
      if (userDetail?.userData?.fileAttachment)
        setFiles(userDetail?.userData?.fileAttachment);
    }
  };

  const onChangeStatus = (action: string) => {
    if (userId)
      if (action === "active") {
        dispatch(activateUser(userId));
      } else {
        dispatch(deactivateUser(userId));
      }
  };

  const onChangeFile = (data: any) => {
    setFiles(data);
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (!_.isEmpty(files)) {
      const { deletedFiles, newFiles } = findDeletedAndNewFiles(
        userDetail?.userData?.fileAttachment,
        files
      );
      if (!_.isEmpty(deletedFiles)) {
        _.forEach(deletedFiles, (file: any) =>
          formData.append("removeFileAttachments[]", file.id)
        );
      }

      if (newFiles) {
        _.forEach(newFiles, (item: any) => {
          if (item.valid)
            formData.append("userData[fileAttachment]", item.file);
        });
      }
    } else if (!_.isEmpty(userDetail?.userData?.fileAttachment)) {
      const deletedFiles = userDetail?.userData?.fileAttachment;
      _.forEach(deletedFiles, (file: any) =>
        formData.append("removeFileAttachments[]", file.id)
      );
    }
    if (!_.isEmpty(data.dob))
      formData.append("userData[dob]", !_.isEmpty(data.dob) ? data.dob : null);
    if (!_.isEmpty(data.officialWorkingDay))
      formData.append(
        "userData[officialWorkingDay]",
        !_.isEmpty(data.officialWorkingDay) ? data.officialWorkingDay : ""
      );
    if (!_.isEmpty(data.probationWorkingDay))
      formData.append(
        "userData[probationWorkingDay]",
        !_.isEmpty(data.probationWorkingDay) ? data.probationWorkingDay : ""
      );
    if (!_.isEmpty(avatar)) formData.append("userData[avatar]", avatar[0]);
    formData.append("email", data.email);
    formData.append("userData[firstName]", data.firstName);
    formData.append("userData[lastName]", data.lastName);

    if (!_.isEmpty(data.roleCode)) {
      const { addedItems, removedItems } = Utils.findAddedAndRemovedItems(
        _.map(userDetail?.userRole, (userRole: any) => userRole?.role?.id),
        data.roleCode
      );
      if (addedItems) {
        _.forEach(addedItems, (item) => {
          formData.append("newRole[]", item);
        });
      }
      if (removedItems) {
        _.forEach(removedItems, (item) => {
          formData.append("removeRole[]", item);
        });
      }
    }

    formData.append(
      "userData[phoneNumber]",
      !_.isEmpty(data.phoneNumber) ? data.phoneNumber : ""
    );
    formData.append(
      "userData[bankAccountNumber]",
      !_.isEmpty(data.bankAccountNumber) ? data.bankAccountNumber : ""
    );
    formData.append(
      "userData[bankAccountName]",
      !_.isEmpty(data.bankAccountName) ? data.bankAccountName : ""
    );
    formData.append(
      "userData[bankName]",
      !_.isEmpty(data.bankName) ? data.bankName : ""
    );
    formData.append(
      "userData[insuranceCompany]",
      !_.isEmpty(data.insuranceCompany) ? data.insuranceCompany : ""
    );
    formData.append(
      "userData[contractTerm]",
      !_.isEmpty(data.contractTerm) ? data.contractTerm : ""
    );

    formData.append(
      "userData[gender]",
      !_.isEmpty(data.gender) ? data.gender : ""
    );
    formData.append(
      "userData[address]",
      !_.isEmpty(data.address) ? data.address : ""
    );
    if (data?.probationEndDay)
      formData.append(
        "userData[probationEndDay]",
        !_.isEmpty(data.probationEndDay) ? data.probationEndDay : ""
      );
    if (userId) dispatch(updateUser(userId, formData));
  };

  React.useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (userId && accessRouter) dispatch(getByIdUser(userId));
  }, []);

  React.useEffect(() => {
    generateDetails();
  }, [userDetail]);

  React.useEffect(() => {
    dispatch(fetchRoles());
    return () => {
      reset();
      dispatch(resetUserDetail());
    };
  }, []);

  React.useEffect(() => {
    if (isUpdateForSelfSuccess) {
      setId("");
      setIsShowPopup({ changePassword: false, changeStatus: false });
    }
  }, [isUpdateForSelfSuccess]);

  const _renderBottom = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {CheckPassWord && (
            <Button
              //  colorScheme="messenger"
              onClick={() => {
                setIsShowPopup({ ...isShowPopup, changePassword: true });
                setId(userDetail?.id);
              }}
              size={"sm"}
              rightIcon={
                <Icon as={UnlockIcon} boxSize={4} mr={isMobile ? 2 : 0} />
              }
              sx={{
                color: "#fff",
                background: "#bb7154",
                "&:hover": {
                  background: "#Db9d97",
                },
              }}
            >
              {isMobile ? "" : t("button.changePassword")}
            </Button>
          )}
        </Box>
        <Box>
          <Button
            onClick={() => Utils.redirect(Routers.USER)}
            isLoading={isLoading}
            leftIcon={<Icon as={ArrowBackIcon} boxSize={3} />}
            size={"sm"}
          >
            {t("button.back")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            size="sm"
            //colorScheme="twitter"
            isLoading={isLoading}
            rightIcon={<Icon as={EditIcon} boxSize={3} />}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderForm = () => {
    return (
      <RoundedContainer as={"form"}>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3}>
          <GridItem>
            <Stack direction="column" alignItems="center" rowGap={2}>
              <UploadAvatar
                direction="row"
                labelSx={{ minWidth: "250px" }}
                containerSx={{ mb: 2 }}
                files={avatar}
                onFileChange={(newFile) => setAvatar(newFile)}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    disabled={
                      userDetail?.userRole?.[0]?.role?.roleCode !==
                        ENUMS.ROLES.ADMIN || !checkEditEmail
                    }
                    placeholder="Email address"
                    value={field.value}
                    label="Email"
                    onChange={(e: any) => field.onChange(e)}
                    isError={!isEmpty(errors.email?.message)}
                    errorMessage={errors.email?.message}
                    isRequired
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Text fontSize="18px" fontWeight={600}>
                {t("title.persionalInformations")}
              </Text>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.firstName")}
                    label={t("label.firstName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.firstName?.message}
                    isError={!isEmpty(errors.firstName?.message)}
                    isRequired
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.lastName")}
                    label={t("label.lastName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    errorMessage={errors.lastName?.message}
                    isError={!isEmpty(errors.lastName?.message)}
                    isRequired
                  />
                )}
              />
              <Stack direction="row" columnGap={2}>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      maxDate={new Date()}
                      value={field.value}
                      onDateChange={(newDate: any) =>
                        field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                      }
                      label={t("label.birthday")}
                      size="sm"
                    />
                  )}
                />
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value || ""}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      size="sm"
                      label={t("label.gender")}
                      //  placeholder="Gender"
                      options={[
                        { label: "", value: "" },
                        { label: t("label.male"), value: "male" },
                        { label: t("label.female"), value: "female" },
                        { label: t("label.other"), value: "other" },
                      ]}
                    />
                  )}
                />
              </Stack>

              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.phoneNumber")}
                    label={t("label.phoneNumber")}
                    type="number"
                    phoneInputvalue={field.value}
                    onPhoneInputChange={(e: any) => field.onChange(e)}
                    autoComplete="false"
                    // onWheel={() =>
                    //   (document.activeElement as HTMLElement).blur()
                    // }
                    isPhoneInput
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextareaComponent
                    placeholder={t("label.address")}
                    label={t("label.address")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    //isError={!isEmpty(errors.fullName?.message)}
                  />
                )}
              />
            </Stack>
          </GridItem>
          <GridItem>
            <Stack direction="column" rowGap={2}>
              <Text fontSize="18px" fontWeight={600}>
                {t("title.positionInformations")}
              </Text>
              <Controller
                name="roleCode"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value}
                    onChange={(newValue: any) => field.onChange(newValue)}
                    label={t("label.role")}
                    isMulti
                    options={
                      !_.isEmpty(roleOptionsAdmin)
                        ? roleOptionsAdmin
                        : roleOptions
                    }
                    isRequired
                    disabled={!_.isEmpty(roleOptionsAdmin)}
                  />
                )}
              />
              <TextField
                value={userDetail?.staffCode}
                size="sm"
                disabled
                placeholder={t("label.staffCode")}
                label={t("label.staffCode")}
                //value={field.value}
                //onChange={(e: any) => field.onChange(e)}
              />
              <Stack direction="row" columnGap={2}>
                <Controller
                  name="probationWorkingDay"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onDateChange={(newDate: any) =>
                        field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                      }
                      label={t("label.probationWorkingDay")}
                    />
                  )}
                />
                <Controller
                  name="probationEndDay"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onDateChange={(newDate) =>
                        field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                      }
                      label={t("label.probationEndDay")}
                    />
                  )}
                />
              </Stack>
              <Controller
                name="officialWorkingDay"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate: any) =>
                      field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                    }
                    label={t("label.officialWorkingDay")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="contractTerm"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onDateChange={(newDate: any) =>
                      field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                    }
                    label={t("label.contractExpirationDate")}
                    size="sm"
                  />
                )}
              />
              <Controller
                name="insuranceCompany"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.insurancePlace")}
                    label={t("label.insurancePlace")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="sm"
                    placeholder={t("label.bankName")}
                    label={t("label.bankName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankAccountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="sm"
                    placeholder={t("label.bankAccountNumber")}
                    label={t("label.bankAccountNumber")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Controller
                name="bankAccountName"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("label.bankAccountName")}
                    label={t("label.bankAccountName")}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e)}
                    size="sm"
                  />
                )}
              />
            </Stack>
          </GridItem>
        </Grid>
        <Grid templateColumns={`repeat(${columnsDrop}, 1fr)`} gap={3}>
          <GridItem colSpan={4}>
            <Box sx={{ display: "flex" }}>
              <Text fontSize="18px" fontWeight={600}>
                {t("label.personalDocumentations")}
              </Text>
              <Box ml={2}>
                {!isEditAttachments ? (
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{
                        background: "none",
                        ":hover": { background: "none" },
                        ml: "auto",
                      }}
                      size="xs"
                      icon={<EditIcon fontSize={"20px"} />}
                      aria-label={""}
                      onClick={() => setIsEditAttachments(true)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{
                        background: "none",
                        ":hover": { background: "none" },
                        ml: "auto",
                      }}
                      size="xs"
                      icon={<CloseIcon fontSize={"14px"} />}
                      aria-label={""}
                      onClick={() => setIsEditAttachments(false)}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
            <UploadFileInput
              accept="image/*, video/*, application/pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx"
              files={userDetail?.userData?.fileAttachment || []}
              isEditing={
                userDetail?.userData?.fileAttachment ? isEditAttachments : true
              }
              sx={{ mb: 3 }}
              onFilesChange={(files: any) => onChangeFile(files)}
              maxSize={1024 * 1024 * 2} // 2MB
              clearIcon
              maxFiles={10}
            />
          </GridItem>
        </Grid>
        <Box sx={{ my: 5 }}>{_renderBottom()}</Box>
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    const role = _.find(
      userDetail?.userRole,
      (role) => role.role.roleCode === ENUMS.ROLES.ADMIN
    );

    return (
      <Box
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md" color={"#5c6e6c"}>
            {t("title.updateUser")}
          </Heading>
          {!_.isEmpty(userDetail) &&
            checkChangeStatus &&
            role?.role?.roleCode !== ENUMS?.ROLES?.ADMIN && (
              <Button
                size="sm"
                onClick={() =>
                  setIsShowPopup({ ...isShowPopup, changeStatus: true })
                }
                colorScheme="orange"
                sx={{
                  color: "#fff",
                  background: "rgba(211, 157, 135,0.9)",
                  "&:hover": {
                    background: "rgba(211, 157, 135,1)",
                  },
                }}
              >
                {t("button.changeStatus")}
              </Button>
            )}
        </Box>
        <Box>{_renderForm()}</Box>
        <UserDialog.ChangeUserStatusDialog
          onChangeStatus={onChangeStatus}
          payload={userDetail}
          open={isShowPopup.changeStatus}
          onClose={() =>
            setIsShowPopup({ ...isShowPopup, changeStatus: false })
          }
        />
        <UserDialog.ChangePasswordAdminDialog
          id={id}
          open={isShowPopup.changePassword}
          onClose={() =>
            setIsShowPopup({ ...isShowPopup, changePassword: false })
          }
        />
      </Box>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default UpdateUser;
