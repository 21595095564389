// import Alert from '../alert';
// import Notification from '../Notification';

export type ResponseValidation = {
  data: {
    status: boolean;
    message: string;
    payload: any;
  };
  isHideAlert?: boolean;
};

// RESOLVE REQUEST RESPONSE
const resolveResponse = (response: ResponseValidation) => {
  return new Promise((resolve) => {
    const { status, payload } = response.data;
    if (status) {
      if (payload) resolve(payload);
      else resolve(true);
    } else {
      resolve(false);
    }
  });
};

export { resolveResponse };
