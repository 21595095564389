import CreateTimelogDialog from "./CreateTimelog";
import UpdateTimelogDialog from "./UpdateTimelog";
import CreateTaskNoteDialog from "./CreateTaskNote";
import UpdateTaskNoteDialog from "./UpdateTaskNote";
import CreateWorkingDialog from "./CreateWorkingTime";

export default {
  CreateTimelogDialog,
  UpdateTimelogDialog,
  CreateTaskNoteDialog,
  UpdateTaskNoteDialog,
  CreateWorkingDialog,
};
