import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
import {
  // useBreakpoint,
  Stack,
  Box,
  Heading,
  Button,
  Icon,
  InputGroup,
  Avatar,
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverFooter,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import {
  chakraComponents,
  OptionBase,
  GroupBase,
  SelectComponentsConfig,
} from "chakra-react-select";

import DataTable from "@/Components/LayoutPart/DataTable";
import { TActions } from "@/Types/Common.types";
import { SearchIcon } from "@chakra-ui/icons";
import {
  FunnelIcon,
  ArrowPathIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  Autocomplete,
  DateRangePicker,
  TextField,
  Select,
  RoundedContainer,
} from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";
import Utils from "@/Utils";
import { Routers, ENUMS } from "@/Constants";
import { ProjectActions, UserActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";
import {
  IFilterProject,
  IProjectStructure,
} from "@/Interfaces/Project.interface";
import { useTranslation } from "react-multi-lang";

const { fetchProjects, deleteProject } = ProjectActions;
const { fetchUser } = UserActions;

const { PROJECT_STATUS, PROJECT_URGENCY } = ENUMS;

const DEFAULT_FILTERS: IFilterProject = {
  page: 1,
  limit: 10,
  memberId: [],
  urgency: "",
  keyword: "",
  status: "",
  type: "interior",
};

type ValuePiece = Date | null;

type Value = [ValuePiece, ValuePiece];

interface IOption extends OptionBase {
  label: string;
  value: string | string[];
  variant?: string;
  colorScheme?: string;
  extendProps?: any;
}

const ProjectListInterior: React.FC = () => {
  const dispatch = useTypedDispatch();
  // const currentBreakpoint = useBreakpoint();
  const t = useTranslation();

  const payload: any[] = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );

  const users: any[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const clients: any[] = useSelector((state: RootState) =>
    _.get(state.CLIENT, "clientList")
  );

  const pagination: IFilterProject = useSelector((state: RootState) =>
    _.get(state.PROJECT, "pagination")
  );

  const roles: any = useSelector((state: RootState) =>
    _.get(state.ROLE, "payload")
  );

  const meta = useSelector((state: RootState) => _.get(state.PROJECT, "meta"));

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isFetchLoading")
  );

  const userData = Utils.getSavedUserData();

  const sortProjects = useMemo(() => {
    if (!payload || payload.length === 0) return [];
    return [...payload].sort((a, b) => {
      if (a?.clientNew?.name < b?.clientNew?.name) return -1;
      if (a?.clientNew?.name > b?.clientNew?.name) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [payload]);

  const canGetUserList = useMemo(() => {
    let hasManagerLeaderAdmin = false;
    _.forEach(userData?.userRole, (userRole) => {
      if (
        userRole?.role?.roleCode === ENUMS.ROLES.ADMIN ||
        userRole?.role?.roleCode === ENUMS.ROLES.MANAGER ||
        userRole?.role?.roleCode === ENUMS.ROLES.LEADER
      )
        hasManagerLeaderAdmin = true;
    });
    return hasManagerLeaderAdmin;
  }, [userData]);

  const canCreateProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "createProject"
  );

  const fieldGroupSize = useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "275px",
      xl: "275px",
      "2xl": "275px",
    };
  }, []);

  const userOptions = useMemo(() => {
    const allowedRoles = [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
    ];
    return _.map(users, (user) => {
      const hasAllowedRole = _.some(user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      if (hasAllowedRole)
        return {
          value: user.id,
          label: user?.userData?.fullName,
          extendProps: {
            email: user?.email,
            avatar: user?.userData?.avatar?.path,
          },
        };
      return null;
    }).filter(Boolean);
  }, [users]);

  const roleCodeOptions = useMemo(() => {
    return _.map(roles, (role) => {
      if (
        role?.roleCode !== ENUMS.ROLES.HUMAN_RESOURCES &&
        role?.roleCode !== ENUMS.ROLES.MARKETING
      )
        return {
          value: role?.roleCode,
          label: `${
            role?.roleCode === ENUMS.ROLES.ARTIST
              ? "Staff"
              : _.startCase(role?.name)
          } `,
        };
      return null;
    }).filter(Boolean);
  }, [roles]);

  const clientOptions = useMemo(
    () =>
      _.map(clients, (client) => ({ label: client?.name, value: client?.id })),
    [clients]
  );

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    edit: false,
    delete: false,
    filter: false,
    filterExterior: false,
  });
  const [dateRange, setDateRange] = useState<Value>([null, null]);
  const [filters, setFilters] = useState<IFilterProject>(DEFAULT_FILTERS);
  const [filtersRoleCode, setFiltersRoleCode] = useState<any[]>([]);

  const [isClearFilters, setIsClearFilters] = useState(false);

  const [selectedItem, setSelectedItem] = useState<IProjectStructure | null>(
    null
  );

  const handleShowPopup = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const handleFiltersChange = (key: string, value: string | string[]) => {
    setFilters({ ...filters, [key]: value });
  };

  const onRowActionChange = (action: TActions, item: any) => {
    setSelectedItem(item);
    setIsShowPopup({ ...isShowPopup, [action]: true });
  };

  const onPageChange = (value: number) => {
    const newFilters = {
      ...filters,
      page: value,
      type: ENUMS.PROJECT_TYPE.INTERIOR,
    };
    setFilters(newFilters);
    dispatch(fetchProjects(newFilters));
  };

  const onPageLimit = (limit: number) => {
    const newFilters = {
      ...filters,
      page: 1,
      limit,
      type: ENUMS.PROJECT_TYPE.INTERIOR,
    };
    setFilters(newFilters);
    dispatch(fetchProjects(newFilters));
  };

  const handleReset = () => {
    setIsClearFilters(true);
    handleShowPopup("filter", false);
    setFilters(DEFAULT_FILTERS);
    setDateRange([null, null]);
    dispatch(fetchProjects(DEFAULT_FILTERS));
    if (canGetUserList) {
      setFiltersRoleCode([]);
      dispatch(
        fetchUser({ page: 0, limit: 0, status: ENUMS.USER_STATUS.ACTIVE })
      );
    }
  };

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);

    if (_.isArray(fieldNames)) setDateRange([null, null]);
    if (fieldNames === "memberId") setIsClearFilters(true);

    dispatch(fetchProjects(updatedPagination));
  };

  const handleSubmit = () => {
    setIsClearFilters(false);
    handleShowPopup("filter", false);
    const resolvedFilters = Utils.removeEmptyFields({
      ...filters,
      keyword: _.trim(filters.keyword),
    });
    if (
      !_.isEmpty(dateRange) &&
      dayjs(_.first(dateRange)).isValid() &&
      dayjs(_.last(dateRange)).isValid()
    ) {
      _.assign(resolvedFilters, {
        startDate: dayjs(_.first(dateRange)).format("YYYY-MM-DD"),
        endDate: dayjs(_.last(dateRange)).format("YYYY-MM-DD"),
      });
    }
    if (resolvedFilters)
      dispatch(fetchProjects(resolvedFilters as IFilterProject));
  };

  const customComponents: SelectComponentsConfig<
    IOption,
    true,
    GroupBase<IOption>
  > = {
    Option: ({ children, ...props }) => {
      return (
        <chakraComponents.Option {...props}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar size="xs" src={props?.data?.extendProps?.avatar} />
            <Box>
              {children}
              <Text
                fontSize="sm"
                sx={{
                  maxW: "250px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden !important",
                }}
              >
                {props?.data?.extendProps?.email}
              </Text>
            </Box>
          </Box>
        </chakraComponents.Option>
      );
    },
    MultiValueContainer: ({ children, ...props }) => (
      <chakraComponents.MultiValueContainer {...props}>
        {children}
      </chakraComponents.MultiValueContainer>
    ),
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        h="max-content"
        variant="solid"
        key={label}
        textTransform={field !== "keyword" ? "capitalize" : "inherit"}
      >
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const getMemberLabel = (memberId: string) => {
    const userOption = _.find(
      userOptions,
      (option) => option?.value === memberId
    );
    return userOption?.label || memberId;
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (!pagination) return;

    if (pagination.keyword) {
      renderedTags.push(renderTag(pagination.keyword, "keyword"));
    }

    if (pagination.status) {
      renderedTags.push(renderTag(t(`status.${pagination.status}`), "status"));
    }

    if (pagination.clientId) {
      renderedTags.push(
        renderTag(
          _.get(
            _.find(
              clientOptions,
              (client) => client?.value === pagination.clientId
            ),
            "label"
          ),
          "clientId"
        )
      );
    }

    if (pagination.urgency) {
      renderedTags.push(renderTag(t(`label.${pagination.urgency}`), "urgency"));
    }

    if (pagination.startDate) {
      renderedTags.push(
        renderTag(
          `${dayjs(pagination.startDate).format("DD/MM/YYYY")} - ${dayjs(
            pagination.endDate
          ).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    if (!_.isEmpty(pagination.memberId)) {
      const memberLabels = _.map(pagination.memberId, getMemberLabel);
      const memberString = memberLabels.join(", ");
      renderedTags.push(renderTag(memberString, "memberId"));
    }

    return (
      <HStack spacing={4} mt={2}>
        <HStack spacing={1} alignItems="flex-start">
          <Text
            fontSize="sm"
            fontWeight={500}
            sx={{
              minW: "max-content",
            }}
            color={"#5C6e6c"}
          >
            {t("label.filterBy")}:
          </Text>
          <HStack spacing={1} flexWrap="wrap">
            {renderedTags}
          </HStack>
        </HStack>
      </HStack>
    );
  };

  const _renderFilterOptions = () => (
    <Popover isOpen={isShowPopup.filter} placement="auto-start">
      <PopoverTrigger>
        <Button
          size="sm"
          onClick={() => {
            handleShowPopup("filter", true);
          }}
          //  colorScheme="whatsapp"
          leftIcon={<Icon as={FunnelIcon} />}
          sx={{
            color: "#fff",
            background: "#D2A96A",
            "&:hover": {
              background: "#D2BD6A",
            },
          }}
        >
          {t("button.filter")}
        </Button>
      </PopoverTrigger>
      <Box
        sx={{
          "& .css-1qq679y": {
            zIndex: isShowPopup.filter ? 10 : -1,
          },
        }}
      >
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader> {t("label.filterOptions")}</PopoverHeader>
          <PopoverBody
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {canGetUserList && (
              <InputGroup size="sm">
                <Autocomplete
                  value={filtersRoleCode}
                  label={t("label.roles")}
                  isClear={isClearFilters}
                  isMulti
                  onChange={(newValue: string[]) => {
                    setFiltersRoleCode(newValue);
                    dispatch(
                      fetchUser({
                        page: 0,
                        limit: 0,
                        status: ENUMS.USER_STATUS.ACTIVE,
                        roleCode: newValue,
                      })
                    );
                  }}
                  options={roleCodeOptions}
                  //customComponents={customComponents}
                  placeholder={t("label.select")}
                />
              </InputGroup>
            )}
            {canGetUserList && (
              <InputGroup size="sm">
                <Autocomplete
                  value={filters.memberId}
                  label={t("label.users")}
                  isClear={isClearFilters}
                  isMulti
                  onChange={(newValue) =>
                    handleFiltersChange("memberId", newValue)
                  }
                  options={userOptions}
                  customComponents={customComponents}
                  placeholder={t("label.select")}
                />
              </InputGroup>
            )}

            <InputGroup size="sm">
              <Select
                label={t("label.client")}
                placeholder={t("label.allClient")}
                value={filters.clientId}
                onChange={(e) =>
                  handleFiltersChange("clientId", e.target.value)
                }
                options={clientOptions}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Select
                label={t("label.urgency")}
                placeholder={t("label.allUrgency")}
                value={filters.urgency}
                onChange={(e) => handleFiltersChange("urgency", e.target.value)}
                options={_.map(_.values(PROJECT_URGENCY), (urgency) => ({
                  label: _.startCase(t(`label.${urgency}`)),
                  value: urgency,
                }))}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Select
                label={t("label.status")}
                placeholder={t("label.allStatus")}
                value={filters.status}
                onChange={(e) => handleFiltersChange("status", e.target.value)}
                options={_.map(_.values(PROJECT_STATUS), (status) => ({
                  label: _.startCase(t(`status.${status}`)),
                  value: status,
                }))}
              />
            </InputGroup>
            <Box>
              <DateRangePicker
                label={t("label.timeline")}
                value={dateRange}
                onDateChange={(newDate) => setDateRange(newDate)}
              />
            </Box>
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent="space-between">
              <Button
                size="sm"
                onClick={() => handleShowPopup("filter", false)}
                variant="ghost"
                colorScheme="red"
              >
                {t("button.close")}
              </Button>
              <HStack spacing={3}>
                <Button
                  size="sm"
                  //  colorScheme="orange"
                  onClick={handleReset}
                  leftIcon={<Icon as={ArrowPathIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                >
                  {t("button.reset")}
                </Button>
                <Button
                  size="sm"
                  //  colorScheme="whatsapp"
                  onClick={handleSubmit}
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                >
                  {t("button.filter")}
                </Button>
              </HStack>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Box>
    </Popover>
  );

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          background: "white",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <Box sx={{ maxW: fieldGroupSize }}>
            <TextField
              placeholder={t("label.search")}
              size={"sm"}
              value={filters.keyword}
              onChange={(e) => handleFiltersChange("keyword", e.target.value)}
              onEnter={() => filters.keyword && handleSubmit()}
              rightElement={
                <Tooltip label={t("label.search")} hasArrow>
                  <Icon
                    as={SearchIcon}
                    boxSize={5}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      dispatch(
                        fetchProjects({
                          ...pagination,
                          keyword: _.trim(filters.keyword),
                        })
                      )
                    }
                  />
                </Tooltip>
              }
            />
          </Box>
          <Box>{_renderFilterOptions()}</Box>
        </Box>
        {_renderTags()}
      </Box>
    );
  };

  const _renderBottomSection = () => {
    return (
      <DataTable.ProjectDataTable
        payload={sortProjects}
        paginate={meta}
        onRowAction={onRowActionChange}
        onPageChange={onPageChange}
        onChangeLimit={onPageLimit}
        isLoading={isFetchLoading}
      />
    );
  };

  const _renderPopup = () => (
    <>
      <ConfirmDialog
        isOpen={isShowPopup.delete}
        onClose={() => handleShowPopup("delete", false)}
        body={`${t("message.thisWillDeleteThisProject")}: ${
          selectedItem?.name
        }`}
        onAction={() => {
          if (selectedItem)
            dispatch(deleteProject(selectedItem.id, pagination));
          handleShowPopup("delete", false);
        }}
        actionType="delete"
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.internalProjects")}
          </Heading>
          {canCreateProject && (
            <Button
              size="sm"
              //  colorScheme="twitter"
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                //  dispatch(performAction("logInternal", ""));
                Utils.redirect(Routers.CREATE_PROJECT);
              }}
            >
              {t("button.create")}
            </Button>
          )}
        </Box>
        <RoundedContainer
          sx={{
            width: "100%",
          }}
        >
          {_renderTopSection()}
          {_renderBottomSection()}
        </RoundedContainer>
        {_renderPopup()}
      </Stack>
    );
  };

  return renderMain();
};

export default ProjectListInterior;
