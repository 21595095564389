import React from "react";
import _ from "lodash";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import Column from "./Column";
import { Grid, GridItem, Skeleton } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";

interface ISectionProps {
  columns: any[];
}

const List: React.FC<ISectionProps> = ({ columns }) => {
  const gridStyles = {
    display: "flex",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "1.25rem",
    mb: 2,
  };

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.BOARD, "isFetchLoading")
  );

  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );

  return (
    <SortableContext
      items={columns}
      disabled
      strategy={horizontalListSortingStrategy}
    >
      <Grid sx={gridStyles}>
        {_.map(columns, (column, index) => (
          <GridItem key={index} sx={{ width: "full", minWidth: 250 }}>
            {isFetchLoading || isActionLoading ? (
              <Skeleton w="full" height={`calc(100vh - 200px)`} />
            ) : (
              <Column column={column} />
            )}
          </GridItem>
        ))}
      </Grid>
    </SortableContext>
  );
};

export default List;
