import React from "react";
import _ from "lodash";
import styled from "@emotion/styled";

import { type IPaginate } from "@/Types/Common.types";

import Pagination from "./Pagination";

import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr as ChakraTr,
  Th,
  Td as ChakraTd,
  TableCaption,
  TableContainer,
  type TableProps,
  Stack,
  Text,
  Checkbox,
  Skeleton,
  Center,
} from "@chakra-ui/react";
import CommonColors from "@/Themes/CommonColors";

interface IColumn {
  label: string;
  key: string;
  textAlign: "left" | "center" | "right";
}

type IPayload = Record<string, string | number | JSX.Element>;

interface IProps extends TableProps {
  caption?: string;
  payload: IPayload[];
  columns: IColumn[];
  paginate?: IPaginate;
  onPageChange?: (page: number) => void;
  showTotal?: boolean;
  sxRowsTable?: object;
  onRowsTableActive?(rowIdx?: number): void;
  isRowsActive?: boolean;
  isLoading?: boolean;
  tableSize?: "xs" | "sm" | "md";
  onCheckAll?: (e: any) => void;
}

const Table = styled(ChakraTable)`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled(ChakraTr)``;

const Td = styled(ChakraTd)`
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 13px;
  font-weight: 400;
`;

const TableComponent: React.FC<IProps> = ({
  payload = [],
  columns = [],
  caption = "",
  paginate,
  onPageChange,
  showTotal = false,
  sxRowsTable,
  isRowsActive = false,
  onRowsTableActive,
  isLoading = false,
  tableSize,
  onCheckAll,
  ...props
}) => {
  const [rowActive, setRowActive] = React.useState<number>(-1);

  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (_.isEmpty(payload)) {
      setIsChecked(false);
    }
  }, [payload]);

  const THead = React.useMemo(() => {
    const th: JSX.Element[] = [];
    columns.forEach((column: IColumn) =>
      th.push(
        <Th
          textAlign={column.textAlign}
          sx={{
            textTransform: "uppercase",
            color: "#fff",
            // fontFamily: "Montserrat",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: 1,
            width: `${column.key === "no" ? "75px" : "auto"}`,
            border: "1px solid #ddd",
            padding: "8px",
          }}
          key={`head-${column.key}`}
        >
          {onCheckAll && column?.key === "no" ? (
            <Checkbox
              size="lg"
              borderRadius="20px"
              // isChecked={_.some(listCheckRows, (row) => row === id)}
              isChecked={isChecked}
              onChange={(e: any) => {
                onCheckAll(e.target.checked);
                setIsChecked(e.target.checked);
              }}
              borderColor="lightblue"
            />
          ) : (
            column?.label
          )}
        </Th>
      )
    );
    return (
      <Thead bg={CommonColors.mainColor}>
        <Tr>{th}</Tr>
      </Thead>
    );
  }, [columns, onCheckAll, isChecked]);

  const TBody = React.useMemo(() => {
    const rows: JSX.Element[] = [];
    payload.forEach((item: IPayload, rowIdx: number) => {
      const td: JSX.Element[] = [];
      columns.forEach((column: IColumn, cellIdx: number) => {
        const currentVal = item[column.key];
        const key = `cell-${rowIdx}-${cellIdx}`;
        if (currentVal)
          td.push(
            <Td
              key={key}
              textAlign={column.textAlign}
              sx={{
                // fontFamily: "Montserrat",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              {currentVal}
            </Td>
          );
        else
          td.push(
            <Td textAlign={column.textAlign} key={key}>
              --
            </Td>
          );
      });
      rows.push(
        <Tr
          key={`row-${rowIdx}`}
          sx={{
            ...sxRowsTable,
            transition: "all .3s ease-in-out",
            bgColor:
              isRowsActive && rowIdx === rowActive ? "gray.200" : "inherit",
          }}
          _hover={{
            bgColor: "gray.100",
            cursor: isRowsActive ? "pointer" : "default",
          }}
          onClick={() => {
            setRowActive(rowIdx);
            onRowsTableActive && onRowsTableActive(rowIdx);
          }}
        >
          {td}
        </Tr>
      );
    });
    if (isLoading)
      return (
        <Tbody>
          {_.times(10, (index) => (
            <Tr key={index}>
              {columns.map((column) => (
                <Td key={column.key} textAlign={column.textAlign}>
                  <Center>
                    <Skeleton height="20px" width="100%" />
                  </Center>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      );
    else {
      if (_.isEmpty(rows) && !isLoading)
        return (
          <Tr>
            <Td colSpan={_.size(columns)}>
              <Text sx={{ p: 0, fontSize: 14, fontWeight: 400 }}>
                No data were found!
              </Text>
            </Td>
          </Tr>
        );

      return <Tbody>{rows}</Tbody>;
    }
  }, [payload, rowActive]);

  return (
    <Stack>
      <TableContainer>
        <Table
          size={tableSize ? tableSize : { base: "sm", lg: "sm", "2xl": "md" }}
          {...props}
        >
          {caption ? (
            <TableCaption placement="top" marginTop={0} fontSize={14}>
              {caption}
            </TableCaption>
          ) : null}
          {THead}
          {TBody}
          {showTotal && paginate != null ? (
            <Tfoot>
              <Tr>
                <Th textAlign="right" colSpan={columns.length}>
                  Totals: {paginate?.totalItems}
                </Th>
              </Tr>
            </Tfoot>
          ) : null}
        </Table>
      </TableContainer>
      {paginate != null ? (
        <Pagination payload={paginate} onPageChange={onPageChange} />
      ) : null}
    </Stack>
  );
};

export default TableComponent;
