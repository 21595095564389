import { DayOffRequestActions } from "@/Actions";
import {
  DateRangePicker,
  RoundedContainer,
  TextField,
} from "@/Components/Common";
import DataTable from "@/Components/LayoutPart/DataTable";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";
import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Tooltip,
  Text,
  Button,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

const { fetchAggregateDayOffRequest } = DayOffRequestActions;

interface SectionProps {
  action?: string;
}

const DEFAULT_FILTERS: IFilterAggregate = {
  page: 0,
  limit: 0,
  keyword: "",
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
};

const AggregateDayOff: React.FC<SectionProps> = ({ action }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [isShowPopup, setIsShowPopup] = useState({
    filter: false,
  });
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [dateFilter, setDateFilter] = useState<any>();

  const aggregateList: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "aggregateList")
  );

  const metaAggregate: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "metaAggregate")
  );
  const paginationAgregate: IFilterAggregate = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "paginationAgregate")
  );
  const isFetchLoading: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "isFetchLoading")
  );

  const filterDayoff = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "DayOffRequest",
    "fetchAggregate"
  );

  //  const fieldGroupSize = useMemo(() => {
  //    return {
  //      base: "100%",
  //      sm: "100%",
  //      md: "100%",
  //      lg: "275px",
  //      xl: "275px",
  //      "2xl": "275px",
  //    };
  //  }, []);

  // const transformData = (aggregateList: any[]) => {
  //   const data: any[] = [];
  //   _.forEach(aggregateList, (item) => {
  //     let total: number = 0;
  //     _.forEach(item.dayOffRequest, (entry) => {
  //       total += _.toNumber(entry.duration);
  //     });
  //     const employeeData: any = {
  //       fullName: item?.userData.fullName,
  //       userRole: item?.userRole,
  //       staffCode: item?.staffCode,
  //       id: item?.id,
  //       total: total,
  //     };
  //     data.push(employeeData);
  //   });
  //   return data;
  // };

  const startDate = dayjs(_.first(dateFilter));
  const endDate = dayjs(_.last(dateFilter));

  const createRange = (startDate: string | Dayjs, endDate: string | Dayjs) => {
    const range: string[] = [];
    let startDateFormatted = dayjs(startDate).format("YYYY-MM-DD");
    let currentDate = dayjs(startDateFormatted);
    let endDateFormatted = dayjs(endDate).format("YYYY-MM-DD");
    const lastDate = dayjs(endDateFormatted);

    while (
      currentDate.isSame(lastDate, "day") ||
      currentDate.isBefore(lastDate, "day")
    ) {
      range.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return range;
  };

  const transformData = (aggregateList: any[]) => {
    const data: any[] = [];
    const dateRange = createRange(startDate, endDate);
    aggregateList.forEach((item) => {
      let total: number = 0;
      let hasWarning: boolean = true; // Initializing warning flag
      const isWithinRange = _.every(item.dayOffRequest, (entry) => {
        const entryStartDate = entry.startDate;
        const entryEndDate = entry.endDate;
        return (
          _.includes(dateRange, entryStartDate) &&
          _.includes(dateRange, entryEndDate)
        );
      });
      if (isWithinRange) {
        hasWarning = false;
      }

      _.forEach(item.dayOffRequest, (entry) => {
        total += Number(entry.duration);
      });

      const employeeData: any = {
        fullName: item?.userData.fullName,
        userRole: item?.userRole,
        staffCode: item?.staffCode,
        id: item?.id,
        total: total,
        warning: hasWarning, // Adding warning flag to the data
      };

      data.push(employeeData);
    });

    return data;
  };

  const transformedData = useMemo(() => {
    return transformData(aggregateList);
  }, [aggregateList, startDate, endDate]);

  const summary = useMemo(() => {
    let sum: any = 0;
    _.forEach(transformedData, (item: any) => (sum += _.toNumber(item.total)));
    return sum;
  }, [transformedData]);

  //  const onPageChange = (page: number) => {
  //    dispatch(fetchAggregateDayOffRequest({ page: page, limit: 10 }));
  //  };

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...paginationAgregate };
    const startDate = dayjs(dayjs().startOf("month")).format("YYYY-MM-DD");
    const endDate = dayjs(dayjs().endOf("month")).format("YYYY-MM-DD");
    _.forEach(resetFields, (fieldName) => {
      if (fieldName === "startDate") {
        updatedPagination.startDate = startDate;
      } else if (fieldName === "endDate") {
        updatedPagination.endDate = endDate;
      } else {
        updatedPagination[fieldName] = _.isArray(updatedPagination[fieldName])
          ? []
          : "";
      }
    });
    setFilters(updatedPagination);
    setDateFilter([startDate, endDate]);
    dispatch(fetchAggregateDayOffRequest(updatedPagination));
  };

  const handleSubmit = () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters)
      dispatch(
        fetchAggregateDayOffRequest(resolvedFilters as IFilterAggregate)
      );
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchAggregateDayOffRequest(DEFAULT_FILTERS));
    setIsShowPopup({ ...isShowPopup, filter: false });
    const currentDate = dayjs();
    const startDate = currentDate.startOf("month");
    const endDate = dayjs().endOf("month");
    setDateFilter([startDate, endDate]);
  };

  const handleFiltersChange = (
    key: string,
    value: string | string[] | Date[]
  ) => {
    setFilters({ ...filters, [key]: value });
  };

  useEffect(() => {
    if (action === "aggregate")
      dispatch(
        fetchAggregateDayOffRequest({
          page: 0,
          limit: 0,
          startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
          endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
        })
      );
    const currentDate = dayjs();
    const startDate = currentDate.startOf("month");
    const endDate = dayjs().endOf("month");
    setDateFilter([startDate, endDate]);
  }, [action]);

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag size="md" borderRadius="full" variant="solid" key={label}>
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (paginationAgregate?.keyword) {
      renderedTags.push(renderTag(paginationAgregate?.keyword, "keyword"));
    }

    if (
      paginationAgregate.startDate &&
      !dayjs(dayjs().startOf("month").format("YYYY-MM-DD")).isSame(
        dayjs(paginationAgregate.startDate).format("YYYY-MM-DD")
      )
    ) {
      renderedTags.push(
        renderTag(
          `${dayjs(paginationAgregate.startDate).format(
            "DD/MM/YYYY"
          )} - ${dayjs(paginationAgregate.endDate).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HStack>
          <HStack spacing={4}>
            <HStack spacing={1}>
              <Text fontWeight={500} color={"#5C6e6c"}>
                {t("label.filterBy")}:
              </Text>
              <HStack spacing={1}>{renderedTags}</HStack>
            </HStack>
          </HStack>
        </HStack>
      </Box>
    );
  };

  const _renderTopSection = () => {
    const [isMobile] = useMediaQuery("(max-width: 495px)");
    return (
      <Box gap={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
            }}
          >
            {filterDayoff && (
              <Box
                sx={{
                  w: isMobile ? "full" : 275,
                }}
              >
                <TextField
                  placeholder={t("label.search")}
                  size="sm"
                  value={filters.keyword}
                  onChange={(e: any) =>
                    handleFiltersChange("keyword", e.target.value)
                  }
                  onKeyDown={(e) => {
                    const element = e.currentTarget as HTMLInputElement;
                    const value = element.value;
                    if (e.key === "Enter")
                      dispatch(
                        fetchAggregateDayOffRequest({
                          ...paginationAgregate,
                          keyword: _.trim(value),
                        })
                      );
                  }}
                  rightElement={
                    <Tooltip label={t("label.search")} hasArrow>
                      <Icon
                        as={SearchIcon}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          dispatch(
                            fetchAggregateDayOffRequest({
                              ...paginationAgregate,
                              keyword: _.trim(filters.keyword),
                            })
                          )
                        }
                      />
                    </Tooltip>
                  }
                />
              </Box>
            )}
            <Box
              sx={{
                w: isMobile ? "full" : 275,
              }}
            >
              <DateRangePicker
                onDateChange={(newDate: any) => {
                  setFilters({
                    ...filters,
                    startDate: dayjs(newDate[0]).format("YYYY-MM-DD"),
                    endDate: dayjs(newDate[1]).format("YYYY-MM-DD"),
                  });
                  setDateFilter(newDate);
                }}
                value={dateFilter}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  //  colorScheme="whatsapp"
                  leftIcon={<Icon as={FunnelIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#D2A96A",
                    "&:hover": {
                      background: "#D2BD6A",
                    },
                  }}
                >
                  {t("button.filter")}
                </Button>
              </Box>
              <Box>
                <Button
                  size="sm"
                  onClick={handleReset}
                  leftIcon={<Icon as={ArrowPathIcon} />}
                  sx={{
                    color: "#fff",
                    background: "#bb7154",
                    "&:hover": {
                      background: "#Db9d97",
                    },
                  }}
                >
                  {t("button.reset")}
                </Button>
              </Box>
            </Box>
          </Box>
          {filterDayoff && (
            <Box
              sx={{
                display: "flex",
                mr: 2,
                color: "#bb7154",
                mt: isMobile ? 1 : 0,
              }}
            >
              <Text sx={{ fontWeight: 600 }} size={"sm"}>
                {t("title.total")}:
              </Text>
              <Text sx={{ fontWeight: 600, ml: 1, mt: 0 }}>
                {summary} {t("title.days")}
              </Text>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box mt={4}>{_renderTags()}</Box>
        </Box>
      </Box>
    );
  };

  const _renderTable = () => {
    return (
      <DataTable.AggregateOvertimeDataTableDayOff
        type="days"
        payload={transformedData}
        paginate={metaAggregate}
        isLoading={isFetchLoading}
        //  onPageChange={onPageChange}
      />
    );
  };

  const renderMain = () => {
    return (
      <RoundedContainer>
        {_renderTopSection()}
        {_renderTable()}
      </RoundedContainer>
    );
  };

  return <Box>{renderMain()}</Box>;
};

export default AggregateDayOff;
