import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Textarea,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Checkbox,
} from "@chakra-ui/react";

import {
  LogtimeActions,
  WorkingTimeActions,
  BoardActions,
  HolidayActions,
} from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { ENUMS, Routers } from "@/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DatePicker,
  TimePicker,
  TextField,
  Select,
  DateTimeRangePicker,
} from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import Utils from "@/Utils";
import { ITaskStructure } from "@/Interfaces/Board.interface";
import TextEditor from "@/Components/Common/TextEditor";
import TextareaComponent from "@/Components/Common/Textarea";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";
import { IWorkingDayStructure } from "@/Interfaces/Workingday.interface";
import { useWorkingDay } from "@/Helpers";

const { createLogtime, createLogtimeInMyTime } = LogtimeActions;
const { fetchWorkingTime } = WorkingTimeActions;
const { getBoardById } = BoardActions;
const { fetchHoliday } = HolidayActions;
//const { fetchProjectsExteriorTimeLog } = ProjectExteriorActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_WORK_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME,
  timeline: ["", ""],
  workingTime: 0,
  date: "",
  description: "",
  user: "",
  note: "",
};

const DEFAULT_OVER_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME,
  workingTime: 0,
  startTime: "",
  endTime: "",
  date: new Date(),
  dateType: ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY,
  description: "",
  user: "",
  note: "",
};

const dateTypeOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeWeekendOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsSunday = [
  { label: "Sunday", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsWeekend = [
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeWeekendOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsSundayVI = [
  { label: "Ngày chủ nhật", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsWeekendVI = [
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const CreateTimelogDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();
  const language = Utils.getSavedLanguage();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );

  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const workingTimeOffice = useMemo(() => {
    const firstItem = workingTimeList[0];
    return {
      morningStart: Number(firstItem?.morningStart || 8),
      morningEnd: Number(firstItem?.morningEnd || 12),
      afternoonStart: Number(firstItem?.afternoonStart || 13.5),
      afternoonEnd: Number(firstItem?.afternoonEnd || 17.5),
    };
  }, [workingTimeList]);

  const ishasArtistRole = useMemo(
    () =>
      _.includes(userRoles, ENUMS.ROLES.ARTIST) &&
      !_.includes(userRoles, ENUMS.ROLES.LEADER) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER),
    [userData]
  );

  const [dateTypeNext, setDateTypeNext] = useState<string>(
    ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
  );
  const [message, setMessage] = useState<string>("");
  const [validTimeRangeMsg, setValidTimeRangeMsg] = useState<string>("");
  const [messageDateType, setMessageDateType] = useState<string>("");
  useState<string>("");

  const workTimeSchema = yup
    .object()
    .shape({
      project: yup.string().trim().required(t("message.projectIsRequired")),
      task: yup.string().required(t("message.processIsRequired")),
      date: yup.string().notRequired(),
      timeline: yup
        .array()
        .of(yup.string().trim().required(t("message.timelineIsRequired")))
        .test(
          t("message.timeline-valid"),
          t("message.startTimeMustBeBeforeEndTime"),
          function (value) {
            const [startTime, endTime] = value as [string, string];

            if (!startTime || !endTime) {
              return true; // Allow null values, as they are handled by the individual date validations
            }

            // Check if start and end dates are the same
            const sameDate = dayjs(startTime).isSame(dayjs(endTime), "day");

            // If the dates are the same and the start time is 12:00 PM or later, return false (invalid)

            if (sameDate) {
              const formatStartTime = dayjs(startTime).format("HH");
              const formatEndTime = dayjs(endTime).format("HH");
              if (
                _.toNumber(formatEndTime) >= 12 &&
                _.toNumber(formatStartTime) === 12
              )
                return false;
            }

            // Otherwise, compare UTC Date objects
            return dayjs(startTime).isBefore(dayjs(endTime));
          }
        ),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeaValidNumber")),
      description: yup.string().notRequired(),
      note: yup.string().notRequired(),
      type: yup.string().notRequired(),
      user: yup.string().required(t("message.assigneeIsRequired")),
    })
    .required();

  const overTimeSchema = yup
    .object()
    .shape({
      project: yup.string().trim().required(t("message.projectIsRequired")),
      task: yup.string().required(t("message.processIsRequired")),
      date: yup.date().required(t("message.dateIsRequired")),
      timeType: yup.string().trim().required(t("message.timeTypeIsRequired")),
      dateType: yup.string().trim().required(t("message.dateTypeIsRequired")),
      startTime: yup.string().trim().notRequired(),
      endTime: yup.string().trim().notRequired(),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeAvalidNumber"))
        .max(24, t("message.workedHoursCannotExceed")),
      description: yup.string().notRequired(),
      type: yup.string().notRequired(),
      note: yup.string().notRequired(),
      user: yup.string().required(t("message.assigneeIsRequired")),
    })
    .required();

  type IWorkTime = yup.InferType<typeof workTimeSchema>;
  //  type IOverTime = yup.InferType<typeof overTimeSchema>;

  const {
    control: workTimeControl,
    handleSubmit: handleWorkTimeSubmit,
    formState: { errors: workTimeErrors },
    setValue: setWorkTimeValue,
    getValues: getWorkTimeValues,
    reset: resetWorkTime,
    watch: watchWorkTime,
  } = useForm({
    resolver: yupResolver(workTimeSchema),
    defaultValues: DEFAULT_WORK_TIME,
  });

  const {
    control: overTimeControl,
    handleSubmit: handleOverTimeSubmit,
    formState: { errors: overTimeErrors },
    setValue: setOverTimeValue,
    getValues: getOverTimeValues,
    reset: resetOverTime,
    clearErrors: clearOverTimeErrors,
    watch: watchOverTime,
  } = useForm({
    resolver: yupResolver(overTimeSchema),
    defaultValues: DEFAULT_OVER_TIME,
  });

  const watchOverStartTime: any = watchOverTime("startTime");
  const watchOverEndTime: any = watchOverTime("endTime");
  const watchWorkTimeProject = watchWorkTime("project") || "";
  const watchOverTimeProject = watchOverTime("project") || "";
  const watchWorkTimeWorkingTime = watchWorkTime("workingTime");
  const watchOverTimeWorkingTime = watchOverTime("workingTime");

  const watchDate = watchOverTime("date") || "";

  const dispatch = useTypedDispatch();
  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const projects: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );

  const isCreateLogSuccess: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );

  const projectsExterior: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "payload")
  );

  const performAction: ITimeLogPerformAction = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );
  const myTimePagination = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "myTimePagination")
  );
  const workingDays: IWorkingDayStructure = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );

  const projectType = useMemo(
    () =>
      performAction?.actionType === "logInternal"
        ? ENUMS.PROJECT_TYPE.INTERIOR
        : ENUMS.PROJECT_TYPE.EXTERIOR,
    [performAction?.actionType]
  );

  const projectOptions = useMemo(
    () =>
      _.map(projects, (project) => ({
        label: `${project?.name} - ${project?.code}`,
        value: project?.id,
      })),
    [projects]
  );

  const projectExteriorOptions = useMemo(
    () =>
      _.map(projectsExterior, (project) => ({
        label: `${project?.name}-${project?.code}`,
        value: project?.id,
      })),
    [projectsExterior]
  );

  const findWorkTimeProccessOptions = useMemo(() => {
    const targetProjects =
      projectType === ENUMS.PROJECT_TYPE.EXTERIOR ? projectsExterior : projects;
    const foundProject: any = _.find(
      targetProjects,
      (project) => project.id === watchWorkTimeProject
    );

    if (foundProject) {
      const kanbanBoard = foundProject.kanbanBoard || {};
      const tasks = kanbanBoard.task || [];
      return _.map(tasks, (task: ITaskStructure) => {
        return (
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
        );
      });
    }

    return [];
  }, [watchWorkTimeProject, projects, projectsExterior, projectType]);

  const findOverTimeProccessOptions = useMemo(() => {
    const targetProjects =
      projectType === ENUMS.PROJECT_TYPE.EXTERIOR ? projectsExterior : projects;
    const foundProject: any = _.find(
      targetProjects,
      (project) => project.id === watchOverTimeProject
    );

    if (foundProject) {
      const kanbanBoard = foundProject.kanbanBoard || {};
      const tasks = kanbanBoard.task || [];

      return tasks.map(
        (task: ITaskStructure) =>
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
      );
    }

    return [];
  }, [watchOverTimeProject, projects, projectsExterior, projectType]);

  useEffect(() => {
    if (_.isEmpty(workingTimeList)) dispatch(fetchWorkingTime());
  }, []);

  useEffect(() => {
    if (boardDetails?.id && isCreateLogSuccess) {
      dispatch(getBoardById(boardDetails?.id));
      onReset();
    }
  }, [boardDetails, isCreateLogSuccess]);

  useEffect(() => {
    if (open) {
      const startDate = new Date();
      const endDate = new Date();
      const { morningStart, afternoonEnd } = workingTimeOffice;

      const { hour: morningStartHour, minute: morningStartMinute } =
        Utils.splitHourMinute(morningStart);
      const { hour: afternoonEndHour, minute: afternoonEndMinute } =
        Utils.splitHourMinute(afternoonEnd);

      startDate.setHours(morningStartHour);
      startDate.setMinutes(morningStartMinute);
      startDate.setSeconds(0);

      endDate.setHours(afternoonEndHour);
      endDate.setMinutes(afternoonEndMinute);
      endDate.setSeconds(0);
      setWorkTimeValue("timeline", [
        startDate.toISOString(),
        endDate.toISOString(),
      ]);

      setWorkTimeValue(
        "workingTime",
        Utils.calculateHourDifference(
          startDate.toISOString(),
          endDate.toISOString(),
          workingTimeOffice,
          holidaysList
        )
      );
    }
  }, [workingTimeOffice, open]);

  useEffect(() => {
    if (ishasArtistRole || pathname === Routers.MY_TIME) {
      setOverTimeValue("user", userData?.id);
      setWorkTimeValue("user", userData?.id);
    }
  }, [pathname, userData]);

  // useEffect(() => {
  //   if (
  //     (performAction?.actionType === "logExternal" &&
  //       ishasArtistRoleExterior) ||
  //     (performAction?.actionType === "logExternal" &&
  //       pathname === Routers.MY_TIME) ||
  //     !permissionExterior
  //   ) {
  //     setOverTimeValue("user", userData?.id);
  //     setWorkTimeValue("user", userData?.id);
  //   }
  // }, [pathname, userData, performAction, permissionExterior]);

  const userOptions = useMemo(() => {
    const hasLeaderRole =
      _.includes(userRoles, ENUMS.ROLES.LEADER) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER);
    const hasManagerRole = _.includes(userRoles, ENUMS.ROLES.MANAGER);
    const projectMembers = boardDetails?.project?.projectMember;

    const managers = _.filter(projectMembers, (projectMember) => {
      const userRoles = projectMember?.user?.userRole || [];
      return _.some(
        userRoles,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );
    });

    const leaders = _.filter(projectMembers, (projectMember) => {
      const userRoles = projectMember?.user?.userRole || [];
      return _.some(
        userRoles,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
      );
    });

    const acceptUsers = _.map(boardDetails?.project?.projectMember, (user) => {
      const allowedRoles = [
        ENUMS.ROLES.ARTIST,
        ENUMS.ROLES.LEADER,
        ENUMS.ROLES.MANAGER,
      ];
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      const userHasManagerRole = _.find(
        user?.user?.userRole,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );

      if (hasLeaderRole && !hasManagerRole && userHasManagerRole) {
        return null;
      }
      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        // return {
        //   id: user.id,
        //   avatar: user?.userData?.avatar?.path,
        //   name: user?.userData?.fullName,
        //   email: user?.email,
        //   roles: user?.userRole,
        //   user,
        // };
        return {
          label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
          value: user?.user.id,
        };
      }
      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    if (hasManagerRole) {
      const removeUser = _.filter(projectMembers, (member) => {
        return !managers.find((mnMember) => mnMember === member);
      });
      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    if (hasLeaderRole) {
      const removeUser = _.filter(projectMembers, (member) => {
        return !managers.includes(member) && !leaders.includes(member);
      });

      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    return acceptUsers;
  }, [boardDetails, userRoles, userData]);

  const userOptionsExterior = useMemo(() => {
    const hasMktRole =
      _.includes(userRoles, ENUMS.ROLES.MARKETING) &&
      (!_.includes(userRoles, ENUMS.ROLES.MANAGER) ||
        !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES));
    const hasLeaderRole =
      _.includes(userRoles, ENUMS.ROLES.LEADER) &&
      (!_.includes(userRoles, ENUMS.ROLES.MANAGER) ||
        !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES));
    const hasManagerRole =
      _.includes(userRoles, ENUMS.ROLES.MANAGER) &&
      !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES);
    const hasHrRole =
      _.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      const acceptUsers = _.map(
        boardDetails?.project?.projectMember,
        (user) => {
          return {
            label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
            value: user?.user.id,
          };
        }
      );
      acceptUsers.unshift({
        label: `${userData?.userData?.fullName} - ${userData?.email}`,
        value: userData?.id,
      });
      return acceptUsers;
    }
    const projectMembers = boardDetails?.project?.projectMember;

    if (hasManagerRole || hasHrRole) {
      const managers = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
        );
      });
      const hr = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.HUMAN_RESOURCES
        );
      });
      const removeUser = _.filter(projectMembers, (member) => {
        return (
          !managers.find((mnMember) => mnMember === member) &&
          !hr.find((hrMember) => hrMember === member)
        );
      });
      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    if (hasMktRole) {
      const artists = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        );
      });

      return [
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
        ..._.map(artists, (artist) => ({
          label: `${artist?.user?.userData?.fullName} - ${artist?.user?.email}`,
          value: artist?.user?.id,
        })),
      ];
    }

    if (hasLeaderRole) {
      const artists = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        );
      });

      const result: any[] = [];
      _.forEach(artists, (artist) => {
        result.push({
          label: `${artist?.user?.userData?.fullName} - ${artist?.user?.email}`,
          value: artist?.user?.id,
        });
      });
      if (!_.some(artists, (artist) => artist?.user?.id === userData?.id)) {
        result.unshift({
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        });
      }
      return result;
    }

    const acceptUsers = _.map(boardDetails?.project?.projectMember, (user) => {
      return {
        label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
        value: user?.user.id,
      };
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    return acceptUsers;
  }, [boardDetails, userRoles, userData]);

  useEffect(() => {
    if (watchDate) {
      if (watchDate.getDay() === 0) {
        setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKEND);
      } else setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
      const dateTypeOptions = _renderOptions();
      const currentDateType = getOverTimeValues("dateType");
      if (
        !_.some(dateTypeOptions, (option) => option.value === currentDateType)
      )
        setOverTimeValue("dateType", dateTypeOptions?.[0]?.value);
    }
  }, [watchDate]);

  const processOptions = useMemo(
    () =>
      _.map(boardDetails?.task, (task) => ({
        label: task.title,
        value: task.id,
      })),
    [boardDetails]
  );

  const [tabIndex, setTabIndex] = useState(0);
  let workTimeFormSubmitted = false;
  let overTimeFormSubmitted = false;

  const [isShowConfirm, setIsShowConfirm] = useState({
    workTime: false,
    overTime: false,
  });

  const cancelRef = useRef<any>(null);
  const workTimeSubmitButtonRef = useRef<any>(null);
  const overTimeSubmitButtonRef = useRef<any>(null);
  const submitButtonBoth = useRef<any>(null);

  const checkStartMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const checkEndMorning =
    workingTimeList[0]?.morningEnd &&
    Utils.convertToTime(workingTimeList[0]?.morningEnd);

  const checkStartAfternoon =
    workingTimeList[0]?.afternoonStart &&
    Utils.convertToTime(workingTimeList[0]?.afternoonStart);

  const checkEndAfternoon =
    workingTimeList[0]?.afternoonEnd &&
    Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

  const handleTabsChange = (newTabIndex: number) => {
    setTabIndex(newTabIndex);
    if (newTabIndex === 0) workTimeFormSubmitted = false;
    if (newTabIndex === 1) overTimeFormSubmitted = false;
  };

  useEffect(() => {
    if (performAction?.actionType === "logInternal") {
      if (performAction.id) {
        if (boardDetails?.project?.id) {
          setWorkTimeValue("project", boardDetails?.project?.id);
          setOverTimeValue("project", boardDetails?.project?.id);
        }
        setOverTimeValue("task", performAction.id);
        setWorkTimeValue("task", performAction.id);
      } else {
        if (performAction?.extraData?.projectId) {
          setWorkTimeValue("project", performAction?.extraData?.projectId);
          setOverTimeValue("project", performAction?.extraData?.projectId);
        }
        if (performAction?.extraData?.processId) {
          setOverTimeValue("task", performAction?.extraData?.processId);
          setWorkTimeValue("task", performAction?.extraData?.processId);
        }
        //if (performAction?.extraData?.userId) {
        //  setOverTimeValue("user", performAction?.extraData?.userId);
        //  setWorkTimeValue("user", performAction?.extraData?.userId);
        //}
        if (performAction?.extraData?.userId) {
          const isValidUser = _.some(
            userOptions,
            (user: any) => user.value === performAction?.extraData?.userId
          );
          if (isValidUser) {
            setWorkTimeValue("user", performAction?.extraData?.userId);
            setOverTimeValue("user", performAction?.extraData?.userId);
          } else {
            setWorkTimeValue("user", userData?.id);
            setOverTimeValue("user", userData?.id);
          }
        }
        if (performAction?.extraData?.date) {
          const startDate = new Date(performAction?.extraData?.date);
          const endDate = new Date(performAction?.extraData?.date);
          const { morningStart, afternoonEnd } = workingTimeOffice;

          const { hour: morningStartHour, minute: morningStartMinute } =
            Utils.splitHourMinute(morningStart);
          const { hour: afternoonEndHour, minute: afternoonEndMinute } =
            Utils.splitHourMinute(afternoonEnd);

          startDate.setHours(morningStartHour);
          startDate.setMinutes(morningStartMinute);
          startDate.setSeconds(0);

          endDate.setHours(afternoonEndHour);
          endDate.setMinutes(afternoonEndMinute);
          endDate.setSeconds(0);
          setWorkTimeValue("date", performAction?.extraData?.date);
          setWorkTimeValue("timeline", [
            startDate?.toISOString(),
            endDate?.toISOString(),
          ]);
          setWorkTimeValue(
            "workingTime",
            Utils.calculateHourDifference(
              startDate.toISOString(),
              endDate.toISOString(),
              workingTimeOffice,
              holidaysList
            )
          );
          // setWorkTimeValue("workingTime", 0);
          setOverTimeValue("date", new Date(performAction?.extraData?.date));
        }
        if (performAction?.extraData?.isSunday) {
          setTabIndex(1);
        }
      }

      setWorkTimeValue("type", ENUMS.LOG_TIME_TYPE.INTERNAL);
      setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.INTERNAL);
    }
    if (performAction?.actionType === "logExternal") {
      setWorkTimeValue("type", ENUMS.LOG_TIME_TYPE.EXTERNAL);
      setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.EXTERNAL);
      if (performAction?.extraData?.projectId) {
        setWorkTimeValue("project", performAction?.extraData?.projectId);
        setOverTimeValue("project", performAction?.extraData?.projectId);
      }
      if (performAction?.extraData?.processId) {
        setOverTimeValue("task", performAction?.extraData?.processId);
        setWorkTimeValue("task", performAction?.extraData?.processId);
      }
      //  if (performAction?.extraData?.userId) {
      //    setOverTimeValue("user", performAction?.extraData?.userId);
      //    setWorkTimeValue("user", performAction?.extraData?.userId);
      //  }
      if (performAction?.extraData?.date) {
        const startDate = new Date(performAction?.extraData?.date);
        const endDate = new Date(performAction?.extraData?.date);
        const { morningStart, afternoonEnd } = workingTimeOffice;
        const { hour: morningStartHour, minute: morningStartMinute } =
          Utils.splitHourMinute(morningStart);
        const { hour: afternoonEndHour, minute: afternoonEndMinute } =
          Utils.splitHourMinute(afternoonEnd);

        startDate.setHours(morningStartHour);
        startDate.setMinutes(morningStartMinute);
        startDate.setSeconds(0);

        endDate.setHours(afternoonEndHour);
        endDate.setMinutes(afternoonEndMinute);
        endDate.setSeconds(0);
        setWorkTimeValue("date", performAction?.extraData?.date);
        setWorkTimeValue("timeline", [
          startDate?.toISOString(),
          endDate?.toISOString(),
        ]);
        setWorkTimeValue(
          "workingTime",
          Utils.calculateHourDifference(
            startDate.toISOString(),
            endDate.toISOString(),
            workingTimeOffice,
            holidaysList
          )
        );
        setOverTimeValue("date", new Date(performAction?.extraData?.date));
      }

      if (performAction?.extraData?.userId) {
        const isValidUser = _.some(
          userOptionsExterior,
          (user) => user.value === performAction?.extraData?.userId
        );

        if (isValidUser) {
          setWorkTimeValue("user", performAction?.extraData?.userId);
          setOverTimeValue("user", performAction?.extraData?.userId);
        } else {
          setWorkTimeValue("user", userData?.id);
          setOverTimeValue("user", userData?.id);
        }
      }
    }
  }, [performAction, workingTimeOffice]);

  useEffect(() => {
    if (!_.isEmpty(projects)) {
    }
  }, [projects]);

  useEffect(() => {
    if (!open) {
      onReset();
      setMessageDateType("");
      setDateTypeNext(ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
      setMessage("");
    }
  }, [open]);

  useEffect(() => {
    if (watchOverStartTime || watchOverEndTime) {
      if (watchOverStartTime && watchOverEndTime) {
        const newValue = Utils.calculateWorkingTime(
          watchOverStartTime,
          watchOverEndTime
        );
        setOverTimeValue("workingTime", newValue);
        if (newValue > 0) clearOverTimeErrors();
        // handle change date type
        if (
          watchOverStartTime > watchOverEndTime &&
          watchOverEndTime !== "00:00:00"
        ) {
          const nextOptions = _renderNextOptions();
          if (!_.some(nextOptions, (option) => option.value === dateTypeNext)) {
            setDateTypeNext(nextOptions?.[0]?.value);
          }
        }
      }
    }
  }, [watchOverStartTime, watchOverEndTime]);

  const onReset = () => {
    resetWorkTime();
    resetOverTime();
    setTabIndex(0);
    setIsShowConfirm({
      workTime: false,
      overTime: false,
    });
    setValidTimeRangeMsg("");
    onClose();
  };

  const handleShowConfirm = (name: string, value: boolean) =>
    setIsShowConfirm({ ...isShowConfirm, [name]: value });

  const handleCombinedSubmit = () => {
    const isWorkTimeFormValid = _.isEmpty(workTimeErrors);
    const isOverTimeFormValid = _.isEmpty(overTimeErrors);
    if (isWorkTimeFormValid && isOverTimeFormValid) {
      if (
        getOverTimeValues("workingTime") === 0 ||
        getWorkTimeValues("workingTime") === 0
      ) {
        if (tabIndex === 0) handleShowConfirm("workTime", true);
        if (tabIndex === 1) {
          handleShowConfirm("overTime", true);
        }
      } else {
        //workTimeSubmitButtonRef.current.click();
        //overTimeSubmitButtonRef.current.click();
        submitButtonBoth.current.click();
      }
    } else {
      if (tabIndex === 0 && isWorkTimeFormValid && !workTimeFormSubmitted) {
        if (isOverTimeFormValid) workTimeSubmitButtonRef.current.click();
        else handleShowConfirm("workTime", true);
      }
      if (tabIndex === 1 && isOverTimeFormValid && !overTimeFormSubmitted) {
        if (isWorkTimeFormValid) overTimeSubmitButtonRef.current.click();
        else handleShowConfirm("overTime", true);
      }
    }
  };

  const onWorkTimeSubmit = (data: IWorkTime) => {
    const formatTime = (date: Date) => {
      let hours: any = date?.getHours();
      let minutes: any = date?.getMinutes();
      let seconds: any = date?.getSeconds();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return `${hours}:${minutes}:${seconds}`;
    };

    const { timeline } = data;
    const workTimeStartDate = dayjs(timeline?.[0]).format("YYYY-MM-DD");
    const workTimeEndDate = dayjs(timeline?.[1]).format("YYYY-MM-DD");
    const workTimeStartTime = formatTime(new Date(timeline?.[0] as any));
    const workTimeEndTime = formatTime(new Date(timeline?.[1] as any));
    // const isValid = Utils.isValidTimeRange(
    //   timeline?.[0] || "",
    //   timeline?.[1] || "",
    //   workingTimeOffice
    // );
    if (
      _.isEmpty(workTimeErrors) &&
      _.toNumber(data.workingTime) > 0 &&
      // isValid &&
      !validTimeRangeMsg
    ) {
      if (pathname === Routers.MY_TIME) {
        dispatch(
          createLogtimeInMyTime(
            {
              ..._.omit(data, ["timeline"]),
              date: workTimeStartDate,
              workTimeStartDate,
              workTimeEndDate,
              workTimeStartTime,
              workTimeEndTime,
              workingTime: watchWorkTimeWorkingTime,
            },
            myTimePagination,
            () => onClose()
          )
        );
      } else {
        dispatch(
          createLogtime({
            ..._.omit(data, ["timeline"]),
            date: workTimeStartDate,
            workTimeStartDate,
            workTimeEndDate,
            workTimeStartTime,
            workTimeEndTime,
            workingTime: watchWorkTimeWorkingTime,
          })
        );
      }

      // const maxHours = Utils.getMaxHoursWorktime(date);
      // const A_HOUR_IN_DAY = 8;
      // let endDate: any = date;
      // if (workingTime > maxHours) {
      //   // when Sunday is the first day of the week
      //   const currentDate = dayjs(date);
      //   let firstDayOfNextWeek;
      //   if (currentDate.day() === 0) {
      //     firstDayOfNextWeek = currentDate.add(1, "week").startOf("week");
      //   } else {
      //     firstDayOfNextWeek = currentDate
      //       .add(1, "week")
      //       .startOf("week")
      //       .add(1, "day");
      //   }
      //   const remainingHours =
      //     workingTime - maxHours < 8 ? 8 : workingTime - maxHours;
      //   const remainingDays = Math.ceil(remainingHours / A_HOUR_IN_DAY);
      //   endDate = firstDayOfNextWeek
      //     .add(remainingDays, "day")
      //     .format("YYYY-MM-DD");
      // }
      // dispatch(
      //   createLogtime({
      //     ...data,
      //     date: dayjs(data?.date).format("YYYY-MM-DD"),
      //     dateExtend: endDate,
      //   })
      // );
    }
    // else {
    //   if (!isValid) {
    //     setValidTimeRangeMsg("Timeline invalid");
    //   }
    // }
  };

  const onOverTimeSubmit = (data: any) => {
    const newWorkedStartTime = Utils.calculateWorkingTime(
      data?.startTime,
      "00:00:00"
    );
    const newWorkedEndTime = Utils.calculateWorkingTime(
      "00:00:00",
      data?.endTime
    );

    const day = 60 * 60 * 24 * 1000;
    const endDate = new Date(data?.date.getTime() + day);

    if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
      if (data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
        const currentDate = dayjs(data.date).toDate();
        const morningKey = useWorkingDay.getDayAndPeriod(
          currentDate,
          "Morning"
        );
        const afternoonKey = useWorkingDay.getDayAndPeriod(
          currentDate,
          "Afternoon"
        );
        if (
          (data?.startTime >= checkStartMorning &&
            data?.startTime < checkEndMorning &&
            workingDays[morningKey]) ||
          (data?.startTime >= checkStartAfternoon &&
            data?.startTime < checkEndAfternoon &&
            workingDays[afternoonKey])
        ) {
          setMessage(t("message.startTimeInvalid"));
          return;
        }

        if (
          (data?.endTime > checkStartMorning &&
            data?.endTime <= checkEndMorning &&
            workingDays[morningKey]) ||
          (data?.endTime > checkStartAfternoon &&
            data?.endTime <= checkEndAfternoon &&
            workingDays[afternoonKey])
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }

        if (
          data?.startTime >= checkEndMorning &&
          data?.startTime < checkStartAfternoon &&
          data?.endTime > checkStartAfternoon &&
          workingDays[morningKey]
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }

        if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
          const nextDate = dayjs(data.date).add(1, "day").toDate();
          const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");

          if (
            data?.endTime > checkStartMorning &&
            workingDays[morningKey] &&
            dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
          ) {
            setMessage(t("message.endTimeInvalid"));
            return;
          }

          if (pathname === Routers.MY_TIME) {
            dispatch(
              createLogtimeInMyTime(
                {
                  ...data,
                  date: dayjs(data?.date).format("YYYY-MM-DD"),
                  endTime: "23:59:00",
                  workingTime: newWorkedStartTime,
                },
                myTimePagination,
                () => onClose()
              )
            );
          } else {
            dispatch(
              createLogtime(
                {
                  ...data,
                  date: dayjs(endDate).format("YYYY-MM-DD"),
                  startTime: "00:00:00",
                  workingTime: newWorkedEndTime,
                  dateType: dateTypeNext,
                },
                true
              )
            );
          }
        } else {
          if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
            dispatch(
              createLogtime({
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                workingTime: watchOverTimeWorkingTime,
              })
            );
          }
        }
      }
      if (
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND ||
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY
      ) {
        if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
          const nextDate = dayjs(data.date).add(1, "day").toDate();
          const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");

          if (
            data?.endTime > checkStartMorning &&
            workingDays[morningKey] &&
            dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
          ) {
            setMessage(t("message.endTimeInvalid"));
            return;
          }

          dispatch(
            createLogtime({
              ...data,
              date: dayjs(data?.date).format("YYYY-MM-DD"),
              endTime: "23:59:00",
              workingTime: newWorkedStartTime,
            })
          );
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
              },
              true
            )
          );
        } else {
          if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
            dispatch(
              createLogtime({
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                workingTime: watchOverTimeWorkingTime,
              })
            );
          }
        }
      }
    }
  };

  const onSubmitBoth = (data: any) => {
    const newWorkedStartTime = Utils.calculateWorkingTime(
      data?.startTime,
      "00:00:00"
    );
    const newWorkedEndTime = Utils.calculateWorkingTime(
      "00:00:00",
      data?.endTime
    );
    const day = 60 * 60 * 24 * 1000;
    const endDate = new Date(data?.date.getTime() + day);

    if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
      if (data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
        const currentDate = dayjs(data.date).toDate();
        const morningKey = useWorkingDay.getDayAndPeriod(
          currentDate,
          "Morning"
        );
        const afternoonKey = useWorkingDay.getDayAndPeriod(
          currentDate,
          "Afternoon"
        );
        if (
          (data?.startTime >= checkStartMorning &&
            data?.startTime < checkEndMorning &&
            workingDays[morningKey]) ||
          (data?.startTime >= checkStartAfternoon &&
            data?.startTime < checkEndAfternoon &&
            workingDays[afternoonKey])
        ) {
          setMessage(t("message.startTimeInvalid"));
          return;
        }

        if (
          (data?.endTime > checkStartMorning &&
            data?.endTime <= checkEndMorning &&
            workingDays[morningKey]) ||
          (data?.endTime > checkStartAfternoon &&
            data?.endTime <= checkEndAfternoon &&
            workingDays[afternoonKey])
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }
        if (
          data?.startTime >= checkEndMorning &&
          data?.startTime < checkStartAfternoon &&
          data?.endTime > checkStartAfternoon &&
          workingDays[morningKey]
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }
        if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
          const nextDate = dayjs(data.date).add(1, "day").toDate();
          const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");

          if (
            data?.endTime > checkStartMorning &&
            workingDays[morningKey] &&
            dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
          ) {
            setMessage(t("message.endTimeInvalid"));
            return;
          }
          dispatch(
            createLogtime({
              ...data,
              date: dayjs(data?.date).format("YYYY-MM-DD"),
              endTime: "23:59:00",
              workingTime: newWorkedStartTime,
            })
          );
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
              },
              true
            )
          );
          workTimeSubmitButtonRef.current.click();
        } else {
          if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
            dispatch(
              createLogtime({
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
              })
            );
            workTimeSubmitButtonRef.current.click();
          }
        }
      }
      if (
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND ||
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY
      ) {
        if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
          const nextDate = dayjs(data.date).add(1, "day").toDate();
          const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");

          if (
            data?.endTime > checkStartMorning &&
            workingDays[morningKey] &&
            dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
          ) {
            setMessage(t("message.endTimeInvalid"));
            if (tabIndex === 0) handleShowConfirm("workTime", true);
            return;
          }
          dispatch(
            createLogtime({
              ...data,
              date: dayjs(data?.date).format("YYYY-MM-DD"),
              endTime: "23:59:00",
              workingTime: newWorkedStartTime,
            })
          );
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
              },
              true
            )
          );
          workTimeSubmitButtonRef.current.click();
        } else {
          if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
            dispatch(
              createLogtime({
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
              })
            );
            workTimeSubmitButtonRef.current.click();
          }
        }
      }
    }
  };

  //  useEffect(() => {
  //    dispatch(
  //      fetchProjectsExteriorTimeLog({
  //        page: 0,
  //        limit: 0,
  //        status: ENUMS.PROJECT_EXTERIOR_STATUS.ACTIVE,
  //      })
  //    );
  //  }, []);

  useEffect(() => {
    if (_.isEmpty(holidaysList)) {
      dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    }
  }, []);

  const _renderWorktime = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {boardDetails?.id ? (
        <>
          <TextField
            label={`${
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internalProject")
                : t("label.externalProject")
            } `}
            placeholder={`${
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internalProject")
                : t("label.externalProject")
            } `}
            value={`${boardDetails?.project?.name} - ${boardDetails?.project?.code}`}
            size="sm"
            isReadOnly
          />
          <Controller
            name="task"
            control={workTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.process")}
                size="sm"
                options={processOptions}
                isRequired
                isError={!_.isEmpty(workTimeErrors.task?.message)}
                errorMessage={workTimeErrors?.task?.message}
              />
            )}
          />
        </>
      ) : (
        <>
          <Controller
            name="project"
            control={workTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setWorkTimeValue("task", "");
                }}
                label={`${
                  projectType === ENUMS.PROJECT_TYPE.INTERIOR
                    ? t("label.internalProject")
                    : t("label.externalProject")
                } `}
                size="sm"
                options={
                  projectType === ENUMS.PROJECT_TYPE.INTERIOR
                    ? projectOptions
                    : projectExteriorOptions
                }
                isRequired
                isError={!_.isEmpty(workTimeErrors.project?.message)}
                errorMessage={workTimeErrors?.project?.message}
              />
            )}
          />
          <Controller
            name="task"
            control={workTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.process")}
                size="sm"
                options={findWorkTimeProccessOptions}
                isRequired
                isError={!_.isEmpty(workTimeErrors.task?.message)}
                errorMessage={workTimeErrors?.task?.message}
              />
            )}
          />
        </>
      )}
      <Box
        sx={{
          width: "max-content",
        }}
      >
        <Controller
          name="timeline"
          control={workTimeControl}
          render={({ field }) => (
            <DateTimeRangePicker
              isRequired
              label={t("label.timeline")}
              onDateChange={(newDate) => {
                field.onChange(newDate);
                // const isValid = Utils.isValidTimeRange(
                //   newDate[0],
                //   newDate[1],
                //   workingTimeOffice
                // );

                // const calculateWorkingTime = Utils.calculateHourDifference(
                //   newDate[0],
                //   newDate[1],
                //   workingTimeOffice,
                //   holidaysList
                // );
                // setWorkTimeValue("workingTime", calculateWorkingTime);
                // if (isValid)
                //   setWorkTimeValue("workingTime", calculateWorkingTime);
                // setValidTimeRangeMsg(isValid ? "" : "Timeline invalid");
              }}
              value={field.value}
              isError={
                !_.isEmpty(workTimeErrors.timeline) ||
                !_.isEmpty(validTimeRangeMsg)
              }
              errorMessage={
                workTimeErrors.timeline?.message ||
                workTimeErrors.timeline?.[0]?.message ||
                validTimeRangeMsg
              }
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <Controller
          name="workingTime"
          control={workTimeControl}
          render={({ field }) => (
            <TextField
              value={field.value}
              size="sm"
              type="number"
              label={t("label.workedHours")}
              placeholder="0"
              onChange={(e) => field.onChange(e.target.value)}
              // moreInfo="Working hours will be calculated based on
              // filled timeline value"
              isError={!_.isEmpty(workTimeErrors.workingTime?.message)}
              errorMessage={workTimeErrors.workingTime?.message}
              isRequired
              // isReadOnly
            />
          )}
        />
      </Box>
      <Box>
        {pathname !== Routers.MY_TIME && (
          <Controller
            name="user"
            control={workTimeControl}
            render={({ field }) => (
              <>
                <Select
                  options={
                    projectType === ENUMS.PROJECT_TYPE.EXTERIOR
                      ? userOptionsExterior
                      : userOptions
                  }
                  size="sm"
                  label={t("label.assignee")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  displayChoose
                  isRequired
                  isDisabled={ishasArtistRole}
                  isError={!_.isEmpty(workTimeErrors.user?.message)}
                  errorMessage={workTimeErrors.user?.message}
                />
                <Checkbox
                  isDisabled={ishasArtistRole}
                  isChecked={field.value === userData?.id}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? userData?.id : "");
                  }}
                >
                  <Text fontSize="sm">{t("label.assigneeToMe")}</Text>
                </Checkbox>
              </>
            )}
          />
        )}
      </Box>
      <Box>
        <Controller
          name="description"
          control={workTimeControl}
          render={({ field }) => (
            <TextEditor
              height={80}
              value={field?.value || ""}
              label={t("label.description")}
              containerSx={{ mb: 2 }}
              onChange={(e: any) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="note"
          control={workTimeControl}
          render={({ field }) => (
            <TextareaComponent
              placeholder={t("label.note")}
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.note")}
            />
          )}
        />
      </Box>
    </Box>
  );

  const getDateTypeOptions = (day: number, language: string) => {
    const isSunday = day === 0;
    const dayOptions = {
      0: { morning: "sundayMorning", afternoon: "sundayAfternoon" },
      1: { morning: "mondayMorning", afternoon: "mondayAfternoon" },
      2: { morning: "tuesdayMorning", afternoon: "tuesdayAfternoon" },
      3: { morning: "wednesdayMorning", afternoon: "wednesdayAfternoon" },
      4: { morning: "thursdayMorning", afternoon: "thursdayAfternoon" },
      5: { morning: "fridayMorning", afternoon: "fridayAfternoon" },
      6: { morning: "saturdayMorning", afternoon: "saturdayAfternoon" },
    };

    const { morning, afternoon } = dayOptions[day as keyof typeof dayOptions];
    const isMorningWorking = workingDays[morning as keyof typeof workingDays];
    const isAfternoonWorking =
      workingDays[afternoon as keyof typeof workingDays];

    let options;

    switch (true) {
      case isMorningWorking && isAfternoonWorking:
        options = "dateTypeOptions";
        break;
      case !isMorningWorking && !isAfternoonWorking:
        options = isSunday ? "dateTypeOptionsSunday" : "dateTypeOptionsWeekend";
        break;
      default:
        options = "dateTypeWeekendOptions";
        break;
    }

    const optionsMapping: any = {
      en: {
        dateTypeOptions,
        dateTypeOptionsSunday,
        dateTypeWeekendOptions,
        dateTypeOptionsWeekend,
      },
      vi: {
        dateTypeOptions: dateTypeOptionsVI,
        dateTypeOptionsSunday: dateTypeOptionsSundayVI,
        dateTypeWeekendOptions: dateTypeWeekendOptionsVI,
        dateTypeOptionsWeekend: dateTypeOptionsWeekendVI,
      },
    };

    return optionsMapping[language][options];
  };

  const _renderOptions = () => {
    const day = dayjs(watchDate)?.day();
    return getDateTypeOptions(day, language);
  };
  const _renderNextOptions = () => {
    const day = dayjs(watchDate)?.add(1, "day")?.day();
    return getDateTypeOptions(day, language);
  };

  const _renderOvertime = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {boardDetails?.id ? (
        <>
          <TextField
            label={`${
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internalProject")
                : t("label.externalProject")
            } `}
            placeholder={`${
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internalProject")
                : t("label.externalProject")
            } `}
            value={boardDetails?.project?.name}
            size="sm"
            isReadOnly
          />
          <Controller
            name="task"
            control={overTimeControl}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.process")}
                size="sm"
                options={processOptions}
                isRequired
              />
            )}
          />
        </>
      ) : (
        <>
          <Controller
            name="project"
            control={overTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setOverTimeValue("task", "");
                }}
                label={`${
                  projectType === ENUMS.PROJECT_TYPE.INTERIOR
                    ? t("label.internalProject")
                    : t("label.externalProject")
                } `}
                size="sm"
                options={
                  projectType === ENUMS.PROJECT_TYPE.INTERIOR
                    ? projectOptions
                    : projectExteriorOptions
                }
                isRequired
                isError={!_.isEmpty(overTimeErrors.project?.message)}
                errorMessage={overTimeErrors?.project?.message}
              />
            )}
          />
          <Controller
            name="task"
            control={overTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.process")}
                size="sm"
                options={findOverTimeProccessOptions}
                isRequired
                isError={!_.isEmpty(overTimeErrors.task?.message)}
                errorMessage={overTimeErrors?.task?.message}
              />
            )}
          />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <Controller
          name="date"
          control={overTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label={t("label.date")}
              value={field.value as any}
              onDateChange={(date) => field.onChange(date)}
              isError={!_.isEmpty(overTimeErrors.date?.message)}
              errorMessage={overTimeErrors.date?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="dateType"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.dateType")}
              size="sm"
              options={_renderOptions()}
              isRequired
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <TimePicker
          isRequired
          label={t("label.timeline")}
          sx={{
            flex: 1,
          }}
          onTimeChange={(newTime: string[]) => {
            setOverTimeValue("startTime", newTime[0]);
            setOverTimeValue("endTime", newTime[1]);
            setMessage("");
          }}
        />
        <Controller
          name="workingTime"
          control={overTimeControl}
          render={({ field }) => (
            <TextField
              label={t("label.workedHours")}
              value={field.value}
              placeholder="0"
              size="sm"
              isReadOnly
              isError={!_.isEmpty(overTimeErrors.workingTime?.message)}
              errorMessage={overTimeErrors.workingTime?.message}
              moreInfo={t(
                "message.workingHoursWillBeCalculatedBasedOnFilledTimelineValue"
              )}
            />
          )}
        />
      </Box>
      <Box>
        {message && (
          <Text fontSize={"14px"} color={"error"}>
            {message}
          </Text>
        )}
      </Box>
      {watchOverStartTime > watchOverEndTime &&
        watchOverEndTime !== "00:00:00" && (
          <Select
            value={dateTypeNext}
            onChange={(e: any) => {
              setDateTypeNext(e.target.value);
              setMessage("");
              setMessageDateType("");
            }}
            label={t("label.nextDate")}
            size="sm"
            options={_renderNextOptions()}
            isRequired
          />
        )}
      <Box>
        {messageDateType && (
          <Text fontSize={"14px"} color={"error"}>
            {messageDateType}
          </Text>
        )}
      </Box>
      <Box
      // sx={{
      //   display: "none",
      // }}
      >
        {pathname !== Routers.MY_TIME && (
          <Controller
            name="user"
            control={overTimeControl}
            render={({ field }) => (
              <>
                <Select
                  options={
                    projectType === ENUMS.PROJECT_TYPE.EXTERIOR
                      ? userOptionsExterior
                      : userOptions
                  }
                  size="sm"
                  label={t("label.assigneeToMe")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  displayChoose
                  isRequired
                  isDisabled={ishasArtistRole}
                  isError={!_.isEmpty(overTimeErrors.user?.message)}
                  errorMessage={overTimeErrors.user?.message}
                />
                <Checkbox
                  isDisabled={ishasArtistRole}
                  isChecked={field.value === userData?.id}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? userData?.id : "");
                  }}
                >
                  <Text fontSize="sm">{t("label.assigneeToMe")}</Text>
                </Checkbox>
              </>
            )}
          />
        )}
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>

        <Controller
          name="description"
          control={overTimeControl}
          render={({ field }) => (
            <Textarea
              size="sm"
              value={field?.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t("label.description")}
            />
          )}
        />
      </Box>
      {/* <Box>
        <Controller
          name="note"
          control={overTimeControl}
          render={({ field }) => (
            <TextareaComponent
              placeholder="Note"
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              label="Note"
            />
          )}
        />
      </Box> */}
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="lg"
    >
      <Tabs
        size="sm"
        variant="line"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxHeight: "100%",
          }}
        >
          <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
            {t("title.logTime")}
            <TabList
              sx={{
                width: "max-content",
                mx: "auto",
              }}
            >
              <Tab
                _selected={{
                  color: "#bb7154",
                  fontWeight: 600,
                  borderBottom: "2px solid #bb7154",
                }}
              >
                {t("label.worktime")}
              </Tab>
              <Tab
                _selected={{
                  color: "#bb7154",
                  fontWeight: 600,
                  borderBottom: "2px solid #bb7154",
                }}
              >
                {t("label.overtime")}
              </Tab>
            </TabList>
            {/* {(!_.isEmpty(workTimeErrors) || !_.isEmpty(overTimeErrors)) && (
              <Text
                fontSize="sm"
                sx={{
                  color: "#E53E3E",
                  width: "100%",
                  fontWeight: 500,
                }}
              >
                An error occurred, please check again
              </Text>
            )} */}
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              p: 0,
              overflow: "auto",
            }}
          >
            <TabPanels>
              <TabPanel>{_renderWorktime()}</TabPanel>
              <TabPanel>{_renderOvertime()}</TabPanel>
            </TabPanels>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              //  p: 4,
              pt: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button size="sm" ref={cancelRef} onClick={onClose}>
                {t("button.cancel")}
              </Button>
              <Button
                size="sm"
                onClick={handleCombinedSubmit}
                ml={3}
                //colorScheme="twitter"
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  minW: 65,
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
                isDisabled={isActionLoading}
              >
                {t("button.create")}
              </Button>
              <Button
                ref={workTimeSubmitButtonRef}
                sx={{ display: "none" }}
                onClick={handleWorkTimeSubmit(onWorkTimeSubmit)}
              />
              <Button
                ref={overTimeSubmitButtonRef}
                sx={{ display: "none" }}
                onClick={handleOverTimeSubmit(onOverTimeSubmit)}
              />
              <Button
                ref={submitButtonBoth}
                sx={{ display: "none" }}
                onClick={handleOverTimeSubmit(onSubmitBoth)}
              />
            </Box>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Tabs>
      <ConfirmDialog
        title={t("message.doYouWantToProceed")}
        isOpen={isShowConfirm.workTime}
        onClose={() => setIsShowConfirm({ ...isShowConfirm, workTime: false })}
        body={t(
          "message.youAreAboutToSubmittheWorktimeformTheOvertimeFormHasMissingOrInvalidDetailsAreYouSureYouWantToProceed"
        )}
        onAction={() => {
          workTimeSubmitButtonRef.current.click();
          handleShowConfirm("workTime", false);
        }}
        actionType="confirm"
      />
      <ConfirmDialog
        title={t("message.doYouWantToProceed")}
        isOpen={isShowConfirm.overTime}
        onClose={() => handleShowConfirm("overTime", false)}
        body={t(
          "message.youAreAboutToSubmitTheOvertimeFormTheWorktimeFormHasMissingOrInvalidDetailsAreYouSureYouWantToProceed"
        )}
        onAction={() => {
          overTimeSubmitButtonRef.current.click();
          handleShowConfirm("overTime", false);
        }}
        actionType="confirm"
      />
    </AlertDialog>
  );
};

export default CreateTimelogDialog;
