import React, { useState, useMemo, useEffect, memo } from "react";
import _ from "lodash";
import { motion, useAnimation } from "framer-motion";
import { useSelector } from "react-redux";
// import addNotification from "react-push-notification";

import {
  Box,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Divider,
  Tooltip,
} from "@chakra-ui/react";

import { Routers } from "@/Constants";
import Utils from "@/Utils";

import { BellAlertIcon, BellIcon } from "@heroicons/react/24/outline";
import {
  INewNotification,
  INotificationFilter,
  INotificationStructure,
} from "@/Interfaces/Notification.interface";
import { RootState, useTypedDispatch } from "@/Store";
import { NotificationActions } from "@/Actions";
import { ProcessContent } from "@/Components/Common";
import { getSocket } from "@/Configs/socket.config";
import { NOTIFICATION_TYPE } from "@/Constants/Enums.contant";
import { useTranslation } from "react-multi-lang";
import dayjs from "dayjs";

const {
  markAllAsReadOutside,
  markAllAsRead,
  markAsRead,
  fetchOutsideSuccess,
  fetchNotifications,
} = NotificationActions;

const Notification: React.FC = () => {
  const dispatch = useTypedDispatch();
  const socket = getSocket();
  const t = useTranslation();
  const countNotifications: any = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "count")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "isFetchLoading")
  );

  const payload: INotificationStructure[] = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "payload")
  );

  const pagination: INotificationFilter = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "pagination")
  );

  const memoizedNotifications = useMemo(() => {
    const readNotifications = payload.filter(
      (notification: INotificationStructure) => notification.status === "read"
    );
    const unreadNotifications = payload.filter(
      (notification) => notification.status === "unread"
    );

    return { read: readNotifications, unread: unreadNotifications };
  }, [payload]);

  const { unread: unreadNotification } = memoizedNotifications;

  const controls = useAnimation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [newNotification, setNewNotification] = useState<any>(null);

  useEffect(() => {
    // if (
    //   "serviceWorker" in navigator &&
    //   navigator.serviceWorker.controller !== null
    // ) {
    //   navigator.serviceWorker.controller.postMessage({
    //     type: "notification",
    //   });
    // }
    socket.on("notification", (data: INewNotification) => {
      setNewNotification(data);
    });

    // navigator.serviceWorker.addEventListener("message", (event) => {
    //   const { type, data } = event.data;
    //   if (type === "notification") {
    //     setNewNotification(data);
    //   }
    // });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newNotification)) {
      dispatch(fetchNotifications(pagination));
      // addNotification({
      //   title: newNotification?.newNotification?.content,
      //   theme: "darkblue",
      //   native: true,
      //   onClick: () => {
      //     const noti = newNotification?.newNotification;
      //     const redirectPath = Utils.mapNotificationToPath(noti?.type);
      //     if (noti?.id && noti?.status === "unread")
      //       dispatch(
      //         markAsRead(
      //           {
      //             notificationIds: [noti.id],
      //           },
      //           pagination
      //         )
      //       );

      //     if (noti.type === NOTIFICATION_TYPE.TASK_COMMENT) {
      //       Utils.redirect(
      //         `${redirectPath}?id=${
      //           noti?.extraData?.boardId || noti?.recordId
      //         }${noti?.extraData?.boardId ? `&taskId=${noti?.recordId}` : ""}`
      //       );
      //     } else {
      //       Utils.redirect(
      //         `${redirectPath}?id=${noti?.extraData?.boardId || noti?.recordId}`
      //       );
      //     }
      //   },
      // });
      dispatch(fetchOutsideSuccess(_.omit(newNotification, "newNotification")));
    }
    setNewNotification(null);
  }, [newNotification]);

  useEffect(() => {
    // Update animation when countNotification changes
    controls.start({
      scale: 1.2,
      opacity: 1,
      transition: { duration: 0.5 },
    });
  }, [memoizedNotifications, controls]);

  useEffect(() => {
    // Reset animation after 0.5s
    const timeoutId = setTimeout(() => {
      controls.start({ scale: 1, opacity: 1, transition: { duration: 0 } });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [controls, isOpen]);

  useEffect(() => {
    if (isOpen) dispatch(markAllAsReadOutside(pagination, countNotifications));
  }, [isOpen]);

  const formatNotificationCount = (count: number) => {
    return count > 10 ? "9+" : count.toString();
  };

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} isLazy>
      <PopoverTrigger>
        <motion.div
          // whileTap={{ scale: 0.95 }}
          // whileHover={{ scale: 1.05 }}
          style={{
            position: "relative",
            cursor: "pointer",
            // color: "#fff",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {_.isNumber(countNotifications?.countNotification) &&
          countNotifications?.countNotification > 0 ? (
            <BellAlertIcon width={30} height={30} />
          ) : (
            <BellIcon width={30} height={30} />
          )}

          {/* {_.isNumber(countNotifications?.countNotification) &&
            countNotifications?.countNotification > 0 && (
              // <motion.div
              //   animate={controls}
              //   style={{
              //     position: "absolute",
              //     top: "-7px",
              //     right: 0,
              //     fontSize: "12px",
              //     borderRadius: "50%",
              //     background: "red",
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     width: "17px",
              //     height: "17px",

              //   }}
              // >
              //   <Text sx={{ fontSize: "12px", color: "#fff", fontWeight: 600 }}>
              //     {formatNotificationCount(
              //       countNotifications?.countNotification
              //     )}
              //   </Text>
              // </motion.div>
            )} */}
          {_.isNumber(countNotifications?.countNotification) &&
            countNotifications?.countNotification > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "-7px",
                  right: 0,
                  fontSize: "12px",
                  borderRadius: "50%",
                  background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "17px",
                  height: "17px",
                }}
              >
                <Text
                  sx={{
                    fontSize: "12px",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {formatNotificationCount(
                    countNotifications?.countNotification
                  )}
                </Text>
              </Box>
            )}
        </motion.div>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("label.notifications")}
              <Button
                sx={{ mr: 5 }}
                size="xs"
                variant="ghost"
                colorScheme="teal"
                onClick={() => dispatch(markAllAsRead(pagination))}
              >
                {t("button.markAllAsRead")}
              </Button>
            </Box>
          </Box>
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Tabs
            variant="enclosed"
            size="sm"
            sx={{
              ".chakra-tabs__tab-panel": {
                p: 1,
              },
            }}
          >
            <TabList>
              <Tab
                _selected={{
                  color: "#bb7154",
                }}
              >
                {t("button.all")}
              </Tab>
              <Tab
                _selected={{
                  color: "#bb7154",
                }}
              >
                {t("button.unread")}
                {`(${countNotifications?.countNotificationUnread || 0})`}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel
                sx={{
                  maxHeight: "300px",
                  minHeight: _.size(payload) > 10 ? "300px" : "auto",
                  overflowY: "auto",
                }}
              >
                {!_.isEmpty(payload) ? (
                  _.map(payload, (noti, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          cursor: "pointer",
                          transition: "all .3s ease-in-out",
                          ":hover": {
                            bg: "rgba(166, 183, 170,0.2)",
                          },
                          p: 2,
                          maxH: 300,
                          overflow: "auto",
                        }}
                        onClick={() => {
                          const redirectPath = Utils.mapNotificationToPath(
                            noti?.type
                          );
                          if (noti?.id && noti?.status === "unread")
                            dispatch(
                              markAsRead(
                                {
                                  notificationIds: [noti.id],
                                },
                                pagination
                              )
                            );

                          if (noti.type === NOTIFICATION_TYPE.TASK_COMMENT) {
                            Utils.redirect(
                              `${redirectPath}?id=${
                                noti?.extraData?.boardId || noti?.recordId
                              }${
                                noti?.extraData?.boardId
                                  ? `&taskId=${noti?.recordId}`
                                  : ""
                              }`
                            );
                          } else {
                            Utils.redirect(
                              `${redirectPath}?id=${
                                noti?.extraData?.boardId || noti?.recordId
                              }`
                            );
                          }
                        }}
                      >
                        <Box w="max-content">
                          <Box
                            w={3}
                            h={3}
                            rounded="full"
                            bg={
                              noti.status === "read"
                                ? "gray.200"
                                : "rgba(187, 113, 84,0.8)"
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            wordBreak: "break-word",
                          }}
                        >
                          <ProcessContent payload={noti} />
                          {/* <Text fontSize="sm">{noti?.content}</Text> */}
                          <Tooltip
                            label={dayjs(noti?.createdAt).format(
                              "YYYY-MM-DD, h:mm:ss"
                            )}
                            hasArrow
                          >
                            <Text fontSize="xs" w="max-content">
                              {Utils.calculateTimeAgo(noti?.createdAt)}
                            </Text>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  ))
                ) : !isFetchLoading ? (
                  <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel
                sx={{
                  maxHeight: "300px",
                  minHeight: _.size(payload) > 10 ? "300px" : "auto",
                  overflowY: "auto",
                }}
              >
                {!_.isEmpty(unreadNotification) ? (
                  _.map(unreadNotification, (noti, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          cursor: "pointer",
                          transition: "all .3s ease-in-out",
                          ":hover": {
                            bg: "rgba(166, 183, 170,0.2)",
                          },
                          p: 2,
                        }}
                        onClick={() => {
                          const redirectPath = Utils.mapNotificationToPath(
                            noti?.type
                          );
                          if (noti?.id && noti?.status === "unread")
                            dispatch(
                              markAsRead(
                                {
                                  notificationIds: [noti.id],
                                },
                                pagination
                              )
                            );
                          Utils.redirect(
                            `${redirectPath}?id=${
                              noti?.extraData?.boardId || noti?.recordId
                            }`
                          );
                        }}
                      >
                        <Box w="max-content">
                          <Box
                            w={3}
                            h={3}
                            rounded="full"
                            bg={
                              noti.status === "read"
                                ? "gray.200"
                                : "rgba(187, 113, 84,0.8)"
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            wordBreak: "break-word",
                          }}
                        >
                          <ProcessContent payload={noti} />
                          {/* <Text fontSize="sm">{noti?.content}</Text> */}
                          <Tooltip label={noti?.createdAt} hasArrow>
                            <Text fontSize="xs" w="max-content">
                              {Utils.calculateTimeAgo(noti?.createdAt)}
                            </Text>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  ))
                ) : !isFetchLoading ? (
                  <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
                ) : (
                  ""
                )}
              </TabPanel>
            </TabPanels>
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="sm"
                onClick={() => Utils.redirect(Routers.NOTIFICATION)}
              >
                {t("button.viewall")}
              </Button>
            </Box>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default memo(Notification);
