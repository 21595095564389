import React, { useState, useRef } from "react";
import _ from "lodash";
import {
  Box,
  Text,
  HStack,
  Icon,
  IconButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useOutsideClick,
} from "@chakra-ui/react";
import {
  ClipboardDocumentCheckIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

interface IPinStructure {
  id: string;
  content?: string;
  isSender?: boolean;
  sender?: any;
  timestamp?: string;
  fileAttachment?: any[];
}

interface ISectionProps {
  payload?: IPinStructure[];
  onPinClick(pinId: string): void;
  onUnPinClick(pinId: string): void;
}

const Bookmark: React.FC<ISectionProps> = ({
  payload,
  onPinClick,
  onUnPinClick,
}) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [popoverVisible, setPopoverVisible] = useState<string | null>(null);

  const bookmarkref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: bookmarkref,
    handler: () => {
      setIsShowMore(false);
      setPopoverVisible(null);
    },
  });

  const _renderMoreOptions = (pinId: string) => {
    return (
      <Popover isOpen={popoverVisible === pinId}>
        <PopoverTrigger>
          <IconButton
            size="sm"
            aria-label={""}
            isRound
            sx={{ bg: "none", ml: "auto" }}
            onClick={(e) => {
              e.stopPropagation();
              setPopoverVisible(pinId);
            }}
          >
            <EllipsisHorizontalIcon />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent sx={{ maxW: 100 }}>
          {/* <Button
            size="sm"
            variant="ghost"
            onClick={() => {
              console.log("copy", pinId);
            }}
          >
            Copy
          </Button> */}
          <Button
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={(e) => {
              e.stopPropagation();
              onUnPinClick(pinId);
            }}
          >
            Unpin
          </Button>
        </PopoverContent>
      </Popover>
    );
  };

  if (_.isEmpty(payload)) return;

  const firstPin = payload?.[0];
  const morePin = payload?.slice(1);

  return (
    <Box ref={bookmarkref}>
      {!isShowMore && !_.isEmpty(firstPin) ? (
        <Box
          sx={{
            background: "#fff",
            border: "1px solid silver",
            p: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
            "&:hover": {
              bg: "gray.100",
            },
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onPinClick(firstPin.id);
            setIsShowMore(false);
          }}
        >
          <Icon
            sx={{
              cursor: "pointer",
            }}
            as={ClipboardDocumentCheckIcon}
            boxSize={5}
          />
          <Box>
            <Text fontSize="sm" fontWeight={600}>
              Tin nhắn
            </Text>
            <Text fontSize="sm">
              {firstPin?.sender?.name}: {firstPin?.content}
            </Text>
          </Box>
          {_renderMoreOptions(firstPin?.id)}
          {!_.isEmpty(morePin) && (
            <Button
              size="xs"
              rightIcon={<TriangleDownIcon />}
              colorScheme="teal"
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setIsShowMore(true);
              }}
            >
              Another {_.size(morePin)} pin
            </Button>
          )}
        </Box>
      ) : !_.isEmpty(morePin) ? (
        <Box>
          <HStack
            sx={{
              justifyContent: "space-between",
              bg: "gray.100",
              width: "full",
              px: 2,
            }}
          >
            <Text fontSize="sm">Pin list (2)</Text>
            <Text
              fontSize="sm"
              sx={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsShowMore(false);
              }}
            >
              Collapse
              <Icon
                sx={{
                  cursor: "pointer",
                  ml: 1,
                }}
                as={TriangleUpIcon}
              />
            </Text>
          </HStack>
          {_.map(morePin, (pin, index) => (
            <Box
              key={index}
              sx={{
                background: "#fff",
                border: "1px solid silver",
                p: 2,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: 2,
                "&:hover": {
                  bg: "gray.100",
                },
              }}
              onClick={() => {
                onPinClick(pin.id);
                setIsShowMore(false);
              }}
            >
              <Icon
                sx={{
                  cursor: "pointer",
                }}
                as={ClipboardDocumentCheckIcon}
                boxSize={5}
              />
              <Box>
                <Text fontSize="sm" fontWeight={600}>
                  Tin nhắn
                </Text>
                <Text fontSize="sm">
                  {pin?.sender?.name} : {pin?.content}
                </Text>
              </Box>
              {_renderMoreOptions(pin?.id)}
            </Box>
          ))}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Bookmark;
