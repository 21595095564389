import { PURGE } from "redux-persist";
import { ActionTypes, DEFAULT_LOADING_STATES } from "@/Constants";

const initialState = {
  DEFAULT_LOADING_STATES,
  payload: [],
  details: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_ROLE_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_ROLE_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_ROLE_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.RESET_ROLES_REDUCER:
      return initialState;

    case ActionTypes.FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    }
    case ActionTypes.FETCH_ROLES_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }

    default:
      return state;
  }
};
