import React, { useEffect, useMemo } from "react";
import _ from "lodash";

import { useSearchParams } from "react-router-dom";

import {
  Stack,
  Heading,
  Button,
  Box,
  Icon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, LinkIcon } from "@heroicons/react/24/outline";

import { AdminLayout } from "@/Components/DefaultLayout";
import Board from "./Board";

import Utils from "@/Utils";
import { Routers } from "@/Constants";
import {
  BoardActions,
  LogtimeActions,
  ProjectActions,
  UserActions,
  WorkingDayActions,
} from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { IPerformAction } from "@/Interfaces/Board.interface";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";

import { BoardDialog, ConfirmDialog, TimeLogDialog } from "@/Components/Popup";
import { RoundedContainer } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const {
  getBoardById,
  resetBoardPerformAction,
  resetBoardDetail,
  performAction: boardPerformActions,
  deleteColumnInBoard,
} = BoardActions;
const { resetLogTimePerformAction, deleteLogTime } = LogtimeActions;
const { fetchProjects, resetProjectReducer } = ProjectActions;
const { fetchUser, resetUserReducer } = UserActions;
const { fetchWorkingDay, resetWorkingDayReducer } = WorkingDayActions;

const BoardProject: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const boardId = searchParams.get("id");
  const taskId = searchParams.get("taskId");
  const t = useTranslation();

  const userData = Utils.getSavedUserData();
  const details: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );
  const isLogTimeActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );
  const performAction: IPerformAction = useSelector((state: RootState) =>
    _.get(state.BOARD, "performAction")
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const canFetchUsers = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "fetchUsers"
  );

  const resolvedData = useMemo(() => {
    if (_.isEmpty(details?.task)) return [];

    // sort by order
    const sortedTasks = [...details?.task].sort((a, b) => a.order - b.order);
    const orderedColumns = _.map(sortedTasks, (task) => task?.order);
    return _.map(sortedTasks, (task) => {
      const userMap: any = {};

      _.forEach(task.logTime, (logTime) => {
        const userId = logTime?.user?.id;

        if (!userMap[userId as keyof typeof userMap]) {
          userMap[userId as keyof typeof userMap] = {
            user: logTime?.user?.userData,
            order: task?.order,
            logtimes: [],
          };
        }

        userMap[userId as keyof typeof userMap].logtimes.push(logTime);
      });

      const transformedData = _.map(userMap, (userEntry) => {
        const sortedLogTimes = userEntry.logtimes.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        );
        return {
          id: userEntry.user.id,
          columnId: task.id,
          boardId: details?.id,
          projectType: details?.project?.type,
          name: userEntry.user.fullName,
          avatar: userEntry?.user?.avatar?.path,
          desc: "",
          order: userEntry?.order,
          placeholderCard: false,
          logtimes: sortedLogTimes,
        };
      });

      return {
        id: task.id,
        name: task.title,
        desc: task.description,
        startDate: task?.timeLineStart,
        endDate: task?.timeLineEnd,
        totalFiles: _.size(task?.fileAttachment),
        totalComments: _.size(task?.taskComment),
        cardOrderIds: [],
        columnOrderNumber: orderedColumns,
        status: task.status,
        order: task?.order,
        cards: !_.isEmpty(task.logTime)
          ? transformedData
          : [
              {
                id: `placeholder-card-${task.id}`,
                columnId: task.id,
                placeholderCard: true,
                name: "",
                desc: "",
              },
            ],
      };
    });
  }, [details]);

  useEffect(() => {
    if (boardId) {
      dispatch(getBoardById(boardId));
    }
    if (canFetchUsers)
      dispatch(
        fetchUser({
          page: 0,
          limit: 0,
        })
      );
    dispatch(
      fetchProjects({
        page: 0,
        limit: 0,
      })
    );

    dispatch(fetchWorkingDay());

    return () => {
      dispatch(resetUserReducer());
      dispatch(resetBoardDetail());
      dispatch(resetProjectReducer());
      dispatch(resetLogTimePerformAction());
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(details) && taskId)
      dispatch(boardPerformActions("view", taskId));
  }, [details]);

  const _renderPopup = () => (
    <>
      <BoardDialog.TaskDetailDialog
        open={performAction?.actionType === "view"}
        onClose={() => dispatch(resetBoardPerformAction())}
      />
      <BoardDialog.EditTaskDialog
        open={performAction?.actionType === "edit"}
        onClose={() =>
          performAction?.actionType === "edit" &&
          dispatch(resetBoardPerformAction())
        }
      />
      <TimeLogDialog.CreateTimelogDialog
        open={
          timeLogPerformAction?.actionType === "logInternal" ||
          timeLogPerformAction?.actionType === "logExternal"
        }
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <TimeLogDialog.UpdateTimelogDialog
        open={timeLogPerformAction?.actionType === "editLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(deleteLogTime(timeLogPerformAction?.id, details?.id));
        }}
        body={t("message.doYouWantRemoveThisLog")}
        actionType="delete"
        isLoading={isLogTimeActionLoading}
      />
      <ConfirmDialog
        isOpen={performAction?.actionType === "deleteProcess"}
        onClose={() => dispatch(resetBoardPerformAction())}
        onAction={() => {
          dispatch(
            deleteColumnInBoard(
              performAction?.itemId,
              details?.id,
              () => dispatch(resetBoardPerformAction()),
              () => dispatch(resetBoardPerformAction())
            )
          );
        }}
        body={t("message.doYouWantRemoveThisProcess")}
        actionType="delete"
        isLoading={isActionLoading}
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="sm"
            variant="ghost"
            colorScheme="twitter"
            leftIcon={<Icon as={ChevronLeftIcon} />}
            onClick={() => Utils.redirect(Routers.BOARD)}
            color={"#bb7154"}
          >
            {t("button.backToBoardOverview")}
          </Button>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="twitter"
            leftIcon={<Icon as={LinkIcon} />}
            onClick={(e) => {
              e.stopPropagation();
              Utils.redirect(
                `${Routers.PROJECT_DETAILS}?id=${details?.project?.id}`
              );
            }}
            isDisabled={_.isEmpty(details)}
          >
            {t("button.goToProjectDetail")}
          </Button>
        </Box>
        <Flex justifyContent="space-between">
          <Heading size="md" color={"#5C6e6c"}>
            {details?.project?.name}
          </Heading>
          <Flex alignItems="center" justifyContent="end" gap={2} mt={2}>
            <Flex alignItems="center" gap={1}>
              <Box
                sx={{
                  w: 2.5,
                  h: 2.5,
                  borderRadius: "50%",
                  background: "#a6b7aa",
                }}
              />
              <Text fontSize="14px" fontWeight={400}>
                {t("label.worktime")}
              </Text>
            </Flex>
            <Flex alignItems="center" gap={1}>
              <Box
                sx={{
                  w: 2.5,
                  h: 2.5,
                  borderRadius: "50%",
                  background: "#bb7154",
                }}
              />
              <Text fontSize="14px" fontWeight={400}>
                {t("label.overtime")}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <RoundedContainer>
          <Board data={resolvedData} />
        </RoundedContainer>
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default BoardProject;
