import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import _ from "lodash";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  startDate: _.first(Utils.getDaysOfWeek(new Date(), "YYYY-MM-DD")),
  endDate: _.last(Utils.getDaysOfWeek(new Date(), "YYYY-MM-DD")),
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isCreateTimekeepingSuccess: false,
  isUpdateTimekeepingSuccess: false,
  isCreaetTimekeepingFail: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  checkInList: [],
  message: "",
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_CHECK_IN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateTimekeepingSuccess: false,
        isUpdateTimekeepingSuccess: false,
        isCreaetTimekeepingFail: false,
        checkInList: payload,
      };
    case ActionTypes.FETCH_CHECK_IN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateTimekeepingSuccess: false,
        isUpdateTimekeepingSuccess: false,
        checkInList: [],
      };

    default:
      return state;
  }
};
