import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { BACKUP, FILES } = APIs;

export const fetchBackup = async (payload: any) => {
  return request(BACKUP.BASIC, "GET", payload);
};

export const changeBackup = async (payload: any) => {
  return request(BACKUP.BASIC, "POST", payload);
};

export const fetchBackupList = async (payload: any) => {
  return request(BACKUP.BACKUP_LIST, "GET", payload);
};

export const getBackupList = async () => {
  return request(BACKUP.BACKUP_DATABASE, "POST");
};

export const restoreDatabase = async (payload: any) => {
  return request(BACKUP.RESTORE, "POST_FORM_DATA", payload);
};

export const deleteDatabase = async (id: string) => {
  return request(`${BACKUP.DELETE}/${id}`, "PUT");
};

export const getAllMemoryConversation = async () => {
  return request(FILES.ALL_MEMORY_CONVERSATION, "GET");
};
