import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
  Stack,
  Heading,
  Box,
  InputGroup,
  Select,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverFooter,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { AdminLayout } from "@/Components/DefaultLayout";
import DataTable from "@/Components/LayoutPart/DataTable";
// import { IPaginate } from "@/Types/Common.types";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  FunnelIcon,
  ArrowPathIcon,
  // MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { ConfirmDialog, UserDialog } from "@/Components/Popup";
import Utils from "@/Utils";
import { ENUMS, Routers } from "@/Constants";
import { UserActions, RoleActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { IFilterUser, IUser } from "@/Interfaces/User.interface";
import { Autocomplete, TextField, RoundedContainer } from "@/Components/Common";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
// import CommonColors from "@/Themes/CommonColors";

const { fetchUser, deleteUser, resetUserReducer } = UserActions;
const { fetchRoles } = RoleActions;
const { USER_STATUS } = ENUMS;

const initialFilter: IFilterUser = {
  page: 1,
  limit: 10,
  roleCode: [],
  keyword: "",
  status: "",
};

const UserList: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [filters, setFilters] = useState(initialFilter);
  const [valueIdUserr, setValueIdUser] = useState<string>("");

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    edit: false,
    delete: false,
    filter: false,
  });

  const userList: any[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const roles = useSelector((state: RootState) => _.get(state.ROLE, "payload"));

  const meta = useSelector((state: RootState) => _.get(state.USER, "meta"));

  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const sortedUsers = useMemo(
    () => _.sortBy(userList, (user) => user?.userData?.fullName.toLowerCase()),
    [userList]
  );

  const createUser = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "checkCreateUser"
  );

  const roleOptions = React.useMemo(
    () =>
      _.map(roles, (role: any) => ({
        label: role.roleCode === "artist" ? "Staff" : _.startCase(role.name),
        value: role.roleCode,
      })),
    [roles]
  );

  const fieldGroupSize = useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "275px",
      xl: "275px",
      "2xl": "275px",
    };
  }, []);

  // const pagination = useSelector((state: RootState) =>
  //   _.get(state.USER, "pagination")
  // );

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.USER, "isFetchLoading")
  );

  const isDeleteSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.USER, "isDeleteSuccess")
  );

  const pagination: IFilterUser = useSelector((state: RootState) =>
    _.get(state.USER, "pagination")
  );

  const handleShowPopup = (name: string, value: boolean) => {
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const onRowAction = (item: IUser, action: string) => {
    if (action === "edit")
      Utils.redirect(`${Routers.USER_UPDATE}?id=${item.id}`);
    if (action === "delete") {
      setIsShowPopup({ ...isShowPopup, delete: true });
      setValueIdUser(item?.id);
    }
  };

  const onPageChange = (page: number) => {
    const newFilters = { ...filters, page: page };
    setFilters(newFilters);
    dispatch(fetchUser(newFilters));
  };

  const onPageLimit = (limit: number) => {
    const newFilter = { ...filters, page: 1, limit };
    setFilters(newFilter);
    dispatch(fetchUser(newFilter));
  };

  const handleFiltersChange = (key: string, value: string | string[]) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleReset = () => {
    setFilters(initialFilter);
    dispatch(fetchUser(initialFilter));
  };

  const handleSubmit = () => {
    setIsShowPopup({ ...isShowPopup, filter: false });
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters) dispatch(fetchUser(resolvedFilters as IFilterUser));
  };

  const onChangStatus = (action: "delete") => {
    if (action === "delete" && !_.isEmpty(valueIdUserr))
      dispatch(deleteUser(valueIdUserr, pagination));
  };

  React.useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (accessRouter)
      dispatch(
        fetchUser({
          page: 1,
          limit: 10,
        })
      );
    return () => {
      dispatch(resetUserReducer());
    };
  }, []);

  React.useEffect(() => {
    if (_.isEmpty(roles)) dispatch(fetchRoles());
  }, [roles]);

  React.useEffect(() => {
    if (isDeleteSuccess)
      setIsShowPopup({
        create: false,
        edit: false,
        delete: false,
        filter: false,
      });
  }, [isDeleteSuccess]);

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName: any) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);
    dispatch(fetchUser(updatedPagination));
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        variant="solid"
        key={label}
        textTransform={field !== "keyword" ? "capitalize" : "inherit"}
      >
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const getRoleLabel = (roleId: string) => {
    const userOption = (roleOptions as { value: string; label: string }[]).find(
      (option) => option.value === roleId
    );
    return userOption?.label || roleId;
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (pagination?.keyword) {
      renderedTags.push(renderTag(pagination?.keyword, "keyword"));
    }
    if (pagination?.status) {
      renderedTags.push(renderTag(t(`status.${pagination?.status}`), "status"));
    }

    if (!_.isEmpty(pagination?.roleCode)) {
      const roleLabels = _.map(pagination?.roleCode, getRoleLabel);
      const roleString = roleLabels.join(", ");
      renderedTags.push(renderTag(roleString, "roleCode"));
    }

    return (
      <HStack spacing={4} mt={2}>
        <HStack spacing={1} flexWrap="wrap">
          <Text
            fontSize="sm"
            fontWeight={500}
            sx={{
              minW: "max-content",
            }}
            color={"#5C6e6c"}
          >
            {t("label.filterBy")}:
          </Text>
          <HStack spacing={1} flexWrap="wrap">
            {renderedTags}
          </HStack>
        </HStack>
      </HStack>
    );
  };

  const _renderFilterOptions = () => (
    <Popover isOpen={isShowPopup.filter} placement="auto-start" closeOnBlur>
      <PopoverTrigger>
        <Button
          size="sm"
          //  colorScheme="whatsapp"
          onClick={(e: any) => {
            e.stopPropagation();
            handleShowPopup("filter", true);
          }}
          leftIcon={<Icon as={FunnelIcon} />}
          sx={{
            color: "#fff",
            background: "#D2A96A",
            "&:hover": {
              background: "#D2BD6A",
            },
          }}
        >
          {t("label.filter")}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{t("label.filterOptions")}</PopoverHeader>
        <PopoverBody
          sx={{
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <InputGroup size="sm">
              <Autocomplete
                value={filters.roleCode}
                //  isClear={isClearFilters}
                isMulti
                onChange={(newValue: any) =>
                  handleFiltersChange("roleCode", newValue)
                }
                options={roleOptions}
                //  customComponents={customComponents}
                placeholder={t("label.allRole")}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Select
                placeholder={t("label.allStatus")}
                value={filters.status}
                onChange={(e: any) =>
                  handleFiltersChange("status", e.target.value)
                }
              >
                {_.map(_.values(USER_STATUS), (status: any, index: number) => (
                  <option key={index} value={status}>
                    {t(`status.${status}`)}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <HStack justifyContent="space-between">
            <Button
              size="sm"
              onClick={() => handleShowPopup("filter", false)}
              variant="ghost"
              colorScheme="red"
            >
              {t("button.close")}
            </Button>
            <HStack spacing={3}>
              <Button
                size="sm"
                //colorScheme="orange"
                onClick={handleReset}
                leftIcon={<Icon as={ArrowPathIcon} />}
                sx={{
                  color: "#fff",
                  background: "#bb7154",
                  "&:hover": {
                    background: "#Db9d97",
                  },
                }}
              >
                {t("button.reset")}
              </Button>
              <Button
                size="sm"
                //colorScheme="whatsapp"
                onClick={handleSubmit}
                leftIcon={<Icon as={FunnelIcon} />}
                sx={{
                  color: "#fff",
                  background: "#D2A96A",
                  "&:hover": {
                    background: "#D2BD6A",
                  },
                }}
              >
                {t("button.filter")}
              </Button>
            </HStack>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );

  const _renderTopUser = () => {
    return (
      <Box sx={{ background: "white", p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
            flex: 1,
          }}
        >
          <Box sx={{ maxW: fieldGroupSize, flex: 2 }}>
            <TextField
              placeholder={t("label.search")}
              value={filters.keyword}
              onChange={(e) => handleFiltersChange("keyword", e.target.value)}
              onKeyDown={(e) => {
                const element = e.currentTarget as HTMLInputElement;
                const value = element.value;
                if (e.key === "Enter")
                  dispatch(
                    fetchUser({
                      ...pagination,
                      keyword: _.trim(value),
                    })
                  );
              }}
              rightElement={
                <Tooltip label={t("label.search")} hasArrow>
                  <Icon
                    as={SearchIcon}
                    boxSize={5}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      dispatch(
                        fetchUser({
                          ...pagination,
                          keyword: _.trim(filters.keyword),
                        })
                      )
                    }
                  />
                </Tooltip>
              }
            />
          </Box>
          <Box>{_renderFilterOptions()}</Box>
        </Box>
        {_renderTags()}
      </Box>
    );
  };

  const _renderPopup = () => (
    <>
      <UserDialog.CreateUserDialog
        open={isShowPopup.create}
        onClose={() => handleShowPopup("create", false)}
      />
    </>
  );

  const _renderDatatable = () => {
    return (
      <DataTable.UserDataTable
        payload={sortedUsers}
        paginate={meta}
        onRowAction={onRowAction}
        onPageChange={onPageChange}
        onChangeLimit={onPageLimit}
        isLoading={isFetchLoading}
      />
    );
  };
  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.users")}
          </Heading>
          <Box
            sx={{
              width: "max-content",
            }}
          >
            {createUser && (
              <Button
                size="sm"
                rightIcon={<Icon as={AddIcon} boxSize={3} />}
                onClick={() => Utils.redirect(Routers.USER_CREATE)}
                //colorScheme="twitter"
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
              >
                {t("button.create")}
                {/*Create*/}
              </Button>
            )}
          </Box>
        </Box>
        <RoundedContainer>
          {_renderTopUser()}
          {_renderDatatable()}
        </RoundedContainer>
        {_renderPopup()}
        <ConfirmDialog
          isOpen={isShowPopup.delete}
          onClose={() => {
            setIsShowPopup({ ...isShowPopup, delete: false });
          }}
          body={t("message.doYouWantToDeleteUser")}
          onAction={() => onChangStatus("delete")}
          actionType={"delete"}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default UserList;
