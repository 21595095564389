import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import {
  Box,
  Heading,
  Text,
  Icon,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Portal,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import {
  EllipsisHorizontalIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import { ENUMS } from "@/Constants";
import { LogtimeActions } from "@/Actions";
import { useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import CommonColors from "@/Themes/CommonColors";
import { useTranslation } from "react-multi-lang";

const { performAction, updateLogtime } = LogtimeActions;

interface ISectionProps {
  card: any;
}

const CardItem: React.FC<ISectionProps> = ({ card }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const {
    // attributes,
    // listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: card.id, data: { ...card } });

  const userData = Utils.getSavedUserData();
  const projectType = card?.projectType;
  const userRoles = React.useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const dndKitCardStyles = {
    // touchAction: "none",
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
    //backgroundColor: !card?.placeholderCard ? "white" : "transparent",
    borderRadius: "4px",
    visibility: card?.placeholderCard ? "hidden" : "visible",
  };

  const _renderMoreOptions = (logId: string) => (
    <Popover>
      <PopoverTrigger>
        <Icon
          sx={{
            ml: "auto",
            cursor: "pointer",
            "&:hover": {
              bg: "gray.100",
              borderRadius: "50%",
            },
          }}
          as={EllipsisHorizontalIcon}
          boxSize={5}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            maxW: 200,
          }}
        >
          <PopoverArrow />
          <PopoverHeader>
            <Heading size="sm" fontWeight={600}>
              {t("label.moreOptions")}
            </Heading>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(performAction("editLog", logId));
                }}
                colorScheme="twitter"
                variant="outline"
                sx={{
                  color: "#5c6e6c",
                  //  background: "#5c6e6c",
                  border: "1px solid #5c6e6c",
                  "&:hover": {
                    background: "rgba(92, 110, 108,0.1)",
                  },
                }}
              >
                {t("button.edit")}
              </Button>
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(performAction("deleteLog", logId));
                }}
                colorScheme="red"
                variant="outline"
              >
                {t("button.delete")}
              </Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  const handleUpdateTaskStatus = (logId: string, isDone: boolean) =>
    card?.boardId && dispatch(updateLogtime(logId, { isDone }, card?.boardId));

  // const logCounts = _.countBy(card?.logtimes, "overTimeStatus");
  // const pendingLogsCount = logCounts[ENUMS.LOG_OVER_TIME_STATUS.PENDING] || 0;
  // const rejectLogsCount = logCounts[ENUMS.LOG_OVER_TIME_STATUS.REJECTED] || 0;

  // if (
  //   _.isEmpty(card?.logtimes) ||
  //   pendingLogsCount === _.size(card?.logtimes) ||
  //   rejectLogsCount === _.size(card?.logtimes)
  // )
  //   return;

  return (
    <Box
      ref={setNodeRef}
      sx={dndKitCardStyles}
      // {...attributes}
      // {...listeners}
      // onClick={(event) => {
      //   event.stopPropagation();
      //   handleShowPopupChange("detail", !isShowPopup.detail);
      // }}
    >
      <Box>
        {card?.placeholderCard ? (
          <Heading sx={{ fontWeight: 500, fontSize: "small" }}>
            {t("label.noDataToDisplay")}
          </Heading>
        ) : (
          <Box>
            {!_.isEmpty(card?.logtimes) &&
              _.map(card?.logtimes, (log, index) => {
                const targetUserRoles = _.map(
                  log?.user?.userRole,
                  (userRole) => userRole?.role?.roleCode
                );
                const canChangeLogStatus =
                  Utils.handleCheckLogTimePermissions(
                    userRoles,
                    targetUserRoles,
                    projectType
                  ) || log?.user?.id === userData?.id;

                return (
                  <Accordion
                    key={index}
                    defaultIndex={[0]}
                    allowMultiple
                    sx={{
                      background: "#fff",
                      mb: 2,
                      border: `2px solid ${
                        log?.isDone ? CommonColors.burntSienna : " transparent"
                      }`,
                    }}
                  >
                    <AccordionItem>
                      <AccordionButton
                        sx={{
                          p: 1,
                        }}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            size="sm"
                            src={card?.avatar}
                            name={card.name}
                          />

                          <Box sx={{ mt: log?.description ? 1 : 0, maxW: 220 }}>
                            <Heading fontSize="sm" fontWeight={600}>
                              {card.name}
                            </Heading>
                          </Box>
                        </Box>
                        <AccordionIcon />
                        {(userData?.id === log?.user?.id ||
                          canChangeLogStatus) && (
                          <Tooltip
                            label={
                              log?.isDone
                                ? t("message.confirmTaskUnCompleted")
                                : t("message.confirmTaskCompleted")
                            }
                          >
                            {
                              <IconButton
                                aria-label={""}
                                size="xs"
                                sx={{
                                  bg: "none",
                                  "&:hover": {
                                    bg: "none",
                                  },
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUpdateTaskStatus(log?.id, !log?.isDone);
                                }}
                              >
                                <CheckCircleIcon
                                  color={
                                    log?.isDone ? CommonColors.burntSienna : ""
                                  }
                                />
                              </IconButton>
                            }
                          </Tooltip>
                        )}
                      </AccordionButton>
                      <AccordionPanel
                        pb={2}
                        sx={{
                          p: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            ml: 2,
                            fontSize: 14,
                            fontWeight: 400,
                            wordWrap: "break-word",
                            maxWidth: 160,
                            ul: {
                              ml: 4,
                            },
                            ol: {
                              ml: 4,
                            },
                            "& a": {
                              color: "rgba(22, 117, 224,1)",
                              "&:hover": {
                                color: "rgba(22, 117, 224,0.8)",
                              },
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: log?.description ? log?.description : "--",
                          }}
                        />
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              w: 2.5,
                              h: 2.5,
                              borderRadius: "50%",
                              background:
                                log?.timeType ===
                                ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
                                  ? "#a6b7aa"
                                  : "#bb7154",
                            }}
                          />

                          <Text
                            fontSize="xs"
                            textDecoration={
                              log?.overTimeStatus ===
                              ENUMS.LOG_OVER_TIME_STATUS.REJECTED
                                ? "line-through"
                                : "default"
                            }
                          >
                            {log?.timeType ===
                            ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
                              ? dayjs(log.workTimeStartDate).format(
                                  "DD/MM/YYYY"
                                ) !==
                                dayjs(log.workTimeEndDate).format("DD/MM/YYYY")
                                ? `${dayjs(log.workTimeStartDate).format(
                                    "DD/MM/YYYY"
                                  )} - ${dayjs(log.workTimeEndDate).format(
                                    "DD/MM/YYYY"
                                  )}`
                                : dayjs(log.workTimeEndDate).format(
                                    "DD/MM/YYYY"
                                  )
                              : dayjs(log.date).format("DD/MM/YYYY")}
                            : {log.workingTime}h{" "}
                            {log.timeType ===
                              ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME && `(OT)`}
                          </Text>

                          {canChangeLogStatus &&
                            (log?.overTimeStatus ===
                              ENUMS.LOG_OVER_TIME_STATUS.PENDING ||
                              !log?.overTimeStatus) &&
                            _renderMoreOptions(log.id)}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardItem;
