import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ITaskNoteFilters } from "@/Interfaces/TaskNote.interface";

const { TASK_NOTE } = APIs;

export const fetchTaskNote = async (payload?: ITaskNoteFilters) => {
  return await request(TASK_NOTE.BASIC, "GET", payload);
};

export const createTaskNote = async (payload?: FormData) => {
  return await request(TASK_NOTE.BASIC, "POST_FORM_DATA", payload);
};

export const getTaskNoteById = async (id: string) => {
  return await request(`${TASK_NOTE.GET_BY_ID}/${id}`, "GET");
};

export const updateTaskNote = async (id: string, payload: FormData) => {
  return await request(`${TASK_NOTE.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const deleteTaskNote = async (id: string) => {
  return await request(`${TASK_NOTE.DELETE}/${id}`, "PUT");
};
