import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 0,
  limit: 0,
  year: new Date().getFullYear(),
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  LeaveDaysList: [],
  LeaveDaysFilterList: [],
  details: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_LEAVE_DAYS:
      return {
        ...initialState,
      };
    case ActionTypes.RESET_LEAVE_DAY_DETAILS:
      return {
        ...state,
        details: {},
      };
    case ActionTypes.SET_LEAVE_DAYS_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_LEAVE_DAYS_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    case ActionTypes.SET_LEAVE_DAYS_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isGetLoading: true,
      };
    case ActionTypes.SET_META_LEAVE_DAYS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_LEAVE_DAYS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };

    case ActionTypes.CREATE_LEAVE_DAYS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_LEAVE_DAYS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.FETCH_LEAVE_DAYS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        LeaveDaysList: payload,
      };
    case ActionTypes.FETCH_LEAVE_DAYS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.GET_BY_ID_LEAVE_DAYS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        details: payload,
      };
    case ActionTypes.GET_BY_ID_LEAVE_DAYS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
        details: {},
      };

    case ActionTypes.UPDATE_LEAVE_DAYS_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_LEAVE_DAYS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.FETCH_LEAVE_DAYS_FILTER_CREATE_SUCCESS:
      return {
        ...state,
        LeaveDaysFilterList: payload,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };

    default:
      return state;
  }
};
