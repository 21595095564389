import { Toast } from "@/Widgets";
import _ from "lodash";

import { ActionTypes } from "@/Constants";
import { IClientStructure, ICreateClient } from "@/Interfaces/Client.interface";
import { IPaginate } from "@/Types/Common.types";

import Utils from "@/Utils";
import APIs from "@/APIs";

const setClientFetchLoading = () => {
  return {
    type: ActionTypes.SET_CLIENT_FETCH_LOADING,
  };
};

const setClientGetLoading = () => {
  return {
    type: ActionTypes.SET_CLIENT_GET_LOADING,
  };
};

const setClientActionLoading = () => {
  return {
    type: ActionTypes.SET_CLIENT_ACTION_LOADING,
  };
};

const resetClientReducer = () => {
  return {
    type: ActionTypes.RESET_CLIENT_REDUCER,
  };
};

const resetStatusClient = () => {
  return {
    type: ActionTypes.RESET_STATUS_CLIENT,
  };
};

const setMetaClient = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_CLIENT,
    payload,
  };
};

const setPaginationClient = (payload?: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_CLIENT,
    payload,
  };
};

const fetchClientSuccess = (payload: IClientStructure) => {
  return {
    type: ActionTypes.FETCH_CLIENT_SUCCESS,
    payload,
  };
};

const fetchClientFail = () => {
  return {
    type: ActionTypes.FETCH_CLIENT_FAILURE,
  };
};

const fetchClient = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setClientFetchLoading());
    await APIs.fetchClient(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchClientFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaClient(_.get(result, "meta")));
          await dispatch(setPaginationClient(payload));
          await dispatch(fetchClientSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchClientFail());
      });
  };
};

const createClientSuccess = () => {
  return {
    type: ActionTypes.CREATE_CLIENT_SUCCESS,
  };
};

const createClientFail = () => {
  return {
    type: ActionTypes.CREATE_CLIENT_FAILURE,
  };
};

const createClient = (payload: ICreateClient) => {
  return async (dispatch: any) => {
    dispatch(setClientActionLoading());
    resetStatusClient();
    await APIs.createClient(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createClientFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createClientSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchClient());
        }
      })
      .catch(async (error) => {
        await dispatch(createClientFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdClientSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_CLIENT_SUCCESS,
    payload,
  };
};

const getByIdClientFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_CLIENT_FAILURE,
  };
};

const getByIdClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setClientGetLoading());
    await APIs.getClientById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdClientFail());
        else {
          dispatch(getByIdClientSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdClientFail());
      });
  };
};

const updateClientSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CLIENT_SUCCESS,
    payload,
  };
};

const updateClientFail = () => {
  return {
    type: ActionTypes.UPDATE_CLIENT_FAILURE,
  };
};

const updateClient = (id: string, payload: ICreateClient, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setClientFetchLoading());
    resetStatusClient();
    await APIs.updateClient(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateClientFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateClientSuccess(result));
          dispatch(fetchClient(pagination));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchClient());
        }
      })
      .catch(async (error) => {
        await dispatch(updateClientFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusClientSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_CLIENT_SUCCESS,
  };
};

const changeStatusClientFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_CLIENT_FAILURE,
  };
};

const changeStatusClient = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setClientActionLoading());
    await APIs.changeStatusClient(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusClientFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(changeStatusClientSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchClient());
        }
      })
      .catch(async (error: any) => {
        await dispatch(changeStatusClientFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  setClientFetchLoading,
  setClientGetLoading,
  setClientActionLoading,
  resetClientReducer,
  fetchClient,
  createClient,
  getByIdClient,
  updateClient,
  changeStatusClient,
};
