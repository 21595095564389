import { useEffect, useState, useMemo } from "react";
import { RoundedContainer } from "@/Components/Common";
import { AdminLayout } from "@/Components/DefaultLayout";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Switch,
  Text,
  List,
  ListItem,
  ListIcon,
  IconButton,
} from "@chakra-ui/react";
import {
  ArrowPathIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { BackupActions, MailReceiveActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import _ from "lodash";
import BackupDataTable from "@/Components/LayoutPart/DataTable/BackupDataTable";
import {
  ConfirmDialog,
  CreateFileUploadRestoreDialog,
  WarningBackupDialog,
  MailReceiveDialog,
} from "@/Components/Popup";
import { useTranslation } from "react-multi-lang";

const {
  fetchBackup,
  changstatusBackup,
  fetchBackupDatabase,
  getBackupDatabase,
  deleteDatabase,
} = BackupActions;

const { fetchMailReceive, resetMailReceiveReducer } = MailReceiveActions;

const BackupRestore = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [confirmChangeStatus, setConfirmChangeStatus] = useState<{
    open: boolean;
    type: string;
  }>({ open: false, type: "" });
  const [isShowPopupWarning, setIsShowPopupWarning] = useState<boolean>(false);
  const [confirmDelete, setConfirmCDelete] = useState<{
    open: boolean;
    id: string;
  }>({ open: false, id: "" });
  const [isShowUploadFile, setIsShowUploadFile] = useState<boolean>(false);
  const [isShowEditEmail, setIsShowEditEmail] = useState<boolean>(false);

  const backupStatus: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "backupStatus")
  );
  const backupDatabase: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "backupDatabase")
  );
  const isChangeStatusSuccess: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "isChangeStatusSuccess")
  );
  const pagination: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "pagination")
  );
  const isFetchLoading: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "isFetchLoading")
  );
  const isDeleteSuccess: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "isDeleteSuccess")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.BACKUP, "isActionLoading")
  );

  const mailReceive: any[] = useSelector((state: RootState) =>
    _.get(state.MAIL_RECEIVE, "payload")
  );

  const mailReceiveBackup = useMemo(
    () =>
      _.find(mailReceive, (item) => item?.type === "backup") || {
        type: "backup",
        email: "",
      },
    [mailReceive]
  );

  const meta = useSelector((state: RootState) => _.get(state.BACKUP, "meta"));

  const handleCreateBackup = () => {
    if (!backupStatus.status) {
      setIsShowPopupWarning(true);
    } else {
      dispatch(getBackupDatabase());
    }
  };

  const handleRestoreBackup = () => {
    if (!backupStatus.status) {
      setIsShowPopupWarning(true);
    } else {
      //  dispatch(restoreDatabase());
      setIsShowUploadFile(true);
    }
  };

  const onSwitchRemote = (value: boolean) => {
    if (value) {
      setConfirmChangeStatus({
        open: true,
        type: t("message.doYouWantToEnableMaintenanceMode"),
      });
    } else
      setConfirmChangeStatus({
        open: true,
        type: t("message.doYouWantToTurnOffMaintenanceMode"),
      });
  };

  const onRowAction = (e: any) => {
    if (e.id) {
      setConfirmCDelete({ open: true, id: e.id });
    }
  };

  const onChangeDelete = () => {
    if (confirmDelete?.id) {
      dispatch(deleteDatabase(confirmDelete.id, pagination));
    }
  };

  const onChangeRemote = () => {
    if (backupStatus?.status) {
      dispatch(changstatusBackup({ status: false }));
    } else dispatch(changstatusBackup({ status: true }));
  };

  const onPageChange = (value: number) =>
    dispatch(fetchBackupDatabase({ ...pagination, page: value }));
  const onChangeLimit = (limit: number) =>
    dispatch(fetchBackupDatabase({ ...pagination, page: 1, limit }));

  useEffect(() => {
    dispatch(fetchMailReceive());
    dispatch(fetchBackup());
    dispatch(fetchBackupDatabase({ page: 1, limit: 10 }));
    return () => {
      resetMailReceiveReducer();
    };
  }, []);

  useEffect(() => {
    if (isChangeStatusSuccess || isDeleteSuccess) {
      setConfirmChangeStatus({ open: false, type: "" });
      setConfirmCDelete({ open: false, id: "" });
    }
  }, [isChangeStatusSuccess, isDeleteSuccess]);

  useEffect(() => {
    if (isActionLoading) {
      setIsShowUploadFile(false);
    }
  }, [isActionLoading]);

  const _renderTable = () => {
    return (
      <Box>
        <BackupDataTable
          payload={backupDatabase}
          paginate={meta}
          onRowAction={(e) => onRowAction(e)}
          onChangeLimit={onChangeLimit}
          onPageChange={onPageChange}
          isLoading={isFetchLoading}
        />
      </Box>
    );
  };

  const _renderPopup = () => {
    return (
      <Box>
        <WarningBackupDialog
          open={isShowPopupWarning}
          onClose={() => setIsShowPopupWarning(false)}
        />
        <ConfirmDialog
          isOpen={confirmChangeStatus.open}
          onClose={() => {
            setConfirmChangeStatus({ ...confirmChangeStatus, open: false });
          }}
          body={confirmChangeStatus.type}
          onAction={() => onChangeRemote()}
          actionType={"save"}
        />
        <ConfirmDialog
          isOpen={confirmDelete.open}
          onClose={() => {
            setConfirmCDelete({ open: false, id: "" });
          }}
          body={t("message.doYouWantToDeleteThisBackup")}
          onAction={() => onChangeDelete()}
          actionType={"delete"}
        />
        <CreateFileUploadRestoreDialog
          open={isShowUploadFile}
          onClose={() => setIsShowUploadFile(false)}
        />
        <MailReceiveDialog
          open={isShowEditEmail}
          onClose={() => setIsShowEditEmail(false)}
          data={mailReceiveBackup}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            justifyContent: "space-between",
            alignItems: "flex-start",
            //flexWrap: "wrap",
            //flexWrap: "wrap",
          }}
        >
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.backupRestore")}
            </Heading>
          </HStack>
          <Box sx={{ display: "flex", gap: 3, w: "auto" }}>
            <Text sx={{ fontWeight: 700, fontSize: 16, w: "max-content" }}>
              {t("label.maintenanceMode")} :
            </Text>
            <Box
              sx={{
                "& .css-p27qcy[data-checked]": {
                  background: "#bb7154",
                },
              }}
            >
              <Switch
                isChecked={!_.isEmpty(backupStatus) && backupStatus.status}
                onChange={(e: any) => onSwitchRemote(e.target.checked)}
              />
            </Box>
          </Box>
        </Box>
        <List>
          <ListItem
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListIcon as={EnvelopeIcon} color="green.500" />
            <HStack>
              <Text
                size="md"
                sx={{
                  fontWeight: 500,
                }}
              >
                {t("label.emailToTeceiveFiles")}:{" "}
                <Box
                  as="span"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {mailReceiveBackup?.mail || "[Email Address]"}
                </Box>
              </Text>
              <IconButton
                size="xs"
                aria-label={""}
                onClick={() => setIsShowEditEmail(true)}
              >
                <Icon>
                  <PencilSquareIcon />
                </Icon>
              </IconButton>
            </HStack>
          </ListItem>
        </List>
        <Box sx={{ display: "flex", gap: 3, justifyContent: "flex-end" }}>
          <Button
            size="sm"
            //  colorScheme="whatsapp"
            leftIcon={<Icon as={AddIcon} boxSize={3} />}
            onClick={() => handleCreateBackup()}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
            isLoading={isActionLoading}
          >
            {t("button.createBackupFile")}
          </Button>
          <Button
            size="sm"
            //  colorScheme="whatsapp"
            onClick={() => handleRestoreBackup()}
            leftIcon={<Icon as={ArrowPathIcon} boxSize={5} />}
            isLoading={isActionLoading}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.restoreDatabase")}
          </Button>
        </Box>
        <RoundedContainer>{_renderTable()}</RoundedContainer>
        {_renderPopup()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};
export default BackupRestore;
