import { ActionTypes } from "@/Constants";
const setUserConnectSocket = (payload: any) => {
  return {
    type: ActionTypes.USER_CONNECT_SOCKET,
    payload,
  };
};

const setSidebarExpanded = (payload: boolean) => {
  return {
    type: ActionTypes.SET_SIDEBAR_EXPANDED,
    payload,
  };
};
const saveCurrentDateInScroller = (payload: string) => {
  return {
    type: ActionTypes.SAVE_CURRENT_DATE_IN_SCROLLER,
    payload,
  };
};

const resetSocketRecucer = () => {
  return {
    type: ActionTypes.RESET_SOCKET_REDUCER,
  };
};

export default {
  setUserConnectSocket,
  setSidebarExpanded,
  saveCurrentDateInScroller,
  resetSocketRecucer,
};
