import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
  Checkbox,
} from "@chakra-ui/react";

import {
  LogtimeActions,
  BoardActions,
  WorkingTimeActions,
  HolidayActions,
} from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { ENUMS, Routers } from "@/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Select, DateTimeRangePicker } from "@/Components/Common";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import Utils from "@/Utils";
import { ITaskStructure } from "@/Interfaces/Board.interface";
import TextEditor from "@/Components/Common/TextEditor";
import TextareaComponent from "@/Components/Common/Textarea";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";

const { createLogtime, createLogtimeInMyTime } = LogtimeActions;
const { getBoardById } = BoardActions;
const { fetchWorkingTime } = WorkingTimeActions;
const { fetchHoliday } = HolidayActions;
//const { fetchProjectsExteriorTimeLog } = ProjectExteriorActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_WORK_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME,
  timeline: ["", ""],
  workingTime: 0,
  date: "",
  description: "",
  user: "",
  note: "",
};

const CreateWorkingDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const { pathname } = useLocation();
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );
  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );
  const myTimePagination = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "myTimePagination")
  );

  const workingTimeOffice = useMemo(() => {
    const firstItem = workingTimeList[0];
    return {
      morningStart: Number(firstItem?.morningStart || 8),
      morningEnd: Number(firstItem?.morningEnd || 12),
      afternoonStart: Number(firstItem?.afternoonStart || 13.5),
      afternoonEnd: Number(firstItem?.afternoonEnd || 17.5),
    };
  }, [workingTimeList]);

  const ishasArtistRole = useMemo(
    () =>
      _.includes(userRoles, ENUMS.ROLES.ARTIST) &&
      !_.includes(userRoles, ENUMS.ROLES.LEADER) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER),
    [userData]
  );

  const ishasArtistRoleExterior = useMemo(
    () =>
      (_.includes(userRoles, ENUMS.ROLES.ARTIST) &&
        !_.includes(userRoles, ENUMS.ROLES.MANAGER)) ||
      (_.includes(userRoles, ENUMS.ROLES.LEADER) &&
        !_.includes(userRoles, ENUMS.ROLES.MANAGER)) ||
      (_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES) &&
        !_.includes(userRoles, ENUMS.ROLES.MANAGER) &&
        _.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES) &&
        !_.includes(userRoles, ENUMS.ROLES.MARKETING)),
    [userData]
  );

  const [validTimeRangeMsg, setValidTimeRangeMsg] = useState<string>("");
  useState<string>("");

  const workTimeSchema = yup
    .object()
    .shape({
      project: yup.string().trim().required(t("message.projectIsRequired")),
      task: yup.string().required(t("message.processIsRequired")),
      date: yup.string().notRequired(),
      timeline: yup
        .array()
        .of(yup.string().trim().required(t("message.timelineIsRequired")))
        .test(
          t("message.timeline-valid"),
          t("message.startTimeMustBeBeforeEndTime"),
          function (value) {
            const [startTime, endTime] = value as [string, string];

            if (!startTime || !endTime) {
              return true; // Allow null values, as they are handled by the individual date validations
            }

            // Check if start and end dates are the same
            const sameDate = dayjs(startTime).isSame(dayjs(endTime), "day");

            // If the dates are the same and the start time is 12:00 PM or later, return false (invalid)

            if (sameDate) {
              const formatStartTime = dayjs(startTime).format("HH");
              const formatEndTime = dayjs(endTime).format("HH");
              if (
                _.toNumber(formatEndTime) >= 12 &&
                _.toNumber(formatStartTime) === 12
              )
                return false;
            }

            // Otherwise, compare UTC Date objects
            return dayjs(startTime).isBefore(dayjs(endTime));
          }
        ),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeaValidNumber")),
      description: yup.string().notRequired(),
      note: yup.string().notRequired(),
      type: yup.string().notRequired(),
      user: yup.string().required(t("message.assigneeIsRequired")),
    })
    .required();

  type IWorkTime = yup.InferType<typeof workTimeSchema>;

  const {
    control: workTimeControl,
    handleSubmit: handleWorkTimeSubmit,
    formState: { errors: workTimeErrors },
    setValue: setWorkTimeValue,
    reset: resetWorkTime,
    watch: watchWorkTime,
  } = useForm({
    resolver: yupResolver(workTimeSchema),
    defaultValues: DEFAULT_WORK_TIME,
  });

  const watchWorkTimeProject = watchWorkTime("project") || "";

  const watchWorkingtime = watchWorkTime("workingTime") || "";

  const dispatch = useTypedDispatch();
  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const projects: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );

  const projectsExterior: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "payload")
  );

  const details: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "details")
  );

  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );

  const performAction: ITimeLogPerformAction = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const projectOptions = useMemo(
    () =>
      _.map(projects, (project) => ({
        label: `${project?.name} - ${project?.code}`,
        value: project?.id,
      })),
    [projects]
  );

  const projectExteriorOptions = useMemo(
    () =>
      _.map(projectsExterior, (project) => ({
        label: `${project?.name}-${project?.code}`,
        value: project?.id,
      })),
    [projectsExterior]
  );

  const projectType = useMemo(
    () =>
      performAction?.actionType === "logInternal"
        ? ENUMS.PROJECT_TYPE.INTERIOR
        : ENUMS.PROJECT_TYPE.EXTERIOR,
    [performAction?.actionType]
  );

  const findWorkTimeProccessOptions = useMemo(() => {
    const targetProjects =
      projectType === ENUMS.PROJECT_TYPE.EXTERIOR ? projectsExterior : projects;
    const foundProject: any = _.find(
      targetProjects,
      (project) => project.id === watchWorkTimeProject
    );

    if (foundProject) {
      const kanbanBoard = foundProject.kanbanBoard || {};
      const tasks = kanbanBoard.task || [];
      return _.map(tasks, (task: ITaskStructure) => ({
        value: task.id,
        label: task.title,
      }));
    }

    return [];
  }, [watchWorkTimeProject, projects, projectsExterior]);

  //check time

  useEffect(() => {
    if (_.isEmpty(workingTimeList)) dispatch(fetchWorkingTime());
  }, []);

  useEffect(() => {
    if (open) {
      const startDate = new Date();
      const endDate = new Date();
      const { morningStart, afternoonEnd } = workingTimeOffice;

      const { hour: morningStartHour, minute: morningStartMinute } =
        Utils.splitHourMinute(morningStart);
      const { hour: afternoonEndHour, minute: afternoonEndMinute } =
        Utils.splitHourMinute(afternoonEnd);

      startDate.setHours(morningStartHour);
      startDate.setMinutes(morningStartMinute);
      startDate.setSeconds(0);

      endDate.setHours(afternoonEndHour);
      endDate.setMinutes(afternoonEndMinute);
      endDate.setSeconds(0);
      setWorkTimeValue("timeline", [
        startDate.toISOString(),
        endDate.toISOString(),
      ]);
      setWorkTimeValue(
        "workingTime",
        Utils.calculateHourDifference(
          startDate.toISOString(),
          endDate.toISOString(),
          workingTimeOffice,
          holidaysList
        )
      );
    }
  }, [workingTimeOffice, open]);

  useEffect(() => {
    if (ishasArtistRole || pathname === Routers.MY_TIME) {
      setWorkTimeValue("user", userData?.id);
    }
  }, [pathname, userData]);

  useEffect(() => {
    if (
      (performAction?.actionType === "logExternal" &&
        ishasArtistRoleExterior) ||
      (performAction?.actionType === "logExternal" &&
        pathname === Routers.MY_TIME)
    ) {
      setWorkTimeValue("user", userData?.id);
      setWorkTimeValue(
        "type",
        performAction?.actionType === "logExternal"
          ? ENUMS.LOG_TIME_TYPE.EXTERNAL
          : ENUMS.LOG_TIME_TYPE.INTERNAL
      );
    }
  }, [pathname, userData, performAction]);

  const userOptions = useMemo(() => {
    const hasManagerRole = !!_.find(
      userRoles,
      (role) => role === ENUMS.ROLES.MANAGER
    );
    const hasLeaderRole = !!_.find(
      userRoles,
      (role) => role === ENUMS.ROLES.LEADER
    );

    const acceptUsers = _.map(boardDetails?.project?.projectMember, (user) => {
      const allowedRoles = [
        ENUMS.ROLES.ARTIST,
        ENUMS.ROLES.LEADER,
        ENUMS.ROLES.MANAGER,
      ];
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      const userHasManagerRole = _.find(
        user?.user?.userRole,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );

      if (hasLeaderRole && !hasManagerRole && userHasManagerRole) {
        return null;
      }
      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        // return {
        //   id: user.id,
        //   avatar: user?.userData?.avatar?.path,
        //   name: user?.userData?.fullName,
        //   email: user?.email,
        //   roles: user?.userRole,
        //   user,
        // };
        return {
          label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
          value: user?.user.id,
        };
      }
      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    return acceptUsers; // Filter out null values (users without allowed roles)
  }, [boardDetails, userRoles, userData]);

  const userOptionsExterior = useMemo(() => {
    //const hasManagerRole = !!_.find(
    //  userRoles,
    //  (role) => role === ENUMS.ROLES.MANAGER
    //);
    //const hasLeaderRole = !!_.find(
    //  userRoles,
    //  (role) => role === ENUMS.ROLES.LEADER
    //);
    const acceptUsers = _.map(details?.projectExteriorMember, (user) => {
      return {
        label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
        value: user?.user.id,
      };
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    return acceptUsers; // Filter out null values (users without allowed roles)
  }, [details, userRoles, userData]);

  const cancelRef = useRef<any>(null);

  useEffect(() => {
    if (performAction?.actionType === "logInternal") {
      if (performAction.id) {
        if (boardDetails?.project?.id) {
          setWorkTimeValue("project", boardDetails?.project?.id);
        }
        setWorkTimeValue("task", performAction.id);
      } else {
        if (performAction?.extraData?.projectId) {
          setWorkTimeValue("project", performAction?.extraData?.projectId);
        }
        if (performAction?.extraData?.processId) {
          setWorkTimeValue("task", performAction?.extraData?.processId);
        }
        if (performAction?.extraData?.userId) {
          setWorkTimeValue("user", performAction?.extraData?.userId);
        }
        if (performAction?.extraData?.date) {
          const startDate = new Date(performAction?.extraData?.date);
          const endDate = new Date(performAction?.extraData?.date);
          const { morningStart, afternoonEnd } = workingTimeOffice;

          const { hour: morningStartHour, minute: morningStartMinute } =
            Utils.splitHourMinute(morningStart);
          const { hour: afternoonEndHour, minute: afternoonEndMinute } =
            Utils.splitHourMinute(afternoonEnd);

          startDate.setHours(morningStartHour);
          startDate.setMinutes(morningStartMinute);
          startDate.setSeconds(0);

          endDate.setHours(afternoonEndHour);
          endDate.setMinutes(afternoonEndMinute);
          endDate.setSeconds(0);
          setWorkTimeValue("date", performAction?.extraData?.date);
          setWorkTimeValue("timeline", [
            startDate?.toISOString(),
            endDate?.toISOString(),
          ]);
          setWorkTimeValue(
            "workingTime",
            Utils.calculateHourDifference(
              startDate.toISOString(),
              endDate.toISOString(),
              workingTimeOffice,
              holidaysList
            )
          );
        }
      }
      setWorkTimeValue("type", ENUMS.LOG_TIME_TYPE.INTERNAL);
    }
    if (performAction?.actionType === "logExternal") {
      setWorkTimeValue("type", ENUMS.LOG_TIME_TYPE.EXTERNAL);
      if (performAction?.extraData?.date) {
        const startDate = new Date(performAction?.extraData?.date);
        const endDate = new Date(performAction?.extraData?.date);
        const { morningStart, afternoonEnd } = workingTimeOffice;
        const { hour: morningStartHour, minute: morningStartMinute } =
          Utils.splitHourMinute(morningStart);
        const { hour: afternoonEndHour, minute: afternoonEndMinute } =
          Utils.splitHourMinute(afternoonEnd);

        startDate.setHours(morningStartHour);
        startDate.setMinutes(morningStartMinute);
        startDate.setSeconds(0);

        endDate.setHours(afternoonEndHour);
        endDate.setMinutes(afternoonEndMinute);
        endDate.setSeconds(0);
        setWorkTimeValue("date", performAction?.extraData?.date);
        setWorkTimeValue("timeline", [
          startDate?.toISOString(),
          endDate?.toISOString(),
        ]);
        setWorkTimeValue(
          "workingTime",
          Utils.calculateHourDifference(
            startDate.toISOString(),
            endDate.toISOString(),
            workingTimeOffice,
            holidaysList
          )
        );
      }
      if (performAction?.extraData?.userId) {
        setWorkTimeValue("user", performAction?.extraData?.userId);
      }
    }
  }, [performAction, workingTimeOffice]);

  useEffect(() => {
    if (!_.isEmpty(projects)) {
    }
  }, [projects]);

  useEffect(() => {
    if (isCreateSuccess) {
      if (boardDetails?.id) dispatch(getBoardById(boardDetails?.id));
      onReset();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (!open) {
      onReset();
    }
  }, [open]);

  const onReset = () => {
    resetWorkTime();
    setValidTimeRangeMsg("");
    onClose();
  };

  const onWorkTimeSubmit = (data: IWorkTime) => {
    const formatTime = (date: Date) => {
      let hours: any = date?.getHours();
      let minutes: any = date?.getMinutes();
      let seconds: any = date?.getSeconds();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return `${hours}:${minutes}:${seconds}`;
    };

    const { timeline } = data;
    const workTimeStartDate = dayjs(timeline?.[0]).format("YYYY-MM-DD");
    const workTimeEndDate = dayjs(timeline?.[1]).format("YYYY-MM-DD");
    const workTimeStartTime = formatTime(new Date(timeline?.[0] as any));
    const workTimeEndTime = formatTime(new Date(timeline?.[1] as any));
    // const isValid = Utils.isValidTimeRange(
    //   timeline?.[0] || "",
    //   timeline?.[1] || "",
    //   workingTimeOffice
    // );
    if (
      _.isEmpty(workTimeErrors) &&
      _.toNumber(data.workingTime) > 0 &&
      // isValid &&
      !validTimeRangeMsg
    ) {
      if (pathname === Routers.MY_TIME) {
        dispatch(
          createLogtimeInMyTime(
            {
              ..._.omit(data, ["timeline"]),
              date: workTimeStartDate,
              workTimeStartDate,
              workTimeEndDate,
              workTimeStartTime,
              workTimeEndTime,
              workingTime: watchWorkingtime,
            },
            myTimePagination,
            () => onClose()
          )
        );
      } else {
        dispatch(
          createLogtime({
            ..._.omit(data, ["timeline"]),
            date: workTimeStartDate,
            workTimeStartDate,
            workTimeEndDate,
            workTimeStartTime,
            workTimeEndTime,
          })
        );
      }
    }
    // else {
    //   if (!isValid) {
    //     setValidTimeRangeMsg("Timeline invalid");
    //   }
    // }
  };

  useEffect(() => {
    if (_.isEmpty(holidaysList)) {
      dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    }
  }, []);

  const _renderWorktime = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Controller
        name="project"
        control={workTimeControl}
        render={({ field }) => (
          <Select
            displayChoose
            value={field.value || ""}
            onChange={(e) => {
              field.onChange(e.target.value);
              setWorkTimeValue("task", "");
            }}
            label={`${
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internalProject")
                : t("label.externalProject")
            } `}
            size="sm"
            options={
              projectType === ENUMS.PROJECT_TYPE.INTERIOR
                ? projectOptions
                : projectExteriorOptions
            }
            isRequired
            isError={!_.isEmpty(workTimeErrors.project?.message)}
            errorMessage={workTimeErrors?.project?.message}
          />
        )}
      />
      <Controller
        name="task"
        control={workTimeControl}
        render={({ field }) => (
          <Select
            displayChoose
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            label={t("label.process")}
            size="sm"
            options={findWorkTimeProccessOptions}
            isRequired
            isError={!_.isEmpty(workTimeErrors.task?.message)}
            errorMessage={workTimeErrors?.task?.message}
          />
        )}
      />
      <Box
        sx={{
          width: "max-content",
        }}
      >
        <Controller
          name="timeline"
          control={workTimeControl}
          render={({ field }) => (
            <DateTimeRangePicker
              isRequired
              label={t("label.timeline")}
              onDateChange={(newDate) => {
                field.onChange(newDate);
                // const isValid = Utils.isValidTimeRange(
                //   newDate[0],
                //   newDate[1],
                //   workingTimeOffice
                // );
                // const calculateWorkingTime = Utils.calculateHourDifference(
                //   newDate[0],
                //   newDate[1],
                //   workingTimeOffice,
                //   holidaysList
                // );
                // if (isValid)
                //   setWorkTimeValue("workingTime", calculateWorkingTime);
                // setValidTimeRangeMsg(isValid ? "" : "Timeline invalid");
              }}
              value={field.value}
              isError={
                !_.isEmpty(workTimeErrors.timeline) ||
                !_.isEmpty(validTimeRangeMsg)
              }
              errorMessage={
                workTimeErrors.timeline?.message ||
                workTimeErrors.timeline?.[0]?.message ||
                validTimeRangeMsg
              }
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        {/* <Controller
          name="date"
          control={workTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label="Date"
              value={field.value as any}
              onDateChange={(date) => field.onChange(date)}
              isError={!_.isEmpty(workTimeErrors.date?.message)}
              errorMessage={workTimeErrors.date?.message}
              isRequired
              disabledTitle
            />
          )}
        /> */}
        <Controller
          name="workingTime"
          control={workTimeControl}
          render={({ field }) => (
            <TextField
              value={field.value}
              size="sm"
              type="number"
              label={t("label.workedHours")}
              placeholder="0"
              onChange={(e) => field.onChange(e.target.value)}
              isError={!_.isEmpty(workTimeErrors.workingTime?.message)}
              errorMessage={workTimeErrors.workingTime?.message}
              isRequired
            />
          )}
        />
      </Box>
      <Box
      // sx={{
      //   display: "none",
      // }}
      >
        {pathname !== Routers.MY_TIME && (
          <Controller
            name="user"
            control={workTimeControl}
            render={({ field }) => (
              <>
                <Select
                  options={
                    !_.isEmpty(details) && !ishasArtistRoleExterior
                      ? userOptionsExterior
                      : userOptions
                  }
                  size="sm"
                  label={t("label.assigneeToMe")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  displayChoose
                  isRequired
                  isError={!_.isEmpty(workTimeErrors.user?.message)}
                  errorMessage={workTimeErrors.user?.message}
                />
                <Checkbox
                  isChecked={field.value === userData?.id}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? userData?.id : "");
                  }}
                >
                  <Text fontSize="sm">{t("label.assigneeToMe")}</Text>
                </Checkbox>
              </>
            )}
          />
        )}
      </Box>
      <Box>
        <Controller
          name="description"
          control={workTimeControl}
          render={({ field }) => (
            <TextEditor
              height={150}
              value={field?.value || ""}
              label={t("label.description")}
              containerSx={{ mb: 2 }}
              onChange={(e: any) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="note"
          control={workTimeControl}
          render={({ field }) => (
            <TextareaComponent
              placeholder={t("label.note")}
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.note")}
            />
          )}
        />
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="lg"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          {t("title.createWorkTime")}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            p: 4,
          }}
        >
          {_renderWorktime()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            p: 4,
            //pt: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button size="sm" ref={cancelRef} onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              onClick={handleWorkTimeSubmit(onWorkTimeSubmit)}
              ml={3}
              //colorScheme="twitter"
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                minW: 65,
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
              isDisabled={isActionLoading}
            >
              {t("button.create")}
            </Button>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateWorkingDialog;
