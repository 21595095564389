import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { ChatActions } from "@/Actions";
import PortentialChats from "@/Pages/MainPages/Chat/PortentialChats";
import { getSocket } from "@/Configs/socket.config";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const { fetchMessageChannel, fetchMessageNotificationSuccess } = ChatActions;

const Chat: React.FC = () => {
  const socket = getSocket();
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const notifications: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "notifications")
  );

  const potentialChats = useSelector((state: RootState) =>
    _.get(state.CHAT, "potentialChats")
  );

  const totalNotifications =
    (notifications?.private || 0) + (notifications?.group || 0);

  const [newMsgNoti, setNewMsgNoti] = useState<any>(null);

  const formatNotificationCount = (count: number) => {
    return count > 10 ? "9+" : count.toString();
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (_.isEmpty(potentialChats)) dispatch(fetchMessageChannel(false));
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_NOTIFICATION, (data) => {
      if (!_.isEmpty(data)) {
        setNewMsgNoti(data);
      }
    });
  }, []);

  useEffect(() => {
    if (newMsgNoti) {
      dispatch(fetchMessageNotificationSuccess(newMsgNoti));
      setNewMsgNoti(null);
    }
  }, [newMsgNoti]);

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
            // color: "#fff",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <ChatBubbleBottomCenterIcon width={30} height={30} />
            {totalNotifications > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "-7px",
                  right: 0,
                  fontSize: "12px",
                  borderRadius: "50%",
                  background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "17px",
                  height: "17px",
                }}
              >
                {
                  <Text
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: 600 }}
                  >
                    {formatNotificationCount(totalNotifications)}
                  </Text>
                }
              </Box>
            )}
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t("title.chat")}</PopoverHeader>
        <PopoverBody>
          <Tabs variant="enclosed" size="sm">
            <TabPanels>
              <TabPanel
                sx={{
                  maxHeight: 300,
                  overflow: "auto",
                  p: 0,
                }}
              >
                <PortentialChats
                  getForAppBar
                  onChannelClick={() => {
                    setIsOpen(false);
                  }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Chat;
