import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Box, Text, Avatar, Divider } from "@chakra-ui/react";
import { Tooltip, Stack } from "@chakra-ui/react";
import { Table } from "rsuite";

import Utils from "@/Utils";
import { RootState, useTypedDispatch } from "@/Store";

import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import { TimekeepingDialog } from "@/Components/Popup";
import { IUser } from "@/Interfaces/User.interface";
import { NoDataFound } from "@/Components/Common";
const { Column, HeaderCell, Cell } = Table;
import { UserActions } from "@/Actions";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
import { useWorkingDay } from "@/Helpers";

interface IDayData {
  id: string;
  date: string;
  time: string;
  type: string;
  note: string;
  user?: any;
}

interface IDataStructure {
  id: string;
  name: string;
  avatar?: string;
  timekeeping: IDayData[];
  date?: any;
  user?: any;
}

interface ISections {
  action?: string;
  keyword: string;
}
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const { performAction: userPerformAction, resetActionUserReducer } =
  UserActions;

const TableComponent: React.FC<ISections> = ({ action, keyword }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const [isOpenUpdate, setIsOpenUpdate] = useState<boolean>(false);
  const [dataUpdate, setDataUpdate] = useState<IDataStructure>({
    id: "",
    name: "",
    avatar: "",
    timekeeping: [],
    date: "",
  });
  const userData = Utils.getSavedUserData();

  const timekeeping = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "timekeepingList")
  );

  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "pagination")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isFetchLoading")
  );

  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isGetLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isActionLoading")
  );

  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const workingTimeList: any = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const workingDay: any = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );

  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const tableTimekeeping = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timekeeping",
    "tableTimekeeping"
  );

  const startDate = dayjs(pagination?.startDate);
  const endDate = dayjs(pagination?.endDate);
  const daysInMonth = Array.from(
    { length: startDate.daysInMonth() },
    (_, index) =>
      startDate.startOf("month").add(index, "day").format("YYYY-MM-DD")
  );

  const transformData = (
    timekeeping: any[],
    users: IUser[],
    keyword: string
  ): IDataStructure[] => {
    const data: IDataStructure[] = [];
    if (users?.length > 0 && tableTimekeeping) {
      _.forEach(users, (user) => {
        const userId = user.id;
        if (!_.isEmpty(pagination?.roleCode)) {
          const userRoles = _.map(
            user?.userRole,
            (userRole) => userRole?.role?.roleCode
          );
          if (
            !_.some(pagination?.roleCode, (roleCode) =>
              _.includes(userRoles, roleCode)
            )
          )
            return;
        }

        if (
          !_.isEmpty(pagination?.userId) &&
          !_.includes(pagination?.userId, userId)
        ) {
          return;
        }

        if (
          !_.isEmpty(pagination?.keyword) &&
          !_.includes(user?.userData?.fullName, pagination?.keyword)
        ) {
          return;
        }

        const employee = _.find(timekeeping, { id: userId });
        const userItem: IUser = employee ? { ...user, ...employee } : user;

        const employeeData: IDataStructure = {
          id: userItem?.id,
          name: userItem?.userData?.fullName,
          avatar: userItem?.userData?.avatar?.path,
          timekeeping: [],
          user: userItem,
        };

        _.forEach(userItem?.timekeeping, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            time: entry.time,
            type: entry.type,
            note: entry.note,
          };
          employeeData.timekeeping.push(dayData);
        });
        data.push(employeeData);
      });
    } else {
      _.forEach(timekeeping, (employee) => {
        const employeeData: IDataStructure = {
          id: employee.id,
          name: employee.userData.fullName,
          avatar: employee.userData?.avatar?.path,
          timekeeping: [],
        };
        _.forEach(employee.timekeeping, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            time: entry.time,
            type: entry.type,
            note: entry.note,
            user: employee?.user,
          };
          employeeData.timekeeping.push(dayData);
        });
        data.push(employeeData);
      });
    }

    const groupTimekeepingByDate: any = _.map(data, (employee) => {
      return {
        ...employee,
        timekeeping: _.chain(employee?.timekeeping)
          .groupBy("date")
          .map((value, key) => ({ date: key, timekeeping: value }))
          .value(),
      };
    });

    const filteredUsers = groupTimekeepingByDate.filter((user: any) => {
      const normalizedUserName = user.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const normalizedKeyword = keyword
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return normalizedUserName
        .toLowerCase()
        .includes(normalizedKeyword.toLowerCase());
    });

    return filteredUsers;
  };

  const transformedData = useMemo(() => {
    return transformData(timekeeping, users, keyword);
  }, [timekeeping, users, keyword]);

  useEffect(() => {
    return () => {
      dispatch(resetActionUserReducer());
    };
  }, []);

  const generateTableData = (startDate: any, endDate: any) => {
    const header = [];
    let currentDate = dayjs(startDate);

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      header.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return header;
  };

  const headerDates = generateTableData(startDate, endDate);

  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={transformedData}
        autoHeight={transformedData?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isFetchLoading || isGetLoading || isActionLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={80}
        bordered
        cellBordered
        wordWrap
        height={isMobile ? 400 : 550}
      >
        <Column
          minWidth={250}
          flexGrow={1}
          fixed={!isMobile}
          resizable
          verticalAlign="middle"
        >
          <HeaderCell style={HEADER_STYLE}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='rgba(0, 0, 0, .5)' vector-effect='non-scaling-stroke'/></svg>")`,
                backgroundSize: "100% 100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: 5,
                  fontSize: 17,
                }}
              >
                {t("table.date")}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "5px",
                  left: 5,
                  fontSize: 17,
                }}
              >
                {t("table.name")}
              </Box>
            </Box>
          </HeaderCell>
          <Cell dataKey="user.userData.fullName">
            {(rowData) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Tooltip label={rowData?.name} hasArrow>
                    <Avatar
                      size="xs"
                      sx={{
                        cursor: canViewUserProfile ? "pointer" : "default",
                      }}
                      name={rowData?.name}
                      src={rowData?.avatar}
                      onClick={() =>
                        canViewUserProfile &&
                        dispatch(
                          userPerformAction(
                            "viewProfile",
                            rowData?.id,
                            rowData?.user
                          )
                        )
                      }
                    />
                  </Tooltip>
                  <Text fontSize="sm" fontWeight={500}>
                    {rowData.name}
                  </Text>
                </Box>
              );
            }}
          </Cell>
        </Column>
        {_.map(action === "month" ? daysInMonth : headerDates, (day) => {
          const isWeekend =
            Utils.getDayOfWeek(day, "short") === "Sun" ||
            Utils.getDayOfWeek(day, "short") === "CN";
          const today = dayjs(dayjs().format("YYYY-MM-DD")).isSame(
            dayjs(day).format("YYYY-MM-DD")
          );

          return (
            <Column key={day} align="center" width={140}>
              <HeaderCell
                verticalAlign="center"
                style={{
                  ...HEADER_STYLE,
                  background: isWeekend
                    ? "rgb(234, 236,251,100)"
                    : today
                    ? "rgba(97, 149, 237, 0.5)"
                    : "inherit",
                  cursor: isWeekend ? "not-allowed" : "inherit",
                }}
              >
                <Stack
                  direction="column"
                  rowGap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box color={isWeekend ? "red" : "inherit"}>
                    {Utils.getDayOfWeek(day, "short")}
                  </Box>
                  <Box color={isWeekend ? "red" : "inherit"}>
                    {dayjs(day).get("date")}
                  </Box>
                </Stack>
              </HeaderCell>
              <Cell
                verticalAlign="center"
                style={{
                  background: isWeekend ? "rgb(234, 236,251,100)" : "inherit",
                  // cursor: isWeekend ? "not-allowed" : "inherit",
                }}
              >
                {(rowData) => {
                  // if (isWeekend) return null;
                  const matchingDay = _.find(
                    rowData?.timekeeping,
                    (dayData: any) =>
                      dayjs(dayjs(dayData.date).format("YYYY-MM-DD")).isSame(
                        dayjs(day).format("YYYY-MM-DD")
                      )
                  );

                  let timeCheckIn: number = 0;
                  let timeCheckInAffternoon: number = 0;

                  const resetSeconds = (timeString: any) => {
                    const timeParts = timeString.split(":");

                    const hours = parseInt(timeParts[0]);
                    const minutes = parseInt(timeParts[1]);

                    const date = new Date();
                    date.setHours(hours);
                    date.setMinutes(minutes);
                    date.setSeconds(0);

                    return `${date
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${date
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}:00`;
                  };

                  const currentDate = dayjs();
                  const formatCurrentDate = currentDate.format("YYYY-MM-DD");

                  const renderCheckIn = `${_.get(
                    _.find(
                      matchingDay?.timekeeping,
                      (timekeeping) => timekeeping.type === "check-in"
                    ),
                    "time"
                  )}`;

                  const renderCheckOut = `${_.get(
                    _.find(
                      matchingDay?.timekeeping,
                      (timekeeping) => timekeeping.type === "check-out"
                    ),
                    "time"
                  )}`;

                  const renderCheckInAffternoon = `${_.get(
                    _.find(
                      matchingDay?.timekeeping,
                      (timekeeping) => timekeeping.type === "check-in-afternoon"
                    ),
                    "time"
                  )}`;

                  const renderCheckOutAfternoon = `${_.get(
                    _.find(
                      matchingDay?.timekeeping,
                      (timekeeping) =>
                        timekeeping.type === "check-out-afternoon"
                    ),
                    "time"
                  )}`;

                  //  const currentTime = new Date();
                  //  const formatTime =
                  //    currentTime.getHours() +
                  //    ":" +
                  //    currentTime.getMinutes() +
                  //    ":" +
                  //    currentTime.getSeconds();

                  const currentTime = dayjs();
                  const formatTime = currentTime.format("HH:mm:ss");

                  const checkCurrentimeAfternoonEnd =
                    workingTimeList[0]?.afternoonEnd &&
                    formatTime >
                      Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

                  if (
                    renderCheckIn &&
                    renderCheckOut &&
                    renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined"
                  ) {
                    const TotalTimeCheckIn = Utils.calculateWorkingTime(
                      resetSeconds(renderCheckIn),
                      resetSeconds(renderCheckOut)
                    );
                    timeCheckIn = _.toNumber(TotalTimeCheckIn);
                  }

                  if (
                    renderCheckInAffternoon &&
                    renderCheckOutAfternoon &&
                    renderCheckInAffternoon !== "undefined" &&
                    renderCheckOutAfternoon !== "undefined"
                  ) {
                    const TotalTimeCheckIn = Utils.calculateWorkingTime(
                      resetSeconds(renderCheckInAffternoon),
                      resetSeconds(renderCheckOutAfternoon)
                    );

                    timeCheckInAffternoon = _.toNumber(TotalTimeCheckIn);
                  }
                  const hours = Math.floor(timeCheckInAffternoon + timeCheckIn);
                  const minutes = Math.round(
                    (timeCheckInAffternoon + timeCheckIn - hours) * 60
                  );

                  const endTimeAfternoon = Utils.convertToTime(
                    workingTimeList[0]?.afternoonEnd
                  ).slice(0, 5);
                  const startTimeAfternoon = Utils.convertToTime(
                    workingTimeList[0]?.afternoonStart
                  ).slice(0, 5);

                  const endTimeMorning = Utils.convertToTime(
                    workingTimeList[0]?.morningEnd
                  ).slice(0, 5);
                  const startTimeMorning = Utils.convertToTime(
                    workingTimeList[0]?.morningStart
                  ).slice(0, 5);

                  const morningKey = useWorkingDay.getDayAndPeriod(
                    dayjs(day).toDate(),
                    "Morning"
                  );
                  const afternoonKey = useWorkingDay.getDayAndPeriod(
                    dayjs(day).toDate(),
                    "Afternoon"
                  );
                  const checkMorning = workingDay[morningKey];
                  const checkAfternoon = workingDay[afternoonKey];

                  return (
                    <Stack
                      direction="row"
                      columnGap={1}
                      sx={{
                        fontWeight: 500,
                        display: "block",
                        position: "relative",
                      }}
                      onClick={() => {
                        setIsOpenUpdate(true);
                        if (matchingDay)
                          setDataUpdate({
                            ...matchingDay,
                            id: rowData.id,
                            name: rowData.name,
                          });
                        else {
                          setDataUpdate({
                            id: rowData.id,
                            name: rowData.name,
                            date: day,
                            timekeeping: [],
                          });
                        }
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            color:
                              renderCheckIn !== "undefined" &&
                              matchingDay &&
                              startTimeMorning < renderCheckIn.slice(0, 5)
                                ? "red"
                                : "#5c6e6c",
                          }}
                        >
                          {renderCheckIn !== "undefined" && matchingDay
                            ? renderCheckIn.slice(0, 5)
                            : "--"}
                        </Box>
                        <Box sx={{ mx: 1 }}>{" - "}</Box>
                        <Box
                          sx={{
                            color:
                              renderCheckOut !== "undefined" &&
                              matchingDay &&
                              endTimeMorning > renderCheckOut.slice(0, 5)
                                ? "red"
                                : "#bb7154",
                          }}
                        >
                          {!_.isEmpty(renderCheckOut) &&
                          matchingDay &&
                          renderCheckOut !== "undefined"
                            ? renderCheckOut.slice(0, 5)
                            : "--"}
                        </Box>
                      </Box>
                      <Divider
                        w="50%"
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            color:
                              renderCheckInAffternoon !== "undefined" &&
                              matchingDay &&
                              startTimeAfternoon <
                                renderCheckInAffternoon.slice(0, 5)
                                ? "red"
                                : "#5c6e6c",
                          }}
                        >
                          {renderCheckInAffternoon !== "undefined" &&
                          matchingDay
                            ? renderCheckInAffternoon.slice(0, 5)
                            : "--"}
                        </Box>
                        <Box sx={{ mx: 1 }}>{" - "}</Box>
                        <Box
                          sx={{
                            color:
                              matchingDay &&
                              renderCheckOutAfternoon !== "undefined" &&
                              endTimeAfternoon >
                                renderCheckOutAfternoon.slice(0, 5)
                                ? "red"
                                : "#bb7154",
                          }}
                        >
                          {!_.isEmpty(renderCheckOutAfternoon) &&
                          matchingDay &&
                          renderCheckOutAfternoon !== "undefined"
                            ? renderCheckOutAfternoon.slice(0, 5)
                            : "--"}
                        </Box>
                      </Box>
                      <Box textAlign={"center"}>
                        {day === formatCurrentDate ? (
                          checkCurrentimeAfternoonEnd ? (
                            (renderCheckIn === "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon === "undefined" &&
                              renderCheckOutAfternoon === "undefined" &&
                              checkMorning &&
                              checkAfternoon) ||
                            (renderCheckIn === "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon === "undefined" &&
                              renderCheckOutAfternoon === "undefined" &&
                              !checkMorning &&
                              checkAfternoon) ||
                            (renderCheckIn === "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon === "undefined" &&
                              renderCheckOutAfternoon === "undefined" &&
                              checkMorning &&
                              !checkAfternoon) ? (
                              <Text color={"#D2A96A"}>
                                {t("message.absent")}
                              </Text>
                            ) : (
                              <Box>
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut === "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon !== "undefined" && (
                                    <Text color={"#BB7154"}>
                                      {t("message.error")}
                                    </Text>
                                  )}
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut !== "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon === "undefined" && (
                                    <Text color={"#BB7154"}>
                                      {" "}
                                      {t("message.error")}
                                    </Text>
                                  )}
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut === "undefined" &&
                                  renderCheckInAffternoon === "undefined" &&
                                  renderCheckOutAfternoon === "undefined" && (
                                    <Text color={"#BB7154"}>
                                      {t("message.error")}
                                    </Text>
                                  )}
                                {renderCheckIn === "undefined" &&
                                  renderCheckOut === "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon === "undefined" && (
                                    <Text color={"#BB7154"}>
                                      {t("message.error")}
                                    </Text>
                                  )}
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut === "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon === "undefined" && (
                                    <Text color={"#BB7154"}>
                                      {t("message.error")}
                                    </Text>
                                  )}
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut !== "undefined" &&
                                  renderCheckInAffternoon === "undefined" &&
                                  renderCheckOutAfternoon === "undefined" && (
                                    <Text color={"#A6B7AA"}>
                                      {hours +
                                        t("label.HourG") +
                                        "  " +
                                        minutes +
                                        t("label.minutes")}
                                    </Text>
                                  )}
                                {renderCheckIn === "undefined" &&
                                  renderCheckOut === "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon !== "undefined" && (
                                    <Text color={"#A6B7AA"}>
                                      {hours +
                                        " " +
                                        t("label.HourG") +
                                        "  " +
                                        minutes +
                                        " " +
                                        t("label.minutes")}
                                    </Text>
                                  )}
                                {renderCheckIn !== "undefined" &&
                                  renderCheckOut !== "undefined" &&
                                  renderCheckInAffternoon !== "undefined" &&
                                  renderCheckOutAfternoon !== "undefined" && (
                                    <Text color={"#A6B7AA"}>
                                      {hours +
                                        " " +
                                        t("label.HourG") +
                                        "  " +
                                        minutes +
                                        " " +
                                        t("label.minutes")}
                                    </Text>
                                  )}

                                {/*{timeCheckIn + timeCheckInAffternoon > 0 ? (
                                  <Text color={"#A6B7AA"}>
                                    {hours + " hour " + minutes + " minute"}
                                  </Text>
                                ) : (
                                  day < formatCurrentDate && (
                                    <Text color={"#BB7154"}>Error</Text>
                                  )
                                )}*/}
                              </Box>
                            )
                          ) : (
                            ""
                          )
                        ) : (day < formatCurrentDate &&
                            renderCheckIn === "undefined" &&
                            renderCheckOut === "undefined" &&
                            renderCheckInAffternoon === "undefined" &&
                            renderCheckOutAfternoon === "undefined" &&
                            checkMorning &&
                            checkAfternoon) ||
                          (day < formatCurrentDate &&
                            renderCheckIn === "undefined" &&
                            renderCheckOut === "undefined" &&
                            renderCheckInAffternoon === "undefined" &&
                            renderCheckOutAfternoon === "undefined" &&
                            !checkMorning &&
                            checkAfternoon) ||
                          (day < formatCurrentDate &&
                            renderCheckIn === "undefined" &&
                            renderCheckOut === "undefined" &&
                            renderCheckInAffternoon === "undefined" &&
                            renderCheckOutAfternoon === "undefined" &&
                            checkMorning &&
                            !checkAfternoon) ? (
                          <Text color={"#D2A96A"}> {t("message.absent")}</Text>
                        ) : (
                          <Box>
                            {renderCheckIn !== "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon !== "undefined" &&
                              day < formatCurrentDate && (
                                <Text color={"#BB7154"}>
                                  {t("message.error")}
                                </Text>
                              )}
                            {renderCheckIn !== "undefined" &&
                              renderCheckOut !== "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon === "undefined" &&
                              day < formatCurrentDate && (
                                <Text color={"#BB7154"}>
                                  {t("message.error")}
                                </Text>
                              )}
                            {renderCheckIn !== "undefined" &&
                              renderCheckOut !== "undefined" &&
                              renderCheckInAffternoon === "undefined" &&
                              renderCheckOutAfternoon === "undefined" &&
                              day < formatCurrentDate && (
                                <Text color={"#A6B7AA"}>
                                  {hours +
                                    " " +
                                    t("label.HourG") +
                                    "  " +
                                    minutes +
                                    " " +
                                    t("label.minutes")}
                                </Text>
                              )}
                            {renderCheckIn === "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon !== "undefined" &&
                              day < formatCurrentDate && (
                                <Text color={"#A6B7AA"}>
                                  {hours +
                                    " " +
                                    t("label.HourG") +
                                    "  " +
                                    minutes +
                                    " " +
                                    t("label.minutes")}
                                </Text>
                              )}
                            {renderCheckIn !== "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon === "undefined" &&
                              renderCheckOutAfternoon === "undefined" && (
                                <Text color={"#BB7154"}>
                                  {t("message.error")}
                                </Text>
                              )}
                            {renderCheckIn === "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon === "undefined" && (
                                <Text color={"#BB7154"}>
                                  {t("message.error")}
                                </Text>
                              )}
                            {renderCheckIn !== "undefined" &&
                              renderCheckOut === "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon === "undefined" && (
                                <Text color={"#BB7154"}>
                                  {t("message.error")}
                                </Text>
                              )}

                            {renderCheckIn !== "undefined" &&
                              renderCheckOut !== "undefined" &&
                              renderCheckInAffternoon !== "undefined" &&
                              renderCheckOutAfternoon !== "undefined" &&
                              day < formatCurrentDate && (
                                <Text color={"#A6B7AA"}>
                                  {hours +
                                    t("label.HourG") +
                                    " " +
                                    minutes +
                                    " " +
                                    t("label.minutes")}
                                </Text>
                              )}

                            {/*{timeCheckIn + timeCheckInAffternoon > 0 ? (
                              <Text color={"#A6B7AA"}>
                                {hours + " hour " + minutes + " minute"}
                              </Text>
                            ) : (
                              day < formatCurrentDate && (
                                <Text color={"#BB7154"}>Error</Text>
                              )
                            )}*/}
                          </Box>
                        )}
                      </Box>
                    </Stack>
                  );
                }}
              </Cell>
            </Column>
          );
        })}
      </Table>
      <TimekeepingDialog.UpdateTimekeepingDialog
        open={isOpenUpdate}
        onClose={() => setIsOpenUpdate(false)}
        payload={dataUpdate}
      />
    </Box>
  );
};

export default TableComponent;
