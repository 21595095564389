import React, { useRef, useState } from "react";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Select,
  Heading,
  Input,
  Textarea,
  InputGroup,
  Stack,
  InputLeftAddon,
  InputRightElement,
  Grid,
  GridItem,
  Tabs,
} from "@chakra-ui/react";

import { UploadAvatar, UploadFile } from "@/Components/Common";
// import { saveAs } from "file-saver";

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const CreateUserDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [fileImage, setFile] = useState<File[]>([]);

  const cancelRef = useRef<any>(null);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  // const downloadImage = () => {
  //   saveAs("image_url", "image.jpg"); // Put your image URL here.
  // };

  const _renderInformantion = () => {
    return (
      <Box>
        <Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Email
            </Heading>
            <Input size="sm" placeholder="Email" />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Phone number
            </Heading>
            <InputGroup size={"sm"}>
              <InputLeftAddon children="+84" />
              <Input size="sm" type="tel" placeholder="phone number" />
            </InputGroup>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Password
            </Heading>
            <InputGroup size="sm">
              <Input
                pr="4.5rem"
                //type={show ? "text" : "password"}
                placeholder="Password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm">
                  {/*{show ? "Hide" : "Show"}*/}
                </Button>
              </InputRightElement>
            </InputGroup>
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Gender
            </Heading>
            <Select placeholder="Gender" size={"sm"}>
              <option value="option1">Male</option>
              <option value="option2">Female</option>
            </Select>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              FullName
            </Heading>
            <Input size="sm" placeholder="FullName" />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Birthday
            </Heading>
            <Input placeholder="Select Date and Time" size="sm" type="date" />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Role
            </Heading>
            <Select placeholder="Role" size={"sm"}>
              <option value="option1">Manager</option>
              <option value="option2">Leader</option>
            </Select>
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Staffcode
            </Heading>
            <Input size="sm" placeholder="Staffcode" />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Probation day
            </Heading>
            <Input placeholder="Probation day" size="sm" type="date" />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Official working day
            </Heading>
            <Input placeholder=" Official working day" size="sm" type="date" />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Contract expiration date
            </Heading>
            <Input
              placeholder=" Contract expiration date"
              size="sm"
              type="date"
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Bank number
            </Heading>
            <Input placeholder="Bank numbber" size="sm" />
          </GridItem>
          <GridItem colSpan={3}>
            <Grid templateColumns="repeat(6, 1fr)" gap={3}>
              <GridItem colSpan={6}>
                <Heading
                  fontSize="sm"
                  sx={{
                    mb: 2,
                  }}
                >
                  Insurance place
                </Heading>
                <Input placeholder="Insurance place" size="sm" />
              </GridItem>
              <GridItem colSpan={6}>
                <Heading
                  fontSize="sm"
                  sx={{
                    mb: 2,
                  }}
                >
                  Image
                </Heading>
                <UploadFile
                  direction="row"
                  labelSx={{ minWidth: "250px" }}
                  containerSx={{ mb: 2 }}
                  files={fileImage}
                  onFileChange={(newFile: any) => console.log(newFile)}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={3}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
              }}
            >
              Address
            </Heading>
            <Textarea placeholder="Address" size={"sm"} />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xl"
      motionPreset="slideInBottom"
    >
      <Tabs
        size="sm"
        index={tabIndex}
        onChange={handleTabsChange}
        variant="line"
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
            borderRadius: 0,
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 2px 1px -1px gray",
            }}
          >
            Create New User
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
            }}
          >
            <Stack>
              <UploadAvatar
                direction="row"
                labelSx={{ minWidth: "250px" }}
                containerSx={{ mb: 2 }}
                files={fileImage}
                onFileChange={(newFile) => setFile(newFile)}
              />
            </Stack>
            <Box>{_renderInformantion()}</Box>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              boxShadow: "0px -1px 1px gray",
            }}
          >
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={() => {}} ml={3} colorScheme="whatsapp">
              Create
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Tabs>
    </AlertDialog>
  );
};

export default CreateUserDialog;
