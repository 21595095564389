import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  Text,
  InputGroup,
  Select,
} from "@chakra-ui/react";
import {
  ArrowPathIcon,
  FunnelIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { RoundedContainer, TextField } from "@/Components/Common";
import { AdminLayout } from "@/Components/DefaultLayout";
import ClientsDataTable from "@/Components/LayoutPart/DataTable/ClientsDataTable";
import CreateClientDialog from "@/Components/Popup/Client/Create";
import { IClientStructure, IFilterClient } from "@/Interfaces/Client.interface";

import { useSelector } from "react-redux";

import { RootState, useTypedDispatch } from "@/Store";
import ClientActions from "@/Actions/Client.actions";
import { ConfirmDialog } from "@/Components/Popup";
import UpdateClientDialog from "@/Components/Popup/Client/Update";
import { SearchIcon } from "@chakra-ui/icons";
import { ENUMS } from "@/Constants";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

const { CLIENT_STATUS } = ENUMS;

const { fetchClient, changeStatusClient, resetClientReducer } = ClientActions;

const DEFAULT_FILTERS = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
};

const Clients: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    update: false,
  });

  const [clientDetail, setClientDetail] = useState<IClientStructure>({
    id: "",
    name: "",
    description: "",
    status: "",
  });

  const [confirmStatus, setConfirmStatus] = useState<{
    open: boolean;
    status: string;
    type: string;
    id: string;
  }>({ open: false, status: "", type: "", id: "" });
  const t = useTranslation();
  const payload: IClientStructure[] = useSelector((state: RootState) =>
    _.get(state.CLIENT, "clientList")
  );
  const meta = useSelector((state: RootState) => _.get(state.CLIENT, "meta"));
  const pagination: any = useSelector((state: RootState) =>
    _.get(state.CLIENT, "pagination")
  );
  const isFetchLoading: any = useSelector((state: RootState) =>
    _.get(state.CLIENT, "isFetchLoading")
  );
  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.CLIENT, "isChangeStatusSuccess")
  );

  const fieldGroupSize = useMemo(() => {
    return {
      base: "100%",
      sm: "100%",
      md: "48%",
      lg: "205",
      xl: "205",
      "2xl": "205",
    };
  }, []);

  const onSwitchStatus = (type: boolean, id: string) => {
    if (type)
      setConfirmStatus({
        open: true,
        status: "active",
        type: t("message.doYouWantToActiveClient"),
        id: id,
      });
    else
      setConfirmStatus({
        open: true,
        status: "inactive",
        type: t("message.doYouWantToInactiveClient"),
        id: id,
      });
  };

  const onChangeStatus = () => {
    if (confirmStatus?.id) {
      if (confirmStatus?.status === "inactive")
        dispatch(changeStatusClient(confirmStatus.id, { status: "inactive" }));
      else dispatch(changeStatusClient(confirmStatus.id, { status: "active" }));
    }
  };

  const onRowActionChange = (action: string, item: IClientStructure) => {
    if (action === "edit") {
      setClientDetail(item);
      handlePopupChange("update", true);
    }
  };

  const onPageChange = (value: number) =>
    dispatch(fetchClient({ ...pagination, page: value }));

  const onChangeLimit = (limit: number) =>
    dispatch(fetchClient({ ...pagination, page: 1, limit }));

  const handlePopupChange = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const handleFiltersChange = (
    key: string,
    value: string | string[] | Date[]
  ) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSubmit = () => {
    const resolvedFilters = Utils.removeEmptyFields(filters);
    if (resolvedFilters)
      dispatch(fetchClient(resolvedFilters as IFilterClient));
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchClient(DEFAULT_FILTERS));
  };

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName: any) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);
    dispatch(fetchClient(updatedPagination));
  };

  useEffect(() => {
    dispatch(fetchClient(DEFAULT_FILTERS));
    return () => {
      dispatch(resetClientReducer());
    };
  }, []);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setConfirmStatus({ open: false, status: "", type: "", id: "" });
    }
  }, [isChangeStatusSuccess]);

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        variant="solid"
        key={label}
        h="max-content"
      >
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (pagination?.keyword) {
      renderedTags.push(renderTag(pagination?.keyword, "keyword"));
    }
    if (pagination?.status) {
      renderedTags.push(renderTag(t(`status.${pagination.status}`), "status"));
    }

    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HStack>
          <HStack spacing={4}>
            <HStack spacing={1}>
              <Text fontSize="sm" fontWeight={500} color={"#5C6e6c"}>
                {t("label.filterBy")}:
              </Text>
              <HStack spacing={1} sx={{ textTransform: "capitalize" }}>
                {renderedTags}
              </HStack>
            </HStack>
          </HStack>
        </HStack>
      </Box>
    );
  };

  const _renderFilter = () => {
    return (
      <Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ maxW: fieldGroupSize }}>
              <TextField
                size="sm"
                placeholder={t("label.search")}
                value={filters.keyword}
                onChange={(e) => handleFiltersChange("keyword", e.target.value)}
                onKeyDown={(e) => {
                  const element = e.currentTarget as HTMLInputElement;
                  const value = element.value;
                  if (e.key === "Enter")
                    dispatch(
                      fetchClient({
                        ...filters,
                        keyword: _.trim(value),
                      })
                    );
                }}
                rightElement={
                  <Tooltip label={t("label.search")} hasArrow>
                    <Icon
                      as={SearchIcon}
                      boxSize={5}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        dispatch(
                          fetchClient({
                            ...filters,
                            keyword: _.trim(filters.keyword),
                          })
                        )
                      }
                    />
                  </Tooltip>
                }
              />
            </Box>
            <InputGroup size="sm" maxW={150} ml={2}>
              <Select
                sx={{ borderRadius: "10px" }}
                placeholder={t("label.status")}
                value={filters.status}
                onChange={(e: any) =>
                  handleFiltersChange("status", e.target.value)
                }
              >
                {_.map(
                  _.values(CLIENT_STATUS),
                  (status: any, index: number) => (
                    <option key={index} value={status}>
                      {t(`status.${status}`)}
                    </option>
                  )
                )}
              </Select>
            </InputGroup>
          </Box>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box>
              <Button
                size="sm"
                onClick={handleSubmit}
                //colorScheme="whatsapp"
                leftIcon={<Icon as={FunnelIcon} />}
                sx={{
                  color: "#fff",
                  background: "#D2A96A",
                  "&:hover": {
                    background: "#D2BD6A",
                  },
                }}
              >
                {t("button.filter")}
              </Button>
            </Box>
            <Box>
              <Button
                size="sm"
                onClick={handleReset}
                leftIcon={<Icon as={ArrowPathIcon} />}
                sx={{
                  color: "#fff",
                  background: "#bb7154",
                  "&:hover": {
                    background: "#Db9d97",
                  },
                }}
              >
                {t("button.reset")}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box mt={2} ml={2}>
          {_renderTags()}
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.clients")}
            </Heading>
            <Button
              size="sm"
              //  colorScheme="whatsapp"
              onClick={() => handlePopupChange("create", true)}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </HStack>
        </Box>
        <RoundedContainer>
          {_renderFilter()}
          <ClientsDataTable
            paginate={meta}
            payload={payload}
            onRowAction={onRowActionChange}
            onSwitchStatus={onSwitchStatus}
            onPageChange={onPageChange}
            isLoading={isFetchLoading}
            onChangeLimit={onChangeLimit}
          />
        </RoundedContainer>
        <CreateClientDialog
          onClose={() => {
            handlePopupChange("create", false);
          }}
          open={isShowPopup.create}
        />
        <UpdateClientDialog
          onClose={() => {
            handlePopupChange("update", false);
            setClientDetail({
              id: "",
              name: "",
              description: "",
              status: "",
            });
          }}
          open={isShowPopup.update}
          payload={clientDetail}
        />
        <ConfirmDialog
          isOpen={confirmStatus.open}
          onClose={() => {
            setConfirmStatus({ ...confirmStatus, open: false });
          }}
          body={confirmStatus.type}
          onAction={() => onChangeStatus()}
          actionType={confirmStatus.status === "active" ? "active" : "inactive"}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Clients;
