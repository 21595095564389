import { useEffect, useState } from "react";

import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";
import OverTimeOverview from "./Overtime";
import { OvertimeDialog } from "@/Components/Popup";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import _ from "lodash";
import AggregateOvertime from "./AggregateOvertime";
import Utils from "@/Utils";
import {
  LogtimeActions,
  ProjectActions,
  ProjectExteriorActions,
  WorkingDayActions,
} from "@/Actions";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import { ExportToExcelOvertime } from "@/Components/Export";
import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const { performAction, resetLogTimePerformAction } = LogtimeActions;
const { fetchProjectsExterior, resetProjectExteriorReducer } =
  ProjectExteriorActions;
const { resetProjectReducer, fetchProjects } = ProjectActions;
const { fetchWorkingDay, resetWorkingDayReducer } = WorkingDayActions;

const OverTime = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [openTable, setOpenTable] = useState<string>("overview");

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const viewTotalOvertime = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "totalOvertime"
  );

  const canCreateInternalLog = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LogTime",
    "createInternalLog"
  );
  const canViewScreenProjectExterior = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "canViewScreenProjectExterior"
  );

  const createInternalLogArtist = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LogTime",
    "createInternalLogArtist"
  );

  useEffect(() => {
    dispatch(fetchWorkingDay());

    return () => {
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    if (canViewScreenProjectExterior)
      dispatch(
        fetchProjectsExterior({
          page: 0,
          limit: 0,
        })
      );
    return () => {
      dispatch(resetProjectExteriorReducer());
      dispatch(resetProjectReducer());
    };
  }, [canViewScreenProjectExterior]);

  useEffect(() => {
    if (canCreateInternalLog)
      dispatch(
        fetchProjects({
          page: 0,
          limit: 0,
          type: ENUMS.PROJECT_TYPE.INTERIOR,
        })
      );
    return () => {
      dispatch(resetProjectReducer());
    };
  }, [canCreateInternalLog]);

  const _renderMoreOptions = () => (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sm"
          //  colorScheme="twitter"
          leftIcon={<Icon as={AddIcon} boxSize={3} />}
          sx={{
            color: "#fff",
            background: "#5c6e6c",
            "&:hover": {
              background: "#a6b7af",
            },
          }}
        >
          {t("button.newRequest")}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            maxW: 200,
          }}
        >
          <PopoverArrow />
          <PopoverHeader>
            <Heading size="sm">{t("label.moreOptions")}</Heading>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(performAction("logInternal", ""));
                }}
              >
                {t("button.internalProject")}
              </Button>
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(performAction("logExternal", ""));
                }}
              >
                {t("button.externalProject")}
              </Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.overtime")}
            </Heading>
            <Box sx={{ display: "flex" }}>
              {canCreateInternalLog ? (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box>
                    {openTable === "aggregate" && viewTotalOvertime && (
                      <ExportToExcelOvertime />
                    )}
                  </Box>
                  {createInternalLogArtist ? (
                    <>{_renderMoreOptions()}</>
                  ) : (
                    <Button
                      size="sm"
                      //  colorScheme="twitter"
                      leftIcon={<Icon as={AddIcon} boxSize={3} />}
                      sx={{
                        color: "#fff",
                        background: "#5c6e6c",
                        "&:hover": {
                          background: "#a6b7af",
                        },
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(performAction("logInternal", ""));
                      }}
                    >
                      {t("button.newRequest")}
                    </Button>
                  )}
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box>
                    <Box>
                      {openTable === "aggregate" && viewTotalOvertime && (
                        <ExportToExcelOvertime />
                      )}
                    </Box>
                  </Box>
                  <Button
                    colorScheme="twitter"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(performAction("logExternal", ""));
                    }}
                    leftIcon={<Icon as={AddIcon} boxSize={3} />}
                    sx={{
                      color: "#fff",
                      background: "#5c6e6c",
                      "&:hover": {
                        background: "#a6b7af",
                      },
                    }}
                  >
                    {t("button.newRequest")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <TabList
              sx={{
                width: "100%",
                mt: 2,
              }}
            >
              <Tab
                sx={{
                  w: 200,
                  fontSize: 16,
                }}
                _selected={{
                  color: "#bb7154",
                  fontWeight: 600,
                  borderBottom: "4px solid #bb7154",
                }}
                onClick={() => setOpenTable("overview")}
              >
                {t("button.overview")}
              </Tab>
              {viewTotalOvertime ? (
                <Tab
                  sx={{
                    w: 210,
                    fontSize: 16,
                  }}
                  _selected={{
                    color: "#bb7154",
                    fontWeight: 600,
                    borderBottom: "4px solid #bb7154",
                  }}
                  onClick={() => setOpenTable("aggregate")}
                >
                  {t("button.aggregateWithUsers")}
                </Tab>
              ) : (
                <Tab
                  sx={{
                    w: 200,
                    fontSize: 16,
                  }}
                  _selected={{
                    color: "#bb7154",
                    fontWeight: 600,
                    borderBottom: "4px solid #bb7154",
                  }}
                  onClick={() => setOpenTable("aggregate")}
                >
                  {t("button.aggregate")}
                </Tab>
              )}
            </TabList>
          </Box>
        </Box>
      </Stack>
    );
  };
  const renderMain = () => {
    return (
      <Tabs size={"sm"}>
        <Box>{_renderTopSection()}</Box>
        <TabPanels>
          <TabPanel pt={0}>
            <OverTimeOverview />
          </TabPanel>
          <TabPanel pt={0}>
            <AggregateOvertime />
          </TabPanel>
        </TabPanels>
        <OvertimeDialog.SendOvertimeDialog
          open={
            timeLogPerformAction?.actionType === "logExternal" ||
            timeLogPerformAction?.actionType === "logInternal"
          }
          onClose={() => dispatch(resetLogTimePerformAction())}
        />
      </Tabs>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default OverTime;
