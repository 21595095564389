import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Checkbox,
} from "@chakra-ui/react";
import { useTranslation } from "react-multi-lang";

interface SectionProps {
  open: boolean;
  onClose(): void;
  onChangeStatus: (action: string) => void;
  payload?: any;
}
const options = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const ChangeUserStatusDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  onChangeStatus,
  payload,
}) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const onClick = () => {
    onChangeStatus(selectedStatus);
    onClose();
  };

  const handleCheckboxChange = (value: string) => setSelectedStatus(value);

  useEffect(() => {
    if (payload) setSelectedStatus(payload.status);
  }, [payload]);

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ".chakra-checkbox__control": {
            borderRadius: "50%",
          },
        }}
      >
        {_.map(options, (option) => {
          return (
            <Checkbox
              key={option.value}
              isChecked={selectedStatus === option.value}
              onChange={() => handleCheckboxChange(option.value)}
              size="lg"
              colorScheme="teal"
              rounded={9999}
            >
              <Heading fontSize="sm">{t(`status.${option.value}`)}</Heading>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          {t("title.changeStatusUser")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            mx: "auto",
          }}
        >
          <Button ref={cancelRef} onClick={onClose} size="sm">
            {t("button.cancel")}
          </Button>
          <Button
            onClick={onClick}
            ml={3}
            //colorScheme="whatsapp"
            size="sm"
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.confirm")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChangeUserStatusDialog;
