import { useRef, useEffect, useState, memo, useMemo, RefObject } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import { useSelector } from "react-redux";
import { EventContentArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Box, Text, Checkbox, Heading, Tooltip } from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import CommonColors from "@/Themes/CommonColors";

import { calendarStyles, sharedStyles } from "./Calendar.styles";
import { TextComponent } from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { HolidayActions, ProjectActions } from "@/Actions";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  activeView: "week" | "month";
  visibleRange: {
    start: string;
    end: string;
  };
  isShowFullScreen?: boolean;
  minSlotWidthDate: number;
  onContainerWidth(width: number): void;
  isReset: boolean;
  onReset(): void;
}
const { fetchHoliday, resetHolidayReducer } = HolidayActions;
const { saveCurrentDateInScroller } = ProjectActions;

const Calendar: React.FC<ISectionProps> = ({
  visibleRange,
  isShowFullScreen,
  minSlotWidthDate,
  onContainerWidth,
  isReset,
  onReset,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const dispatch = useTypedDispatch();
  const language = Utils.getSavedLanguage();
  const t = useTranslation();

  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.PROJECT, "currentDateInScroller")
  );

  const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails, 2);

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );
  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const projectDetails = boardDetails?.project;

  const workingTimeOffice = useMemo(() => {
    return {
      morningStart: 8,
      morningEnd: 12,
      afternoonStart: 13.5,
      afternoonEnd: 17.5,
    };
  }, []);

  const timeline = useMemo(() => {
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails, 2);

    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);

    return dateRanges;
  }, [boardDetails]);

  const addedMembers = new Set();
  const addedProcess = new Set();
  const addedMemberProcess = new Set();

  const [events, setEvents] = useState<any[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [slotLabelDisplayType, setSlotLabelDisplayType] = useState<string>("");
  const [resouceAreaWidth, setResourceAreaWidth] = useState<number>(0);
  const [oldVisibleRange, setOldVisibleRange] = useState<{
    start: string;
    end: string;
  }>({ start: "", end: "" });

  const calendarRef = useRef<FullCalendar | any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const lastResourceId = useMemo(() => {
    const maxOrderResource = resources.reduce((maxOrderItem, currentItem) => {
      return currentItem.order > maxOrderItem.order
        ? currentItem
        : maxOrderItem;
    }, resources[0]);
    const currentProcessId = maxOrderResource?.id;
    const groupProcess = _.filter(resources, (resource) => {
      if (resource?.extendedProps?.type === "task-user") {
        const findTaskUsers = _.filter(resources, (res) =>
          _.includes(res?.id, currentProcessId)
        );

        return (
          _.last(findTaskUsers)?.id ===
          `${resource?.processId}=${resource?.userId}`
        );
      }
      return false;
    });
    return !_.isEmpty(groupProcess)
      ? _.last(groupProcess)?.id
      : maxOrderResource?.id;
  }, [resources]);

  const slotLabelFormats = useMemo(() => {
    const formats: any[] = [];
    formats.push({
      weekday:
        slotLabelDisplayType === "full" || minSlotWidthDate >= 30
          ? "short"
          : "narrow",
    });
    formats.push({ day: "2-digit" });
    return formats;
  }, [slotLabelDisplayType, minSlotWidthDate]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    _.delay(() => {
      calendarApi?.refetchEvents();
      calendarApi?.refetchResources();
    }, 500);
  }, [isShowFullScreen, isSidebarExpanded]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const { minStartDate } = Utils.findDateRange(boardDetails, 2);
    if (calendarApi && minStartDate) {
      requestAnimationFrame(() => {
        calendarApi.gotoDate(minStartDate);
      });
    }
  }, [boardDetails]);

  useEffect(() => {
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) - (resouceAreaWidth || 150);
    if (containerWidth > 0) onContainerWidth(containerWidth);
  }, [resouceAreaWidth]);

  const [isTooltipOpen, setIsTooltipOpen] = useState("");

  const handleLongPress = (id: any) => (e: any) => {
    e.stopPropagation();
    setIsTooltipOpen(id);
  };
  const handleTouchEnd = async () => {
    setIsTooltipOpen("");
  };
  const calendarCalendar = document.querySelector(".css-1lj3q15");
  useEffect(() => {
    const closeTooltip = async () => {
      setIsTooltipOpen("");
    };
    const calendarContainer = document.querySelector(".container");

    document.addEventListener("click", closeTooltip);
    if (calendarContainer) {
      calendarContainer.addEventListener("scroll", closeTooltip);
    }
    if (calendarCalendar) {
      calendarCalendar.addEventListener("scroll", closeTooltip);
    }
    return () => {
      document.removeEventListener("click", closeTooltip);
      if (calendarContainer) {
        calendarContainer.removeEventListener("scroll", closeTooltip);
      }
      if (calendarCalendar) {
        calendarCalendar.addEventListener("scroll", closeTooltip);
      }
    };
  }, [calendarCalendar]);

  useEffect(() => {
    if (!_.isEmpty(boardDetails) && !_.isEmpty(projectDetails)) {
      let eventData: any[] = [
        {
          id: projectDetails?.id,
          title: "OVERALL",
          start: projectDetails.startDate
            ? dayjs(dayjs(projectDetails.startDate)).startOf("day").toDate()
            : "",
          end: projectDetails.endDate
            ? dayjs(dayjs(projectDetails.endDate)).endOf("day").toDate()
            : "",
          resourceId: "overall",
          backgroundColor: CommonColors.lightOrange,
          extendedProps: {
            resourceId: "overall",
          },
        },
      ];
      let resourceData: any[] = [{ id: "overall", title: "OVERALL", order: 0 }];
      const colors = [
        "#5C6E6C", // balsamGreen
        "#A6B7AA", // aquatone
        // "#D2A96A", // artermis
        "#D39D87", // dustyCoral
        // "#BB7154", // warmCopper
      ];

      const userColors: any = {};
      const processColors: any = {};
      const projectMembers = projectDetails?.projectMember;

      const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails, 2);

      _.forEach(projectMembers, (member, memberIndex: number) => {
        const color = colors[memberIndex % colors.length];
        userColors[member?.user?.id as keyof typeof userColors] = color;
      });

      _.forEach(boardDetails?.task, (task, taskIndex: number) => {
        const backgroundColor = colors[taskIndex % colors.length];
        processColors[task?.id as keyof typeof processColors] = backgroundColor;
        let priority = 0;

        // const resourceTimelineStage = {
        //   id: `${task.id}-timeline`,
        //   title: task?.title,
        //   isShow: true,
        //   order: task?.order,
        // };

        if (!addedProcess.has(task?.id)) {
          addedProcess.add(task?.id);
        }

        resourceData.push({
          id: task?.id,
          title: task?.title,
          order: task?.order,
          isShow: true,
          processId: task?.id,
          type: "process",
          initiallyExpanded: true,
        });

        // resourceData.push(resourceTimelineStage);

        const eventTimelineStage = {
          id: task.id,
          title: `OVERALL - ${task?.title}`,
          start: dayjs(task?.timeLineStart).toDate(),
          end: dayjs(task?.timeLineEnd).toDate(),
          resourceId: task.id,
          extendedProps: {
            resourceId: task.id,
            type: "task",
            process: task,
          },
          backgroundColor: CommonColors.deepOrange,
          priority,
          display: "block",
        };

        eventData.push(eventTimelineStage);
        const countLogTime = countLogTimeForEachTask(
          task,
          new Date(minStartDate),
          new Date(maxEndDate)
        );

        _.forEach(task?.logTime, (log) => {
          const logType = log?.timeType;

          if (logType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
            if (!addedMembers.has(log.user?.id)) {
              addedMembers.add(log.user?.id);
            }
            const startDate = new Date(
              `${log?.workTimeStartDate}T${log?.workTimeStartTime}`
            );
            const endDate = new Date(
              `${log?.workTimeEndDate}T${log?.workTimeEndTime}`
            );

            if (!addedMemberProcess.has(`${task?.id}=${log.user?.id}`)) {
              const totalLogByUserId =
                countLogTime[`${task?.id}=${log.user?.id}`];
              resourceData.push({
                id: `${task?.id}=group`,
                // id: `${task?.id}=${log.user?.id}`,
                title: log.user?.userData?.fullName,
                isShow: false,
                parentId: task.id,
                projectId: projectDetails?.id,
                userId: log.user?.id,
                processId: task?.id,
                resourceEditable: false,
                extendedProps: {
                  type: "task-user",
                  totalLogByUserId,
                },
              });
              addedMemberProcess.add(`${task?.id}=${log.user?.id}`);
            }

            let event = {
              id: log.id,
              title: log.user?.userData?.fullName,
              start: startDate,
              end: endDate,
              resourceId: `${task?.id}=group`,
              // resourceId: `${task?.id}=${log.user?.id}`,
              extendedProps: {
                ...log,
                resourceId: log.user?.id,
                backgroundColor,
                borderColor: "inherit",
                type: "log",
              },
              backgroundColor: userColors[log.user?.id] || backgroundColor,
              borderColor: log?.isDone ? CommonColors.burntSienna : "inherit",
              priority: priority++,
            };
            eventData.push(event);
          }
        });
      });
      // Sort resourceData by the order field before setting the value for resources
      resourceData = _.sortBy(resourceData, "order");

      setEvents(eventData);
      setResources(resourceData);
    }
  }, [boardDetails, projectDetails]);

  useEffect(() => {
    if (containerRef?.current) {
      const type = calculateDisplayType();
      setSlotLabelDisplayType(type);
    }
  }, [resouceAreaWidth, visibleRange]);

  useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  useEffect(() => {
    scrollToTime();
  }, [oldVisibleRange, timeline]);

  useEffect(() => {
    const calendarEl = calendarRef?.current?.calendar?.el;
    const scroller: any = calendarEl.querySelectorAll("tfoot .fc-scroller")[1];
    const handleCalendarScroll = _.debounce(() => {
      const { scrollLeft } = scroller;
      const currentDay = Math.round(scrollLeft / minSlotWidthDate);
      if (timeline[currentDay]) {
        dispatch(saveCurrentDateInScroller(timeline[currentDay]));
      }
    }, 300);
    if (scroller) {
      scroller.addEventListener("scroll", handleCalendarScroll);
    }

    return () => {
      scroller?.removeEventListener("scroll", handleCalendarScroll);
    };
  }, [minSlotWidthDate, timeline]);

  useEffect(() => {
    const isSameVisibleRange =
      JSON.stringify(visibleRange) === JSON.stringify(oldVisibleRange);
    if (!isSameVisibleRange && visibleRange) {
      setOldVisibleRange(visibleRange);
    }
  }, [visibleRange]);

  useEffect(() => {
    if (isReset) {
      scrollToTime();
      onReset();
    }
  }, [isReset]);

  const scrollToTime = () => {
    const calendarApi = calendarRef?.current?.getApi();
    if (!_.isEmpty(oldVisibleRange) && calendarApi) {
      const midIndex = _.findIndex(
        timeline,
        (date) => date === currentDateInScroller || date === visibleRange.start
      );

      if (midIndex !== -1) {
        _.delay(() => {
          calendarApi.scrollToTime({
            days: midIndex,
          });
        }, 100);
      }
    }
  };

  const calculateDisplayType = () => {
    const numberOfDays =
      dayjs(visibleRange.end).diff(dayjs(visibleRange.start), "day") + 1;
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) - (resouceAreaWidth || 150);
    return Utils.calculateDisplayType(numberOfDays, containerWidth);
  };

  const isLastTaskUser = (resource: any, resources: any[]) => {
    const currentProcessId = _.split(resource?.id, "=")[0];
    if (resource?.extendedProps?.type === "task-user") {
      const findTaskUsers = _.filter(resources, (res) =>
        _.includes(res?.id, currentProcessId)
      );

      return (
        _.last(findTaskUsers)?.id ===
        `${resource?.extendedProps?.processId}=${resource?.extendedProps?.userId}`
      );
    }
    return false;
  };

  const isDateInRange = (date: Date, startDate: Date, endDate: Date) => {
    return dayjs(date).isBetween(dayjs(startDate), dayjs(endDate), null, "[]");
  };

  const isTimeRangeOverlapping = (
    start: Date,
    end: Date,
    startDate: Date,
    endDate: Date
  ) => {
    const isStartInRange = isDateInRange(start, startDate, endDate);
    const isEndInRange = isDateInRange(end, startDate, endDate);
    const isStartDateInRange = isDateInRange(startDate, start, end);
    const isEndDateInRange = isDateInRange(endDate, start, end);
    return (
      isStartInRange || isEndInRange || isStartDateInRange || isEndDateInRange
    );
  };

  const countLogTimeForEachTask = (
    task: any,
    startDate: Date,
    endDate: Date
  ) => {
    const countLogTime: any = {};

    _.forEach(task.logTime, (log) => {
      const taskId = task?.id;
      const workTimeStartDate = new Date(log?.workTimeStartDate);
      const workTimeEndDate = new Date(log?.workTimeEndDate);

      const isOverlap = isTimeRangeOverlapping(
        new Date(startDate),
        new Date(endDate),
        new Date(workTimeStartDate),
        new Date(workTimeEndDate)
      );

      const userId = log?.user ? log?.user?.id : null;

      if (taskId && userId && isOverlap) {
        countLogTime[`${taskId}=${userId}`] =
          (countLogTime[`${taskId}=${userId}`] || 0) + 1;
      }
    });
    return countLogTime;
  };

  const convertTime = (inputString: any) => {
    const updatedString =
      inputString &&
      inputString.replace(/days/g, "ngày").replace(/hours/g, "giờ");

    return updatedString;
  };

  const _renderEventContent = (eventInfo: EventContentArg) => {
    const { event } = eventInfo;
    const log = event?.extendedProps;
    const isTimelineProject = log?.resourceId === "overall";
    const process = log?.process;
    const firstTextContent = Utils.getFirstTextContent(log?.description);

    if (isTimelineProject) {
      return (
        <TextComponent
          sx={{
            color: "white",
            fontSize: isMobile ? 12 : "18px",
            p: isMobile ? 0 : 1.5,
          }}
          content={
            language === "en"
              ? `${projectDetails?.name} | ${projectDetails?.estimateWorkingTime}`
              : `${projectDetails?.name} | ${
                  projectDetails?.estimateWorkingTime &&
                  convertTime(projectDetails?.estimateWorkingTime)
                }`
          }
          maxW="100%"
        />
      );
    }

    if (!_.isEmpty(process)) {
      const calcHours = Utils.calculateHourDifference(
        process?.timeLineStart,
        process?.timeLineEnd,
        workingTimeOffice,
        holidaysList
      );

      const totalWorktime =
        workingTimeOffice.morningEnd -
        workingTimeOffice.morningStart +
        workingTimeOffice.afternoonEnd -
        workingTimeOffice.afternoonStart;

      const calcDays = Math.round(_.toNumber(calcHours / totalWorktime));

      // const calcDays = dayjs(process?.timeLineEnd).isSame(
      //   process?.timeLineStart
      // )
      //   ? dayjs(dayjs(process?.timeLineEnd).toDate()).diff(
      //       dayjs(process?.timeLineStart).toDate(),
      //       "day"
      //     )
      //   : dayjs(dayjs(process?.timeLineEnd)).diff(
      //       dayjs(process?.timeLineStart).toDate(),
      //       "day"
      //     );
      return (
        <TextComponent
          maxW="100%"
          content={`${process?.title} | ${calcDays || 0} ${t("label.days")}${
            calcDays > 1 ? "" : ""
          }`}
          sx={{
            color: "white",
            fontSize: isMobile ? 10 : "16px",
            p: isMobile ? 0 : 1,
            height: "100%",
            textTransform: "uppercase",
            verticalAlign: "middle",
          }}
        />
      );
    }
    const portalContainerRef = isShowFullScreen
      ? containerRef
      : (null as unknown as RefObject<HTMLElement | null>);

    return (
      <Tooltip
        hasArrow
        placement="auto"
        isOpen={isTooltipOpen === log?.id}
        portalProps={{ containerRef: portalContainerRef }}
        label={
          event?.extendedProps?.description ? (
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: event?.extendedProps?.description,
                }}
                sx={{
                  wordBreak: "break-word",
                  fontSize: isMobile ? 10 : "14px",
                }}
              />
            </Box>
          ) : (
            <Box sx={{ whiteSpace: "nowrap" }}>--</Box>
          )
        }
      >
        <Box
          onTouchStart={handleLongPress(log?.id)}
          onMouseEnter={handleLongPress(log?.id)}
          onMouseLeave={handleTouchEnd}
        >
          <Heading
            sx={{
              color: "white",
              fontSize: isMobile ? 10 : "12px",
              p: isMobile ? 0 : 0.5,
              display: "flex",
              width: "100%",
              position: "relative",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              WebkitLineClamp: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "nowrap",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: `calc(100% - ${log?.isDone ? "25px" : "0"})`,
              }}
            >
              <TextComponent
                fontSize="sm"
                maxW="100%"
                content={`${firstTextContent || "--"}`}
                showTooltip={false}
              />
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  ml: "auto",
                  position: "absolute",
                  right: 1,
                  top: "50%",
                  zIndex: 2,
                  transform: "translateY(-50%)",
                  "label.chakra-checkbox": {
                    borderColor: "#38A169",
                  },
                }}
              >
                {log?.isDone && (
                  <Checkbox defaultChecked colorScheme="green" isReadOnly />
                )}
              </Box>
            </Box>
          </Heading>
        </Box>
      </Tooltip>
    );
  };

  const _renderResourceContent = (resourceInfo: any) => {
    const { _resource } = resourceInfo.resource;

    const isHiddenTitle =
      _resource?.id === "overall" || !_resource?.extendedProps?.isShow;
    let isLastItem = false;
    const currentProcessId = _.split(_resource?.id, "=")[0];
    if (_resource?.extendedProps?.type === "task-user") {
      const findTaskUsers = _.filter(resources, (resource) =>
        _.includes(resource?.id, currentProcessId)
      );
      if (
        _.last(findTaskUsers)?.id ===
        `${_resource?.processId}=${_resource?.userid}`
      )
        isLastItem = true;
    }

    return !isHiddenTitle ? (
      <Text
        fontSize="sm"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {_resource?.title}
      </Text>
    ) : (
      <Box
        sx={{
          mb: isLastItem ? "10px" : 0,
        }}
      ></Box>
    );
  };

  const _renderCalendar = () => {
    return (
      <FullCalendar
        locale={language === "en" ? "en" : "vi"}
        scrollTimeReset={false}
        height="auto"
        ref={calendarRef}
        plugins={[interactionPlugin, resourceTimelinePlugin]}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        resourceAreaHeaderClassNames="custom-header"
        eventContent={_renderEventContent}
        events={events}
        resources={resources}
        rerenderDelay={2}
        resourceOrder={[
          "overall",
          ...resources?.map((resource) => resource.order),
        ]}
        resourceLabelContent={_renderResourceContent}
        resourcesInitiallyExpanded
        stickyFooterScrollbar
        stickyHeaderDates
        eventResizableFromStart
        firstDay={1}
        headerToolbar={false}
        editable={false}
        aspectRatio={1}
        initialView={"resourceTimelineMonth"}
        eventDisplay="block"
        eventOrder="priority"
        views={{
          resourceTimelineWeek: {
            slotDuration: { day: 1 },
            slotMinWidth: minSlotWidthDate,
            eventMinWidth: minSlotWidthDate,
            slotLabelFormat: slotLabelFormats,
            // hiddenDays: [0, 6] // Hide Sunday and Saturday?
            visibleRange: {
              start: minStartDate,
              end: maxEndDate,
            },
            duration: {
              days:
                dayjs(minStartDate).isValid() && dayjs(maxEndDate).isValid()
                  ? dayjs(maxEndDate).diff(dayjs(minStartDate), "day") + 1
                  : 7,
            },
          },
          resourceTimelineMonth: {
            slotDuration: { day: 1 },
            slotMinWidth: minSlotWidthDate,
            eventMinWidth: minSlotWidthDate,
            slotLabelFormat: slotLabelFormats,
            visibleRange: {
              start: minStartDate,
              end: maxEndDate,
            },
            duration: {
              days:
                dayjs(minStartDate).isValid() && dayjs(maxEndDate).isValid()
                  ? dayjs(maxEndDate).diff(dayjs(minStartDate), "day") + 1
                  : 30,
            },
          },
        }}
        resourceAreaWidth={isMobile ? 50 : 150}
        dayMaxEvents
        lazyFetching
        resourceLabelClassNames={(resourceInfo) => {
          const classNames = [];
          if (
            resourceInfo?.resource?._resource?.extendedProps?.type === "process"
          ) {
            classNames.push("has-border");
          }

          if (resourceInfo?.resource?._resource?.id === lastResourceId)
            classNames.push("last-resource-id");

          if (resourceInfo?.resource?._resource?.id === "overall") {
            classNames.push("overall");
          }

          const isLastItem = isLastTaskUser(
            resourceInfo?.resource?._resource,
            resources
          );

          if (isLastItem) {
            classNames.push("is-last-task");
          }

          if (
            resourceInfo?.resource?._resource?.extendedProps?.totalLogByUserId
          ) {
            classNames.push("fixed-height");
          }
          return classNames;
        }}
        resourceLaneClassNames={(slotContent) => {
          const classNames: string[] = [];
          if (
            slotContent?.resource?._resource?.extendedProps?.isShow ||
            slotContent?.resource?._resource?.id === "overall"
          ) {
            classNames.push("has-border");
          }

          if (slotContent?.resource?._resource?.id === "overall") {
            classNames.push("overall");
          }
          if (slotContent?.resource?._resource?.id === lastResourceId) {
            classNames.push("last-resource-id");
          }
          const isLastItem = isLastTaskUser(
            slotContent?.resource?._resource,
            resources
          );

          if (isLastItem) {
            classNames.push("is-last-task");
          }

          if (
            slotContent?.resource?._resource?.extendedProps?.totalLogByUserId
          ) {
            classNames.push("fixed-height");
          }
          return classNames;
        }}
        slotLabelClassNames={(slotContent) => {
          const classNames = [];
          const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
          const isHoliday = _.some(
            holidaysList,
            (holiday) => holiday?.day === slotDate
          );
          if (isHoliday) classNames.push("holiday");
          return classNames;
        }}
        slotLaneClassNames={(slotContent) => {
          const classNames = [];
          const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
          const isHoliday = _.some(
            holidaysList,
            (holiday) => holiday?.day === slotDate
          );
          if (isHoliday) classNames.push("holiday");
          return classNames;
        }}
        viewWillUnmount={() => {}}
        viewDidMount={(info) => {
          const resourcesEl: Element | any = info.el.querySelector(
            ".fc-scrollgrid > colgroup > col"
          );
          new ResizeObserver(() => {
            if (resourcesEl.clientWidth > 0) {
              setResourceAreaWidth(resourcesEl.clientWidth);
            }
          }).observe(resourcesEl);
        }}
      />
    );
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        ...calendarStyles,
        ...sharedStyles,
        height: isShowFullScreen ? "calc(100vh - 150px)" : "100%",
        "td.fc-resource-timeline-divider.fc-cell-shaded": {
          width: isMobile ? "7px" : "3px",
        },
        //The commented code you provided is used to hide unnecessary days when the container area is small.
        ".fc-timeline-header-row:last-of-type .fc-timeline-slot.fc-timeline-slot-label.fc-day:not(.fc-day-mon) a":
          {
            visibility:
              (slotLabelDisplayType === "firstDay" ||
                slotLabelDisplayType === "hide") &&
              minSlotWidthDate <= 20
                ? "hidden"
                : "visible",
          },
        ".fc .fc-timeline-slot-cushion": {
          fontSize:
            slotLabelDisplayType === "firstDay" ||
            slotLabelDisplayType === "short" ||
            slotLabelDisplayType === "hide"
              ? "10px"
              : "inherit",
        },
      }}
    >
      {_renderCalendar()}
    </Box>
  );
};

export default memo(Calendar);
