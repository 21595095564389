import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateUser } from "@/Interfaces/User.interface";

const { USER } = APIs;

export const fetchUser = async (payload: any) => {
  return request(USER.BASIC, "GET", payload);
};

export const getUserById = async (id: string) => {
  return request(`${USER.GET_BY_ID}/${id}`, "GET");
};

export const getForSelf = async () => {
  return request(USER.GET_FOR_SELF, "GET");
};

export const createUser = async (payload: FormData) => {
  return request(USER.CREATE, "POST_FORM_DATA", payload);
};

export const updateUser = async (id: string, payload: ICreateUser) => {
  return request(`${USER.UPDATE}/${id}`, "PUT_FORM_DATA", payload);
};

export const updateForSelf = async (payload: ICreateUser) => {
  return request(USER.UPDATE_FOR_SELF, "PUT_FORM_DATA", payload);
};

export const activateUser = async (id: string) => {
  return request(`${USER.ACTIVATE}/${id}`, "PUT");
};

export const deactivateUser = async (id: string) => {
  return request(`${USER.DEACTIVATE}/${id}`, "PUT");
};

export const updatePasswordForSelf = async (payload: {
  newPassword: string;
}) => {
  return request(USER.UPDATE_PASSWORD_FOR_SELF, "PUT", payload);
};

export const deleteUser = async (id: string) => {
  return request(`${USER.DELETE}/${id}`, "PUT");
};
