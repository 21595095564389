import React, { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { validate } from "uuid";
import { useSelector } from "react-redux";
import { SearchIcon } from "@chakra-ui/icons";

import {
  Button,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Icon,
  Box,
  Avatar,
  AvatarBadge,
  Text,
  SkeletonCircle,
  SkeletonText,
  Tooltip,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { ChatActions } from "@/Actions";
import CommonColors from "@/Themes/CommonColors";
import { useTypedDispatch, RootState } from "@/Store";
import Utils from "@/Utils";
import {
  IChannelStructure,
  IChatPerformAction,
} from "@/Interfaces/Chat.interface";

import noDataFound from "@/Assets/no_data.png";

const {
  performAction,
  // fetchMediaByChannelId,
  // resetNewChatData,
  fetchMessageChannel,
  readConversation,
  resetConversationChannelDetail,
  setNewPerformAction,
  setTypeChat,
} = ChatActions;

import { ChatDialog } from "@/Components/Popup";
import { ENUMS, Routers } from "@/Constants";
import { useWindowWidth } from "@/Helpers";
import { getSocket } from "@/Configs/socket.config";

import HighlightedText from "./HighlightText";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  getForAppBar?: boolean;
  onChannelClick?(): void;
}

const PortentialChats: React.FC<ISectionProps> = ({
  getForAppBar,
  onChannelClick,
}) => {
  const t = useTranslation();
  const socket = getSocket();
  const { pathname } = useLocation();
  const width = useWindowWidth();
  const isMobile = width <= 768;
  const dispatch = useTypedDispatch();
  const userLogged = Utils.getSavedUserData();
  const [searchParams, setSearchParams] = useSearchParams();
  const chatPerformAction: IChatPerformAction | any = useSelector(
    (state: RootState) => _.get(state.CHAT, "performAction")
  );
  const personalChats: IChannelStructure[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "personalChat")
  );
  const isFetchChannelLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isFetchChannelLoading")
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isFetchLoading")
  );
  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isGetLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isActionLoading")
  );
  const conversationChannelDetail: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "conversationChannelDetail")
  );
  const notifications: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "notifications")
  );

  const groupChats: IChannelStructure[] = useSelector((state: RootState) =>
    _.get(state.CHAT, "groupChat")
  );
  const newChatData: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "newChatData")
  );
  const userConnectSocket: any[] = useSelector((state: RootState) =>
    _.get(state.SOCKET, "userConnectSocket")
  );

  const typeChat: string =
    useSelector((state: RootState) => _.get(state.CHAT, "typeChat")) ||
    "private";

  const [isShowPopup, setIsShowPopup] = useState({
    createGroup: false,
    createChat: false,
    chatDetail: false,
  });

  const [searchPersonalValue, setSearchPersonalValue] = useState("");
  const [searchGroupValue, setSearchGroupValue] = useState("");

  const [filteredPersonals, setFilteredPersonals] = useState<any>([]);
  const [filteredGroups, setFilteredGroups] = useState<any>([]);
  const [newChannelInfo, setNewChannelInfo] = useState<any>(null);
  const [dataRemoveMember, setDataRemoveMember] = useState<any>(null);
  const [dataAddMember, setDataAddMember] = useState<any>(null);
  const [dataLeaveMember, setDataLeaveMember] = useState<any>(null);
  const [dataUpdateGroupAvatar, setDataUpdateGroupAvatar] = useState<any>(null);

  // Ref
  const personChatContainerRef = useRef<HTMLDivElement | null>(null);
  const groupChatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (_.isEmpty(chatPerformAction) && !isMobile) {
      if (typeChat === "private" && !_.isEmpty(personalChats)) {
        const firstChannel: any = _.first(personalChats);
        if (!_.isEmpty(firstChannel)) {
          const isMessageRead = firstChannel?.messageNotification?.notification;
          firstChannel?.messageNotification
            ? !firstChannel?.messageNotification?.notification
            : false;
          const channelId = firstChannel?.channel?.id;
          if (isMessageRead && channelId) {
            const formData = new FormData();
            formData.append("channelId", channelId);
            dispatch(readConversation(channelId, formData, () => {}));
          }
          dispatch(performAction("view", channelId, firstChannel));
        }
      }
      if (typeChat === "group" && !_.isEmpty(groupChats)) {
        const firstChannel: any = _.first(groupChats);
        const channelId = firstChannel?.channel?.id;

        if (!_.isEmpty(firstChannel) && channelId) {
          const isMessageRead = firstChannel?.messageNotification?.notification;
          firstChannel?.messageNotification
            ? !firstChannel?.messageNotification?.notification
            : false;
          const channelId = firstChannel?.channel?.id;
          if (isMessageRead) {
            const formData = new FormData();
            formData.append("channelId", channelId);
            dispatch(readConversation(channelId, formData, () => {}));
          }
          dispatch(performAction("view", channelId, firstChannel));
        }
      }
    }
  }, [personalChats, groupChats, isMobile, searchParams]);

  useEffect(() => {
    const channelId = searchParams.get("id");
    if (
      channelId &&
      validate(channelId) &&
      channelId !== chatPerformAction?.channelId
    ) {
      const foundChannel = _.find(
        groupChats,
        (group) => group?.channel?.id === channelId
      );
      if (foundChannel)
        dispatch(
          performAction("view", foundChannel?.channel?.id, foundChannel)
        );
    }
    if (channelId === chatPerformAction?.chatId) {
      searchParams.delete("id");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setFilteredPersonals(personalChats);
    setFilteredGroups(groupChats);
  }, [personalChats, groupChats]);

  useEffect(() => {
    // Check if conversationChannelDetail is not empty
    if (!_.isEmpty(conversationChannelDetail)) {
      const { channel } = conversationChannelDetail;
      // Check if channel type is "group"
      if (channel?.type === "group") {
        // Update filteredGroups array
        setFilteredGroups((prevFilteredGroups: IChannelStructure[]) => {
          // Map through the previous filteredGroups array
          return _.map(prevFilteredGroups, (group) => {
            // Check if the channel ID matches the group's channel ID
            if (group?.channel?.id === channel.id) {
              // Replace the group with conversationChannelDetail
              return conversationChannelDetail;
            }
            // Keep the group unchanged if IDs don't match
            return group;
          });
        });
      }
      // Check if channel type is "private"
      if (channel?.type === "private") {
        // Update filteredPersonals array
        setFilteredPersonals((prevFilteredPersonals: IChannelStructure[]) => {
          // Map through the previous filteredPersonals array
          return _.map(prevFilteredPersonals, (personal) => {
            // Check if the channel ID matches the personal's channel ID
            if (personal?.channel?.id === channel.id) {
              // Replace the personal with conversationChannelDetail
              return conversationChannelDetail;
            }
            // Keep the personal unchanged if IDs don't match
            return personal;
          });
        });
      }
      dispatch(resetConversationChannelDetail());
    }
  }, [conversationChannelDetail]);

  useEffect(() => {
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_CHANNEL, (data) => {
      if (!_.isEmpty(data)) {
        setNewChannelInfo(data);
        // dispatch(fetchMessageNotification());
      }
      // dispatch(fetchChannelSuccess(data));
      if (!_.isEmpty(newChatData))
        dispatch(performAction("view", "new-chat-data-placeholder"));
    });

    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_UPDATE_GROUP_AVATAR, (data: any) => {
      if (!_.isEmpty(data)) setDataUpdateGroupAvatar(data);
    });
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_CHANNEL_ADD_MEMBER, (data) => {
      if (!_.isEmpty(data)) setDataAddMember(data);
    });
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_CHANNEL_REMOVE_MEMBER, (data) => {
      if (!_.isEmpty(data)) setDataRemoveMember(data);
    });
    // socket.on(ENUMS.SOCKET_EVENT.MESSAGE_CHANNEL_LEAVE_GROUP, (data) => {
    //   if (!_.isEmpty(data)) setDataLeaveMember(data);
    // });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newChannelInfo)) {
      const { type } = newChannelInfo?.channel;
      const existingIndex =
        type === "private"
          ? _.findIndex(
              filteredPersonals,
              (item: IChannelStructure) =>
                item.channel.id === newChannelInfo.channel.id
            )
          : _.findIndex(
              filteredGroups,
              (item: IChannelStructure) =>
                item.channel.id === newChannelInfo.channel.id
            );
      if (existingIndex !== -1) {
        if (type === "private") {
          const updatedFilteredPersonals = [...filteredPersonals];
          updatedFilteredPersonals.splice(existingIndex, 1);
          updatedFilteredPersonals.unshift({
            ...newChannelInfo,
            messageNotification: {
              ...newChannelInfo?.messageNotification,
              notification:
                newChannelInfo?.channel?.id !== chatPerformAction?.chatId,
            },
          });
          setFilteredPersonals(updatedFilteredPersonals);
        }
        if (type === "group") {
          const updatedFilteredGroups = [...filteredGroups];
          updatedFilteredGroups.splice(existingIndex, 1);
          updatedFilteredGroups.unshift({
            ...newChannelInfo,
            messageNotification: {
              ...newChannelInfo?.messageNotification,
              notification:
                newChannelInfo?.channel?.id !== chatPerformAction?.chatId,
            },
          });
          setFilteredGroups(updatedFilteredGroups);
        }
      } else {
        // If the item doesn't exist, prepend it to the corresponding array
        if (type === "private") {
          setFilteredPersonals([newChannelInfo, ...filteredPersonals]);
        }
        if (type === "group") {
          setFilteredGroups([newChannelInfo, ...filteredGroups]);
        }
      }
    }
    setNewChannelInfo(null);
  }, [newChannelInfo]);

  // handle new member event
  useEffect(() => {
    if (!_.isEmpty(dataAddMember)) {
      const updatedFilteredGroups = [dataAddMember, ...groupChats];
      setFilteredGroups(updatedFilteredGroups);
    }
    setDataAddMember(null);
  }, [dataAddMember]);

  useEffect(() => {
    if (!_.isEmpty(dataRemoveMember)) {
      const channelIdToRemove = dataRemoveMember?.channel?.id;
      if (channelIdToRemove) {
        const updatedGroups = groupChats.filter(
          (group: any) => group?.channel?.id !== channelIdToRemove
        );
        setFilteredGroups(updatedGroups);
        if (chatPerformAction?.chatId === channelIdToRemove) {
          const indexToRemove = groupChats.findIndex(
            (group: any) => group?.channel?.id === channelIdToRemove
          );
          const { nextChat, prevChat } = Utils.getNextOrPrevChat(
            groupChats,
            indexToRemove
          );
          const nextChannelId = prevChat?.channel?.id || nextChat?.channel?.id;
          const nextChannelData = prevChat || nextChat;
          if (nextChannelId && nextChannelData)
            dispatch(performAction("view", nextChannelId, nextChannelData));
        }
      }
    }
    setDataRemoveMember(null);
  }, [dataRemoveMember]);

  useEffect(() => {
    if (!_.isEmpty(dataLeaveMember)) {
      const channelIdToRemove = dataRemoveMember?.channel?.id;
      if (channelIdToRemove) {
        const indexToRemove = groupChats.findIndex(
          (group: any) => group?.channel?.id === channelIdToRemove
        );
        if (indexToRemove !== -1) {
          const updatedGroups = [...groupChats];
          updatedGroups.splice(indexToRemove, 1);
          setFilteredGroups(updatedGroups);
          if (chatPerformAction?.chatId === channelIdToRemove) {
            const { nextChat, prevChat } = Utils.getNextOrPrevChat(
              groupChats,
              indexToRemove
            );
            const nextChannelId =
              prevChat?.channel?.id || nextChat?.channel?.id;
            const nextChannelData = prevChat || nextChat;
            if (nextChannelId && nextChannelData)
              dispatch(performAction("view", nextChannelId, nextChannelData));
          }
        }
      }
    }
    setDataLeaveMember(null);
  }, [dataLeaveMember]);

  useEffect(() => {
    if (!_.isEmpty(dataUpdateGroupAvatar)) {
      const channelId = dataUpdateGroupAvatar?.channel?.id;
      const findChannelIndex = _.findIndex(
        filteredGroups,
        (item: any) => item.channel.id === channelId
      );
      if (findChannelIndex !== -1) {
        // Create a copy of filteredGroups
        const updatedGroups = [...filteredGroups];
        // Replace the element at the found index with dataUpdateGroupAvatar
        updatedGroups.splice(findChannelIndex, 1, dataUpdateGroupAvatar);
        // Update the state with the modified array
        setFilteredGroups(updatedGroups);

        const newChatPerformAction = {
          ...chatPerformAction,
          extraData: dataUpdateGroupAvatar,
        };
        dispatch(setNewPerformAction(newChatPerformAction));
      }
    }
    setDataUpdateGroupAvatar(null);
  }, [dataUpdateGroupAvatar]);

  const handlePopupChange = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const handleSearchPersonalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchPersonalValue(event.target.value);
    const regex = new RegExp(_.escapeRegExp(value), "i");
    const filtered = !_.isEmpty(value)
      ? _.filter(filteredPersonals, (personalChat) => {
          const receiver = _.filter(
            personalChat?.channel?.messageChannelMember,
            (member) => member?.user?.id !== userLogged?.id
          )[0];

          const fullName = receiver?.user?.userData?.fullName;
          return regex.test(fullName);
        })
      : personalChats;

    setFilteredPersonals(filtered);
  };

  const handleSearchGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchGroupValue(event.target.value);
    const regex = new RegExp(_.escapeRegExp(value), "i");

    const filtered = !_.isEmpty(value)
      ? _.filter(filteredGroups, (groupChat) => {
          const title = groupChat?.channel?.title;
          return regex.test(title);
        })
      : groupChats;

    setFilteredGroups(filtered);
  };

  const handleReset = (type: "personal" | "group") => {
    if (type === "personal") {
      setSearchPersonalValue("");
      setFilteredPersonals(personalChats);
    }
    if (type === "group") {
      setSearchGroupValue("");
      setFilteredGroups(groupChats);
    }
  };

  const _renderSkeleton = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 2,
      }}
    >
      {_.times(10, (index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box>
            <SkeletonCircle size="10" />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <SkeletonText noOfLines={2} spacing="4" />
          </Box>
        </Box>
      ))}
    </Box>
  );

  const _renderPrivate = () => {
    switch (typeChat) {
      case "private":
        return _renderDataUserChat();
      case "group":
        return _renderDataGroupChat();
      default:
        return;
    }
  };

  const _renderChatPlaceholder = () => {
    const receiver = newChatData?.receiver;
    const channelId = newChatData?.id;
    const isReceiverOnline = _.some(
      userConnectSocket,
      (user) => user?.userId === receiver?.user?.id
    );
    const isActiveChat = chatPerformAction?.chatId === channelId;
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            transition: "all .3s ease-in-out",
            ":hover": {
              background: CommonColors.blueLight,
              borderLeft: `3px solid ${CommonColors.warmCopper}`,
            },
            background: isActiveChat ? CommonColors.blueLight : "inherit",
            borderLeft: `3px solid ${
              isActiveChat ? CommonColors.warmCopper : "transparent"
            }`,
            cursor: "pointer",
            p: 2,
            rounded: "sm",
          }}
          onClick={() => dispatch(performAction("view", channelId))}
        >
          <Avatar src={receiver?.avatar} size="sm" key={receiver?.id}>
            <AvatarBadge
              boxSize={3}
              bg={`${isReceiverOnline ? "green" : "gray"}.500`}
            />
          </Avatar>
          <Box sx={{ maxWidth: 200 }}>
            <Box sx={{ ml: 2 }}>
              <Text sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                {receiver?.name || "User"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderDataUserChat = () => {
    return (
      <Box ref={personChatContainerRef}>
        {!isFetchChannelLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!_.isEmpty(newChatData) &&
              newChatData?.messageType === "personal" &&
              _renderChatPlaceholder()}
            {/* Chat Item */}
            {!_.isEmpty(filteredPersonals)
              ? _.map(filteredPersonals, (personalChat) => {
                  const isSender =
                    personalChat?.lastMessage?.userCreated?.id ===
                    userLogged?.id;

                  const receiver = _.filter(
                    personalChat?.channel?.messageChannelMember,
                    (member) => member?.user?.id !== userLogged?.id
                  )[0];
                  const isReceiverOnline = _.some(
                    userConnectSocket,
                    (user) => user?.userId === receiver?.user?.id
                  );
                  const channelId = personalChat?.channel?.id;
                  const lastestMessage = personalChat?.lastMessage;
                  const isActiveChat = chatPerformAction?.chatId === channelId;
                  const isMessageRead = personalChat?.messageNotification
                    ? !personalChat?.messageNotification?.notification
                    : false;
                  return (
                    <Box key={`${personalChat?.channel?.id}-${getForAppBar}`}>
                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          transition: "all .3s ease-in-out",
                          ":hover": {
                            background: CommonColors.blueLight,
                            borderLeft: `3px solid ${CommonColors.dustyCoral}`,
                          },
                          background: isActiveChat
                            ? CommonColors.blueLight
                            : "inherit",
                          borderLeft: `3px solid ${
                            isActiveChat
                              ? CommonColors.warmCopper
                              : "transparent"
                          }`,
                          cursor: "pointer",
                          p: 2,
                          borderBottom: "1px solid #ddd",
                        }}
                        onClick={() => {
                          if (
                            !isFetchChannelLoading &&
                            !isFetchLoading &&
                            !isGetLoading &&
                            !isActionLoading
                          ) {
                            onChannelClick && onChannelClick();
                            if (chatPerformAction?.chatId !== channelId) {
                              dispatch(
                                performAction("view", channelId, personalChat)
                              );
                              if (!isMessageRead && channelId) {
                                const formData = new FormData();
                                formData.append("channelId", channelId);
                                dispatch(
                                  readConversation(channelId, formData, () => {
                                    //handle readConversation success
                                    // Find the item in filteredPersonals with the matching channelId
                                    // const index = filteredPersonals.findIndex(
                                    //   (item: any) => item.channel.id === channelId
                                    // );
                                    // // If the item is found, update its messageNotification field to true
                                    // if (index !== -1) {
                                    //   const updatedFilteredPersonals = [
                                    //     ...filteredPersonals,
                                    //   ];
                                    //   updatedFilteredPersonals[index] = {
                                    //     ...updatedFilteredPersonals[index],
                                    //     messageNotification: {
                                    //       ...updatedFilteredPersonals[index]
                                    //         ?.messageNotification,
                                    //       notification: false,
                                    //     },
                                    //   };
                                    //   setFilteredPersonals(
                                    //     updatedFilteredPersonals
                                    //   );
                                    // }
                                    dispatch(fetchMessageChannel(false));
                                  })
                                );
                              }
                            }
                            if (pathname !== Routers.CHAT) {
                              if (typeChat === "group")
                                dispatch(setTypeChat("private"));
                              Utils.redirect(Routers.CHAT);
                            }
                          }
                        }}
                      >
                        <Box
                          sx={{
                            mr: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              w: "8px",
                              h: "8px",
                              bg:
                                isMessageRead || isSender
                                  ? "rgba(187, 113, 84,0.2)"
                                  : "rgba(187, 113, 84,0.8)",
                              rounded: "full",
                            }}
                          />
                        </Box>
                        <Avatar
                          size="sm"
                          src={receiver?.user?.userData?.avatar?.path}
                          key={receiver?.user?.id}
                        >
                          <AvatarBadge
                            borderColor="papayawhip"
                            boxSize={3}
                            bg={`${isReceiverOnline ? "green.500" : "tomato"}`}
                          />
                        </Avatar>
                        <Box sx={{ maxWidth: 200 }}>
                          <Box sx={{ ml: 2 }}>
                            <Text
                              sx={{ fontSize: "0.875rem", fontWeight: 600 }}
                            >
                              {receiver?.user?.userData?.fullName ||
                                `User doesn't exist`}
                            </Text>
                            <Text
                              fontSize="xs"
                              sx={{
                                fontWeight:
                                  isSender || isMessageRead ? 400 : 600,
                                color:
                                  isSender || isMessageRead ? "#333" : "#000",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                              }}
                            >
                              {!lastestMessage?.userCreated
                                ? lastestMessage?.message
                                : `${isSender ? `${t("label.you")}:` : ""} ${
                                    !lastestMessage?.recalled
                                      ? Utils.isJsonString(
                                          lastestMessage?.message
                                        )
                                        ? "send a gif"
                                        : lastestMessage?.message
                                        ? lastestMessage?.message
                                        : !_.isEmpty(
                                            lastestMessage?.fileAttachment
                                          )
                                        ? `has send ${
                                            _.size(
                                              lastestMessage?.fileAttachment
                                            ) > 1
                                              ? `${_.size(
                                                  lastestMessage?.fileAttachment
                                                )} files`
                                              : "a file"
                                          }`
                                        : ""
                                      : "has recalled a message"
                                  }`}
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            ml: "auto",
                          }}
                        >
                          <Text fontSize="xs">
                            {Utils.resolveTimeAgo(lastestMessage?.createdAt)}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              : _.isEmpty(newChatData) && <Image src={noDataFound} />}
          </Box>
        ) : (
          _renderSkeleton()
        )}
      </Box>
    );
  };

  const _renderDataGroupChat = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        ref={groupChatContainerRef}
      >
        {!isFetchChannelLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Chat Item */}
            {!_.isEmpty(filteredGroups)
              ? _.map(filteredGroups, (groupChat) => {
                  const isSender =
                    groupChat?.lastMessage?.userCreated?.id === userLogged?.id;

                  const channelId = groupChat?.channel?.id;
                  const lastestMessage = groupChat?.lastMessage;
                  const isActiveChat = chatPerformAction?.chatId === channelId;
                  const isMessageRead = isSender
                    ? true
                    : groupChat?.messageNotification
                    ? !groupChat?.messageNotification?.notification
                    : false;

                  const members = _.map(
                    groupChat?.channel?.messageChannelMember,
                    (member) => {
                      return {
                        id: member?.user?.id,
                        display: member?.user?.userData?.fullName,
                        avatar: member?.user?.userData?.avatar?.path,
                      };
                    }
                  );

                  // const memberIds = _.map(
                  //   members,
                  //   (member) => member?.user?.id
                  // );
                  const isReceiverOnline = true;

                  return (
                    <Box key={groupChat?.channel?.id}>
                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          transition: "all .3s ease-in-out",
                          ":hover": {
                            background: CommonColors.blueLight,
                            ":hover": {
                              background: CommonColors.blueLight,
                              borderLeft: `3px solid ${CommonColors.dustyCoral}`,
                            },
                          },
                          background: isActiveChat
                            ? CommonColors.blueLight
                            : "inherit",
                          borderLeft: `3px solid ${
                            isActiveChat
                              ? CommonColors.warmCopper
                              : "transparent"
                          }`,
                          cursor: "pointer",
                          p: 2,
                          borderBottom: "1px solid #ddd",
                          rounded: "sm",
                        }}
                        onClick={() => {
                          if (
                            !isFetchChannelLoading &&
                            !isFetchChannelLoading &&
                            !isGetLoading &&
                            !isActionLoading
                          ) {
                            onChannelClick && onChannelClick();
                            if (chatPerformAction?.chatId !== channelId) {
                              if (!isMessageRead && channelId) {
                                const formData = new FormData();
                                formData.append("channelId", channelId);
                                dispatch(
                                  readConversation(channelId, formData, () => {
                                    //handle readConversation success
                                    // Find the item in filteredGroups with the matching channelId
                                    // const index = groupChats.findIndex(
                                    //   (item: any) => item.channel.id === channelId
                                    // );
                                    // // If the item is found, update its messageNotification field to true
                                    // if (index !== -1) {
                                    //   const updatedFilteredGroups = [
                                    //     ...groupChats,
                                    //   ];
                                    //   updatedFilteredGroups[index] = {
                                    //     ...updatedFilteredGroups[index],
                                    //     messageNotification: {
                                    //       ...updatedFilteredGroups[index]
                                    //         ?.messageNotification,
                                    //       notification: false,
                                    //     },
                                    //   };
                                    //   setFilteredGroups(updatedFilteredGroups);
                                    // }
                                    dispatch(fetchMessageChannel(false));
                                  })
                                );
                              }
                              dispatch(
                                performAction("view", channelId, groupChat)
                              );
                            }
                            if (pathname !== Routers.CHAT) {
                              if (typeChat === "private")
                                dispatch(setTypeChat("group"));
                              Utils.redirect(Routers.CHAT);
                            }
                          }
                        }}
                      >
                        <Box
                          sx={{
                            mr: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              w: "8px",
                              h: "8px",
                              bg:
                                isMessageRead || isSender
                                  ? "rgba(187, 113, 84,0.2)"
                                  : "rgba(187, 113, 84,0.8)",
                              rounded: "full",
                            }}
                          />
                        </Box>
                        <Avatar
                          size="sm"
                          src={groupChat?.channel?.avatar?.path}
                          key={groupChat?.channel?.avatar?.path}
                        >
                          <AvatarBadge
                            boxSize={3}
                            bg={`${isReceiverOnline ? "green" : "gray"}.500`}
                          />
                        </Avatar>
                        <Box sx={{ maxWidth: 200 }}>
                          <Box sx={{ ml: 2 }}>
                            <Text
                              sx={{ fontSize: "0.875rem", fontWeight: 600 }}
                            >
                              {groupChat?.channel?.title || "Group"}
                            </Text>
                            <Text
                              fontSize="xs"
                              sx={{
                                fontWeight:
                                  isSender || isMessageRead ? 400 : 600,
                                color:
                                  isSender || isMessageRead ? "#333" : "#000",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                              }}
                            >
                              {/* No sender */}
                              {!lastestMessage?.userCreated ? (
                                lastestMessage?.message
                              ) : (
                                <>
                                  {/* Check who the sender is */}
                                  {isSender
                                    ? `${t("label.you")}`
                                    : groupChat?.lastMessage?.userCreated
                                        ?.userData?.fullName}
                                  :{" "}
                                  {/* Check if the message has not been recalled */}
                                  {!lastestMessage?.recalled ? (
                                    // Check if the message is a JSON string (gif)
                                    Utils.isJsonString(
                                      lastestMessage?.message
                                    ) ? (
                                      "send a gif"
                                    ) : lastestMessage?.message ? (
                                      // Display the message content
                                      <HighlightedText
                                        getFor="text"
                                        content={lastestMessage?.message || ""}
                                        users={members}
                                      />
                                    ) : !_.isEmpty(
                                        lastestMessage?.fileAttachment
                                      ) &&
                                      _.every(
                                        lastestMessage?.fileAttachment,
                                        (fileAttachment) =>
                                          !fileAttachment?.file?.isDeleted
                                      ) ? (
                                      // Display the number of attached files
                                      `has sent ${_.size(
                                        lastestMessage?.fileAttachment
                                      )} file${
                                        _.size(lastestMessage?.fileAttachment) >
                                        1
                                          ? "s"
                                          : ""
                                      }`
                                    ) : (
                                      "file has been deleted"
                                    )
                                  ) : (
                                    // Display a message when the message has been recalled
                                    "has recalled a message"
                                  )}
                                </>
                              )}
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            ml: "auto",
                          }}
                        >
                          <Text fontSize="xs">
                            {Utils.resolveTimeAgo(lastestMessage?.createdAt)}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              : _.isEmpty(newChatData) && <Image src={noDataFound} />}
          </Box>
        ) : (
          _renderSkeleton()
        )}
      </Box>
    );
  };

  const _renderUserTab = () =>
    typeChat === "private" && (
      <Box
        sx={{
          my: 3,
          px: 3,
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <InputGroup
          size="sm"
          sx={{
            cursor: "pointer",
            w: "full",
          }}
        >
          <Input
            isDisabled={
              isFetchChannelLoading ||
              isFetchLoading ||
              isGetLoading ||
              isActionLoading
            }
            placeholder={t("label.searchUserName")}
            value={searchPersonalValue}
            onChange={handleSearchPersonalChange}
            rounded="full"
          />
          <InputLeftElement>
            <Icon as={SearchIcon} boxSize={4} />
          </InputLeftElement>
          <InputRightElement
            sx={{
              height: "100%",
            }}
          >
            {searchPersonalValue.length > 0 && (
              <Tooltip label={t("tooltip.remove")} hasArrow>
                <IconButton
                  isDisabled={
                    isFetchChannelLoading ||
                    isFetchLoading ||
                    isGetLoading ||
                    isActionLoading
                  }
                  isRound
                  sx={{
                    background: "none",
                  }}
                  size="sm"
                  icon={<Icon as={XMarkIcon} />}
                  aria-label={""}
                  onClick={() => handleReset("personal")}
                />
              </Tooltip>
            )}
          </InputRightElement>
        </InputGroup>
        <Tooltip label={t("label.createNewChat")} hasArrow placement="auto">
          <IconButton
            isDisabled={
              isFetchChannelLoading ||
              isFetchLoading ||
              isGetLoading ||
              isActionLoading
            }
            size="sm"
            rounded="full"
            aria-label={"btn-create-private"}
            sx={{
              bg: "none",
            }}
          >
            <Icon
              as={PencilSquareIcon}
              boxSize={5}
              onClick={() => handlePopupChange("createChat", true)}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );

  const _renderGroupTab = () =>
    typeChat === "group" && (
      <Box
        sx={{
          my: 3,
          px: 3,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <InputGroup size="sm" sx={{ cursor: "pointer", w: "full" }}>
          <Input
            placeholder={t("label.searchGroupName")}
            value={searchGroupValue}
            onChange={handleSearchGroupChange}
            rounded="full"
            isDisabled={
              isFetchChannelLoading ||
              isFetchLoading ||
              isGetLoading ||
              isActionLoading
            }
          />
          <InputLeftElement>
            <Icon as={SearchIcon} boxSize={4} />
          </InputLeftElement>
          <InputRightElement
            sx={{
              height: "100%",
            }}
          >
            {searchGroupValue.length > 0 && (
              <Tooltip label={t("tooltip.remove")} hasArrow>
                <IconButton
                  isRound
                  sx={{
                    background: "none",
                  }}
                  size="sm"
                  icon={<Icon as={XMarkIcon} />}
                  aria-label={""}
                  onClick={() => handleReset("group")}
                  isDisabled={
                    isFetchChannelLoading ||
                    isFetchLoading ||
                    isGetLoading ||
                    isActionLoading
                  }
                />
              </Tooltip>
            )}
          </InputRightElement>
        </InputGroup>
        <Tooltip label={t("title.createNewGroup")} hasArrow placement="auto">
          <IconButton
            isDisabled={
              isFetchChannelLoading ||
              isFetchLoading ||
              isGetLoading ||
              isActionLoading
            }
            size="sm"
            rounded="full"
            aria-label={"btn-create-private"}
            sx={{
              bg: "none",
            }}
          >
            <Icon
              as={PencilSquareIcon}
              boxSize={5}
              onClick={() => handlePopupChange("createGroup", true)}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );

  const _renderPopup = () => (
    <>
      <ChatDialog.CreateGroup
        open={isShowPopup.createGroup}
        onClose={() => handlePopupChange("createGroup", false)}
      />
      <ChatDialog.CreateChat
        open={isShowPopup.createChat}
        onClose={() => handlePopupChange("createChat", false)}
      />
      <ChatDialog.ChatDetail
        open={isShowPopup.chatDetail}
        onClose={() => handlePopupChange("chatDetail", false)}
      />
    </>
  );

  const _renderTopSection = () => (
    <Box sx={{ display: "flex", alignItems: "flex-start", p: 2 }}>
      <Button
        variant="outline"
        size="sm"
        sx={{
          border: "none",
          width: isMobile ? "50%" : 150,
          color: typeChat === "private" ? "#fff" : "#000",
          background: `${
            typeChat === "private" ? "rgba(92, 110, 108,1)" : "gray.100"
          }`,
          "&:hover": {
            background: `${
              typeChat !== "private"
                ? " rgba(92, 110, 108, 0.9)"
                : CommonColors.mainBackground
            }`,
          },
          borderRadius: "25px 0 0 25px",
        }}
        rounded={0}
        onClick={() => dispatch(setTypeChat("private"))}
        isDisabled={
          isFetchChannelLoading ||
          isFetchLoading ||
          isGetLoading ||
          isActionLoading
        }
      >
        {t("button.private")}{" "}
        {notifications?.private > 0 && `(${notifications?.private})`}
      </Button>
      <Button
        variant="outline"
        size="sm"
        sx={{
          border: "none",
          width: isMobile ? "50%" : 150,
          color: typeChat === "group" ? "#fff" : "#000",
          background: `${
            typeChat === "group" ? "rgba(92, 110, 108,1)" : "gray.100"
          }`,
          "&:hover": {
            background: `${
              typeChat === "group"
                ? " rgba(92, 110, 108, 0.9)"
                : CommonColors.mainBackground
            }`,
          },
          borderRadius: "0 25px 25px 0px",
        }}
        rounded={0}
        onClick={() => dispatch(setTypeChat("group"))}
        isDisabled={
          isFetchChannelLoading ||
          isFetchLoading ||
          isGetLoading ||
          isActionLoading
        }
      >
        {t("button.group")}
        {notifications?.group > 0 && `(${notifications?.group})`}
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        w: isMobile || getForAppBar ? "100%" : "250px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box>
        {_renderTopSection()}
        {_renderUserTab()}
        {_renderGroupTab()}
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>{_renderPrivate()}</Box>
      {_renderPopup()}
    </Box>
  );
};

export default PortentialChats;
