import React, { useEffect, useState } from "react";
import {
  Stack,
  Heading,
  Box,
  HStack,
  Button,
  Icon,
  Image,
  Divider,
  Text,
  Tabs,
  TabList,
  Tab,
  Tooltip,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";

import { RoundedContainer } from "@/Components/Common";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import CreateShortCutGroupDialog from "@/Components/Popup/Workspace/CreateShortCutGroup";
import { RootState, useTypedDispatch } from "@/Store";
import { WorkspaceActions } from "@/Actions";
import _ from "lodash";
import { useSelector } from "react-redux";
import UpdateShortCutGroupDialog from "@/Components/Popup/Workspace/UpdateShortCutGroup";
import CreateWorkspaceShortCutDialog from "@/Components/Popup/Workspace/CreateWorkspaceShortCut";
import UpdateWorkspaceShortCutDialog from "@/Components/Popup/Workspace/UpdateWorkspaceShortCut";
import { ConfirmDialog } from "@/Components/Popup";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";

const { fetchWorkspaceTime, fetchWorkspaceShortCut, deleteWorkspaceShort } =
  WorkspaceActions;

const WorkspaceList: React.FC = () => {
  const t = useTranslation();
  const [isOpenAddShortGroup, setIsOpenAddShortGroup] =
    useState<boolean>(false);
  const [isOpenUpdateShortGroup, setIsOpenUpdateShortGroup] = useState<{
    payload: any;
    open: boolean;
  }>({ payload: {}, open: false });
  const [isOpenAddShortCut, setIsOpenAddShortCut] = useState<{
    id?: string;
    type: string;
    open: boolean;
  }>({ id: "", type: "", open: false });
  const [isOpenUpdateShortCut, setIsOpenUpdateShortCut] = useState<{
    payload: any;
    open: boolean;
  }>({ payload: {}, open: false });
  const [isShowDeleteLog, setIsShowDeleteLog] = React.useState<{
    open: boolean;
    id: string;
  }>({ open: false, id: "" });
  const dispatch = useTypedDispatch();

  const workspaceGroupList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceGroupList")
  );
  const workspaceShortList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceShortList")
  );

  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isCreateSuccess")
  );

  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isUpdateSuccess")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isActionLoading")
  );

  useEffect(() => {
    dispatch(fetchWorkspaceTime({ page: 0, limit: 0 }));
    dispatch(fetchWorkspaceShortCut({ page: 0, limit: 0, type: "single" }));
  }, []);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      setIsOpenAddShortGroup(false);
      setIsOpenUpdateShortGroup({ payload: {}, open: false });
      setIsOpenUpdateShortCut({ payload: {}, open: false });
      setIsOpenAddShortCut({ id: "", open: false, type: "" });
      setIsShowDeleteLog({ id: "", open: false });
      dispatch(fetchWorkspaceTime({ page: 0, limit: 0 }));
      dispatch(fetchWorkspaceShortCut({ page: 0, limit: 0, type: "single" }));
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  const _renderPopup = () => {
    return (
      <Box>
        <CreateShortCutGroupDialog
          open={isOpenAddShortGroup}
          onClose={() => setIsOpenAddShortGroup(false)}
        />
        <UpdateShortCutGroupDialog
          open={isOpenUpdateShortGroup.open}
          payload={isOpenUpdateShortGroup.payload}
          onClose={() =>
            setIsOpenUpdateShortGroup({ payload: [], open: false })
          }
        />
        <CreateWorkspaceShortCutDialog
          open={isOpenAddShortCut.open}
          workspaceGroupId={isOpenAddShortCut.id}
          type={isOpenAddShortCut.type}
          onClose={() =>
            setIsOpenAddShortCut({ id: "", type: "", open: false })
          }
        />
        <UpdateWorkspaceShortCutDialog
          open={isOpenUpdateShortCut.open}
          payload={isOpenUpdateShortCut.payload}
          onClose={() => setIsOpenUpdateShortCut({ payload: [], open: false })}
        />
        <ConfirmDialog
          isOpen={isShowDeleteLog.open}
          onClose={() => setIsShowDeleteLog({ open: false, id: "" })}
          onAction={() => {
            if (isShowDeleteLog?.id)
              dispatch(deleteWorkspaceShort(isShowDeleteLog.id));
          }}
          body={t("message.doYouWantToRemoveTheShortcut")}
          actionType="delete"
          isLoading={isActionLoading}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.workspaceShortcut")}
            </Heading>
          </HStack>
        </Box>

        <RoundedContainer>
          <Box
            sx={{
              ".css-52dxnr[aria-selected=true]": {
                "--tabs-color": "#BB7154",
                borderColor: "currentColor",
                cursor: "context-menu",
              },
              ".css-52dxnr": {
                px: "8px",
              },
            }}
          >
            <Box>
              <Tabs>
                <TabList>
                  <Tab>
                    <Heading size="sm" color={"#5C6e6c"}>
                      {t("title.ShortcutWorkspace")}
                    </Heading>
                  </Tab>
                </TabList>
              </Tabs>
              {/*<Box mt={1}>
                <Divider
                  sx={{
                    height: "2px",
                    backgroundColor: "#BB7154",
                  }}
                />
              </Box>*/}
              <Box mt={2}>
                <Box
                  sx={{ display: "flex", gap: 4, padding: 2, flexWrap: "wrap" }}
                >
                  {!_.isEmpty(workspaceShortList) &&
                    _.map(
                      _.reverse([...workspaceShortList]),
                      (shortCut, key) => {
                        return (
                          <Box
                            sx={{ position: "relative", cursor: "pointer" }}
                            key={key}
                          >
                            <CloseIcon
                              onClick={() => {
                                setIsShowDeleteLog({
                                  open: true,
                                  id: shortCut.id,
                                });
                              }}
                              color="#000"
                              style={{
                                position: "absolute",
                                top: 2,
                                right: 5,
                                fontSize: 10,
                                border: "1px solid white",
                                borderRadius: "15px",
                                cursor: "pointer",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: 170,
                                gap: 2,
                                borderRadius: "10px",
                                background: "rgba(166, 183, 170,0.2)",
                                padding: 2,
                                height: "50px",
                              }}
                              onClick={() =>
                                setIsOpenUpdateShortCut({
                                  payload: shortCut,
                                  open: true,
                                })
                              }
                            >
                              <Image
                                borderRadius="full"
                                boxSize="35px"
                                src={
                                  shortCut.iconCustom?.path || shortCut?.icon
                                }
                              />
                              <Tooltip label={shortCut.name}>
                                <Text
                                  size="sm"
                                  color={"#5C6e6c"}
                                  sx={{
                                    display: "-webkit-Box",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {shortCut.name}
                                </Text>
                              </Tooltip>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  <Button
                    size="sm"
                    //  colorScheme="whatsapp"
                    rightIcon={<Icon as={AddIcon} boxSize={3} />}
                    onClick={() =>
                      setIsOpenAddShortCut({ type: "single", open: true })
                    }
                    sx={{
                      color: "#000",
                      background: "rgba(166, 183, 170,0.2)",
                      height: "50px",
                      "&:hover": {
                        background: "rgba(166, 183, 170,0.6)",
                      },
                      w: 170,
                    }}
                    //isLoading={isActionLoading}
                  >
                    {t("button.addShortcut")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              {_.map(_.reverse([...workspaceGroupList]), (item, index) => {
                return (
                  <Box key={index} mb={8}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          paddingBottom: 2,
                          ml: 2,
                        }}
                      >
                        <Image
                          borderRadius="full"
                          boxSize="35px"
                          src={item.iconCustom?.path || item?.icon}
                        />
                        <Heading
                          size="sm"
                          color={"rgba(92, 110, 108,1)"}
                          //  sx={{
                          //    cursor: "pointer",
                          //    "&:hover": {
                          //      color: "rgba(92, 110, 108,0.8)",
                          //    },
                          //  }}
                        >
                          {item.name}
                        </Heading>
                        <Tooltip label={t("tooltip.edit")}>
                          <Icon
                            sx={{ cursor: "pointer" }}
                            as={PencilSquareIcon}
                            onClick={() =>
                              setIsOpenUpdateShortGroup({
                                payload: item,
                                open: true,
                              })
                            }
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                          ml: 2,
                          flexWrap: "wrap",
                        }}
                      >
                        {!_.isEmpty(item.workspaceShortcut) && (
                          <Box
                            sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}
                          >
                            {_.map(item.workspaceShortcut, (shortCut, key) => {
                              return (
                                <Box
                                  sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                  key={key}
                                >
                                  <CloseIcon
                                    onClick={() => {
                                      setIsShowDeleteLog({
                                        open: true,
                                        id: shortCut.id,
                                      });
                                    }}
                                    color="#000"
                                    style={{
                                      position: "absolute",
                                      top: 2,
                                      right: 5,
                                      fontSize: 10,
                                      border: "1px solid white",
                                      borderRadius: "15px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: 170,
                                      gap: 2,
                                      borderRadius: "10px",
                                      background: "rgba(166, 183, 170,0.2)",
                                      padding: 2,

                                      height: "50px",
                                    }}
                                    onClick={() =>
                                      setIsOpenUpdateShortCut({
                                        payload: shortCut,
                                        open: true,
                                      })
                                    }
                                  >
                                    <Box sx={{ width: "40px" }}>
                                      <Image
                                        borderRadius="full"
                                        boxSize="35px"
                                        src={
                                          shortCut.iconCustom?.path ||
                                          shortCut?.icon
                                        }
                                      />
                                    </Box>
                                    <Tooltip label={shortCut.name}>
                                      <Text
                                        size="sm"
                                        color={"#5C6e6c"}
                                        sx={{
                                          cursor: "pointer",
                                          display: "-webkit-Box",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                        }}
                                      >
                                        {shortCut.name}
                                      </Text>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                        <Button
                          size="sm"
                          rightIcon={<Icon as={AddIcon} boxSize={3} />}
                          onClick={() =>
                            setIsOpenAddShortCut({
                              id: item.id,
                              type: "group",
                              open: true,
                            })
                          }
                          sx={{
                            color: "#000",
                            background: "rgba(166, 183, 170,0.2)",
                            height: "50px",
                            "&:hover": {
                              background: "rgba(166, 183, 170,0.6)",
                            },
                            w: 170,
                          }}
                        >
                          {t("button.addShortcut")}
                        </Button>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Divider />
                    </Box>
                  </Box>
                );
              })}
              <Box mt={2} ml={2}>
                <Button
                  size="sm"
                  //  colorScheme="whatsapp"
                  rightIcon={<Icon as={AddIcon} boxSize={3} />}
                  onClick={() => setIsOpenAddShortGroup(true)}
                  sx={{
                    color: "#000",
                    background: "rgba(166, 183, 170,0.2)",
                    height: "50px",
                    "&:hover": {
                      background: "rgba(166, 183, 170,0.6)",
                    },
                  }}
                  //isLoading={isActionLoading}
                >
                  {t("button.addCustomGroup")}
                </Button>
              </Box>
            </Box>
          </Box>
        </RoundedContainer>
        {_renderPopup()}

        {/*<ConfirmDialog
          isOpen={confirmRemote.open}
          onClose={() => {
            setConfirmRemote({ ...confirmRemote, open: false });
          }}
          body={confirmRemote.type}
          onAction={() => onChangeRemote()}
          actionType={"save"}
        />*/}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default WorkspaceList;
