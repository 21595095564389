import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  Stack,
  Heading,
  Box,
  Button,
  HStack,
  Icon,
  Text,
  Switch,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";

import { ConfirmDialog } from "@/Components/Popup";

import { InternalIpActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";

import CreateInternalDialog from "@/Components/Popup/InternalIP/CreateInternalDialog";
import UpdateInternalDialog from "@/Components/Popup/InternalIP/UpdateInternalDialog";

import { IInternalStructure } from "@/Interfaces/InternalIp.interface";
import InternalIPDataTable from "@/Components/LayoutPart/DataTable/InternalIPDataTable";
import { PlusIcon } from "@heroicons/react/24/outline";
import { RoundedContainer } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const {
  fetchInternalIp,
  changeStatusInternalIp,
  fetchInternalIpRemote,
  createInternalIpRemote,
} = InternalIpActions;

const DEFAULT_FILTERS = {
  page: 1,
  limit: 10,
};

const InternalIP: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const internalList: IInternalStructure[] = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "internalList")
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isFetchLoading")
  );
  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isChangeStatusSuccess")
  );
  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isCreateSuccess")
  );

  const pagination: any = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "pagination")
  );

  const meta = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "meta")
  );

  const internalRemote: any = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "internalRemote")
  );

  const isChangIpRemote: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isChangeIpRemoteSuccess")
  );

  const [confirmStatus, setConfirmStatus] = useState<{
    open: boolean;
    status: string;
    type: string;
    id: string;
  }>({ open: false, status: "", type: "", id: "" });

  const [confirmRemote, setConfirmRemote] = useState<{
    open: boolean;
    type: string;
  }>({ open: false, type: "" });

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    update: false,
  });

  const [internalIpDetail, setInternalIpDetail] = useState<IInternalStructure>({
    id: "",
    ipAddress: "",
    description: "",
    status: "",
  });

  const sortedInternalIPList = useMemo(() => {
    return !_.isEmpty(internalList)
      ? Utils.sortByProperty(internalList, "updatedAt", false)
      : [];
  }, [internalList]);

  useEffect(() => {
    dispatch(fetchInternalIp(DEFAULT_FILTERS));
    dispatch(fetchInternalIpRemote());
  }, []);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setConfirmStatus({ open: false, status: "", type: "", id: "" });
    }
  }, [isChangeStatusSuccess]);

  useEffect(() => {
    if (isCreateSuccess) handlePopupChange("create", false);
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isChangIpRemote) {
      setConfirmRemote({ type: "", open: false });
    }
  }, [isChangIpRemote]);

  const handlePopupChange = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const onSwitchStatus = (type: boolean, id: string) => {
    if (type)
      setConfirmStatus({
        open: true,
        status: "active",
        type: t("message.doYouWantToActiveInternalIpAddress"),
        id: id,
      });
    else
      setConfirmStatus({
        open: true,
        status: "inactive",
        type: t("message.doYouWantToInactiveInternalAddress"),
        id: id,
      });
  };

  const onChangeStatus = () => {
    if (confirmStatus?.id) {
      if (confirmStatus?.status === "inactive")
        dispatch(
          changeStatusInternalIp(confirmStatus.id, { status: "inactive" })
        );
      if (confirmStatus?.status === "active")
        dispatch(
          changeStatusInternalIp(confirmStatus.id, { status: "active" })
        );

      if (confirmStatus?.status === "terminated")
        dispatch(
          changeStatusInternalIp(confirmStatus.id, { status: "terminated" })
        );
    }
  };

  const onRowActionChange = (action: string, item: any) => {
    if (action === "edit") {
      setInternalIpDetail(item);
      handlePopupChange("update", true);
    }
    if (action === "delete")
      setConfirmStatus({
        open: true,
        status: "terminated",
        type: t("message.doYouWantToRemoveThisInternalIpAddress"),
        id: item?.id,
      });
  };

  const onSwitchRemote = (value: boolean) => {
    if (value) {
      setConfirmRemote({
        open: true,
        type: t("message.doYouWantToEnableRemoteWorkingMode"),
      });
    } else
      setConfirmRemote({
        open: true,
        type: t("message.doYouWantToTurnOffRemoteWorkingMode"),
      });
  };

  const onChangeRemote = () => {
    dispatch(createInternalIpRemote());
  };

  const onPageChange = (value: number) =>
    dispatch(fetchInternalIp({ ...pagination, page: value }));
  const onChangeLimit = (limit: number) =>
    dispatch(fetchInternalIp({ ...pagination, page: 1, limit }));

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.internalIPAddress")}
            </Heading>
            <Button
              size="sm"
              //  colorScheme="whatsapp"
              onClick={() => handlePopupChange("create", true)}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </HStack>
        </Box>
        <RoundedContainer>
          <Box mb={2} ml={2} sx={{ display: "flex", alignItems: "center" }}>
            <Text fontSize={"16px"}> {t("title.allowsWorkingRemotely")}:</Text>
            <Box
              ml={2}
              sx={{
                "& .css-p27qcy[data-checked]": {
                  background: "#bb7154",
                },
              }}
            >
              <Switch
                isChecked={
                  !_.isEmpty(internalRemote) ? internalRemote.status : false
                }
                onChange={(e: any) => onSwitchRemote(e.target.checked)}
              />
            </Box>
          </Box>
          <InternalIPDataTable
            paginate={meta}
            payload={sortedInternalIPList}
            onRowAction={onRowActionChange}
            onSwitchStatus={onSwitchStatus}
            onPageChange={onPageChange}
            isLoading={isFetchLoading}
            onChangeLimit={onChangeLimit}
          />
        </RoundedContainer>
        <CreateInternalDialog
          onClose={() => {
            handlePopupChange("create", false);
            setInternalIpDetail({
              id: "",
              ipAddress: "",
              description: "",
              status: "",
            });
          }}
          open={isShowPopup.create}
        />
        <UpdateInternalDialog
          onClose={() => {
            handlePopupChange("update", false);
            setInternalIpDetail({
              id: "",
              ipAddress: "",
              description: "",
              status: "",
            });
          }}
          open={!!internalIpDetail && isShowPopup.update}
          payload={internalIpDetail}
        />
        <ConfirmDialog
          isOpen={confirmStatus.open}
          onClose={() => {
            setConfirmStatus({ ...confirmStatus, open: false });
          }}
          body={confirmStatus.type}
          onAction={() => onChangeStatus()}
          actionType={
            confirmStatus.status === "active"
              ? "active"
              : confirmStatus.status === "inactive"
              ? "inactive"
              : "confirm"
          }
        />
        <ConfirmDialog
          isOpen={confirmRemote.open}
          onClose={() => {
            setConfirmRemote({ ...confirmRemote, open: false });
          }}
          body={confirmRemote.type}
          onAction={() => onChangeRemote()}
          actionType={"save"}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default InternalIP;
