import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";

const setWorkspaceFetchLoading = () => {
  return {
    type: ActionTypes.SET_WORKSPACE_FETCH_LOADING,
  };
};

const setWorkspaceGetLoading = () => {
  return {
    type: ActionTypes.SET_WORKSPACE_GET_LOADING,
  };
};

const resetWorkingspace = () => {
  return {
    type: ActionTypes.RESET_WORKSPACE,
  };
};

const setWorkspaceActionLoading = () => {
  return {
    type: ActionTypes.SET_WORKSPACE_ACTION_LOADING,
  };
};

const fetchWorkspaceSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_WORKSPACE_SUCCESS,
    payload,
  };
};

const fetchWorkspaceFail = () => {
  return {
    type: ActionTypes.FETCH_WORKSPACE_FAILURE,
  };
};

const fetchWorkspaceTime = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceFetchLoading());
    await APIs.fetchWorkspace(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchWorkspaceFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchWorkspaceSuccess(resolveResult?.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchWorkspaceFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_WORKSPACE_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_WORKSPACE_FAILURE,
  };
};

const createWorkspace = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.createWorkspace(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateWorkSpaceSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_WORKSPACE_SUCCESS,
    payload,
  };
};

const updateWorkSpaceFail = () => {
  return {
    type: ActionTypes.UPDATE_WORKSPACE_FAILURE,
  };
};

const updateWorkSpace = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.updateWorkspace(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateWorkSpaceFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateWorkSpaceSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateWorkSpaceFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload?: any) => {
  return {
    type: ActionTypes.GET_BY_ID_WORKSPACE_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_BY_ID_WORKSPACE_FAILURE,
  };
};

const getWorkspaceById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceGetLoading());
    await APIs.getWorkspaceById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdSuccess(result));
      })
      .catch(async (error) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ActionTypes.DELETE_WORKSPACE_SUCCESS,
  };
};

const deleteFailure = () => {
  return {
    type: ActionTypes.DELETE_WORKSPACE_FAILURE,
  };
};

const deleteWorkSpace = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.deleteWorkspace(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

// short

const fetchWorkspaceShorCutSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_WORKSPACE_SHORT_CUT_SUCCESS,
    payload,
  };
};

const fetchWorkspaceShorCutFail = () => {
  return {
    type: ActionTypes.FETCH_WORKSPACE_SHORT_CUT_FAILURE,
  };
};

const fetchWorkspaceShortCut = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceFetchLoading());
    await APIs.fetchWorkspaceShort(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchWorkspaceShorCutFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(fetchWorkspaceShorCutSuccess(resolveResult?.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchWorkspaceShorCutFail());
      });
  };
};

const createWorkspaceShorCutSuccess = () => {
  return {
    type: ActionTypes.CREATE_WORKSPACE_SHORT_CUT_SUCCESS,
  };
};

const createWorkspaceShorCutFailure = () => {
  return {
    type: ActionTypes.CREATE_WORKSPACE_SHORT_CUT_FAILURE,
  };
};

const createWorkspaceShorCut = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.createWorkspaceShort(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createWorkspaceShorCutFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createWorkspaceShorCutSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createWorkspaceShorCutFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdWorkspaceShorCutSuccess = (payload?: any) => {
  return {
    type: ActionTypes.GET_BY_ID_WORKSPACE_SHORT_CUT_SUCCESS,
    payload,
  };
};

const getByIdWorkspaceShorCutFailure = () => {
  return {
    type: ActionTypes.GET_BY_ID_WORKSPACE_SHORT_CUT_FAILURE,
  };
};

const getWorkspaceWorkspaceShorCutById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceGetLoading());
    await APIs.getWorkspaceShortById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdWorkspaceShorCutFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdWorkspaceShorCutSuccess(result));
      })
      .catch(async (error) => {
        await dispatch(getByIdWorkspaceShorCutFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateWorkSpaceShorCutSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_WORKSPACE_SHORT_CUT_SUCCESS,
    payload,
  };
};

const updateWorkSpaceShorCutFail = () => {
  return {
    type: ActionTypes.UPDATE_WORKSPACE_SHORT_CUT_FAILURE,
  };
};

const updateWorkSpaceShorCut = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.updateWorkspaceShort(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateWorkSpaceShorCutFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateWorkSpaceShorCutSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateWorkSpaceShorCutFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteWorkSpaceShortCutSuccess = () => {
  return {
    type: ActionTypes.DELETE_WORKSPACE_SHORT_CUT_SUCCESS,
  };
};

const deleteWorkSpaceShortCutFailure = () => {
  return {
    type: ActionTypes.DELETE_WORKSPACE_SHORT_CUT_FAILURE,
  };
};

const deleteWorkspaceShort = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setWorkspaceActionLoading());
    await APIs.deleteWorkspaceShort(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteWorkSpaceShortCutFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteWorkSpaceShortCutSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteWorkSpaceShortCutFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchWorkspaceTime,
  createWorkspace,
  updateWorkSpace,
  getWorkspaceById,
  deleteWorkSpace,
  resetWorkingspace,
  fetchWorkspaceShortCut,
  createWorkspaceShorCut,
  getWorkspaceWorkspaceShorCutById,
  updateWorkSpaceShorCut,
  deleteWorkspaceShort,
};
