import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { useTypedDispatch } from "@/Store";
import { AuthenticationActions } from "@/Actions";

interface ISectionProps {
  children: JSX.Element;
  location: string;
}

const ProtectedRouter: React.FC<ISectionProps> = ({ children }) => {
  const pathname = useLocation().pathname;
  const token = Utils.getSavedToken();
  const refreshToken = Utils.getSavedRefreshToken();

  const dispatch = useTypedDispatch();
  let isValidToken = true;
  if (token) {
    const decodedToken: any = jwt_decode(token);
    if (decodedToken.exp - Math.floor(new Date().getTime() / 1000) <= 0) {
      if (Utils.getSavedRefreshToken() != undefined) {
        const decodedToken: any = jwt_decode(refreshToken);
        if (decodedToken.exp - Math.floor(new Date().getTime() / 1000) > 0) {
          isValidToken = true;
          dispatch(
            AuthenticationActions.refeshToken({
              refreshToken,
            })
          );
        } else {
          isValidToken = false;
        }
      }
    }
  } else {
    Utils.clearAllSavedData();
    return <Navigate to={`${Routers.SIGN_IN}?redirect=${pathname}`} replace />;
  }

  if (!isValidToken) {
    Utils.clearAllSavedData();
    return <Navigate to={`${Routers.SIGN_IN}?redirect=${pathname}`} replace />;
  }
  return children;
};
export default ProtectedRouter;
