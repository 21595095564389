import { Button } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  XMarkIcon,
  CheckCircleIcon,
  TrashIcon,
  PlusIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import { useRef } from "react";
import { useTranslation } from "react-multi-lang";

type TActionType =
  | "save"
  | "delete"
  | "create"
  | "confirm"
  | "approve"
  | "rejected"
  | "active"
  | "inactive";

interface ConfirmDialogProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  leastDestructiveRef?: React.MutableRefObject<null>;
  actionType?: TActionType;
  body: string;
  onAction: () => void; // Callback function for action
  onChange?(newValue: string): void;
  isError?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  isOpen,
  onClose,
  leastDestructiveRef,
  actionType,
  body,
  onChange,
  onAction, // Callback function from parent component
  isError,
  errorMessage = "",
  isLoading = false,
}) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const handleAction = () => onAction();
  const actionContentMap = {
    save: {
      label: "save",
      color: "rgba(92, 110, 108,0.9)",
      colorHover: "rgba(92, 110, 108,1)",
      icon: <Icon as={BookmarkIcon} />,
    },
    delete: {
      label: "delete",
      color: "rgba(187, 113, 84,0.9)",
      colorHover: "rgba(187, 113, 84,1)",
      icon: <Icon as={TrashIcon} />,
    },
    create: {
      label: "create",
      color: "rgba(92, 110, 108,0.9)",
      colorHover: "rgba(92, 110, 108,1)",
      icon: <Icon as={PlusIcon} />,
    },
    confirm: {
      label: "confirm",
      color: "rgba(187, 113, 84,0.9)",
      colorHover: "rgba(187, 113, 84,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
    approve: {
      label: "approve",
      color: "rgba(92, 110, 108,0.9)",
      colorHover: "rgba(92, 110, 108,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
    default: {
      label: "confirm",
      color: "rgba(187, 113, 84,0.9)",
      colorHover: "rgba(187, 113, 84,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
    rejected: {
      label: "rejected",
      color: "rgba(187, 113, 84,0.9)",
      colorHover: "rgba(187, 113, 84,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
    active: {
      label: "active",
      color: "rgba(187, 113, 84,0.9)",
      colorHover: "rgba(187, 113, 84,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
    inactive: {
      label: "inactive",
      color: "rgba(92, 110, 108,0.9)",
      colorHover: "rgba(92, 110, 108,1)",
      icon: <Icon as={CheckCircleIcon} />,
    },
  };

  const getActionButtonContent = (type: TActionType) => {
    const actionType = actionContentMap[type] || actionContentMap.default;
    return actionType;
  };

  const {
    label: actionButtonLabel,
    icon: actionButtonIcon,
    color: actionButtonColor,
    colorHover: actionButtonColorHover,
  } = getActionButtonContent(actionType as TActionType);

  const handleEditorChange = (content: any) => {
    if (onChange) onChange(content.target.value);
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef || cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title ? title : t("message.areYouSure")}
        </AlertDialogHeader>
        <AlertDialogBody>
          <Box>{body}</Box>
          <Box>
            {actionType === "rejected" && (
              <TextareaComponent
                onChange={handleEditorChange}
                isError={isError}
                errorMessage={errorMessage}
              />
            )}
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            size="sm"
            ref={cancelRef}
            leftIcon={<Icon as={XMarkIcon} />}
            onClick={onClose}
          >
            {t("button.cancel")}
          </Button>
          <Button
            size="sm"
            //colorScheme={actionButtonColor}
            leftIcon={actionButtonIcon}
            onClick={handleAction}
            ml={3}
            isLoading={isLoading}
            sx={{
              color: "#ffffff",
              bg: actionButtonColor,
              "&:hover": {
                bg: actionButtonColorHover,
              },
            }}
          >
            {t(`button.${actionButtonLabel}`)}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDialog;
