import React, { CSSProperties } from "react";
import { Box, Text } from "@chakra-ui/react";

interface IUserStructure {
  id: string;
  display: string;
  avatar?: string;
}

interface ISectionProps {
  textContent: string;
  users: IUserStructure[];
  sx?: CSSProperties;
}

const HighlightedText: React.FC<ISectionProps> = ({
  textContent,
  users,
  sx,
}) => {
  const highlightUsers = () => {
    const regex = /@\[([^\]]+)\]\(([^)]+)\)|@\b([^\s,@]+)\b/g;

    const matches: any[] = [];
    let match;

    // Find all matches in the text
    while ((match = regex.exec(textContent)) !== null) {
      matches.push({
        fullMatch: match[0],
        usernameWithBrackets: match[1],
        userId: match[2],
        usernameWithoutBrackets: match[3],
        index: match.index,
      });
    }

    if (matches.length === 0) {
      // No mentions found, render the entire textContent
      return (
        <Box as="p">
          <Text fontSize="sm" sx={{ display: "inline" }}>
            {textContent}
          </Text>
        </Box>
      );
    }

    const highlightedText = matches.map((mention, index) => {
      const {
        usernameWithBrackets,
        userId,
        usernameWithoutBrackets,
        index: mentionIndex,
      } = mention;
      const username = usernameWithBrackets || usernameWithoutBrackets;

      const userIndex = users.findIndex(
        (u) => u.id === userId && u.display === username
      );

      if (userIndex !== -1) {
        const user = users[userIndex];
        const mentionComponent = (
          <Text
            key={mentionIndex}
            fontSize="sm"
            sx={{
              fontWeight: "bold",
              color: "blue.500",
              display: "inline",
            }}
          >
            @{user.display}
          </Text>
        );

        if (index === 0) {
          // If it's the first mention, include the content before it
          const contentBeforeFirstMention = textContent.substring(
            0,
            mentionIndex
          );
          return (
            <React.Fragment key={index}>
              <Text fontSize="sm" sx={{ display: "inline" }}>
                {contentBeforeFirstMention}
              </Text>
              {mentionComponent}
            </React.Fragment>
          );
        }

        // Check if there's content between mentions
        const prevMention = matches[index - 1];
        const contentBetweenMentions = textContent.substring(
          prevMention.index + prevMention.fullMatch.length,
          mentionIndex
        );

        return (
          <React.Fragment key={index}>
            <Text fontSize="sm" sx={{ display: "inline" }}>
              {contentBetweenMentions}
            </Text>
            {mentionComponent}
          </React.Fragment>
        );
      } else {
        return (
          <Text key={mentionIndex} fontSize="sm" sx={{ display: "inline" }}>
            {mention.fullMatch}
          </Text>
        );
      }
    });

    // Include the content after the last mention
    const lastMention = matches[matches.length - 1];
    const contentAfterLastMention = textContent.substring(
      lastMention.index + lastMention.fullMatch.length
    );

    highlightedText.push(
      <Text
        key={`${lastMention.index}-index`}
        fontSize="sm"
        sx={{ display: "inline" }}
      >
        {contentAfterLastMention}
      </Text>
    );

    return <Box>{highlightedText}</Box>;
  };

  return (
    textContent && (
      <Box
        sx={{
          ...sx,
          gap: 1,
          alignItems: "center",
          p: 1,
          bg: "gray.200",
          width: "max-content",
          rounded: 5,
        }}
      >
        {highlightUsers()}
      </Box>
    )
  );
};

export default HighlightedText;
