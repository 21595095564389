import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";

// SINGLE ACTIONS
const setRoleFetchLoading = () => {
  return {
    type: ActionTypes.SET_ROLE_FETCH_LOADING,
  };
};

// const setRoleGetLoading = () => {
//   return {
//     type: ActionTypes.SET_ROLE_GET_LOADING,
//   };
// };

// const setRoleActionLoading = () => {
//   return {
//     type: ActionTypes.SET_ROLE_ACTION_LOADING,
//   };
// };

const resetRoleRecucer = () => {
  return {
    type: ActionTypes.RESET_ROLES_REDUCER,
  };
};

//ASYNC ACTIONS

const fetchSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_ROLES_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_ROLES_FAILURE,
  };
};

const fetchRoles = () => {
  return async (dispatch: any) => {
    dispatch(setRoleFetchLoading());
    await APIs.fetchRoles()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(fetchSuccess(result?.items));
      })
      .catch(async () => {
        await dispatch(fetchFailure());
      });
  };
};

export default { fetchRoles, resetRoleRecucer };
