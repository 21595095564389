import React, { useEffect, useState } from "react";
import {
  //  Avatar,
  Box,
} from "@chakra-ui/react";
import { Table } from "rsuite";

import { NoDataFound, TextComponent } from "@/Components/Common";
import { type IPaginate, type TActions } from "@/Types/Common.types";
//import { useWindowWidth } from "@/Helpers";
import _ from "lodash";
import dayjs from "dayjs";
import Utils from "@/Utils";
import { ENUMS, Routers } from "@/Constants";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: any[];
  paginate?: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction?: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  year?: string;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const DealineDashboardDataTable: React.FC<IProps> = ({
  payload,
  isLoading,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const t = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const userTasksMap: any = {};
    payload.forEach((task) => {
      const userId = task?.user?.id;
      if (!userTasksMap[userId]) {
        userTasksMap[userId] = [];
      }
      userTasksMap[userId].push(task);
    });

    const groupedTasks = Object.keys(userTasksMap).map((userId) => ({
      userId: userId,
      logTime: userTasksMap[userId],
    }));
    setData(groupedTasks);
  }, [payload]);

  const resolveData = () => {
    const rows: any[] = [];
    _.forEach(data, (proj) => {
      _.forEach(proj?.logTime, (task, indexTask: number) => {
        let initRow: any = {
          id: task?.id,
          task: `${task?.task?.title} `,
          description: task?.description,
          deadline: `${dayjs(task?.workTimeEndDate).format("DD/MM/YYYY")} - ${
            task?.workTimeEndTime
          }`,
          project: task?.task?.kanbanBoard?.project?.name,
          manager: task?.task?.kanbanBoard?.project?.projectMember,

          note: task?.note,
          employee: task?.user?.userData?.fullName,
          type: task?.task?.kanbanBoard?.project?.type,
          boardId: task?.task?.kanbanBoard?.id,
        };
        if (!indexTask) initRow.rowSpan = proj?.logTime.length;
        rows.push(initRow);
      });
    });
    return rows;
  };

  useEffect(() => {
    setRows(resolveData());
  }, [data]);

  return (
    <Box
      sx={{
        ".rs-table-body-row-wrapper": {
          background: "white",
        },
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "6px",
        },
      }}
    >
      <Table
        data={rows}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        bordered
        cellBordered
        height={isMobile ? 400 : 400}
      >
        <Column
          width={180}
          fixed={!isMobile}
          resizable
          rowSpan={(rowData) => rowData?.rowSpan}
          align="center"
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.employees")}
          </HeaderCell>
          <Cell dataKey="employee">
            {(rowData) => {
              return <TextComponent content={rowData?.employee} maxW="100%" />;
            }}
          </Cell>
        </Column>
        <Column align="left" width={180} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.tasks")}
          </HeaderCell>
          <Cell dataKey="task" style={{ alignItems: "center" }}>
            {(rowData) => {
              return (
                <Box
                  onClick={() => {
                    Utils.redirect(
                      `${Routers.BOARD_DETAIL}?id=${rowData?.boardId}`
                    );
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "rgba(0, 0, 255,0.7)",
                    fontWeight: 550,
                    "&:hover": {
                      color: "rgba(0, 0, 255)",
                    },
                  }}
                >
                  <TextComponent
                    content={rowData?.task || "--"}
                    maxW="100%"
                    style={{
                      alignItems: "center",
                    }}
                    sx={{ textDecoration: "underline" }}
                  />
                </Box>
              );
            }}
          </Cell>
        </Column>
        <Column align="left" width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.projects")}
          </HeaderCell>
          <Cell dataKey="task" style={{ alignItems: "center" }}>
            {(rowData) => {
              return (
                <TextComponent content={rowData?.project || "--"} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={120} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.managers")}
          </HeaderCell>
          <Cell
            dataKey="task"
            style={{ alignItems: "center", textTransform: "capitalize" }}
          >
            {(rowData) => {
              const manager = rowData?.manager?.filter((manager: any) =>
                manager?.user?.userRole.some(
                  (role: any) => role?.role?.roleCode === ENUMS.ROLES.MANAGER
                )
              );
              const userNames = _.map(
                manager,
                (userName) => userName?.user?.userData?.fullName
              );
              const concatenatedRoleNames = userNames.join(", ");
              return (
                <TextComponent content={concatenatedRoleNames} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={120} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.description")}
          </HeaderCell>
          <Cell dataKey="description">
            {(rowData) => {
              const firstTextContent = Utils.getFirstTextContent(
                rowData?.description
              );
              return (
                <Box
                  sx={{
                    w: "100%",
                    ul: {
                      marginLeft: "15px!important",
                    },
                    height: "100%",
                  }}
                >
                  <TextComponent
                    fontSize="sm"
                    maxW="100%"
                    content={firstTextContent ? firstTextContent : "--"}
                    showTooltip={false}
                  />
                </Box>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center" width={140} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.deadline")}
          </HeaderCell>
          <Cell dataKey="deadline">
            {(rowData) => {
              return (
                <TextComponent
                  content={rowData?.deadline || "--"}
                  maxW="100%"
                />
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.note")}
          </HeaderCell>
          <Cell dataKey="note">
            {(rowData) => {
              return (
                <TextComponent content={rowData?.note || "--"} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
      </Table>
    </Box>
  );
};

export default DealineDashboardDataTable;
