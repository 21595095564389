import React, { useMemo } from "react";
import _ from "lodash";
import { Button, Icon } from "@chakra-ui/react";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { DownloadIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-multi-lang";

const headerExceljs = ["A", "B", "C", "D", "E", "F", "G"];

type Props = {
  csvData: any[];
  totalDayOff: number;
  TotalLeaveDays: number;
  year: number;
};

export const ExportToExcelLeaveDays: React.FC<Props> = ({
  csvData,
  totalDayOff,
  TotalLeaveDays,
  year,
}) => {
  const t = useTranslation();
  const transformData = (aggregateList: any[]) => {
    const data: any[] = [];
    _.forEach(aggregateList, (item) => {
      const employeeData: any = {
        fullName: item?.user?.userData?.fullName,
        leaveDays: item?.leaveDay,
        bonus: item?.bonus,
        totalLeaveDays: item?.bonus + item?.leaveDay,
        dayOff: item?.dayOff,
        totalReMaining: item?.bonus + item?.leaveDay - item?.dayOff,
      };
      data.push(employeeData);
    });
    return data;
  };

  const transformedData = useMemo(() => {
    return transformData(csvData);
  }, [csvData]);

  //  const inputArray = [{ name: "ten", age: "12" }];

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 25;

    //title
    worksheet.getCell("A1").value = t("title.leaveStatistics");
    worksheet.mergeCells("A1:G1");

    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A1").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(1).height = 30;
    // year
    worksheet.getCell("A2").value = `${year}`;
    worksheet.mergeCells("A2:G2");
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A2").font = {
      size: 13,
      bold: false,
      color: { argb: "FFFFFF" },
    };
    worksheet.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(2).height = 20;

    //header
    worksheet.addRow([
      `${t("table.no")}`,
      `${t("table.fullName")}`,
      `${t("table.leaveDays")}`,
      `${t("table.bonus")}`,
      `${t("table.totalLeaveDays")}`,
      `${t("table.dayOff")}`,
      `${t("table.totalRemaining")}`,
    ]);

    _.forEach(headerExceljs, (i) => {
      worksheet.getCell(`${i}3`).border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    //worksheet.getRow(worksheet.rowCount).border = {
    //  top: { style: "thin", color: { argb: "000000" } },
    //  left: { style: "thin", color: { argb: "000000" } },
    //  bottom: { style: "thin", color: { argb: "000000" } },
    //  right: { style: "thin", color: { argb: "000000" } },
    //};

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data
    transformedData.forEach((item, index) => {
      worksheet.addRow([
        index + 1,
        item.fullName,
        item.leaveDays,
        item.bonus,
        item.totalLeaveDays,
        item.dayOff,
        item.totalReMaining,
      ]);

      _.forEach(headerExceljs, (i) => {
        worksheet.getCell(`${i}${index + 4}`).border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
        };
      });

      worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
        //if (index % 2 === 0) {
        //  cell.fill = {
        //    type: "pattern",
        //    pattern: "solid",
        //    fgColor: { argb: "E8E8E8" },
        //  };
        //}
        cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
      });
      worksheet.getRow(worksheet.rowCount).height = 25;

      worksheet.getRow(worksheet.rowCount).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    worksheet.addRow([
      `${t("table.total")}`,
      "",
      "",
      "",
      TotalLeaveDays,
      totalDayOff,
      TotalLeaveDays - totalDayOff,
    ]);

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      //  cell.fill = {
      //    type: "pattern",
      //    pattern: "solid",
      //    fgColor: { argb: "FFFFFF" },
      //  };
      cell.font = { size: 12, bold: true, color: { argb: "FF0000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "leave-days.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(csvData)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};
