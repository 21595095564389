import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
//import { IFilterRequestUpdateTimeCard } from "@/Interfaces/RequestUpdateTimeCard";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setRequestUpdateTimeCardFetchLoading = () => {
  return {
    type: ActionTypes.SET_REQUEST_UPDATE_TIME_CARD_FETCH_LOADING,
  };
};

const setRequestUpdateTimeCardGetLoading = () => {
  return {
    type: ActionTypes.SET_REQUEST_UPDATE_TIME_CARD_GET_LOADING,
  };
};

const setRequestUpdateTimeCardActionLoading = () => {
  return {
    type: ActionTypes.SET_REQUEST_UPDATE_TIME_CARD_ACTION_LOADING,
  };
};

const resetRequestUpdateTimeCardDetails = () => {
  return {
    type: ActionTypes.RESET_REQUEST_UPDATE_TIME_CARD_DETAILS,
  };
};

const resetRequestUpdateTimeCardReducer = () => {
  return {
    type: ActionTypes.RESET_REDUCER_REQUEST_UPDATE_TIME_CARD,
  };
};

const setMetaRequestUpdateTimeCard = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_REQUEST_UPDATE_TIME_CARD,
    payload,
  };
};

const setPaginationRequestUpdateTimeCard = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_REQUEST_UPDATE_TIME_CARD,
    payload,
  };
};

const createRequestUpdateTimeCardSuccess = () => {
  return {
    type: ActionTypes.CREATE_REQUEST_UPDATE_TIME_CARD_SUCCESS,
  };
};

const createRequestUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.CREATE_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const createRequestUpdateTimeCard = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardActionLoading());
    await APIs.createRequestUpdateTimeCard(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createRequestUpdateTimeCardFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createRequestUpdateTimeCardSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(
            fetchRequestUpdateTimeCard({
              page: 1,
              limit: 10,
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(createRequestUpdateTimeCardFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchRequestUpdateTimeCardSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_REQUEST_UPDATE_TIME_CARD_SUCCESS,
    payload,
  };
};

const fetchRequestUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.FETCH_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const fetchRequestUpdateTimeCard = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardFetchLoading());
    await APIs.fetchRequestUpdateTimeCard(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchRequestUpdateTimeCardFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          dispatch(fetchRequestUpdateTimeCardSuccess(items));
          dispatch(setMetaRequestUpdateTimeCard(meta));
          dispatch(setPaginationRequestUpdateTimeCard(payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchRequestUpdateTimeCardFail());
      });
  };
};

const getByIdRequestUpdateTimeCardSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_REQUEST_UPDATE_TIME_CARD_SUCCESS,
    payload,
  };
};

const getByIdRequestUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const getByIdRequestUpdateTimeCard = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardGetLoading());
    await APIs.getRequestUpdateTimeCardById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdRequestUpdateTimeCardFail());
        else {
          dispatch(getByIdRequestUpdateTimeCardSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdRequestUpdateTimeCardFail());
      });
  };
};

const updateRequestUpdateTimeCardSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_REQUEST_UPDATE_TIME_CARD_SUCCESS,
    payload,
  };
};

const updateRequestUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.UPDATE_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const updateRequestUpdateTimeCard = (
  id: string,
  payload: any,
  pagination: any
) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardActionLoading());
    await APIs.updateRequestUpdateTimeCard(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateRequestUpdateTimeCardFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateRequestUpdateTimeCardSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchRequestUpdateTimeCard(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(updateRequestUpdateTimeCardFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusRequestUpdateTimeCardSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_REQUEST_UPDATE_TIME_CARD_SUCCESS,
  };
};

const changeStatusRequestUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const changeStatusRequestUpdateTimeCard = (
  id: string,
  payload: any,
  pagination?: any,
  type?: string
) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardActionLoading());
    await APIs.ChangeStatusRequestUpdateTimeCard(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        const language = Utils.getSavedLanguage();
        if (!result) {
          await dispatch(changeStatusRequestUpdateTimeCardFail());
          await Toast({
            title: `${type} ${
              language === "en"
                ? "request update timecard fail"
                : "yêu cầu cập nhật thẻ thời gian thất bại"
            } `,
            description: message,
            status: "error",
          });
        } else {
          dispatch(changeStatusRequestUpdateTimeCardSuccess());
          Toast({
            title: `${type} ${
              language === "en"
                ? "request update timecard successfully"
                : "yêu cầu cập nhật thẻ thời gian thành công"
            }`,
            status: "success",
          });
          await dispatch(fetchRequestUpdateTimeCard(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusRequestUpdateTimeCardFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteUpdateTimeCardSuccess = () => {
  return {
    type: ActionTypes.DELETE_REQUEST_UPDATE_TIME_CARD_SUCCESS,
  };
};

const deleteUpdateTimeCardFail = () => {
  return {
    type: ActionTypes.DELETE_REQUEST_UPDATE_TIME_CARD_FAILURE,
  };
};

const deleteUpdateTimeCard = (id: string, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setRequestUpdateTimeCardActionLoading());
    await APIs.deleteUpdateTimeCard(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteUpdateTimeCardFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteUpdateTimeCardSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchRequestUpdateTimeCard(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(deleteUpdateTimeCardFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  createRequestUpdateTimeCard,
  fetchRequestUpdateTimeCard,
  getByIdRequestUpdateTimeCard,
  updateRequestUpdateTimeCard,
  changeStatusRequestUpdateTimeCard,
  resetRequestUpdateTimeCardReducer,
  resetRequestUpdateTimeCardDetails,
  deleteUpdateTimeCard,
};
