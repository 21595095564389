import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Text, Box, CircularProgress } from "@chakra-ui/react";
import { AuthenticationActions } from "@/Actions";
import { useTypedDispatch } from "@/Store";
import { Routers } from "@/Constants";
import Utils from "@/Utils";
import backgroundImage_bigSize from "@/Assets/Login.jpg";

const { verifyAccount } = AuthenticationActions;

const VerifyAccount: React.FC = () => {
  // Constructors
  const queryParams = useLocation().search;
  const dispatch = useTypedDispatch();

  const [message, setMessage] = useState("Checking information...");

  useEffect(() => {
    if (!queryParams) Utils.redirect(Routers.SIGN_IN);
    const params = _.split(queryParams, "&");
    const email = _.replace(params[0], "?e=", "");
    const code = _.replace(params[1], "c=", "");
    if (email && code) {
      dispatch(verifyAccount({ email, code }));
    } else {
      setMessage(
        "Your link has been expired! System will redirect after 5 seconds..."
      );
      Utils.sleep(5000).then(() => Utils.redirect(Routers.SIGN_IN));
    }
  }, []);

  // Events
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage_bigSize})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <Box
        maxW="xs"
        p={3}
        sx={{
          zIndex: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress isIndeterminate />
          <Box>
            <Text
              fontSize="2xl"
              fontWeight="bold"
              textAlign="center"
              mt={4}
              color="#fff"
            >
              {message}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyAccount;
