import _ from "lodash";
import { useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Avatar,
  Text,
} from "@chakra-ui/react";

interface SectionProps {
  open: boolean;
  onClose(): void;
  onSelect(userId: string): void;
}

const SelectNewAdmin: React.FC<SectionProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const cancelRef = useRef<any>(null);
  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        {_.times(5, (index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
              cursor: "pointer",
              transition: "all .3s ease-in-out",
              "&:hover": {
                bg: "gray.200",
              },
              borderBottom: "1px solid #ddd",
              p: 2,
            }}
            onClick={() => onSelect("1")}
          >
            <Avatar size="sm" />
            <Box>
              <Text fontSize="sm" fontWeight={600}>
                Admin
              </Text>
              <Text fontSize="sm" fontWeight={500}>
                admin@gmail.com
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <Box>
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            borderRadius: 0,
            overflow: "auto",
          }}
        >
          <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
            Select A New Admin
          </AlertDialogHeader>
          <AlertDialogCloseButton rounded="full" />
          <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        </AlertDialogContent>
      </Box>
    </AlertDialog>
  );
};

export default SelectNewAdmin;
