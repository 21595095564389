import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import {
  ICountNotifications,
  INotificationFilter,
} from "@/Interfaces/Notification.interface";

// SINGLE ACTIONS
const setNotificationFetchLoading = () => {
  return {
    type: ActionTypes.SET_NOTIFICATION_FETCH_LOADING,
  };
};

const setNotificationFetchOutsideLoading = () => {
  return {
    type: ActionTypes.SET_NOTIFICATION_FETCH_OUTSIDE_LOADING,
  };
};

const setNotificationGetLoading = () => {
  return {
    type: ActionTypes.SET_NOTIFICATION_GET_LOADING,
  };
};

const setNotificationActionLoading = () => {
  return {
    type: ActionTypes.SET_NOTIFICATION_ACTION_LOADING,
  };
};

const setPaginationNotification = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_NOTIFICATION,
    payload,
  };
};

const setMetaNotification = (payload: any) => {
  return {
    type: ActionTypes.SET_META_NOTIFICATION,
    payload,
  };
};

const resetNotificationRecucer = () => {
  return {
    type: ActionTypes.RESET_NOTIFICATION_REDUCER,
  };
};

//ASYNC ACTIONS

const fetchSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_NOTIFICATION_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_NOTIFICATION_FAILURE,
  };
};

const fetchNotifications = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setNotificationFetchLoading());
    await APIs.fetchNotifications(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(setPaginationNotification(payload));
          dispatch(setMetaNotification(result?.meta));
          dispatch(fetchSuccess(result?.items));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchOutsideSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_OUTSIDE_NOTIFICATION_SUCCESS,
    payload,
  };
};

const fetchOutsideFailure = () => {
  return {
    type: ActionTypes.FETCH_OUTSIDE_NOTIFICATION_FAILURE,
  };
};

const fetchOutsideNotifications = () => {
  return async (dispatch: any) => {
    dispatch(setNotificationFetchOutsideLoading());
    await APIs.fetchOutsideNotifications()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchOutsideFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(fetchOutsideSuccess(result));
      })
      .catch(async (error: any) => {
        await dispatch(fetchOutsideFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_NOTIFICATION_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_NOTIFICATION_BY_ID_FAILURE,
  };
};

const getNotificationById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setNotificationGetLoading());
    await APIs.getNotificationById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdSuccess(result));
      })
      .catch(async (error: any) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const markAsReadSuccess = () => {
  return {
    type: ActionTypes.MARK_AS_READ_NOTIFICATION_SUCCESS,
  };
};

const markAsReadFailure = () => {
  return {
    type: ActionTypes.MARK_AS_READ_NOTIFICATION_FAILURE,
  };
};

const markAsRead = (
  payload: {
    notificationIds: string[];
  },
  pagination: INotificationFilter
) => {
  return async (dispatch: any) => {
    dispatch(setNotificationActionLoading());
    await APIs.markAsRead(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(markAsReadFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(markAsReadSuccess());
          dispatch(fetchNotifications(pagination));
        }
      })
      .catch(async (error: any) => {
        await dispatch(markAsReadFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const markAllAsReadSuccess = () => {
  return {
    type: ActionTypes.MARK_ALL_AS_READ_NOTIFICATION_SUCCESS,
  };
};

const markAllAsReadFailure = () => {
  return {
    type: ActionTypes.MARK_ALL_AS_READ_NOTIFICATION_FAILURE,
  };
};

const markAllAsRead = (pagination: INotificationFilter) => {
  return async (dispatch: any) => {
    dispatch(setNotificationActionLoading());
    await APIs.markAllAsRead()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(markAllAsReadFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await Toast({
            title: message,
            status: "success",
          });
          dispatch(markAllAsReadSuccess());
          dispatch(fetchNotifications(pagination));
        }
      })
      .catch(async (error: any) => {
        await dispatch(markAllAsReadFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const markAllAsReadOutsideSuccess = () => {
  return {
    type: ActionTypes.MARK_ALL_AS_READ_OUTSIDE_NOTIFICATION_SUCCESS,
  };
};

const markAllAsReadOutsideFailure = () => {
  return {
    type: ActionTypes.MARK_ALL_AS_READ_OUTSIDE_NOTIFICATION_FAILURE,
  };
};

const markAllAsReadOutside = (
  pagination: INotificationFilter,
  count: ICountNotifications
) => {
  return async (dispatch: any) => {
    dispatch(setNotificationActionLoading());
    await APIs.markAllAsReadOutside()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(markAllAsReadOutsideFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          if (count?.countNotificationUnread > 0) {
            dispatch(fetchOutsideNotifications());
            dispatch(markAllAsReadOutsideSuccess());
          }
          dispatch(fetchNotifications(pagination));
        }
      })
      .catch(async (error: any) => {
        await dispatch(markAllAsReadOutsideFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchNotifications,
  fetchOutsideSuccess,
  fetchOutsideNotifications,
  getNotificationById,
  markAsRead,
  markAllAsRead,
  markAllAsReadOutside,
  resetNotificationRecucer,
};
