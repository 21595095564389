import AggregateOvertimeDialog from "./AggregateList";
import DetailOvertimeDialog from "./DetailOvertime";
import SendOvertimeDialog from "./SendOvertime";
import UpdateOvertimeDialog from "./UpdateOvertime";

export default {
  SendOvertimeDialog,
  UpdateOvertimeDialog,
  DetailOvertimeDialog,
  AggregateOvertimeDialog,
};
