import { PURGE } from "redux-persist";
import { ActionTypes, DEFAULT_LOADING_STATES } from "@/Constants";

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  payload: {
    mondayMorning: false,
    mondayAfternoon: false,
    tuesdayMorning: false,
    tuesdayAfternoon: false,
    wednesdayMorning: false,
    wednesdayAfternoon: false,
    thursdayMorning: false,
    thursdayAfternoon: false,
    fridayMorning: false,
    fridayAfternoon: false,
    saturdayMorning: false,
    saturdayAfternoon: false,
    sundayMorning: false,
    sundayAfternoon: false,
  },
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_WORKING_DAY_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_WORKING_DAY_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_WORKING_DAY_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.RESET_WORKING_DAY:
      return {
        ...initialState,
      };

    case ActionTypes.FETCH_WORKING_DAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        payload,
      };

    case ActionTypes.FETCH_WORKING_DAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isUpdateSuccess: false,
        workingTimeList: [],
      };

    case ActionTypes.GET_BY_ID_WORKING_DAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
        detail: payload,
      };
    case ActionTypes.GET_BY_ID_WORKING_DAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.CREATE_WORKING_DAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
        payload,
      };
    case ActionTypes.CREATE_WORKING_DAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };
    case ActionTypes.UPDATE_WORKING_DAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
        payload,
      };
    case ActionTypes.UPDATE_WORKING_DAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    default:
      return state;
  }
};
