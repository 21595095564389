import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Heading,
  HStack,
  Text,
  Icon,
  Button,
  List,
  ListItem,
  ListIcon,
  Avatar,
} from "@chakra-ui/react";

import {
  UserCircleIcon,
  PhotoIcon,
  PaperClipIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import { UploadAvatar, FileItem } from "@/Components/Common";

import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { ChatActions } from "@/Actions";
import { IChatFilters } from "@/Interfaces/Chat.interface";
import { IFileResponse } from "@/Interfaces/File.interface";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  open: boolean;
  onClose(): void;
}

interface IAttachmentStructure {
  media: IFileResponse[];
  file: IFileResponse[];
}

type TChatType = "private" | "group";
type TActiveTab = "information" | "media" | "file";

const { fetchMediaByChannelId, resetMediaInChannel, updateConversation } =
  ChatActions;

const ChatDetail: React.FC<ISectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userLogged = Utils.getSavedUserData();
  const chatPerformAction: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "performAction")
  );
  const messageDetailsPagination: IChatFilters = useSelector(
    (state: RootState) => _.get(state.CHAT, "messageDetailsPagination")
  );
  const attachmentInChannel: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "attachmentInChannel")
  );

  const chatInfo = useMemo(() => {
    const type = chatPerformAction?.extraData?.channel?.type;
    const chatTitle =
      type === "private"
        ? _.filter(
            chatPerformAction?.extraData?.channel?.messageChannelMember,
            (member) => member?.user?.id !== userLogged?.id
          )[0]?.user?.userData?.fullName
        : chatPerformAction?.extraData?.channel?.title;

    const receiver =
      type === "private"
        ? _.first(
            _.filter(
              chatPerformAction?.extraData?.channel?.messageChannelMember,
              (member) => member?.user?.id !== userLogged?.id
            )
          )
        : null;
    const members = chatPerformAction?.extraData?.channel?.messageChannelMember;

    return {
      chatTitle,
      receiver,
      members,
      type: chatPerformAction?.extraData?.channel?.type,
    };
  }, [chatPerformAction, userLogged]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [avatar, setAvatar] = useState<File[]>([]);
  const [type, setType] = useState<TChatType>("private");
  const [activeTab, setActiveTab] = useState<TActiveTab | null>(null);

  const [attachments, setAttachments] = useState<IAttachmentStructure>({
    media: [],
    file: [],
  });

  const privateTabs = [
    { icon: UserCircleIcon, label: "Account Information", key: "information" },
    { icon: PhotoIcon, label: "Media File", key: "media" },
    { icon: PaperClipIcon, label: "File", key: "file" },
  ];

  const groupTabs = [
    { icon: UserCircleIcon, label: "Group Information", key: "information" },
    { icon: PhotoIcon, label: "Media File", key: "media" },
    { icon: PaperClipIcon, label: "File", key: "file" },
  ];

  useEffect(() => {
    return () => {
      if (!_.isEmpty(attachmentInChannel)) dispatch(resetMediaInChannel());
      setAttachments({
        media: [],
        file: [],
      });
    };
  }, []);

  useEffect(() => {
    // handle check type here
    if (!_.isEmpty(chatPerformAction?.chatId) && open) {
      setType(chatPerformAction?.extraData?.channel?.type);
      dispatch(
        fetchMediaByChannelId(chatPerformAction?.chatId, {
          page: 0,
          limit: 0,
        })
      );
    }
  }, [chatPerformAction?.chatId, open, messageDetailsPagination]);

  useEffect(() => {
    generateDetails();
  }, [attachmentInChannel]);

  const generateDetails = async () => {
    if (!_.isEmpty(attachmentInChannel)) {
      const filteredAttachments = _.filter(
        attachmentInChannel,
        (attachment) => !attachment?.file?.isDeleted
      );
      const media = _.filter(
        filteredAttachments,
        (attachment) =>
          _.includes(
            Utils.getMimeTypeFromFile(attachment?.file?.nameConvert),
            "image"
          ) ||
          _.includes(
            Utils.getMimeTypeFromFile(attachment?.file?.nameConvert),
            "video"
          )
      );

      const file: any = _.differenceBy(
        filteredAttachments,
        media,
        "file.nameConvert"
      );
      ("messageChannel");
      setAttachments({
        media,
        file,
      });
    } else {
      setAttachments({
        media: [],
        file: [],
      });
    }
  };

  const handleTabClick = (tabKey: TActiveTab) => {
    setActiveTab(tabKey);
  };

  const _renderTabs = (tab: { icon: any; label: string; key: TActiveTab }) => {
    const { icon: Icon, label, key } = tab;
    return (
      <ListItem
        key={key}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          transition: "all .3s ease-in-out",
          "&:hover": {
            bg: "gray.200",
          },
          p: 1,
          rounded: 5,
        }}
        onClick={() => handleTabClick(key)}
      >
        <ListIcon as={Icon} w={6} h={6} />
        <Text fontSize="sm">{t(`label.${label}`)}</Text>
        <ListIcon
          sx={{
            ml: "auto",
            mr: 0,
          }}
          as={ChevronRightIcon}
        />
      </ListItem>
    );
  };

  const _renderAccountInfomation = () => {
    const { chatTitle, receiver, type } = chatInfo;
    const roleNames = _.map(receiver?.user?.userRole, (userRole) =>
      userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        ? `Staff`
        : userRole?.role?.name
    );

    const avatarSrc =
      type === "group"
        ? chatPerformAction?.extraData?.channel?.avatar?.path
        : receiver?.user?.userData?.avatar?.path;

    return (
      <>
        <Box>
          <UploadAvatar
            files={avatar}
            previewUrl={
              avatarSrc ||
              "https://static-00.iconduck.com/assets.00/profile-major-icon-2048x2048-z1oddwyo.png"
            }
            onFileChange={() => {}}
            isOnlyRead
          />
          {/* {activeTab !== "information" && (
            <Text fontSize="md" textAlign="center" fontWeight="bold">
              {chatTitle}
            </Text>
          )} */}
        </Box>
        <HStack>
          <Heading fontSize="sm">{t("label.fullName")}:</Heading>
          <Text fontSize="sm">{chatTitle}</Text>
        </HStack>
        <HStack>
          <Heading fontSize="sm">{t("label.position")}:</Heading>
          <Text fontSize="sm">{roleNames || "--"}</Text>
        </HStack>
        <HStack>
          <Heading fontSize="sm">{t("label.phoneNumber")}:</Heading>
          <Text fontSize="sm">
            {receiver?.user?.userData?.phoneNumber || "--"}
          </Text>
        </HStack>
        <HStack>
          <Heading fontSize="sm">Email:</Heading>
          <Text fontSize="sm">{receiver?.user?.email || "--"}</Text>
        </HStack>
      </>
    );
  };

  const _renderGroupInfomation = () => {
    const { chatTitle, members } = chatInfo;

    return (
      <>
        <Box>
          {/* <Text fontSize="md" textAlign="center" fontWeight="bold">
              {chatTitle}
            </Text> */}
          <UploadAvatar
            previewUrl={
              chatPerformAction?.extraData?.channel?.avatar?.path ||
              "https://static-00.iconduck.com/assets.00/profile-major-icon-2048x2048-z1oddwyo.png"
            }
            files={avatar}
            onFileChange={(file) => {
              const formData = new FormData();
              formData.append("avatar", file[0]);
              setAvatar(file);
              dispatch(
                updateConversation(
                  chatPerformAction?.chatId,
                  formData,
                  chatPerformAction
                )
              );
            }}
          />
        </Box>
        <HStack>
          <Heading fontSize="sm">{t("label.groupName")}:</Heading>
          <Text fontSize="sm">{chatTitle}</Text>
        </HStack>

        <Box>
          <Heading fontSize="sm">
            {t("label.members")} ({_.size(members)})
          </Heading>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              overflow: "auto",
              mt: 3,
            }}
          >
            {!_.isEmpty(members) ? (
              _.map(members, (userInfo, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      size="sm"
                      name={userInfo?.user?.userData?.fullName}
                      src={userInfo?.user?.userData?.avatar?.path}
                    />
                    <Box>
                      <Text fontSize="sm" fontWeight="600">
                        {userInfo?.user?.userData?.fullName}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const _renderInformation = () => {
    if (type === "private") return _renderAccountInfomation();
    if (type === "group") return _renderGroupInfomation();
    return;
  };

  const _renderMedia = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            size="sm"
            sx={{
              flex: 1,
              rounded: "full",
            }}
            variant={activeTab === "media" ? "solid" : "outline"}
            colorScheme="teal"
            onClick={() => setActiveTab("media")}
          >
            {t("button.mediaFile")}
            {`${
              _.size(attachments.media) > 0
                ? `(${_.size(attachments.media)})`
                : ""
            }`}
          </Button>
          <Button
            size="sm"
            sx={{
              flex: 1,
              rounded: "full",
            }}
            variant={activeTab === "file" ? "solid" : "outline"}
            colorScheme="teal"
            onClick={() => setActiveTab("file")}
          >
            {t("button.file")}
            {`${
              _.size(attachments.file) > 0
                ? `(${_.size(attachments.file)})`
                : ""
            }`}
          </Button>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          {!_.isEmpty(attachments?.media) ? (
            <FileItem
              files={_.map(attachments?.media, (fileAttachment) => {
                const fileType = Utils.getMimeTypeFromFile(
                  fileAttachment?.file?.nameOriginal
                );
                return {
                  id: fileAttachment?.id,
                  name: fileAttachment?.file?.nameOriginal,
                  type: fileType,
                  imageUrl:
                    _.includes(fileType, "image") ||
                    _.includes(fileType, "video")
                      ? fileAttachment?.file?.path
                      : "",
                  downloadUrl: fileAttachment?.file?.path,
                };
              })}
              containerStyles={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
              }}
              sizeStyles={{
                width: "90px",
                height: "90px",
              }}
            />
          ) : (
            <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
          )}
        </Box>
      </Box>
    );
  };

  const _renderAttachment = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            size="sm"
            sx={{
              flex: 1,
              rounded: "full",
            }}
            variant={activeTab === "media" ? "solid" : "outline"}
            colorScheme="teal"
            onClick={() => setActiveTab("media")}
          >
            {t("button.mediaFile")}
            {`${
              _.size(attachments.media) > 0
                ? `(${_.size(attachments.media)})`
                : ""
            }`}
          </Button>
          <Button
            size="sm"
            sx={{
              flex: 1,
              rounded: "full",
            }}
            variant={activeTab === "file" ? "solid" : "outline"}
            colorScheme="teal"
            onClick={() => setActiveTab("file")}
          >
            {t("button.file")}
            {`${
              _.size(attachments.file) > 0
                ? `(${_.size(attachments.file)})`
                : ""
            }`}
          </Button>
        </Box>
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          {!_.isEmpty(attachments?.file) ? (
            <FileItem
              files={_.map(attachments?.file, (fileAttachment) => {
                const fileType = Utils.getMimeTypeFromFile(
                  fileAttachment?.file?.nameOriginal
                );
                return {
                  id: fileAttachment?.id,
                  name: fileAttachment?.file?.nameOriginal,
                  type: fileType,
                  imageUrl:
                    _.includes(fileType, "image") ||
                    _.includes(fileType, "video")
                      ? fileAttachment?.file?.path
                      : "",
                  downloadUrl: fileAttachment?.file?.path,
                };
              })}
              containerStyles={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
              }}
              sizeStyles={{
                width: "90px",
                height: "90px",
              }}
            />
          ) : (
            <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
          )}
        </Box>
      </Box>
    );
  };

  const _renderContent = () => {
    switch (activeTab) {
      case "information":
        return _renderInformation();
      case "media":
        return _renderMedia();
      case "file":
        return _renderAttachment();
    }
    return;
  };

  const _renderPersonDetails = () => {
    const { chatTitle, receiver } = chatInfo;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
        }}
      >
        {activeTab ? (
          _renderContent()
        ) : (
          <>
            <Box>
              <UploadAvatar
                files={avatar}
                previewUrl={
                  receiver?.user?.userData?.avatar?.path ||
                  "https://static-00.iconduck.com/assets.00/profile-major-icon-2048x2048-z1oddwyo.png"
                }
                onFileChange={() => {}}
                isOnlyRead
              />
              <Text fontSize="md" textAlign="center" fontWeight="bold">
                {chatTitle}
              </Text>
            </Box>
            <List
              sx={{
                overflow: "auto",
              }}
            >
              {_.map(privateTabs, _renderTabs)}
            </List>
          </>
        )}
      </Box>
    );
  };

  const _renderGroupDetails = () => {
    const { chatTitle } = chatInfo;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
          overflow: "auto",
        }}
      >
        {activeTab ? (
          _renderContent()
        ) : (
          <>
            <Box>
              <UploadAvatar
                files={[]}
                previewUrl={
                  chatPerformAction?.extraData?.channel?.avatar?.path ||
                  "https://static-00.iconduck.com/assets.00/profile-major-icon-2048x2048-z1oddwyo.png"
                }
                onFileChange={(file) => {
                  const formData = new FormData();
                  formData.append("avatar", file[0]);
                  setAvatar(file);
                  dispatch(
                    updateConversation(
                      chatPerformAction?.chatId,
                      formData,
                      chatPerformAction
                    )
                  );
                }}
              />
              <Text fontSize="md" textAlign="center" fontWeight="bold">
                {chatTitle}
              </Text>
            </Box>
            <List>{_.map(groupTabs, _renderTabs)}</List>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Drawer isOpen={open} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          {!activeTab && <DrawerCloseButton rounded="full" />}
          <DrawerHeader
            sx={{
              borderBottom: "1px solid #ddd",
              p: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activeTab && (
              <Icon
                as={ChevronLeftIcon}
                w={6}
                h={6}
                sx={{
                  cursor: "pointer",
                  rounded: "full",
                }}
                onClick={() => setActiveTab(null)}
              />
            )}
            <Heading fontSize="lg">{`${
              type === "private"
                ? t("title.chatDetailInfo")
                : t("title.chatDetailInfoGroup")
            } `}</Heading>
          </DrawerHeader>

          <DrawerBody
            sx={{
              p: 4,
            }}
            ref={containerRef}
          >
            {type === "private" && _renderPersonDetails()}
            {type === "group" && _renderGroupDetails()}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChatDetail;
