import React, { useState } from "react";
import _ from "lodash";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Box } from "@chakra-ui/react";

import CardItem from "./CardItem";
import { BoardDialog } from "@/Components/Popup";

interface ISectionProps {
  cards: any[];
}

const Cards: React.FC<ISectionProps> = ({ cards }) => {
  const [isShowCreateTask, setIsShowCreateTask] = useState(false);

  return (
    <Box
      sx={{
        height: "calc(100vh - 325px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <SortableContext
        items={_.map(cards, (card) => card.id)}
        strategy={verticalListSortingStrategy}
      >
        <Box display="flex" flexDirection="column" gap="2" sx={{ mb: 2 }}>
          {!_.isEmpty(cards) &&
            _.map(cards, (card, index) => <CardItem key={index} card={card} />)}
        </Box>
      </SortableContext>
      <BoardDialog.CreateTaskDialog
        open={isShowCreateTask}
        onClose={() => setIsShowCreateTask(false)}
      />
    </Box>
  );
};

export default Cards;
