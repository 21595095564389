import React, { useState, useEffect } from "react";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Button, TextField } from "@/Components/Common";
import { AuthenticationActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { Box, Stack, Heading, Text, Icon, Image } from "@chakra-ui/react";
import forgot_background from "@/Assets/forgot_bg.png";
import logo from "@/Assets/cc-logo-white.svg";

import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-multi-lang";

const { requestForgotPassword, resetStepForgotPassword } =
  AuthenticationActions;

interface ISectionProps {
  onBack?(): void;
}

const ForgotPassword: React.FC<ISectionProps> = ({ onBack }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const isRequestForgotPasswordSuccess = useSelector((state: RootState) =>
    _.get(state.AUTH, "isRequestForgotPasswordSuccess")
  );

  const isLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, "requestIsLoading")
  );

  React.useEffect(() => {
    return () => {
      dispatch(resetStepForgotPassword());
    };
  }, []);

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .email(t("message.emailMustBeAValidEmail"))
        .required(t("message.emailIsRequired")),
    })
    .required();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (isRequestForgotPasswordSuccess) handleNextStep();
  }, [isRequestForgotPasswordSuccess]);

  const handleNextStep = () => setStep(2);

  const onSubmit = (data: any) => {
    dispatch(requestForgotPassword(data));
  };

  const _renderBeforeConfirm = () => {
    return (
      <Stack
        p={[5, 5, 5, 5, 5, 10, 12]}
        borderRightRadius={10}
        background="white"
        justifyContent="flex-start"
      >
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Image src={logo} sx={{ w: 50, height: 50 }} />
          <Heading fontSize="24px" fontWeight={600} color="#000">
            Workspace
          </Heading>
        </Stack>
        <Stack
          direction="column"
          justifyContent="flex-start"
          sx={{
            width: "100%",
            rowGap: "1em",
            // my: "auto",
          }}
        >
          <Text
            fontSize={{
              base: "1.5rem",
              md: "1.6rem",
              lg: "1.7rem",
              xl: "1.8rem",
            }}
            fontWeight={600}
            color="#000"
            textAlign="center"
          >
            {t("label.forgotPassword")}
            <Text fontSize="14px" color="gray">
              {t("label.thePasswordResetLinkwillBeSentToYourEmail")}
            </Text>
          </Text>

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                placeholder="Email"
                type="email"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value.trim())}
                onEnter={handleSubmit(onSubmit)}
                isError={!_.isEmpty(errors.email?.message)}
                errorMessage={errors.email?.message}
                isRequired
              />
            )}
          />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 5,
            gap: 3,
          }}
        >
          <Button
            size={["sm", "sm", "sm", "sm", "sm", "md"]}
            variant="text"
            sx={{
              flex: 1,
            }}
            leftIcon={<Icon as={ChevronLeftIcon} />}
            onClick={() => onBack && onBack()}
          >
            {t("button.backToLogin")}
          </Button>
          <Button
            size={["sm", "sm", "sm", "sm", "sm", "md"]}
            sx={{
              flex: 1,
              bgColor: "#212B36",
              color: "#FFFFFF",
            }}
            _hover={{ bgColor: "none" }}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            {t("button.confirm")}
          </Button>
        </Box>
      </Stack>
    );
  };

  const _renderAfterConfirm = () => {
    return (
      <Stack
        p={[5, 5, 5, 5, 5, 10, 12]}
        borderRightRadius={10}
        background="white"
        justifyContent="flex-start"
      >
        <Stack mb={5} direction="row" alignItems="center">
          <Image src={logo} sx={{ w: 50, height: 50 }} />
          <Heading fontSize="24px" fontWeight={600} color="#000">
            {t("title.workspace")}
          </Heading>
        </Stack>
        <Stack mb={5} textAlign="center">
          <Text
            fontWeight={600}
            color="#000"
            textAlign="center"
            fontSize={{
              base: "1.5rem",
              md: "1.6rem",
              lg: "1.7rem",
              xl: "1.8rem",
            }}
          >
            {t("label.forgotPassword")}
            <Text fontSize="14px" color="gray">
              {t("label.thePasswordResetLinkwillBeSentToYourEmail")}
            </Text>
          </Text>
        </Stack>
        <Box
          as="img"
          src={forgot_background}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 5,
            gap: 3,
          }}
        >
          <Button
            size={["sm", "sm", "sm", "sm", "sm", "md"]}
            variant="text"
            sx={{
              flex: 1,
            }}
            leftIcon={<Icon as={ChevronLeftIcon} />}
            onClick={() => {
              if (onBack) {
                reset();
                onBack();
              }
            }}
          >
            {t("button.backToLogin")}
          </Button>
        </Box>
      </Stack>
    );
  };

  const _renderStepContent = () => {
    switch (step) {
      case 1:
        return _renderBeforeConfirm();
      case 2:
        return _renderAfterConfirm();
      default:
        return null;
    }
  };

  return _renderStepContent();
};

export default ForgotPassword;
