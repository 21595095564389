import CreateChat from "./CreateChat";
import CreateGroup from "./CreateGroup";
import ChatDetail from "./ChatDetail";
import SelectNewAdmin from "./SelectNewAdmin";
import Member from "./Member";
import UpdateClearFileDialog from "./UpdateClearFile";
import AdminSettingClearChatDialog from "./AdminSettingClearFile";
import UpdateTimeDeleteFilePrivateChatDialog from "./UpdateTimeDeleteFilePrivateChat";

export default {
  CreateGroup,
  CreateChat,
  ChatDetail,
  SelectNewAdmin,
  Member,
  UpdateClearFileDialog,
  AdminSettingClearChatDialog,
  UpdateTimeDeleteFilePrivateChatDialog,
};
