enum Routers {
  // HOME = "/",
  // AUTHENTICATION
  SIGN_IN = "/",
  ACCOUNT_DEACTIVATED = "account-deactivated",
  COMING_SOON = "coming-soon",
  ERROR_404 = "error-404",
  ERROR_500 = "error-500",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/auth/forgot-password",
  VERIFY_ACCOUNT = "/verify-account",
  VERIFY_ACCOUNT_SUCCESS = "/verify-account-success",
  VERIFY_ACCOUNT_FAIL = "/verify-account-failure",

  DASHBOARD = "/dashboard",
  USER = "/users",
  USER_UPDATE = "/users/update",
  USER_CREATE = "/users/create",
  PROJECT = "/projects",
  PROJECT_LIST = "/projects/list",
  PROJECT_DETAILS = "/projects/detail",
  CREATE_PROJECT = "/projects/create",
  UPDATE_PROJECT = "/projects/update",
  PERFORMANCE = "/performance",
  BOARD = "/board",
  BOARD_DETAIL = "/board/detail",
  BOARD_EMPLOYEE = "/board/employee",
  TIME_CARD = "/time-card",
  TIME_SHEET = "/time-card/attendance-sheet",
  DAY_OFF_REQUEST = "/time-card/day-off-request",
  OVER_TIME = "/time-card/over-time",
  LEAVE_DAY = "/time-card/leave-day",
  TIME_KEEPPING = "/time-card/time-sheet",
  REQUEST_UPDATE_TIME_CARD = "/time-card/request-update",
  CHAT = "/chat",
  MY_TIME = "/my-time",
  PROFILE = "/profile",
  NOTIFICATION = "/notifications",
  HOLIDAYS = "/time-card/attendance-sheet/holidays",
  SETTING = "/setting",
  INTERNAL_IP = "/setting/internal-ip",
  CLIEINT = "/clients",
  WORKING_TIME = "/setting/working-time",
  WORKING_DAY = "/setting/working-day",
  SHARE_TIMELINE = "/share-timeline",
  CREATE_PROJECT_EXTERIOR = "/projects/create-exterior",
  UPDATE_PROJECT_EXTERIOR = "/projects/update-exterior",
  DETAIL_PROJECT_EXTERIOR = "/projects/detail-exterior",
  GET_MY_INTERNAL_IP = "/setting/my-internal-ip",
  BACKUP_RESTORE = "/backup",
  FILE_SYSTEM = "/setting/file-system",
  WORKSPACE = "/workspace",
  WORKSPACE_GROUP = "/shortcut",
}

export default Routers;
