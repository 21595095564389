import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateHoliday, IFilterHoliday } from "@/Interfaces/Holiday.interface";

const { HOLIDAY } = APIs;

export const fetchHoliday = async (payload: IFilterHoliday) => {
  return request(HOLIDAY.BASIC, "GET", payload);
};

export const getHolidayById = async (id: string) => {
  return request(`${HOLIDAY.GET_BY_ID}/${id}`, "GET");
};

export const createHoliday = async (payload: ICreateHoliday) => {
  return request(HOLIDAY.BASIC, "POST_FORM_DATA", payload);
};

export const updateHoliday = async (id: string, payload: ICreateHoliday) => {
  return request(`${HOLIDAY.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const ChangeStatusHoliday = async (id: string, payload: any) => {
  return request(`${HOLIDAY.CHANGE_STATUS}/${id}`, "PUT_FORM_DATA", payload);
};
