import React from "react";
import _ from "lodash";
import {
  //  Avatar,
  Box,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { Table } from "rsuite";

import { NoDataFound } from "@/Components/Common";
import { type IPaginate, type TActions } from "@/Types/Common.types";
import { EyeIcon } from "@heroicons/react/24/outline";
import { IDataLeaveDays } from "@/Interfaces/LeaveDays.interface";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: IDataLeaveDays[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  year?: string;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const LeaveDaysDataTable: React.FC<IProps> = ({ payload, isLoading, year }) => {
  const userData = Utils.getSavedUserData();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const t = useTranslation();

  const checkLeaveDays = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LeaveDays",
    "checkLeaveDays"
  );

  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={payload}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        height={isMobile ? 400 : 550}
      >
        {checkLeaveDays && (
          <Column width={200} fixed={!isMobile} resizable>
            <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
              {t("table.fullName")}
            </HeaderCell>
            <Cell dataKey="user.userData.fullName" />
          </Column>
        )}
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.leaveDays")}
          </HeaderCell>
          <Cell dataKey="leaveDay" />
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.bonus")}
          </HeaderCell>
          <Cell dataKey="bonus" />
        </Column>
        <Column flexGrow={1} align="center" width={120}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.totalLeaveDays")}
          </HeaderCell>
          <Cell>{(rowData) => Number(rowData?.leaveDay + rowData?.bonus)}</Cell>
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.dayOff")}
          </HeaderCell>
          <Cell dataKey="dayOff" />
        </Column>
        <Column flexGrow={1} align="center" width={120}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.totalRemaining")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              Number(rowData?.leaveDay + rowData?.bonus - rowData?.dayOff)
            }
          </Cell>
        </Column>
        {checkLeaveDays && (
          <Column align="center" width={70} fixed="right">
            <HeaderCell
              verticalAlign="center"
              align="center"
              style={HEADER_STYLE}
            >
              {t("table.actions")}
            </HeaderCell>
            <Cell style={{ padding: "5px" }}>
              {(rowData: any) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip label={t("tooltip.view")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={EyeIcon} />}
                        aria-label={""}
                        onClick={() => {
                          Utils.redirect(
                            `${Routers.LEAVE_DAY}?id=${rowData?.id}&year=${year}`
                          );
                        }}
                      />
                    </Tooltip>
                  </Box>
                );
              }}
            </Cell>
          </Column>
        )}
      </Table>
    </Box>
    // <Table
    //   columns={checkLeaveDays ? Columns : ColumnsUser}
    //   payload={rows}
    //   //  paginate={paginate}
    //   onPageChange={onPageChange}
    //   showTotal={showTotal}
    //   isLoading={isLoading}
    // />
  );
};

export default LeaveDaysDataTable;
