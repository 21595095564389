import React, { useState, useEffect } from "react";
import _ from "lodash";
import DateRangePicker, {
  DateRangePickerProps,
} from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  type InputProps,
  Stack,
} from "@chakra-ui/react";
import Utils from "@/Utils";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  value?: any;
  onDateChange(newDate: Date[] | string[] | any): void;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateRange = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      value,
      onDateChange,
      format = "dd/MM/yyyy",
      minDate,
      maxDate,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [key, setKey] = useState(Math.random());
    const language = Utils.getSavedLanguage();

    const config: DateRangePickerProps = {
      closeCalendar: true,
      onFocus: () => setIsFocus(true),
      onBlur: () => setIsFocus(false),
      value,
      format,
      ...(minDate && { minDate }),
      ...(maxDate && { maxDate }),
      onChange: (value) => onDateChange(value as any),
    };

    useEffect(() => {}, [ref]);

    return (
      <FormControl sx={style} isInvalid={isError} isRequired={props.isRequired}>
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Stack
          sx={{
            fontSize: 14,
            ".react-daterange-picker.react-daterange-picker--closed.react-daterange-picker--enabled":
              {
                height: "100%",
              },
            ".react-daterange-picker__wrapper": {
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              // "#E53E3E"
              //       : "#3182ce"
              outline: `2px solid ${
                isFocus ? "#3182ce" : isError ? "#E53E3E" : "transparent"
              }`,
              outlineOffset: "-2px",
            },
            ".react-daterange-picker__inputGroup": {
              height: "100%",
              px: "12px",
              input: {
                outline: "none",
              },
            },
            ".react-daterange-picker__clock": {
              display: "none",
            },
          }}
        >
          <DateRangePicker
            key={key}
            onCalendarClose={() => setKey(Math.random())}
            {...config}
            {...(_.isEmpty(value) ? { clearIcon: true } : { clearIcon: null })}
            locale={language === "en" ? "en" : "vi"}
          />
        </Stack>
        {!_.isEmpty(errorMessage) ? (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        ) : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default DateRange;
