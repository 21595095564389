import React, { useEffect, useState, useMemo, useRef } from "react";
import _ from "lodash";

import { Icon, Link, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Pagination, Table } from "rsuite";

import { IconButton, Text, Box, Avatar } from "@chakra-ui/react";
import { ArrowPathIcon, PencilSquareIcon } from "@heroicons/react/24/outline";

import { RootState, useTypedDispatch } from "@/Store";
import { TextComponent, NoDataFound } from "@/Components/Common";
import { ENUMS, Routers } from "@/Constants";
import Utils from "@/Utils";
import { PerformanceActions, UserActions } from "@/Actions";
import { useWindowWidth } from "@/Helpers";

import { PROJECT_STATUS_COLOR } from "@/Constants/Enums.contant";
import { IPerformanceFilter } from "@/Interfaces/Performance.interface";
import { IProjectStructure } from "@/Interfaces/Project.interface";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const { fetchPerformances, performAction, resetPerformancePerformAction } =
  PerformanceActions;
const { performAction: userPerformAction } = UserActions;

const PerformanceDataTable: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const payload: any = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "payload")
  );

  const pagination: IPerformanceFilter = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "pagination")
  );
  const paginate: any = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "meta")
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "isFetchLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "isActionLoading")
  );
  const userData = Utils.getSavedUserData();
  const canEditAmountOfWork = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Performance",
    "editAmountOfWork"
  );
  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const tableRef = useRef<any>(null);
  const [rows, setRows] = useState<any>([]);

  //  const sortProjects = useMemo(() => {
  //    if (!payload || payload.length === 0) return [];
  //    return [...payload].sort((a, b) => {
  //      if (a?.clientNew?.name < b?.clientNew?.name) return -1;
  //      if (a?.clientNew?.name > b?.clientNew?.name) return 1;
  //      return a.name.localeCompare(b.name);
  //    });
  //  }, [payload]);

  const sortedProjects = useMemo(() => {
    if (!payload || payload.length === 0) return [];
    const sortedClient = _.sortBy(payload, ["name"]);
    return _.sortBy(sortedClient, [
      (item) => _.get(item, "clientNew.name", "").toLowerCase(),
    ]);
  }, [payload]);

  const getTotalLogTimes = (projectMember: any[], members: any[]) => {
    return _.sumBy(
      _.filter(projectMember, (item) =>
        _.includes(
          _.map(members, (member) => member?.user?.id),
          _.get(item, "user.id")
        )
      ),
      (item) => _.get(item, "user.logTime")?.length || 1
    );
  };

  const resolveData = () => {
    const rows: any[] = [];
    let index = 0;

    _.forEach(sortedProjects, (proj) => {
      const projectMember = proj?.projectMember;
      if (!_.isEmpty(projectMember)) {
        const managers = proj?.projectMember.filter((item: any) => {
          return _.some(
            item?.user?.userRole,
            (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
          );
        });

        const filteredMembers = proj?.projectMember.filter((item: any) => {
          return !_.some(managers, (manager) => manager.id === item.id);
        });
        const members = sortUserByName(filteredMembers, false);
        // const leaders = proj?.projectMember.filter((item: any) => {
        //   return _.some(
        //     item?.user?.userRole,
        //     (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
        //   );
        // });

        // const sortedLeaders = sortUserByName(leaders, false);

        // const artists = proj?.projectMember.filter((item: any) => {
        //   const hasArtistRole = _.some(
        //     item?.user?.userRole,
        //     (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        //   );

        //   const hasLeaderRole = _.some(
        //     item?.user?.userRole,
        //     (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
        //   );

        //   return hasArtistRole && !hasLeaderRole;
        // });

        // const sortedArtists = sortUserByName(artists, false);

        // const members = [...sortedLeaders, ...sortedArtists];
        const joinedTasks = _.map(
          proj?.kanbanBoard?.task,
          (item) => item?.title
        ).join(", ");

        const isMemberLonger = members.length >= managers.length;
        const totalLogTimes = getTotalLogTimes(projectMember, members);

        let currentUserIndex = -1;

        if (totalLogTimes < (_.size(managers) || _.size(members))) {
          if (isMemberLonger) {
            _.forEach(members, (member, memberIndex: number) => {
              const getLogByUserId = _.filter(
                projectMember,
                (pMember) => pMember?.user?.id === member?.user?.id
              )[0];
              if (memberIndex !== currentUserIndex) {
                currentUserIndex = memberIndex;
              }
              const userLogged = _.get(getLogByUserId, "user.logTime");
              if (!_.isEmpty(userLogged)) {
                const groupedLogTime: any = {};
                _.forEach(userLogged, (logTime) => {
                  const taskId = logTime?.task?.id;
                  if (taskId) {
                    if (!groupedLogTime[taskId]) {
                      groupedLogTime[taskId] = [];
                    }
                    groupedLogTime[taskId].push(logTime);
                  }
                });
                let currentProcessIndex = -1;

                _.forEach(
                  Object.keys(groupedLogTime),
                  (gLogTime, gLogTimeIndex) => {
                    if (gLogTimeIndex !== currentProcessIndex) {
                      currentProcessIndex = gLogTimeIndex;
                    }
                    const process = _.filter(
                      proj?.kanbanBoard?.task,
                      (process) => process?.id === gLogTime
                    )[0];
                    _.forEach(
                      groupedLogTime[gLogTime],
                      (logTime, logIndex: number) => {
                        const matchingPerformance = _.find(
                          proj?.performanceEvaluation,
                          (evaluation: any) =>
                            evaluation?.user?.id ===
                            (member ? member?.user?.id : null)
                        );
                        const performanceForm = {
                          id: matchingPerformance?.id,
                          project: proj,
                          member,
                          performance: matchingPerformance?.performance || 0,
                          workContent: joinedTasks || "",
                          amountOfWork: matchingPerformance?.amountOfWork || "",
                          note: matchingPerformance?.note || "",
                          performanceLog:
                            matchingPerformance?.performanceEvaluationLog || [],
                          userReview: matchingPerformance?.userReview,
                          createdAt: matchingPerformance?.createdAt || "",
                          status: proj?.status || "",
                          userLogged,
                        };

                        const isShowCommonRowSpan =
                          memberIndex === 0 &&
                          gLogTimeIndex === 0 &&
                          logIndex === 0;
                        const isShowMemberRowSpan =
                          memberIndex === currentUserIndex &&
                          gLogTimeIndex === 0 &&
                          logIndex === 0;
                        const isShowProcessRowSpan =
                          gLogTimeIndex === currentProcessIndex &&
                          logIndex === 0;
                        index += 1;
                        let initRow: any = {
                          id: index,
                          projectId: proj?.id,
                          kanbanBoardId: proj?.kanbanBoard?.id,
                          name: proj?.name,
                          clientNew: proj?.clientNew?.name,
                          code: proj?.code,
                          serverPath: proj?.serverPath,
                          status: proj?.status,
                          managers,
                          member,
                          joinedTasks: joinedTasks || "",
                          amountOfWork: matchingPerformance?.amountOfWork || "",
                          performance: matchingPerformance?.performance
                            ? `${matchingPerformance?.performance}%`
                            : "",
                          note: matchingPerformance?.note || "",
                          reviewer: matchingPerformance?.userReview,
                          performanceForm,
                          isMemberLonger,
                          process,
                          log: logTime,
                          commonRowSpan: isShowCommonRowSpan && _.size(members),
                          isShowMember: isShowMemberRowSpan,
                          memberRowSpan: isShowMemberRowSpan
                            ? _.size(userLogged)
                            : false,
                          isShowProcess: isShowProcessRowSpan,
                          processRowSpan: isShowProcessRowSpan
                            ? _.size(groupedLogTime[gLogTime])
                            : false,
                          type: proj?.type,
                        };
                        rows.push(initRow);
                      }
                    );
                  }
                );
              } else {
                const isShowCommonRowSpan = memberIndex === 0;
                const isShowMemberRowSpan = memberIndex === currentUserIndex;
                // handle empty log
                const matchingPerformance = _.find(
                  proj?.performanceEvaluation,
                  (evaluation: any) =>
                    evaluation?.user?.id === (member ? member?.user?.id : null)
                );
                const performanceForm = {
                  id: matchingPerformance?.id,
                  project: proj,
                  member,
                  performance: matchingPerformance?.performance || 0,
                  workContent: joinedTasks || "",
                  amountOfWork: matchingPerformance?.amountOfWork || "",
                  note: matchingPerformance?.note || "",
                  performanceLog:
                    matchingPerformance?.performanceEvaluationLog || [],
                  userReview: matchingPerformance?.userReview,
                  createdAt: matchingPerformance?.createdAt || "",
                  status: proj?.status || "",
                  userLogged: null,
                };
                // const getManager = managers[memberIndex];
                index += 1;
                let initRow: any = {
                  id: index,
                  projectId: proj?.id,
                  kanbanBoardId: proj?.kanbanBoard?.id,
                  name: proj?.name,
                  clientNew: proj?.clientNew?.name,
                  code: proj?.code,
                  serverPath: proj?.serverPath,
                  status: proj?.status,
                  managers,
                  member,
                  joinedTasks: joinedTasks || "",
                  amountOfWork: matchingPerformance?.amountOfWork || "",
                  performance: matchingPerformance?.performance
                    ? `${matchingPerformance?.performance}%`
                    : "",
                  note: matchingPerformance?.note || "",
                  reviewer: matchingPerformance?.userReview,
                  commonRowSpan: isShowCommonRowSpan && _.size(members),
                  isShowMember: isShowMemberRowSpan,
                  memberRowSpan: isShowMemberRowSpan
                    ? _.size(userLogged)
                    : false,
                  performanceForm,
                  isMemberLonger,
                  type: proj?.type,
                };
                rows.push(initRow);
              }
            });
          } else {
            if (!_.isEmpty(members)) {
              _.forEach(managers, (_manager, managerIndex: number) => {
                const getMember = members[managerIndex];
                if (getMember) {
                  const getLogByUserId = _.filter(
                    projectMember,
                    (pMember) => pMember?.user?.id === getMember?.user?.id
                  )[0];
                  if (managerIndex !== currentUserIndex) {
                    currentUserIndex = managerIndex;
                  }
                  const userLogged = _.get(getLogByUserId, "user.logTime");

                  if (!_.isEmpty(userLogged)) {
                    const groupedLogTime: any = {};
                    _.forEach(userLogged, (logTime) => {
                      const taskId = logTime?.task?.id;
                      if (taskId) {
                        if (!groupedLogTime[taskId]) {
                          groupedLogTime[taskId] = [];
                        }
                        groupedLogTime[taskId].push(logTime);
                      }
                    });
                    let currentProcessIndex = -1;
                    _.forEach(
                      Object.keys(groupedLogTime),
                      (gLogTime, gLogTimeIndex) => {
                        if (gLogTimeIndex !== currentProcessIndex) {
                          currentProcessIndex = gLogTimeIndex;
                        }
                        const process = _.filter(
                          proj?.kanbanBoard?.task,
                          (process) => process?.id === gLogTime
                        )[0];
                        _.forEach(
                          groupedLogTime[gLogTime],
                          (logTime, logIndex: number) => {
                            const matchingPerformance = _.find(
                              proj?.performanceEvaluation,
                              (evaluation: any) =>
                                evaluation?.user?.id ===
                                (getMember ? getMember?.user?.id : null)
                            );
                            const performanceForm = {
                              id: matchingPerformance?.id,
                              project: proj,
                              member: getMember,
                              performance:
                                matchingPerformance?.performance || 0,
                              workContent: joinedTasks || "",
                              amountOfWork:
                                matchingPerformance?.amountOfWork || "",
                              note: matchingPerformance?.note || "",
                              performanceLog:
                                matchingPerformance?.performanceEvaluationLog ||
                                [],
                              userReview: matchingPerformance?.userReview,
                              createdAt: matchingPerformance?.createdAt || "",
                              status: proj?.status || "",
                              userLogged,
                            };

                            const isShowCommonRowSpan =
                              managerIndex === 0 &&
                              gLogTimeIndex === 0 &&
                              logIndex === 0;

                            const isShowMemberRowSpan =
                              managerIndex === currentUserIndex &&
                              gLogTimeIndex === 0 &&
                              logIndex === 0;
                            const isShowProcessRowSpan =
                              gLogTimeIndex === currentProcessIndex &&
                              logIndex === 0;

                            index += 1;
                            let initRow: any = {
                              id: index,
                              projectId: proj?.id,
                              kanbanBoardId: proj?.kanbanBoard?.id,
                              name: proj?.name,
                              clientNew: proj?.clientNew?.name,
                              code: proj?.code,
                              serverPath: proj?.serverPath,
                              status: proj?.status,
                              managers,
                              member: getMember,
                              joinedTasks: joinedTasks || "",
                              amountOfWork:
                                matchingPerformance?.amountOfWork || "",
                              performance: matchingPerformance?.performance
                                ? `${matchingPerformance?.performance}%`
                                : "",
                              note: matchingPerformance?.note || "",
                              reviewer: matchingPerformance?.userReview,
                              performanceForm,
                              isMemberLonger,
                              process,
                              log: logTime,
                              commonRowSpan:
                                isShowCommonRowSpan && _.size(managers),
                              isShowMember: isShowMemberRowSpan,
                              memberRowSpan: isShowMemberRowSpan
                                ? _.size(userLogged)
                                : false,
                              isShowProcess: isShowProcessRowSpan,
                              processRowSpan: isShowProcessRowSpan
                                ? _.size(groupedLogTime[gLogTime])
                                : false,
                              type: proj?.type,
                            };
                            rows.push(initRow);
                          }
                        );
                      }
                    );
                  } else {
                    // handle member empty log
                    const isShowCommonRowSpan = managerIndex === 0;
                    const matchingPerformance = _.find(
                      proj?.performanceEvaluation,
                      (evaluation: any) =>
                        evaluation?.user?.id ===
                        (getMember ? getMember?.user?.id : null)
                    );
                    const performanceForm = {
                      id: matchingPerformance?.id,
                      project: proj,
                      member: getMember,
                      performance: matchingPerformance?.performance || 0,
                      workContent: joinedTasks || "",
                      amountOfWork: matchingPerformance?.amountOfWork || "",
                      note: matchingPerformance?.note || "",
                      performanceLog:
                        matchingPerformance?.performanceEvaluationLog || [],
                      userReview: matchingPerformance?.userReview,
                      createdAt: matchingPerformance?.createdAt || "",
                      status: proj?.status || "",
                      userLogged: null,
                    };
                    index += 1;
                    let initRow: any = {
                      id: index,
                      projectId: proj?.id,
                      kanbanBoardId: proj?.kanbanBoard?.id,
                      name: proj?.name,
                      clientNew: proj?.clientNew?.name,
                      code: proj?.code,
                      serverPath: proj?.serverPath,
                      status: proj?.status,
                      managers,
                      member: getMember,
                      joinedTasks: joinedTasks || "",
                      amountOfWork: matchingPerformance?.amountOfWork || "",
                      performance: matchingPerformance?.performance
                        ? `${matchingPerformance?.performance}%`
                        : "",
                      note: matchingPerformance?.note || "",
                      reviewer: matchingPerformance?.userReview,
                      commonRowSpan: isShowCommonRowSpan
                        ? totalLogTimes
                        : false,
                      performanceForm,
                      isShowMember: true,
                      isShowProcess: true,
                      type: proj?.type,
                    };
                    rows.push(initRow);
                  }
                }
              });
            } else {
              // handle empty members
              index += 1;
              const initRow: any = createEmptyMember(proj, index, managers);
              rows.push(initRow);
            }
          }
        } else {
          if (isMemberLonger) {
            _.forEach(members, (member, memberIndex: number) => {
              const getLogByUserId = _.filter(
                projectMember,
                (pMember) => pMember?.user?.id === member?.user?.id
              )[0];
              if (memberIndex !== currentUserIndex) {
                currentUserIndex = memberIndex;
              }
              const userLogged = _.get(getLogByUserId, "user.logTime");

              if (!_.isEmpty(userLogged)) {
                const groupedLogTime: any = {};
                _.forEach(userLogged, (logTime) => {
                  const taskId = logTime?.task?.id;
                  if (taskId) {
                    if (!groupedLogTime[taskId]) {
                      groupedLogTime[taskId] = [];
                    }
                    groupedLogTime[taskId].push(logTime);
                  }
                });

                let currentProcessIndex = -1;
                _.forEach(
                  Object.keys(groupedLogTime),
                  (gLogTime, gLogTimeIndex) => {
                    if (gLogTimeIndex !== currentProcessIndex) {
                      currentProcessIndex = gLogTimeIndex;
                    }
                    const process = _.filter(
                      proj?.kanbanBoard?.task,
                      (process) => process?.id === gLogTime
                    )[0];
                    _.forEach(
                      groupedLogTime[gLogTime],
                      (logTime, logIndex: number) => {
                        const matchingPerformance = _.find(
                          proj?.performanceEvaluation,
                          (evaluation: any) =>
                            evaluation?.user?.id ===
                            (member ? member?.user?.id : null)
                        );
                        const performanceForm = {
                          id: matchingPerformance?.id,
                          project: proj,
                          member,
                          performance: matchingPerformance?.performance || 0,
                          workContent: joinedTasks || "",
                          amountOfWork: matchingPerformance?.amountOfWork || "",
                          note: matchingPerformance?.note || "",
                          performanceLog:
                            matchingPerformance?.performanceEvaluationLog || [],
                          userReview: matchingPerformance?.userReview,
                          createdAt: matchingPerformance?.createdAt || "",
                          status: proj?.status || "",
                          userLogged,
                        };

                        const isShowCommonRowSpan =
                          memberIndex === 0 &&
                          gLogTimeIndex === 0 &&
                          logIndex === 0;
                        const isShowMemberRowSpan =
                          memberIndex === currentUserIndex &&
                          gLogTimeIndex === 0 &&
                          logIndex === 0;
                        const isShowProcessRowSpan =
                          gLogTimeIndex === currentProcessIndex &&
                          logIndex === 0;
                        index += 1;
                        let initRow: any = {
                          id: index,
                          projectId: proj?.id,
                          kanbanBoardId: proj?.kanbanBoard?.id,
                          name: proj?.name,
                          clientNew: proj?.clientNew?.name,
                          code: proj?.code,
                          serverPath: proj?.serverPath,
                          status: proj?.status,
                          managers,
                          member,
                          joinedTasks: joinedTasks || "",
                          amountOfWork: matchingPerformance?.amountOfWork || "",
                          performance: matchingPerformance?.performance
                            ? `${matchingPerformance?.performance}%`
                            : "",
                          note: matchingPerformance?.note || "",
                          reviewer: matchingPerformance?.userReview,
                          performanceForm,
                          isMemberLonger,
                          process,
                          log: logTime,
                          commonRowSpan: isShowCommonRowSpan
                            ? totalLogTimes
                            : false,
                          isShowMember: isShowMemberRowSpan,
                          memberRowSpan: isShowMemberRowSpan
                            ? _.size(userLogged)
                            : false,
                          isShowProcess: isShowProcessRowSpan,
                          processRowSpan: isShowProcessRowSpan
                            ? _.size(groupedLogTime[gLogTime])
                            : false,
                          type: proj?.type,
                        };
                        rows.push(initRow);
                      }
                    );
                  }
                );
              } else {
                const isShowCommonRowSpan = memberIndex === 0;
                const commonRowSpan =
                  totalLogTimes > _.size(members)
                    ? totalLogTimes
                    : _.size(members);
                const isShowMemberRowSpan = memberIndex === currentUserIndex;

                // handle empty log
                const matchingPerformance = _.find(
                  proj?.performanceEvaluation,
                  (evaluation: any) =>
                    evaluation?.user?.id === (member ? member?.user?.id : null)
                );
                const performanceForm = {
                  id: matchingPerformance?.id,
                  project: proj,
                  member,
                  performance: matchingPerformance?.performance || 0,
                  workContent: joinedTasks || "",
                  amountOfWork: matchingPerformance?.amountOfWork || "",
                  note: matchingPerformance?.note || "",
                  performanceLog:
                    matchingPerformance?.performanceEvaluationLog || [],
                  userReview: matchingPerformance?.userReview,
                  createdAt: matchingPerformance?.createdAt || "",
                  status: proj?.status || "",
                  userLogged: null,
                };
                index += 1;
                let initRow: any = {
                  id: index,
                  projectId: proj?.id,
                  kanbanBoardId: proj?.kanbanBoard?.id,
                  name: proj?.name,
                  clientNew: proj?.clientNew?.name,
                  code: proj?.code,
                  serverPath: proj?.serverPath,
                  status: proj?.status,
                  managers,
                  member,
                  joinedTasks: joinedTasks || "",
                  amountOfWork: matchingPerformance?.amountOfWork || "",
                  performance: matchingPerformance?.performance
                    ? `${matchingPerformance?.performance}%`
                    : "",
                  note: matchingPerformance?.note || "",
                  reviewer: matchingPerformance?.userReview,
                  commonRowSpan: isShowCommonRowSpan ? commonRowSpan : false,
                  performanceForm,
                  isMemberLonger,
                  isShowMember: isShowMemberRowSpan,
                  memberRowSpan: isShowMemberRowSpan
                    ? _.size(userLogged) || 1
                    : false,
                  type: proj?.type,
                };
                rows.push(initRow);
              }
            });
          } else {
            // handle managers longer members
            if (!_.isEmpty(members)) {
              _.forEach(managers, (_manager, managerIndex: number) => {
                const getMember = members[managerIndex];
                if (getMember) {
                  const getLogByUserId = _.filter(
                    projectMember,
                    (pMember) => pMember?.user?.id === getMember?.user?.id
                  )[0];
                  if (managerIndex !== currentUserIndex) {
                    currentUserIndex = managerIndex;
                  }
                  const userLogged = _.get(getLogByUserId, "user.logTime");

                  if (!_.isEmpty(userLogged)) {
                    const groupedLogTime: any = {};
                    _.forEach(userLogged, (logTime) => {
                      const taskId = logTime?.task?.id;
                      if (taskId) {
                        if (!groupedLogTime[taskId]) {
                          groupedLogTime[taskId] = [];
                        }
                        groupedLogTime[taskId].push(logTime);
                      }
                    });
                    let currentProcessIndex = -1;
                    _.forEach(
                      Object.keys(groupedLogTime),
                      (gLogTime, gLogTimeIndex) => {
                        if (gLogTimeIndex !== currentProcessIndex) {
                          currentProcessIndex = gLogTimeIndex;
                        }
                        const process = _.filter(
                          proj?.kanbanBoard?.task,
                          (process) => process?.id === gLogTime
                        )[0];
                        _.forEach(
                          groupedLogTime[gLogTime],
                          (logTime, logIndex: number) => {
                            const matchingPerformance = _.find(
                              proj?.performanceEvaluation,
                              (evaluation: any) =>
                                evaluation?.user?.id ===
                                (getMember ? getMember?.user?.id : null)
                            );
                            const performanceForm = {
                              id: matchingPerformance?.id,
                              project: proj,
                              member: getMember,
                              performance:
                                matchingPerformance?.performance || 0,
                              workContent: joinedTasks || "",
                              amountOfWork:
                                matchingPerformance?.amountOfWork || "",
                              note: matchingPerformance?.note || "",
                              performanceLog:
                                matchingPerformance?.performanceEvaluationLog ||
                                [],
                              userReview: matchingPerformance?.userReview,
                              createdAt: matchingPerformance?.createdAt || "",
                              status: proj?.status || "",
                              userLogged,
                            };

                            const isShowCommonRowSpan =
                              managerIndex === 0 &&
                              gLogTimeIndex === 0 &&
                              logIndex === 0;
                            const isShowMemberRowSpan =
                              managerIndex === currentUserIndex &&
                              gLogTimeIndex === 0 &&
                              logIndex === 0;
                            const isShowProcessRowSpan =
                              gLogTimeIndex === currentProcessIndex &&
                              logIndex === 0;

                            index += 1;
                            let initRow: any = {
                              id: index,
                              projectId: proj?.id,
                              kanbanBoardId: proj?.kanbanBoard?.id,
                              name: proj?.name,
                              clientNew: proj?.clientNew?.name,
                              code: proj?.code,
                              serverPath: proj?.serverPath,
                              status: proj?.status,
                              managers,
                              member: getMember,
                              joinedTasks: joinedTasks || "",
                              amountOfWork:
                                matchingPerformance?.amountOfWork || "",
                              performance: matchingPerformance?.performance
                                ? `${matchingPerformance?.performance}%`
                                : "",
                              note: matchingPerformance?.note || "",
                              reviewer: matchingPerformance?.userReview,
                              performanceForm,
                              isMemberLonger,
                              process,
                              log: logTime,
                              commonRowSpan:
                                isShowCommonRowSpan && totalLogTimes,
                              isShowMember: isShowMemberRowSpan,
                              memberRowSpan: isShowMemberRowSpan
                                ? _.size(userLogged)
                                : false,
                              isShowProcess: isShowProcessRowSpan,
                              processRowSpan: isShowProcessRowSpan
                                ? _.size(groupedLogTime[gLogTime])
                                : false,
                              type: proj?.type,
                            };
                            rows.push(initRow);
                          }
                        );
                      }
                    );
                  } else {
                    // handle member empty log
                    const isShowCommonRowSpan = managerIndex === 0;
                    const commonRowSpan =
                      totalLogTimes < _.size(managers)
                        ? _.size(managers)
                        : totalLogTimes;
                    const matchingPerformance = _.find(
                      proj?.performanceEvaluation,
                      (evaluation: any) =>
                        evaluation?.user?.id ===
                        (getMember ? getMember?.user?.id : null)
                    );
                    const performanceForm = {
                      id: matchingPerformance?.id,
                      project: proj,
                      member: getMember,
                      performance: matchingPerformance?.performance || 0,
                      workContent: joinedTasks || "",
                      amountOfWork: matchingPerformance?.amountOfWork || "",
                      note: matchingPerformance?.note || "",
                      performanceLog:
                        matchingPerformance?.performanceEvaluationLog || [],
                      userReview: matchingPerformance?.userReview,
                      createdAt: matchingPerformance?.createdAt || "",
                      status: proj?.status || "",
                      userLogged: null,
                    };
                    index += 1;
                    let initRow: any = {
                      id: index,
                      projectId: proj?.id,
                      kanbanBoardId: proj?.kanbanBoard?.id,
                      name: proj?.name,
                      clientNew: proj?.clientNew?.name,
                      code: proj?.code,
                      serverPath: proj?.serverPath,
                      status: proj?.status,
                      managers,
                      member: getMember,
                      joinedTasks: joinedTasks || "",
                      amountOfWork: matchingPerformance?.amountOfWork || "",
                      performance: matchingPerformance?.performance
                        ? `${matchingPerformance?.performance}%`
                        : "",
                      note: matchingPerformance?.note || "",
                      reviewer: matchingPerformance?.userReview,
                      commonRowSpan: isShowCommonRowSpan && commonRowSpan,
                      performanceForm,
                      isShowMember: true,
                      isShowProcess: true,
                      type: proj?.type,
                    };
                    rows.push(initRow);
                  }
                }
              });
            } else {
              // handle empty members
              index += 1;
              const initRow: any = createEmptyMember(proj, index, managers);
              rows.push(initRow);
            }
          }
        }
      } else {
        index += 1;
        const initRow: any = createEmptyProjectMember(proj, index);
        rows.push(initRow);
      }
    });
    return rows;
  };

  useEffect(() => {
    setRows(resolveData());
  }, [sortedProjects]);

  useEffect(() => {
    return () => {
      dispatch(resetPerformancePerformAction());
    };
  }, []);

  const createEmptyMember = (
    proj: IProjectStructure,
    index: number,
    managers: any[]
  ) => {
    return {
      id: index,
      projectId: proj?.id,
      kanbanBoardId: proj?.kanbanBoard?.id,
      name: proj?.name,
      clientNew: proj?.clientNew?.name,
      code: proj?.code,
      serverPath: proj?.serverPath,
      status: proj?.status,
      managers,
      member: {},
      joinedTasks: "",
      amountOfWork: "",
      performance: "",
      note: "",
      reviewer: {},
      performanceForm: {},
      commonRowSpan: _.size(managers),
      isShowMember: true,
      isShowProcess: true,
      type: proj?.type,
    };
  };

  const createEmptyProjectMember = (proj: IProjectStructure, index: number) => {
    return {
      id: index,
      projectId: proj?.id,
      kanbanBoardId: proj?.kanbanBoard?.id,
      name: proj?.name,
      clientNew: proj?.clientNew?.name,
      code: proj?.code,
      serverPath: proj?.serverPath,
      status: proj?.status,
      manager: undefined,
      member: undefined,
      joinedTasks: "",
      amountOfWork: "",
      performance: "",
      note: "",
      reviewer: undefined,
      performanceForm: {},
      commonRowSpan: false,
      isMemberLonger: false,
      isShowMember: true,
      isShowProcess: true,
      type: proj?.type,
    };
  };

  const onPageChange = (value: number) => {
    const newFilters = { ...pagination, page: value };
    dispatch(fetchPerformances(newFilters));
  };

  const onChangeLimit = (limit: number) => {
    const newFilters = { ...pagination, page: 1, limit };
    dispatch(fetchPerformances(newFilters));
  };

  const sortUserByName = (array: any[], descending = false) => {
    const sortOrder = descending ? -1 : 1;
    const sortedArray = [...array];
    sortedArray.sort((a, b) => {
      const valueA = String(a?.user?.userData?.fullName)?.toLowerCase();
      const valueB = String(b?.user?.userData?.fullName)?.toLowerCase();

      if (valueA < valueB) return -1 * sortOrder;
      if (valueA > valueB) return 1 * sortOrder;
      return 0;
    });

    return sortedArray;
  };

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box
      className="override-table"
      sx={{
        w: "100%",
        overflow: "auto",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={rows}
        //autoHeight
        affixHeader
        affixHorizontalScrollbar
        loading={isFetchLoading || isActionLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        bordered
        cellBordered
        height={500}
        ref={tableRef}
      >
        <Column
          width={200}
          resizable
          //fixed="left" // issue
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell verticalAlign="middle" style={HEADER_STYLE}>
            {t("table.projects")}
          </HeaderCell>
          <Cell dataKey="name">
            {(rowData) => {
              return (
                <Link
                  href={`${Routers.PROJECT_DETAILS}?id=${rowData.projectId}`}
                  isExternal
                  sx={{
                    color: "rgba(0, 0, 255,0.7)",
                    fontWeight: 550,
                    "&:hover": {
                      color: "rgba(0, 0, 255)",
                    },
                  }}
                >
                  <TextComponent content={rowData?.name} maxW="100%" />
                </Link>
              );
            }}
          </Cell>
        </Column>
        <Column
          width={100}
          resizable
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell verticalAlign="middle" style={HEADER_STYLE}>
            {t("table.type")}
          </HeaderCell>
          <Cell dataKey="type">
            {(rowData) => {
              return rowData?.type === ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internal")
                : t("label.external");
            }}
          </Cell>
        </Column>
        <Column
          align="center"
          width={100}
          resizable
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell verticalAlign="middle" style={HEADER_STYLE}>
            {t("table.client")}
          </HeaderCell>
          <Cell dataKey="clientNew">
            {(rowData) => {
              return <TextComponent content={rowData?.clientNew} maxW="100%" />;
            }}
          </Cell>
        </Column>
        <Column
          width={150}
          resizable
          // verticalAlign="middle"
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell verticalAlign="middle" style={HEADER_STYLE}>
            {t("table.serverLink")}
          </HeaderCell>
          <Cell dataKey="serverPath">
            {(rowData) => {
              return (
                <TextComponent content={rowData?.serverPath} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
        <Column
          // align="center"
          width={150}
          resizable
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell
            verticalAlign="middle"
            style={{ ...HEADER_STYLE, textAlign: "center" }}
          >
            {t("table.status")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (
                !rowData?.status ||
                (rowData?.managerIndex && rowData?.managerIndex > 0)
              )
                return null;
              return (
                <Text
                  color={
                    PROJECT_STATUS_COLOR[
                      rowData?.status as keyof typeof PROJECT_STATUS_COLOR
                    ].text
                  }
                  bgColor={
                    PROJECT_STATUS_COLOR[
                      rowData?.status as keyof typeof PROJECT_STATUS_COLOR
                    ].background
                  }
                  sx={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {t(`status.${rowData?.status}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column
          width={200}
          resizable
          rowSpan={(rowData) => rowData?.commonRowSpan}
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.managers")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.managers) return "";
              return _.map(rowData?.managers, (manager, mnIndex: number) => (
                <Box
                  key={`${manager.id}`}
                  sx={{
                    height: "24px",
                    display: "flex",
                    gap: 1,
                    mb: mnIndex === 0 ? "10px" : 0,
                    mt: mnIndex !== 0 ? "20px" : 0,
                  }}
                >
                  {
                    <Tooltip label={manager?.user?.userData?.fullName} hasArrow>
                      <Avatar
                        key={manager?.user?.userData?.avatar?.path}
                        size="xs"
                        name={manager?.user?.userData?.fullName}
                        src={manager?.user?.userData?.avatar?.path}
                        onClick={() =>
                          canViewUserProfile &&
                          dispatch(
                            userPerformAction(
                              "viewProfile",
                              manager?.id,
                              manager
                            )
                          )
                        }
                        sx={{
                          cursor: "default",
                          pointerEvents: "none",
                          // cursor: canViewUserProfile ? "pointer" : "default",
                        }}
                      />
                    </Tooltip>
                  }
                  {manager?.user?.userData?.fullName}
                </Box>
              ));
            }}
          </Cell>
        </Column>
        <Column
          width={200}
          resizable
          rowSpan={(rowData) => rowData?.memberRowSpan}
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.members")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.member || !rowData?.isShowMember) return "";
              const userRoleCodes = _.map(
                rowData?.member?.user?.userRole,
                (userRole) => userRole?.role?.roleCode
              );
              const hasMembers =
                _.includes(userRoleCodes, ENUMS.ROLES.ARTIST) ||
                _.includes(userRoleCodes, ENUMS.ROLES.LEADER) ||
                _.includes(userRoleCodes, ENUMS.ROLES.MARKETING) ||
                _.includes(userRoleCodes, ENUMS.ROLES.HUMAN_RESOURCES);
              return (
                <Box
                  key={rowData?.member?.id}
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {hasMembers ? (
                    <>
                      <Tooltip
                        label={rowData?.member?.user?.userData?.fullName}
                        hasArrow
                      >
                        <Avatar
                          key={rowData?.member?.user?.id}
                          size="xs"
                          name={rowData?.member?.user?.userData?.fullName}
                          src={rowData?.member?.user?.userData?.avatar?.path}
                          onClick={() =>
                            canViewUserProfile &&
                            dispatch(
                              userPerformAction(
                                "viewProfile",
                                rowData?.member?.user?.id,
                                rowData?.member?.user
                              )
                            )
                          }
                          sx={{
                            cursor: "default",
                            pointerEvents: "none",
                            // cursor: canViewUserProfile ? "pointer" : "default",
                          }}
                        />
                      </Tooltip>
                      {rowData?.member?.user?.userData?.fullName}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              );
            }}
          </Cell>
        </Column>
        <Column
          flexGrow={1}
          minWidth={150}
          resizable
          rowSpan={(rowData) => rowData?.processRowSpan}
        >
          <HeaderCell style={HEADER_STYLE}>{t("table.process")}</HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.isShowProcess) return "";
              return (
                <TextComponent maxW="100%" content={rowData?.process?.title} />
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} minWidth={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.workContent")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const firstTextContent = Utils.getFirstTextContent(
                rowData?.log?.description
              );
              return (
                rowData?.log && (
                  <Link
                    href={
                      rowData?.kanbanBoardId &&
                      `${Routers.BOARD_DETAIL}?id=${rowData?.kanbanBoardId}`
                    }
                    isExternal
                    sx={{
                      color: "rgba(0, 0, 255,0.7)",
                      fontWeight: 550,
                      "&:hover": {
                        color: "rgba(0, 0, 255)",
                      },
                    }}
                  >
                    <TextComponent
                      content={firstTextContent || "--"}
                      maxW="100%"
                    />
                  </Link>
                )
              );
            }}
          </Cell>
        </Column>
        {canEditAmountOfWork && (
          <Column
            align="center"
            width={120}
            resizable
            rowSpan={(rowData) => rowData?.memberRowSpan}
          >
            <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
              {t("table.amountOfWork")}
            </HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <TextComponent maxW="100%" content={rowData?.amountOfWork} />
                );
              }}
            </Cell>
          </Column>
        )}
        <Column
          align="center"
          width={120}
          resizable
          rowSpan={(rowData) => rowData?.memberRowSpan}
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.performance")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.isShowMember) return "";
              return (
                <TextComponent maxW="100%" content={rowData?.performance} />
              );
            }}
          </Cell>
        </Column>
        <Column
          flexGrow={1}
          resizable
          rowSpan={(rowData) => rowData?.memberRowSpan}
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.note")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.note) return "";
              return <TextComponent maxW="100%" content={rowData?.note} />;
            }}
          </Cell>
        </Column>
        <Column
          width={200}
          resizable
          // rowSpan={(rowData) => rowData?.memberRowSpan}
        >
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.reviewer")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              if (!rowData?.isShowMember) return "";
              return (
                <Box>
                  {!_.isEmpty(rowData?.reviewer) && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Tooltip
                        label={rowData?.reviewer?.userData?.fullName}
                        hasArrow
                      >
                        <Avatar
                          size="xs"
                          name={rowData?.reviewer?.userData?.fullName}
                          src={rowData?.reviewer?.userData?.avatar?.path}
                          onClick={() =>
                            canViewUserProfile &&
                            dispatch(
                              userPerformAction(
                                "viewProfile",
                                rowData?.reviewer?.id,
                                rowData?.reviewer
                              )
                            )
                          }
                          sx={{
                            cursor: canViewUserProfile ? "pointer" : "default",
                          }}
                        />
                      </Tooltip>
                      {rowData?.reviewer?.userData?.fullName}
                    </Box>
                  )}
                </Box>
              );
            }}
          </Cell>
        </Column>
        <Column width={100} fixed="right">
          <HeaderCell
            verticalAlign="center"
            align="center"
            style={HEADER_STYLE}
          >
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData: any) => {
              if (!rowData?.isShowMember) return "";
              const userRoleCodes = _.map(
                rowData?.member?.user?.userRole,
                (userRole) => userRole?.role?.roleCode
              );
              const hasArtistOrLeaderRole =
                _.includes(userRoleCodes, ENUMS.ROLES.ARTIST) ||
                _.includes(userRoleCodes, ENUMS.ROLES.LEADER) ||
                _.includes(userRoleCodes, ENUMS.ROLES.MARKETING) ||
                _.includes(userRoleCodes, ENUMS.ROLES.HUMAN_RESOURCES);

              if (!hasArtistOrLeaderRole) return "";
              return (
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Tooltip label={t("tooltip.view")}>
                    <IconButton
                      sx={{
                        bg: "none",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={ArrowPathIcon} />}
                      aria-label={""}
                      onClick={() =>
                        dispatch(
                          performAction(
                            "view",
                            rowData?.performanceForm?.id,
                            rowData?.performanceForm
                          )
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip label={t("tooltip.edit")}>
                    <IconButton
                      sx={{
                        bg: "none",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={PencilSquareIcon} />}
                      aria-label={""}
                      onClick={() =>
                        dispatch(
                          performAction(
                            "update",
                            rowData?.performanceForm?.id,
                            rowData?.performanceForm
                          )
                        )
                      }
                    />
                  </Tooltip>
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          locale={locale}
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile
              ? ["total", "-", "limit", "|", "pager", "skip"]
              : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
        />
      </Box>
    </Box>
  );
};

export default PerformanceDataTable;
