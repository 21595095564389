import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const initialState = {
  isLoading: false,
  isLogged: false,
  checkIsLogged: false,
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  payload: {},
  authMessage: "",
  isRequestForgotPasswordSuccess: false,
  changeLocale: false,
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_AUTH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isRequestForgotPasswordSuccess: false,
      };
    case ActionTypes.RESET_AUTH_REDUCER:
      return initialState;

    case ActionTypes.LOGOUT:
      return initialState;

    case ActionTypes.RESET_STEP_FORGOT_PASSWORD: {
      return {
        ...state,
        isRequestForgotPasswordSuccess: false,
        requestIsLoading: false,
      };
    }

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isLogged: true,
        checkIsLogged: true,
        payload: payload,
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isLogged: false,
        checkIsLogged: false,
        authMessage: payload,
        payload: {},
      };

    case ActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isLogged: true,
        role: payload,
      };
    case ActionTypes.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isLogged: false,
        authMessage: payload,
      };

    case ActionTypes.REQUEST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isRequestForgotPasswordSuccess: true,
      };
    case ActionTypes.REQUEST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isRequestForgotPasswordSuccess: false,
      };

    case ActionTypes.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ActionTypes.VERIFY_ACCOUNT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };
    case ActionTypes.CREATE_FCM_TOKEN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ActionTypes.CREATE_FCM_TOKEN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };
    case ActionTypes.REMOVE_FCM_TOKEN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ActionTypes.REMOVE_FCM_TOKEN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ActionTypes.RESET_CHECK_LOGIN:
      return {
        ...state,
        checkIsLogged: false,
      };

    case ActionTypes.CHANGE_LOCALE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        changeLocale: true,
      };
    case ActionTypes.CHANGE_LOCALE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        changeLocale: false,
      };

    default:
      return state;
  }
};
