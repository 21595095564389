import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isUpdataSuccess: false,
  isCreateSuccess: false,
  isChangeStatusSuccess: false,
  isChangeIpRemoteSuccess: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  internalList: [],
  internalDetail: {},
  internalRemote: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_INTERNAL_IP:
      return {
        ...initialState,
      };
    case ActionTypes.RESET_STATUS_INTERNAL_IP:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        isUpdataSuccess: false,
        isCreateSuccess: false,
        isChangeStatusSuccess: false,
      };
    case ActionTypes.SET_INTERNAL_IP_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_INTERNAL_IP_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_META_INTERNAL_IP:
      return {
        ...state,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_INTERNAL_IP:
      return {
        ...state,
        pagination: payload,
      };

    case ActionTypes.CREATE_INTERNAL_IP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_INTERNAL_IP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.FETCH_INTERNAL_IP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        internalList: payload,
      };

    case ActionTypes.FETCH_INTERNAL_IP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isUpdataSuccess: false,
        internalList: [],
      };

    case ActionTypes.GET_BY_ID_INTERNAL_IP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        internalDetail: payload,
      };
    case ActionTypes.GET_BY_ID_INTERNAL_IP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        internalDetail: {},
      };

    case ActionTypes.UPDATE_INTERNAL_IP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdataSuccess: true,
      };
    case ActionTypes.UPDATE_INTERNAL_IP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdataSuccess: false,
      };

    case ActionTypes.CHANGE_STATUS_INTERNAL_IP_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
      };
    case ActionTypes.CHANGE_STATUS_INTERNAL_IP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
      };

    case ActionTypes.CHANGE_INTERNAL_IP_REMOTE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeIpRemoteSuccess: true,
      };
    case ActionTypes.CHANGE_INTERNAL_IP_REMOTE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeIpRemoteSuccess: false,
      };

    case ActionTypes.FETCH_INTERNAL_REMOTE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeIpRemoteSuccess: false,
        internalRemote: payload,
      };

    case ActionTypes.FETCH_INTERNAL_REMOTE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeIpRemoteSuccess: false,
        internalRemote: {},
      };

    default:
      return state;
  }
};
