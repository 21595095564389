import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import {
  ICreateTimesheet,
  ITimesheetStructure,
} from "@/Interfaces/Timesheet.interface";

// SINGLE ACTIONS
const setTimesheetFetchLoading = () => {
  return {
    type: ActionTypes.SET_TIMESHEET_FETCH_LOADING,
  };
};

const setTimesheetGetLoading = () => {
  return {
    type: ActionTypes.SET_TIMESHEET_GET_LOADING,
  };
};

const setTimesheetActionLoading = () => {
  return {
    type: ActionTypes.SET_TIMESHEET_ACTION_LOADING,
  };
};

const resetTimesheetStatus = () => {
  return {
    type: ActionTypes.RESET_TIMESHEET_STATUS,
  };
};

const setTimesheetPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_TIMESHEET_PAGINATION,
    payload,
  };
};

const setTimesheetMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_TIMESHEET_META,
    payload,
  };
};

const resetTimesheetRecucer = () => {
  return {
    type: ActionTypes.RESET_TIMESHEET_REDUCER,
  };
};

//ASYNC ACTIONS

const fetchSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_TIMESHEET_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_TIMESHEET_FAILURE,
  };
};

const fetchTimesheets = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setTimesheetFetchLoading());
    await APIs.fetchTimesheets(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(setTimesheetMeta(result?.meta));
          dispatch(setTimesheetPagination(payload));
          dispatch(fetchSuccess(result?.items));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload?: any) => {
  return {
    type: ActionTypes.GET_TIMESHEET_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_TIMESHEET_BY_ID_FAILURE,
  };
};

const getTimesheetById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setTimesheetGetLoading());
    await APIs.getTimesheetById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdSuccess(result));
      })
      .catch(async (error) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_TIMESHEET_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_TIMESHEET_FAILURE,
  };
};

const createTimesheet = (
  pagination: any,
  payload: ICreateTimesheet,
  onSuccess: (data: ITimesheetStructure) => void
) => {
  return async (dispatch: any) => {
    dispatch(setTimesheetActionLoading());
    await APIs.createTimesheet(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchTimesheets(pagination));
          dispatch(createSuccess());
          onSuccess(result);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(createFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateSuccess = () => {
  return {
    type: ActionTypes.UPDATE_TIMESHEET_SUCCESS,
  };
};

const updateFailure = () => {
  return {
    type: ActionTypes.UPDATE_TIMESHEET_FAILURE,
  };
};

const updateTimesheet = (
  pagination: any,
  id: string,
  payload: any,
  onSuccess: (data: ITimesheetStructure) => void
) => {
  return async (dispatch: any) => {
    dispatch(setTimesheetActionLoading());
    await APIs.updateTimesheet(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchTimesheets(pagination));
          dispatch(updateSuccess());
          onSuccess(result);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ActionTypes.DELETE_TIMESHEET_SUCCESS,
  };
};

const deleteFailure = () => {
  return {
    type: ActionTypes.DELETE_TIMESHEET_FAILURE,
  };
};

const deleteTimesheet = (
  pagination: any,
  id: string,
  onSuccess: () => void
) => {
  return async (dispatch: any) => {
    dispatch(setTimesheetActionLoading());
    await APIs.deleteTimesheet(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchTimesheets(pagination));
          dispatch(deleteSuccess());
          onSuccess();
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchTimesheets,
  getTimesheetById,
  createTimesheet,
  updateTimesheet,
  deleteTimesheet,
  resetTimesheetRecucer,
  resetTimesheetStatus,
};
