import React, { useRef, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { EventClickArg, EventContentArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
// import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import {
  Stack,
  Heading,
  HStack,
  Box,
  CircularProgress,
  Text,
  // Button,
  // Icon,
  // Popover,
  // PopoverArrow,
  // PopoverBody,
  // PopoverCloseButton,
  // PopoverContent,
  // PopoverHeader,
  // PopoverTrigger,
  // Portal,
  Tooltip,
} from "@chakra-ui/react";

import { LogtimeActions, HolidayActions, SocketActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { ENUMS } from "@/Constants";

import { calendarStyles } from "./Calendar.style";
import { TextComponent } from "@/Components/Common";
import dayjs from "dayjs";
import Utils from "@/Utils";
import { MoreOptionsDialog } from "@/Components/Popup";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";
import { useWindowWidth } from "@/Helpers";

const { performAction } = LogtimeActions;
const { fetchHoliday, resetHolidayReducer } = HolidayActions;

const { saveCurrentDateInScroller } = SocketActions;

interface ISectionProps {
  onRowAction: (action: any) => void;
  onDateClick: (date: string) => void;
  activeDates: string[];
  activeView: "month" | "week";
  minSlotWidthDate: number;
  onContainerWidth(width: number): void;
  isReset: boolean;
  onReset(): void;
  timeline: string[];
  defaultTimeRange: string[];
}

const MyTimeV3: React.FC<ISectionProps> = ({
  onRowAction,
  onDateClick,
  activeDates,
  minSlotWidthDate,
  onContainerWidth,
  timeline,
  isReset,
  onReset,
  defaultTimeRange,
}) => {
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [showPopupOptions, setShowPopupOptions] = useState<{
    open: boolean;
    action: string;
    date?: string;
  }>({
    open: false,
    action: "",
    date: "",
  });
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const [isTooltipOpen, setIsTooltipOpen] = useState("");
  const language = Utils.getSavedLanguage();
  const t = useTranslation();

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isFetchLoading")
  );
  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.SOCKET, "currentDateInScroller")
  );
  const myTime = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "getForMe")
  );

  const canCreateInternalLog = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LogTime",
    "createInternalLog"
  );
  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );
  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const visibleRange = useMemo(
    () => ({
      start: _.first(activeDates) || "",
      end: _.last(activeDates) || "",
    }),
    [activeDates]
  );

  const calendarRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [containerHeight, setContainerHeight] = useState<number | string>(260);
  const [events, setEvents] = useState<any[]>([]);
  const [slotLabelDisplayType, setSlotLabelDisplayType] = useState<string>("");
  const [calendarMounted, setCalendarMounted] = useState(false);

  const [resources] = useState<any[]>([
    { id: ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME, title: t("title.WORKTIME") },
    { id: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME, title: t("title.OVERTIME") },
  ]);

  const overTime = useMemo(
    () =>
      _.filter(
        events,
        (event) => event?.resourceId !== ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
      ),
    [events]
  );

  const workTime = useMemo(
    () =>
      _.filter(
        events,
        (event) => event?.resourceId !== ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
      ),
    [events]
  );

  const slotLabelFormats = useMemo(() => {
    const formats: any[] = [];
    formats.push({
      weekday:
        slotLabelDisplayType === "full" || minSlotWidthDate >= 30
          ? "short"
          : "narrow",
    });
    formats.push({ day: "2-digit" });
    return formats;
  }, [slotLabelDisplayType, minSlotWidthDate]);

  useEffect(() => {
    if (_.size(overTime) <= 1 && _.size(workTime) <= 4) setContainerHeight(350);
    else {
      setContainerHeight(`calc(100vh - ${isMobile ? "150px" : "300px"})`);
    }
  }, [overTime, workTime, isMobile]);

  useEffect(() => {
    return () => {
      setCalendarMounted(false);
    };
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    setTimeout(() => {
      calendarApi?.refetchEvents();
      calendarApi?.refetchResources();
    }, 500);
  }, [isSidebarExpanded, minSlotWidthDate]);

  useEffect(() => {
    const events: any[] = [];

    const countEventsPerDay = () => {
      const workTimeCount: any = {};
      const overTimeCount: any = {};
      _.forEach(myTime, (timeLog: any) => {
        const date = dayjs(timeLog.date).format("YYYY-MM-DD");
        if (timeLog.timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
          workTimeCount[date] = workTimeCount[date]
            ? workTimeCount[date] + 1
            : 1;
        } else if (timeLog.timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME) {
          overTimeCount[date] = overTimeCount[date]
            ? overTimeCount[date] + 1
            : 1;
        }
      });
      return { workTimeCount, overTimeCount };
    };

    const { workTimeCount, overTimeCount } = countEventsPerDay();

    if (_.isEmpty(overTimeCount)) {
      events.push(
        generateVirtualEvents(
          dayjs(activeDates[0]).toDate(),
          ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
        )
      );
    }
    if (_.isEmpty(workTimeCount)) {
      _.times(4, () => {
        events.push(
          generateVirtualEvents(
            activeDates[0],
            ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
          )
        );
      });
    }
    const colors = [
      "#5C6E6C", // balsamGreen
      "#A6B7AA", // aquatone
      "#D2A96A", // artermis
      "#D39D87", // dustyCoral
      "#BB7154", // warmCopper
    ];

    let colorIndex = 0;

    const getNextColor = () => {
      const color = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length;
      return color;
    };

    _.forEach(myTime, (timeLog: any) => {
      const timeType = timeLog.timeType;
      if (timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
        const startDate = new Date(
          `${timeLog?.workTimeStartDate}T${timeLog?.workTimeStartTime}`
        );
        const endDate = new Date(
          `${timeLog?.workTimeEndDate}T${timeLog?.workTimeEndTime}`
        );
        events.push({
          id: timeLog.id,
          resourceId: ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME,
          start: startDate,
          end: endDate,
          title: timeLog?.task?.title,
          backgroundColor: getNextColor(),
          borderColor: "transparent",
          extendedProps: timeLog,
        });
      }
      if (timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME) {
        const startDate = dayjs(timeLog.date).toDate();
        const endDate = dayjs(timeLog.date).toDate();
        events.push({
          id: timeLog.id,
          resourceId: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME,
          start: startDate,
          end: endDate,
          title: timeLog?.task?.title,
          backgroundColor: "transparent",
          borderColor: "transparent",
          extendedProps: timeLog,
        });
      }
    });
    setEvents(events);
  }, [myTime]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    if (!_.isEmpty(timeline) && calendarApi) {
      requestAnimationFrame(() => {
        calendarApi.gotoDate(_.first(timeline));
      });
    }
  }, [timeline]);

  useEffect(() => {
    scrollToTime();
  }, [visibleRange, timeline]);

  useEffect(() => {
    if (!calendarMounted) return;
    const calendarEl = calendarRef?.current?.calendar?.el;
    const scroller: any = calendarEl.querySelectorAll("tfoot .fc-scroller")[1];

    const handleCalendarScroll = _.debounce(() => {
      const { scrollLeft } = scroller;
      const currentDay = Math.round(scrollLeft / minSlotWidthDate);
      if (timeline[currentDay]) {
        dispatch(saveCurrentDateInScroller(timeline[currentDay]));
      }
    }, 300);

    if (scroller) {
      scroller.addEventListener("scroll", handleCalendarScroll);
    }

    return () => {
      scroller?.removeEventListener("scroll", handleCalendarScroll);
    };
  }, [calendarMounted, minSlotWidthDate, timeline]);

  useEffect(() => {
    if (containerRef?.current) {
      const type = calculateDisplayType();
      setSlotLabelDisplayType(type);
    }
  }, [minSlotWidthDate]);

  useEffect(() => {
    if (isReset) {
      const calendarApi = calendarRef?.current?.getApi();
      if (!_.isEmpty(visibleRange) && calendarApi) {
        const midIndex = _.findIndex(
          timeline,
          (date) => date === defaultTimeRange[0]
        );

        if (midIndex !== -1) {
          _.delay(() => {
            calendarApi.scrollToTime({
              days: midIndex,
            });
            onReset();
          }, 100);
        }
      }
      onReset();
    }
  }, [isReset]);

  const handleLongPress = (id: any) => (e: any) => {
    e.stopPropagation();
    //setTimeout(() => {
    setIsTooltipOpen(id);
    //}, 50);
  };

  const handleTouchEnd = () => {
    setIsTooltipOpen("");
  };

  useEffect(() => {
    const closeTooltip = () => {
      setIsTooltipOpen("");
    };
    const calendarContainer = document.querySelector(".container");
    const calendarCalendar = document.querySelector(".chakra-stack.css-z83g0r");

    document.addEventListener("click", closeTooltip);

    if (calendarContainer) {
      calendarContainer.addEventListener("scroll", closeTooltip);
    }
    if (calendarCalendar) {
      calendarCalendar.addEventListener("scroll", closeTooltip);
    }
    return () => {
      document.removeEventListener("click", closeTooltip);
      if (calendarContainer) {
        calendarContainer.removeEventListener("scroll", closeTooltip);
      }
      if (calendarCalendar) {
        calendarCalendar.addEventListener("scroll", closeTooltip);
      }
    };
  }, []);

  // const _renderEventContent = (arg: EventContentArg) => {
  //   console.log("arg", arg);
  //   return <Text>event</Text>;
  // };

  // const _renderMoreOptions = (card: any, canUpdateLog: boolean) => {
  //   return (
  //     <Popover>
  //       <PopoverTrigger>
  //         <Icon
  //           sx={{
  //             color: "#333",
  //             ml: "auto",
  //             cursor: "pointer",
  //             "&:hover": {
  //               bg: "gray.100",
  //               borderRadius: "50%",
  //             },
  //           }}
  //           as={EllipsisHorizontalIcon}
  //           boxSize={5}
  //         />
  //       </PopoverTrigger>
  //       <Portal>
  //         <PopoverContent
  //           sx={{
  //             maxW: 200,
  //           }}
  //         >
  //           <PopoverArrow />
  //           <PopoverHeader>
  //             <Heading size="sm">More Options</Heading>
  //           </PopoverHeader>
  //           <PopoverCloseButton />
  //           <PopoverBody>
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 gap: 1,
  //               }}
  //             >
  //               <Button
  //                 size="sm"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   e.stopPropagation();
  //                   dispatch(getBoardById(card?.kanbanBoard?.id));
  //                   dispatch(performAction("editLog", card.id));
  //                 }}
  //                 colorScheme="twitter"
  //                 variant="outline"
  //                 sx={{
  //                   color: "#5c6e6c",
  //                   border: "1px solid #5c6e6c",
  //                   "&:hover": {
  //                     background: "rgba(92, 110, 108,0.1)",
  //                   },
  //                 }}
  //               >
  //                 View
  //               </Button>
  //               <Button
  //                 size="sm"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   e.stopPropagation();
  //                   dispatch(performAction("deleteLog", card.id));
  //                 }}
  //                 colorScheme="red"
  //                 variant="outline"
  //                 isDisabled={!canUpdateLog}
  //               >
  //                 Delete
  //               </Button>
  //             </Box>
  //           </PopoverBody>
  //         </PopoverContent>
  //       </Portal>
  //     </Popover>
  //   );
  // };

  const scrollToTime = () => {
    const calendarApi = calendarRef?.current?.getApi();
    if (!_.isEmpty(visibleRange) && calendarApi) {
      const midIndex = _.findIndex(
        timeline,
        (date) => date === currentDateInScroller || date === activeDates[0]
      );

      if (midIndex !== -1) {
        _.delay(() => {
          calendarApi.scrollToTime({
            days: midIndex,
          });
        }, 100);
      }
    }
  };

  const calculateDisplayType = () => {
    const numberOfDays =
      dayjs(visibleRange.end).diff(dayjs(visibleRange.start), "day") + 1;
    const containerWidth = (containerRef?.current?.offsetWidth || 0) - 100;
    return Utils.calculateDisplayType(numberOfDays, containerWidth);
  };

  const generateVirtualEvents = (day: any, resourceId: string) => {
    return {
      id: `virtual_event-${Math.random()}`,
      resourceId: resourceId,
      start: dayjs(new Date(day)).toDate(),
      end: dayjs(new Date(day)).endOf("day").toDate(),
      title: "Virtual Event",
      backgroundColor: "transparent",
      borderColor: "transparent",
      textColor: "transparent",
      extendedProps: {
        timeType:
          resourceId === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
            ? ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
            : ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME,
      },
    };
  };

  const _renderLoading = () =>
    isFetchLoading && (
      <Box
        sx={{
          position: "absolute",
          background: "#fff",
          inset: 0,
          zIndex: 99,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all .3s ease-in-out",
          width: "full",
          height: "full",
        }}
      >
        <HStack>
          <CircularProgress size="20px" isIndeterminate color="gray" />
          <Text>Loading...</Text>
        </HStack>
      </Box>
    );

  const _renderElementTooltipWorkingTime = (card: any) => {
    return (
      <Stack
        sx={{
          w: "max-content",
        }}
      >
        <TextComponent
          sx={{
            fontWeight: 500,
            height: "100%",
          }}
          maxW="100%"
          content={
            card?.projectExterior?.title
              ? `${card?.projectExterior?.title} | ${card?.workingTime || 0}H`
              : `${card?.task?.title} | ${card?.kanbanBoard?.project?.code} | ${card?.workingTime}H`
          }
          showTooltip={false}
        />
      </Stack>
    );
  };

  const _renderElementTooltipOvertime = (eventInfo: any, card: any) => {
    return (
      <Stack
        sx={{
          w: "max-content",
        }}
      >
        <TextComponent
          maxW="100%"
          content={`${eventInfo?.event?.title} | ${
            card?.description ? `${card?.description} (OT)` : "--"
          } | ${card.workingTime || 0}H`}
          showTooltip={false}
        />
      </Stack>
    );
  };

  const _renderResourceContent = (resourceInfo: any) => {
    const { _resource } = resourceInfo.resource;
    return (
      <HStack alignItems="center" w="full" h="full">
        <Tooltip label={_resource?.title}>
          <Heading
            fontSize="sm"
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {_resource?.title}
          </Heading>
        </Tooltip>
      </HStack>
    );
  };

  const _renderEventContent = (eventInfo: EventContentArg) => {
    const isEventPlaceholder = _.includes(
      eventInfo?.event?.id,
      "virtual_event"
    );
    const card = eventInfo?.event?.extendedProps;
    const canUpdateLog = eventInfo?.event?.extendedProps?.overTimeStatus
      ? eventInfo?.event?.extendedProps?.overTimeStatus ===
        ENUMS.LOG_OVER_TIME_STATUS.PENDING
      : true;

    if (
      eventInfo?.event?.extendedProps?.timeType ===
      ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
    ) {
      if (minSlotWidthDate <= 100) {
        return (
          <Tooltip
            label={_renderElementTooltipOvertime(eventInfo, card)}
            isOpen={isTooltipOpen === eventInfo?.event?.id}
            placement="auto"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                touchAction: "none",
                cursor: "pointer",
                background:
                  ENUMS.TIMECARD_STATUS_COLOR[
                    card?.overTimeStatus as keyof typeof ENUMS.TIMECARD_STATUS_COLOR
                  ]?.background,
                visibility: isEventPlaceholder ? "hidden" : "visible",
                mb: -1,
              }}
              onTouchStart={handleLongPress(eventInfo?.event?.id)}
              onMouseEnter={handleLongPress(eventInfo?.event?.id)}
              onMouseLeave={handleTouchEnd}
              onClick={(e) => {
                e.stopPropagation();
                if (isTooltipOpen) setIsTooltipOpen("");
                dispatch(performAction("editLog", eventInfo?.event?.id));
              }}
            >
              <TextComponent
                sx={{
                  fontWeight: 500,
                  height: "100%",
                }}
                maxW="100%"
                content={`${eventInfo?.event?.title} | ${
                  card?.description ? `${card?.description} (OT)` : "--"
                } | ${card.workingTime || 0}H`}
                showTooltip={isTooltipOpen === eventInfo?.event?.id}
              />
            </Box>
          </Tooltip>
        );
      }
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            touchAction: "none",
            cursor: "pointer",
            transition: "all .3s ease-in-out",
            padding: "5px",
            backgroundColor: "white",
            borderRadius: "2px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            position: "relative",
            minHeight: "50px",
            // borderLeft: `4px solid ${
            //   card?.overTimeStatus
            //     ? "#ff3232"
            //     : !card?.isTaskNote
            //     ? "#3498DB"
            //     : "#ddd"
            // }`,
            mx: 0,
            visibility: isEventPlaceholder ? "hidden" : "visible",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (canUpdateLog) {
              // dispatch(
              //   getBoardById(eventInfo?.event?.extendedProps?.kanbanBoard?.id)
              // );
              setIsTooltipOpen("");
              dispatch(performAction("editLog", eventInfo?.event?.id));
            }
          }}
        >
          <Box>
            <Heading
              noOfLines={1}
              sx={{
                fontWeight: "bold",
                fontSize: "small",
                mb: 1,
                color: "#000",
                width: "calc(100% - 15px)",
              }}
            >
              {eventInfo?.event?.title || "--"}
            </Heading>
            <Text
              sx={{
                fontSize: 12,
                color: "#000",
              }}
            >
              {card?.description || "--"}
            </Text>
          </Box>
          <Box
            sx={{
              mt: "auto",
            }}
            noOfLines={1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip label={card?.project?.name} hasArrow>
                <Text
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {card?.project?.code ? `#${card?.project?.code}` : ""}
                </Text>
              </Tooltip>
              <Text
                sx={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {`${card.workingTime || 0}H`}
              </Text>
            </Box>
            {card?.overTimeStatus && (
              <Text
                fontSize="xs"
                textTransform="uppercase"
                textAlign="center"
                color={
                  ENUMS.TIMECARD_STATUS_COLOR[
                    card?.overTimeStatus as keyof typeof ENUMS.TIMECARD_STATUS_COLOR
                  ]?.text
                }
                rounded={2}
                fontWeight={600}
                background={
                  ENUMS.TIMECARD_STATUS_COLOR[
                    card?.overTimeStatus as keyof typeof ENUMS.TIMECARD_STATUS_COLOR
                  ]?.background
                }
              >
                {card?.overTimeStatus}
              </Text>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <Tooltip
        label={_renderElementTooltipWorkingTime(card)}
        isOpen={isTooltipOpen === eventInfo?.event?.id}
      >
        <Box
          sx={{
            width: "100%",
            visibility: isEventPlaceholder ? "hidden" : "visible",
            cursor: "pointer",
          }}
          onTouchStart={handleLongPress(eventInfo?.event?.id)}
          onMouseEnter={handleLongPress(eventInfo?.event?.id)}
          onMouseLeave={handleTouchEnd}
        >
          <TextComponent
            sx={{
              fontWeight: 500,
              height: "100%",
            }}
            maxW="100%"
            content={
              card?.projectExterior?.title
                ? `${card?.projectExterior?.title} | ${card?.workingTime || 0}H`
                : `${card?.task?.title} | ${card?.kanbanBoard?.project?.code} | ${card?.workingTime}H`
            }
            showTooltip={false}
          />
        </Box>
      </Tooltip>
    );
  };

  // const userRoles = useMemo(
  //   () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
  //   [userData]
  // );

  // const ishasArtistRole = useMemo(
  //   () =>
  //     _.includes(userRoles, ENUMS.ROLES.ARTIST) &&
  //     !_.includes(userRoles, ENUMS.ROLES.LEADER) &&
  //     !_.includes(userRoles, ENUMS.ROLES.MANAGER) &&
  //     !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES) &&
  //     !_.includes(userRoles, ENUMS.ROLES.MARKETING),
  //   [userData]
  // );

  useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  const handleDateClick = (arg: DateClickArg) => {
    const isSunday = dayjs(arg?.date).day() === 0;
    const workType = arg?.resource?.id;

    if (canCreateInternalLog) {
      if (!isTooltipOpen) {
        if (workType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME || !isSunday) {
          setShowPopupOptions({
            open: true,
            action: "worktime",
            date: arg.dateStr,
          });
          onRowAction("worktime");
          onDateClick(arg.dateStr);
        }
        if (workType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME) {
          {
            setShowPopupOptions({
              open: true,
              action: "overtime",
              date: arg.dateStr,
            });
            onRowAction("overtime");
            onDateClick(arg.dateStr);
          }
        }
      }
    } else {
      if (workType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME || !isSunday) {
        onRowAction("worktime");
        onDateClick(arg.dateStr);
        dispatch(performAction("logExternal", "", { date: arg.dateStr }));
      }
      if (workType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME) {
        {
          dispatch(performAction("logExternal", "", { date: arg.dateStr }));
          onDateClick(arg.dateStr);
          onRowAction("overtime");
        }
      }
    }
  };

  // const handleNavLinkDateClick = (date: Date) => {
  //   if (ishasArtistRole)
  //     dispatch(
  //       performAction("logInternal", "", {
  //         date: dayjs(date).format("YYYY-MM-DD"),
  //       })
  //     );
  //   else
  //     dispatch(
  //       performAction("logExternal", "", {
  //         date: dayjs(date).format("YYYY-MM-DD"),
  //       })
  //     );
  // };

  // const handleDateMouseEnter = (arg: EventHoveringArg) => {
  //   console.log("Ngày đã hover:", arg.dateStr);
  // };

  // const handleDateMouseLeave = (arg: EventHoveringArg) => {
  //   console.log("Ngày đã bỏ hover:", arg.dateStr);
  // };

  const _renderPopup = () => {
    return (
      <MoreOptionsDialog
        open={showPopupOptions.open}
        onClose={() =>
          setShowPopupOptions({ open: false, action: "", date: "" })
        }
        date={showPopupOptions.date}
      />
    );
  };

  const _renderCalendar = () => {
    return (
      <Stack
        ref={containerRef}
        sx={{
          //The commented code you provided is used to hide unnecessary days when the container area is small.
          ".fc-timeline-header-row:last-of-type .fc-timeline-slot.fc-timeline-slot-label.fc-day:not(.fc-day-mon) a":
            {
              visibility:
                (slotLabelDisplayType === "firstDay" ||
                  slotLabelDisplayType === "hide") &&
                minSlotWidthDate <= 20
                  ? "hidden"
                  : "visible",
            },
          ".fc .fc-timeline-slot-cushion": {
            fontSize:
              slotLabelDisplayType === "firstDay" ||
              slotLabelDisplayType === "short" ||
              slotLabelDisplayType === "hide"
                ? "10px"
                : "inherit",
            width: "100%",
            height: "100%",
          },
        }}
      >
        <FullCalendar
          locale={language === "en" ? "en" : "vi"}
          scrollTimeReset={false}
          height="auto"
          rerenderDelay={2}
          ref={calendarRef}
          resourceAreaHeaderClassNames="custom-header"
          plugins={[resourceTimelinePlugin, dayGridPlugin, interactionPlugin]}
          resourcesInitiallyExpanded={true}
          resourceOrder="from"
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          firstDay={1}
          weekends={true}
          stickyHeaderDates
          stickyFooterScrollbar
          headerToolbar={false}
          // customButtons={{f
          //   setting: {
          //     text: "",
          //     icon: "cogs",
          //     click: () => setResourceAreaWidth((prev) => (prev === 400 ? 0 : 400)),
          //   },
          // }}
          dayMaxEvents
          editable={false}
          aspectRatio={1}
          resourceAreaWidth={100}
          initialView="resourceTimelineMonth"
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }}
          views={{
            resourceTimelineWeek: {
              slotDuration: { day: 1 },
              // slotMinTime: "08:00",
              // slotMaxTime: "17:30",
              // slotDuration: { hours: 1 },
              // slotLabelFormat: [
              //   { weekday: "short", day: "2-digit" }, // lower level of text
              // ],
              slotMinWidth: minSlotWidthDate,
              eventMinWidth: minSlotWidthDate,
              slotLabelFormat: slotLabelFormats,
              // hiddenDays: [0, 6] // Hide Sunday and Saturday?
              visibleRange: {
                start: _.first(timeline),
                end: _.last(timeline),
              },
              duration: {
                days: !_.isEmpty(timeline)
                  ? dayjs(_.last(timeline)).diff(
                      dayjs(_.first(timeline)),
                      "day"
                    ) + 1
                  : 7,
              },
            },
            resourceTimelineMonth: {
              // slotMinTime: "08:00",
              // slotMaxTime: "17:30",
              // slotDuration: { hours: 1 },
              // slotLabelFormat: [
              //   { weekday: "short", day: "2-digit" },
              //   { hour: "numeric", minute: "2-digit" },
              // ],
              slotDuration: { day: 1 },
              slotMinWidth: minSlotWidthDate,
              eventMinWidth: minSlotWidthDate,
              slotLabelFormat: slotLabelFormats,
              visibleRange: {
                start: _.first(timeline),
                end: _.last(timeline),
              },
              duration: {
                days: !_.isEmpty(timeline)
                  ? dayjs(_.last(timeline)).diff(
                      dayjs(_.first(timeline)),
                      "day"
                    ) + 1
                  : 30,
              },
            },
          }}
          events={events}
          resources={resources}
          eventDisplay="block"
          eventContent={_renderEventContent}
          resourceLabelContent={_renderResourceContent}
          eventClick={(eventInfo: EventClickArg) => {
            const isEventPlaceholder = _.includes(
              eventInfo?.event?.id,
              "virtual_event"
            );
            if (isEventPlaceholder) {
              if (canCreateInternalLog) {
                if (
                  eventInfo?.event?.extendedProps?.timeType ===
                  ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
                )
                  setShowPopupOptions({ open: true, action: "worktime" });
                if (
                  eventInfo?.event?.extendedProps?.timeType ===
                  ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
                ) {
                  setShowPopupOptions({ open: true, action: "overtime" });
                }
              } else {
                dispatch(performAction("logExternal", ""));
              }
            } else {
              if (!eventInfo?.event?.extendedProps?.overTimeStatus) {
                if (
                  eventInfo?.event?.extendedProps?.timeType ===
                  ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
                ) {
                  // dispatch(
                  //   getBoardById(eventInfo?.event?.extendedProps?.kanbanBoard?.id)
                  // );
                  setIsTooltipOpen("");
                  dispatch(performAction("editLog", eventInfo?.event?.id));
                }
              } else {
                dispatch(performAction("editLog", eventInfo?.event?.id));
              }
            }
          }}
          dateClick={handleDateClick}
          slotLabelClassNames={(slotContent) => {
            const classNames = [];
            const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
            const isHoliday = _.some(
              holidaysList,
              (holiday) => holiday?.day === slotDate
            );
            if (isHoliday) classNames.push("holiday");
            return classNames;
          }}
          slotLaneClassNames={(slotContent) => {
            const classNames = [];
            const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
            const isHoliday = _.some(
              holidaysList,
              (holiday) => holiday?.day === slotDate
            );
            if (isHoliday) classNames.push("holiday");
            return classNames;
          }}
          eventDidMount={() => {
            setCalendarMounted(true);
            scrollToTime();
            const containerWidth =
              (containerRef?.current?.offsetWidth || 0) - 100;
            onContainerWidth(containerWidth);
          }}
        />
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        overflow: "auto",
        position: "relative",
        height: containerHeight,
      }}
    >
      <Stack
        sx={{
          ".fc .fc-datagrid-cell-cushion": {
            p: "10px",
            height: "100%",
          },
          ...calendarStyles,
        }}
      >
        {_renderCalendar()}
      </Stack>
      {_renderLoading()}
      {_renderPopup()}
    </Stack>
  );
};

export default MyTimeV3;
