import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ENUMS, Routers } from "@/Constants";
import * as yup from "yup";
import {
  Button,
  DatePicker,
  Select,
  TextField,
  Textarea,
  TimePicker,
} from "@/Components/Common";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions, LogtimeActions } from "@/Actions";
import Utils from "@/Utils";
import { ITaskStructure } from "@/Interfaces/Board.interface";
import dayjs from "dayjs";
import {
  IFilterOverTime,
  ITimeLogPerformAction,
} from "@/Interfaces/LogTime.interface";
import { useLocation } from "react-router";
import { useTranslation } from "react-multi-lang";
import { IWorkingDayStructure } from "@/Interfaces/Workingday.interface";
import { useWorkingDay } from "@/Helpers";

const { getBoardById } = BoardActions;
const { createLogtime, createLogtimeInMyTime } = LogtimeActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  title?: string;
}

const DEFAULT_OVER_TIME = {
  project: "",
  task: "",
  projectExterior: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME,
  workingTime: 0,
  startTime: "",
  endTime: "",
  date: new Date(),
  dateType: ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY,
  description: "",
};

const dateTypeOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeWeekendOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsSunday = [
  { label: "Sunday", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsWeekend = [
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeWeekendOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsSundayVI = [
  { label: "Ngày chủ nhật", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsWeekendVI = [
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const SendOvertimeDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  title,
}) => {
  const t = useTranslation();
  const { pathname } = useLocation();
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [message, setMessage] = useState<string>("");
  const language = Utils.getSavedLanguage();
  const [dateTypeNext, setDateTypeNext] = useState<string>(
    ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
  );
  const [messageDateType, setMessageDateType] = useState<string>("");
  const projects: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );
  const myTimePagination = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "myTimePagination")
  );
  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const projectsExterior: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "payload")
  );

  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );
  const workingDays: IWorkingDayStructure = useSelector((state: RootState) =>
    _.get(state.WORKING_DAY, "payload")
  );
  const pagination: IFilterOverTime = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "pagination")
  );

  // //check time

  const overTimeSchema = yup
    .object()
    .shape({
      project: yup
        .string()
        .trim()
        .when("type", ([type], sch) => {
          return type === ENUMS.LOG_TIME_TYPE.EXTERNAL
            ? sch.required(t("message.projectIsRequired"))
            : sch.required(t("message.projectIsRequired"));
        }),
      task: yup.string().when("type", ([type], sch) => {
        return type === ENUMS.LOG_TIME_TYPE.INTERNAL
          ? sch.trim().required(t("message.processIsRequired"))
          : sch.trim().required(t("message.processIsRequired"));
      }),
      date: yup.date().required(t("message.dateIsRequired")),
      timeType: yup.string().trim().required(t("message.timeTypeIsRequired")),
      dateType: yup.string().trim().required(t("message.dateTypeIsRequired")),
      startTime: yup.string().trim().notRequired(),
      endTime: yup.string().trim().notRequired(),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeAvalidNumber"))
        .max(24, t("message.workedHoursCannotExceed")),
      description: yup.string().notRequired(),
      type: yup.string().notRequired(),
    })
    .required();

  const {
    control: overTimeControl,
    handleSubmit,
    formState: { errors: overTimeErrors },
    setValue: setOverTimeValue,
    reset,
    clearErrors: clearOverTimeErrors,
    watch: watchOverTime,
    getValues,
  } = useForm({
    resolver: yupResolver(overTimeSchema),
    defaultValues: DEFAULT_OVER_TIME,
  });

  const watchOverStartTime: any = watchOverTime("startTime");
  const watchOverEndTime: any = watchOverTime("endTime");
  const watchOverTimeProject = watchOverTime("project") || "";
  const watchOverTimeWorkingTime = watchOverTime("workingTime");
  const watchDate = watchOverTime("date") || "";
  const projectOptions = useMemo(
    () =>
      _.map(projects, (project) => ({
        label: `${project?.name} - ${project?.code}`,
        value: project?.id,
      })),
    [projects]
  );

  const projectExteriorOptions = useMemo(
    () =>
      _.map(projectsExterior, (project) => ({
        label: `${project?.name}-${project?.code}`,
        value: project?.id,
      })),
    [projectsExterior]
  );

  useEffect(() => {
    if (watchOverStartTime || watchOverEndTime) {
      if (watchOverStartTime && watchOverEndTime) {
        const newValue = Utils.calculateWorkingTime(
          watchOverStartTime,
          watchOverEndTime
        );
        setOverTimeValue("workingTime", newValue);
        if (newValue > 0) clearOverTimeErrors();
      }
      // handle change date type
      if (
        watchOverStartTime > watchOverEndTime &&
        watchOverEndTime !== "00:00:00"
      ) {
        const nextOptions = _renderNextOptions();
        if (!_.some(nextOptions, (option) => option.value === dateTypeNext)) {
          setDateTypeNext(nextOptions?.[0]?.value);
        }
      }
    }
  }, [watchOverStartTime, watchOverEndTime]);

  useEffect(() => {
    if (watchDate) {
      if (watchDate.getDay() === 0) {
        setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKEND);
      } else setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
      // handle change dateType
      const dateTypeOptions = _renderOptions();
      const currentDateType = getValues("dateType");
      if (
        !_.some(dateTypeOptions, (option) => option.value === currentDateType)
      )
        setOverTimeValue("dateType", dateTypeOptions?.[0]?.value);
    }
  }, [watchDate]);

  const checkStartMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const checkEndMorning =
    workingTimeList[0]?.morningEnd &&
    Utils.convertToTime(workingTimeList[0]?.morningEnd);

  const checkStartAfternoon =
    workingTimeList[0]?.afternoonStart &&
    Utils.convertToTime(workingTimeList[0]?.afternoonStart);

  const checkEndAfternoon =
    workingTimeList[0]?.afternoonEnd &&
    Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

  const onSubmit = (data: any) => {
    const newWorkedStartTime = Utils.calculateWorkingTime(
      data?.startTime,
      "00:00:00"
    );
    const newWorkedEndTime = Utils.calculateWorkingTime(
      "00:00:00",
      data?.endTime
    );
    const day = 60 * 60 * 24 * 1000;
    const endDate = new Date(data?.date.getTime() + day);
    if (data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
      const currentDate = dayjs(data.date).toDate();
      const morningKey = useWorkingDay.getDayAndPeriod(currentDate, "Morning");
      const afternoonKey = useWorkingDay.getDayAndPeriod(
        currentDate,
        "Afternoon"
      );
      if (
        (data?.startTime >= checkStartMorning &&
          data?.startTime < checkEndMorning &&
          workingDays[morningKey]) ||
        (data?.startTime >= checkStartAfternoon &&
          data?.startTime < checkEndAfternoon &&
          workingDays[afternoonKey])
      ) {
        setMessage(t("message.startTimeInvalid"));
        return;
      }

      if (
        data?.startTime >= checkEndMorning &&
        data?.startTime < checkStartAfternoon &&
        data?.endTime > checkStartAfternoon &&
        workingDays[morningKey]
      ) {
        setMessage(t("message.endTimeInvalid"));
        return;
      }

      if (
        (data?.endTime > checkStartMorning &&
          data?.endTime <= checkEndMorning &&
          workingDays[morningKey]) ||
        (data?.endTime > checkStartAfternoon &&
          data?.endTime <= checkEndAfternoon &&
          workingDays[afternoonKey])
      ) {
        setMessage(t("message.endTimeInvalid"));
        return;
      }
      if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
        const nextDate = dayjs(data.date).add(1, "day").toDate();
        const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");
        if (
          data?.endTime > checkStartMorning &&
          workingDays[morningKey] &&
          dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }

        if (pathname === Routers.MY_TIME) {
          dispatch(
            createLogtimeInMyTime(
              {
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                endTime: "23:59:00",
                workingTime: newWorkedStartTime,
                user: userData?.id,
              },
              myTimePagination,
              () => onClose()
            )
          );
        } else {
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                endTime: "23:59:00",
                workingTime: newWorkedStartTime,
                user: userData?.id,
              },
              false,
              true,
              pagination
            )
          );
        }
        if (pathname === Routers.MY_TIME) {
          dispatch(
            createLogtimeInMyTime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
                user: userData?.id,
              },
              myTimePagination,
              () => onClose()
            )
          );
        } else {
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
                user: userData?.id,
              },
              true,
              true,
              pagination
            )
          );
        }
      } else {
        if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
          if (pathname === Routers.MY_TIME) {
            dispatch(
              createLogtimeInMyTime(
                {
                  ...data,
                  date: dayjs(data?.date).format("YYYY-MM-DD"),
                  user: userData?.id,
                  workingTime: watchOverTimeWorkingTime,
                },
                myTimePagination,
                () => onClose()
              )
            );
          } else {
            dispatch(
              createLogtime(
                {
                  ...data,
                  date: dayjs(data?.date).format("YYYY-MM-DD"),
                  user: userData?.id,
                  workingTime: watchOverTimeWorkingTime,
                },
                false,
                true,
                pagination
              )
            );
          }
        }
      }
      // } else setMessage(t("message.startTimeInvalid"));
    }
    if (
      data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND ||
      data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY
    ) {
      if (data?.startTime > data?.endTime && data?.endTime !== "00:00:00") {
        const nextDate = dayjs(data.date).add(1, "day").toDate();
        const morningKey = useWorkingDay.getDayAndPeriod(nextDate, "Morning");
        if (
          data?.endTime > checkStartMorning &&
          workingDays[morningKey] &&
          dateTypeNext === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY
        ) {
          setMessage(t("message.endTimeInvalid"));
          return;
        }
        if (pathname === Routers.MY_TIME) {
          dispatch(
            createLogtimeInMyTime(
              {
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                endTime: "23:59:00",
                workingTime: newWorkedStartTime,
                user: userData?.id,
              },
              myTimePagination,
              () => onClose()
            )
          );
          dispatch(
            createLogtimeInMyTime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
                user: userData?.id,
              },
              myTimePagination,
              () => onClose()
            )
          );
        } else {
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                endTime: "23:59:00",
                workingTime: newWorkedStartTime,
                user: userData?.id,
              },
              false,
              true,
              pagination
            )
          );
          dispatch(
            createLogtime(
              {
                ...data,
                date: dayjs(endDate).format("YYYY-MM-DD"),
                startTime: "00:00:00",
                workingTime: newWorkedEndTime,
                dateType: dateTypeNext,
                user: userData?.id,
              },
              true,
              true,
              pagination
            )
          );
        }
      } else {
        if (_.isEmpty(overTimeErrors) && _.toNumber(data.workingTime) > 0) {
          if (pathname === Routers.MY_TIME) {
            dispatch(
              createLogtimeInMyTime(
                {
                  ...data,
                  date: dayjs(data?.date).format("YYYY-MM-DD"),
                  user: userData?.id,
                  workingTime: watchOverTimeWorkingTime,
                },
                myTimePagination,
                () => onClose()
              )
            );
          } else {
            dispatch(
              createLogtime(
                {
                  ...data,
                  date: dayjs(data?.date).format("YYYY-MM-DD"),
                  user: userData?.id,
                  workingTime: watchOverTimeWorkingTime,
                },
                false,
                true,
                pagination
              )
            );
          }
        }
      }
    }
  };

  const findOverTimeProccessOptions = useMemo(() => {
    const targetProjects =
      timeLogPerformAction?.actionType === "logExternal"
        ? projectsExterior
        : projects;
    const foundProject: any = _.find(
      targetProjects,
      (project) => project.id === watchOverTimeProject
    );

    if (foundProject) {
      const kanbanBoard = foundProject.kanbanBoard || {};
      const tasks = kanbanBoard.task || [];
      return _.map(
        tasks,
        (task: ITaskStructure) =>
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
      );
    }

    return [];
  }, [watchOverTimeProject, projects, projectsExterior]);

  useEffect(() => {
    if (isCreateSuccess) {
      onClose();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (!open) {
      reset();
      setMessageDateType("");
      setDateTypeNext(ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
    }
  }, [open]);

  useEffect(() => {
    if (timeLogPerformAction?.extraData?.date) {
      const startDate = new Date(timeLogPerformAction?.extraData?.date);
      setOverTimeValue("date", startDate);
    }
    if (timeLogPerformAction?.actionType === "logInternal") {
      setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.INTERNAL);
    } else setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.EXTERNAL);
  }, [timeLogPerformAction]);

  // const getDateTypeOptions = (day: number, language: string) => {
  //   switch (language) {
  //     case "en":
  //       switch (day) {
  //         case 0:
  //           return dateTypeOptionsSunday;
  //         case 6:
  //           return dateTypeWeekendOptions;
  //         default:
  //           return dateTypeOptions;
  //       }
  //     default:
  //       switch (day) {
  //         case 0:
  //           return dateTypeOptionsSundayVI;
  //         case 6:
  //           return dateTypeWeekendOptionsVI;
  //         default:
  //           return dateTypeOptionsVI;
  //       }
  //   }
  // };

  // const _renderOptions = () => {
  //   const day = watchDate.getDay();
  //   return getDateTypeOptions(day, language);
  // };

  const getDateTypeOptions = (day: number, language: string) => {
    const isSunday = day === 0;
    const dayOptions = {
      0: { morning: "sundayMorning", afternoon: "sundayAfternoon" },
      1: { morning: "mondayMorning", afternoon: "mondayAfternoon" },
      2: { morning: "tuesdayMorning", afternoon: "tuesdayAfternoon" },
      3: { morning: "wednesdayMorning", afternoon: "wednesdayAfternoon" },
      4: { morning: "thursdayMorning", afternoon: "thursdayAfternoon" },
      5: { morning: "fridayMorning", afternoon: "fridayAfternoon" },
      6: { morning: "saturdayMorning", afternoon: "saturdayAfternoon" },
    };

    const { morning, afternoon } = dayOptions[day as keyof typeof dayOptions];
    const isMorningWorking = workingDays[morning as keyof typeof workingDays];
    const isAfternoonWorking =
      workingDays[afternoon as keyof typeof workingDays];

    let options;

    switch (true) {
      case isMorningWorking && isAfternoonWorking:
        options = "dateTypeOptions";
        break;
      case !isMorningWorking && !isAfternoonWorking:
        options = isSunday ? "dateTypeOptionsSunday" : "dateTypeOptionsWeekend";
        break;
      default:
        options = "dateTypeWeekendOptions";
        break;
    }

    const optionsMapping: any = {
      en: {
        dateTypeOptions,
        dateTypeOptionsSunday,
        dateTypeWeekendOptions,
        dateTypeOptionsWeekend,
      },
      vi: {
        dateTypeOptions: dateTypeOptionsVI,
        dateTypeOptionsSunday: dateTypeOptionsSundayVI,
        dateTypeWeekendOptions: dateTypeWeekendOptionsVI,
        dateTypeOptionsWeekend: dateTypeOptionsWeekendVI,
      },
    };

    return optionsMapping[language][options];
  };

  const _renderOptions = () => {
    const day = dayjs(watchDate)?.day();
    return getDateTypeOptions(day, language);
  };

  const _renderNextOptions = () => {
    const day = dayjs(watchDate).add(1, "day")?.day();
    return getDateTypeOptions(day, language);
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <Box>
        <Controller
          name="project"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              displayChoose
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e.target.value);
                setOverTimeValue("task", "");
                dispatch(getBoardById(e.target.value));
                setMessage("");
              }}
              label={`${
                timeLogPerformAction?.actionType === "logInternal"
                  ? t("label.internalProject")
                  : t("label.externalProject")
              }  `}
              size="sm"
              options={
                timeLogPerformAction?.actionType === "logInternal"
                  ? projectOptions
                  : projectExteriorOptions
              }
              isRequired
              isError={!_.isEmpty(overTimeErrors.project?.message)}
              errorMessage={overTimeErrors?.project?.message}
            />
          )}
        />
        <Box>
          <Controller
            name="task"
            control={overTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setMessage("");
                }}
                label={t("label.process")}
                size="sm"
                options={findOverTimeProccessOptions}
                isRequired
                isError={!_.isEmpty(overTimeErrors.task?.message)}
                errorMessage={overTimeErrors?.task?.message}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <Controller
          name="date"
          control={overTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label={t("label.date")}
              value={field.value as any}
              onDateChange={(date) => {
                field.onChange(date);
                setMessage("");
              }}
              isError={!_.isEmpty(overTimeErrors.date?.message)}
              errorMessage={overTimeErrors.date?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="dateType"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
                setMessage("");
              }}
              label={t("label.dateType")}
              size="sm"
              options={_renderOptions()}
              isRequired
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <TimePicker
          isRequired
          label={t("label.timeline")}
          sx={{
            flex: 1,
          }}
          onTimeChange={(newTime: string[]) => {
            setOverTimeValue("startTime", newTime[0]);
            setOverTimeValue("endTime", newTime[1]);
            setMessage("");
          }}
        />
        <Controller
          name="workingTime"
          control={overTimeControl}
          render={({ field }) => (
            <TextField
              label={t("label.workedHours")}
              value={field.value}
              placeholder="0"
              size="sm"
              isReadOnly
              isError={!_.isEmpty(overTimeErrors.workingTime?.message)}
              errorMessage={overTimeErrors.workingTime?.message}
              moreInfo={t(
                "message.workingHoursWillBeCalculatedBasedOnfilledTimelineValue"
              )}
            />
          )}
        />
      </Box>
      <Box>
        {message && (
          <Text fontSize={"14px"} color={"error"}>
            {message}
          </Text>
        )}
      </Box>
      {watchOverStartTime > watchOverEndTime &&
        watchOverEndTime !== "00:00:00" && (
          <Select
            value={dateTypeNext}
            onChange={(e: any) => {
              setDateTypeNext(e.target.value);
              setMessage("");
              setMessageDateType("");
            }}
            label={t("label.nextDate")}
            size="sm"
            options={_renderNextOptions()}
            isRequired
          />
        )}
      <Box>
        {messageDateType && (
          <Text fontSize={"14px"} color={"error"}>
            {messageDateType}
          </Text>
        )}
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>
        <Controller
          name="description"
          control={overTimeControl}
          render={({ field }) => (
            <Textarea
              size="sm"
              value={field?.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t("label.description")}
            />
          )}
        />
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {title ? title : t("title.sendRequestOvertime")}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflow: "auto",
          }}
        >
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={onClose}
            size={"sm"}
            isLoading={isActionLoading}
            w={75}
          >
            {t("button.cancel")}
          </Button>
          <Button
            ml={3}
            //colorScheme="twitter"
            onClick={handleSubmit(onSubmit)}
            size={"sm"}
            isLoading={isActionLoading}
            w={75}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.send")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SendOvertimeDialog;
