import _ from "lodash";

import { ActionTypes, ENUMS, Routers } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import { IFilterProject } from "@/Interfaces/Project.interface";

// SINGLE ACTIONS

const setProjectExteriorFetchLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_EXTERIOR_FETCH_LOADING,
  };
};

const setProjectExteriorGetLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_EXTERIOR_GET_LOADING,
  };
};

const setProjectExteriorActionLoading = () => {
  return {
    type: ActionTypes.SET_PROJECT_EXTERIOR_ACTION_LOADING,
  };
};

const setProjectExteriorMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_PROJECT_EXTERIOR_META,
    payload,
  };
};

const setProjectExteriorPagination = (payload: IFilterProject) => {
  return {
    type: ActionTypes.SET_PROJECT_EXTERIOR_PAGINATION,
    payload,
  };
};

const resetProjectExteriorStatus = () => {
  return {
    type: ActionTypes.RESET_PROJECT_EXTERIOR_STATUS,
  };
};

const resetProjectExteriorReducer = () => {
  return {
    type: ActionTypes.RESET_PROJECT_EXTERIOR_REDUCER,
  };
};

const fetchSuccess = (payload: any[]) => {
  return {
    type: ActionTypes.FETCH_PROJECT_EXTERIORS_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_PROJECT_EXTERIORS_FAILURE,
  };
};

const fetchProjectsExterior = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorFetchLoading());
    await APIs.fetchProjects({ ...payload, type: ENUMS.PROJECT_TYPE.EXTERIOR })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setProjectExteriorMeta(_.get(result, "meta")));
          await dispatch(setProjectExteriorPagination(payload));
          await dispatch(fetchSuccess(resolveResult.items));
        }
      })
      .catch(async (error) => {
        await dispatch(fetchFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_EXTERIOR_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_EXTERIOR_FAILURE,
  };
};

const createProjectExterior = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorActionLoading());
    await APIs.createProjectExterior(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(createSuccess());
          await Toast({
            title: message,
            status: "success",
          });
          Utils.redirect(Routers.PROJECT);
        }
      })
      .catch(async (error) => {
        await dispatch(createFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_PROJECT_EXTERIOR_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_PROJECT_EXTERIOR_BY_ID_FAILURE,
  };
};

const getProjectExteriorById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorGetLoading());
    await APIs.getProjectById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else await dispatch(getByIdSuccess(result));
      })
      .catch(async (error) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_EXTERIOR_SUCCESS,
  };
};

const deleteFailure = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_EXTERIOR_FAILURE,
  };
};

const deleteProject = (id: string, filters: IFilterProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorActionLoading());
    await APIs.deleteProject(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(deleteSuccess());
          await dispatch(fetchProjectsExterior(filters));
          Utils.redirect(Routers.PROJECT);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(deleteFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusSuccess = () => {
  return {
    type: ActionTypes.CHANGE_PROJECT_EXTERIOR_STATUS_SUCCESS,
  };
};

const changeStatusFailure = () => {
  return {
    type: ActionTypes.CHANGE_PROJECT_EXTERIOR_STATUS_FAILURE,
  };
};

const changeProjectStatus = (id: string, status: string) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorActionLoading());
    await APIs.changeProjectStatus(id, {
      status,
    })
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(changeStatusSuccess());
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const updateSuccess = () => {
  return {
    type: ActionTypes.UPDATE_PROJECT_EXTERIOR_SUCCESS,
  };
};

const updateFailure = () => {
  return {
    type: ActionTypes.UPDATE_PROJECT_EXTERIOR_FAILURE,
  };
};

const updateProject = (id: string, payload: any, customSuccessMsg?: string) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorActionLoading());
    await APIs.updateProject(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(updateSuccess());
          await Toast({
            title: customSuccessMsg || message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await dispatch(updateFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchUserCanAddSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_USER_CAN_ADD_SUCCESS,
    payload,
  };
};

const fetchUerCanAddFailure = () => {
  return {
    type: ActionTypes.FETCH_USER_CAN_ADD_FAILURE,
  };
};

const fetchUserCanAdd = (payload: IFilterProject) => {
  return async (dispatch: any) => {
    dispatch(setProjectExteriorFetchLoading());
    await APIs.fetchUserCanAdd(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchUerCanAddFailure());
          await Toast({
            title: "Fetch projects exterior failure",
            description: message,
            status: "error",
          });
        } else {
          await dispatch(fetchUserCanAddSuccess(result));
        }
      })
      .catch(async (error) => {
        await dispatch(fetchUerCanAddFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchProjectsExterior,
  createProjectExterior,
  getProjectExteriorById,
  deleteProject,
  updateProject,
  changeProjectStatus,
  resetProjectExteriorReducer,
  resetProjectExteriorStatus,
  fetchUserCanAdd,
};
