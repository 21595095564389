import React, { useState, useEffect } from "react";
import _ from "lodash";
import "react-phone-input-2/lib/style.css";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  type InputProps,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-multi-lang";

interface IProps extends InputProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  helperText?: string;
  style?: object;
  labelSx?: object;
  values?: {
    days: number;
    hours: number;
  } | null;
  onValuesChange(days: number, hours: number): void;
}

const EstimatedInput = React.forwardRef<InputProps, IProps>(
  (
    {
      label = "",
      isError,
      errorMessage = "",
      helperText = "",
      style = {},
      labelSx,
      values,
      onValuesChange,
      ...props
    },
    ref
  ) => {
    const [days, setDays] = useState<string>("0");
    const [hours, setHours] = useState<string>("0");
    const t = useTranslation();

    useEffect(() => {}, [ref]);

    useEffect(() => {
      if (!_.isEmpty(values)) {
        setDays(_.toString(values.days));
        setHours(_.toString(values.hours));
      }
    }, [values]);

    const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setDays(event.target.value);

    const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setHours(event.target.value);

    const handlePaste = (event: any) => {
      event.preventDefault();
      const clipboardData = event?.clipboardData || window.Clipboard;
      const pastedData = clipboardData.getData("text");
      if (!isNaN(pastedData))
        document.execCommand("insertText", false, pastedData);
    };

    const convertLeadingZero = (inputString: string) => {
      if (_.startsWith(inputString, "0")) {
        const numberPart = inputString.substring(1);
        const result = _.toNumber(numberPart);
        return result;
      }
      return _.toNumber(inputString);
    };

    return (
      <FormControl
        isInvalid={isError}
        isRequired={props.isRequired}
        style={style}
      >
        {label ? (
          <FormLabel fontSize="14" fontWeight={700} sx={{ ...labelSx }}>
            {label}
          </FormLabel>
        ) : null}
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <InputGroup size="sm">
              <Input
                min={0}
                pr="4.5rem"
                type="number"
                value={days}
                onChange={handleDaysChange}
                onPaste={handlePaste}
                onFocus={(e) => {
                  if (!e.target.value || e.target.value === "0") setDays("");
                }}
                onBlur={(e) => {
                  if (!e.target.value || e.target.value === "0") setDays("0");
                  const newDays = convertLeadingZero(e.target.value);
                  const newHours = _.toNumber(hours);
                  onValuesChange(newDays, newHours);
                }}
              />
              <InputRightElement width="4.5rem">
                {t("label.days")}
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <InputGroup size="sm">
              <Input
                min={0}
                max={24}
                pr="4.5rem"
                type="number"
                value={hours}
                onChange={handleHoursChange}
                onPaste={handlePaste}
                onFocus={(e) => {
                  if (!e.target.value || e.target.value === "0") setHours("");
                }}
                onBlur={(e) => {
                  if (!e.target.value || e.target.value === "0") setHours("0");
                  const newHours = convertLeadingZero(e.target.value);
                  const newDays = _.toNumber(days);
                  onValuesChange(newDays, newHours);
                }}
              />
              <InputRightElement width="4.5rem">
                {t("label.hours")}
              </InputRightElement>
            </InputGroup>
          </Box>
        </Box>
        {isError ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
);

export default EstimatedInput;
