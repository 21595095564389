import React, { useState, useMemo } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import {
  Box,
  Flex,
  Text,
  Avatar,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Icon,
  keyframes,
  Image,
  Tooltip,
  CircularProgress,
} from "@chakra-ui/react";
import {
  EllipsisHorizontalIcon,
  ArrowUturnLeftIcon,
  ExclamationTriangleIcon,
  // BookmarkIcon,
} from "@heroicons/react/24/outline";
import Utils from "@/Utils";
import { FileItem } from "@/Components/Common";

import HighlightedText from "../HighlightText";
import { useTranslation } from "react-multi-lang";

interface ITimelineProps {
  currentDate: string;
}

interface IGiphyProps {
  payload: IMessageStructure;
}

interface IMessageStructure {
  id?: string;
  timestamp: string;
  isSender: boolean;
  sender?: {
    id: string;
    name: string;
    avatar: string;
  } | null;
  content?: any;
  recalled?: boolean;
  fileAttachments?: any[];
  members?: any;
  status?: string;
  isNotification?: boolean;
}

interface ISectionProps {
  payload: IMessageStructure;
  type?: string;
  isSelected?: boolean;
  currentDate?: string | null;
  sx?: object;
  onPinMsg(): void;
  onDeleteMsg(msgId: string): void;
  onResendMsg(msgId: string): void;
}

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Timeline: React.FC<ITimelineProps> = ({ currentDate }) => {
  return (
    <Box
      sx={{
        py: 0.1,
        h2: {
          width: "100%",
          textAlign: "center",
          borderBottom: "2px solid #eee",
          lineHeight: "0.2em",
          margin: "10px 0 20px",
        },
        "h2 span": {
          fontSize: 12,
          fontWeight: 500,
          background: "gray.50",
          padding: "0 10px",
          textTransform: "uppercase",
        },
      }}
    >
      <h2>
        <span>{currentDate}</span>
      </h2>
    </Box>
  );
};

const Giphy: React.FC<IGiphyProps> = ({ payload }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!payload?.isSender && (
        <Text
          fontSize="sm"
          sx={{
            color: "gray.500",
            fontWeight: 600,
          }}
        >
          {payload?.sender?.name}
        </Text>
      )}
      <Tooltip label={payload?.timestamp} placement="auto">
        <Image
          src={JSON.parse(payload?.content)?.images?.fixed_height?.url}
          sx={{
            rounded: 5,
            minW: JSON.parse(payload?.content)?.images?.fixed_height?.width,
            minH: JSON.parse(payload?.content)?.images?.fixed_height?.height,
          }}
        />
      </Tooltip>
    </Box>
  );
};

const MessageBox: React.FC<ISectionProps> = ({
  payload,
  isSelected,
  currentDate,
  sx,
  // onPinMsg,
  onDeleteMsg,
  onResendMsg,
}) => {
  const t = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);

  const fileHasDeleted = useMemo(
    () =>
      _.every(
        payload?.fileAttachments,
        (fileAttachment) => fileAttachment?.file?.isDeleted
      ),
    [payload?.fileAttachments]
  );

  const highlightedText = useMemo(() => {
    return (
      <HighlightedText
        content={payload?.content || ""}
        users={!_.isEmpty(payload?.members) ? payload?.members : []}
      />
    );
  }, [payload]);

  const _renderMoreOptions = () =>
    payload?.isSender && (
      <Popover
        isOpen={isShowMore}
        onClose={() => setIsShowMore(false)}
        placement="auto"
      >
        <PopoverTrigger>
          {payload?.status === "success" ? (
            <IconButton
              size="sm"
              aria-label={""}
              isRound
              sx={{ bg: "none", ml: "auto" }}
              onClick={() => setIsShowMore(true)}
            >
              <EllipsisHorizontalIcon />
            </IconButton>
          ) : payload?.status === "pending" ? (
            <CircularProgress size="20px" isIndeterminate color="blue.300" />
          ) : (
            <Tooltip label="Retry" hasArrow>
              <IconButton
                size="xs"
                aria-label={""}
                isRound
                sx={{
                  bg: "none",
                  ml: "auto",
                  "&:hover": {
                    bg: "none",
                  },
                }}
                onClick={() => onResendMsg(payload?.id || "")}
              >
                <ExclamationTriangleIcon color="red" />
              </IconButton>
            </Tooltip>
          )}
        </PopoverTrigger>
        <PopoverContent
          sx={{
            width: "max-content",
            zIndex: "sticky",
          }}
        >
          {/* <Button
          size="sm"
          rounded={0}
          variant="ghost"
          colorScheme="cyan"
          leftIcon={<Icon as={BookmarkIcon} />}
          sx={{
            justifyContent: "flex-start",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsShowMore(false);
            onPinMsg();
          }}
        >
          Pin
        </Button> */}
          <Button
            size="sm"
            rounded={0}
            variant="ghost"
            colorScheme="red"
            leftIcon={<Icon as={ArrowUturnLeftIcon} />}
            sx={{
              justifyContent: "flex-start",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsShowMore(false);
              onDeleteMsg(payload?.id || "");
            }}
          >
            {t("button.recall")}
          </Button>
        </PopoverContent>
      </Popover>
    );

  return (
    <Box
      sx={{
        position: "relative", // Needed for the animation
        p: 2,
        animation: isSelected ? `${scaleAnimation} 0.3s ease-in-out` : "none", // Apply animation only if isSelected is true
        ...sx,
      }}
    >
      {currentDate && <Timeline currentDate={currentDate} />}
      {!_.isEmpty(payload?.content) && payload?.sender && (
        <Flex justify={payload?.isSender ? "flex-end" : "flex-start"} mb={2}>
          {!payload?.isSender && (
            <Tooltip label={payload?.sender?.name} hasArrow>
              <Avatar size="sm" src={payload?.sender?.avatar} mr={2} />
            </Tooltip>
          )}
          {
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {payload?.isSender && !payload?.recalled && _renderMoreOptions()}
              {Utils.isJsonString(payload?.content) && !payload?.recalled ? (
                <Giphy payload={payload} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {!payload?.isSender && (
                    <Text
                      fontSize="sm"
                      sx={{
                        color: "gray.500",
                        fontWeight: 600,
                      }}
                    >
                      {payload?.sender?.name}
                    </Text>
                  )}
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: payload?.isSender
                        ? "15px 15px 0px 15px"
                        : "15px 15px 15px 0px",
                      bg: payload?.recalled
                        ? "#F0F0F0"
                        : payload?.isSender
                        ? "#ebf5ff"
                        : "#F0F0F0",
                      color: payload?.recalled
                        ? "#B0B0B0"
                        : payload?.isSender
                        ? "#3699ff"
                        : "black",
                      border: "1px solid transparent",
                      transition: "border-color 0.3s ease-in-out",
                      "&:hover": {
                        borderColor:
                          payload?.isSender && !payload?.recalled
                            ? "#82b8ff"
                            : "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Box>
                      <Box
                        fontSize="sm"
                        sx={{
                          cursor: payload?.recalled ? "not-allowed" : "default",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {payload?.recalled
                          ? t("message.messageHasBeenRecalled")
                          : highlightedText}
                      </Box>
                    </Box>
                    <Text
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {dayjs(payload?.timestamp).format("HH:mm")}
                    </Text>
                  </Box>
                </Box>
              )}
              {!payload?.isSender && !payload?.recalled && _renderMoreOptions()}
            </Box>
          }
        </Flex>
      )}
      {!payload?.recalled && !fileHasDeleted ? (
        <Flex justify={payload?.isSender ? "flex-end" : "flex-start"}>
          {!payload?.isSender &&
            !payload?.isNotification &&
            !payload?.content && (
              <Tooltip label={payload?.sender?.name} hasArrow>
                <Avatar
                  size="sm"
                  src={payload?.sender?.avatar}
                  key={payload?.sender?.id}
                  mr={2}
                />
              </Tooltip>
            )}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              ml: !payload?.isSender && !payload?.content ? "-7px" : 0,
              maxW: "50%",
            }}
          >
            {payload?.isSender &&
              _.isEmpty(payload?.content) &&
              !payload?.recalled &&
              _renderMoreOptions()}
            {!_.isEmpty(payload?.fileAttachments) &&
              payload?.status !== "pending" && (
                <Box>
                  {!payload?.isSender && (
                    <Text
                      fontSize="sm"
                      sx={{
                        color: "gray.500",
                        fontWeight: 600,
                        pl: 2,
                      }}
                    >
                      {payload?.sender?.name}
                    </Text>
                  )}
                  <FileItem
                    zIndex={9}
                    files={_.map(payload?.fileAttachments, (fileAttachment) => {
                      const fileType = Utils.getMimeTypeFromFile(
                        fileAttachment?.file?.nameOriginal
                      );
                      return {
                        id: fileAttachment?.id,
                        name: fileAttachment?.file?.nameOriginal,
                        type: fileType,
                        imageUrl: _.includes(fileType, "image")
                          ? fileAttachment?.file?.path
                          : "",
                        downloadUrl: fileAttachment?.file?.path,
                        videoUrl: _.includes(fileType, "video")
                          ? fileAttachment?.file?.path
                          : "",
                        uploadStatus:
                          payload?.status === "pending" ? "uploading" : "",
                        timestamp: payload?.timestamp,
                      };
                    })}
                    containerStyles={{
                      marginLeft:
                        !payload?.isSender && payload?.content ? "33px" : 0,
                    }}
                  />
                </Box>
              )}
          </Box>
        </Flex>
      ) : (
        !_.isEmpty(payload?.fileAttachments) && (
          <Flex justify={payload?.isSender ? "flex-end" : "flex-start"}>
            {!payload?.isSender &&
              !payload?.isNotification &&
              !payload?.content && (
                <Tooltip label={payload?.sender?.name} hasArrow>
                  <Avatar
                    size="sm"
                    src={payload?.sender?.avatar}
                    key={payload?.sender?.id}
                    mr={2}
                  />
                </Tooltip>
              )}
            <Box>
              {!payload?.isSender && (
                <Text
                  fontSize="sm"
                  sx={{
                    color: "gray.500",
                    fontWeight: 600,
                    pl: 2,
                  }}
                >
                  {payload?.sender?.name}
                </Text>
              )}
              <Box
                sx={{
                  p: 2,
                  borderRadius: payload?.isSender
                    ? "15px 15px 0px 15px"
                    : "15px 15px 15px 0px",
                  bg: payload?.recalled
                    ? "#F0F0F0"
                    : payload?.isSender
                    ? "#ebf5ff"
                    : "#F0F0F0",
                  color: payload?.recalled
                    ? "#B0B0B0"
                    : payload?.isSender
                    ? "#3699ff"
                    : "black",
                  border: "1px solid transparent",
                  transition: "border-color 0.3s ease-in-out",
                  "&:hover": {
                    borderColor:
                      payload?.isSender && !payload?.recalled
                        ? "#82b8ff"
                        : "rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Box>
                  <Text
                    fontSize="sm"
                    sx={{
                      cursor: payload?.recalled ? "not-allowed" : "default",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {fileHasDeleted
                      ? t("message.filehasbeendeleted")
                      : t("message.messagehasbeenrecalled")}
                  </Text>
                </Box>
                <Text
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {dayjs(payload?.timestamp).format("HH:mm")}
                </Text>
              </Box>
            </Box>
          </Flex>
        )
      )}
      {!payload?.sender && (
        <Box>
          <Text
            sx={{
              fontSize: 12,
              fontWeight: 500,
              padding: "0 10px",
              textAlign: "center",
            }}
          >
            {dayjs(payload?.timestamp).format("HH:mm")}
          </Text>
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 500,
              padding: "0 10px",
              textAlign: "center",
            }}
          >
            {highlightedText}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MessageBox;
