import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import {
  Stack,
  Heading,
  Icon,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  HStack,
  Text,
  Tooltip,
  Tag,
  TagCloseButton,
  TagLabel,
  Skeleton,
} from "@chakra-ui/react";
import { FunnelIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { AdminLayout } from "@/Components/DefaultLayout";
import Pagination from "@/Components/Common/Table/Pagination";
import { SearchIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import Utils from "@/Utils";
import CommonColors from "@/Themes/CommonColors";

import { NotificationActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import {
  INotificationFilter,
  INotificationStructure,
} from "@/Interfaces/Notification.interface";
import { ProcessContent, RoundedContainer } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";
import dayjs from "dayjs";

const {
  fetchNotifications,
  markAsRead,
  markAllAsRead,
  resetNotificationRecucer,
} = NotificationActions;

interface INotification {
  page: number;
  limit: number;
  keyword?: string;
  status?: string;
}

const DEFAULT_FILTERS = {
  page: 1,
  limit: 10,
  sortBy: "createdAt",
  orderBy: "DESC",
};

const Notification: React.FC = () => {
  const t = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const payload: INotificationStructure[] = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "payload")
  );
  const pagination: INotificationFilter = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "pagination")
  );
  const meta = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "meta")
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION, "isFetchLoading")
  );
  const [filters, setFilters] = useState<INotification>(DEFAULT_FILTERS);

  useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (accessRouter) {
      dispatch(fetchNotifications(DEFAULT_FILTERS));
    }
    return () => {
      dispatch(resetNotificationRecucer());
    };
  }, []);

  const onPageChange = async (value: number) => {
    const newFilters = { ...filters, page: value };
    setFilters(newFilters);
    dispatch(fetchNotifications(newFilters));
  };

  const handleFilterChange = (name: string, value: string) =>
    setFilters({ ...filters, [name]: value });

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    dispatch(fetchNotifications(DEFAULT_FILTERS));
  };

  const handleSearch = () => {
    const resolvedFilter = Utils.removeEmptyFields(filters);
    if (resolvedFilter) dispatch(fetchNotifications(resolvedFilter));
  };

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);
    dispatch(fetchNotifications(updatedPagination));
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        h="max-content"
        variant="solid"
        key={label}
      >
        <TagLabel
          sx={{
            textTransform: field === "status" ? "capitalize" : "inherit",
          }}
        >
          {label}
        </TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (!pagination) return;

    if (pagination.keyword) {
      renderedTags.push(renderTag(pagination.keyword, "keyword"));
    }

    if (pagination.status) {
      renderedTags.push(renderTag(t(`status.${pagination.status}`), "status"));
    }

    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HStack>
          <Text fontSize="sm">
            {/* Any additional information you want to display */}
          </Text>
          <HStack spacing={4}>
            {!_.isEmpty(renderedTags) ? (
              <HStack spacing={1} alignItems="flex-start">
                <Text
                  fontSize="sm"
                  fontWeight={500}
                  sx={{
                    minW: "max-content",
                  }}
                  color={"#5C6e6c"}
                >
                  {t("label.filterBy")}:
                </Text>
                <HStack spacing={1} flexWrap="wrap">
                  {renderedTags}
                </HStack>
              </HStack>
            ) : (
              ""
            )}
          </HStack>
        </HStack>
      </Box>
    );
  };

  const _renderSkeleton = () => {
    return (
      <HStack flexDirection="column" gap={1}>
        {Array.from({ length: 10 }, (_, index) => (
          <Box key={index} w="full">
            <Skeleton height="30px" width="100%" />
          </Box>
        ))}
      </HStack>
    );
  };

  const _renderTopSection = () => {
    return (
      <RoundedContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <InputGroup
              size="sm"
              sx={{
                maxWidth: 200,
              }}
            >
              <InputRightElement
                onClick={handleSearch}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Icon as={SearchIcon} boxSize={5} />
              </InputRightElement>
              <Input
                placeholder={t("label.search")}
                value={filters.keyword}
                onChange={(e) => handleFilterChange("keyword", e.target.value)}
              />
            </InputGroup>
            <InputGroup
              sx={{
                maxWidth: 200,
              }}
              size="sm"
            >
              <Select
                placeholder={t("label.allStatus")}
                value={filters?.status}
                onChange={(e) => handleFilterChange("status", e.target.value)}
              >
                <option value="read">{t("status.read")}</option>
                <option value="unread">{t("status.unread")}</option>
              </Select>
            </InputGroup>
            <Box>
              <Button
                size="sm"
                //sx={{
                //  background: CommonColors.button,
                //  color: "#fff",
                //  "&:hover": {
                //    background: CommonColors.hoverButton,
                //  },
                //}}
                leftIcon={<Icon as={FunnelIcon} />}
                onClick={handleSearch}
                sx={{
                  color: "#fff",
                  background: "#D2A96A",
                  "&:hover": {
                    background: "#D2BD6A",
                  },
                }}
              >
                {t("button.filter")}
              </Button>
            </Box>
            <Box>
              <Button
                size="sm"
                leftIcon={<Icon as={ArrowPathIcon} />}
                onClick={handleReset}
                sx={{
                  color: "#fff",
                  background: "#bb7154",
                  "&:hover": {
                    background: "#Db9d97",
                  },
                }}
              >
                {t("button.reset")}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>{_renderTags()}</Box>
      </RoundedContainer>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        {isFetchLoading ? (
          _renderSkeleton()
        ) : !_.isEmpty(payload) ? (
          _.map(payload, (noti, index) => (
            <HStack
              key={index}
              sx={{
                p: 1,
                justifyContent: "space-between",
                cursor: "pointer",
                transition: "all .3s ease-in-out",
                borderBottom: "1px solid silver",
                ":hover": {
                  bg: CommonColors.blueLight,
                },
                rounded: 5,
              }}
              onClick={() => {
                const redirectPath = Utils.mapNotificationToPath(noti?.type);
                if (noti?.id && noti?.status === "unread")
                  dispatch(
                    markAsRead(
                      {
                        notificationIds: [noti.id],
                      },
                      pagination
                    )
                  );
                Utils.redirect(
                  `${redirectPath}?id=${
                    noti?.extraData?.boardId || noti?.recordId
                  }`
                );
              }}
            >
              <Box>
                <ProcessContent payload={noti} />
                <Text fontSize="xs">
                  <Tooltip
                    label={dayjs(noti?.createdAt).format("YYYY-MM-DD, h:mm:ss")}
                    hasArrow
                  >
                    {Utils.calculateTimeAgo(noti?.createdAt)}
                  </Tooltip>
                </Text>
              </Box>
              <Box
                w={3}
                h={3}
                rounded="full"
                bg={
                  noti.status === "read" ? "gray.200" : "rgba(187, 113, 84,0.8)"
                }
              />
            </HStack>
          ))
        ) : (
          <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
        )}
        <Box sx={{}}>
          <Pagination payload={meta} onPageChange={onPageChange} />
        </Box>
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading size="lg" color={"#5C6e6c"}>
            {t("title.Notifications")}
          </Heading>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="teal"
            onClick={() => dispatch(markAllAsRead(pagination))}
          >
            {t("button.markAllAsRead")}
          </Button>
        </Box>

        {_renderTopSection()}
        {_renderBottomSection()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Notification;
