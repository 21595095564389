import { ActionTypes } from "@/Constants";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import { IPerformanceFilter } from "@/Interfaces/Performance.interface";

// SINGLE ACTIONS
const setPerformanceFetchLoading = () => {
  return {
    type: ActionTypes.SET_PERFORMANCE_FETCH_LOADING,
  };
};

const setPerformanceGetLoading = () => {
  return {
    type: ActionTypes.SET_PERFORMANCE_GET_LOADING,
  };
};

const setPerformanceActionLoading = () => {
  return {
    type: ActionTypes.SET_PERFORMANCE_ACTION_LOADING,
  };
};

const setPerformancePagination = (payload: IPerformanceFilter) => {
  return {
    type: ActionTypes.SET_PERFORMANCE_PAGINATION,
    payload,
  };
};

const setPerformanceMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_PERFORMANCE_META,
    payload,
  };
};

const performAction = (
  actionType: string,
  itemId: string,
  performance?: any
) => ({
  type: ActionTypes.PERFORMANCE_PERFORM_ACTION,
  payload: { actionType, itemId, performance },
});

const resetPerformancePerformAction = () => {
  return {
    type: ActionTypes.RESET_PERFORMANCE_PERFORM_ACTION,
  };
};

const resetPerformanceRecucer = () => {
  return {
    type: ActionTypes.RESET_PERFORMANCE_REDUCER,
  };
};

//ASYNC ACTIONS

const fetchSuccess = (payload?: any) => {
  return {
    type: ActionTypes.FETCH_PERFORMANCES_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ActionTypes.FETCH_PERFORMANCES_FAILURE,
  };
};

const fetchPerformances = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setPerformanceFetchLoading());
    await APIs.fetchPerformances(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(fetchFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(fetchSuccess(result?.items));
          dispatch(setPerformanceMeta(result?.meta));
          dispatch(setPerformancePagination(payload));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const createSuccess = () => {
  return {
    type: ActionTypes.CREATE_PERFORMANCE_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ActionTypes.CREATE_PERFORMANCE_FAILURE,
  };
};

const createPerformance = (payload: any, pagination: IPerformanceFilter) => {
  return async (dispatch: any) => {
    dispatch(setPerformanceActionLoading());
    await APIs.createPerformance(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createSuccess());
          dispatch(fetchPerformances(pagination));
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error: any) => {
        await dispatch(createFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_PERFORMANCE_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ActionTypes.GET_PERFORMANCE_BY_ID_FAILURE,
  };
};

const getPerformanceById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setPerformanceGetLoading());
    await APIs.getPerformanceById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getByIdFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else dispatch(getByIdSuccess(result));
      })
      .catch(async (error: any) => {
        await dispatch(getByIdFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  fetchPerformances,
  createPerformance,
  getPerformanceById,
  performAction,
  resetPerformancePerformAction,
  resetPerformanceRecucer,
};
