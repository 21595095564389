//import { useState } from "react";
//import _ from "lodash";
//import { useSelector } from "react-redux";

import { Box } from "@chakra-ui/react";
//import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//import {
//  MagnifyingGlassPlusIcon,
//  MagnifyingGlassMinusIcon,
//  ClipboardIcon,
//} from "@heroicons/react/24/outline";
//import { RepeatClockIcon } from "@chakra-ui/icons";

//import { Routers } from "@/Constants";
//import { RootState } from "@/Store";
//import { useCopyToClipboard } from "@/Helpers";
//import { IProjectStructure } from "@/Interfaces/Project.interface";

import Calendar from "./Calendar";
//import CommonColors from "@/Themes/CommonColors";

interface ISectionProps {}

const Timeline: React.FC<ISectionProps> = ({}) => {
  //  const [, copy] = useCopyToClipboard();

  //  const projectDetails: IProjectStructure = useSelector((state: RootState) =>
  //    _.get(state.PROJECT, "details")
  //  );

  //  const [isCopied, setIsCopied] = useState<boolean>(false);
  //  const [scale, setScale] = useState<number>(1);

  //  const handleCopyText = (value: string) => {
  //    if (value)
  //      copy(value)
  //        .then(() => {
  //          setIsCopied(true);
  //          setTimeout(() => {
  //            setIsCopied(false);
  //          }, 500);
  //        })
  //        .catch((err) => console.log(err));
  //  };

  //  const handleZoomChange = (zoom: number) => setScale(zoom);

  //  const _renderToolbar = (params: any) => {
  //    const { zoomIn, zoomOut, resetTransform } = params;

  //    return (
  //      <Box
  //        sx={{
  //          display: "flex",
  //          justifyContent: "space-between",
  //          alignItems: "center",
  //          mb: 3,
  //        }}
  //      >
  //        <Box
  //          sx={{
  //            display: "flex",
  //            alignItems: "center",
  //            justifyContent: "flex-end",
  //          }}
  //        >
  //          <Tooltip hasArrow isOpen={isCopied} label={"Copied!"}>
  //            <Icon
  //              boxSize={5}
  //              sx={{
  //                "&:hover": {
  //                  color: CommonColors.dustyCoral,
  //                },
  //              }}
  //              onClick={() =>
  //                handleCopyText(
  //                  `${window.location.origin}${Routers.SHARE_TIMELINE}/?id=${projectDetails?.kanbanBoard?.id}`
  //                )
  //              }
  //            >
  //              <ClipboardIcon />
  //            </Icon>
  //          </Tooltip>
  //          <Link
  //            size="sm"
  //            variant="ghost"
  //            colorScheme="twitter"
  //            href={`${Routers.SHARE_TIMELINE}/?id=${projectDetails?.kanbanBoard?.id}`}
  //            target="_blank"
  //          >
  //            Share to client
  //          </Link>
  //        </Box>
  //        <Box
  //          sx={{
  //            display: "flex",
  //            gap: 1,
  //            p: 2,
  //          }}
  //        >
  //          <IconButton
  //            isDisabled={scale >= 2}
  //            sx={{
  //              background: CommonColors.balsamGreen,
  //              color: "#Fff",
  //              "&:hover": {
  //                background: "#45615F!important",
  //              },
  //            }}
  //            size="sm"
  //            aria-label={"Zoom In"}
  //            onClick={() => zoomIn()}
  //          >
  //            <MagnifyingGlassPlusIcon />
  //          </IconButton>
  //          <IconButton
  //            isDisabled={scale === 1 || scale <= params.minScale}
  //            sx={{
  //              background: CommonColors.artermis,
  //              color: "#Fff",
  //              "&:hover": {
  //                background: "#A48351!important",
  //              },
  //            }}
  //            size="sm"
  //            aria-label={"Zoom Out"}
  //            onClick={() => zoomOut()}
  //          >
  //            <MagnifyingGlassMinusIcon />
  //          </IconButton>
  //          <IconButton
  //            size="sm"
  //            aria-label={"Reset"}
  //            sx={{
  //              background: CommonColors.dustyCoral,
  //              color: "#Fff",
  //              "&:hover": {
  //                background: "#B57D6E!important",
  //              },
  //            }}
  //            isDisabled={scale === 1}
  //            onClick={() => resetTransform()}
  //          >
  //            <RepeatClockIcon />
  //          </IconButton>
  //        </Box>
  //      </Box>
  //    );
  //  };

  return (
    <Box>
      {/*<TransformWrapper
        limitToBounds={true}
        centerZoomedOut={false}
        minScale={1}
        initialScale={1}
        maxScale={2}
        panning={{ excluded: ["input"] }}
        doubleClick={{ disabled: true }}
        onTransformed={(event: any) =>
          handleZoomChange(event.instance.transformState.scale)
        }
        disabled
      >
        {(params) => (*/}
      <Box
        sx={{
          position: "relative",
          ".transform-component-module_content__FBWxo": {
            display: "block",
          },
        }}
      >
        {/*{_renderToolbar(params)}*/}
        {/*<TransformComponent
          wrapperStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          contentStyle={{
            minWidth: "100%",
            minHeight: "100%",
          }}
        >*/}
        <Calendar />
        {/*</TransformComponent>*/}
      </Box>
      {/*)}
      </TransformWrapper>*/}
    </Box>
  );
};

export default Timeline;
