import React from "react";
import _ from "lodash";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Box, Heading } from "@chakra-ui/react";

import CardItem from "./CardItem";
import { useTranslation } from "react-multi-lang";

interface ISectionProps {
  cards: any[];
}

const Cards: React.FC<ISectionProps> = ({ cards }) => {
  const t = useTranslation();
  return (
    <SortableContext
      items={_.map(cards, (card) => card.id)}
      strategy={verticalListSortingStrategy}
    >
      <Box display="flex" flexDirection="column" gap="2">
        {!_.isEmpty(cards) ? (
          _.map(cards, (card, index) => <CardItem key={index} card={card} />)
        ) : (
          <Heading
            sx={{
              fontWeight: 500,
              fontSize: "small",
              p: "5px",
              border: "1px solid transpanrent",
            }}
          >
            {t("label.noDataToDisplay")}
          </Heading>
        )}
      </Box>
    </SortableContext>
  );
};

export default Cards;
