import React, { useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  Text,
  Image,
  Flex,
  Avatar,
  Tooltip,
  Box,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";

import arrowIcon from "@/Assets/right_long_arrow.svg";

import { RootState, useTypedDispatch } from "@/Store";
import { ENUMS } from "@/Constants";
import Utils from "@/Utils";
import { UserActions } from "@/Actions";
import { RoundedContainer } from "@/Components/Common";
import { IProjectStructure } from "@/Interfaces/Project.interface";
import { useTranslation } from "react-multi-lang";

const { performAction: userPerformAction } = UserActions;
const { PROJECT_STATUS_COLOR } = ENUMS;

const Information: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const lineRef = useRef<any>(null);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const details: IProjectStructure = useSelector((state: RootState) =>
    _.get(state.PROJECT, "details")
  );
  const language = Utils.getSavedLanguage();

  const userData = Utils.getSavedUserData();
  const canViewUserProfile = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "viewProfile"
  );

  const _renderProjectInfo = () => {
    const managers = _.filter(details?.projectMember, (item) => {
      return _.some(item?.user?.userRole, (userRole) => {
        return userRole?.role?.roleCode === ENUMS.ROLES.MANAGER;
      });
    });

    const members = _.filter(details?.projectMember, (item) => {
      return !_.some(item?.user?.userRole, (userRole) => {
        return userRole?.role?.roleCode === ENUMS.ROLES.MANAGER;
      });
    });

    const convertTime = (inputString: any) => {
      const updatedString =
        inputString &&
        inputString.replace(/days/g, "ngày").replace(/hours/g, "giờ");

      return updatedString;
    };

    if (details?.type === ENUMS.PROJECT_TYPE.EXTERIOR)
      return (
        <Box
          sx={{
            maxW: isMobile ? "100%" : "100%",
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {/* Comment */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Box
              sx={{
                width: 192,
              }}
            >
              <Heading fontSize="sm">{t("label.degreeOfUrgency")}</Heading>
              <Text
                fontSize="sm"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {details?.urgency && t(`label.${details?.urgency}`)}
              </Text>
            </Box>
          </Box>
          {/* End Comment */}
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Box
              sx={{
                width: 192,
              }}
            >
              <Heading
                fontSize="sm"
                sx={{
                  mb: 1,
                }}
              >
                {t("label.managers")}
              </Heading>
              {!_.isEmpty(managers)
                ? _.map(managers, (manager, index) => (
                    <Tooltip
                      key={index}
                      hasArrow
                      label={manager?.user?.userData?.fullName}
                    >
                      <Avatar
                        sx={{
                          cursor: canViewUserProfile ? "pointer" : "default",
                        }}
                        size="sm"
                        name={manager?.user?.userData?.fullName}
                        src={manager?.user?.userData?.avatar?.path}
                        onClick={() =>
                          canViewUserProfile &&
                          dispatch(
                            userPerformAction(
                              "viewProfile",
                              manager?.id,
                              manager
                            )
                          )
                        }
                      />
                    </Tooltip>
                  ))
                : "--"}
            </Box>
            <Box>
              <Heading fontSize="sm">{t("label.members")}</Heading>
              {!_.isEmpty(members)
                ? _.map(members, (member, index) => (
                    <Tooltip
                      key={index}
                      hasArrow
                      label={member?.user?.userData?.fullName}
                    >
                      <Avatar
                        sx={{
                          cursor: canViewUserProfile ? "pointer" : "default",
                        }}
                        size="sm"
                        name={member?.user?.userData?.fullName}
                        src={member?.user?.userData?.avatar?.path}
                        onClick={() =>
                          canViewUserProfile &&
                          dispatch(
                            userPerformAction("viewProfile", member?.id, member)
                          )
                        }
                      />
                    </Tooltip>
                  ))
                : "--"}
            </Box>
          </Box>
          <Flex flexDirection="column" gap={3}>
            <Flex alignItems="center" gap={3}>
              <Box
                ref={lineRef}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ minW: "max-content" }}>
                  <Heading fontSize="sm"> {t("label.startDate")}</Heading>
                  <Text fontSize="sm">
                    {dayjs(details?.startDate).isValid()
                      ? dayjs(details.startDate).format("DD/MM/YYYY")
                      : "--"}
                  </Text>
                </Box>
                <Box
                  sx={{
                    minW: "103px",
                  }}
                >
                  <Image objectFit="cover" src={arrowIcon} />
                </Box>
              </Box>
              <Box>
                <Heading fontSize="sm">{t("label.endDate")}</Heading>
                <Text fontSize="sm">
                  {dayjs(details?.endDate).isValid()
                    ? dayjs(details.endDate).format("DD/MM/YYYY")
                    : "--"}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box
              sx={{
                width: 192,
              }}
            >
              <Heading fontSize="sm">
                {t("label.estimatiedWorkingTime")}
              </Heading>
              <Text fontSize="sm">
                {language === "en"
                  ? details?.estimateWorkingTime || "--"
                  : details?.estimateWorkingTime &&
                    convertTime(details?.estimateWorkingTime)}
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading fontSize="sm"> {t("label.description")}</Heading>
            <Box
              sx={{
                ml: 4,
                "& a": {
                  color: "rgba(22, 117, 224,1)",
                  "&:hover": {
                    color: "rgba(22, 117, 224,0.8)",
                  },
                },
              }}
              dangerouslySetInnerHTML={{
                __html: details?.description ? details?.description : "--",
              }}
            />
          </Box>
        </Box>
      );
    return (
      <Box
        sx={{
          maxW: isMobile ? "100%" : "100%",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {/* Comment */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: 192,
            }}
          >
            <Heading fontSize="sm">{t("label.degreeOfUrgency")}</Heading>
            <Text
              fontSize="sm"
              sx={{
                textTransform: "capitalize",
              }}
            >
              {details?.urgency && t(`label.${details?.urgency}`)}
            </Text>
          </Box>

          <Box>
            <Heading fontSize="sm">{t("label.serverPath")}</Heading>
            <Text fontSize="sm">{details?.serverPath || "--"}</Text>
          </Box>
        </Box>
        {/* End Comment */}
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            flexWrap: "wrap",
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: 192,
            }}
          >
            <Heading
              fontSize="sm"
              sx={{
                mb: 1,
              }}
            >
              {t("label.managers")}
            </Heading>
            {!_.isEmpty(managers)
              ? _.map(managers, (manager, index) => (
                  <Tooltip
                    key={index}
                    hasArrow
                    label={manager?.user?.userData?.fullName}
                  >
                    <Avatar
                      sx={{
                        cursor: canViewUserProfile ? "pointer" : "default",
                      }}
                      size="sm"
                      name={manager?.user?.userData?.fullName}
                      src={manager?.user?.userData?.avatar?.path}
                      onClick={() =>
                        canViewUserProfile &&
                        dispatch(
                          userPerformAction("viewProfile", manager?.id, manager)
                        )
                      }
                    />
                  </Tooltip>
                ))
              : "--"}
          </Box>
          <Box>
            <Heading fontSize="sm"> {t("label.client")}</Heading>
            <Text fontSize="sm">{details?.clientNew?.name}</Text>
          </Box>
        </Box>
        <Flex flexDirection="column" gap={3}>
          <Flex alignItems="center" gap={3}>
            <Box
              ref={lineRef}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Box sx={{ minW: "max-content" }}>
                <Heading fontSize="sm">{t("label.startDate")}</Heading>
                <Text fontSize="sm">
                  {dayjs(details?.startDate).isValid()
                    ? dayjs(details.startDate).format("DD/MM/YYYY")
                    : "--"}
                </Text>
              </Box>
              <Box
                sx={{
                  minW: "103px",
                }}
              >
                <Image objectFit="cover" src={arrowIcon} />
              </Box>
            </Box>
            <Box>
              <Heading fontSize="sm">{t("label.endDate")}</Heading>
              <Text fontSize="sm">
                {dayjs(details?.endDate).isValid()
                  ? dayjs(details.endDate).format("DD/MM/YYYY")
                  : "--"}
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: 192,
            }}
          >
            <Heading fontSize="sm"> {t("label.estimatiedWorkingTime")}</Heading>
            <Text fontSize="sm">
              {language === "en"
                ? details?.estimateWorkingTime || "--"
                : details?.estimateWorkingTime &&
                  convertTime(details?.estimateWorkingTime)}
            </Text>
          </Box>
        </Box>
        <Box>
          <Heading fontSize="sm"> {t("label.description")}</Heading>
          <Box
            sx={{
              ul: {
                ml: 5,
              },
              ol: {
                ml: 5,
              },
              "& a": {
                color: "rgba(22, 117, 224,1)",
                "&:hover": {
                  color: "rgba(22, 117, 224,0.8)",
                },
              },
            }}
            dangerouslySetInnerHTML={{
              __html: details?.description ? details?.description : "--",
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <RoundedContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Heading
              size="md"
              sx={{
                textTransform: "uppercase",
              }}
            >
              {details?.name}
            </Heading>
            <Text
              sx={{
                fontSize: 12,
              }}
            >
              {`#${details?.code}`}
            </Text>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Heading size="sm">{t("label.status")}</Heading>
            <Text
              color={
                PROJECT_STATUS_COLOR[
                  details?.status as keyof typeof PROJECT_STATUS_COLOR
                ]?.text
              }
              bgColor={
                PROJECT_STATUS_COLOR[
                  details?.status as keyof typeof PROJECT_STATUS_COLOR
                ]?.background
              }
              sx={{
                textTransform: "capitalize",
                fontWeight: 700,
                borderRadius: "20px",
                px: 2,
                py: 1,
                fontSize: "12px",
              }}
            >
              {details?.status && t(`status.${details?.status}`)}
            </Text>
          </Box>
        </Box>
        {_renderProjectInfo()}
      </RoundedContainer>
    );
  };

  return renderMain();
};

export default Information;
