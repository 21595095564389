import React from "react";
import _ from "lodash";
import {
  Box,
  Icon,
  IconButton,
  Text,
  //  Avatar,
  Tooltip,
} from "@chakra-ui/react";

import { Table, Pagination } from "rsuite";

import { TextComponent, NoDataFound } from "@/Components/Common";
import { type IPaginate, type TActions } from "@/Types/Common.types";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

const { TIMECARD_STATUS_COLOR } = ENUMS;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  onChangeLimit?: (limint: number) => void;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const TimeOffRequestDataTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  onRowAction,
  isLoading,
  onChangeLimit,
}) => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;

  const getAccountId = Utils.getAccountId();
  const userData = Utils.getSavedUserData();

  const approveDayOffRequest = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "DayOffRequest",
    "approve"
  );

  const deleteDayOff = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "DayOffRequest",
    "deleteDayOff"
  );

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={payload}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        height={isMobile ? 400 : 550}
      >
        <Column width={200} fixed={!isMobile} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.fullName")}
          </HeaderCell>
          <Cell dataKey="userCreated.userData.fullName" />
        </Column>
        <Column width={100} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.staffCode")}
          </HeaderCell>
          <Cell dataKey="userCreated.staffCode" />
        </Column>
        <Column align="center" width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.startDate")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              `${dayjs(rowData.startDate).format("DD/MM/YYYY")}(${
                rowData.startDateType
              })`
            }
          </Cell>
        </Column>
        <Column align="center" width={150} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.endDate")}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              `${dayjs(rowData.endDate).format("DD/MM/YYYY")}(${
                rowData.endDateType
              })`
            }
          </Cell>
        </Column>
        <Column width={120} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.duration")}
          </HeaderCell>
          <Cell dataKey="duration" />
        </Column>
        <Column minWidth={150} flexGrow={1} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.reason")}
          </HeaderCell>
          <Cell dataKey="reason">
            {(rowData) => (
              <TextComponent maxW={"100%"} content={rowData.reason || "--"} />
            )}
          </Cell>
        </Column>
        <Column width={100} align="center" resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.status")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Text
                  color={
                    TIMECARD_STATUS_COLOR[
                      rowData?.status as keyof typeof TIMECARD_STATUS_COLOR
                    ].text
                  }
                  bgColor={
                    TIMECARD_STATUS_COLOR[
                      rowData?.status as keyof typeof TIMECARD_STATUS_COLOR
                    ].background
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {t(`status.${rowData?.status}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={120} fixed="right">
          <HeaderCell
            verticalAlign="center"
            align="center"
            style={HEADER_STYLE}
          >
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    // justifyContent: "center",
                  }}
                >
                  {approveDayOffRequest && (
                    <Tooltip label={t("tooltip.view")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={EyeIcon} />}
                        aria-label={""}
                        onClick={() => {
                          onRowAction("details", rowData);
                        }}
                      />
                    </Tooltip>
                  )}
                  {rowData?.status !== "pending" && !approveDayOffRequest && (
                    <Tooltip label={t("tooltip.view")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={EyeIcon} />}
                        aria-label={""}
                        onClick={() => onRowAction("details", rowData)}
                      />
                    </Tooltip>
                  )}
                  {rowData?.status === "pending" &&
                    (approveDayOffRequest ||
                      getAccountId === rowData?.userCreated.id) && (
                      <Box
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Tooltip label={t("tooltip.edit")}>
                          <IconButton
                            sx={{
                              bg: "transparent",
                            }}
                            size="md"
                            isRound
                            icon={<Icon as={PencilSquareIcon} />}
                            aria-label={""}
                            onClick={() => onRowAction("edit", rowData)}
                          />
                        </Tooltip>
                        {(deleteDayOff ||
                          getAccountId === rowData?.userCreated.id) && (
                          <Tooltip label={t("tooltip.delete")}>
                            <IconButton
                              sx={{
                                bg: "transparent",
                              }}
                              size="md"
                              isRound
                              icon={
                                <Icon
                                  as={TrashIcon}
                                  sx={{ color: "#bb7154" }}
                                />
                              }
                              aria-label={""}
                              color="error"
                              onClick={() => onRowAction("delete", rowData)}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    )}
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "&.rs-pagination-btn:disabled": {
            color: "#c5c6c7!important",
          },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default TimeOffRequestDataTable;
