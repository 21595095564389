import { PURGE } from "redux-persist";
import { ActionTypes } from "@/Constants";
import Utils from "@/Utils";
import _ from "lodash";

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1,
};

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  keyword: "",
  status: "",
  startDate: _.first(Utils.getDaysOfWeek(new Date(), "YYYY-MM-DD")),
  endDate: _.last(Utils.getDaysOfWeek(new Date(), "YYYY-MM-DD")),
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  isCreateTimekeepingSuccess: false,
  isUpdateTimekeepingSuccess: false,
  isCreaetTimekeepingFail: false,
  meta: DEFAULT_META,
  pagination: DEFAULT_PAGINATION,
  timekeepingList: [],
  checkInList: [],
  message: "",
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_REDUCER_TIMEKEEPING:
      return {
        ...initialState,
      };
    case ActionTypes.SET_TIMEKEEPING_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_TIMEKEEPING_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    case ActionTypes.SET_TIMEKEEPING_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isGetLoading: true,
      };
    case ActionTypes.SET_META_TIMEKEEPING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_TIMEKEEPING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };

    case ActionTypes.CREATE_TIMEKEEPING_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: true,
        isCreaetTimekeepingFail: false,
      };
    case ActionTypes.CREATE_TIMEKEEPING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: false,
        isCreaetTimekeepingFail: true,
        message: payload,
      };

    case ActionTypes.CREATE_TIMEKEEPING_ADMIN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: true,
      };
    case ActionTypes.CREATE_TIMEKEEPING_ADMIN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: false,
      };

    case ActionTypes.FETCH_TIMEKEEPING_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateTimekeepingSuccess: false,
        isUpdateTimekeepingSuccess: false,
        timekeepingList: payload,
      };
    case ActionTypes.FETCH_TIMEKEEPING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isCreateTimekeepingSuccess: false,
        isUpdateTimekeepingSuccess: false,
        timekeepingList: [],
      };

    case ActionTypes.GET_BY_ID_TIMEKEEPING_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.GET_BY_ID_TIMEKEEPING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.UPDATE_TIMEKEEPING_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateTimekeepingSuccess: true,
      };
    case ActionTypes.UPDATE_TIMEKEEPING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateTimekeepingSuccess: false,
      };

    case ActionTypes.CREATE_CHECK_OUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: true,
      };
    case ActionTypes.CREATE_CHECK_OUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateTimekeepingSuccess: false,
      };

    case ActionTypes.UPDATE_CHECK_OUT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateTimekeepingSuccess: true,
      };
    case ActionTypes.UPDATE_CHECK_OUT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateTimekeepingSuccess: false,
      };

    //case ActionTypes.FETCH_CHECK_IN_SUCCESS:
    //  return {
    //    ...state,
    //    requestHasError: false,
    //    requestIsSuccess: true,
    //    requestIsLoading: false,
    //    isFetchLoading: false,
    //    isCreateTimekeepingSuccess: false,
    //    isUpdateTimekeepingSuccess: false,
    //    isCreaetTimekeepingFail: false,
    //    checkInList: payload,
    //  };
    //case ActionTypes.FETCH_CHECK_IN_FAILURE:
    //  return {
    //    ...state,
    //    requestHasError: true,
    //    requestIsSuccess: false,
    //    requestIsLoading: false,
    //    isFetchLoading: false,
    //    isCreateTimekeepingSuccess: false,
    //    isUpdateTimekeepingSuccess: false,
    //    checkInList: [],
    //  };

    case ActionTypes.RESET_MESSAGE_TIMEKEEPING:
      return {
        ...state,
        message: "",
        isCreaetTimekeepingFail: false,
      };

    default:
      return state;
  }
};
