import {
  Center,
  Heading,
  Image,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { Button, TextField } from "@/Components/Common";

const ComingSoon = () => {
  return (
    <Stack
      bgGradient="/bg9.jpg"
      bgAttachment="fixed"
      bgPosition="center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Center p={20} flex={1}>
        <Center
          px={10}
          py={14}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width="100%"
          maxWidth="xl"
          bgColor="white"
          borderRadius={10}
        >
          <Heading size="md" textAlign="center">
            We&apos;re launching soon
          </Heading>
          <Text fontSize={14} textAlign="center" mt={2} color="gray.500">
            This is your opportunity to get creative amazing opportunaties that
            gives readers an idea
          </Text>
          <Wrap width="100%" mt={4}>
            <WrapItem flex={1} minWidth="100px">
              <TextField placeholder="Email" flex={1} />
            </WrapItem>
            <WrapItem width="100px">
              <Button colorScheme="blue">Notify me</Button>
            </WrapItem>
          </Wrap>
          <Image maxWidth="250px" src="/chart-graph.png" />
        </Center>
      </Center>
    </Stack>
  );
};

export default ComingSoon;
