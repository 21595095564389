import React, { useState, useEffect } from "react";
import _ from "lodash";

import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";

import { Stack, Box, Icon, Text, ScaleFade, Portal } from "@chakra-ui/react";
import { Toast } from "@/Widgets";
import Utils from "@/Utils";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";

interface UploadFileInputProps {
  isEditing?: boolean;
  files?: any[];
  onFilesChange(files: any[]): void;
  sx?: object;
  maxSize?: number;
  clearIcon?: boolean;
  accept?: string;
  maxFiles?: number;
  onErrors?(valid: boolean): void;
  disabled?: boolean;
  isCancel?: boolean;
}

const UploadFileInput: React.FC<UploadFileInputProps> = ({
  isEditing,
  files,
  sx,
  onFilesChange,
  maxSize,
  clearIcon = true,
  accept = "image/*, video/*",
  maxFiles,
  onErrors,
  disabled,
  isCancel,
}) => {
  const t = useTranslation();
  const [extFiles, setExtFiles] = useState<any[]>([]);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const [selectedCopyFile, setSelectedCopyFile] = useState<any>(null);

  useEffect(() => {
    if (!_.isEmpty(files) || (isCancel && !_.isEqual(files, extFiles)))
      fetchData(files);
  }, [files, isCancel]);

  useEffect(() => {
    if (!_.isEmpty(extFiles) && onErrors) {
      onErrors(_.every(extFiles, (file) => file.valid));
    }
  }, [extFiles]);

  useEffect(() => {
    if (selectedCopyFile !== null) {
      const timeoutId = setTimeout(() => {
        setSelectedCopyFile(null);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [selectedCopyFile]);

  const fetchData = async (files: any) => {
    const promises = _.map(files, (item) =>
      Utils.getFileFromURL(item.file.path, item.file.nameOriginal)
        .then((newFile) => newFile)
        .catch((error) => {
          Toast({
            title: error?.message,
            status: "error",
          });
          return null;
        })
    );

    try {
      const results = await Promise.all(promises);

      if (!_.isEmpty(files) && !_.isEmpty(results)) {
        setExtFiles(
          _.map(results, (result, index: number) => ({
            id: files?.[index]?.id,
            errors: undefined,
            file: result,
            name: result?.name,
            size: result?.size,
            valid: true,
            downloadUrl: files?.[index]?.file?.path,
            videoUrl:
              result?.name &&
              _.includes(Utils.getMimeTypeFromFile(result?.name), "video")
                ? files?.[index]?.file?.path
                : "",
          }))
        );
      } else {
        setExtFiles([]);
      }
    } catch (error: any) {
      Toast({
        title: error?.message,
        status: "error",
      });
    } finally {
    }
  };

  const updateFiles = (incomingFiles: any[]) => {
    setExtFiles(incomingFiles);
    onFilesChange(incomingFiles);
  };

  const onDelete = (id: string) => {
    const newFiles = extFiles.filter((x) => x.id !== id);
    setExtFiles(newFiles);
    onFilesChange(newFiles);
  };

  /**
   * Handles the download of a file identified by fileId from a given downloadUrl.
   * Downloads the file content, creates a Blob, and triggers a download link.
   *
   * @param {string} fileId - The unique identifier for the file.
   * @param {string} downloadUrl - The URL from which to fetch the file.
   */
  const handleDownload = async (fileId: string, downloadUrl: string) => {
    // Check if downloadUrl is provided
    if (!downloadUrl) return;

    try {
      // Fetch the file content from the provided downloadUrl
      const image = await fetch(downloadUrl);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      // Create an anchor element for initiating the download
      const anchor = document.createElement("a");

      // Retrieve the fileName based on fileId from the 'files' array
      const fileName =
        _.filter(files, (file) => file.id === fileId)[0]?.file?.nameOriginal ||
        "New file";

      // Set the fileName as the download attribute for the anchor element
      anchor.download = fileName;

      // Set the URL.createObjectURL result as the href for the anchor element
      anchor.href = imageURL;

      // Append the anchor to the document body, trigger the click event, and remove the anchor
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Revoke the object URL to free up resources
      URL.revokeObjectURL(imageURL);
    } catch (error: any) {
      // Handle any download errors
      Toast({ status: "error", title: error?.message });
    }
  };

  const handleSee = (imageSource: string) => {
    setImageSrc(imageSource);
  };

  const handleWatch = (videoSource: string) => {
    setVideoSrc(videoSource);
  };

  // const handleStart = (filesToUpload: any[]) => {
  //   console.log("advanced demo start upload", filesToUpload);
  // };

  // const handleFinish = (uploadedFiles: any[]) => {
  //   console.log("advanced demo finish upload", uploadedFiles);
  // };

  const handleAbort = (id: string) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };

  const handleCancel = (id: string) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  const handleCopy = (id: string) => {
    const findSelectedFile = _.find(extFiles, (file) => file.id === id);
    if (findSelectedFile)
      Utils.copyImageToClipboard(findSelectedFile?.downloadUrl);
  };

  return (
    <Stack
      sx={{
        ...sx,
        ".files-ui-footer, .files-ui-header, .files-ui-dropzone-children-container label":
          {
            display: isEditing ? "flex" : "none",
          },
        // ".files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container":
        //   {
        //     width: "100px",
        //     height: "100px",
        //   },
        ".files-ui-file-mosaic-main-container": {
          pointerEvents: "visible",
        },
        ".fui-dropzone-root": {
          border: isEditing ? "1px dashed rgba(100, 108,127 , 1)" : "none",
        },
        ".files-ui-dropzone-children-container": {
          ml: !isEditing || _.isEmpty(extFiles) ? 0 : 5,
          justifyContent: "start",
        },
        ".files-ui-header > :nth-last-of-type(2), .files-ui-header > :last-child":
          {
            display: `${clearIcon ? "none" : "flex"}!important`,
          },

        transition: "all .3s ease-in-out",
        pointerEvents:
          disabled || !isEditing
            ? videoSrc || imageSrc
              ? "visible"
              : "none"
            : "visible",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "100%",
      }}
    >
      {/* {_.isEmpty(files) && !isEditing && <Text fontSize="sm">--</Text>} */}
      <Dropzone
        onChange={updateFiles}
        minHeight={isEditing || !_.isEmpty(extFiles) ? "195px" : 0}
        value={extFiles}
        accept={accept}
        maxFiles={maxFiles && maxFiles}
        maxFileSize={maxSize}
        label={t("label.DragDropDilesHereOrClickToBrowse")}
        // onUploadStart={handleStart}
        // onUploadFinish={handleFinish}
        clickable={isEditing}
        fakeUpload
      >
        {_.map(extFiles, (file, index) => {
          return (
            <Box
              key={file?.id || index}
              sx={{
                position: "relative",
                zIndex: 999,
                rounded: 5,
              }}
            >
              <ScaleFade initialScale={0.9} in={true}>
                {!isEditing && _.includes(file?.file?.type, "image") && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "32px",
                      p: 0,
                      right: "46px",
                      zIndex: 999,
                      lineHeight: 1,
                      w: "23px",
                      h: "23px",
                      rounded: "full",
                      bg: "rgba(32, 33, 36, 0.65)",
                      "&:hover": {
                        bg: "rgba(32, 33, 36, 0.85)",
                      },
                      cursor: "pointer",
                      pointerEvents: "auto",
                      overflow: "hidden",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCopy(file?.id);
                      setSelectedCopyFile(file);
                    }}
                  >
                    <Icon
                      as={DocumentDuplicateIcon}
                      w="18px"
                      h="18px"
                      color="#ddd"
                    />
                  </Box>
                )}
                {/* Copied */}
                {selectedCopyFile?.id === file?.id && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 999,
                      bg: "rgba(28, 29, 32, 0.65)",
                      inset: 0,
                      w: "100%",
                      h: "100%",
                      overflow: "hidden",
                      rounded: 5,
                      pointerEvents: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        w: "full",
                        h: "full",
                      }}
                    >
                      <Icon
                        as={CheckCircleIcon}
                        w="18px"
                        h="18px"
                        color="green.200"
                      />
                      <Text fontSize="sm" color="#ddd">
                        Copied
                      </Text>
                    </Box>
                  </Box>
                )}
                <FileMosaic
                  key={file.id}
                  {...file}
                  onDelete={isEditing ? onDelete : undefined}
                  onSee={handleSee}
                  onWatch={handleWatch}
                  onAbort={handleAbort}
                  onCancel={handleCancel}
                  onDownload={handleDownload}
                  resultOnTooltip
                  alwaysActive
                  preview
                />
              </ScaleFade>
            </Box>
          );
        })}
      </Dropzone>
      <Portal>
        {imageSrc && (
          <FullScreen
            open={imageSrc !== undefined}
            onClose={() => {
              setImageSrc(undefined);
            }}
          >
            <ImagePreview src={imageSrc} />
          </FullScreen>
        )}
        {videoSrc && (
          <FullScreen
            open={videoSrc !== undefined}
            onClose={() => setVideoSrc(undefined)}
          >
            <VideoPreview src={videoSrc} autoPlay controls />
          </FullScreen>
        )}
      </Portal>
    </Stack>
  );
};

export default UploadFileInput;
