import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ILoginStructure } from "@/Interfaces/Auth.interface";

const { AUTHENTICATION, FCM_TOKEN } = APIs;

//export const login = async (payload: ILoginStructure) => {
//  return await request(AUTHENTICATION.LOGIN, "POST", {
//    account: payload.email,
//    password: payload.password,
//  });
//};

export const login = async (payload: ILoginStructure) => {
  return await request(AUTHENTICATION.LOGIN, "POST", payload);
};

export const refreshToken = async (payload: { refreshToken: string }) => {
  return await request(AUTHENTICATION.REFRESH_TOKEN, "POST", payload);
};

export const forgotPassword = async (payload: { email: string }) => {
  return await request(AUTHENTICATION.FORGOT_PASSWORD, "POST", payload);
};

export const verifyAccount = async (payload: {
  email: string;
  code: string;
}) => {
  return await request(AUTHENTICATION.VERIFY_ACCOUNT, "POST", payload);
};

export const resetPassword = async (payload: {
  email: string;
  code: string;
  newPassword: string;
  confirmationNewPassword: string;
}) => {
  return await request(AUTHENTICATION.RESET_PASSWORD, "POST", payload);
};

export const createFCMToken = async (payload: {
  userId: string;
  token: string;
}) => {
  return await request(FCM_TOKEN.BASIC, "POST", payload);
};

export const removeFCMToken = async (payload: {
  userId: string;
  token: string;
}) => {
  return await request(FCM_TOKEN.BASIC, "PUT", payload);
};

export const changeLocale = async (payload: {
  userId: string;
  locale: string;
  token: string;
}) => {
  return await request(FCM_TOKEN.CHANGE_LOCALE, "PUT", payload);
};
