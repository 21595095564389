import React, { useState, useEffect } from "react";
import { Box, Button, Icon } from "@chakra-ui/react";

import { ChatActions } from "@/Actions";

import PortentialChats from "../PortentialChats";

import UserChat from "../UserChat";
import { RootState, useTypedDispatch } from "@/Store";
import { IChatPerformAction } from "@/Interfaces/Chat.interface";
import _ from "lodash";
import { useSelector } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-multi-lang";

const { resetChatPerformAction, fetchMessageChannel } = ChatActions;

const ChatMobile: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [isShowChat, setIsShowChat] = useState(false);
  const chatPerformAction: IChatPerformAction | any = useSelector(
    (state: RootState) => _.get(state.CHAT, "performAction")
  );

  useEffect(() => {
    if (chatPerformAction?.chatId) {
      setIsShowChat(true);
    }
    return () => {
      setIsShowChat(false);
    };
  }, [chatPerformAction]);

  const _renderChat = () => {
    return (
      <Box sx={{ display: "flex", gap: 3, height: "100%" }}>
        {!isShowChat ? (
          <PortentialChats />
        ) : (
          <Box sx={{ h: "100%", w: "100%", borderLeft: "1px solid silver" }}>
            <Button
              size="sm"
              leftIcon={<Icon as={ChevronLeftIcon} />}
              onClick={() => {
                dispatch(resetChatPerformAction());
                dispatch(fetchMessageChannel(true));
              }}
              variant="ghost"
            >
              {t("button.backToChatList")}
            </Button>
            <UserChat />
          </Box>
        )}
      </Box>
    );
  };

  return _renderChat();
};

export default ChatMobile;
