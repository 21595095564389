import React, { useEffect, useRef, useState, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Box, Heading, IconButton, Image, Icon } from "@chakra-ui/react";
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";
import { RepeatClockIcon } from "@chakra-ui/icons";

import CommonColors from "@/Themes/CommonColors";
import SingleSlider from "@/Components/Common/DateRangeSlider/SingleRange";
import { BoardActions, ProjectActions } from "@/Actions";
import Utils from "@/Utils";

import Calendar from "./Calendar";
import logo from "@/Assets/cc-logo-white.svg";
import { RootState, useTypedDispatch } from "@/Store";
import { useWindowWidth } from "@/Helpers";
import FullHDWarning from "@/Components/Common/FullScreenWarning";
import "dayjs/locale/vi";
import LanguageSwitch from "@/Components/LayoutPart/Appbar/LanguageSwitch";
const { getBoardById } = BoardActions;
const { saveCurrentDateInScroller } = ProjectActions;

const ShareTimelineToClient: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const language = Utils.getSavedLanguage();
  const currentLocale = dayjs().locale();

  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.PROJECT, "currentDateInScroller")
  );

  const [activeDates, setActiveDates] = useState<string[]>(
    Utils.generateDefaultTimeline(7)
  );

  const [activeView, setActiveView] = useState<"week" | "month">("week");
  // const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  // const [currentFilter, setCurrentFilter] = useState<any>([]);
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);
  const [minSlotWidthDate, setMinSlotWidthDate] = useState<number>(50);
  const [calendarWidth, setCalendarWidth] = useState<number>(0);
  const [visibleDays, setVisibleDays] = useState<number>(isMobile ? 7 : 14);
  const [isReset, setIsReset] = useState(false);

  const timeline = useMemo(() => {
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails, 2);
    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);
    return dateRanges;
  }, [boardDetails]);

  useEffect(() => {
    generateTimeline();
    return () => {
      setActiveDates(Utils.generateDefaultWeekDay());
      setActiveView("week");
    };
  }, []);

  useEffect(() => {
    updateDateRanges();
  }, [boardDetails]);

  useEffect(() => {
    if (visibleDays > 0 && calendarWidth > 0) handleVisibleDaysChange();
  }, [visibleDays, calendarWidth]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsShowFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const generateTimeline = () => {
    const boardId = searchParams.get("id");
    if (boardId) {
      dispatch(getBoardById(boardId));
    }
  };

  const handleVisibleDaysChange = () => {
    const newValue = parseInt((calendarWidth / visibleDays).toString());
    setMinSlotWidthDate(newValue);
    const defaultTimelineStartDateIndex = _.findIndex(
      timeline,
      (date) => date === currentDateInScroller
    );

    if (defaultTimelineStartDateIndex !== -1) {
      const startDate = currentDateInScroller;
      const endDate =
        timeline[defaultTimelineStartDateIndex + (visibleDays - 1)] ||
        _.last(timeline);
      const newActiveDates = Utils.generateDateRanges(startDate, endDate);
      if (!_.isEmpty(newActiveDates)) {
        setActiveDates(newActiveDates);
      }
    }
  };

  const updateDateRanges = () => {
    const DEFAULT_DURATION = isMobile ? 3 : 7;
    const MAX_RANGE = isMobile ? 7 : 15;
    const defaultRanges = Utils.generateDefaultTimeline(DEFAULT_DURATION);
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails, 2);
    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);

    const defaultTimelineStartDate = defaultRanges[0];
    const defaultTimelineEndDate = defaultRanges[defaultRanges.length - 1];
    const timelineStartDate = dateRanges[0];
    const timelineEndDate = dateRanges[dateRanges.length - 1];
    let defaultDate = "";

    if (_.size(dateRanges) <= MAX_RANGE) {
      if (!_.isEmpty(dateRanges)) {
        setActiveDates(dateRanges);
        defaultDate =
          _.first(dateRanges) || Utils.getMiddleValue(dateRanges) || "";
      }
    } else {
      const currentDay = dayjs().format("YYYY-MM-DD");
      if (
        defaultTimelineStartDate >= timelineStartDate &&
        defaultTimelineEndDate <= timelineEndDate
      ) {
        setActiveDates(defaultRanges);
        if (!_.isEmpty(_.first(defaultRanges)))
          defaultDate =
            _.first(defaultRanges) || Utils.getMiddleValue(defaultRanges) || "";
      } else {
        const indexOfCurrentDay = dateRanges.indexOf(currentDay);
        if (indexOfCurrentDay !== -1) {
          const { daysBeforeCurrentDay, daysAfterCurrentDay } =
            Utils.calculateDaysAround(dateRanges, indexOfCurrentDay);
          if (daysBeforeCurrentDay >= daysAfterCurrentDay) {
            const startIndex = Math.max(
              indexOfCurrentDay - (MAX_RANGE - 1 - daysAfterCurrentDay),
              0
            );
            const endIndex = Math.min(
              indexOfCurrentDay + daysAfterCurrentDay + 1,
              dateRanges.length
            );
            const newActiveDates = dateRanges.slice(startIndex, endIndex);
            if (!_.isEmpty(newActiveDates)) {
              setActiveDates(newActiveDates);
              defaultDate = _.first(newActiveDates) || "";
            }
          } else {
            const endIndex = Math.min(
              indexOfCurrentDay + (MAX_RANGE - 1 - daysBeforeCurrentDay) + 1,
              dateRanges.length
            );
            const startIndex = Math.max(
              indexOfCurrentDay - daysBeforeCurrentDay,
              0
            );
            const newActiveDates = dateRanges.slice(startIndex, endIndex);
            if (!_.isEmpty(newActiveDates)) {
              setActiveDates(newActiveDates);
              defaultDate =
                _.first(newActiveDates) ||
                Utils.getMiddleValue(newActiveDates) ||
                "";
            }
          }
        } else {
          const MAX_MID_DATE = isMobile ? 7 : 14;
          const middleDates = Utils.getMiddleDates(dateRanges, MAX_MID_DATE);
          if (!_.isEmpty(middleDates)) {
            setActiveDates(middleDates);
            defaultDate =
              _.first(middleDates) || Utils.getMiddleValue(middleDates) || "";
          }
        }
      }
    }
    dispatch(saveCurrentDateInScroller(defaultDate));
  };

  const formattedDateRange = React.useMemo(() => {
    const startDate = dayjs(_.first(timeline));
    const endDate = dayjs(_.last(timeline));

    const isValidStartDate = dayjs(startDate).isValid();
    const isValidEndDate = endDate.isValid();

    const formattedStartDate = isValidStartDate
      ? startDate.format("MMM D")
      : "";
    const formattedEndDate = isValidEndDate
      ? endDate.format("MMM D, YYYY")
      : "";

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [timeline, currentLocale]);

  const containerRef = useRef<HTMLDivElement>(null);

  const toggleFullscreen = () => {
    const element: any = document.documentElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          mx: 3,
          mb: 3,
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Heading
            sx={{ textAlign: "center" }}
            fontSize="xl"
            mb={isMobile ? 1 : 2}
          >
            {boardDetails?.project?.name || "--"}
          </Heading>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              display: "flex",
              gap: 1,
              p: 2,
            }}
          >
            <Icon
              sx={{
                w: "32px",
                height: "32px",
                color: "#fff",
                p: 2,
                rounded: 5,
                cursor: "pointer",
                background: CommonColors.aquatone,
                "&:hover": {
                  background: "#748580",
                },
              }}
              onClick={() => {
                setIsShowFullScreen(!isShowFullScreen);
                toggleFullscreen();
              }}
            >
              {isShowFullScreen ? (
                <ArrowsPointingInIcon />
              ) : (
                <ArrowsPointingOutIcon />
              )}
            </Icon>
            <IconButton
              size="sm"
              aria-label={"Reset"}
              sx={{
                background: CommonColors.dustyCoral,
                color: "#Fff",
                "&:hover": {
                  background: "#B57D6E!important",
                },
              }}
              onClick={() => {
                updateDateRanges();
                setVisibleDays(isMobile ? 7 : 14);
                setIsReset(true);
              }}
            >
              <RepeatClockIcon />
            </IconButton>
          </Box>
        </Box>
        {isMobile && (
          <Box
            sx={{
              width: "100%",
              mt: 2,
              mx: 0,
            }}
          >
            {/* <DateRangeSlider
              activeDates={activeDates}
              onDateRangeChange={(activeDates) =>
                !_.isEmpty(activeDates) && setActiveDates(activeDates)
              }
              timeline={timeline}
              step={3}
            /> */}
            <SingleSlider
              value={visibleDays}
              onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
              calendarWidth={calendarWidth}
              defaultValue={isMobile ? 7 : 14}
              step={7}
              maxDays={_.size(timeline)}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            {/* {_renderFilterPopper()} */}
            <Heading size="sm">{formattedDateRange}</Heading>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                width: `calc(50% - ${language === "vi" ? "90" : "80"}px)`,
                position: "absolute",
                right: 0,
              }}
            >
              {/* <DateRangeSlider
                activeDates={activeDates}
                onDateRangeChange={(activeDates) =>
                  !_.isEmpty(activeDates) && setActiveDates(activeDates)
                }
                timeline={timeline}
              /> */}
              <SingleSlider
                value={visibleDays}
                onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
                calendarWidth={calendarWidth}
                defaultValue={isMobile ? 7 : 14}
                step={7}
                maxDays={_.size(timeline)}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const _renderToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mx: 4,
        }}
      >
        <Heading
          variant="subtitle2"
          sx={{
            fontSize: isMobile ? 16 : 18,
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            h: "full",
            whiteSpace: "nowrap",
            overflow: "hidden",
            transition: "width .5s ease-in-out",
            // color: "#fff",
            flex: 1,
            width: "50%",
            mt: 2,
          }}
        >
          <Image src={logo} sx={{ w: 35, height: 35, mr: 2 }} />
          {"Workspace"}
        </Heading>
        {!isShowFullScreen && (
          <Box sx={{ zIndex: 9 }}>
            <LanguageSwitch toClient isOpen />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box ref={containerRef}>
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          bg: "#fff",
        }}
      >
        {_renderToolbar()}
        {_renderTopSection()}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: `calc(100% - ${isMobile ? "150px" : "130px"})`,
          }}
        >
          <Calendar
            activeView={activeView}
            isShowFullScreen={isShowFullScreen}
            visibleRange={{
              start: _.first(activeDates) || "",
              end: _.last(activeDates) || "",
            }}
            minSlotWidthDate={minSlotWidthDate}
            onContainerWidth={(width) => setCalendarWidth(width)}
            isReset={isReset}
            onReset={() => setIsReset(false)}
          />
        </Box>
      </Box>
      {isShowFullScreen && (
        <FullHDWarning
          onAction={(e) => {
            setIsShowFullScreen(e);
          }}
        />
      )}
    </Box>
  );
};

export default ShareTimelineToClient;
