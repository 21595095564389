import React from "react";
import "rsuite/dist/rsuite.css";
import _ from "lodash";
import { Icon, IconButton, Text, Tooltip, Box } from "@chakra-ui/react";
import { Table, Pagination } from "rsuite";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { TrashIcon } from "@heroicons/react/24/outline";
import { NoDataFound, TextComponent } from "@/Components/Common";

import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { type IPaginate, type TActions } from "@/Types/Common.types";
import { useWindowWidth } from "@/Helpers";
import { IUser } from "../../../Interfaces/User.interface";
import { useTranslation } from "react-multi-lang";

const { Column, HeaderCell, Cell } = Table;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onChangeLimit?: (limint: number) => void;
  onRowAction: (item: IUser, action: TActions) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const UserDataTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  // actions = [],
  onRowAction,
  isLoading,
  onChangeLimit,
}) => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;

  const userData = Utils.getSavedUserData();
  const checkDeleteUser = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "checkDeleteUser"
  );

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box>
      <Table
        data={payload}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
      >
        <Column width={180} fixed={!isMobile} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.fullName")}
          </HeaderCell>
          <Cell dataKey="userData.fullName" />
        </Column>
        <Column minWidth={200} flexGrow={1} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            Email
          </HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.staffCode")}
          </HeaderCell>
          <Cell dataKey="staffCode" />
        </Column>
        <Column width={120}>
          <HeaderCell
            align="center"
            verticalAlign="center"
            style={HEADER_STYLE}
          >
            {t("table.phoneNumber")}
          </HeaderCell>
          <Cell align="center" dataKey="userData.phoneNumber" />
        </Column>
        <Column align="center" width={200}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.role")}
          </HeaderCell>
          <Cell dataKey="role">
            {(rowData) => {
              const roleNames = _.map(rowData?.userRole, (userRole) =>
                userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                  ? `Staff`
                  : _.startCase(userRole?.role?.name)
              );
              const concatenatedRoleNames = roleNames.join(", ");
              return (
                <TextComponent content={concatenatedRoleNames} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.birthday")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const formatDob =
                rowData?.userData?.dob &&
                dayjs(rowData?.userData?.dob).format("DD/MM/YYYY");
              return formatDob;
            }}
          </Cell>
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.gender")}
          </HeaderCell>
          <Cell
            dataKey="userData.gender"
            style={{ textTransform: "capitalize" }}
          />
        </Column>
        <Column align="center" width={100}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.createdAt")}
          </HeaderCell>
          <Cell dataKey="createAt">
            {(rowData) => {
              const formatCreateAt = dayjs(
                rowData?.createdAt.slice(0, 10)
              ).format("DD/MM/YYYY");
              return formatCreateAt;
            }}
          </Cell>
        </Column>
        <Column align="center" width={110}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.status")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              const isActive = rowData?.status === "active";

              return (
                <Text
                  color={"#fff"}
                  bgColor={
                    isActive ? "rgba(187, 113, 84,1)" : "rgba(166, 183, 170,1)"
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                  }}
                >
                  {t(`status.${rowData?.status}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={100} fixed="right">
          <HeaderCell
            verticalAlign="center"
            align="center"
            style={HEADER_STYLE}
          >
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData: any) => {
              const checkRole = _.find(
                rowData?.userRole,
                (role) => role.role.roleCode === ENUMS.ROLES.ADMIN
              );
              return (
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Tooltip label={t("tooltip.edit")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={PencilSquareIcon} />}
                      aria-label={""}
                      onClick={() => onRowAction(rowData, "edit")}
                    />
                  </Tooltip>
                  {checkDeleteUser &&
                    checkRole?.role?.roleCode !== ENUMS.ROLES.ADMIN && (
                      <Tooltip label={t("tooltip.delete")}>
                        <IconButton
                          sx={{
                            bg: "transparent",
                          }}
                          size="md"
                          isRound
                          icon={
                            <Icon as={TrashIcon} sx={{ color: "#bb7154" }} />
                          }
                          aria-label={""}
                          color="error"
                          onClick={() => onRowAction(rowData, "delete")}
                        />
                      </Tooltip>
                    )}
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default UserDataTable;
