import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  year: new Date().getFullYear(),
  status: "active",
};

const initialState = {
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  ...DEFAULT_LOADING_STATES,
  ...generateSuccessStateVariables(["Create", "Update", "ChangeStatus"]),
  meta: DEFAULT_META_STATES,
  pagination: DEFAULT_PAGINATION,
  holidaysList: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.RESET_HOLIDAY:
      return {
        ...initialState,
      };
    case ActionTypes.SET_HOLIDAY_ACTION_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isActionLoading: true,
      };
    case ActionTypes.SET_HOLIDAY_FETCH_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isFetchLoading: true,
      };
    case ActionTypes.SET_HOLIDAY_GET_LOADING:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        isGetLoading: true,
      };
    case ActionTypes.SET_META_HOLIDAY:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        meta: payload,
      };

    case ActionTypes.SET_PAGINATION_HOLIDAY:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
        pagination: payload,
      };

    case ActionTypes.CREATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: true,
      };
    case ActionTypes.CREATE_HOLIDAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
      };

    case ActionTypes.FETCH_HOLIDAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        holidaysList: payload,
      };
    case ActionTypes.FETCH_HOLIDAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isFetchLoading: false,
        isChangeStatusSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.GET_BY_ID_HOLIDAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.GET_BY_ID_HOLIDAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isGetLoading: false,
      };

    case ActionTypes.UPDATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: true,
      };
    case ActionTypes.UPDATE_HOLIDAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isUpdateSuccess: false,
      };

    case ActionTypes.CHANGE_STATUS_HOLIDAY_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: true,
      };
    case ActionTypes.CHANGE_STATUS_HOLIDAY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isActionLoading: false,
        isChangeStatusSuccess: false,
      };

    default:
      return state;
  }
};
