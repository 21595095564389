import { PURGE } from "redux-persist";
import { ActionTypes, DEFAULT_LOADING_STATES } from "@/Constants";

const initialState = {
  ...DEFAULT_LOADING_STATES,
  payload: [],
  details: {},
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_TASK_NOTE_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_TASK_NOTE_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_TASK_NOTE_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.RESET_TASK_NOTE_DETAILS:
      return {
        ...state,
        details: {},
      };
    case ActionTypes.RESET_TASK_NOTE_REDUCER:
      return initialState;

    case ActionTypes.FETCH_TASK_NOTE_SUCCESS: {
      return {
        ...state,
        isFetchLoading: false,
        payload,
      };
    }
    case ActionTypes.FETCH_TASK_NOTE_FAILURE: {
      return {
        ...state,
        isFetchLoading: false,
      };
    }
    case ActionTypes.CREATE_TASK_NOTE_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.CREATE_TASK_NOTE_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.GET_TASK_NOTE_BY_ID_SUCCESS: {
      return {
        ...state,
        isGetLoading: false,
        details: payload,
      };
    }
    case ActionTypes.GET_TASK_NOTE_BY_ID_FAILURE: {
      return {
        ...state,
        isGetLoading: false,
      };
    }
    case ActionTypes.UPDATE_TASK_NOTE_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.UPDATE_TASK_NOTE_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.DELETE_TASK_NOTE_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }
    case ActionTypes.DELETE_TASK_NOTE_FAILURE: {
      return {
        ...state,
        isActionLoading: false,
      };
    }

    default:
      return state;
  }
};
