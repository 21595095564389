import ProjectList from "./ProjectList";
import ProjectDetail from "./ProjectDetail";
import CreateProject from "./CreateProject";
import UpdateProject from "./UpdateProject";
import ShareTimelineToClient from "./ShareTimelineToClient";
import CreateExteriorProject from "./CreateExteriorProject";
import UpdateExteriorProject from "./UpdateExteriorProject";
import ProjectExteriorDetail from "./ProjectDetailExterior";

export default {
  ProjectList,
  ProjectDetail,
  CreateProject,
  UpdateProject,
  ShareTimelineToClient,
  CreateExteriorProject,
  UpdateExteriorProject,
  ProjectExteriorDetail,
};
