import React, { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  useBreakpoint,
  Stack,
  Icon,
  Box,
  Heading,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Avatar,
  IconButton,
  Grid,
  GridItem,
  Tooltip,
} from "@chakra-ui/react";
import {
  TrashIcon,
  PlusIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { shawdowEffects } from "@/Themes/CommonStyles";
import { AdminLayout } from "@/Components/DefaultLayout";
import {
  TextField,
  DatePicker,
  Select,
  EstimatedInput,
  SelectUser,
  Button,
  RoundedContainer,
} from "@/Components/Common";
import Utils from "@/Utils";
import { Routers } from "@/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTypedDispatch, RootState } from "@/Store";
import { ProjectActions, UserActions } from "@/Actions";
import { ENUMS } from "@/Constants";
import { useWindowWidth } from "@/Helpers";
//import CommonColors from "@/Themes/CommonColors";
import { AddIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import ClientActions from "@/Actions/Client.actions";
import { IClientStructure } from "@/Interfaces/Client.interface";
import TextEditor from "@/Components/Common/TextEditor";
import { useTranslation } from "react-multi-lang";

const { createProject } = ProjectActions;
const { fetchUser, resetUserReducer } = UserActions;
const { fetchClient, resetClientReducer } = ClientActions;
const { PROJECT_URGENCY } = ENUMS;

const DEFAULT_INFORMATION = {
  name: "",
  code: "",
  serverPath: "",
  startDate: "",
  endDate: "",
  clientNew: "",
  estimated: {
    days: 0,
    hours: 0,
  },
  description: "",
  urgency: PROJECT_URGENCY.EARLY,
  member: [],
};

const CreateProject: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const breakpoints = {
    base: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    "2xl": 4,
  };

  const currentBreakpoint = useBreakpoint();

  const columns =
    breakpoints[currentBreakpoint as keyof typeof breakpoints] || 1;

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t("message.projectNameIsRequired")),
      code: yup.string().trim().required(t("message.projectCodeIsRequired")),
      serverPath: yup.string().required(t("message.serverPathIsRequired")),
      clientNew: yup.string().trim().required(t("message.clientIsRequired")),
      startDate: yup
        .mixed()
        .transform((__, originalValue) => {
          // Check for empty string or null
          return originalValue !== "" ? new Date(originalValue) : null;
        })
        .nullable()
        .typeError(t("message.startDateInvalidFormat"))
        .test({
          name: "startDateBeforeEndDate",
          exclusive: true,
          message: t("message.startDateBeforeEndDate"),
          test: function (startDate) {
            const endDate = this.parent.endDate;
            if (startDate && endDate) return startDate <= endDate;
            return true;
          },
        })
        .test({
          name: "startDateNotNull",
          message: t("message.startDateIsRequired"),
          test: function (startDate) {
            return startDate !== null;
          },
        }),
      endDate: yup
        .mixed()
        .transform((__value, originalValue) => {
          // Check for empty string or null
          return originalValue !== "" ? new Date(originalValue) : null;
        })
        .nullable()
        .typeError(t("message.endDateInvalidFormat"))
        .test({
          name: "endDateNotNull",
          message: t("message.endDateIsRequired"),
          test: function (endDate) {
            return endDate !== null;
          },
        }),
      estimated: yup.object().shape({
        days: yup.number().notRequired(),
        hours: yup.number().notRequired(),
      }),
      urgency: yup
        .string()
        .trim()
        .required(t("message.degreeOfUrgencyIsRequired")),
      description: yup.string().notRequired(),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_INFORMATION,
  });

  const users: any[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );
  const clientList: IClientStructure[] = useSelector((state: RootState) =>
    _.get(state.CLIENT, "clientList")
  );

  const urgencyOptions = useMemo(() => {
    return _.map(PROJECT_URGENCY, (urgency) => {
      return {
        label: t(`label.${urgency}`),
        value: urgency,
      };
    });
  }, [PROJECT_URGENCY]);

  const userOptions = useMemo(() => {
    const allowedRoles = [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
    ];
    return _.map(users, (user) => {
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );

      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        return {
          id: user.id,
          avatar: user?.userData?.avatar?.path,
          name: user?.userData?.fullName,
          email: user?.email,
          roles: user?.userRole,
        };
      }

      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean); // Filter out null values (users without allowed roles)
  }, [users]);

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState<string | string[]>("");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [isClearSelectedUser, setIsClearSelectedUser] = useState(false);

  useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (accessRouter) {
      dispatch(
        fetchUser({
          page: 0,
          limit: 0,
          status: ENUMS.USER_STATUS.ACTIVE,
        })
      );
    }
    dispatch(
      fetchClient({
        page: 0,
        limit: 0,
        status: ENUMS.CLIENT_STATUS.ACTIVE,
      })
    );
    return () => {
      dispatch(resetUserReducer());
      dispatch(resetClientReducer());
    };
  }, []);

  const selectedUserIds = useMemo(() => {
    return _.map(selectedUsers, "id");
  }, [selectedUsers]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleAddNewMember = () => {
    const findUser = _.find(userOptions, (user) => user?.id === selectedUserId);
    const findUserExist = _.find(
      selectedUsers,
      (user) => user.id === selectedUserId
    );
    if (!findUserExist) {
      setSelectedUsers([...selectedUsers, findUser]);
    }
    setSelectedUserId("");
    setIsClearSelectedUser(true);
  };

  const handleRemoveMember = (userId: string) => {
    const newSelectedUsers = _.filter(
      selectedUsers,
      (user) => user.id !== userId
    );
    setSelectedUsers(newSelectedUsers);
  };

  const onSubmit = (data: FormData) => {
    const resolvedData: any = {
      ..._.omit(data, ["estimated"]),
      member: selectedUserIds,
      estimateWorkingTime: `${_.get(data, "estimated.days") || 0} days, ${
        _.get(data, "estimated.hours") || 0
      } hours`,
      startDate: dayjs(data?.startDate as any).isValid()
        ? dayjs(data?.startDate as any).format("YYYY-MM-DD")
        : null,
      endDate: dayjs(data?.endDate as any).isValid()
        ? dayjs(data?.endDate as any).format("YYYY-MM-DD")
        : null,
    };
    dispatch(createProject(resolvedData));
  };

  const clientsOptions = useMemo(
    () =>
      _.map(clientList, (item) => ({
        label: _.startCase(item?.name),
        value: item?.id,
      })),
    [clientList]
  );

  const _renderInformationTab = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        mt: 3,
      }}
    >
      <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3}>
        <GridItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("label.projectName")}
                placeholder={t("label.projectName")}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                isError={!_.isEmpty(errors.name?.message)}
                errorMessage={errors.name?.message}
                size="sm"
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("label.projectCode")}
                placeholder={t("label.projectCode")}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                isError={!_.isEmpty(errors.code?.message)}
                errorMessage={errors.code?.message}
                size="sm"
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="clientNew"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                isRequired
                size="sm"
                label={t("label.client")}
                placeholder={t("label.choose")}
                options={clientsOptions}
                isError={!!errors?.clientNew?.message}
                errorMessage={errors?.clientNew?.message}
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="serverPath"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("label.serverPath")}
                placeholder={t("label.serverPath")}
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
                isError={!_.isEmpty(errors.serverPath?.message)}
                errorMessage={errors.serverPath?.message}
                size="sm"
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                size="sm"
                label={t("label.startDate")}
                value={field.value as string}
                onDateChange={(date) => field.onChange(date)}
                isError={!_.isEmpty(errors.startDate?.message)}
                errorMessage={errors.startDate?.message}
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                size="sm"
                label={t("label.endDate")}
                value={field.value as string}
                onDateChange={(date) => field.onChange(date)}
                isError={!_.isEmpty(errors.endDate?.message)}
                errorMessage={errors.endDate?.message}
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="estimated"
            control={control}
            render={({ field }) => (
              <EstimatedInput
                label={t("label.estimatedWorkingTime")}
                values={field.value as any}
                isError={!!errors.estimated?.message}
                errorMessage={errors.estimated?.message}
                size="sm"
                onValuesChange={(days: number, hours: number) =>
                  field.onChange({
                    days,
                    hours,
                  })
                }
                // isRequired
              />
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="urgency"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value || PROJECT_URGENCY.EARLY}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.degreeOfUrgency")}
                size="sm"
                options={urgencyOptions}
                // isRequired
              />
            )}
          />
        </GridItem>
      </Grid>
      <Box>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextEditor
              value={field?.value || ""}
              label={t("label.description")}
              containerSx={{ mb: 2 }}
              onChange={(e: any) => field.onChange(e)}
            />
          )}
        />
      </Box>
    </Box>
  );

  const _renderMembersTab = () => (
    <Box>
      <Heading
        sx={{
          fontSize: 22,
          mb: 3,
        }}
      >
        {t("label.listOfMembers")} ({selectedUsers?.length})
      </Heading>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          alignItems: "center",
          mb: 3,
        }}
      >
        <SelectUser
          key={_.size(userOptions)}
          payload={userOptions}
          onChangeValue={(userId) => {
            setSelectedUserId(userId);
            setIsClearSelectedUser(false);
          }}
          isClear={isClearSelectedUser}
          disabledIds={selectedUserIds}
          sx={{
            width: isMobile ? "100%" : "50%",
          }}
        />
        <Button
          isDisabled={!selectedUserId}
          size="sm"
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
          onClick={handleAddNewMember}
        >
          {t("button.add")}
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {!_.isEmpty(selectedUsers) ? (
            _.map(selectedUsers, (user, index) => {
              const roleNames = _.map(user?.roles, (userRole) =>
                userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                  ? `Staff`
                  : userRole?.role?.name
              );
              const concatenatedRoleNames = roleNames.join(", ");
              return (
                <Box
                  key={index}
                  sx={{
                    boxShadow: shawdowEffects,
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={user.avatar} />
                      <Box>
                        <Heading size="sm">{user.name}</Heading>
                        <Text fontSize="sm">{user.email}</Text>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Text fontSize="sm" textTransform="capitalize">
                        {concatenatedRoleNames}
                      </Text>
                      <Tooltip label={t("tooltip.remove")} hasArrow>
                        <IconButton
                          isRound={true}
                          variant="solid"
                          aria-label={""}
                          size="sm"
                          sx={{
                            background: "none",
                          }}
                          onClick={() => handleRemoveMember(user.id)}
                        >
                          <Icon as={TrashIcon} boxSize={5} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Text fontSize="sm">{t("message.noDataWereFound")}</Text>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderMain = () => (
    <Stack
      sx={{
        p: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Heading
        size="lg"
        textAlign={isMobile ? "center" : "left"}
        mb={2}
        color={"#5c6e6c"}
      >
        {t("title.createInternalProject")}
      </Heading>
      <RoundedContainer>
        <Tabs
          index={tabIndex}
          onChange={handleTabsChange}
          variant="enclosed"
          size="sm"
        >
          <TabList
            sx={{
              width: "max-content",
              mx: "auto",
            }}
          >
            <Tab
              _selected={{
                color: "white",
                bg: "#bb7154",
              }}
            >
              {t("button.information")}
            </Tab>
            <Tab
              _selected={{
                color: "white",
                bg: "#bb7154",
              }}
            >
              {t("button.members")}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel
              sx={{
                p: 0,
              }}
            >
              {_renderInformationTab()}
            </TabPanel>
            <TabPanel
              sx={{
                p: 0,
              }}
            >
              {_renderMembersTab()}
            </TabPanel>
          </TabPanels>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "flex-end",
              mt: 5,
            }}
          >
            <Button
              size="sm"
              onClick={() => Utils.redirect(Routers.PROJECT)}
              leftIcon={<Icon as={ArrowLeftIcon} />}
            >
              {t("button.back")}
            </Button>
            <Button
              size="sm"
              //  colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              // isDisabled={!_.isEmpty(errors)}
              leftIcon={<Icon as={AddIcon} boxSize={3} />}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </Box>
        </Tabs>
      </RoundedContainer>
    </Stack>
  );

  return <AdminLayout content={renderMain()} />;
};

export default CreateProject;
