import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

interface ISectionProps {
  htmlContent: string;
  maxLines?: number;
  lineHeight?: number;
  sx?: object;
}

const ContentWithMaxLines: React.FC<ISectionProps> = ({
  htmlContent,
  maxLines = 1,
  lineHeight = 14,
  sx,
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const paragraphRef = useRef<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (boxRef?.current && paragraphRef?.current && htmlContent) {
      const paragraph = paragraphRef?.current;
      const linesArray = htmlContent.split("<br>");
      const firstLine = linesArray[0];
      paragraph.innerHTML = firstLine;
      const actualLines = Math.floor(paragraph.offsetHeight / lineHeight);
      setIsOverflow(actualLines > maxLines);
    }
  }, [htmlContent, maxLines, lineHeight]);

  let truncatedContent = htmlContent;

  if (isOverflow) {
    const linesArray = htmlContent.split("\n");
    truncatedContent = linesArray.slice(0, maxLines).join("\n");
  }
  return (
    <Box
      ref={boxRef}
      sx={{
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        WebkitLineClamp: maxLines,
        lineHeight: `${lineHeight}px`,
        ...sx,
        p: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: maxLines,
          ml: "2px!important",
        },
      }}
      dangerouslySetInnerHTML={{
        __html: truncatedContent ? truncatedContent : "--",
      }}
    />
  );
};

export default ContentWithMaxLines;
