import { request } from "@/Configs";
import { APIs } from "@/Constants";

const { WORKING_DAY } = APIs;

export const fetchWorkingDay = async (payload: any) => {
  return request(WORKING_DAY.BASIC, "GET", payload);
};

export const updateWorkingDay = async (payload: any) => {
  return request(WORKING_DAY.BASIC, "POST", payload);
};

export const getWorkingDayById = async (id: string) => {
  return request(`${WORKING_DAY.GET_BY_ID}/${id}`, "GET");
};
