import { Routers } from "@/Constants";

import { AuthenticationPages } from "@/Pages";

const AuthenticationRouter = {
  children: [
    {
      path: Routers.SIGN_IN,
      element: <AuthenticationPages.SignIn />,
    },
    {
      path: Routers.RESET_PASSWORD,
      element: <AuthenticationPages.ResetPassword />,
    },
    {
      path: Routers.VERIFY_ACCOUNT,
      element: <AuthenticationPages.VerifyAccount />,
    },
    {
      path: Routers.VERIFY_ACCOUNT_FAIL,
      element: <AuthenticationPages.VerifyAccountFail />,
    },
    {
      path: Routers.VERIFY_ACCOUNT_SUCCESS,
      element: <AuthenticationPages.VerifyAccountSuccess />,
    },
    {
      path: Routers.ACCOUNT_DEACTIVATED,
      element: <AuthenticationPages.AccountDeactivated />,
    },
    {
      path: Routers.COMING_SOON,
      element: <AuthenticationPages.ComingSoon />,
    },
    {
      path: Routers.ERROR_404,
      element: <AuthenticationPages.Error404 />,
    },
    {
      path: Routers.ERROR_500,
      element: <AuthenticationPages.Error500 />,
    },
  ],
};

export default AuthenticationRouter;
